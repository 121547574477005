<template>
  <connection-success :close-action="closeAction" :buttons="buttons" class="obk-connection-suggestion">
    <template v-slot:title>
      <div>רק עוד כמה דקות וסיימנו</div>
    </template>
    <template v-slot:subtitle>
      <div>
        <span>פרטי החיבור של חשבון {{sourceNameToConnect}} לא עדכניים.</span>
        <span class="bold"> כדי להמשיך לקבל תזרים מלא, צריך לחדש את החיבור ל{{sourceNameToConnect}}.</span>
      </div>
    </template>
  </connection-success>
</template>

<script>
import ConnectionSuccess from '@/base-components/ConnectionSuccess';
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';

export default {
  name: 'OBKConnectionSuggestion',
  components: {
    ConnectionSuccess,
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('OBKConnectionSuggestionAfterSuccess_Entered', {
      sourceName: this.sourceName,
    });
  },
  computed: {
    ...mapGetters('credentials', ['invalidCredentialsList']),
    currentInvalidCredsToConnect() {
      return this.invalidCredentialsList[0];
    },
    sourceNameToConnect() {
      return this.currentInvalidCredsToConnect.displayName;
    },
    buttons() {
      return [
        {
          title: `להמשך החיבור ב${this.currentInvalidCredsToConnect.displayName}`,
          action: this.openConnectionFlow,
          size: 'small',
        },
      ];
    },
    sourceName() {
      return this.source.sourceName;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal', 'closeAllModals']),
    openConnectionFlow() {
      Segment.trackUserGot('OBKCOnnectInvalidCredsSuggestion_ConnectClicked', {
        sourceName: this.sourceName,
      });
      this.closeAllModals();
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: this.currentInvalidCredsToConnect.sourceName,
          preventLoadingCredentialsCheck: true,
          closedBankingCredentialsId: this.currentInvalidCredsToConnect.credentialsId,
          showBackActions: false,
        },
        popupAlignment: 'full-screen',
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.bold {
  font-weight: bold;
}

</style>
