import * as SavingsApi from '@/api/SavingsApi';

const state = {
  funds: [],
};

const getters = {
  hasFunds: state => state.funds && state.funds.length > 0,
};

const actions = {
  async initSavingZone({ commit }) {
    const funds = await SavingsApi.getSavingZone();
    commit('setFunds', funds);
  },
};

const mutations = {
  setFunds(state, funds) {
    state.funds = funds;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
