<template>
  <base-tool-tip class="info-tooltip ri-font-14" icon-path="/images/info-icon-blue.svg">
    <div class="ri-font-weight-bold">יש פה כרטיס ששייך לשותפ.ה שלך?</div>
    <div>את ההסכמה צריך לתת עם מספר תעודת הזהות של הבעלים של הכרטיס.</div>
  </base-tool-tip>
</template>

<script>
import BaseToolTip from '@/base-components/onboarding/BaseToolTip';

export default {
  name: 'MissingCredsPartnerWraningTooltip',
  components: {
    BaseToolTip,
  },
};
</script>

<style lang="scss" scoped>
.info-tooltip {
  text-align: right;
  line-height: 20px;
}
</style>
