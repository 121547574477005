<template>
  <div>
    <div>
      <div class="description">
        <span>חיבור דרך {{ source.displayName }} יתן ל־RiseUp     </span>
        <span class="bold">הרשאה לקריאה בלבד</span>
        <span>של הנתונים ובתוקף שלוש שנים.   </span>
      </div>
      <div class="description partners" v-if="source.openBanking.jointAccountSupported">
        <div class="bold">יש לך חשבון עם שותפ.ה? </div>
        <div>צריך לוודא שזיהוי המשתמש מול הבנק מתאים לתעודת הזהות שהכנסת לפני רגע.</div>
      </div>
    </div>
    <div>
      <div v-if="source.openBanking.redirectPopup.backText" class="description">
        <span>בסיום חיבור מוצלח ב{{ source.displayName }},</span>
        <span class="bold"> תועברו אוטומטית חזרה לרייזאפ. </span>
        <span>אם מסיבה כלשהי לא הועברתם, מומלץ לחזור לרייזאפ ולהתחיל שוב את תהליך חיבור {{ source.displayName }}. </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpenBankingDefaultDescription',
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
  .description {
    font-family: SimplerPro;
    margin-bottom: 27px;
    font-size: 16px;
    line-height: 22px;

  .bold {
    font-weight: bold;
  }
  }
  .description.partners { margin-bottom: 60px; }
</style>
