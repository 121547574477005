<template>
  <div class="advisory-top-header" :class="variant">
    <div @click="back" class="back" :class="{ hidden: !showBack }">
      <icon size="24px" weight="bold" icon-name="arrow_right_alt" :color="buttonColor"/>
    </div>
    <div class="left-nav" @click="close">
      <icon size="24px" weight="bold" icon-name="close" :color="buttonColor"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'AdvisoryTopHeader',
  components: {
    Icon: BaseUI.Icon,
  },
  props: {
    showBack: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'white',
      validator: val => ['green', 'beige', 'white', 'transparent', 'yellow', 'blue', 'light-blue', 'pink'].includes(val),
    },
  },
  data() {
    return {
      buttonColor: BaseUI.Colors.riseupWhite,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/mixins';
@import '../../../../../scss/global-variables';
.advisory-top-header {
  height: $app_header_height;
  background-color: $riseup_white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  .left-nav {
    cursor: pointer;
  }

  .back {
    cursor: pointer;

    &.hidden {
      pointer-events: none;
      visibility: hidden;
    }
  }

  &.green {
    background: $riseup_dark_green;
  }

  &.beige {
    background: $riseup_beige;
  }

  &.transparent {
    background: transparent;
  }

  &.yellow {
    background: $riseup_yellow;
  }

  &.blue {
    background: #142AA0;
  }

  &.light-blue {
    background: $riseup_light_blue;
  }

  &.pink {
    background: $riseup_light_saturated_red;
  }
}

</style>
