<template>
  <card :hasHeaderPadding="false">
    <template v-slot:header v-if="headerPath">
      <img class="header" :src="require(`@/assets/${headerPath}`)"/>
    </template>
    <template v-slot:header v-else-if="this.$slots.header">
      <slot name="header"></slot>
    </template>

    <div class="content" :class="{'no-header': !headerPath}">
      <slot name="content"></slot>
      <div class="share-buttons">
        <riseup-button :title="shareButtonTitle" size="slim" :action="shareClick"/>
        <div class="or-copy">
          <span class="line"/>
          <span class="or">או</span>
          <span class="line"></span>
        </div>
        <input-copy class="input-copy" :input-content="link" @copied="linkCopied"/>
      </div>
      <terms-and-conditions v-if="showTerms"/>
    </div>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import TextCopiedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/TextCopiedToaster.vue';
import ShareEvents from '@/utils/shareEvents';
import ShareUtils from '@/utils/share';
import TermsAndConditions from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/TermsAndConditions';

export default {
  name: 'BaseAmbassadorsCard',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    InputCopy: BaseUI.InputCopy,
    Card: BaseUI.Card,
    TermsAndConditions,
  },
  props: {
    headerPath: {
      type: String,
      required: false,
    },
    shareButtonTitle: {
      type: String,
      required: true,
    },
    segmentData: {
      type: Object,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    shareText: {
      type: String,
      required: true,
    },
    showTerms: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    async shareClick() {
      ShareEvents.trackShareIntentClicked(this.segmentData);
      await ShareUtils.shareLink(
        this.link,
        this.shareText,
        this.segmentData,
      );
    },
    linkCopied() {
      Segment.trackUserInteraction('JoinAmbassadorPage_linkCopied', this.segmentData);
      EventBus.$emit('open-toaster', {
        component: TextCopiedToaster,
        props: {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/border-radius";

.text {
  font-size: 18px;
  line-height: 140%;

  &.small {
    font-size: 14px;
  }

  &.error {
    color: $riseup_danger_red;
  }
}

.bold {
  font-weight: bold;
}

.header {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
  width: 100%;
}

.content {
  margin: 20px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  &.no-header {
    margin-top: 32px;
  }

  .share-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    .wrapper {
      border: 1px solid $riseup_gray_0;
    }

    .or-copy {
      width: 100%;
      margin: 12px 0;
      display: flex;
      justify-content: space-between;
      .or {
        margin: 0 16px;
      }
      .line {
        align-self: center;
        width: 100%;
        border-bottom: 1px solid $riseup_gray_0;
      }
    }

    .input-copy {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
    }
  }

}

</style>

<style lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/typography";

.base-ambassadors-card {
  a.copy-btn.text {
    color: $riseup_blue;
    font-weight: $ri-font-weight-bold;
  }
}
</style>
