<template>
  <server-error v-if="error"/>
  <div v-else-if="!isLoading" class="mislaka-consent-flow">
    <advisory-top-header class="padding-horizontal-ml" @close="onCloseClicked" variant="blue" @back="onBackClicked"/>
    <div class="separator"></div>
    <advisory-road v-if="index === 0" @next="handleNext" />
    <advisory-assets-mapping-intro v-if="index === 1" @next="handleNext" />
    <mislaka-consent-form v-if="index===2" @next="handleNext" @error="onError" />
    <mislaka-consent-success v-if="index===3" :is-second-member="isSecondMember" @next="handleNext" @close="onCloseClicked" />
    <!--- Share with existing Member -->
    <mislaka-share-consent v-if="index===4 && showShareConsentScreen" @next="handleNext" @close="onCloseClicked" />
    <mislaka-after-consent-share v-if="index===5 && showShareConsentScreen" @next="goToCredsConnectionPage" />

    <!--- Should invite new member -->
    <mislaka-should-share v-if="index === 4 && !showShareConsentScreen" @next="handleNextWithMemberInvite" @close="onCloseClicked" />
    <mislaka-invite-member-to-cashflow v-if="index===5 && shouldInviteMember" @next="goToCredsConnectionPage" />

    <!--- Renew Creds -->
    <mislaka-creds-to-update v-if="index === renewCredsIndex" @next="handleNext" />
    <mislaka-finished-connection v-if="index === 7" @close="onCloseClicked" />
  </div>
  <div v-else class="loader">
    <lottie-player src="/animations/new-brand-loader.json" autoplay loop class="margin-bottom-m"
                   preserveAspectRatio="xMidYMax slice"/>
  </div>
</template>

<script>
import Segment from '@/Segment';
import ServerError from '@/pages/general-errors/ServerError';
import { mapActions, mapGetters, mapState } from 'vuex';
import AdvisoryTopHeader from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AdvisoryTopHeader';
import AdvisoryAssetsMappingIntro
  from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AdvisoryAssetsMappingIntro';
import AdvisoryRoad from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AdvisoryRoad';
import MislakaConsentForm from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaConsentForm';
import MislakaConsentSuccess
  from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaConsentSuccess';
import MislakaShareConsent from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaShareConsent';
import MislakaAfterConsentShare
  from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaAfterConsentShare';
import MislakaShouldShare from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaShouldShare';
import MislakaInviteMemberToCashflow
  from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaInviteMemberToCashflow';
import MislakaCredsToUpdate
  from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaCredsToUpdate';
import MislakaFinishedConnection
  from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaFinishedConnection';
import _ from 'lodash';

export default {
  name: 'MislakaConsentFlow',
  components: {
    MislakaFinishedConnection,
    MislakaCredsToUpdate,
    MislakaInviteMemberToCashflow,
    MislakaShouldShare,
    MislakaAfterConsentShare,
    MislakaShareConsent,
    MislakaConsentSuccess,
    AdvisoryRoad,
    AdvisoryAssetsMappingIntro,
    ServerError,
    AdvisoryTopHeader,
    MislakaConsentForm,
  },
  async created() {
    Segment.trackUserGot('MislakaConsentFlowEntered', { isSecondMember: this.isSecondMember });
    await this.fetchCredentialsToUpdate();
    if (this.$route.query.jumpToRenewCreds) {
      if (this.allCredsConnected) {
        this.index = this.renewCredsIndex + 1;
      } else {
        this.index = this.renewCredsIndex;
      }
    }
    this.isLoading = false;
  },
  data() {
    return {
      index: 0,
      error: false,
      shouldInviteMember: false,
      isLoading: true,
      history: [0],

    };
  },
  computed: {
    ...mapState('credentialsNew', ['credentialsToMigrateToOBKConfiguration', 'credentialsWithOldConsentConfiguration']),
    ...mapGetters('credentialsNew', ['unvalidatedCredentials']),
    ...mapGetters('session', ['numberOfMembers']),
    isSecondMember() {
      return this.$route.query.secondMember;
    },
    showShareConsentScreen() {
      return this.numberOfMembers > 1;
    },
    renewCredsIndex() {
      return 6;
    },
    allCredsConnected() {
      return this.credentialsToMigrateToOBKConfiguration.length === 0
        && this.credentialsWithOldConsentConfiguration.length === 0
        && this.unvalidatedCredentials.length === 0;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeAllModals']),
    ...mapActions('credentialsNew', ['fetchCredentialsToUpdate']),
    onCloseClicked() {
      Segment.trackUserGot('MislakaConsentFlow_CloseClicked');
      this.moveToHome();
    },
    onBackClicked() {
      Segment.trackUserGot('MislakaConsentFlow_BackClicked');
      this.history.pop();
      this.index = _.last(this.history);
    },
    moveToHome() {
      this.closeAllModals();
      this.$router.push({ path: 'sr' });
    },
    onError() {
      this.error = true;
    },
    handleNext() {
      this.index += 1;
      this.history.push(this.index);
    },
    handleBack() {

    },
    handleNextWithMemberInvite({ inviteMember }) {
      this.shouldInviteMember = inviteMember;
      if (inviteMember) {
        this.handleNext();
      } else {
        this.goToCredsConnectionPage();
      }
    },
    goToCredsConnectionPage() {
      if (this.allCredsConnected) {
        this.index = this.renewCredsIndex + 1;
      } else {
        this.index = this.renewCredsIndex;
      }
      this.history.push(this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.mislaka-consent-flow{
  height: 100%;
  display: flex;
  flex-direction: column;

  .separator{
    height: 1px;
    min-height: 1px;
    width: 100%;
    background: $riseup_black;
  }
}

</style>
