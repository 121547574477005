<template>
  <connection-failure-base class="consent-creation-failure" :close-action="closeAction" :buttons="buttons">
    <template v-slot:title>
         נראה שהחיבור ל{{sourceName}} לא הצליח
      </template>
      <template v-slot:subtitle>
        <span class="bold">כדאי לנסות שוב בעוד כמה דקות,</span>
        <span>או לפנות כאן בצ’אט לצוות התמיכה שלנו שישמח לעזור לך להשלים את חיבור החשבון.</span>
      </template>
  </connection-failure-base>
</template>

<script>
import Intercom from '@/Intercom';
import Segment from '@/Segment';
import { mapGetters } from 'vuex';
import ConnectionFailureBase from './ConnectionFailureBase';

export default {
  name: 'ConsentCreationFailure',
  components: {
    ConnectionFailureBase,
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    sourceName: {
      type: String,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('OBKConsentCreationFailureShown', { source: this.sourceName, isOnboarding: !this.onboardingCompleted });
    Intercom.hideLauncher();
  },
  computed: {
    ...mapGetters('onboardingState', ['onboardingCompleted']),
    buttons() {
      return [{
        action: this.tryAgain,
        title: 'לנסות שוב',
        size: 'slim',
      }, {
        action: this.getHelp,
        // eslint-disable-next-line global-require
        icon: require('@/assets/icons/chat_icon_small_teriatry.svg'),
        title: 'לצ\'אט עם הצוות',
        variant: 'secondary',
        size: 'slim',
        id: 'cx-consent-creation-failure',
      }];
    },
  },
  methods: {
    tryAgain() {
      Segment.trackUserGot('OBKConsentCreationFailure_TryAgainClicked', { source: this.sourceName, isOnboarding: !this.onboardingCompleted });
      this.closeAction();
    },
    getHelp() {
      Segment.trackUserGot('OBKConsentCreationFailure_CXButtonClicked', { source: this.sourceName, isOnboarding: !this.onboardingCompleted });
      Intercom.show();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../scss/mixins';

  .consent-creation-failure {
    .bold{
      font-weight: bold;
    }
  }

</style>
