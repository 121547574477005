<template>
  <div class="anl-liabilities">
    <div v-if="negativeOshBalances && negativeOshBalances.length" class="section">
        <div class="section-title">יתרות עו״ש</div>
        <an-l-account-card
          v-for="(balance, i) in negativeOshBalances"
          :key="i"
          :sourceName="getHebrewSourceName(balance.sourceIdentifier)"
          :interests="balance.interests"
          :amount="-1 * balance.balanceAmount.amount"
        />
    </div>
    <div v-if="loansWithoutOshBalances && loansWithoutOshBalances.length" class="section">
      <div class="section-title">הלוואות</div>
      <an-l-account-card
        v-for="(account, i) in loansWithoutOshBalances"
        :key="i"
        :sourceName="getHebrewSourceName(account.sourceIdentifier)"
        :amount="-1 * account.balanceAmount.amount"
        :interests="account.interests"
        :contract-end-date="account.contractEndDate"
        :contract-start-date="account.contractStartDate"
      />
    </div>
    <div v-if="mortgagesSumBySource && mortgagesSumBySource.length" class="section">
      <div class="section-title">משכנתאות</div>
      <an-l-account-card
        v-for="(account, i) in mortgagesSumBySource"
        :key="i"
        :sourceName="getHebrewSourceName(account.sourceIdentifier)"
        :amount="account.amount"
        :is-mortgage="true"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import AnLAccountCard from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AnLAccountCard';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';

export default {
  name: 'AnLLiabilities',
  components: { AnLAccountCard },
  computed: {
    ...mapState('assetsAndLiabilities', ['savingsAccounts']),
    ...mapGetters('assetsAndLiabilities', ['negativeOshBalances', 'loansWithoutOshBalances', 'mortgagesSumBySource']),
  },
  methods: {
    getHebrewSourceName(sourceName) {
      return getSourceConfigurationBySourceName(sourceName).displayName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import "~@riseupil/base-ui/src/scss/typography";
@import '~@riseupil/base-ui/src/scss/spacings';

.anl-liabilities{
  .section{
    margin-top: $ri-spacing-xl;
    .section-title {
      margin-bottom: $ri-spacing-ml;
      font-size: $ri-font-size-22;
    }
  }

  .section:first-of-type {
    margin-top: $ri-spacing-l;
  }

  .green{
    color: $riseup_green;
  }

  .red{
    color: $riseup_danger_red;
  }
}

</style>
