<template>
  <insight :insight="insight"
           animation-name="riseup-goal.json"
           :button-text="buttonText"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        <span class="ri-bold-title">{{ title }}</span>
        <span>{{ subtitle }}</span>
      </div>
    </template>
  </insight>
</template>

<script>
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/event-bus/event-bus';
import RiseupGoalUpdatedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/RiseupGoalUpdatedToaster';
import EditMonthlyGoalAmount
  from '../cashflow-view/current-month/components/cashflow-edit/edit-riseup-goal/EditMonthlyGoalAmount';

export default {
  name: 'BalancedMonthlyGoalInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('SetMonthlyGoalInsight_Shown');
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    title() {
      return this.cashflowIsPositive ? 'היעד החודשי התעדכן, ' : 'היעד החודשי לא מופיע בתזרים כרגע, ';
    },
    subtitle() {
      return this.cashflowIsPositive ? 'הוא משתנה אוטומטית בהתאם להוצאות שקרו בפועל במהלך החודש.'
        : 'הוא מתעדכן אוטומטית ונראה שהחודש לא נשאר כסף לשים בצד.';
    },
    buttonText() {
      return this.cashflowIsPositive ? 'לבחירת יעד' : 'הבנתי, תודה';
    },
    cashflowIsPositive() {
      return this.predictedBalanceAtEndOfMonth >= 0;
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal']),
    async buttonAction() {
      if (this.cashflowIsPositive) {
        this.openModal({
          component: EditMonthlyGoalAmount,
          props: {
            onSaveCb: this.onSaveRiseUpGoal,
          },
        });
      } else {
        this.markAsRead(this.insight);
      }
    },
    onSaveRiseUpGoal() {
      Segment.trackUserInteraction('SetMonthlyGoalInsight_SavedGoal');
      this.markAsRead(this.insight);
      EventBus.$emit('open-toaster', {
        component: RiseupGoalUpdatedToaster,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.bold {
  font-weight: bold;
}

</style>
