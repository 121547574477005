<template>
  <div>
    <obk-description-partner-note-step step-number="1" />
    <obk-description-connect-supported-accounts-step step-number="2" />
    <obk-description-connect-unsupported-accounts-step step-number="3" />
  </div>
</template>

<script>
import ObkDescriptionPartnerNoteStep
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/ObkDescriptionPartnerNoteStep';
import ObkDescriptionConnectSupportedAccountsStep
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/ObkDescriptionConnectSupportedAccountsStep';
import ObkDescriptionConnectUnsupportedAccountsStep
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/ObkDescriptionConnectUnsupportedAccountsStep';

export default {
  name: 'OpenBankingBankDescription',
  components: { ObkDescriptionConnectUnsupportedAccountsStep, ObkDescriptionConnectSupportedAccountsStep, ObkDescriptionPartnerNoteStep },
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">

</style>
