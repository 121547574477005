<template>
  <toaster @close-toaster="closeSummaryToaster">
    <template v-slot:text>
      <span>התוכנית ׳{{name}}׳ עודכנה</span>
    </template>
  </toaster>
</template>

<script>
import Toaster from '@/base-components/Toaster';
import EventBus from '@/event-bus/event-bus';

export default {
  name: 'PlanEditToaster',
  components: {
    Toaster,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeSummaryToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style scoped>

</style>
