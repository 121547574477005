<template>
  <div>
    <CreateTrackingCategoryAmountPopup :closeAction="close" @close="onSave"/>
    <separator></separator>
    <div @click="doNotShowAgain" class="footer-btn">הבנתי, אל תציגו לי יותר</div>
  </div>
</template>

<script>
import Segment from '@/Segment';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import CreateTrackingCategoryAmountPopup from
  '../../cashflow-view/current-month/components/cashflow-edit/edit-reccuring/CreateTrackingCategoryAmountPopup.vue';

export default {
  name: 'TrackingInsightPopup',
  components: {
    CreateTrackingCategoryAmountPopup,
    Separator: BaseUI.Separator,
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
  },
  created() {
    this.selectTrackingCategory(this.normalizedTrackingCategory);
    this.setAmount(this.insight.trackingCategory.categoryAverage);
  },
  computed: {
    ...mapGetters('createTrackingCategory', ['availableTrackingCategories']),
    normalizedTrackingCategory() {
      return _.find(this.availableTrackingCategories, category => category.value === this.insight.trackingCategory.name);
    },
  },
  methods: {
    ...mapActions('createTrackingCategory', ['reset', 'selectTrackingCategory', 'setAmount']),
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['closeModal']),
    close() {
      this.reset();
      this.closeModal();
    },
    onSave() {
      this.markAsRead(this.insight);
      this.close();
    },
    async doNotShowAgain() {
      await this.markAsRead(this.insight);
      Segment.trackUserInteraction('TrackingInsightDoNotShowAgain');
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

  .footer-btn {
    display: flex;
    justify-content: center;
    margin-top: 22px;
    margin-bottom: 20px;
    font-size: 18px;
    color: $riseup_black;
    cursor: pointer;
  }

</style>
