import OtisApi from '../../api/Otis';

const OTS_JOBID_LOCAL_STORAGE_KEY = 'OTSILJobId';
const state = {
};

const getters = {
};

const actions = {
  async createLLMJob({ commit }, { promptId, flowConfigId }) {
    const { jobId } = await OtisApi.createLLMJob({ flowConfigId, promptId });
    localStorage.setItem(OTS_JOBID_LOCAL_STORAGE_KEY, jobId);
  },

  async getOTSResult({ commit }) {
    return await OtisApi.getOTSResult(localStorage.getItem(OTS_JOBID_LOCAL_STORAGE_KEY));
  },
};

const mutations = {

};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
