<template>
  <sticky-cta-page :cta-props="{buttonProps: { title: 'הבנתי אפשר להמשיך'}}" class="advisory-assets-mapping-intro"
                   @click="onContinueClicked">
    <div class="title-image">
      <img src="/images/advisory/mislaka-consent-header.svg" />
    </div>
      <div class="padding-horizontal-ml content">
        <div class="ri-body top-title margin-top-ml margin-bottom-sm">מיפוי התמונה הכלכלית שלך</div>
        <div class="ri-large-headline margin-bottom-xs">כדי לבנות לך תוכנית אישית, נתחיל למפות את כל הכספים שיש לך.</div>
        <div class="ri-large-body margin-bottom-ml">
          <span>בשלב הראשון, כדי שנוכל להציג לך את כל קרנות ההשתלמות וקופות הגמל, נצטרך ממך</span>
          <span class="ri-font-weight-bold">אישור לפנות בשמך  למסלקה הפנסיונית באופן חד־פעמי.</span>
        </div>
        <img src="/images/advisory/assets-mapping-intro.png" class="image" />
        <riseup-button title="הבנתי, אפשר להמשיך" :action="onContinueClicked"
                        variant="primary" :size="'slim'" class="margin-bottom-s"/>
    </div>
  </sticky-cta-page>
</template>

<script>
import { COLOR_VARIANT } from '@riseupil/base-ui/src/utils/layout-consts';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'AdvisoryAssetsMappingIntro',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  computed: {
    headerProps() {
      return {
        backgroundColor: COLOR_VARIANT.DARK_BLUE,
        leftActions: [{
          type: 'CLOSE',
          onClick: () => this.$emit('close'),
        }],
      };
    },
  },
  created() {
    Segment.trackUserGot('AdvisoryAssetsMappingIntro_Entered');
  },
  methods: {
    onContinueClicked() {
      Segment.trackUserGot('AdvisoryAssetsMappingIntro_ContinueClicked');
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.advisory-assets-mapping-intro{
  text-align: right;
  .title-image {
    height: 120px;
  }

  .content {
    height: 100%;
    .top-title {
      color: $riseup_blue;
    }

    .image {
      height: 160px;
      width: 147px;
    }
  }
}

</style>
