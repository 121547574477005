<template>
  <popup :buttons="buttons" :close-action="closeModal" title="שמת בצד כבר חלק מהסכום">
    <template v-slot:content>
      <div class="ri-large-body">
        אם יש לך כבר חלק מהסכום לתוכנית, אפשר להכניס אותו. זה יכול לעזור לך לשמור על סדר ומעקב.
      </div>
      <div class="margin-top-l ri-title">כמה שמת בצד עד עכשיו?</div>
      <div class="margin-bottom-xl">
        <amount-input :value="savedAmountObj.amount" label="" autofocus @input="onAmountInput"
                      hint="הסכום הזה הוא חלק מהיעד של התוכנית" :error-message="errorMsg"/>
        <div class="margin-top-l margin-bottom-xs ri-title">אפשר לכתוב איפה נמצא הכסף</div>
        <text-input :value="savedAmountObj.comment" :on-input="onCommentInput"
                    hint="למשל: פיקדון בבנק, עו״ש או בית השקעות"/>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import _ from 'lodash';

export default {
  name: 'PlanExistingFundPopup',
  props: {
    handleMonthlyCalculation: {
      type: Function,
      required: true,
    },
    maxAmount: {
      type: Number,
      required: true,
    },
  },
  components: {
    Popup: BaseUi.Popup,
    AmountInput: BaseUi.AmountInput,
    TextInput: BaseUi.TextInput,
  },
  data() {
    return {
      savedAmountObj: {
        comment: '',
        amount: null,
      },
      errorMsg: undefined,
    };
  },
  created() {
    Segment.trackUserGot('AddAmountToPlanPopup_Opened', {
      planId: this.currentPlan.planId,
      hasExistingFundTransaction: !!this.customerPlanTransaction,
    });

    // When existing fund was edited and then the popup reopened again we need to present the newer values that are kept in current
    // plan instead of the values in the customer transaction
    this.savedAmountObj.amount = this.currentPlan.savedAmountObj?.amount ?? this.customerPlanTransaction?.amount ?? 0;
    this.savedAmountObj.comment = this.currentPlan.savedAmountObj?.comment ?? this.customerPlanTransaction?.comment ?? '';
  },
  computed: {
    ...mapState('planAhead', ['currentPlan']),
    buttons() {
      return [
        {
          title: 'סיימתי',
          action: this.save,
          disabled: !!this.errorMsg || this.savedAmountObj.amount < 0,
        },
      ];
    },
    sumNonCustomerPlanTransactions() {
      return _.sumBy(this.currentPlan.planTransactions?.filter(transaction => transaction.creator !== 'customer'), 'amount') || 0;
    },
    customerPlanTransaction() {
      return this.currentPlan.planTransactions?.find(transaction => transaction.creator === 'customer');
    },
  },
  methods: {
    ...mapActions('planAhead', ['updateCurrentPlan']),
    ...mapActions('modalRootStore', ['closeModal']),
    async save() {
      Segment.trackUserInteraction('AddAmountToPlanPopup_Edited', {
        planId: this.currentPlan.planId,
        amount: this.savedAmountObj.amount,
        comment: this.savedAmountObj.comment,
      });

      if (this.currentPlan.planTransactions) {
        await this.updateCurrentPlan({
          savedAmountObj: {
            amount: this.savedAmountObj.amount,
            comment: this.savedAmountObj.amount > 0 ? this.savedAmountObj.comment : '',
          },
        });
      } else if (this.savedAmountObj.amount > 0) {
        await this.updateCurrentPlan({
          currentAmount: 0,
          savedAmountObj: this.savedAmountObj,
        });
      }
      await this.closeModal();
      await this.handleMonthlyCalculation();
    },
    onCommentInput(comment) {
      this.savedAmountObj.comment = comment;
    },
    onAmountInput(amount) {
      this.savedAmountObj.amount = amount;
      if (amount >= this.maxAmount - this.sumNonCustomerPlanTransactions) {
        this.errorMsg = 'אי אפשר לשים יותר מיעד התוכנית';
      } else {
        this.errorMsg = undefined;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.amount-input {
  margin-top: -20px;
  margin-bottom: 0;
}
</style>
