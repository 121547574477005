<template>
  <div class="plan-expenses">
    <transaction v-for="transaction in expenses"
                 :key="transaction._id"
                 :transaction="transaction"
                 :can-edit-category="false"/>
    <div class="summary-line ri-bold-body">
      <div class="total padding-right-ml">סה"כ</div>
      <div>{{ totalExpenses }} ₪</div>
    </div>
  </div>
</template>

<script>
import moneyUtils from '@/utils/money';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import _ from 'lodash';

export default {
  name: 'PlanExpenses',
  components: {
    Transaction: () => import('../../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/Transaction'),
  },
  mixins: [TransactionDisplayUtils],
  props: {
    expenses: {
      type: Array,
      required: true,
    },
  },
  computed: {
    totalExpenses() {
      return moneyUtils.formatAmountWithFraction(_.sumBy(this.expenses, t => this.getTransactionAmountWithoutFraction(t)));
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-expenses {
  text-align: right;
  cursor: default;

  .summary-line {
    height: 61px;
    text-align: right;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .total {
      width: 94px;
    }
  }
}
</style>
