import BaseUI from '@riseupil/base-ui';

const PlansIconsMap = {
  car: { icon: 'car', color: BaseUI.LayoutConsts.COLOR_VARIANT.DARK_GREEN },
  'emergency-fund': { icon: 'general', color: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE },
  gift: { icon: 'gift', color: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE },
  'summer-camp': { icon: 'camps', color: BaseUI.LayoutConsts.COLOR_VARIANT.GREEN },
  vacation: { icon: 'vacation', color: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE },
  event: { icon: 'event', color: BaseUI.LayoutConsts.COLOR_VARIANT.DARK_GREEN },
  renovation: { icon: 'renovation', color: BaseUI.LayoutConsts.COLOR_VARIANT.GREEN },
};

export const PlanIconDefaultColor = BaseUI.LayoutConsts.COLOR_VARIANT.BLUE;

export const OnboardingPlans = {
  Vacation: 'vacation',
  Renovation: 'renovation',
  Gift: 'gift',
  Car: 'car',
  Event: 'event',
  General: 'general',
};

export const OnboardingPlanToButtonProps = {
  [OnboardingPlans.Vacation]: {
    text: 'חופשה',
    image: 'vacation.png',
  },
  [OnboardingPlans.Renovation]: {
    text: 'שיפוץ',
    image: 'renovation.png',
  },
  [OnboardingPlans.Gift]: {
    text: 'מתנה לעצמי',
    image: 'gift.png',
  },
  [OnboardingPlans.Car]: {
    text: 'רכב חדש',
    image: 'car.png',
  },
  [OnboardingPlans.Event]: {
    text: 'אירוע משפחתי',
    image: 'event.png',
  },
  [OnboardingPlans.General]: {
    text: 'יש לי תוכנית אחרת!',
    image: 'default.png',
  },
};

export default PlansIconsMap;
