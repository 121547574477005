import Vue from 'vue';
import _ from 'lodash';
import SignupApi from '@/api/SignupApi';
import router from '@/router';
import LoginApi from '../../api/LoginApi';
import phoneNumber from '../../utils/phoneNumber';

const loginFlowSteps = {
  enterPhone: 'enter-phone',
  enterCode: 'enter-code',
};

const errorTypes = {
  invalidPhoneNumber: 'INVALID_PHONE_NUMBER',
  cookiesDisabled: 'COOKIES_DISABLED',
  unknownError: 'UNKNOWN_ERROR',
  unMarkedError: 'UN_MARKED_ERROR',
};

const state = {
  phoneNumber: '',
  serverErrors: {
    generateCode: '',
    authenticate: '',
    loginAs: '',
  },
};

const getters = {
  loginFlowSteps: () => loginFlowSteps,
  phoneNumber: state => state.phoneNumber.replace(/\D/g, ''),
  errorTypes: () => errorTypes,
};

const actions = {
  async generateCode({ state, dispatch, commit }, force = false) {
    if (phoneNumber.validatePhoneNumber(state.phoneNumber)) {
      try {
        await LoginApi.generateCode({ phoneNumber: state.phoneNumber, force });
      } catch (e) {
        dispatch('catchServerError', { e, functionName: 'generateCode' });
      }
    } else {
      commit('setError', { error: errorTypes.invalidPhoneNumber, functionName: 'generateCode' });
      throw new Error('Invalid phone number');
    }
  },
  forceGenerateCode({ dispatch }) {
    dispatch('generateCode', true);
  },
  async authenticate({ commit, state, dispatch }, code) {
    try {
      const { data } = await LoginApi.authenticate({ phoneNumber: state.phoneNumber, code });
      return { customerId: data.customerId, riseupAccountId: data.riseupAccountId };
    } catch (e) {
      dispatch('catchServerError', { e, functionName: 'authenticate' });
      return null;
    }
  },
  async loginAs({ dispatch }, customerToken) {
    try {
      await LoginApi.loginAs({ customerToken });
    } catch (e) {
      dispatch('catchServerError', { e, functionName: 'loginAs' });
    }
  },
  async logout({ dispatch }) {
    try {
      await LoginApi.logout();
    } catch (e) {
      dispatch('catchServerError', { e, functionName: 'logout' });
    }
  },
  async addCashflowForRiseupAccount({ dispatch, rootGetters }, activeMember) {
    const utmParams = _.pickBy(router.currentRoute.query, (value, key) => (key.startsWith('utm_') && value));
    const icrcParam = router.currentRoute.query.icrc;

    const resp = await SignupApi.createCustomer({
      firstName: activeMember.firstNamePiiValue,
      lastName: activeMember.lastNamePiiValue,
      phoneNumber: phoneNumber.visualizePhoneNumber(activeMember.phoneNumberPiiValue),
      email: activeMember.emailPiiValue,
      enableMarketingEmails: activeMember.enableMarketingEmails,
      utmParams,
      icrcParam,
    });
    const { customerId } = resp.data;
    return {
      customerId,
      intake: {
        utmParams,
        icrcParam,
      },
    };
  },
  catchServerError({ commit, state }, { e, functionName }) {
    if (e.response && e.response.status === 400) {
      commit('setError', { error: e.response.data, functionName });
    } else if (e.response && e.response.status === 409) {
      commit('setError', { error: errorTypes.cookiesDisabled, functionName });
    } else if (e.response && e.response.status === 403) {
      commit('setError', { error: errorTypes.unMarkedError, functionName });
    } else {
      commit('setError', { error: errorTypes.unknownError, functionName });
    }
    throw e;
  },
};

const mutations = {
  setPhoneNumber(state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  setError(state, { error, functionName }) {
    Vue.set(state.serverErrors, functionName, error);
  },
  resetError(state, functionName) {
    Vue.set(state.serverErrors, functionName, '');
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
