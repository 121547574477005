import Vue from 'vue';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';

import _ from 'lodash';

export function isIsraeliIdValid(value) {
  if (!(value.length === 9 && /^\d+$/.test(value))) {
    return false;
  }

  const counter = _.reduce(value, (counter, digit, i) => {
    const rawVal = Number(digit) * ((i % 2) + 1);
    const actualVal = rawVal > 9 ? (rawVal - 9) : rawVal;
    return counter + actualVal;
  }, 0);
  return (counter % 10 === 0);
}

function minLength(value, { min }) {
  return min <= value.length;
}

function maxLength(value, { max }) {
  return max >= value.length;
}

function isNotHebrew(value) {
  return !value.match(/[\u0590-\u05ff]+/);
}

function isNotSpecial(value) {
  return !value.match(/[^A-Za-z0-9]+/);
}

function exactLength(value, { length }) {
  return value.length === parseInt(length, 10);
}

function onlyDigits(value) {
  return /^\d+$/.test(value);
}

function init() {
  Vue.component('ValidationProvider', ValidationProvider);
  Vue.component('ValidationObserver', ValidationObserver);
  extend('id', {
    message: 'מספר ת.ז לא תקין',
    validate: isIsraeliIdValid,
  });
  extend('not-he', {
    message: 'אין להכניס תווים בעברית',
    validate: isNotHebrew,
  });
  extend('not-special', {
    message: ' אין להכניס תווים מיוחדים',
    validate: isNotSpecial,
  });
  extend('min-length', {
    message: 'צריכים להיות כאן לפחות {min} תווים',
    params: ['min'],
    validate: minLength,
  });
  extend('max-length', {
    message: 'צריכים להיות כאן לכל היותר {max} תווים',
    params: ['max'],
    validate: maxLength,
  });
  extend('exact-length', {
    message: 'צריכים להיות כאן {length} תווים',
    params: ['length'],
    validate: exactLength,
  });
  extend('only-digits', {
    message: 'צריך להיות כאן רק מספר',
    validate: onlyDigits,
  });
}

export default {
  init,
  isIsraeliIdValid,
};
