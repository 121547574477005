<template>
  <div class="edit-monthly-amount-popup">
    <colored-popup class="yellow" dark-text :closeAction="close">
      <template v-slot:top-content>
        <div class="title ri-medium-bold-headline">היעד החודשי</div>
      </template>
      <template v-slot:bottom-content>
        <div class="ri-large-body popup-content">
          <div v-if="isGoalCustom" class="ri-large-bold-body">עם איזה סכום נרצה לסיים את החודש?</div>
          <div v-else class="ri-large-bold-body">כרגע נראה שאפשר יהיה לשים בצד החודש {{ formattedRecommendation }} ₪</div>
          <amount-input class="margin-top-ml" label="היעד שלך" :value="updatedAmount"
                        @input="onInput" :error-message="errorMsg" autofocus/>
          <card>
            <div class="margin-vertical-m">
              <balance-bar-graph :left-amount="updatedVariables"
                                 left-label="כמה ישאר במשתנות"
                                 right-label="יעד חודשי"
                                 :right-amount="updatedAmount"
                                 :color="barColor"
              />
            </div>
          </card>
          <expandable-section header-text="איך ההמלצה מחושבת?" class="margin-vertical-ml"
                              @expand-change="logExpandChanged">
            <template v-slot:content>
              <div class="ri-body">
                <div>
                  החישוב הוא ההכנסה של החודש פחות הסכום שמייצג הכי טוב את ההוצאות של החודשים הקודמים
                  (חציון, לא ממוצע).
                </div>
                <div>
                  המטרה של היעד היא לעודד אותנו להתחייב לסכום בתחילת החודש כדי לסיים בתזרים חיובי.
                  במקרה שבו התזרים ישתנה, היעד יתעדכן אוטומטית וישקף את איך שנראה שהחודש הולך להסתיים.
                </div>
              </div>
            </template>
          </expandable-section>
          <riseup-button title="שמירת היעד שלי" @click="onSave" :disabled="saveDisabled"/>
        </div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import BalanceBarGraph from '@/base-components/BalanceBarGraph';
import Segment from '@/Segment';
import moneyUtils from '@/utils/money';

export default {
  name: 'EditMonthlyGoalAmount',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    AmountInput: BaseUI.AmountInput,
    Card: BaseUI.Card,
    RiseupButton: BaseUI.RiseupButton,
    ExpandableSection: BaseUI.ExpandableSection,
    BalanceBarGraph,
  },
  props: {
    onSaveCb: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      errorMsg: '',
      updatedAmount: null,
      barColor: BaseUI.Colors.riseupYellow,
    };
  },
  created() {
    Segment.trackUserInteraction('EditMonthlyGoalAmount_Shown');
    this.updatedAmount = _.round(this.riseupGoal);
  },
  computed: {
    ...mapGetters('cashflowView', ['riseupGoal', 'isGoalCustom']),
    ...mapState('cashflowView', ['presentedBudgetDate', 'cashflow']),
    ...mapState('planAhead', ['eligibleForPlanAhead']),
    ...mapGetters('insights', ['filteredInsights', 'INSIGHT_NAMES']),
    remainingVariables() {
      return _.max([this.cashflow.remainingVariables, 0]);
    },
    totalAmount() {
      return this.remainingVariables + this.riseupGoal;
    },
    updatedVariables() {
      return _.max([this.totalAmount - this.updatedAmount, 0]);
    },
    formattedRecommendation() {
      return moneyUtils.formatAmountWithFraction(this.cashflow.balancedRiseupGoalRecommendation, 0);
    },
    saveDisabled() {
      return !!this.errorMsg || _.isNil(this.updatedAmount) || this.updatedAmount < 0;
    },
  },
  methods: {
    ...mapActions('editCashflow', ['updateRiseupGoal']),
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('insights', ['markAsRead']),
    close() {
      Segment.trackUserInteraction('EditMonthlyGoalAmount_CloseClicked');
      this.$emit('close');
    },
    triggerOnSaveCb() {
      if (!!this.onSaveCb && typeof this.onSaveCb === 'function') {
        this.onSaveCb();
      }
    },
    onSave() {
      if (this.updatedAmount < 0) {
        this.errorMsg = 'הסכום צריך להיות חיובי';
        Segment.trackUserInteraction(
          'EditMonthlyGoalAmount_NegativeGoalBug',
          { currentGoal: this.riseupGoal, newGoal: this.updatedAmount, isCustom: this.isGoalCustom },
        );
        return;
      }
      if (!this.errorMsg) {
        Segment.trackUserInteraction('EditMonthlyGoalAmount_SaveClicked', {
          identicalToRecommendation: this.updatedAmount === this.cashflow.balancedRiseupGoalRecommendation,
        });
        this.updateRiseupGoal({
          budgetDate: this.presentedBudgetDate,
          amount: this.updatedAmount,
        });
        const insightsToDismiss = this.filteredInsights.filter(insight => insight.insightName === this.INSIGHT_NAMES.SetMonthlyGoalInsight
          || insight.insightName === this.INSIGHT_NAMES.BalancedMonthlyGoalInsight
          || insight.insightName === this.INSIGHT_NAMES.RiseupGoalInsight);
        _.forEach(insightsToDismiss, insight => this.markAsRead(insight));
        this.close();
        this.triggerOnSaveCb();
      }
    },
    onInput(value) {
      if (_.isNil(value) || value <= 0 || _.isNaN(value)) {
        this.updatedAmount = null;
        this.errorMsg = '';
        return;
      }
      this.updatedAmount = value;
      if (value > this.totalAmount) {
        this.errorMsg = 'הסכום צריך להיות נמוך מיתרת ההוצאות המשתנות';
      } else {
        this.errorMsg = '';
      }
    },
    logExpandChanged(isExpanded) {
      Segment.trackUserInteraction('EditMonthlyGoalAmount_HowIsItCalculated_Expanded', { isExpanded });
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/category-color-mixins';

.edit-monthly-amount-popup {

  .title {
    display: flex;
    text-align: right;
    color: $riseup_gray_80;
  }
  .align-left {
    text-align: left;
  }

  .popup-content {
    text-align: right;
  }
}

</style>
