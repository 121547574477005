<template>
  <div >
    <colored-popup :close-action="close" :dark-text="true" :hasFooterSlot="true" layout="no-content-padding">
      <template v-slot:top-content>
        <div class="top-content">
          <div class="title">באילו קטגוריות נתחיל להתמקד?</div>
          <div class="text">לפי ממוצע חודשים קודמים, אלה הקטגוריות שהכי בשימוש אצלך.</div>
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="tracking-categories-popup-container">
          <category-block-with-toggle v-for="category of categories" :category="category" :key="category.name" :toggle-category="toggleCategory"/>
          <riseup-button :action="next"
                         :disabled="numberOfCategoriesAdded === 0"
                         :variant="'primary'"
                         :size="'slim'"
                         title="סיימתי לבחור"/>
        </div>
      </template>
      <template v-slot:footer>
        <div @click="doNotShowAgain">הבנתי, אל תציגו לי יותר</div>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import Segment from '@/Segment';
import { mapActions, mapGetters, mapState } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import CategoryBlockWithToggle
  from '@/pages/responsive-pages/authenticated/navigation-pages/tracking-categories/CategoryBlockWithToggle';
import MoveVariablesMixin
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-reccuring/MoveVariablesMixin';

const DISPLAY = {
  HIDE: 'hide',
  SHOW: 'show',
};

const ECONOMY = 'כלכלה';

export default {
  name: 'TrackingCategoriesPopup',
  mixins: [MoveVariablesMixin],
  created() {
    Segment.trackUserInteraction('TrackingCategoriesPopup_Shown');
    this.categories = this.getCategoriesForSelection();
  },
  components: {
    CategoryBlockWithToggle,
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      categories: [],
    };
  },
  computed: {
    ...mapGetters('cashflowView', ['presentedBudget']),
    ...mapGetters('createTrackingCategory', ['defaultCategories']),
    ...mapState('cashflowView', ['cashflow']),
    addedCategories() {
      return this.categories.filter(c => c.isChecked && !c.isCheckedByDefault);
    },
    numberOfCategoriesAdded() {
      return this.addedCategories.length;
    },
    categoriesToShowPredicate() {
      return c => c.display === DISPLAY.SHOW || c.display === DISPLAY.HIDE || c.name === ECONOMY;
    },
  },
  methods: {
    ...mapActions('createTrackingCategory', ['setCategoryAsShown', 'trackingCategoriesSelectionDone']),
    ...mapActions('editCashflow', ['moveTransaction']),
    toggleCategory(name) {
      const categoryToToggle = _.find(this.categories, c => c.label === name);
      categoryToToggle.isChecked = !categoryToToggle.isChecked;
    },
    getCategoriesForSelection() {
      const categories = _.chain(this.presentedBudget.trackingCategoryMetadata)
        .filter(this.categoriesToShowPredicate)
        .filter(category => _.some(this.defaultCategories, t => t.value === category.name))
        .map(c => {
          const { iconName, icon, frequency, label, value } = _.find(this.defaultCategories, t => t.value === c.name);
          return {
            iconName,
            icon,
            frequency,
            isChecked: c.display === DISPLAY.SHOW || c.name === ECONOMY,
            isCheckedByDefault: c.display === DISPLAY.SHOW || c.name === ECONOMY,
            ...c,
            label,
            value,
          };
        })
        .value();
      const superCategory = _.find(categories, c => c.value === ECONOMY);
      const restOfCategories = _.chain(categories)
        .filter(c => c.value !== ECONOMY)
        .sortBy(c => c.historyAverage)
        .reverse()
        .value();
      return [superCategory, ...restOfCategories];
    },
    async next() {
      Segment.trackUserInteraction('CategoriesPopupDoneClicked', {
        numberOfCategoriesAvailable: this.categories.length,
        numberOfCategoriesAdded: this.numberOfCategoriesAdded,
      });
      for (const c of this.addedCategories) {
        const newTrackingCategory = {
          amount: c.historyAverage,
          icon: c.icon,
          name: c.label,
          frequency: c.frequency,
          categoryId: c.trackingCategoryId,
          trackingCategoryId: c.trackingCategoryId,
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING,
          isCustomPrediction: false,
          defaultAmount: {
            type: 'heuristic',
            config: {
              heuristic: 'default',
            },
          },
        };
        await this.setCategoryAsShown({ newTrackingCategory });
        const { name: categoryName, trackingCategoryId } = newTrackingCategory;
        await this.moveExistingTransactionsFromVariablesToCategory({ categoryName, trackingCategoryId });
      }
      await this.trackingCategoriesSelectionDone();
      this.$emit('save');
    },
    close() {
      this.$emit('close');
    },
    doNotShowAgain() {
      Segment.trackUserInteraction('CategoriesPopupDontShowClicked');
      this.$emit('dont-show');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

  .tracking-categories-popup-container {
    padding: 24px 16px 24px 24px;
  }
  .title {
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    text-align: right;
    margin-bottom: 8px;
  }

  .text {
    font-size: 18px;
    line-height: 24px;
    text-align: right;
  }

  ::v-deep .footer {
    cursor: pointer;
  }
</style>
