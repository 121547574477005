<template>
  <div class="full-width full-height flex-column padding-horizontal-m">
    <div class="margin-top-m">בדיקת העברת חמישה שקלים באמצעות הבנקאות הפתוחה</div>
    <dropdown class="margin-top-ml"
              placeholder="בחירת חשבון בנק"
              :firstSelected="selectedBank"
              :options="bankAccountsOptions"
              :on-select="onSelect" />
    <riseup-button class="margin-top-m" :action="buyVoucher"
                   size="slim"
                   title="לרכישה"
                   :loading="loading"/>

  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import AggregatorApi from '@/api/AggregatorApi';
import Dropdown
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/referral-receive-payments/DropdownInput';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'PaymentInitiationTest',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Dropdown,
  },
  data() {
    return {
      selectedBankLabel: undefined,
      selectedBank: undefined,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('credentials', ['connectedBankCredentials']),
    bankAccountDetails() {
      console.log(this.connectedBankCredentials);
      const obkCreds = this.connectedBankCredentials.filter(item => item.provider === 'aggregator');
      return _.flatMap(obkCreds, bank => {
        const { idNumber } = bank;
        return _.chain(bank.accounts)
          .filter(account => account.sourceType === 'bank')
          .map(account => ({
            ...this.parseBankAccountNumber(account.accountNumberPiiValue),
            idNumber,
            credentialsId: bank.credentialsId,
            bankAccountNumber: account.accountNumberPiiValue,
            source: account.source,
            ibanPiiId: account.ibanPiiId,
            bankCode: bank.bankCode.toString(),
          }))
          .value();
      });
    },
    bankAccountsOptions() {
      const bankAccountsLabels = _.map(this.bankAccountDetails, account => ({
        label: account.bankAccountNumber,
        selectedLabel: account.bankAccountNumber,
      }));

      return bankAccountsLabels;
    },
    selectedBankSource() {
      const existingBankDetails = _.find(this.bankAccountDetails, { bankAccountNumber: this.selectedBankLabel });
      if (!existingBankDetails) {
        return null;
      }
      return existingBankDetails;
    },
  },
  methods: {
    async buyVoucher() {
      // TODO: use this for calling aggregator with selected bank
      this.loading = true;
      const { redirectUrl } = await AggregatorApi.createTestSinglePayment(this.selectedBankSource.credentialsId, this.selectedBankSource.ibanPiiId);
      window.location.href = redirectUrl;
    },
    parseBankAccountNumber(bankAccountNumber) {
      const bankAccountNumberParts = bankAccountNumber.split('-');
      return (bankAccountNumberParts.length >= 1)
        ? {
          branchNumber: bankAccountNumberParts[bankAccountNumberParts.length - 2] ?? '',
          accountNumber: bankAccountNumberParts[bankAccountNumberParts.length - 1],
        } : {};
    },
    onSelect(selectedBankAccount) {
      this.selectedBankLabel = selectedBankAccount.label;
    },
  },
};
</script>

<style scoped lang="scss">

</style>
