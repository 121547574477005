<template>
  <div class="tracking-categories-no-selection-popup">
    <colored-popup :back-action="back" :dark-text="true">

      <template v-slot:top-content>
        <lottie-player src="/animations/no-selection-tracking-categories.json" :autoplay="true" :loop="true"/>
        <div class="title">כרגע לא נוספו קטגוריות חדשות</div>
        <div class="text">אפשר תמיד להוסיף עוד קטגוריות דרך כפתור ״הוספת קטגוריה״ בתזרים,</div>
        <span class="text bold">להציב לעצמך יעד חודשי ולהתחיל לחסוך!</span>
      </template>

      <template v-slot:bottom-content>
        <riseup-button :action="close"
                       :variant="'primary'"
                       :size="'slim'"
                       title="הבנתי תודה"/>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'TrackingCategoriesNoSelectionPopup',
  created() {
    Segment.trackUserInteraction('TrackingCategoriesNoSelectionPopup_Shown');
  },
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  methods: {
    close() {
      this.$emit('close');
    },
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}

.text {
  font-size: 18px;
  line-height: 25px;
  text-align: center;

  .bold {
    font-weight: bold;
  }
}
</style>
