import InviteApi from '../../api/InviteApi';
import DDLogs from '../../DDLogs';
import Segment from '../../Segment';

const state = {
  customerId: '',
  accessToken: null,
  primaryMemberDetails: {},
  noInviteToken: false,
  isLoading: true,
  inviteToken: null,
};

const getters = {
  noAccessToken: state => !state.accessToken,
};

const actions = {
  async init({ commit, getters }, inviteToken) {
    commit('setLoading', true);
    commit('setInviteToken', inviteToken);
    if (!getters.noInviteToken) {
      try {
        const inviteDetails = await InviteApi.getInviteDetails();
        commit('setCustomerId', inviteDetails.customerId);
        commit('setPrimaryMemberDetails', inviteDetails);
        DDLogs.addGlobalContext('customerId', inviteDetails.customerId);
        Segment.identifyCustomer(inviteDetails.customerId);
      } catch (e) {
        if (e.response.status === 403) {
          commit('setInviteToken', null);
        }
      }
    }
    commit('setLoading', false);
  },
};

const mutations = {
  setInviteToken(state, inviteToken) {
    state.inviteToken = inviteToken;
  },
  setPrimaryMemberDetails(state, primaryMemberDetails) {
    state.primaryMemberDetails = primaryMemberDetails;
  },
  setCustomerId(state, customerId) {
    state.customerId = customerId;
  },
  setLoading(state, loading) {
    state.isLoading = loading;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
