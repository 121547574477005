<template>
  <div class="transaction-check-list-item" v-on:click="toggleCheck(transaction)">
    <div class="transaction-details" :class="{ unchecked: !transaction.isChecked }">
      <div class="business-name">
        <span class="date" v-if="showDate">{{ formatDate(transaction.transactionDate) }}</span>
        {{ transaction.businessName }}
      </div>
      <div class="amount">
        <span>{{ formatAmount(transaction.amount) }}</span>
        <span>₪</span>
      </div>
    </div>
    <div class="checkbox">
      <div v-if="!transaction.isChecked" class="circle" :class="{ 'checked': transaction.isChecked }"/>
      <img v-else :src="require('@/assets/icons/icon-check-blue.svg')">
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import moneyUtils from '../utils/money';

export default {
  name: 'TransactionCheckListItem',
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    toggleCheck: {
      type: Function,
      required: true,
    },
    showDate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
    formatDate(date) {
      return moment(date).format('D.M.YY');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.transaction-check-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .transaction-details {
    color: $riseup_black;
    margin: 15px 0;

    .business-name {
      font-size: 12px;
      margin-bottom: 10px;

      .date {
        font-weight: bold;
      }
    }
    .amount {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;

    .circle {
      display: inline-block;
      height: 32px;
      width: 32px;
      vertical-align: middle;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $riseup_gray_2;
      background-color: $riseup_white;
    }
  }
}
</style>
