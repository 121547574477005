import _ from 'lodash';
import CreditCardQuestionsApi from '../../api/CreditCardQuestionsApi';

const state = {
  creditCardExpenses: [],
};

const getters = {
  nonConnectedExpenses: state => _.filter(state.creditCardExpenses, { connected: false }),
  expensesBySource: (state, getters) => _.groupBy(getters.nonConnectedExpenses, 'sourceName'),
};

const actions = {
  async fetchCreditCardsExpenses({ commit }) {
    const creditCardExpenses = await CreditCardQuestionsApi.fetchCreditCardExpenses();
    const questionsWithSourceName = _.filter(creditCardExpenses, question => question.sourceName);
    commit('setCreditCardExpenses', questionsWithSourceName);
  },
};

// mutations
const mutations = {
  setCreditCardExpenses(state, creditCardExpenses) {
    state.creditCardExpenses = creditCardExpenses;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
