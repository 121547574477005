<template>
  <full-screen-modal class="connection-success" :show-riseup-logo="true">
    <template v-slot:content>
      <div class="flex-column flex-1 space-between full-width">
        <div class="connection-success-content">
          <img class="margin-vertical-xl" v-if="isNewBrand" :src="require('@/assets/bank-connection-success.png')"/>
          <lottie-player v-else src="/animations/celebration/success-with-confetti.json" autoplay class="success-lottie"/>
          <div class="title">
            <slot name="title" />
          </div>
          <div class="subtitle">
            <slot name="subtitle" />
          </div>
        </div>
        <div class="full-width margin-vertical-l padding-horizontal-sm" v-if="buttons">
          <riseup-button v-for="(button, key) in buttons" :key="`button-${key}`"
                         class="cta"
                         @click="button.action"
                         v-bind="button"/>
        </div>
      </div>
    </template>
  </full-screen-modal>
</template>

<script>
import FullScreenModal from '@/base-components/FullScreenModal';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ConnectionSuccess',
  components: {
    FullScreenModal,
    RiseupButton: BaseUI.V2Components.RiseupButton,
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    isNewBrand: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/typography';
@import '@/scss/mixins';

.connection-success {
  .connection-success-content {
    color: $riseup_black;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 75%;
    }
    .success {
      margin: 35px 0 40px;
      width: 160px;
      height: 160px;
    }

    .title {
      margin-bottom: 15px;
      font-size: $ri-font-size-24;
      line-height: $ri-font-size-32;
      font-weight: $ri-font-weight-bold;
      font-family: SimplerProAlte, sans-serif;
    }

    .subtitle {
      font-family: SimplerPro;
      font-size: 16px;
    }

    .title, .subtitle{
      padding: 0 24px;
      line-height: 24px;
    }

    .success-lottie {
      height: 200px;
      margin-top: 10px;
    }
  }
  .cta {
    &:not(:first-child) {
      margin-top: $ri-spacing-sm;
    }
  }
}
</style>
