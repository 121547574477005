import _ from 'lodash';
import { client, clientWithoutErrorHandling } from '../http';
import store from '../store';
import router from '../router';

const API_REQUEST_PREFIX = '/api';
const INVITE_AUTH_PATH_PREFIX = `${API_REQUEST_PREFIX}/invite-auth`;

function init() {
  store.dispatch('anonymizeData/initAnonymizeData');
  function addTokenToRequests(config) {
    if (_.startsWith(config.url, INVITE_AUTH_PATH_PREFIX) && !_.isNil(store.state.inviteSession.inviteToken)) {
      // eslint-disable-next-line no-param-reassign
      config.headers = _.extend({}, config.headers, { 'RISEUP-ACCESS-TOKEN': store.state.inviteSession.inviteToken });
    }
    // eslint-disable-next-line
    config.headers = _.extend({}, config.headers, { 'COMMIT-HASH': __COMMIT_HASH__ });

    return config;
  }

  function handleError(error) {
    if (!error.response) {
      // Network error
      store.dispatch('genericError/setErrorType', store.state.genericError.ERROR_TYPES.NETWORK_ERROR);
    } else {
      const { status } = error.response;
      if (status === 502) {
        store.dispatch('genericError/setErrorType', store.state.genericError.ERROR_TYPES.SERVER_IS_DOWN);
      } else if (status === 403) {
        if (isMemberInvite()) {
          store.dispatch('genericError/setErrorType', store.state.genericError.ERROR_TYPES.INVITE_MEMBER_ERROR);
        } else {
          store.dispatch('loader/clearLoader');
          const query = { redirectTo: _.trimStart(window.location.pathname, '/'), ...router.currentRoute.query };
          const qs = new URLSearchParams(_.toPairs(query)).toString();
          /*
           * We reload the page and not use Vue Router to redirect to the login page
           * to make sure that all other HTTP requests are cancelled and won't
           * cause additional redirects after we navigate
           */
          window.location.assign(`/login?${qs}`);
        }
      } else if (status === 500) {
        store.dispatch('genericError/setErrorType', store.state.genericError.ERROR_TYPES.SERVER_ERROR);
      }
    }
    return Promise.reject(error);
  }

  function handleSuccess(response) {
    if (store.state.anonymizeData.enabled && response.config.url.startsWith('/api')) {
      const fields = ['name', 'businessName', 'accountNumberPiiValue', 'firstNamePiiValue', 'lastNamePiiValue'];
      replaceFieldsInPlace(response.data, fields, store.state.anonymizeData.pairs);
    }
    return response;
  }

  function replaceFieldsInPlace(obj, fields, pairs) {
    if (Array.isArray(obj)) {
      // If the current property is an array, iterate through its elements
      obj.forEach(item => replaceFieldsInPlace(item, fields, pairs));
    }
    if (typeof obj === 'object' && obj !== null) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          // Recursive call for nested objects
          replaceFieldsInPlace(obj[key], fields, pairs);
        } else if (_.includes(fields, key)) {
          // Replace the values
          _.forEach(pairs, pair => {
            obj[key] = obj[key].replaceAll(pair.from, pair.to); // eslint-disable-line
          });
        }
      }
    }
  }

  // If the customer is in /demo, all request will contain the isDemo flag, so the backend will return demo data.
  // All non-GET requests will be cancelled, as they are not supported in the backend.
  function demoCashflowInterceptor(config) {
    if (router.currentRoute.name === 'Demo') {
      config.headers = _.extend(config.headers, { // eslint-disable-line
        'x-show-demo-data': true,
      });
      if (_.toLower(config.method) !== 'get') {
        return { ...config, method: 'get', url: '/api/logged-in', data: undefined };
      }
    }
    return config;
  }

  // If the customer are in the insights-viewer page we want to block api calls because it's just a page to view mocked insights
  function internalInsightViewerInterceptor(config) {
    if (router.currentRoute.name === 'InsightsViewer') {
      // This will block the request indefinitely
      return new Promise(() => {});
    }
    return config;
  }

  client.interceptors.request.use(addTokenToRequests);
  client.interceptors.request.use(demoCashflowInterceptor);
  client.interceptors.request.use(internalInsightViewerInterceptor);
  clientWithoutErrorHandling.interceptors.request.use(addTokenToRequests);

  client.interceptors.response.use(
    handleSuccess,
    handleError,
  );

  function isMemberInvite() {
    return router.currentRoute.name === 'MemberInvite';
  }
}

export default {
  init,
};
