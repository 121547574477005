<template>
  <advisory-step :step="currentStep" @next="next" @back="back" @close="close" />
</template>

<script>
import Segment from '@/Segment';
import AdvisoryStep from '../AdvisoryStep';

export default {
  name: 'AdvisorySteps',
  components: {
    AdvisoryStep,
  },
  props: {
    backwards: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    Segment.trackUserGot('AdvisoryPackageSteps');
  },
  data() {
    return {
      index: this.backwards ? 4 : 0,
    };
  },
  computed: {
    steps() {
      return [
        {
          number: 1,
          header: 'שלב 1',
          title: 'בשלב הראשון, ננתח ביחד את התזרים שלך ונזהה הזדמנויות לשיפור',
          subTitle: 'בפגישת זום עם אחד או אחת מיועצי התזרים שלנו.',
          image: 'advisory-cashflow',
          preventImagePadding: true,
        },
        {
          number: 2,
          header: 'שלב 2',
          title: 'בשלב הבא, נגדיר חלומות ומטרות',
          subTitle: 'נזמין אותך לענות על שאלון מיוחד שיעזור לנו להבין מה המטרות, הצרכים והשאיפות שלך לעתיד.',
          image: 'advisory-dreams',
          preventImagePadding: true,
        },
        {
          number: 3,
          header: 'שלב 3',
          title: 'נעשה לך היכרות עם הכסף שלך',
          subTitle: 'נעבור על כל החסכונות, הקופות, הקרנות והפקדונות שלך. נבין איפה יש כסף ומתי הוא יהיה זמין לשאיפות ולמטרות שנגדיר בתוכנית.',
          image: 'advisory-plan',
        },
        {
          number: 4,
          header: 'שלב 4',
          title: 'נבנה יחד תוכנית מסודרת וברורה',
          subTitle: 'בפגישת זום, אחד מהיועצים או היועצות שלנו יבנו ביחד איתך תוכנית פרקטית, שתיקח אותך צעד אחר צעד למטרות שהגדרת.',
          image: 'advisory-meeting',
        },
        {
          number: 5,
          header: 'שלב 5',
          title: 'נוודא שהכל במסלול הנכון',
          subTitle: 'ניפגש אחרי כמה חודשים כדי לראות שהתוכנית מתאימה לך ושהכל הולך חלק.',
          image: 'advisory-followup',
        },
      ];
    },
    currentStep() {
      return this.steps[this.index];
    },
  },
  methods: {
    next() {
      if (this.index === 4) {
        this.$emit('next');
      } else {
        this.index++;
        Segment.trackUserGot(`AdvisoryPackageSteps_Step${this.currentStep.number}`);
      }
    },
    back() {
      if (this.index === 0) {
        this.$emit('back');
      } else {
        this.index--;
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
