import { render, staticRenderFns } from "./BaseAmbassadorsCard.vue?vue&type=template&id=691bd0ef&scoped=true"
import script from "./BaseAmbassadorsCard.vue?vue&type=script&lang=js"
export * from "./BaseAmbassadorsCard.vue?vue&type=script&lang=js"
import style0 from "./BaseAmbassadorsCard.vue?vue&type=style&index=0&id=691bd0ef&prod&lang=scss&scoped=true"
import style1 from "./BaseAmbassadorsCard.vue?vue&type=style&index=1&id=691bd0ef&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691bd0ef",
  null
  
)

export default component.exports