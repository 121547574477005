<template>
  <insight :insight="insight"
           image-name="confident.png"
           button-text="רוצה לראות!"
           :show-dismiss="false"
           :button-action="navigateToDataCenter">
    <template v-slot:insight-text>
      היי {{firstName}}, לפני שנצלול למספרים, אפשר לבצע סריקת נתונים, ולגלות  איך אפשר להתחיל לצמוח!
    </template>
  </insight>
</template>

<script>
import { mapGetters } from 'vuex';
import Insight from './components/Insight';

export default {
  name: 'MyProgressInsight',
  components: {
    Insight,
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapGetters('session', ['firstName']),
  },
  methods: {
    async navigateToDataCenter() {
      this.$router.push({ path: '/data-center' });
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/spacings.scss';
@import '@riseupil/base-ui/src/scss/riseup-colors.scss';

::v-deep .top-image {
  height: 120px;
  background-color: $riseup_green;
  background-repeat: no-repeat;
  object-fit: cover;
  padding: 0 $ri-spacing-xxl;
}
</style>
