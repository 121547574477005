const PROMO_CODES = {
  DISCOUNT: 'discount',
};

const PROMOTION_NAMES = {
  BAT_YAM: 'bat-yam-first-month-free',
  DISCOUNT_TWO_MONTHS_FREE: 'discount-two-months-free',
  DISCOUNT_ONE_MONTH_FREE: 'discount-1-month-free-months',
  DISCOUNT_TWO_MONTHS_FREE_BY_CREDS: 'discount-two-months-free-by-creds',
  NEVO_FIRST_MONTH_FREE: 'nevo-first-month-free',
  FRIENDS_TWO_MONTHS_FREE: 'friends-two-months-free',
  NEW_CREDIT_TWO_MONTHS_FREE: 'new-credit-two-months-free',
  TARYA_12_MONTHS_FREE: 'tarya-12-months-free',
  HAYANSHUF_FIRST_MONTH_FREE: 'hayanshuf-first-month-free',
  TALNAHMAN_FIRST_MONTH_FREE: 'talnahman-first-month-free',
  ISRACARD_FIRST_MONTH_FREE: 'isracard-first-month-free',
  ALEF_TWO_MONTHS_FREE: 'aleph-two-months-free',
  JLM_2_MONTHS_FREE: 'JLM-1K2M-2-months-free',
  NETAFIM_2_MONTHS_FREE: 'NETAFIM2-2-months-free',
  YAHAV_2_MONTHS_FREE: 'yahav-2-months-free',
  ASHMORET_FIRST_MONTH_FREE: 'ashmoret-1-month-free',
  HAREL_5_MONTHS_FREE: 'harel-5-months-free',
  ALLJOBS_2_MONTHS_FREE: 'alljobs_vip-2-months-free',
  ALLJOBS_1_MONTH_FREE: 'alljobs-1-month-free',
  DEFAULT_PROMOTION: 'default-promotion',
  DEFAULT_PERCENTAGE_DISCOUNT: 'percentage-discount',
  TWENTY_ONE_DAYS: '21DAYS-1-month-free',
  'STUDENT.P': 'STUDENT.P-11-months-free',
  YES: 'yes-4-months-percentage-discount',
  TRIMA: 'Trima-Employees-4-months-percentage-discount',
  ARIEL: 'Ariel-Azuelos-4-months-percentage-discount',
  MONEYMOON_PROMOTIONS: 'moneymoon-2-months-percentage-discount',
  HEVER_1: 'byxapvuz-6-months-percentage-discount',
  HEVER_2: 'ryacdzro-6-months-percentage-discount',
  HEVER_3: 'cldtluza-6-months-percentage-discount',
  HEVER_4: 'zcblmcjp-6-months-percentage-discount',
  HEVER_5: 'wkkjufwu-6-months-percentage-discount',
  HEVER_6: 'dhqbenvi-6-months-percentage-discount',
  HEVER_7: 'tfxejgbv-6-months-percentage-discount',
  HEVER_8: 'roqdkfuy-6-months-percentage-discount',
  HEVER_9: 'pgfxtzej-6-months-percentage-discount',
  HEVER_10: 'kazzuchpt-6-months-percentage-discount',
  HUNDRED_DAYS_PROMOTION: /on-it-.*-0-months-free-months/,
};

const PROMOTION_TYPES = {
  ONE_TIME: 'one-time',
};

const BENEFIT_TYPES = {
  FREE_MONTHS: 'free-months',
  PERCENTAGE_DISCOUNT: 'percentage-discount',
};

const BENEFIT_CREATION_STATUS = {
  SUCCESS: 'success',
  EXISTING_NON_REDEEMED_BENEFIT: 'existing-non-redeemed-benefit',
  CUSTOMER_LIMIT_EXCEEDED: 'customer-limit-exceeded',
};

const FULL_MONTH_PRICE = 45;

const percentagePromoInfo = (numOfMonths, percentage) => {
  const price = FULL_MONTH_PRICE - (FULL_MONTH_PRICE * (percentage / 100));
  switch (numOfMonths) {
  case 1:
    return { price, text: 'חודש', extraText: 'נוסף' };
  case 2:
    return { price, text: 'חודשיים', extraText: 'נוספים' };
  default:
    return { price, text: `${numOfMonths} חודשים`, extraText: 'נוספים' };
  }
};

const PROMOTION_DETAILS = {
  [PROMOTION_NAMES.BAT_YAM]: {
    header: 'promotions/bat-yam/batyam-header.svg',
    title: () => 'תושבי בת-ים, מצטרפים לשירות ונהנים מחודש נוסף במנוי מתנה!',
    detailsTitle: 'עוד על ההטבה לתושבי בת-ים:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'נרשמים למנוי בתשלום ב-₪45',
      'נהנים מחודש נוסף מתנת עירית בת-ים',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.DISCOUNT_TWO_MONTHS_FREE]: {
    welcomeHeader: 'promotions/discount/discount_welcome_header_new.png',
    header: 'promotions/discount/discount-header.svg',
    welcomeTitle: () => 'לקוחות דיסקונט כבר מנצחים את החודש עם riseup. עכשיו תורך!',
    title: () => 'לקוחות דיסקונט נהנים מחודשיים בשירות בחינם!',
    detailsTitle: 'הטבה בלעדית ללקוחות דיסקונט:',
    details: () => [
      'חודש נסיון חינם',
      'נרשמים למנוי ונהנים מחודש נוסף במתנה',
      'משלמים רק מהחודש השלישי לשימוש, ₪45 לחודש',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.DISCOUNT_ONE_MONTH_FREE]: {
    welcomeHeader: 'promotions/discount/discount_welcome_header_new.png',
    header: 'promotions/discount/discount-header.svg',
    welcomeTitle: () => 'לקוחות דיסקונט כבר מנצחים את החודש עם riseup. עכשיו תורך!',
    title: () => 'לקוחות דיסקונט נהנים מחודשיים בשירות בחינם!',
    detailsTitle: 'הטבה בלעדית ללקוחות דיסקונט:',
    details: () => [
      'חודש נסיון חינם',
      'נרשמים למנוי ונהנים מחודש נוסף במתנה',
      'משלמים רק מהחודש השלישי לשימוש, ₪45 לחודש',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.NEVO_FIRST_MONTH_FREE]: {
    header: 'promotions/nevo/nevo_header.svg',
    title: () => 'משתתפי הקורס נהנים מחודשיים ראשונים בשירות חינם!',
    detailsTitle: 'הטבה למשתתפי הקורס rising up together:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'חודש נוסף במנוי ללא תשלום',
      'משלמים רק מהחודש השלישי לשימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.NEW_CREDIT_TWO_MONTHS_FREE]: {
    header: 'promotions/new-credit/newcredit_header.svg',
    title: () => 'לקוחות NewCredit נהנים מחודשיים ראשונים בשירות חינם!',
    detailsTitle: 'הטבה ללקוחות NewCredit:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'חודשיים נוספים במנוי ללא תשלום',
      'משלמים רק מהחודש הרביעי לשימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.FRIENDS_TWO_MONTHS_FREE]: {
    welcomeHeader: 'promotions/friends/friends-welcome-header.png',
    header: 'promotions/friends/friends-header.png',
    title: () => 'החברים של riseup נהנים מ-3 חודשים בשירות חינם!',
    welcomeTitle: () => 'החברים של riseup נהנים מ-3 חודשים בשירות חינם!',
    detailsTitle: 'הטבה בלעדית לחברים של riseup:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'חודשיים נוספים במנוי חינם',
      'משלמים רק מהחודש הרביעי לשימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.TARYA_12_MONTHS_FREE]: {
    header: 'promotions/tarya/tarya-header.svg',
    title: () => 'לקוחות טריא מצטרפים ונהנים משנה ראשונה בשירות חינם!',
    detailsTitle: 'הטבה בלעדית ללקוחות טריא:',
    details: () => [
      'חודש ראשון נסיון חינם',
      '12 חודשים ללא תשלום',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'לאחר סוף ההטבה, תשלום חודשי של ₪45',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.HAYANSHUF_FIRST_MONTH_FREE]: {
    header: 'promotions/hayanshuf/hayanshuf-header.svg',
    title: () => 'לקוחות הינשוף מצטרפים ונהנים מחודש נוסף בשירות מתנה!',
    detailsTitle: 'הטבה בלעדית ללקוחות הינשוף:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'נרשמים למנוי ונהנים מחודש נוסף מתנת הינשוף',
      'לאחר סוף ההטבה, תשלום חודשי של ₪45 ',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.TALNAHMAN_FIRST_MONTH_FREE]: {
    header: 'promotions/friends/friends-header.svg',
    title: () => 'הלקוחות של טל נחמן נהנים מחודשיים בשירות חינם!',
    detailsTitle: 'הטבה בלעדית ללקוחות של טל נחמן:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'נרשמים למנוי ונהנים מחודש נוסף מתנת טל נחמן',
      'לאחר סוף ההטבה, תשלום חודשי של ₪45 ',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.ISRACARD_FIRST_MONTH_FREE]: {
    header: 'promotions/isracard/isracard-header.svg',
    welcomeHeader: 'promotions/isracard/isracard-welcome-header.png',
    title: () => 'לקוחות ישראכרט מצטרפים ונהנים מחודשיים מתנה!',
    welcomeTitle: () => 'לקוחות ישראכרט נהנים מחודשיים מתנה בשירות של riseup!',
    detailsTitle: 'הטבה ללקוחות ישראכרט:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'חודש נוסף במנוי ללא תשלום',
      'משלמים רק מהחודש השלישי לשימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.ALEF_TWO_MONTHS_FREE]: {
    header: 'promotions/aleph/aleph-header.svg',
    title: () => ' Aleph מעניקה לך 3 חודשים בשירות בחינם!',
    detailsTitle: 'הטבה בלעדית מ-Aleph:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'חודשיים נוספים במנוי במתנה',
      'משלמים רק מהחודש הרביעי לשימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.HAREL_5_MONTHS_FREE]: {
    header: 'promotions/harel/harel-header.png',
    welcomeHeader: 'promotions/harel/harel-welcome-header.png',
    title: () => ' לקוחות הראל מצטרפים ונהנים מחצי שנה מתנה!',
    welcomeTitle: () => 'לקוחות הראל נהנים מחצי שנה מתנה בשירות של riseup!',
    detailsTitle: 'הטבה ללקוחות הראל:',
    details: () => [
      'חודש ראשון נסיון חינם',
      '5 חודשים נוספים במנוי במתנה',
      'משלמים רק מהחודש השביעי לשימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.JLM_2_MONTHS_FREE]: {
    header: 'promotions/jerusalem/jerusalem-header.svg',
    title: () => 'ירושלמים מצטרפים לשירות ונהנים מ-3 חודשים ראשונים במתנה!',
    detailsTitle: 'הטבה לתושבי ותושבות ירושלים:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'חודשיים נוספים במנוי במתנה',
      'משלמים רק מהחודש הרביעי לשימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.NETAFIM_2_MONTHS_FREE]: {
    header: 'promotions/netafim/netafim_header.png',
    title: () => 'עובדי ועובדות נטפים נהנים משלושה חודשים ראשונים במתנה',
    detailsTitle: 'הטבה לעובדי ועובדות נטפים',
    details: () => [
      'חודש ראשון נסיון חינם',
      'חודשיים נוספים במנוי במתנה',
      'משלמים רק מהחודש הרביעי לשימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.ASHMORET_FIRST_MONTH_FREE]: {
    header: 'promotions/ashmoret/ashmoret_header.svg',
    title: () => 'חברי וחברות מועדון אשמורת נהנים מחודשיים ראשונים במתנה',
    detailsTitle: 'הטבה לחברי וחברות מועדון אשמורת:',
    details: () => [
      'חודש ראשון נסיון חינם',
      'חודש נוסף במנוי ללא תשלום',
      'משלמים רק מהחודש השלישי לשימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES['STUDENT.P']]: {
    header: 'promotions/generic-signup-header.png',
    welcomeHeader: 'promotions/generic-welcome-header.png',
    welcomeTitle: months => `מגיעה לך הטבה בלעדית, ${months} ראשונים עם riseup במתנה`,
    title: months => ` מצטרפים ל-riseup ונהנים מ${months} ראשונים במתנה!`,
    detailsTitle: 'כמה פרטים על ההטבה:',
    details: mothsFreeText => [
      'חודש ראשון נסיון חינם',
      mothsFreeText,
      ' משלמים רק לאחר שנה של שימוש, ₪45 לחודש',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.TWENTY_ONE_DAYS]: {
    header: 'promotions/21-days/21-days-promo-header.png',
    welcomeHeader: 'promotions/21-days/21-days-promo-welcome-header.png',
    welcomeTitle: months => `מגיעה לך הטבה בלעדית, ${months} ראשונים עם riseup במתנה`,
    title: months => ` מצטרפים ל-riseup ונהנים מ${months} ראשונים במתנה!`,
    detailsTitle: 'כמה פרטים על ההטבה:',
    details: (mothsFreeText, firstPayingMonth) => [
      'חודש ראשון נסיון חינם',
      mothsFreeText,
      ` משלמים רק מהחודש ${firstPayingMonth} לשימוש, ₪45 לחודש`,
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },
  [PROMOTION_NAMES.ALLJOBS_2_MONTHS_FREE]: {
    header: 'promotions/alljobs/alljobs-welcome.png',
    welcomeHeader: 'promotions/alljobs/alljobs-3-mo-image.png',
    welcomeTitle: months => 'לקוחות AllJobs מתחילים לשלוט בהוצאות ולחסוך עם RiseUp!',
    title: months => 'לקוחות AllJobs נהנים משלושה חודשים בשירות בחינם!',
    detailsTitle: '3 חודשים ראשונים ב-RiseUp חינם!',
    details: (mothsFreeText, firstPayingMonth) => [
      ` משלמים רק מהחודש ${firstPayingMonth} לשימוש, ₪45 לחודש`,
      'בלי התחייבות ואפשר לבטל בכל רגע',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון (35 יום)',
    ],
  },
  [PROMOTION_NAMES.ALLJOBS_1_MONTH_FREE]: {
    header: 'promotions/alljobs/alljobs-welcome.png',
    welcomeHeader: 'promotions/alljobs/alljobs-2-mo-image.png',
    welcomeTitle: months => 'לקוחות AllJobs מתחילים לשלוט בהוצאות ולחסוך עם RiseUp!',
    title: months => 'לקוחות AllJobs נהנים מחודשיים בשירות בחינם!',
    detailsTitle: 'חודשיים ראשונים ב-Riseup חינם!',
    details: (mothsFreeText, firstPayingMonth) => [
      ` משלמים רק מהחודש ${firstPayingMonth} לשימוש, ₪45 לחודש`,
      'בלי התחייבות ואפשר לבטל בכל רגע',
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון (35 יום)',
    ],
  },
  [PROMOTION_NAMES.DEFAULT_PROMOTION]: {
    header: 'promotions/generic-signup-header.png',
    welcomeHeader: 'promotions/generic-welcome-header.png',
    welcomeTitle: months => `מגיעה לך הטבה בלעדית, ${months} ראשונים עם riseup במתנה`,
    title: months => ` מצטרפים ל-riseup ונהנים מ${months} ראשונים במתנה!`,
    detailsTitle: 'כמה פרטים על ההטבה:',
    details: (mothsFreeText, firstPayingMonth) => [
      'חודש ראשון נסיון חינם',
      mothsFreeText,
      ` משלמים רק מהחודש ${firstPayingMonth} לשימוש, ₪45 לחודש`,
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  },

  [PROMOTION_NAMES.DEFAULT_PERCENTAGE_DISCOUNT]: {
    header: 'promotions/generic-signup-header.png',
    welcomeHeader: 'introduction/intro_image_new_smaller.png',
    name: '',
  },

  [PROMOTION_NAMES.YES]: {
    header: 'promotions/percentage-discount/yes.png',
    welcomeHeader: 'promotions/percentage-discount/yes-welcome.png',
    name: 'עובדי yes',
  },

  [PROMOTION_NAMES.TRIMA]: {
    header: 'promotions/percentage-discount/trima.png',
    welcomeHeader: 'promotions/percentage-discount/trima-welcome.png',
    name: 'עובדי תרימה',
  },

  [PROMOTION_NAMES.ARIEL]: {
    header: 'promotions/percentage-discount/ariel.png',
    welcomeHeader: 'promotions/percentage-discount/ariel-welcome.png',
    name: 'לקוחות אריאל אזואלוס',
  },

  [PROMOTION_NAMES.MONEYMOON_PROMOTIONS]: {
    header: 'promotions/generic-signup-header.png',
    welcomeHeader: 'promotions/moneymoon.svg',
    overrideWelcomeTitle: 'כיף שבחרת להצטרף לתכנית ה-MoneyMoon של רייזאפ!'
      + ' מגיע לך חודש חינם ברייזאפ ועוד חודשיים ב-50%.',
    overrideTitle: 'כיף שבחרת להצטרף לתכנית ה-MoneyMoon של רייזאפ!'
      + ' מגיע לך חודש חינם ברייזאפ ועוד חודשיים ב-50%.',
  },

  [PROMOTION_NAMES.HEVER_1]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },
  [PROMOTION_NAMES.HEVER_2]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },
  [PROMOTION_NAMES.HEVER_3]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },
  [PROMOTION_NAMES.HEVER_4]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },
  [PROMOTION_NAMES.HEVER_5]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },
  [PROMOTION_NAMES.HEVER_6]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },
  [PROMOTION_NAMES.HEVER_7]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },
  [PROMOTION_NAMES.HEVER_8]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },
  [PROMOTION_NAMES.HEVER_9]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },
  [PROMOTION_NAMES.HEVER_10]: {
    header: 'promotions/hever/hever-header.png',
    welcomeHeader: 'promotions/hever/hever-welcome-header.png',
    name: 'חברי מועדון חבר',
  },

};

function getPromoWithPercentageDiscount({ header, welcomeHeader, name, numberOfMonths, percentage, overrideTitle, overrideWelcomeTitle }) {
  const { text, extraText, price } = percentagePromoInfo(numberOfMonths, percentage);
  const title = name
    ? `החודש הראשון עלינו. בנוסף, ${text} ב-${percentage}% הנחה${name ? `, רק ל${name}`
      : ''}!` : `החודש הראשון עלינו. בנוסף, ${percentage}% הנחה על ${text} ${extraText}!`;
  return ({
    header,
    welcomeHeader,
    welcomeTitle: () => overrideWelcomeTitle || `מגיעה לך הטבה בלעדית, חודש ראשון חינם ב-RiseUp ו${text} ${extraText} ב-${percentage}% הנחה!`,
    title: overrideTitle || title,
    detailsTitle: 'כמה פרטים על ההטבה:',
    details: [
      'חודש ראשון נסיון חינם',
      ` בסוף תקופת הניסיון אפשר לבחור אם להמשיך למנוי בתשלום. ${text} ב-₪${price} (${percentage}% הנחה) ולאחר מכן, ₪45 לחודש `,
      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הנסיון',
      'בלי התחייבות ואפשר לבטל בכל שלב',
    ],
  });
}

function getPromotionDetails(promotion) {
  return PROMOTION_DETAILS[promotion] ? PROMOTION_DETAILS[promotion]
    : PROMOTION_DETAILS[PROMOTION_NAMES.DEFAULT_PROMOTION];
}

function getPercentageDiscountPromotionDetails(promotion, numberOfMonths, percentage) {
  const promotionDetails = PROMOTION_DETAILS[promotion]
    ? PROMOTION_DETAILS[promotion]
    : PROMOTION_DETAILS[PROMOTION_NAMES.DEFAULT_PERCENTAGE_DISCOUNT];
  return getPromoWithPercentageDiscount({ ...promotionDetails, numberOfMonths, percentage });
}

export default {
  PROMOTION_TYPES,
  PROMOTION_NAMES,
  PROMOTION_DETAILS,
  PROMO_CODES,
  BENEFIT_TYPES,
  BENEFIT_CREATION_STATUS,
  getPromotionDetails,
  getPercentageDiscountPromotionDetails,
  FULL_MONTH_PRICE,
};
