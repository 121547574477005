<template>
  <insight :insight="insight"
           animation-name="MonthlySaving.json"
           button-text="זאת הפקדה לחיסכון"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        המערכת זיהתה טעינה <span class="bold"> לפייבוקס. אם זאת הפקדה לחיסכון, כל הכבוד</span>! כדאי להעביר אותה לקטגורית חיסכון.
      </div>
    </template>
  </insight>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import MoveTransactionToSaving
  from '../cashflow-view/current-month/components/cashflow-edit/edit-transaction/edit-saving-transaction/MoveTransactionToSaving';

export default {
  name: 'BoxToSavingsInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('BoxToSavingsInsightShown');
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal']),
    async buttonAction() {
      this.openModal({
        component: MoveTransactionToSaving,
        props: {
          transaction: this.insight,
          categoryName: cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE,
        },
      });
      await this.markAsRead(this.insight);
    },
  },
};
</script>

<style lang="scss" scoped>

.bold {
  font-weight: bold;
}

</style>
