<template>
  <dynamic-deposit-step :authenticated="false" :step="this.$options.name">
    <template v-slot:content>
      <div class="dd-headline-label ri-bold-body">הזדהות</div>
        <div class="ri-large-headline margin-bottom-s">מה הקוד שקיבלת {{ otpSentVia }}?</div>
        <default-text-input
                          :autofocus="false"
                          :label="'קוד חד-פעמי'"
                          :value="OTPInput"
                          :error-message="errorMessage"
                          input-type="tel"
                          @input="setOTP" />
    </template>
    <template v-slot:next-button>
      <dynamic-deposit-button title="הבא >" :action="verifyOTPAndContinue" :loading="loading" :disabled="!OTPInput || !!errorMessage"/>
      <div class="resend-code" v-on:click="resendAuthCode">שלחו לי קוד חדש</div>
    </template>
  </dynamic-deposit-step>
</template>

<script>

import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import DDLogs from '@/DDLogs';
import * as SavingsApi from '@/api/SavingsApi';
import LoginApi from '@/api/LoginApi';
import { mapGetters } from 'vuex';
// import featureFlags from '@/api/featureFlag';
import DynamicDepositStep from './DynamicDepositStep';
import DynamicDepositButton from './DynamicDepositButton';

export default {
  name: 'DDEnterOTP',
  components: {
    DynamicDepositButton,
    DefaultTextInput: BaseUI.DefaultTextInput,
    DynamicDepositStep,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    showErrorPage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      OTPInput: '',
      errorMessage: '',
      sendCodeViaSMS: false,
    };
  },
  async created() {
    Segment.trackUserInteraction('DynamicDeposit_EnterOTP_Entered');
    // this.sendCodeViaSMS = await featureFlags.getAnonymousTreatment('sms-otp') === 'on';
  },
  computed: {
    ...mapGetters('dynamicSavings', ['idNumber']),
    ...mapGetters('session', ['activeMemberPhoneNumber']),
    otpSentVia() {
      return this.sendCodeViaSMS ? 'בסמס' : 'בוואטסאפ';
    },
  },
  methods: {
    setOTP(otp) {
      this.errorMessage = '';
      this.OTPInput = otp;
    },
    resendAuthCode() {
      Segment.trackUserInteraction('DynamicDeposit_EnterOTP_ResendClicked');
      this.setOTP('');
      LoginApi.generateCode({ phoneNumber: this.activeMemberPhoneNumber.replaceAll('-', ''), force: true }).catch(error => {
        DDLogs.error('Failed resending otp code');
      });
    },
    async verifyOTPAndContinue() {
      Segment.trackUserInteraction('DynamicDeposit_EnterOTP_NextClicked');
      this.loading = true;
      try {
        const response = await SavingsApi.authenticate(this.idNumber, this.OTPInput);
        if (response.status === SavingsApi.STATUS.SUCCESS) {
          Segment.trackUserInteraction('DynamicDeposit_EnterOTP_ValidOTP');
          this.nextStep();
        } else if (response.data === SavingsApi.AUTHENTICATION_ERROR_TYPES.INVALID_CODE) {
          Segment.trackUserInteraction('DynamicDeposit_EnterOTP_InvalidOTP');
          this.errorMessage = 'הקוד שהזנת לא תקין';
        } else if (response.data === SavingsApi.AUTHENTICATION_ERROR_TYPES.CODE_EXPIRED) {
          Segment.trackUserInteraction('DynamicDeposit_EnterOTP_ExpiredOTP');
          this.errorMessage = 'הקוד פג תוקף';
        } else {
          this.showErrorPage();
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.resend-code{
  margin-top: 40px;
  text-decoration: underline;
}
</style>
