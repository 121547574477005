import moment from 'moment';
import SubscriptionStateApi from '@/api/SubscriptionStateApi';
import featureFlags from '@/api/featureFlag';

async function isDormantWinback() {
  const MONTHS_UNTIL_WINBACK_EXPERIENCE = 3;
  const [{ isDormantWinback, since }, treatment] = await Promise.all([
    SubscriptionStateApi.simplifiedSubscriptionState(),
    featureFlags.getTreatment('dormant-winback'),
  ]);
  const applicable = isDormantWinback && since < moment().subtract(MONTHS_UNTIL_WINBACK_EXPERIENCE, 'month').format();
  const featureFlagIsOff = treatment === 'off';
  return applicable && !featureFlagIsOff;
}

export default isDormantWinback;
