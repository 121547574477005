import Vue from 'vue';
import _ from 'lodash';
import AccountInvestigatorApi from '../../api/AccountInvestigatorApi';
import consts from '../utilities/constants';

const state = {
  accountQuestions: [],
};

const getters = {
  noIncludedAccounts: state => _.isEmpty(_.filter(state.accountQuestions, { isIncluded: true })),
  accountInputs: state => ({ [consts.INPUT_TYPES.ACCOUNT_INPUT]: _.map(state.accountQuestions, _extractAccountInput) }),
};

const actions = {
  async fetchAccounts({ commit, state }) {
    const accountQuestions = await AccountInvestigatorApi.fetchAccountInvestigationOnboardingQuestions();
    const defaultIncluded = accountQuestions.length === 1;
    const accountQuestionsWithDefault = _.map(accountQuestions, accountQuestion => ({ isIncluded: defaultIncluded, ...accountQuestion }));
    commit('setAccountQuestions', accountQuestionsWithDefault);
  },
};

const mutations = {
  setAccountQuestions(state, accountQuestions) {
    state.accountQuestions = accountQuestions;
  },
  toggleAccountIsIncluded(state, id) {
    const accountQuestion = _.find(state.accountQuestions, { _id: id });
    const isIncluded = !accountQuestion.isIncluded;
    Vue.set(accountQuestion, 'isIncluded', isIncluded);
  },
};

function _extractAccountInput(accountQuestion) {
  return {
    account: accountQuestion.accountNumberPiiValue,
    accountNumberPiiId: accountQuestion.accountNumberPiiId,
    accountStatus: accountQuestion.isIncluded ? 'include' : 'exclude',
    source: accountQuestion.source,
    sourceType: accountQuestion.sourceType,
  };
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
