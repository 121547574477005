import { client } from '@/http';

async function getBenchmark(category) {
  const response = await client.get(`/api/benchmark/${category}`);
  return response.data;
}

export default {
  getBenchmark,
};
