<template>
  <insight class="zero-balance"
           :insight="insight"
           :animation-name="insightParams.animationName"
           :button-action="buttonAction"
           :button-text="'הבנתי, תודה'">
    <template v-slot:insight-text>
      <div class="text">
        <span>זיהינו שה{{ insightParams.expenseOrIncomeText }} הקבועה {{ insightParams.fromOrTo }}</span>
        <span class="business-name">"{{ shortenedBusinessName }}"</span>
        בסכום של
        <span class="amount">{{ formattedAmount }} ₪</span> לא
        {{ insightParams.expenseOrIncome }}<span v-if="hasRemainingWeeklyVariables">, יתרת המשתנות
        {{ insightParams.variableBalanceText }} בהתאם</span>
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';
import Insight from './components/Insight';

export default {
  name: 'ZeroBalanceInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapGetters('cashflowView', ['remainingWeeklyVariables']),
    amountDifference() {
      return Math.abs(this.insight.originalAmount - this.insight.balancedAmount);
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.amountDifference, 0);
    },
    insightParams() {
      if (this.insight.isIncome) {
        return {
          variableBalanceText: 'קטנה',
          expenseOrIncomeText: 'הכנסה',
          fromOrTo: 'מ',
          expenseOrIncome: 'נכנסה',
          animationName: 'zero-balance-income.json',
        };
      }
      return {
        variableBalanceText: 'גדלה',
        expenseOrIncomeText: 'הוצאה',
        fromOrTo: 'על',
        expenseOrIncome: 'ירדה',
        animationName: 'zero-balance-expense.json',
      };
    },
    shortenedBusinessName() {
      if (this.insight.businessName.length > 15) {
        return `${this.insight.businessName.slice(0, 15)}...`;
      }
      return this.insight.businessName;
    },
    hasRemainingWeeklyVariables() {
      return this.remainingWeeklyVariables > 0;
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    async buttonAction() {
      await this.markAsRead(this.insight);
    },
  },
};

</script>

<style lang="scss" scoped>

.zero-balance {
  .text {
    .amount {
      font-weight: bold;
    }
    .business-name {
      font-weight: bold;
    }
  }
}
</style>
