<template>
  <div class="tracking-categories-flow">
    <tracking-categories-popup v-if="componentIndex === 0" @close="close" @save="save" @dont-show="dontShow"/>
    <tracking-categories-done-popup v-else-if="componentIndex === 1" @close="closeAfterSave"/>
    <tracking-categories-no-selection-popup v-else-if="componentIndex === 2" @close="close" @back="back"/>
  </div>
</template>

<script>

import TrackingCategoriesPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/tracking-categories/TrackingCategoriesPopup';
import TrackingCategoriesDonePopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/tracking-categories/TrackingCategoriesDonePopup';
import TrackingCategoriesNoSelectionPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/tracking-categories/TrackingCategoriesNoSelectionPopup';
import EventBus from '@/event-bus/event-bus';
import EditCategoryAmountToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/EditCategoryAmountToaster';
import cashflowViewConsts from '@/constants/cashflow-view';
import ACTIONS
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/action-list/actions.consts.ts';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TrackingCategoriesFlow',
  components: {
    TrackingCategoriesPopup,
    TrackingCategoriesDonePopup,
    TrackingCategoriesNoSelectionPopup,
  },
  data() {
    return {
      componentIndex: 0,
    };
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapGetters('cashflowView', ['monthName']),
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('actions', ['setActionCheckedState']),
    async save() {
      this.componentIndex = 1;
      await this.completeFlow();
    },
    closeAfterSave() {
      EventBus.$emit('open-toaster', {
        component: EditCategoryAmountToaster,
        props: {
          categoryName: 'המשתנות',
          timeFrameTitle: this.monthName,
        },
      });
      EventBus.$emit('scroll-to', { type: cashflowViewConsts.cashflowTypes.trackingCategory });
      this.close();
    },
    close() {
      this.$emit('close');
    },
    back() {
      this.componentIndex = 0;
    },
    async dontShow() {
      this.componentIndex = 2;
      await this.completeFlow();
    },
    async completeFlow() {
      await Promise.all([
        this.markAsRead(this.insight),
        this.setActionCheckedState({ type: ACTIONS.ADD_CATEGORIES, checked: true }),
      ]);
    },
  },
};
</script>

<style scoped>

</style>
