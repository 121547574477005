<template>
  <div class="anl-assets">
    <div v-if="positiveOshBalances && positiveOshBalances.length" class="section">
        <div class="section-title">יתרות עו״ש</div>
        <an-l-account-card
          v-for="(balance, i) in positiveOshBalances"
          :key="i"
          :sourceName="getHebrewSourceName(balance.source)"
          :accountNumber="balance.accountNumberPiiValue"
          :amount="balance.balance" />
    </div>
    <div v-if="savingsAccounts && savingsAccounts.length" class="section">
      <div class="section-title">פקדונות</div>
      <an-l-account-card
        v-for="(account, i) in savingsAccounts"
        :key="i"
        :sourceName="getHebrewSourceName(account.sourceIdentifier)"
        :amount="account.balanceAmount.amount"
        :interests="account.interests"
        :calculated-interest="account.calculatedInterest"
        :contract-end-date="account.contractEndDate"
        :contract-start-date="account.contractStartDate"
      />
    </div>
    <div v-if="educationFunds && educationFunds.length" class="section">
      <div class="section-title">קרנות השתלמות</div>
      <an-l-account-card
        v-for="(fund, i) in educationFunds"
        :key="i"
        :sourceName="fund.companyName"
        :amount="fund.balance"
        :liquidity-date="fund.liquidityDate"
        :fees="fund.fees"
        :first-name="fund.firstNamePiiValue"
        :last-name="fund.lastNamePiiValue"
      />
    </div>
    <div v-if="gemelLeashkaa && gemelLeashkaa.length" class="section">
      <div class="section-title">גמל להשקעה</div>
      <an-l-account-card
        v-for="(fund, i) in gemelLeashkaa"
        :key="i"
        :sourceName="fund.companyName"
        :amount="fund.balance"
        :fees="fund.fees"
        :first-name="fund.firstNamePiiValue"
        :last-name="fund.lastNamePiiValue"
      />
    </div>
    <div v-if="childSavings && childSavings.length" class="section">
      <div class="section-title">חסכון לכל ילד</div>
      <an-l-account-card
        v-for="(fund, i) in childSavings"
        :key="i"
        :sourceName="fund.companyName"
        :amount="fund.balance"
        :liquidity-date="fund.liquidityDate"
        :fees="fund.fees"
        :first-name="fund.firstNamePiiValue"
        :last-name="fund.lastNamePiiValue"
      />
    </div>
    <div v-if="savingsPolicies && savingsPolicies.length" class="section">
      <div class="section-title">פוליסות חסכון</div>
      <an-l-account-card
        v-for="(fund, i) in savingsPolicies"
        :key="i"
        :sourceName="fund.companyName"
        :amount="fund.balance"
        :fees="fund.fees"
      />
    </div>
    <div v-if="securities && securities.length" class="section">
      <div class="section-title">תיקי השקעות</div>
      <an-l-account-card
        v-for="(account, i) in securities"
        :key="i"
        :sourceName="getHebrewSourceName(account.sourceIdentifier)"
        :amount="account.balanceAmount.amount"
      />
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex';
import money from '@/utils/money';
import AnLAccountCard from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AnLAccountCard';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';

export default {
  name: 'AnLAssets',
  components: { AnLAccountCard },
  computed: {
    ...mapState('assetsAndLiabilities', ['savingsAccounts', 'securities', 'educationFunds', 'gemelLeashkaa',
      'savingsPolicies', 'childSavings']),
    ...mapGetters('assetsAndLiabilities', ['positiveOshBalances']),
    assetsSumFormatted() {
      return money.formatAmountWithFraction(this.assetsSum, 0);
    },
    liabilitiesSumFormatted() {
      return money.formatAmountWithFraction(this.liabilitiesSum * -1, 0);
    },
  },
  methods: {
    getHebrewSourceName(sourceName) {
      return getSourceConfigurationBySourceName(sourceName).displayName;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import "~@riseupil/base-ui/src/scss/typography";
@import '~@riseupil/base-ui/src/scss/spacings';

.anl-assets{
  .section{
    margin-top: $ri-spacing-xl;
    .section-title {
      margin-bottom: $ri-spacing-ml;
      font-size: $ri-font-size-22;
    }
  }

  .section:first-of-type {
    margin-top: $ri-spacing-l;
  }

  .green{
    color: $riseup_green;
  }

  .red{
    color: $riseup_danger_red;
  }
}

</style>
