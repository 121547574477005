<template>
  <div>
    <obk-description-connect-supported-cards-step step-number="1" />
    <obk-description-connect-unsupported-cards-step step-number="2" />
  </div>
</template>

<script>
import ObkDescriptionConnectSupportedCardsStep
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/ObkDescriptionConnectSupportedCardsStep';
import ObkDescriptionConnectUnsupportedCardsStep
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/ObkDescriptionConnectUnsupportedCardsStep';

export default {
  name: 'OpenBankingAmexDescription',
  components: { ObkDescriptionConnectSupportedCardsStep, ObkDescriptionConnectUnsupportedCardsStep },
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">

  .text-bold {
    font-weight: bold;
  }

</style>
