import { TRIAL_PERIODS } from '../../../../constants/subscription-state';

export default {
  periodVariant(trialPeriod) {
    switch (trialPeriod) {
    case TRIAL_PERIODS.BEGINNING:
    case TRIAL_PERIODS.MIDDLE: return 'warning';
    case TRIAL_PERIODS.FINAL: return 'danger';
    default: return 'warning';
    }
  },
  humanizeDays(days) {
    switch (days) {
    case 0: return null;
    case 1: return 'יום אחד';
    case 7: return 'שבוע אחד';
    default: return `${days} ימים`;
    }
  },
};
