<template>
  <insight :insight="insight"
           animation-name="RecalculatePlans.json"
           button-text="מה אפשר לעשות?"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div class="ri-title">
        נראה שחסר כסף בתוכניות שקבעת לעצמך. עדיין אפשר לתכנן קדימה ולהגיע ליעד!
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import RecalculatePlansPopup from
'@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/plans-insights-flow/RecalculatePlansPopup';
import Insight from './components/Insight';

export default {
  name: 'RecalculatePlansInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object, require: true },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('insights', ['markAsRead']),
    async buttonAction() {
      this.openModal({
        component: RecalculatePlansPopup,
        props: {
          plans: this.insight.plans,
          closeAction: this.closeAction,
        },
      });
    },
    closeAction() {
      Segment.trackUserInteraction('RecalculatePlansInsightPopupClosed', this.insight);
      this.markAsRead(this.insight);
      this.closeModal();
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
