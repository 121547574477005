import _ from 'lodash';
import moment from 'moment';
import { getCurrentCashflowMonth } from '@riseupil/common-utils';
import store from '@/store';
import DDLogs from '@/DDLogs';
import CashflowViewApi from '../../api/CashflowViewApi';

const MONTHS_BACK = 5;

async function getBudget(budgetDate) {
  return CashflowViewApi.fetchBudget({ budgetDate });
}

async function getBudgets(budgetDates) {
  const latestBudgetDate = _.chain(budgetDates).sort().last().value();
  DDLogs.info('getBudgets', { latestBudgetDate });
  const customerBudgets = await CashflowViewApi.fetchPreviousBudgets({ budgetDate: latestBudgetDate, numMonthsBack: budgetDates.length - 1 });
  return _.chain(customerBudgets)
    .filter(budget => _.includes(budgetDates, budget.budgetDate))
    .sortBy(budget => budget.budgetDate)
    .reverse()
    .value();
}

async function getAllBudgets() {
  const customerBudgets = await CashflowViewApi.fetchAllBudgets();
  return _.chain(customerBudgets)
    .sortBy(budget => budget.budgetDate)
    .reverse()
    .value();
}

function getPresentedBudgetDates() {
  const currentBudgetDate = getCurrentBudgetDate();
  return _extendMonthsBack(currentBudgetDate, MONTHS_BACK);
}

function _extendMonthsBack(openMonthBudgetDate, numMonthsBack) {
  const openBudgetDate = moment(openMonthBudgetDate);
  const presentedBudgetDates = [openMonthBudgetDate];
  for (let i = 1; i <= numMonthsBack; i++) {
    presentedBudgetDates.push(openBudgetDate.clone().subtract(i, 'month').format('YYYY-MM'));
  }
  return _.filter(presentedBudgetDates);
}

function _getCashflowStartDay() {
  const { cashflowStartDay } = store.state.cashflowView;
  return cashflowStartDay;
}

function getCurrentBudgetDate() {
  return getCurrentCashflowMonth(_getCashflowStartDay());
}

function getPrevBudgetDate(budgetDate, n = 1) {
  return moment(budgetDate).subtract(n, 'month').format('YYYY-MM');
}

function getNextBudgetDate(budgetDate) {
  return moment(budgetDate).add(1, 'month').format('YYYY-MM');
}

function getMonthName(budgetDate) {
  return moment(budgetDate).locale('he').format('MMM');
}

function getFullMonthName(cashflowMonth) {
  const cashflowMonthMoment = moment(cashflowMonth);
  return _getMonthHebrewName(cashflowMonthMoment);
}

function getCashflowMonthDatesRange(cashflowMonth) {
  const cashflowStartDay = _getCashflowStartDay();
  const format = 'D.M';
  const startDate = moment(cashflowMonth).date(cashflowStartDay);
  const endDate = moment(startDate).add(1, 'month').subtract(1, 'day');

  return `${startDate.format(format)} עד ${endDate.format(format)}`;
}

function _getMonthHebrewName(dateMoment) {
  return dateMoment.locale('he').format('MMMM');
}

function getCashflowsForPastMonthsGraph(allCashflows, presentedBudgetDate, currentBudgetDate) {
  return _.chain(allCashflows)
    .filter(cashflow => cashflow.budgetDate < currentBudgetDate)
    .sortBy(cashflow => cashflow.budgetDate)
    .reverse()
    .chunk(5)
    .find(cashflowChunk => cashflowChunk.map(budget => budget.budgetDate).includes(presentedBudgetDate))
    .value();
}

function getCashflowsRange(allCashflows, budgetDate, numMonthsBack = MONTHS_BACK) {
  const cashflows = _.chain(allCashflows)
    .orderBy(cashflow => moment(cashflow.budgetDate, 'YYYY-MM'), 'desc')
    .value();
  const index = _.findIndex(cashflows, cashflow => cashflow.budgetDate === budgetDate);
  return _.slice(cashflows, index, index + numMonthsBack);
}

function getCashflowsForEnvelopeTrend(allCashflows, budgetDate, numMonthsBack = MONTHS_BACK) {
  return getCashflowsRange(allCashflows, budgetDate, numMonthsBack - 1);
}

export default {
  getPresentedBudgetDates,
  getBudget,
  getBudgets,
  getAllBudgets,
  getCurrentBudgetDate,
  getPrevBudgetDate,
  getNextBudgetDate,
  getMonthName,
  getFullMonthName,
  getCashflowsRange,
  getCashflowsForPastMonthsGraph,
  getCashflowsForEnvelopeTrend,
  getCashflowMonthDatesRange,
};
