import { client } from '../http';

async function fetchIncomeQuestions() {
  const response = await client.get('/api/investigator/onboarding/questions/income');
  return response.data;
}

async function fetchExpenseQuestions() {
  const response = await client.get('/api/investigator/onboarding/questions/expense');
  return response.data;
}

async function fetchBugRules() {
  const response = await client.get('/api/bug-manager');
  return response.data;
}

async function answerPredictedIncomeQuestion({ budgetDate, amount }) {
  return await client.post('/api/investigator/answers/income-prediction', { budgetDate, amount });
}

export default {
  fetchIncomeQuestions,
  fetchExpenseQuestions,
  fetchBugRules,
  answerPredictedIncomeQuestion,
};
