<script>

import incognitoUtils from '@/utils/incognito-utils';

export default {
  name: 'BrowserInfo',
  computed: {
    userAgent() {
      return navigator.userAgent || navigator.vendor || window.opera;
    },
    isFacebookApp() {
      return (this.userAgent.indexOf('FBAN') > -1) || (this.userAgent.indexOf('FBAV') > -1);
    },
    browser() {
      return incognitoUtils.getBrowser();
    },
    userOnMobile() {
      return navigator.userAgentData ? navigator.userAgentData.mobile : 'unknown';
    },
    userPlatform() {
      return navigator.userAgentData ? navigator.userAgentData.platform : 'unknown';
    },
  },
  methods: {
    async isIncognitoMode() {
      return incognitoUtils.isIncognitoMode();
    },
    async getBrowserParams() {
      return {
        isIncognitoMode: await this.isIncognitoMode(),
        browser: this.browser,
        userOnMobile: this.userOnMobile,
        userPlatform: this.userPlatform,
        isFacebookApp: this.isFacebookApp,
      };
    },
  },
};

</script>
