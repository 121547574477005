<template>
  <div class="rolling-credit-cta-insight-popup">
    <colored-popup class='danger' :close-action="closePopup">
      <template v-slot:top-content>
        <div class="header">
          <div>הנה כמה צעדים שיכולים לעזור לך למצוא את הדרך הנכונה החוצה:</div>
        </div>
      </template>
      <template v-slot:bottom-content>
        <div class="content">
          <step :number="1"
                :title="'כמה זה עולה לך?'"
                :text="'להתקשר לחברת האשראי ולברר מה גובה החוב ומה הריבית החודשית עליו.'"></step>

          <step :number="2"
                :title="'כמה באמת הוצאת בחודש?'"
                :text="'ההוצאות האמיתיות שלך אל מול תקרת החיוב החודשי.'"></step>

          <step :number="3"
                :title="'האם אפשר להחזיר את החוב?'"
                :text="'לבדוק אם זה אפשרי בחודש אחד או שצריך למצוא פתרון אחר.'"></step>

          <riseup-button
            class="btn-support"
            title="אני רוצה לקרוא על זה עוד"
            :bold="true"
            :action="readMoreClicked"/>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-button" @click="closePopup">הבנתי, תודה</div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import Intercom from '@/Intercom';
import RollingCreditStepContent from './RollingCreditStepContent';

export default {
  name: 'RollingCreditCTAPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    step: RollingCreditStepContent,
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
    onClose: {
      type: Function,
      require: true,
    },
  },
  created() {
    Segment.trackUserGot('RollingCreditCTAPopupLoaded', this.insight);
  },
  computed: {},
  methods: {
    ...mapActions('insights', ['markAsRead']),
    requestSupport() {
      Segment.trackUserGot('RollingCreditCTAPopupRequestSupportClicked', this.insight);
      const message = `היי צוות,
ראיתי שיש לי חוב של ${this.insight.debt} בכרטיס שמסתיים בספרות ${this.insight.last4Digit} שמוגדר עם חיוב חודשי קבוע.
אשמח לשמוע יותר ולהבין איך להחזיר את החוב 🙏`;
      Intercom.showNewMessage(message);
    },
    async closePopup() {
      Segment.trackUserGot('RollingCreditCTAPopupCloseClicked', this.insight);
      await this.onClose();
    },
    async readMoreClicked() {
      Segment.trackUserGot('RollingCreditCTAPopupReadMoreClicked', this.insight);
      window.open('https://www.facebook.com/groups/riseuptogethergroup/permalink/2631662567139107/', '_blank');
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.rolling-credit-cta-insight-popup {
  text-align: right;

  .close-button {
    width: 100%;
  }

  .header {
    color: $riseup_white;
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .btn-support {
    margin-top: 10px;
  }

  .footer-button {
    cursor: pointer;
    text-align: center;
  }

  .footer:hover {
    color: blue;
  }
}

</style>
