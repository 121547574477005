<template>
  <div class="insight-popup">
    <insight-customer-feedback-popup v-if="somethingIsWrong" :insight="insight"
                                     :back-action="() => setSomethingIsWrong(false)"
                                     :close-action="closeAction"/>
    <colored-popup v-else :class="headerVariant"
                   :back-action="backAction"
                   :close-action="closeAction">
      <template v-slot:top-content>
        <slot name="top-content"></slot>
      </template>

      <template v-slot:bottom-content>
        <slot name="bottom-content"></slot>
        <riseup-button v-for="(button, index) of buttons" :key="index"
                       :action="() => answer(button)"
                       :variant="button.variant"
                       :title="button.text"
                       :size="'slim'"
                       :disabled="answering || button.disabled"/>
      </template>
      <template v-slot:footer>
        <div  v-if="showFeedbackFooter" class="footer-btn" v-on:click="() => setSomethingIsWrong(true)">אם משהו כאן לא נכון, נשמח לדעת</div>
        <slot v-else name="footer"></slot>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import InsightCustomerFeedbackPopup from './InsightCustomerFeedbackPopup';

export default {
  name: 'InsightDetailsPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    InsightCustomerFeedbackPopup,
  },
  props: {
    insight: { type: Object, required: true },
    cashflowCategory: { type: String, required: true },
    buttons: { type: Array, required: false },
    backAction: { type: Function, required: false },
    closeAction: { type: Function, required: false },
    showFeedbackFooter: { type: Boolean, default: false },
  },
  created() {
    this.sendSegmentEvent('InsightPopupShown');
  },
  data() {
    return {
      answering: false,
      somethingIsWrong: false,
    };
  },
  computed: {
    ...mapGetters('editTransaction', ['categoryToVariant']),
    headerVariant() {
      return this.categoryToVariant[this.cashflowCategory];
    },
  },
  methods: {
    sendSegmentEvent(eventName, extraProps = {}) {
      Segment.trackUserInteraction(eventName, { ...this.insight, ...extraProps });
    },
    async answer(button) {
      this.answering = true;
      this.sendSegmentEvent('InsightPopupConfirmedClicked', { buttonText: button.text });
      await button.action();
      this.answering = false;
    },
    setSomethingIsWrong(somethingIsWrong) {
      this.somethingIsWrong = somethingIsWrong;
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .insight-popup {
    text-align: right;

    .colored-top {
      color: $riseup_white;
    }

    .bottom {
      .riseup-button {
        width: 100%;
        margin-top: 24px;
      }
    }

    .footer-btn {
      display: flex;
      justify-content: center;
      font-size: 18px;
      color: $riseup_black;
      cursor: pointer;
    }
  }

</style>
