const GIFTCARD_FLOW_STEPS = {
  welcome: 'welcome',
  creditCard: 'credit-card',
  done: 'done',
};

const state = {};

const getters = {
  GIFTCARD_FLOW_STEPS: () => GIFTCARD_FLOW_STEPS,
};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
