<template>
  <insight class="recurring-balance"
           :insight="insight"
           :animation-name="animationName"
           :button-action="buttonAction"
           button-text="עדכון ופרטים נוספים">
    <template v-slot:insight-text>
      <div class="text">זיהינו שההוצאות על <span class="bold">{{ insight.expense }}</span> היו גבוהות ב-
        <span class="amount">{{ formattedAmount }} ₪</span>
        מהצפוי<span v-if="hasRemainingWeeklyVariables">, יתרת המשתנות שלך קטנה בהתאם</span></div>
    </template>
  </insight>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import RecurringBalanceInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/RecurringBalanceInsightPopup';
import moneyUtils from '@/utils/money';
import Insight from './components/Insight';

const TRACKING_CATEGORIES = [
  { label: 'אוכל בחוץ', animationName: 'take-outs.json' },
  { label: 'תחבורה ציבורית', animationName: 'transport.json' },
  { label: 'פארמה', animationName: 'pharma.json' },
  { label: 'רכב', animationName: 'car.json' },
  { label: 'תרומה', animationName: 'donation.json' },
  { label: 'תיירות', animationName: 'tourism.json' },
  { label: 'פנאי', animationName: 'leisure.json' },
  { label: 'ביגוד והנעלה', animationName: 'shopping.json' },
  { label: 'חיות מחמד', animationName: 'pets.json' },
];

export default {
  name: 'RecurringBalanceInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapGetters('cashflowView', ['remainingWeeklyVariables']),
    amountDifference() {
      return Math.abs(this.insight.originalAmount - this.insight.balancedAmount);
    },
    animationName() {
      const trackingCategory = _.find(TRACKING_CATEGORIES, { label: this.insight.expense });
      if (trackingCategory) {
        return trackingCategory.animationName;
      }
      return 'other.json';
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.amountDifference, 0);
    },
    hasRemainingWeeklyVariables() {
      return this.remainingWeeklyVariables > 0;
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal']),
    async buttonAction() {
      this.openRecurringBalanceInsightPopup();
    },
    openRecurringBalanceInsightPopup() {
      this.openModal({
        component: RecurringBalanceInsightPopup,
        props: {
          insight: this.insight,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>

.recurring-balance {
  .text {
    .bold {
      font-weight: bold;
    }
    .amount {
      font-weight: bold;
      white-space: pre;
    }
  }
}
</style>
