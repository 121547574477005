<template>
  <div :class="['monthly-amount-container', { 'initial-state': !isLoading && noMonthlyAmount, 'loading-state': isLoading }]">
    <div class="monthly-amount-note ri-title">{{ amountNoteText }}</div>
    <div class="monthly-amount-box">
      <img v-if="noMonthlyAmount || isLoading" src="/images/plan-ahead/loading-flower.svg"/>
      <div v-else-if="!isLoading && !noMonthlyAmount" class="amount">{{ calculatedMonthlyFormattedAmount }}<span> ₪</span></div>
    </div>
    <div v-if="!isLoading && !noMonthlyAmount" class="status-note">
      <div v-if="isCurrentPlanOnTrack" class="note-text">
        <div >
          <div style="display: flex; justify-content: center">
            <icon icon-name="check_circle" :color="statusIconColor" fill class="icon"/>
            לפי התזרים שלך החודש, נראה שאפשר לשים
          </div>
          <div style="display: flex; justify-content: center">
            עד ₪{{totalRemaining}}
            לתוכניות שלך
          </div>
        </div>
      </div>
      <div v-else class="note-text">
        <div v-if="negativeCashflowExperience" >
          <div class="ri-font-weight-bold"> כל הכבוד על התיכנון מראש! </div>
          <div>
            כרגע התוכנית בהמתנה, בכל פעם שהתזרים יהיה חיובי, נוודא שהוא מיועד לתוכניות שלך.
          </div>
        </div>
        <div v-else >
          <div style="display: flex">
            <icon icon-name="favorite" :color="statusIconColor" fill class="icon"/>
            <span>כרגע נראה שלא ישאר מספיק בצד החודש כדי לעמוד בתוכנית הזאת.</span>
          </div>
          <div>
            בינתיים אפשר להוסיף לרשימה, ולערוך בהמשך.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moneyUtils from '@/utils/money';
import BaseUi from '@riseupil/base-ui';
import EnterViewport from '@/mixins/EnterViewport.vue';
import { mapState } from 'vuex';
import _ from 'lodash';

export default {
  name: 'MonthlyAmountContainer',
  components: {
    Icon: BaseUi.Icon,
  },
  mixins: [EnterViewport],
  props: {
    isLoading: {
      type: Boolean,
      require: true,
    },
    calculatedMonthlyAmount: {
      type: Number,
      require: false,
    },
    isCurrentPlanOnTrack: {
      type: Boolean,
      require: true,
    },
    totalRemaining: {
      type: String,
      require: true,
    },
    negativeCashflowExperience: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customNotOnTrackIcon: {
        icon: 'favorite',
        color: BaseUi.Colors.riseupRed,
      },
    };
  },
  computed: {
    ...mapState('planAhead', ['currentPlan']),
    calculatedMonthlyFormattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.calculatedMonthlyAmount, 0);
    },
    sumNonCustomerPlanTransactions() {
      return _.sumBy(this.currentPlan.planTransactions?.filter(transaction => transaction.creator !== 'customer'), 'amount');
    },
    existingFund() {
      return this.currentPlan.savedAmountObj?.amount
        ?? this.currentPlan.planTransactions?.find(transaction => transaction.creator === 'customer')?.amount
        ?? 0;
    },
    savedAmountTillNow() {
      return this.sumNonCustomerPlanTransactions + this.existingFund;
    },
    amountNoteText() {
      if (!this.calculatedMonthlyAmount || this.isLoading) {
        return 'כמה זה יעלה לך כל חודש ...';
      }
      if (this.existingFund > 0) {
        const formattedCurrentAmount = moneyUtils.formatAmountWithFraction(this.savedAmountTillNow, 0);
        return ` עד עכשיו שמת בצד ₪${formattedCurrentAmount}\nומעכשיו צריך כל חודש לשים בצד :`;
      }
      return 'כלומר, צריך כל חודש לשים בצד:';
    },
    statusIconColor() {
      return this.isCurrentPlanOnTrack ? BaseUi.Colors.riseupGreen : BaseUi.Colors.riseupRed;
    },
    noMonthlyAmount() {
      return _.isNil(this.calculatedMonthlyAmount);
    },
  },
  methods: {
    onEnterViewport() {
      this.$emit('entered-viewport');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/border-radius';

  .monthly-amount-container {
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 30px;
    border-radius: $card-border-radius;
    background: $riseup_beige;

    .monthly-amount-note {
      margin-bottom: 10px;
      white-space: pre-wrap;
      text-align: center;
    }

    .amount {
      font-weight: bold;
      font-size: 44px;

      span {
        font-size: 24px;
      }
    }

    .status-note {
      margin: 15px 40px 0 40px;

      .note-text {

        text-align: center;
        font-size: 12px;
        line-height: 18px;
      }

      .icon {
        margin-left: 4px;
        font-size: 16px;
      }
    }

    &.initial-state {
      .monthly-amount-note {
        color: $riseup_gray_2;
      }
    }

    &.loading-state {
      img {
        animation: rotation 2s infinite linear;
        @keyframes rotation {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(359deg);
          }
        }
      }
    }
  }

</style>
