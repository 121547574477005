<template>
  <div @click="onPlanDetails">
    <card class="plan-card padding-vertical-m" :class="{ 'preview-mode': previewMode }">
      <div class="deadline">{{ deadline }}</div>
      <div class="icon-and-data-container">
        <div class="circular-container">
          <circular-progress :percent="completedPercentage" progress-size="small"
                             :size="previewMode ? 'medium' : 'big'" :progress-bar-color="circleColor"/>
          <div class="icon-and-shape">
            <plan-icon :planType="plan.type" :size="previewMode ? 40 : 48"/>
          </div>
        </div>
        <div class="data-container">
          <div class="name ri-medium-headline">{{ plan.name }}</div>
          <div class="money-section">
            <span class="large-text bold">{{ currentAmount }} ₪</span>
            <span class="medium-text">/ </span>
            <span class="large-text">{{ targetAmount }} ₪</span>
          </div>
        </div>
      </div>
      <div v-if="!previewMode">
        <riseup-button class="button margin-bottom-sm" v-if="!plan.onTrack && !planCompleted && !cashflowIsNegative"
                       title="לחלוקה חודשית חדשה" variant="primary" @click="onEditPlan"/>

        <separator/>
        <plan-status-note class="note"
                          :text="noteText"
                          :is-on-track="isPlanOnTrack"
                          :planCompleted="planCompleted"
                          :cashflowIsNegative="cashflowIsNegative"/>
      </div>
    </card>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';
import CircularProgress
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/action-list/CircularProgress';
import BaseUI from '@riseupil/base-ui';
import PlanStatusNote from './PlanStatusNote';
import PlanIcon from './PlanIcon';

export default {
  name: 'Plan',
  components: {
    PlanStatusNote,
    CircularProgress,
    PlanIcon,
    Separator: BaseUI.Separator,
    RiseupButton: BaseUI.RiseupButton,
    Card: BaseUI.Card,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      circleColor: BaseUI.Colors.riseupYellow,
    };
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    deadline() {
      return moment(this.plan.deadline).locale('he').format('MMMM YYYY');
    },
    targetAmount() {
      return moneyUtils.formatAmountWithFraction(this.plan.targetAmount, 0);
    },
    currentAmount() {
      return moneyUtils.formatAmountWithFraction(this.plan.currentAmount, 0);
    },
    isPlanOnTrack() {
      if (this.planCompleted) {
        return this.plan.onTrack;
      }
      return this.plan.onTrack && this.plan.isOnTrackThisMonth;
    },
    noteText() {
      if (this.isPlanOnTrack && this.planCompleted) {
        return 'התוכנית הושלמה!';
      }
      if (!this.isPlanOnTrack && this.planCompleted) {
        return 'התוכנית הגיעה למועד שקבעת';
      }
      if (this.cashflowIsNegative) {
        return 'התוכנית תופעל בתזרים החיובי הבא';
      }
      if (this.isPlanOnTrack && this.planOngoing) {
        return 'נראה שהכל בכיוון הנכון';
      }
      if (!this.isPlanOnTrack && this.planOngoing) {
        return 'נראה שלא יהיה אפשר להגיע לסכום במועד שרצית';
      }

      throw new Error('Invalid plan state');
    },
    planCompleted() {
      return this.plan.state === 'done';
    },
    planOngoing() {
      return this.plan.state === 'ongoing';
    },
    completedPercentage() {
      const currentAmountToTargetAmountRatio = this.plan.currentAmount / this.plan.targetAmount;
      if (currentAmountToTargetAmountRatio > 1) {
        return 100;
      }
      return (this.plan.currentAmount / this.plan.targetAmount) * 100;
    },
    cashflowIsNegative() {
      return this.predictedBalanceAtEndOfMonth < 0;
    },
  },
  methods: {
    onPlanDetails() {
      this.$emit('edit-plan', this.plan);
    },
    onEditPlan() {
      this.$emit('edit-plan-flow', this.plan);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.plan-card {
  cursor: pointer;

  .icon-and-data-container {
    display: flex;
    position: relative;
    left: 5px;
    height: 70px;
    margin-bottom: 20px;
  }

  .circular-container {
    position: relative;
    margin-left: 7px;
  }

  .icon-and-shape {
    position: absolute;
    top: 11px;
    right: 11px;
  }

  .icon-container {
    height: 48px;
    width: 48px;
    padding: 4px;
    display: flex;
    border-radius: 50%;

    img {
      width: 40px;
      height: 40px;
    }
  }

  .large-text {
    font-size: 18px;
  }

  .bold {
    font-weight: bold;
  }

  .medium-text {
    font-size: 16px;
  }

  .deadline {
    display: inline-block;
    background: $riseup_light_blue;
    border-radius: 3px;
    font-size: 12px;
    margin-bottom: 12px;
    padding: 2px 6px;
  }

  .monthly-target-section {
    color: $riseup_gray_2;
    padding-bottom: 9px;
  }

  .note {
    font-size: 14px;
    margin-top: 14px;
  }

  .data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 28px;
  }

  &.preview-mode {
    &.plan {
      padding: 17px;
    }

    .name {
      font-size: 18px;
    }

    .large-text {
      font-size: 16px;
    }

    .icon-and-shape {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .deadline {
      font-size: 10px;
      border-radius: 2.5px;
      margin-bottom: 9px;
    }

    .icon-and-data-container {
      left: 5px;
      height: 58px;
      margin-bottom: 0px;
    }

    .icon-container {
      height: 40px;
      width: 40px;
    }
  }
}

</style>
