import moment from 'moment';
import _ from 'lodash';
import { getEndOfCashflowDate } from '@riseupil/common-utils';
import Vue from 'vue';

const ENG_TO_HEB_MONTHS = {
  January: 'ינואר',
  February: 'פברואר',
  March: 'מרץ',
  April: 'אפריל',
  May: 'מאי',
  June: 'יוני',
  July: 'יולי',
  August: 'אוגוסט',
  September: 'ספטמבר',
  October: 'אוקטובר',
  November: 'נובמבר',
  December: 'דצמבר',
};
const ENG_TO_HEB_DAYS = {
  0: 'א',
  1: 'ב',
  2: 'ג',
  3: 'ד',
  4: 'ה',
  5: 'ו',
  6: 'ש',
};

const ENG_TO_HEB_FULL_DAYS = {
  0: 'ראשון',
  1: 'שני',
  2: 'שלישי',
  3: 'רביעי',
  4: 'חמישי',
  5: 'שישי',
  6: 'שבת',
};

function getHebrewMonth(value) {
  return ENG_TO_HEB_MONTHS[moment(String(value)).format('MMMM')];
}

function endOfMonthText(cashflowMonth, cashflowStartDay) {
  const endOfCashflowMonth = getEndOfCashflowDate({ cashflowMonth, cashflowStartDay });
  return moment(endOfCashflowMonth).format('D.M');
}

function endOfMonthHebText(cashflowMonth, cashflowStartDay) {
  const endOfCashflowMonth = getEndOfCashflowDate({ cashflowMonth, cashflowStartDay });
  return getShortHebrewMonthAndDay(endOfCashflowMonth);
}

function startOfMonthText(cashflowMonth, cashflowStartDay) {
  const startOfCashflowMonth = moment(cashflowMonth).date(cashflowStartDay);
  return moment(startOfCashflowMonth).format('D.M');
}

function startOfMonthHebText(cashflowMonth, cashflowStartDay) {
  const startOfCashflowMonth = moment(cashflowMonth).date(cashflowStartDay);
  return getShortHebrewMonthAndDay(startOfCashflowMonth);
}

function getShortHebrewMonth(value) {
  return moment(String(value)).locale('he').format('MMM');
}

function getShortHebrewMonthAndDay(value) {
  return moment(String(value)).locale('he').format('D בMMMM');
}

function normalizeDate(dateString) {
  return moment(dateString).format('D.M.YYYY');
}

function shortDateFormat(dateString) {
  return moment(dateString).format('D.M.YY');
}

function getYear(date) {
  return moment(date).format('YYYY');
}

function getMonthAndYear(date) {
  return moment(date).locale('he').format('MMMM YYYY');
}

function getShortMonthAndYear(date) {
  return moment(date).locale('he').format('MMM YYYY');
}

function getShortMonthAndShortYear(date) {
  return moment(date).locale('he').format('MMM YY');
}

function getMonthAndShortYear(date) {
  return moment(date).locale('he').format('MMMM YY');
}
Vue.filter('getMonthAndShortYear', getMonthAndShortYear);

function generateMonthsList(monthsDiff) {
  const now = moment().startOf('month');
  return _.range(monthsDiff).map(() => moment(now.add(1, 'months')));
}

function formatCashflowDate(date) {
  return moment(date).format('YYYY-MM');
}

function getMonthTimeFrame(month, cashflowStartDay) {
  const startDate = startOfMonthText(month, cashflowStartDay);
  const endDate = endOfMonthText(month, cashflowStartDay);

  return `${startDate} עד ${endDate}`;
}
function getHebrewDayOfWeek(date) {
  return ENG_TO_HEB_DAYS[moment(date).day()];
}

function getHebrewDayOfWeekFullName(date) {
  return ENG_TO_HEB_FULL_DAYS[moment(date).day()];
}

function getDaysDiffFromNow(date) {
  return moment().diff(moment(date), 'days') + 1;
}
function getDaysDiff(startDate, endDate) {
  return moment(endDate).diff(moment(startDate), 'days') + 1;
}
function getSundayDateBeforeNow() {
  return moment().startOf('week').format('YYYY-MM-DD');
}
function getConsecutiveDaysFromDate(date, numOfDays) {
  return _.map(
    [...Array(numOfDays).keys()],
    days => moment(date).add(days, 'day').format('YYYY-MM-DD'),
  );
}
function getFormattedDateString(date) {
  return moment(date).format('YYYY-MM-DD');
}
function getDaysCounterFromSundayToDate(date) {
  return moment(date).diff(moment(date).day('Sunday'), 'day');
}
function isDateOutOfRange(dateString, startDate, endDate) {
  return isDateBefore(dateString, getFormattedDateString(startDate))
    || moment(dateString).isAfter(getFormattedDateString(endDate));
}

function isDateAfterToday(dateString) {
  return moment(dateString).isAfter(moment(), 'day');
}

function isDateBefore(dateString, startDate) {
  return moment(dateString).isBefore(startDate, 'day');
}

function isDateBeforeToday(dateString) {
  return isDateBefore(dateString, moment());
}

Vue.filter('toMonthAndYear', budgetDate => getMonthAndYear(budgetDate));

export default {
  getShortHebrewMonthAndDay,
  getHebrewDayOfWeek,
  getHebrewMonth,
  normalizeDate,
  shortDateFormat,
  getYear,
  getShortHebrewMonth,
  getMonthAndYear,
  getShortMonthAndYear,
  getShortMonthAndShortYear,
  getMonthAndShortYear,
  generateMonthsList,
  formatCashflowDate,
  endOfMonthText,
  startOfMonthText,
  startOfMonthHebText,
  endOfMonthHebText,
  getMonthTimeFrame,
  getDaysDiffFromNow,
  getDaysDiff,
  getSundayDateBeforeNow,
  getConsecutiveDaysFromDate,
  getFormattedDateString,
  getDaysCounterFromSundayToDate,
  isDateOutOfRange,
  isDateAfterToday,
  isDateBeforeToday,
  isDateBefore,
  getHebrewDayOfWeekFullName,
};
