<template>
  <div class="create-tracking-category-popup">
    <colored-popup :class="variant" :close-action="onClose" :back-action="onBack">

      <template v-slot:top-content>
        <div class="title">
          {{title}}
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="question">
          <slot name="sub-title"/>
        </div>
        <cashflow-category-dropdown :options="categoriesOptions"
                                    :first-selected="selectedTrackingCategory"
                                    :on-select="onSelect" />
        <slot name="callout"/>
        <riseup-button :action="onSave"
                       :variant="'primary'"
                       :size="'slim'"
                       :title="buttonText || 'המשך'"
                       :disabled="!selectedTrackingCategory"/>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import cashflowViewConsts from '../../../../../../../../constants/cashflow-view';

export default {
  name: 'CategoriesSelectionPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    CashflowCategoryDropdown: BaseUI.CashflowCategoryDropdown,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    categoriesOptions: {
      type: Array,
      require: true,
    },
    buttonText: {
      type: String,
      require: false,
    },
    onClose: {
      type: Function,
      require: false,
    },
    onBack: {
      type: Function,
      require: false,
    },
  },
  created() {
    this.setAvailableTransactions(this.presentedBudget);
    if (this.selectedTrackingCategory.label === this.customCategoryLabel) {
      this.setCustomTrackingCategory(true);
    }
  },
  computed: {
    ...mapState('createTrackingCategory', ['selectedTrackingCategory']),
    ...mapGetters('createTrackingCategory', ['availableTrackingCategories', 'customCategoryLabel']),
    ...mapGetters('cashflowView', ['presentedBudget']),
    variant() {
      return cashflowViewConsts.CATEGORY_STYLE[cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING];
    },
  },
  methods: {
    ...mapActions('createTrackingCategory', ['selectTrackingCategory', 'setAvailableTransactions']),
    ...mapMutations('createTrackingCategory', ['setCustomTrackingCategory']),
    onSelect(selectedCategory) {
      if (selectedCategory.label === this.customCategoryLabel) {
        this.setCustomTrackingCategory(true);
        return;
      }
      this.setCustomTrackingCategory(false);
      this.selectTrackingCategory(selectedCategory);
    },
    onSave() {
      this.$emit('done-clicked');
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.create-tracking-category-popup {
  text-align: right;

  .colored-top {
    color: $riseup_white;

    .title {
      font-size: 22px;
      font-weight: bold;
    }
  }

  .bottom {
    .question {
      margin-bottom: 13px;
      font-size: 18px;
      line-height: 22px;
    }

    .cashflow-category-dropdown-v2 {
      margin-bottom: 40px;
    }

    .custom-category {
      margin-bottom: 80px;
    }

    .riseup-button {
      width: 100%;
    }
  }
}

</style>
