<template>
  <categories-selection-popup title="בחירת קטגוריה ליעד" :categoriesOptions="categoriesOptions"
                              :onBack="back" @done-clicked="onSave" button-text="הבא">
    <template v-slot:sub-title>
      <span>באיזו קטגוריה נתמקד החודש וננסה</span>
      <span class="bold-sub-title">
        לצמצם בה את ההוצאות?
      </span>
    </template>
    <template v-slot:callout>
      <callout v-if="isPredictedCashflowPositive" iconPath="icons/heart-icon.svg" title="טיפ מאיתנו" class="insight-callout">
        <template v-slot:text>
          בכל חודש מומלץ לבחור לפחות קטגוריה  אחת ולהגדיר לה יעד לצימצום הוצאות
        </template>
      </callout>
    </template>
  </categories-selection-popup>
</template>

<script>
import * as _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';
import CategoriesSelectionPopup from '../CategoriesSelectionPopup';

export default {
  name: 'SetCategoryChallenge',
  components: {
    CategoriesSelectionPopup,
    Callout: BaseUI.Callout,
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth', 'hasExcluded', 'riseupGoal', 'trackingCategories']),
    isPredictedCashflowPositive() {
      return this.predictedBalanceAtEndOfMonth > 0;
    },
    categoriesOptions() {
      return _.chain(this.trackingCategories)
        .map(item => ({ ...item, label: item.categoryName, color: 'blue', value: item.categoryName }))
        .filter(item => item.frequency === 'monthly')
        .value();
    },
  },
  created() {
    this.selectTrackingCategory(_.first(this.categoriesOptions));
  },
  methods: {
    ...mapActions('createTrackingCategory', ['selectTrackingCategory']),
    back() {
      this.$emit('back-clicked');
    },
    onSave() {
      Segment.trackUserInteraction('SetCategoryChallenge_SaveClicked', {
        selectedCategory: this.selectedCategory,
      });
      this.$emit('done-clicked');
    },
  },
};
</script>

<style scoped>
  .bold-sub-title {
    font-weight: bold;
  }
  .insight-callout {
    text-align: right;
    padding: 16px;
    margin-bottom: 20px;
  }
</style>
