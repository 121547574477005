<template>
  <toaster @close-toaster="closeSummaryToaster">
    <template v-slot:text>
      <span><span class="bold">{{ excludedAmountString }}</span> {{ excludedAction }}</span>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../../base-components/Toaster';

export default {
  name: 'ExclusionToaster',
  components: {
    Toaster,
  },
  props: {
    excludedAmount: {
      type: String,
      required: true,
      validator: val => ['income', 'expense', 'loan'].includes(val),
    },
    excludedAction: {
      type: String,
      required: true,
    },
  },
  computed: {
    excludedAmountString() {
      if (this.excludedAmount === 'loan') {
        return 'ההלוואה';
      } if (this.excludedAmount === 'expense') {
        return 'ההוצאה';
      } if (this.excludedAmount === 'income') {
        return 'ההכנסה';
      }
      return null;
    },
  },
  methods: {
    closeSummaryToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
