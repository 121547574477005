import { client } from '../http';

export default {
  async fetchAccountInvestigationQuestions() {
    const response = await client.get('/api/investigator/questions/account');
    return response.data;
  },

  async fetchAccountInvestigationOnboardingQuestions() {
    const response = await client.get('/api/investigator/onboarding/questions/account');
    return response.data;
  },

  async setAccountsStatus({ accountAnswers }) {
    return await client.post('/api/investigator/answers/account', { accountAnswers });
  },
};
