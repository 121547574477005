<template>
  <div class="dynamic-deposit-step">
    <div>
      <d-d-header :authenticated="authenticated"/>
    </div>
    <div class="content">
      <div class="progress-bar"><img :src="require(`@/assets/dynamic-deposit/progress-bar/${progressBarImagePath}`)"/></div>
      <slot name="content"/>
    </div>
    <div class="button">
      <slot name="next-button"/>
    </div>
  </div>
</template>

<script>

import EventBus from '@/event-bus/event-bus';
import DDHeader from './DDHeader';

const PROGRESS_BAR_IMAGE_BY_STEP = {
  DDChooseMonthlyAmount: 'choose-amount.svg',
  DDChooseOpportunisticAmount: 'choose-amount.svg',
  DDReduceMonthlyAmount: 'choose-amount.svg',
  DDEnterIdNumber: 'authentication.svg',
  DDEnterOTP: 'authentication.svg',
  DDConfirmDetails: 'detailes.svg',
  DDSignature: 'signature.svg',

};

export default {
  name: 'DynamicDepositStep',
  components: {
    DDHeader,
  },
  props: {
    authenticated: {
      type: Boolean,
      required: true,
    },
    step: {
      type: String,
      required: true,
    },
  },
  mounted() {
    EventBus.$emit('reset-scroll');
  },
  computed: {
    progressBarImagePath() {
      return PROGRESS_BAR_IMAGE_BY_STEP[this.step];
    },
  },
};
</script>

<style lang="scss" scoped>

.dynamic-deposit-step {
  .content {
    text-align: right;
    margin: 30px;

    .progress-bar {
      margin-bottom: 35px;
    }
  }

  .button {
    margin: 0 30px;
    margin-top: 50px;
  }
}
</style>
