import _ from 'lodash';
import moment from 'moment';

function calcInterestAffect(interest, amount, days) {
  const interestPerDay = interest / 100 / 365;
  return Math.round(((amount) * ((1 + interestPerDay) ** (days)) - amount) * 100) / 100;
}

function getEndOfYearAmount(currentAmount, interestAmount, years) {
  const days = moment('2023-07-01').add(years, 'years').diff(moment(), 'days');
  return currentAmount + calcInterestAffect(interestAmount, currentAmount, days);
}

const grades = [
  { value: 0, label: 'גן חובה' },
  { value: 1, label: 'כיתה א' },
  { value: 2, label: 'כיתה ב' },
  { value: 3, label: 'כיתה ג' },
  { value: 4, label: 'כיתה ד' },
  { value: 5, label: 'כיתה ה' },
  { value: 6, label: 'כיתה ו' },
  { value: 7, label: 'כיתה ז' },
  { value: 8, label: 'כיתה ח' },
  { value: 9, label: 'כיתה ט' },
  { value: 10, label: 'כיתה י' },
  { value: 11, label: 'כיתה יא' },
  { value: 12, label: 'כיתה יב' },
];

function gradeToString(grade) {
  const gradeRow = _.find(grades, row => row.value === grade);
  return gradeRow ? gradeRow.label : '';
}

export default {
  calcInterestAffect,
  getEndOfYearAmount,
  grades,
  gradeToString,
};
