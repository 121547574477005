<template>
  <full-screen-modal :back-action="showPrevious" :close-action="close">
    <template v-slot:content>
        <planNameAndSuggestionsPage v-if="currentPage === componentNames.planNameAndSuggestionsPage"
                                    @done="onNameUpdate"/>
        <planAmountAndDatePage v-else-if="currentPage === componentNames.planAmountAndDatePage"
                          @done="onAmountAndDateUpdate"/>
    </template>
  </full-screen-modal>
</template>

<script>
import Segment from '@/Segment';
import FullScreenModal from '@/base-components/FullScreenModal';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import PlanNameAndSuggestionsPage from './PlanNameAndSuggestionsPage';
import PlanAmountAndDatePage from './PlanAmountAndDatePage';
import PlanCreatedPopup from './PlanCreatedPopup';
import PlanCreatedNegativeCashflowExperiencePopup from './PlanCreatedNegativeCashflowExperiencePopup';

const PAGES = {
  planNameAndSuggestionsPage: 'planNameAndSuggestionsPage',
  planAmountAndDatePage: 'planAmountAndDatePage',
};

const COMPONENTS_MAP = {
  planNameAndSuggestionsPage: {
    name: PAGES.planNameAndSuggestionsPage,
    next: PAGES.planAmountAndDatePage,
  },
  planAmountAndDatePage: {
    name: PAGES.planAmountAndDatePage,
    prev: PAGES.planNameAndSuggestionsPage,
  },
};

export default {
  name: 'AddPlanFlow',
  components: {
    FullScreenModal,
    PlanNameAndSuggestionsPage,
    PlanAmountAndDatePage,
  },
  created() {
    Segment.trackUserGot('PlanAhead_Flow_Shown', { isEditMode: false });
    this.resetCurrentPlan();
  },
  props: {
    onDone: {
      type: Function,
      require: true,
    },
    closeOnShowPrevious: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: COMPONENTS_MAP.planNameAndSuggestionsPage.name,
    };
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    componentNames() {
      return _.mapValues(COMPONENTS_MAP, c => c.name);
    },
    cashflowIsNegative() {
      return this.predictedBalanceAtEndOfMonth < 0;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('planAhead', ['updateCurrentPlan', 'resetCurrentPlan']),
    async onNameUpdate(newNameAndType) {
      await this.updateCurrentPlan(newNameAndType);
      this.showNext();
    },
    async onAmountAndDateUpdate({ deadline, targetAmount }) {
      await this.updateCurrentPlan({ deadline, targetAmount });
      await this.onDone();
      this.closeModal();
      this.openModal({
        component: this.cashflowIsNegative ? PlanCreatedNegativeCashflowExperiencePopup : PlanCreatedPopup,
      });
    },
    async showPrevious() {
      if (this.closeOnShowPrevious) {
        await this.closeModal();
        return;
      }
      if (this.currentPage === COMPONENTS_MAP.planNameAndSuggestionsPage.name) {
        await this.close();
      } else {
        Segment.trackUserInteraction('PlanAhead_Flow_Back');
        this.currentPage = COMPONENTS_MAP[this.currentPage].prev;
      }
    },
    showNext() {
      Segment.trackUserInteraction('PlanAhead_Flow_Next');
      this.currentPage = COMPONENTS_MAP[this.currentPage].next;
    },
    async close() {
      Segment.trackUserInteraction('PlanAhead_Flow_Close');
      await this.resetCurrentPlan();
      await this.closeModal();
    },
  },
};
</script>

<style scoped>
</style>
