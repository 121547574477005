<template>
  <div class="tracking-categories-done-popup">
    <colored-popup>

      <template v-slot:top-content>
        <lottie-player src="/animations/Check.json" :autoplay="true" :loop="true"/>
        <div class="title">הקטגוריות שבחרת נפתחו</div>
        <div class="text"> עכשיו יהיה יותר קל לעקוב אחריהן, <span class="bold">להציב לעצמך יעד חודשי</span> חדש, ולהתחיל לחסוך!</div>
      </template>

      <template v-slot:bottom-content>
        <riseup-button :action="close"
                       :variant="'primary'"
                       :size="'slim'"
                       title="הבנתי, תודה!"/>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'TrackingCategoriesDonePopup',
  created() {
    Segment.trackUserInteraction('TrackingCategoriesDonePopup_Shown');
  },
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.text {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  .bold {
    font-weight: bold;
  }
}
</style>
