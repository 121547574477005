<template>
  <sticky-cta-page :cta-props="{buttonProps: { title: 'הבנתי אפשר להמשיך', disabled: !continueButtonEnabled}}" @click="next">
    <img src="/images/advisory/mislaka-consent-header.svg" />
    <div class="padding-horizontal-ml mislaka-creds-to-update">
      <div class="ri-body top-title margin-top-ml margin-bottom-sm">התקדמנו! עוד רגע ואנחנו שם</div>
      <div class="ri-large-headline margin-bottom-xs">נשאר רק לעדכן את חיבור החשבונות שלך</div>
      <div class="ri-large-body margin-bottom-ml">
        <span>כדי שנוכל להציג לך את המידע העדכני ביותר על פקדונות, הלוואות, משכנתאות וניירות ערך בנקאיים המערכת צריכה</span>
        <span class="ri-font-weight-bold">לחדש את ההסכמה</span>
        <span>לחשבונות הרלוונטים.</span>
      </div>
      <!-- creds with old consent -->
      <credentials-card v-for="creds of credentialsWithOldConsentConfiguration" :key="creds.credentialsId"
                        :source="creds" :enable-menu="false" :should-update="true">
        <template v-slot:buttons>
          <div class="margin-top-m margin-bottom-m">
            <riseup-button title="לחדש את ההסכמה"  size="slim" :bold="true" :action="() => reconnectConsent(creds)"
                           :loading="loadingCreds[creds.credentialsId]"/>
          </div>
        </template>
      </credentials-card>
      <!-- non OBK creds -->
      <credentials-card v-for="creds of credentialsToMigrateToOBKConfiguration" :key="creds.credentialsId" :source="creds"
                        :should-update="true" :enable-menu="false" class="margin-top-ml">
        <template v-slot:buttons>
          <div class="margin-top-m margin-bottom-m">
            <riseup-button title="לחדש את ההסכמה" size="slim" :bold="true" :action="() => migrateToOBK(creds)"/>
          </div>
        </template>
      </credentials-card>

      <!-- creds in connection proccess -->
      <credentials-card v-for="creds of credsInConnectionProccess"
                        :key="creds.credentialsId" :source="creds" :enable-menu="false" class="margin-top-ml" :show-menu="false" />
    </div>
  </sticky-cta-page>
</template>

<script>

import Segment from '@/Segment';
import { mapActions, mapGetters, mapState } from 'vuex';
import CredentialsCard from '@/base-components/onboarding/CredentialsCard';
import BaseUI from '@riseupil/base-ui';
import ConsentActionsMixin from '@/base-components/onboarding/openBanking/ConsentActionsMixin';
import MigrateToOBKFlow from '@/base-components/credentials/credentials-list/MigrateToOBKFlow';
import moment from 'moment';
import { LOCAL_STORAGE_KEY_UPDATE_OLD_CREDENTIALS } from '@/constants/credentials';

export default {
  name: 'MislakaCredsToUpdate',
  components: {
    CredentialsCard,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    RiseupButton: BaseUI.RiseupButton,
  },
  mixins: [ConsentActionsMixin],
  async created() {
    Segment.trackUserGot('MislakaCredsToUpdate_Entered');
  },
  data() {
    return {
      loadingCreds: {},
    };
  },
  computed: {
    ...mapState('credentialsNew', ['credentialsWithOldConsentConfiguration', 'credentialsToMigrateToOBKConfiguration']),
    ...mapGetters('credentialsNew', ['unvalidatedCredentials']),
    credsInConnectionProccess() {
      return this.unvalidatedCredentials;
    },
    continueButtonEnabled() {
      return this.credentialsToMigrateToOBKConfiguration.length === 0
          && this.credentialsWithOldConsentConfiguration.length === 0;
    },

  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    reconnectConsent(source) {
      Segment.trackUserGot(
        'MislakaCredsToUpdate_ReconnectConsentClicked',
        { source: source.sourceName, credentialsId: source.credentialsId },
      );
      this.loadingCreds = { ...this.loadingCreds, [source.credentialsId]: true };

      localStorage.setItem(LOCAL_STORAGE_KEY_UPDATE_OLD_CREDENTIALS, moment().toLocaleString());
      this.redirectToGetAccessCode({
        source,
        credentialsToUpdateMetaData: { consentId: source.openBankingConsentId, credentialsId: source.credentialsId },
      });
    },
    migrateToOBK(source) {
      Segment.trackUserGot(
        'MislakaCredsToUpdate_MigrateToOBKClicked',
        { source: source.sourceName, credentialsId: source.credentialsId },
      );
      this.openModal({
        component: MigrateToOBKFlow,
        props: {
          source,
        },
      });
    },
    next() {
      Segment.trackUserGot('MislakaCredsToUpdate_NextClicked');
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.mislaka-creds-to-update{

  height: 100%;
  text-align: right;
  height: 100%;
  .top-title {
    color: $riseup_blue;
  }

  ::v-deep .snack-bar {
    &.warning {
      background: $riseup_dark_yellow;
    }
  }
}

</style>
