<template>
  <popup class="async-fallback-popup">
    <template v-slot:content>
      <div class="async-fallback-popup-content">
        <div class="image-wrapper">
          <img src="/images/onboarding/creds-async-fallback.svg" class="fallback-image">
        </div>
        <div class="ri-bold-title ri-alte">נראה שהחיבור לוקח יותר זמן מהרגיל</div>
        <div class="description">
          המערכת תמשיך לנסות אבל לא צריך להמתין ואפשר לסגור את החלון.
          <span class="bold">אנחנו נעדכן אותך בוואטסאפ</span> בהתקדמות ונמשיך בהרשמה.
        </div>
      </div>
    </template>
  </popup>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';

export default {
  name: 'AsyncFallbackPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  async created() {
    Segment.trackUserGot('AsyncTimeoutPopupShown');
    await this.publishAsyncFallback();
  },
  methods: {
    ...mapActions('onboardingState', ['publishAsyncFallback']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../scss/mixins';

.async-fallback-popup {
  width: 100%;

  .async-fallback-popup-content {
    text-align: center;
    @include responsive-padding;

    .image-wrapper {
      width: 100%;
      margin: 40px 0 40px;

      .fallback-image {
        width: 120px;
      }
    }

    .description {
      font-size: 16px;
      font-family: SimplerPro;
      line-height: 1.5;

      .bold {
        font-weight: bold;
      }
    }
  }
}
</style>
