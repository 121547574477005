const state = {
  errorType: '',
  ERROR_TYPES: {
    SERVER_IS_DOWN: 'SERVER_IS_DOWN',
    SERVER_ERROR: 'SERVER_ERROR',
    NETWORK_ERROR: 'NETWORK_ERROR',
    INVITE_MEMBER_ERROR: 'INVITE_MEMBER_ERROR',
    PII_UNAVAILABLE: 'PII_UNAVAILABLE',
  },
};

const getters = {};

const actions = {
  setErrorType({ commit }, errorType) {
    commit('setErrorType', errorType);
  },
  resetError({ commit }) {
    commit('setErrorType', '');
  },
};

const mutations = {
  setErrorType(state, errorType) {
    state.errorType = errorType;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
