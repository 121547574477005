<template>
  <div class="challenge-selected-celebration-popup">
    <colored-popup>

      <template v-slot:top-content>
        <div class="flex-row justify-content-center">
          <img :src="require('@/assets/great-success.png')" class="great-success-asset-in-full-screen" />
        </div>
        <div class="title">
          <div class="bold">מעולה!</div>
          <div>הגדרת לעצמך יעד חודשי וביחד נעמוד בו ונתחיל לחסוך!</div>
        </div>
      </template>

      <template v-slot:bottom-content>
        <callout v-if="!isPredictedCashflowPositive" iconPath="icons/heart-icon.svg" title="טיפ מאיתנו" class="insight-callout">
          <template v-slot:text>
            כדי להיות על זה במהלך החודש, נשלח לך בוואטסאפ בדיוק כמה נשאר להוציא!
          </template>
        </callout>
        <riseup-button :action="close"
                       :variant="'primary'"
                       :size="'slim'"
                       title="סגור"/>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapGetters } from 'vuex';

export default {
  name: 'ChallengeSelectedCelebrationPopup',
  created() {
    Segment.trackUserInteraction('ChallengeSelectedCelebrationPopup_Shown');
  },
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    Callout: BaseUI.Callout,
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    isPredictedCashflowPositive() {
      return this.predictedBalanceAtEndOfMonth > 0;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
  .title {
    font-size: 22px;
    line-height: 28px;
    .bold {
      font-weight: bold;
    }
  }
  .celebration-image {
    width: 100%;
  }
  .insight-callout {
    text-align: right;
    padding: 16px;
    margin-bottom: 20px;
  }
</style>
