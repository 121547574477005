import Vue from 'vue';
import _ from 'lodash';
import DDLogs from '@/DDLogs';
import BillyApi from '@/api/BillyApi';
import MetricsApi from '../../api/MetricsApi';
import Segment from '../../Segment';
import PaymeInterface from '../../Payme';

export const CREDIT_CARD_FIELDS = {
  cardNumber: 'cardNumber',
  expiration: 'expiration',
  cvv: 'cvv',
  socialId: 'payerSocialId',
};

export const PERSONAL_INFO_FIELDS = {
  firstName: 'payerFirstName',
  lastName: 'payerLastName',
  phoneNumber: 'payerPhone',
};

function _handlePaymentError(error) {
  const isKnownError = error && error.payload && error.payload.status_error_code;
  const errorDetails = isKnownError ? error.payload.status_error_details : 'unknown';
  const errorCode = isKnownError ? error.payload.status_error_code : undefined;
  const errorMessage = error && error.message ? error.message : undefined;

  if (isKnownError) {
    MetricsApi.sendCounter('payment.tokenize.error', { payme_status_error_code: errorCode });
    DDLogs.error('Payment error', { errorDetails, errorCode, errorMessage });
  } else {
    MetricsApi.sendCounter('payment.tokenize.unknown-error');
  }
}

const state = {
  fields: null,
  personalInfo: {
    [PERSONAL_INFO_FIELDS.firstName]: { value: null, isValid: false },
    [PERSONAL_INFO_FIELDS.lastName]: { value: null, isValid: false },
    [PERSONAL_INFO_FIELDS.phoneNumber]: { value: null, isValid: false },
  },
  creditCard: {
    [CREDIT_CARD_FIELDS.cardNumber]: { isEmpty: true, isValid: false, error: '' },
    [CREDIT_CARD_FIELDS.expiration]: { isEmpty: true, isValid: false, error: '' },
    [CREDIT_CARD_FIELDS.cvv]: { isEmpty: true, isValid: false, error: '' },
    [CREDIT_CARD_FIELDS.socialId]: { isEmpty: true, isValid: false, error: '' },
  },
  tokenizationError: null,
  creditCardIsValid: false,
  loadingToken: false,
  loadingBenefit: false,
  eligibilityError: null,
  paymeToken: null,
  didEnteredCcFields: false,
  type: null,
  pricing: {
    annual: null,
    monthly: null,
    advisory: null,
  },
  enableTerms: false,
  cardNumberMask: null,
};

function trackValidationFailures(cardIsValid) {
  if (!cardIsValid) {
    _.chain(state.creditCard)
      .pickBy(value => !value.isValid)
      .keys()
      .forEach(field => Segment.trackUserGot('CreditCardValidationFailed', { field }))
      .value();
  }
}

const getters = {
  creditCardFields: () => CREDIT_CARD_FIELDS,
  formattedCardNumberMask: state => {
    if (state.cardNumberMask) {
      const lastFourDigits = state.cardNumberMask.slice(-4);
      return `${lastFourDigits} ••••`;
    }
    return null;
  },
};

const actions = {
  async fetchCreditCardMask({ commit }) {
    try {
      const creditCardDetails = await BillyApi.getCreditCardDetails();
      if (creditCardDetails) {
        commit('setCardNumberMask', creditCardDetails.cardNumberMask);
      }
    } catch (error) {
      commit('setCardNumberMask', undefined);
    }
  },
  setCreditCardField({ commit }, { field, isEmpty, isValid }) {
    if (!state.didEnteredCcFields) {
      commit('setDidEnteredCcFields', true);
    }
    commit('setCreditCardField', { field, isEmpty, isValid, error: '' });
  },
  setCreditCardValidations({ commit, state }) {
    if (!state.creditCard[CREDIT_CARD_FIELDS.cardNumber].isValid) {
      commit('setCreditCardValidationError', { field: CREDIT_CARD_FIELDS.cardNumber, error: 'אפשר לבדוק שוב את מספר הכרטיס?' });
    }
    if (!state.creditCard[CREDIT_CARD_FIELDS.expiration].isValid) {
      commit('setCreditCardValidationError', { field: CREDIT_CARD_FIELDS.expiration, error: 'התוקף לא נכון' });
    }
    if (!state.creditCard[CREDIT_CARD_FIELDS.cvv].isValid) {
      commit('setCreditCardValidationError', { field: CREDIT_CARD_FIELDS.cvv, error: '3 ספרות בגב הכרטיס' });
    }
    if (!state.creditCard[CREDIT_CARD_FIELDS.socialId].isValid) {
      commit('setCreditCardValidationError', { field: CREDIT_CARD_FIELDS.socialId, error: 'אפשר לבדוק שוב את מספר תעודת הזהות?' });
    }
    const cardIsValid = _.every(state.creditCard, field => field.isValid);
    commit('setCreditCardIsValid', cardIsValid);
    trackValidationFailures(cardIsValid);
    return cardIsValid;
  },
  async teardownPaymeInterface({ commit }) {
    const paymeInterface = await PaymeInterface.teardown();
    if (paymeInterface) {
      commit('setFieldsInstance', paymeInterface);
    }
  },
  async initPaymeInterface({ commit }) {
    const paymeInterface = await PaymeInterface.initOrGet();
    if (paymeInterface) {
      commit('setFieldsInstance', paymeInterface);
    }
  },
  async getOrCreatePaymentToken({ state, dispatch, commit }) {
    await dispatch('setCreditCardValidations');
    if (state.creditCardIsValid) {
      Segment.trackUserGot('PaymentSubmitSucceededOnValidation');
      commit('setLoadingToken', true);
      try {
        const paymeToken = state.paymeToken ? state.paymeToken : await dispatch('createPaymentToken');
        commit('setPaymeToken', paymeToken);
        commit('setLoadingToken', false);
        return true;
      } catch (error) {
        _handlePaymentError(error);
        commit('setTokenizationError', error);
        return false;
      }
    } else {
      Segment.trackUserGot('PaymentSubmitFailedOnValidation', { validations: state.creditCard });
      return false;
    }
  },
  setPaymeToken(state, paymeToken) {
    state.paymeToken = paymeToken;
  },
  async createPaymentToken({ state, rootState }) {
    const { customerId } = rootState.session;
    const sale = {
      payerFirstName: state.personalInfo[PERSONAL_INFO_FIELDS.firstName].value,
      payerLastName: state.personalInfo[PERSONAL_INFO_FIELDS.lastName].value,
      payerPhone: state.personalInfo[PERSONAL_INFO_FIELDS.phoneNumber].value.replace('-', ''),
      payerEmail: `${customerId}@riseup.co.il`,
      total: {
        label: 'תשלום לרייזאפ',
        amount: {
          currency: 'ILS',
          value: '45.00',
        },
      },
    };
    Segment.trackUserGot('PaymentTokenize');
    const tokenRequest = await state.fieldsInstance.tokenize(sale);
    Segment.trackUserGot('PaymentTokenizeSucceeded');
    const { token, card } = tokenRequest;
    return { token, card };
  },
  reset({ commit }) {
    commit('setTokenizationError', null);
  },
  setPersonalInfoField({ commit }, { field, value, isValid, errorMessage }) {
    commit('setPersonalInfoField', { field, value, isValid, errorMessage });
  },
};

const mutations = {
  setCardNumberMask(state, cardNumberMask) {
    state.cardNumberMask = cardNumberMask;
  },
  setPaymeToken(state, paymeToken) {
    state.paymeToken = paymeToken;
  },
  setDidEnteredCcFields(state, didEnteredCcFields) {
    state.didEnteredCcFields = didEnteredCcFields;
  },
  setFieldsInstance(state, { fieldsInstance, fields }) {
    state.fieldsInstance = fieldsInstance;
    state.fields = fields;
  },
  setPersonalInfoField(state, { field, value, isValid }) {
    Vue.set(state.personalInfo, field, { value, isValid });
  },
  setCreditCardField(state, { field, isEmpty, isValid, error }) {
    Vue.set(state.creditCard, field, { isEmpty, isValid, error });
  },
  setTokenizationError(state, error) {
    state.tokenizationError = error;
  },
  setPersonalInfoValidationError(state, { field, error }) {
    Vue.set(state.personalInfo, field, _.extend({}, { error }, state.personalInfo[field]));
  },
  setCreditCardValidationError(state, { field, error }) {
    Vue.set(state.creditCard, field, _.extend({}, state.creditCard[field], { error }));
  },
  setCreditCardIsValid(state, isValid) {
    state.creditCardIsValid = isValid;
  },
  setLoadingToken(state, loading) {
    state.loadingToken = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
