import Segment from '@/Segment';
import shareUtils from '@/utils/share';

const eventTypes = {
  impression: 'ShareImpression',
  shareIntentClicked: 'ShareIntentClicked',
  sharePopupShown: 'SharePopupShown',
  sharePopupClosed: 'SharePopupClosed',
  nativeShareClicked: 'ShareNativeButtonClicked',
  shareSucceeded: 'ShareSucceeded',
  shareFailed: 'ShareFailed',
  shareCanceled: 'ShareCanceled',
  downloadSucceeded: 'DownloadSucceeded',
};

function trackImpression(props) {
  Segment.trackUserGot(eventTypes.impression, _getPropsForShare(props));
}

function trackShareIntentClicked(props) {
  Segment.trackUserInteraction(eventTypes.shareIntentClicked, _getPropsForShare(props));
}

function trackSharePopupShown(props) {
  Segment.trackUserGot(eventTypes.sharePopupShown, _getPropsForShare(props));
}

function trackSharePopupClosed(props) {
  Segment.trackUserInteraction(eventTypes.sharePopupClosed, _getPropsForShare(props));
}

function trackNativeShareClicked(props) {
  Segment.trackUserInteraction(eventTypes.nativeShareClicked, _getPropsForShare(props));
}

function trackShareSucceeded(props) {
  Segment.trackUserGot(eventTypes.shareSucceeded, _getPropsForShare(props));
}

function trackShareFailed(props) {
  Segment.trackUserGot(eventTypes.shareFailed, _getPropsForShare(props));
}

function trackShareCanceled(props) {
  Segment.trackUserGot(eventTypes.shareCanceled, _getPropsForShare(props));
}

function trackDownloadSucceeded(props) {
  Segment.trackUserGot(eventTypes.downloadSucceeded, _getPropsForShare(props));
}

function _getPropsForShare(props = {}) {
  return {
    ...props,
    download: !shareUtils.webShareSupported(),
    isWebShareSupported: shareUtils.webShareSupported(),
  };
}

export default {
  trackImpression,
  trackShareIntentClicked,
  trackSharePopupShown,
  trackSharePopupClosed,
  trackNativeShareClicked,
  trackShareSucceeded,
  trackShareFailed,
  trackShareCanceled,
  trackDownloadSucceeded,
};
