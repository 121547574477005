const state = {
  isShown: false,
};

const getters = {
  shouldShowPopup: state => !state.isShown,
};

const actions = {
  setIsShownTrue({ commit }) {
    commit('setIsShown', true);
  },

};

// mutations
const mutations = {
  setIsShown(state, isShown) {
    state.isShown = isShown;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
