<template>
  <dynamic-deposit-step :authenticated="false" :step="this.$options.name">
    <template v-slot:content>
      <div class="dd-choose-opportunistic-amount">
        <div class="dd-headline-label ri-bold-body">בחירת סכום</div>
        <div class="ri-large-headline margin-bottom-s">כמה להעביר לקופה?</div>
        <div>
          <amount-input
            :label="'סכום הפקדה חד-פעמית'"
            :value="addToSaving"
            :error-message="errorMessage"
            @input="setOpportunisticDepositSum"
          />
        </div>
        <callout v-if="monthlyPlansTotalAmount > 0" iconPath="icons/heart-icon.svg" title="כדאי לשים לב" class="insight-callout">
          <template v-slot:text>
            {{ monthlyPlansTotalAmount }} ₪ מתוך התזרים החיובי החודש,  מיועדים לתכניות הגדולות שלך.
          </template>
        </callout>
      </div>
    </template>
    <template v-slot:next-button>
      <dynamic-deposit-button title="הבא >" :action="verifyDepositSum" :loading="loading" :disabled="buttonDisabled"/>
    </template>
  </dynamic-deposit-step>
</template>

<script>

import * as _ from 'lodash';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';
import { mapState, mapGetters, mapMutations } from 'vuex';
import * as SavingsApi from '@/api/SavingsApi';

import DynamicDepositStep from './DynamicDepositStep';
import DynamicDepositButton from './DynamicDepositButton';

export default {
  name: 'DDChooseOpportunisticAmount',
  components: {
    AmountInput: BaseUI.AmountInput,
    DynamicDepositButton,
    DynamicDepositStep,
    Callout: BaseUI.Callout,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    showErrorPage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      addToSaving: 0,
    };
  },
  created() {
    Segment.trackUserInteraction('DynamicDeposit_ChooseOpportunisticAmount_Entered');
    const previouslySelectedDepositSum = this.depositSum;
    this.addToSaving = previouslySelectedDepositSum || this.proposedSavingsAmount;
    this.setOpportunisticDepositSum(this.addToSaving);
  },
  computed: {
    ...mapState('dynamicSavings', ['proposedSavingsAmount']),
    ...mapGetters('dynamicSavings', ['depositSum']),
    ...mapGetters('planAhead', ['ongoingPlans']),
    buttonDisabled() {
      return _.isNil(this.addToSaving) || !!this.errorMessage;
    },
    monthlyPlansTotalAmount() {
      return _.sumBy(this.ongoingPlans, ongoingPlan => ongoingPlan.monthlyTarget);
    },
  },
  methods: {
    ...mapMutations('dynamicSavings', ['setDepositSum', 'setProposedSavingsAmount', 'setBankDetails', 'setFundDetails']),
    setOpportunisticDepositSum(value) {
      this.errorMessage = '';
      this.addToSaving = value;
      if (!_.isNil(value) && value < 100) {
        this.errorMessage = 'הסכום המינימלי להפקדה הוא 100 ₪';
      }
    },
    async verifyDepositSum() {
      this.loading = true;
      Segment.trackUserInteraction('DynamicDeposit_ChooseOpportunisticAmount_NextClicked');
      Segment.trackUserInteraction(
        'DynamicDeposit_ChooseOpportunisticAmount_EditAmount',
        { diff: this.addToSaving - this.proposedSavingsAmount },
      );

      this.loading = true;
      try {
        const verifyResponse = await SavingsApi.verifyDepositSum(this.addToSaving, false);
        if (verifyResponse.status === SavingsApi.VERIFY_DEPOSIT_RESPONSE_TYPES.SUCCESS) {
          this.setDepositSum(this.addToSaving);
          const { data: { bankNumber, bankBranch, bankAccountNumber, fundNumber } } = verifyResponse;
          this.setBankDetails({ bankNumber, bankBranch, bankAccountNumber });
          this.setFundDetails({ fundNumber });
          this.nextStep();
        } else if (verifyResponse.status === SavingsApi.VERIFY_DEPOSIT_RESPONSE_TYPES.DEPOSIT_EXCEED_LIMIT) {
          Segment.trackUserInteraction('DynamicDeposit_ChooseOpportunisticAmount_ExceedLimit');
          this.errorMessage = `הסכום שהזנת עובר את התקרה השנתית, אפשר להפקיד השנה עד ${moneyUtils.formatAmount(verifyResponse.data.maxDeposit)} ₪`;
        } else {
          this.showErrorPage();
        }
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>
