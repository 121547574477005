<template>
  <insight :insight="insight"
           :animationName="'rolling-credit.json'"
           :button-text="'ספרו לי עוד'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div v-if="isDebtTypeTotal">
        ב{{ budgetMonth }} העברת
        <span class="bold">חוב של {{ formattedAmount }} ₪. </span>
        בחיוב חודשי קבוע. זו הלוואה בתנאים לא טובים, אבל אפשר לשפר את המצב!
      </div>
      <div v-else>
        ב{{ budgetMonth }} <span class="bold">גדל חוב</span> שקיים בחיוב חודשי קבוע <span class="bold">ב־{{ formattedAmount }} ₪.</span>
        זו הלוואה בתנאים לא טובים, אבל אפשר לשפר את המצב!
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import Segment from '@/Segment';
import moneyUtils from '@/utils/money';
import RollingCreditCommunityPopup from './components/rolling-credit/RollingCreditCommunityPopup';
import Insight from './components/Insight';
import dateUtils from '../../../../../../utils/dates';
import consts from './components/rolling-credit/consts';

export default {
  name: 'RollingCreditInsight',
  components: {
    Insight,
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
  },
  computed: {
    isDebtTypeTotal() {
      return this.insight.debtType === consts.DebtType.Total;
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.debt, 0);
    },
    budgetMonth() {
      return dateUtils.getHebrewMonth(moment(this.insight.budgetDate)
        .subtract(1, 'month')
        .format('YYYY-MM'));
    },
    DebtType() {
      return consts.DebtType;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      Segment.trackUserInteraction('RollingCredit_Insight_Clicked');
      this.openModal({
        component: RollingCreditCommunityPopup,
        props: {
          insight: this.insight,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
  white-space: pre;
}
</style>
