<template>
  <div class="advisory-flow" v-if="initialized">
    <div class="content">
      <advisory-intro-1 v-if="index === 0" @next="next" @close="close" />
      <advisory-steps v-if="index === 1" @next="next" @back="back" @close="close" :backwards="advisoryStepsBackwards"/>
      <advisory-review-2 v-if="index === 2" @next="next" @back="back"/>
      <one-off-landing-page v-if="index === customerSummaryPageIndex" @next="next" @back="back" @close="close" />
      <payment-page v-if="index === paymentPageIndex" @back="back" @next="next" @close="close" :insight="insight "/>
      <mislaka-consent-flow v-if="index === 5" @next="next" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Segment from '@/Segment';
import _ from 'lodash';
import router from '@/router';
import MislakaConsentFlow from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaNewConsentFlow';
import PaymentPage from '../payment/PaymentPage';
import OneOffLandingPage from '../offerings/one-off/OneOffLandingPage.vue';
import AdvisorySteps from './AdvisorySteps';
import AdvisoryIntro1 from './AdvisoryIntro1.vue';
import AdvisoryReview2 from './AdvisoryReview2.vue';

export default {
  name: 'AdvisoryFlow',
  components: {
    OneOffLandingPage,
    MislakaConsentFlow,
    AdvisorySteps,
    AdvisoryIntro1,
    PaymentPage,
    AdvisoryReview2,
  },
  props: {
    insight: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      index: 0,
      advisoryStepsBackwards: false,
    };
  },
  async created() {
    const utmParams = _.pickBy(router.currentRoute.query, (value, key) => (key.startsWith('utm_') && value));
    const moveToPaymentPage = router.currentRoute.query.showPayment;
    const moveToCustomerSummary = router.currentRoute.query.summary;
    Segment.trackUserGot('AdvisoryFlow', { ...utmParams, moveToPaymentPage, moveToCustomerSummary });
    await this.init();
    await this.selectOffering({ offeringId: this.offeringsTypes.ADVISORY });
    if (moveToPaymentPage) {
      this.index = this.paymentPageIndex;
    }

    if (moveToCustomerSummary) {
      this.index = this.customerSummaryPageIndex;
    }
  },
  computed: {
    ...mapGetters('oneOffOfferings', ['offeringsTypes']),
    ...mapState('oneOffOfferings', ['initialized']),
    customerSummaryPageIndex() {
      return 3;
    },
    paymentPageIndex() {
      return 4;
    },
  },
  methods: {
    ...mapActions('oneOffOfferings', ['init']),
    ...mapMutations('oneOffOfferings', ['selectOffering']),
    next() {
      this.index++;
      if (this.index === 3) {
        this.advisoryStepsBackwards = true;
      }
    },
    back() {
      this.index--;
      if (this.index === 1) {
        this.advisoryStepsBackwards = false;
      }
    },
    close() {
      router.push({ path: '/sr' });
    },
  },
};
</script>

<style scoped lang="scss">
.advisory-flow {
  height: 100%;
  overflow-y: auto;

  .content {
    height: 100%;
  }
}
</style>
