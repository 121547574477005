import _ from 'lodash';
import moment from 'moment';
import { OFFERING_TYPES } from '@/api/BillyApi';
import { SUBSCRIPTION_STATES } from '@/constants/credentials';

export const SUBSCRIPTIONS_IDS = {
  MONTHLY: 'cashflow-monthly-subscription',
  ANNUAL: 'cashflow-annual-subscription',
};

export function fillSubscriptionOfferingsDisplayInfo(subscriptionOfferings, subscriptionState) {
  const monthlySubscription = subscriptionOfferings.find(({ id }) => id === SUBSCRIPTIONS_IDS.MONTHLY);
  const annualSubscription = subscriptionOfferings.find(({ id }) => id === SUBSCRIPTIONS_IDS.ANNUAL);

  return {
    [SUBSCRIPTIONS_IDS.MONTHLY]: getMonthlySubscriptionDisplayInfo(monthlySubscription, subscriptionState),
    [SUBSCRIPTIONS_IDS.ANNUAL]: getAnnualSubscriptionDisplayInfo(annualSubscription),
  };
}

function getMonthlySubscriptionDisplayInfo(monthlySubscriptionFromBackend, subscriptionState) {
  const isDormant = _.includes([SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL, SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED], subscriptionState);
  const isPretrial = SUBSCRIPTION_STATES.PRE_TRIAL === subscriptionState;
  const title = isPretrial ? 'מינוי רייזאפ - חודש ניסיון' : 'מינוי רייזאפ';
  const importantSubtitlePart = isDormant ? '' : 'בחיוב חודשי של 45 ₪ ';
  const subTitle = isDormant ? 'על מנת להמשיך איתנו לתוכנית עליך לשלם על מינוי בעמוד הבא.' : ' שיחל רק לאחר תקופת הניסיון.';

  return {
    offerId: 'cashflow-monthly-subscription',
    title,
    importantSubtitlePart,
    subTitle,
    type: OFFERING_TYPES.SUBSCRIPTION,
    hideAmount: !isDormant,
    subscriptionStartDate: calcSubscriptionStartDate(
      monthlySubscriptionFromBackend.trialPeriod.number,
      monthlySubscriptionFromBackend.trialPeriod.unit,
    ),
    amount: monthlySubscriptionFromBackend.price.amount ?? 0,
  };
}

function getAnnualSubscriptionDisplayInfo(annualSubscriptionDataFromBackend) {
  return {
    offerId: 'cashflow-annual-subscription',
    title: 'מינוי רייזאפ שנתי',
    importantSubtitlePart: 'בחיוב של 430 ₪ ',
    subTitle: 'שיחל רק לאחר תקופת הניסיון.',
    type: OFFERING_TYPES.SUBSCRIPTION,
    subscriptionStartDate: calcSubscriptionStartDate(
      annualSubscriptionDataFromBackend.trialPeriod.number,
      annualSubscriptionDataFromBackend.trialPeriod.unit,
    ),
    amount: annualSubscriptionDataFromBackend.price.amount ?? 0,
  };
}

function calcSubscriptionStartDate(trialPeriodNumber, trialPeriodUnit) {
  return moment().add(trialPeriodNumber, trialPeriodUnit);
}
