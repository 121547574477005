<template>
  <div class="category-block" :class="className"
       v-on:click="onClick">
    <card :has-content-padding="false">
      <div class="block">
        <div class="icon-name-container">
          <img :src="require(`@/assets/tracking-categories/${category.iconName}`)">
          <div class="name ri-bold-title">
            <div v-for="word of category.label.split(' ')" class="word" :key="word">{{ word }}</div>
          </div>
        </div>
        <div class="data-arrow-container">
          <div v-if=categoryAvg class="data-section">
            <div class="text">ממוצע הוצאות:</div>
            <div class="amount">{{ categoryAvg }}<span class="small-amount"> ₪</span></div>
          </div>
          <div class="arrow">
            <img :src="require('@/assets/icons/arrow_enabled_left.svg')">
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';

export default {
  name: 'CategoryBlock',
  components: {
    Card: BaseUI.Card,
  },
  props: {
    category: {
      type: Object,
      require: true,
    },
    selectCategory: {
      type: Function,
      require: true,
    },
    className: {
      type: String,
      require: false,
    },
  },
  computed: {
    categoryAvg() {
      return moneyUtils.formatAmount(Math.round(this.category.historyAverage));
    },
  },
  methods: {
    onClick(event) {
      this.selectCategory(this.category);
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
  .category-block {
    display: flex;
    flex: 1;
    align-items: center;
    cursor: pointer;

    .block {
      display: flex;
      flex: 1;
      justify-content: space-between;
      height: 80px;
      padding: 0 10px;
    }
    .icon-name-container {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 56px;
        height: 51px;
      }

      .name {
        text-align: start;
        padding-right: 10px;
      }
    }
    .data-arrow-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .data-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      min-width: 92px;

      .text {
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 10px;
      }

      .amount {
        color: $riseup_blue;
        font-weight: bold;
        font-size: 22px;
        line-height: 8px;
        position: relative;
        right: 2px;

        .small-amount {
          font-size: 16px;
        }
      }
    }
    .arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 3px;
      margin-right: 10px;

      img {
        width: 10px;
        height: 13px;
      }
    }
  }
</style>
