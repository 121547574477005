import _ from 'lodash';
import config from './config';

const TABS_HEIGHT = 60;
const LAUNCHER_ICON_PADDING = 8;
/* eslint-disable */
export default {
  cashflowTabsEnabled: false,

  install(Vue, options) {
    includeIntercom();
  },

  async boot(customerId, householdName, extraAttributes, cashflowTabsEnabled) {
    const { intercomEnabled, appId, normalizedExtraAttributes } = _getIntercomAttributes(extraAttributes);
    if (intercomEnabled === "true") {
      window.Intercom('boot', {
        app_id: appId,
        user_id: customerId,
        name: householdName,
        ...normalizedExtraAttributes,
      })
    }
    if (cashflowTabsEnabled) {
      this.cashflowTabsEnabled = true;
      this.adjustLauncherPositionForTabs();
    }
  },
  show() {
    window.Intercom('show');
  },
  hide() {
    window.Intercom('hide');
  },
  async update(customerId, householdName, extraAttributes, cashflowTabsEnabled) {
    const { intercomEnabled, appId, normalizedExtraAttributes } = _getIntercomAttributes(extraAttributes);
    if (intercomEnabled === "true") {
      window.Intercom('update', {
        app_id: appId,
        user_id: customerId,
        name: householdName,
        ...normalizedExtraAttributes,
      })
      if (cashflowTabsEnabled) {
        this.cashflowTabsEnabled = true;
        this.adjustLauncherPositionForTabs();
      }
    }
  },
  adjustLauncherPositionForTabs() {
    setTimeout(() => {
      const intercomLauncherButton = document.querySelector('.intercom-launcher-frame')?.parentElement || document.querySelector('.intercom-lightweight-app-launcher');
      if (intercomLauncherButton) {
        intercomLauncherButton.style['bottom'] = `${TABS_HEIGHT + LAUNCHER_ICON_PADDING}px`;
      }
    }, 0);
  },
  hideLauncher() {
    window.Intercom('update', {
      'hide_default_launcher': true
    });
  },
  showLauncher() {
    window.Intercom('update', {
      'hide_default_launcher': false
    });
    if (this.cashflowTabsEnabled) {
      this.adjustLauncherPositionForTabs();
    }
  },
  showNewMessage(prompt = '') {
    window.Intercom('showNewMessage', prompt);
  },
};
function includeIntercom() {
  const w = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function() {
      i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function() {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/APP_ID';
      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}

function _getIntercomAttributes(extraAttributes) {
  const { intercomEnabled, intercomProdAppId } = config.get();
  const normalizedExtraAttributes = _.mapKeys(extraAttributes, (v, k) => _.snakeCase(k));
  return { intercomEnabled, appId: intercomProdAppId, normalizedExtraAttributes };
}
