<template>
  <img :src="image"
       class="expand-arrow"
       :class="{ 'expanded': expanded }" />
</template>

<script>
export default {
  name: 'ExpandArrow',
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      required: false,
      validator(value) {
        return ['medium', 'small'].indexOf(value) !== -1;
      },
      default: () => 'medium',
    },
    greyedOut: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    image() {
      if (this.size === 'medium') {
        // eslint-disable-next-line global-require
        return this.greyedOut ? require('@/assets/icons/expand-arrow-down-medium-disabled.svg')
        // eslint-disable-next-line global-require
          : require('@/assets/icons/expand-arrow-down-medium.svg');
      }
      // eslint-disable-next-line global-require
      return this.greyedOut ? require('@/assets/icons/expand-arrow-down-small-disabled.svg')
      // eslint-disable-next-line global-require
        : require('@/assets/icons/expand-arrow-down-small.svg');
    },
  },
};
</script>

<style scoped>

.expand-arrow{
  transition: transform 0.5s;
}

.expanded{
  transform: rotate(-180deg)
}
</style>
