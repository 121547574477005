import { client } from '@/http';

export default {
  async getLatestSeenTransactionCreationDate() {
    const response = await client.get('/api/latest-seen-transaction-creation-date');
    return response?.data?.latestSeenTransactionCreationDate;
  },
  async setLatestSeenTransactionCreationDate(latestSeenTransactionCreationDate) {
    return client.post('/api/latest-seen-transaction-creation-date', { latestSeenTransactionCreationDate });
  },
};
