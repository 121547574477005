<template>
  <div class="ativation-insight-popup">
    <colored-popup :class="colorVariant"
                   :close-action="close">
      <template v-slot:top-content>
        <div class="content">
          התחלת להסתכל קדימה, מה עכשיו?
          <div class="whats-next">הנה כמה פעולות קטנות שיעזרו לך לצאת לדרך</div>
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="actions">
          <activation-insight-popup-action :action="editAction" :title="'לוודא שכל ההוצאות במקום'" :icon="'icon_edit.svg'" />
          <Loader v-if="loading"/>
          <activation-insight-popup-action v-else-if="shouldSuggestChallenge" :action="addCategoryChallenge"
                                           :title="'להגדיר יעד חודשי לקטגוריה'" :icon="'challenge-action.svg'" />
          <activation-insight-popup-action v-else :action="addRecurringAction" :title="'להוסיף יעד חודשי לקטגוריה'" :icon="'icon_supermarket.svg'" />
          <activation-insight-popup-action :action="addPartnerAction"
                                           :title="'להוסיף בן או בת זוג בתזרים'" :icon="'icon_add_member.svg'" :is-last="true"/>
        </div>
        <separator />
        <div class="dont-show-again">
          <div class="text" v-on:click="dontShowAgain">הבנתי, אל תציגו לי יותר</div>
        </div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import router from '@/router';
import EventBus from '@/event-bus/event-bus';
import { mapState, mapActions, mapGetters } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
// eslint-disable-next-line max-len
import CreateTrackingCategoryPopupFlow from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-reccuring/CreateTrackingCategoryPopupFlow';
import ChallengeFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/ChallengeFlow';
import Loader from '@/base-components/Loader';
import ActivationInsightPopupAction from './ActivationInsightPopupAction.vue';

export default {
  name: 'ActivationInsightPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    ActivationInsightPopupAction,
    Separator: BaseUI.Separator,
    Loader,
  },
  props: {
    insight: { type: Object, required: true },
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.sendSegmentEvent('ActivationInsightPopup_Shown');
    this.loading = false;
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    ...mapGetters('featureFlags', ['showTrackingCategoryChallenge']),
    ...mapGetters('createTrackingCategory', ['currentDefaultTrackingCategories']),
    ...mapState('challenge', ['activeChallenge']),
    colorVariant() {
      return cashflowViewConsts.CATEGORY_STYLE[cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE];
    },
    shouldSuggestChallenge() {
      return this.predictedBalanceAtEndOfMonth < 0 && !this.activeChallenge && this.currentDefaultTrackingCategories.length
        && this.showTrackingCategoryChallenge;
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal']),
    async dontShowAgain() {
      await this.markAsRead(this.insight);
      this.$emit('close');
    },
    sendSegmentEvent(eventName, extraProps = {}) {
      Segment.trackUserInteraction(eventName, { ...this.insight, ...extraProps });
    },
    close() {
      this.sendSegmentEvent('ActivationInsightPopup_CloseClicked');
      this.$emit('close');
    },
    editAction() {
      this.sendSegmentEvent('ActivationInsightPopup_EditClicked');
      this.$emit('close');
      EventBus.$emit('expand-to-edit', { type: cashflowViewConsts.cashflowTypes.variableExpense });
    },
    addRecurringAction() {
      this.sendSegmentEvent('ActivationInsightPopup_AddRecurringClicked');
      this.$emit('close');
      this.openModal({
        component: CreateTrackingCategoryPopupFlow,
      });
    },
    addCategoryChallenge() {
      this.sendSegmentEvent('ActivationInsightPopup_AddCategoryChallenge');
      this.$emit('close');
      this.openModal({
        component: ChallengeFlow,
      });
    },
    addPartnerAction() {
      this.sendSegmentEvent('ActivationInsightPopup_AddPartnerClicked');
      this.$emit('close');
      router.push({ path: '/invite-member', query: this.$route.query });
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .ativation-insight-popup {

    #popup {
      .colored-top {
        text-align: center;
        color: $riseup_white;
        padding: 60px 30px;

        .content {
          margin-top: 30px;
          font-size: 18px;

          .whats-next {
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 24px;
            font-weight: bold;
            line-height: 34px;
          }
        }
      }

      .bottom {
        padding: 0;

        .actions {
          padding: 20px 30px;
        }

        .dont-show-again {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 60px;
          font-size: 18px;
          color: $riseup_black;

          .text {
            cursor: pointer;
          }
        }
      }
    }

    .spinner-wrapper {
      border-bottom: 1px solid $riseup_gray_0;
      padding: 7px;
    }
  }

</style>
