<template>
  <div class="score-question">
    <div class="title"> {{ title }}</div>
    <div class="score-container">
      <div class="score-box-container" v-for="score of scores" :key="score">
        <div :class="getClasses(score)" @click="onScoreClicked(score)"> {{ score }}</div>
        <span class="score-span">{{ getScoreText(score) }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ScoreQuestion',
  props: {
    title: {
      type: String,
      required: true,
    },
    onScoreChanged: {
      type: Function,
      required: true,
    },
    lowScoreText: {
      type: String,
      default: 'בכלל לא',
    },
    highScoreText: {
      type: String,
      default: 'מאוד',
    },
    defaultResult: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      selectedScore: this.defaultResult,
      scores: [1, 2, 3, 4, 5],
    };
  },
  methods: {
    getClasses(score) {
      return {
        'score-box': true,
        'score-box-selected': this.selectedScore === score,
      };
    },
    getScoreText(score) {
      const spanMap = {
        1: this.lowScoreText,
        5: this.highScoreText,
      };
      return spanMap[score];
    },
    onScoreClicked(score) {
      this.selectedScore = score;
      this.onScoreChanged(score);
    },
  },
  watch: {
    defaultResult() {
      this.selectedScore = this.defaultResult;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.score-question {
  display: flex;
  flex-direction: column;

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .score-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 70px 0;

    .score-box-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .score-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        border: solid 1px $riseup_gray_0;
        border-radius: 6px;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
      }

      .score-box-selected {
        font-weight: 700;
        border-color: $riseup_blue;
        color: $riseup_blue;
      }

      .score-span {
        font-size: 12px;
        color: #202125;
        margin-top: 12px;
        height: 12px;
      }
    }
  }
}

</style>
