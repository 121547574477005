<template>
  <button :class="['tag', { selected: isSelected }]"
          @click="clicked" >
    <span class="title">{{ title }}</span>
  </button>
</template>

<script>

export default {
  name: 'Tag',
  props: {
    title: {
      type: String,
      require: true,
    },
    isSelected: {
      type: Boolean,
      require: false,
    },
  },
  methods: {
    clicked() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>

@import '~@riseupil/base-ui/src/scss/riseup-colors';

.tag {
  margin: 8px 0px 6px 10px;
  padding: 4px 15px;
  border-radius: 20px;
  cursor: pointer;
  border-color: transparent;
  background-color: $riseup_light_blue;

  .title {
    font-family: SimplerPro;
    font-size: 14px;
    line-height: 24px;
    color: $riseup_blue;
  }

  &.selected {
    background-color: $riseup_blue;
    .title {
      color: $riseup_light_blue;
    }
  }
}
</style>
