<template>
  <div :class="['percircle', size]">
    <svg viewBox="0 0 120 120">
      <circle
        class="filler-bar circle-bar"
        :class="progressSize"
        cx="60"
        cy="60"
        r="50"
        fill="none"
      />

      <circle
        class="progress-bar circle-bar"
        :class="progressSize"
        cx="60"
        cy="60"
        r="50"
        fill="none"
        :stroke="progressBarColor"
        :pathLength="100"
        :stroke-dasharray="100"
        :stroke-dashoffset="fillerOffset"
      />
    </svg>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'CircularProgress',
  props: {
    progressBarColor: {
      type: String,
      default: BaseUI.Colors.riseupGreen,
    },
    // How full the bar is
    percent: {
      type: Number,
      validator: val => val >= 0 && val <= 100,
      default: 0,
    },
    progressSize: {
      type: String,
      validator: val => ['big', 'small'].includes(val),
      require: false,
    },
    size: {
      type: String,
      validator: val => ['big', 'medium', 'small'].includes(val),
      require: false,
    },
  },
  computed: {
    fillerOffset() {
      return 100 - this.percent;
    },
  },
};
</script>

<style lang="scss" scoped>
.percircle {
  position: relative;
  width: 30px;
  height: 30px;
  background: transparent;
  &.medium {
    width: 56px;
    height: 56px;
  }
  &.big {
    width: 70px;
    height: 70px;
  }
  svg {
    transform: rotate(-90deg);
  }
  .filler-bar {
    stroke: rgba(0, 0, 0, 0.1);
  }
  .circle-bar {
    stroke-width: 12;

    &.small {
      stroke-width: 5;
    }

    &.medium {
      stroke-width: 7;
    }
  }
}
</style>
