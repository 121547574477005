import DDLogs from '@/DDLogs';
import { client, clientWithoutErrorHandling } from '../http';

async function fetchInsights() {
  const response = await client.get('/api/insights/all');
  return response.data;
}

async function markAsRead(insightId, insightName, insight, dismissed) {
  const response = await client.post('/api/read-insights', { insightName, insightId, insight, dismissed });
  return response.data;
}

async function snooze(insight, timeAmount, timeType) {
  const response = await client.post('/api/snoozed-insights', { insight, snooze: { timeAmount, timeType } });
  return response.data;
}

async function sendInsightFeedback(insightId, insightName, insight, feedback) {
  const response = await client.post('/api/create-insight-feedback-input', { insightId, insightName, insight, feedback });
  return response.data;
}

async function sendInsightEvent(insight, interactionType) {
  try {
    const { insightId, insightName } = insight;
    await clientWithoutErrorHandling.post('/api/insight-events', { insightId, insightName, interactionType });
  } catch (error) {
    DDLogs.error('Error happened while saving insight event', { error });
  }
}

async function getWhatsappAlertsSettings() {
  const response = await client.get('/api/whatsapp-alerts/settings');
  return response.data;
}

async function updateWhatsappAlertsSettings(alertsSettings) {
  const response = await client.put('/api/whatsapp-alerts/settings', alertsSettings);
  return response.data;
}

export default {
  fetchInsights,
  markAsRead,
  snooze,
  sendInsightFeedback,
  sendInsightEvent,
  getWhatsappAlertsSettings,
  updateWhatsappAlertsSettings,
};
