<template>
  <colored-popup
    :back-action="back"
    class="danger connected-bank-cards-popup"
  >
    <template v-slot:top-content>
      <div class="header">
        רגע לפני שנמשיך, אלו החשבונות שמחוברים כרגע:
      </div>
    </template>
    <template v-slot:bottom-content>
      <span class="title">כדאי לוודא שהחשבון שרצית להוסיף באמת חסר.</span>
      <connected-credentials-card v-for="source in connectedBankCredentials" v-bind:key="source.credentialsId"
                                  :accounts="bankAccounts(source)"
                                  :always-expand="true"
                                  :name="source.name"
                                  :show-status="false"
                                  :source="source"
                                  :source-type="source.type"
                                  :sourceName="source.sourceName"
                                  class="account-wrapper"
      />
      <riseup-button :action="openConnectBankModal" :size="'slim'" :title="'להמשך חיבור החשבון'" :variant="'primary'" class='button'/>
      <riseup-button :action="openBankAlreadyConnectedModal" :size="'slim'" :title="'החשבון כבר מחובר'" :variant="'secondary'" class='button'/>
    </template>
  </colored-popup>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import ConnectedCredentialsCard from '@/base-components/onboarding/ConnectedCredentialsCard.vue';
import BaseUi from '@riseupil/base-ui';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow.vue';
import BankAlreadyConnectedSonInvestigationModal
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/merge/BankAlreadyConnectedSonInvestigationModal.vue';
import Segment from '@/Segment';

export default {
  name: 'ConnectedBanksSonInvestigationModal',
  components: {
    ConnectedCredentialsCard,
    ColoredPopup: BaseUi.ColoredPopup,
    RiseupButton: BaseUi.RiseupButton,
  },
  props: {
    investigation: {
      type: Object,
      required: true,
    },
    insightId: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('credentials', ['connectedBankCredentials']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal', 'closeAllModals']),

    bankAccounts(source) {
      return _.filter(source.accounts, { sourceType: 'bank' });
    },
    openConnectBankModal() {
      Segment.trackUserInteraction('SonsMergeInvestigationAddBankClicked', { investigation: this.investigation });
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          allowBackToSourceSelection: false,
          knownSourceType: 'bank',
        },
        popupAlignment: 'full-screen',
      });
    },
    openBankAlreadyConnectedModal() {
      Segment.trackUserInteraction('SonsMergeInvestigationBankAlreadyConnectedClicked', { investigation: this.investigation });
      this.openModal({
        component: BankAlreadyConnectedSonInvestigationModal,
        props: {
          investigation: this.investigation,
          insightId: this.insightId,
        },
      });
    },
    back() {
      Segment.trackUserInteraction('SonsMergeInvestigationConnectedBanksBackClicked', { investigation: this.investigation });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.accounts {
  text-align: right;
}

.account-wrapper {
  margin: 30px 0;
}

.header {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: $riseup_white;
}

.connected-bank-cards-popup {
  text-align: right;
}

.button {
  margin: 15px 0 0 0;
}

.title {
  font-size: 18px;
  line-height: 24px;
}
</style>
