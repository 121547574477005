<template>
  <insight :insight="insight"
            animation-name="ReferralMissingPaymentDetailsInsight.json"
           :button-text="'להזנת פרטי קבלת תשלום'"
           :show-dismiss="false"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div class="ri-title">
        חברה או חבר הצטרפו לרייזאפ, גם עשית טוב וגם מגיע לך כסף על זה! לאיזה חשבון להעביר את הכסף?
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import router from '@/router';
import Insight from './components/Insight';

export default {
  name: 'ReferralMissingPaymentDetailsInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('ReferralMissingPaymentDetails_Shown');
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    buttonAction() {
      Segment.trackUserInteraction('ReferralMissingPaymentDetails_ButtonClicked');
      this.markAsRead(this.insight);
      router.push({ path: '/ambassadors', query: { showPaymentForm: 'true' } });
    },
  },
};

</script>
