import { client } from '@/http';

async function getReferralEvents() {
  const response = await client.get('/api/referral-events');
  return response.data;
}

async function shouldAskForPaymentDetails() {
  const response = await client.get('/api/referrer/should-ask-payment-details');
  return response.data;
}

async function getReferrerInfo() {
  const response = await client.get('/api/referrers/with-pii');
  return response.data;
}

async function updatePaymentDetails(paymentDetails) {
  const response = await client.put('/api/referrers', { paymentDetails });
  return response.data;
}

async function hasReferralEvents() {
  const response = await client.get('/api/partial-auth/has-referral-events');
  return response.data;
}

export default {
  getReferralEvents,
  shouldAskForPaymentDetails,
  updatePaymentDetails,
  getReferrerInfo,
  hasReferralEvents,
};
