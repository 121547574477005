<template>
  <category-numeric-input-popup
      title="הגדרת יעד חודשי להוצאה"
      button-text="שמירת יעד חודשי!"
      label-text="צפוי לצאת"
      @back-clicked="back"
      @save="save"
      :displayErrorMessage="displayErrorMessage"
      :progress-title="categoryName"
      :cashculations="cashculations"
      :category="cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING"
      :place-holder="avgCategoryActualsAmount ? avgCategoryActualsAmount.toFixed(1) : 0" :error-message="errorMessage">
    <template v-slot:sub-title>
      <div class="title">
        <span class="bold" v-if="avgCategoryActualsAmount">
          בחודשים קודמים הוצאת בממוצע {{ avgCategoryActualsAmount.toFixed(1) }} ₪ על {{ categoryName }}
        </span>
        <span>מה הסכום שנרצה לעמוד בו החודש?</span>
      </div>
    </template>
  </category-numeric-input-popup>
</template>
se
<script>
import CategoryNumericInputPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/CategoryNumericInputPopup';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import ChallengeApi from '@/api/ChallengeApi';
import Segment from '@/Segment';

export default {
  name: 'SetCategoryChallengeInputPopup',
  components: {
    CategoryNumericInputPopup,
  },
  computed: {
    ...mapGetters('createTrackingCategory', ['transactionsToTrackAmount', 'avgCategoryActualsAmount', 'currentCategoryActuals']),
    ...mapGetters('cashflowView', ['presentedBudget']),
    ...mapState('cashflowView', ['cashflowUITree']),
    ...mapState('createTrackingCategory', ['selectedTrackingCategory', 'amount']),
    variableExpenses() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.variableExpense];
    },
    trackingCategory() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.trackingCategory];
    },
    upToNowAmount() {
      return this.trackingCategory.upToNowAmount;
    },
    selectedCategory() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.trackingCategory].find(c => c.categoryName === this.categoryName);
    },
    recommendedAmount() {
      return this.amount || (this.avgCategoryActualsAmount && this.avgCategoryActualsAmount.toFixed(1)) || 0;
    },
    cashculations() {
      return {
        untilNowAmount: this.currentCategoryActuals,
        recommendedAmount: this.recommendedAmount,
      };
    },
    displayErrorMessage() {
      return this.currentCategoryActuals > this.amount;
    },
    timeFrameDescription() {
      return 'חודש';
    },
    errorMessage() {
      return `הסכום הצפוי צריך להיות מעל ${this.currentCategoryActuals.toFixed(1)} ₪ שכבר יצאו על
       ${this.categoryName} ה${this.timeFrameDescription}`;
    },
    cashflowViewConsts() {
      return cashflowViewConsts;
    },
    categoryName() {
      return this.selectedTrackingCategory.label;
    },
  },
  methods: {
    ...mapActions('editCashflow', ['updateEnvelopeAmount']),
    ...mapMutations('challenge', ['setActiveChallenge']),
    back() {
      this.$emit('back-clicked');
    },
    async save() {
      if (!this.displayErrorMessage) {
        const { envelopeId } = this.selectedCategory;
        await this.updateEnvelopeAmount({
          envelopeId,
          amount: this.amount,
        });
        await ChallengeApi.createChallenge('trackingCategoryChallenge', { categoryName: this.categoryName });
        this.setActiveChallenge(this.categoryName);
        Segment.trackUserInteraction('SetCategoryChallengeInputPopup_Save_Clicked');
        this.$emit('done-clicked');
      }
    },
  },
};
</script>

<style scoped>

.load-wrapper {
  padding-bottom: 20px;
}
</style>
