<template>
  <category-numeric-input-popup title="הוספת קטגוריה למעקב" @back-clicked="back" @save="save"
                                :cashculations="cashculations" :place-holder="placeholder">
    <template v-slot:sub-title>
      <div class="title">כמה כסף צפוי לצאת על
        <span class="bold">הוצאות {{ selectedTrackingCategory.label }}</span>
        בחודש {{ currentMonthName }}{{ timeFrame }}?
      </div>
    </template>
    <template v-if="selectedTrackingCategory.historyAverage" v-slot:comment>
      <div class="comment">ממוצע שלושת החודשים הקודמים</div>
    </template>
  </category-numeric-input-popup>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import EventBus from '@/event-bus/event-bus';
import NewTrackingCategoryToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/NewTrackingCategoryToaster';
import CategoryNumericInputPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/CategoryNumericInputPopup';
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '@/Segment';
import dateUtils from '@/utils/dates';
import MoveVariablesMixin
  from './MoveVariablesMixin';

export default {
  name: 'CreateTrackingCategoryAmountPopup',
  mixins: [MoveVariablesMixin],
  components: {
    CategoryNumericInputPopup,
  },
  computed: {
    ...mapState('createTrackingCategory', ['selectedTrackingCategory', 'amount']),
    ...mapState('cashflowView', ['cashflowUITree', 'cashflow', 'cashflowStartDay', 'presentedBudgetDate']),
    ...mapGetters('editTransaction', ['ENRICHMENT_INPUT_TYPES']),
    ...mapGetters('createTrackingCategory', ['transactionsToTrackAmount', 'transactionsToTrack']),
    currentMonthName() {
      return dateUtils.getHebrewMonth(this.presentedBudgetDate);
    },
    variableExpenses() {
      return this.cashflowUITree[cashflowViewConsts.cashflowTypes.variableExpense];
    },
    upToNowAmount() {
      return this.variableExpenses.upToNowAmount - this.transactionsToTrackAmount;
    },
    recommendedAmount() {
      return this.variableExpenses.recommendedOrExpectedAmount - (this.amount || 0);
    },
    cashculations() {
      return {
        untilNowAmount: this.upToNowAmount,
        recommendedAmount: this.recommendedAmount,
      };
    },
    placeholder() {
      return this.selectedTrackingCategory.historyAverage ? this.selectedTrackingCategory.historyAverage.toFixed(0) : '0';
    },
    timeFrame() {
      if (this.cashflowStartDay === 1) {
        return '';
      }
      const startDate = dateUtils.startOfMonthText(this.presentedBudgetDate, this.cashflowStartDay);
      const endDate = dateUtils.endOfMonthText(this.presentedBudgetDate, this.cashflowStartDay);
      return ` (מ־${startDate} עד ${endDate})`;
    },
  },
  created() {
    Segment.trackUserInteraction('CreateTrackingCategoryAmount_Entered', {
      selectedTrackingCategory: this.selectedTrackingCategory,
    });
  },
  methods: {
    ...mapActions('createTrackingCategory', ['showTrackingCategory']),
    ...mapActions('editCashflow', ['moveTransaction']),
    back() {
      Segment.trackUserInteraction('CreateTrackingCategoryAmount_BackClicked', {
        selectedTrackingCategory: this.selectedTrackingCategory,
      });
      this.$emit('back-clicked');
    },
    async save() {
      Segment.trackUserInteraction('CreateTrackingCategoryAmount_SaveClicked', {
        selectedTrackingCategory: this.selectedTrackingCategory,
        amount: this.amount,
      });
      const newTrackingCategory = await this.showTrackingCategory();
      _.forEach(this.transactionsToTrack, transaction => {
        this.moveTransaction({
          originalCategory: transaction.trackingCategory,
          selectedCategory: newTrackingCategory,
          transaction,
          categoryRuleType: this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID,
          valueForPredicate: transaction.transactionId,
        });
      });
      const { name: categoryName, trackingCategoryId } = newTrackingCategory;
      await this.moveExistingTransactionsFromVariablesToCategory({ categoryName, trackingCategoryId });
      EventBus.$emit('open-toaster', {
        component: NewTrackingCategoryToaster,
        props: {
          trackingCategoryName: this.selectedTrackingCategory.label,
        },
      });
      this.$emit('close');
    },
  },
};
</script>
