<template>
  <div class="monthly-goal-bar" :style="{ '--bar-color': color }">
    <div class="labels">
      <div class="right-side">
        <div class="label">{{ rightLabel }}</div>
        <div class="number bold"><decimal-number :value="rightAmount"/>
          ₪
        </div>
      </div>

      <div class="left-side">
        <div class="label">{{ leftLabel }}</div>
        <div class="number bold"><animated-decimal-number :value="leftAmount"/>
          ₪
        </div>
      </div>
    </div>
    <div class="graph">
      <div class="right-value bar" :style="{ width: `${barWidthPercent}%` }"/>
      <div class="left-value bar" :style="{ width: `${100 - barWidthPercent}%` }"/>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import DecimalNumber from '@/base-components/DecimalNumber.vue';
import AnimatedDecimalNumber from '@/base-components/AnimatedDecimalNumber.vue';
import moneyUtils from '@/utils/money';

export default {
  name: 'BalanceBarGraph', // Riseup goal bar, RiseupGoalBar, Monthly goal bar, MonthlyGoalBar #SEO
  components: {
    AnimatedDecimalNumber,
    DecimalNumber,
  },
  props: {
    rightLabel: {
      type: String,
      required: true,
    },
    rightAmount: {
      type: Number,
      required: false,
    },
    leftLabel: {
      type: String,
      required: true,
    },
    leftAmount: {
      type: Number,
      required: false,
    },
    color: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fillAmount: 0,
    };
  },
  mounted() {
    setImmediate(() => {
      this.fillAmount = this.rightAmount;
    });
  },
  computed: {
    totalAmount() {
      return this.rightAmount + this.leftAmount;
    },
    barWidthPercent() {
      if (this.fillAmount === 0) {
        return 0;
      }
      return _.min([Math.floor((this.fillAmount / this.totalAmount) * 100), 100]);
    },
  },
  methods: {
    formatAmount(amount) {
      const formattedAmount = moneyUtils.formatAmountWithFraction(amount);
      return `${formattedAmount} ₪`;
    },
  },
  watch: {
    rightAmount() {
      this.fillAmount = this.rightAmount;
    },
  },
};
</script>

<style lang="scss">
@import '../../node_modules/@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/category-color-mixins';

.monthly-goal-bar {
  margin-top: 15px;

  .labels {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    color: $riseup_black;
    font-size: 10px;

    .left-side {
      text-align: left;
    }
    .right-side {
      .number {
        color: var(--bar-color);
      }
    }
    .label {
      margin-bottom: 5px;
      font-size: 12px;
      line-height: 11px;
    }

    .number {
      font-size: 18px;
      line-height: 21px;
      &.bold {
        font-weight: bold;
      }
    }
  }
  .graph {
    height: 15px;
    width: 100%;
    border-radius: 19px;
    display: flex;
    flex-direction: row;
    column-gap: 4px;

    .bar {
      height: 100%;
      transition: width 1s;
      border-radius: 25px;
      background: var(--bar-color);
      min-width: 15px;

      &.right-value {
        background: url('~@public/images/shapes/stripes-pattern.png') var(--bar-color);
        background-size: contain;
      }
    }
  }

}
</style>
