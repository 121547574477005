<template>
  <div class="plan-created-popup">
    <popup :buttons="buttons" :close-action="close">
      <template v-slot:title>
        <div class="icon-with-title">
          <img :src="require('@/assets/generic-success.png')" class="generic-success-asset-in-popup">
          <div class="title-text" v-if="isCurrentPlanOnTrack">
            <div>כל הכבוד על התיכנון המוקדם!</div>
          </div>
          <div class="title-text" v-else>
            <div>התוכנית נוספה לרשימה,</div>
            <div>כל הכבוד על התיכנון המוקדם!
            </div>
          </div>
        </div>
      </template>
      <template v-slot:content>
        <div class="content-container">
          <div class="sub-title" v-if="isCurrentPlanOnTrack">
            <div>התוכנית נוספה לרשימה.</div>
            <div>אפשר לראות בתזרים החודשי איך התוכנית מתאימה ליעד החודשי שלך.</div>
          </div>
          <div class="not-on-track-explanation" v-else>
            <div class="sub-title">כרגע נראה שלא ישאר מספיק בצד החודש כדי לעמוד בתוכנית הזאת.</div>
            <callout iconPath="icons/heart-icon.svg" title="מה אפשר לעשות?" class="explanation-callout">
              <template v-slot:text>
                <div class="callout-text">
                  לערוך קטגוריות בתזרים החודשי או לשנות את התוכנית עצמה. תמיד אפשר להתייעץ עם הצוות שלנו.
                </div>
              </template>
            </callout>
          </div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import { mapState, mapGetters, mapActions } from 'vuex';
import Segment from '@/Segment';

export default {
  name: 'PlanCreatedPopup',
  components: {
    Popup: BaseUi.Popup,
    Callout: BaseUi.Callout,

  },
  data() {
    return {
      checkIconColor: BaseUi.Colors.riseupGreen,
    };
  },
  created() {
    Segment.trackUserGot(
      'PlanAhead_Flow_CreatedPopup',
      { isAble: this.isCurrentPlanOnTrack, cashflowIsPositive: this.cashflowIsPositive },
    );
  },
  computed: {
    ...mapState('planAhead', ['calculatedMonthlyAmount']),
    ...mapGetters('planAhead', ['totalPlansMonthlyAmount']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    buttons() {
      return [
        {
          title: 'סגור',
          action: this.close,
          variant: 'primary',
          size: 'slim',
        },
      ];
    },
    isCurrentPlanOnTrack() {
      return this.totalPlansMonthlyAmount <= this.predictedBalanceAtEndOfMonth;
    },
    cashflowIsPositive() {
      return this.predictedBalanceAtEndOfMonth >= 0;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('planAhead', ['resetCurrentPlan']),
    async close() {
      await this.resetCurrentPlan();
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.plan-created-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  .success {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .icon-with-title {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .title-text {
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    line-height: 28px;
    margin-bottom: 12px;
  }
  .content-container {
    text-align: center;
    line-height: 18px;
    font-size: 14px;
  }
  .sub-title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 15px;
  }

  .callout-text {
    margin-left: 5px;
  }

  .not-on-track-explanation {
    margin-bottom: 15px;

    .explanation-callout {
      text-align: start;
    }
  }
}

</style>
