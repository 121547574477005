const onboardingFlowSteps = {
  addMember: 'add-member',
  preferences: 'preferences',
  credentials: 'credentials',
};

const state = {
  currentFlowStep: null,
};

const getters = {
  onboardingFlowSteps: () => onboardingFlowSteps,
};

const actions = {
};

const mutations = {
  setCurrentFlowStep(state, currentFlowStep) {
    state.currentFlowStep = currentFlowStep;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
