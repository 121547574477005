<template>
  <div class="mislaka-should-share">
    <img src="/images/advisory/mislaka-consent-header.svg" />

      <div class="padding-horizontal-ml content">
        <div class="ri-body top-title margin-top-ml margin-bottom-sm">אנחנו רק שאלה</div>
        <div class="ri-large-headline margin-bottom-xs">התוכנית שנבנה היא רק עבורך או גם עבור אדם נוסף?</div>
        <div class="ri-large-body margin-bottom-l">
          אם התוכנית היא גם עבור אדם נוסף, נצטרך לצרף אותו לתזרים ולקבל גם ממנו אישור פנייה למסלקה.
        </div>

        <div class="btns-container padding-bottom-ml">
          <riseup-button class="button border-radius-medium margin-bottom-ml" :action="handleOnlyMe"
                         :size="'slim'"
                         title="עבורי בלבד"
                         variant="secondary"/>

          <riseup-button class="button border-radius-medium" :action="handleShare"
                         :size="'slim'"
                         title="עבורי ועבור אדם נוסף"
                         variant="secondary"/>
        </div>
      </div>
    </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'MislakaShouldShare',
  components: {
    RiseupButton: BaseUi.RiseupButton,
  },
  async created() {
    Segment.trackUserGot('MislakaShouldShare_Entered');
  },
  methods: {
    handleOnlyMe() {
      Segment.trackUserInteraction('MislakaShouldShare_OnlyMeClicked');
      this.$emit('next', { inviteMember: false });
    },
    handleShare() {
      Segment.trackUserInteraction('MislakaShouldShare_ANotherMemberClicked');
      this.$emit('next', { inviteMember: true });
    },
  },
};
</script>

<style lang="scss" scoped>

@import "~@riseupil/base-ui/src/scss/riseup-colors";

.mislaka-should-share{
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .top-title{
    color: $riseup_blue;
  }

  .content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .btns-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
}
</style>
