<template>
  <div class="payment-using-different-card">
    <img class="full-width" :src="image">
    <div v-if="showAdvisoryNightOffering" class="padding-horizontal-ml margin-vertical-ml">
      <advisory-night-offering-callout  />
    </div>
    <div class="different-card">
      <different-credit-card/>
      <div class="payment-details padding-top-ml margin-horizontal-l">
        <div class="ri-medium-bold-headline">פרטי התשלום</div>
        <payment-details class="margin-vertical-l"
                         :price="selectedOffering.price.amount" :payment-date="currentDateFormatted" />
        <separator />
        <payment-installments :price="selectedOffering.price.amount" :payment-date="currentDate"/>
      </div>
      <separator/>
    </div>
    <div  v-if="configs.showCallout" class="callout-container margin-top-l">
      <advisory-next-step-callout  />
    </div>

    <payment-terms v-if="configs.showTerms"/>
  </div>
</template>

<script>
import Segment from '@/Segment';
import moment from 'moment';
import DifferentCreditCard from '@/pages/responsive-pages/authenticated/navigation-pages/payment/DifferentCreditCard';
import PaymentTerms from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentTerms';
import { mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import AdvisoryNextStepCallout
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/AdvisoryNextStepCallout';
import AdvisoryNightOfferingCallout
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/AdvisoryNightOfferingCallout';
import PaymentDetails from '@/pages/responsive-pages/authenticated/subscription/PaymentDetails.vue';
import PaymentInstallments from '@/pages/responsive-pages/authenticated/subscription/PaymentInstallments.vue';

export default {
  name: 'PaymentUsingDifferentCard',
  components: {
    AdvisoryNightOfferingCallout,
    PaymentTerms,
    DifferentCreditCard,
    AdvisoryNextStepCallout,
    Separator: BaseUI.Separator,
    PaymentDetails,
    PaymentInstallments,
  },
  props: {
    onClose: {
      type: Function,
      require: true,
    },
    image: {
      type: String,
      require: true,
    },
  },
  async created() {
    Segment.trackUserGot('PaymentUsingDifferentCard');
  },
  computed: {
    ...mapGetters('oneOffOfferings', ['configs', 'showAdvisoryNightOffering']),
    ...mapState('oneOffOfferings', ['selectedOffering']),
    currentDate() {
      return moment();
    },
    currentDateFormatted() {
      return this.currentDate.format('D.M.YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>

.payment-using-different-card{
  text-align: right;

  .callout-container{
    padding: 0 26px;
  }
}

</style>
