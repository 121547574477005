<template>
  <popup :close-action="closeAction" class="connect-missing-regular-ccs-connection-decision" :buttons="buttons">
    <template v-slot:content>
      <div class="image">
        <lottie-player src="/animations/obk-connection-alert.json" :autoplay="true" :loop="false"/>
      </div>
      <div class="main-title ri-font-20 ri-font-weight-bold margin-top-m">
           רגע לפני שנעבור ל{{source.sourceName}}, למי שייך הכרטיס החסר?
      </div>
      <div class="second-title margin-top-m margin-bottom-m ri-font-16">
        <span>אם הכרטיס שייך לשותפ.ה,</span>
        <span class="ri-font-weight-bold">צריך את מספר תעודת הזהות ואת אמצעי ההזדהות של הבעלים</span>
        <span>בשביל לתת את ההסכמה.</span>
      </div>
    </template>
  </popup>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import AggregatorApi from '@/api/AggregatorApi';
import { mapActions, mapState } from 'vuex';
import ConsentActionsMixin from '@/base-components/onboarding/openBanking/ConsentActionsMixin';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';
import Segment from '@/Segment';

export default {
  name: 'ConnectMissingRegularCCsConnectionDecision',
  components: {
    Popup: BaseUI.Popup,
  },
  mixins: [ConsentActionsMixin],
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('ConnectMissingRegularCCsConnectionDecision_Entered');
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState('credentials', ['credentialsList']),
    buttons() {
      return [{
        title: 'הכרטיס שייך לי',
        action: this.renewConsent,
        size: 'slim',
        variant: 'secondary',
        loading: this.loading,
      },
      {
        title: 'הכרטיס שייך לשותפ.ה שלי',
        action: this.openCCConnectionFlow,
        size: 'slim',
        variant: 'secondary',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    async renewConsent() {
      Segment.trackUserGot('ConnectMissingRegularCCsConnectionDecision_RenewConsentClicked');
      this.loading = true;
      const { credentialsId, consentId, bankIdentifier } = await AggregatorApi.getMigratedCredentialsConsent(this.source.credentialsId);
      const url = await this.updateConsent({
        consentId,
        bankIdentifier,
        credentialsId,
      });
      window.location.href = url;
    },
    openCCConnectionFlow() {
      Segment.trackUserGot('ConnectMissingRegularCCsConnectionDecision_ConnectPartnerClicked');
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: this.source.sourceName,
        },
        showLastModal: true,
        popupAlignment: 'full-screen',
      });
    },
    closeAction() {
      Segment.trackUserGot('ConnectMissingRegularCCsConnectionDecision_CloseClicked');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

  .connect-missing-regular-ccs-connection-decision {
    text-align: right;

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 78px;
    }

    .main-title {
      text-align: center;
      line-height: 28px;
    }

    .second-title {
      text-align: center;
      line-height: 24px;
    }
  }

</style>
