import * as _ from 'lodash';
import * as moment from 'moment';
import ActionsApi from '@/api/ActionsApi';
import ACTIONS
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/action-list/actions.consts.ts';

const state = {
  allActions: [],
};

const DEFAULT_ACTIONS = [
  {
    type: ACTIONS.FIRST_CF,
    order: 1,
  },
  {
    type: ACTIONS.SETUP_ACCOUNTS,
    order: 2,
  },
  {
    type: ACTIONS.PREDICTED_VARIABLE_INCOME_CHECK,
    order: 3,
  },
  {
    type: ACTIONS.FIXED_CHECK,
    order: 4,
  },
  {
    type: ACTIONS.ADD_CATEGORIES,
    order: 5,
  },
  {
    type: ACTIONS.SET_CHALLENGE,
    order: 6,
  },
  {
    type: ACTIONS.SET_RISEUP_GOAL,
    order: 7,
  },
  {
    type: ACTIONS.OPEN_PLAN_AHEAD_PLAN,
    order: 7,
  },
  {
    type: ACTIONS.INVITE_MEMBER,
    order: 8,
  },
  {
    type: ACTIONS.GO_TO_CHAT,
    order: 9,
  },
  {
    type: ACTIONS.GO_TO_COMMUNITY,
    order: 10,
  },
];

const getters = {
  shouldShowActionsBadge: state => state.allActions.length && moment(state.allActions[0].created).add(1, 'month').isAfter(moment()),
  actions: (state, getters, rootState, rootGetters) => {
    if (state.allActions && state.allActions.length) {
      return _.chain(state.allActions)
        .map(action => _.assign(action, _.find(DEFAULT_ACTIONS, a => a.type === action.type)))
        .filter(action => {
          if (action.checked) {
            return true;
          }
          if (rootGetters['cashflowView/predictedBalanceAtEndOfMonth'] < 0) {
            if (action.type === ACTIONS.OPEN_PLAN_AHEAD_PLAN || action.type === ACTIONS.SET_RISEUP_GOAL) {
              return false;
            }
          } else if (action.type === ACTIONS.SET_RISEUP_GOAL && state.allActions.find(a => a.type === ACTIONS.OPEN_PLAN_AHEAD_PLAN)) {
            return false;
          }
          if (action.type === ACTIONS.ADD_CATEGORIES) {
            return hasTrackingCategoriesSelectionInsight(rootGetters);
          }
          return true;
        })
        .orderBy(action => action.order)
        .value();
    }
    return [];
  },
};

const hasTrackingCategoriesSelectionInsight = rootGetters => rootGetters['insights/filteredInsights']
  .find(insight => insight.insightName === rootGetters['insights/INSIGHT_NAMES'].TrackingCategoriesSelectionInsight);

const actions = {
  async fetchActions({ commit, dispatch, rootGetters }) {
    const actions = await ActionsApi.fetchActions();
    commit('setActions', actions);
    dispatch('setSetupAccountActionCheckedState', { allPapaQuestionsAnswered: rootGetters['mergeInvestigationQuestions/allPapasAnswered'] });
  },
  setSetupAccountActionCheckedState({ state, commit, dispatch }, { allPapaQuestionsAnswered }) {
    const connectAccountsAction = _.find(state.allActions, action => action.type === ACTIONS.SETUP_ACCOUNTS);
    if (connectAccountsAction && allPapaQuestionsAnswered !== connectAccountsAction.checked) {
      dispatch('setActionCheckedState', {
        type: ACTIONS.SETUP_ACCOUNTS,
        checked: allPapaQuestionsAnswered,
      });
    }
  },
  async setActionCheckedState({ commit, state }, { type, checked }) {
    const typeToMark = state.allActions.find(action => action.type === type);
    typeToMark.checked = checked;
    commit('setActions', [...state.allActions]);
    await ActionsApi.setCheckedState(type, checked);
  },

};

const mutations = {
  setActions(state, actions) {
    state.allActions = actions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
