<template>
  <menu-button @click="menuOpened" :menu-button-color="menuButtonColor" />
</template>

<script>

import { mapActions } from 'vuex';
import BaseUi from '@riseupil/base-ui';

export default {
  name: 'BottomSheetOverflowButton',
  components: {
    MenuButton: BaseUi.MenuButton,
  },
  props: {
    bottomSheetComponent: {
      type: Object,
      required: true,
    },
    componentProps: {
      type: Object,
      required: false,
    },
    menuButtonColor: {
      type: String,
      required: false,
    },
    onMenuOpened: {
      type: Function,
      required: false,
    },
    showLastModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'changeCurrentModalProps']),
    menuOpened() {
      this.menuOpen = true;
      if (this.onMenuOpened) {
        this.onMenuOpened();
      }
      this.openModal({
        component: this.bottomSheetComponent,
        props: this.componentProps,
        popupAlignment: 'menu',
        showLastModal: this.showLastModal,
        eventHandlers: {
          close: () => {
            this.menuOpen = false;
          },
        },
      });
    },
  },
  watch: {
    componentProps(val) {
      if (this.menuOpen) {
        this.changeCurrentModalProps({ props: val, popupAlignment: 'menu' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
