<template>
  <div class="unsupported-bank-popup">
    <popup :buttons="buttons" :close-action="popupClosed">
      <template v-slot:title>
        <img class="generic-failure-asset-in-popup" :src="require('@/assets/bank-connection-failure.png')">
        <div class="popup-title">{{ popupTitle }}</div>
      </template>
      <template v-slot:content>
        <div class="description">
          <p v-for="line in descriptionText" :key="line">{{ line }}</p>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import Segment from '../../Segment';

export default {
  name: 'UnsupportedBankPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  props: {
    sourceName: {
      type: String,
      required: true,
    },
    bankDisplayName: {
      type: String,
      required: true,
    },
    waitingListName: {
      type: String,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('UnsupportedBanksPopupEntered', { sourceName: this.sourceName });
  },
  computed: {
    popupTitle() {
      if (this.bankDisplayName === 'בנק יהב') {
        return 'מצטערים, אבל באופן זמני לא ניתן להכניס פרטי בנק יהב';
      }
      if (this.bankDisplayName === 'ONE ZERO') {
        return 'לצערנו ONE ZERO עוד לא תומך בחיבור לרייזאפ';
      }
      return `לצערנו המערכת לא תומכת כרגע בחיבור ל${this.bankDisplayName}`;
    },
    descriptionText() {
      return [
        'במידה שזה רק חשבון נוסף, אפשר עדיין ליהנות מהשירות עם החשבונות האחרים.',
        ...this.bankDisplayName === 'ONE ZERO' ? [
          'בכל מקרה, נעדכן אותך בווטסאפ בחודשים הקרובים כשהחיבור יתאפשר.',
        ] : [],
      ];
    },
    updateMeButtonTitle() {
      if (this.bankDisplayName === 'בנק יהב') {
        return 'כן, עדכנו אותי כשאפשר להתקדם';
      }
      return 'כן, עדכנו אותי כשתתמכו';
    },
    buttons() {
      return [
        {
          title: 'תודה, הבנתי',
          action: this.continueWithoutSelected,
          variant: 'secondary',
          size: 'slim',
        },
      ];
    },
  },
  methods: {
    continueWithoutSelected() {
      Segment.trackUserInteraction('ContinueWithoutBankClicked', { sourceName: this.sourceName });
      this.closeModal();
    },
    popupClosed() {
      Segment.trackUserInteraction('UnsupportedBanksDecisionClosed', { sourceName: this.sourceName });
      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

  .unsupported-bank-popup {
    .action-icon {
      margin-bottom: 15px;
    }

    .popup-title {
      font-family: SimplerPro;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 18px;
      text-align: center;
      padding: 0 18px 0 18px;
    }

    .logo-image {
      width: 100%;
      text-align: center;
      height: 110px;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .description {
      margin-bottom: 20px;
      line-height: 20px;
      text-align: center;
      padding: 0 10px 0 10px;

      p {
        margin: 25px 0 0;
      }

      .bold {
        font-weight: bold;
      }
    }
  }

</style>
