import { clientWithoutErrorHandling } from '../http';
import DDLogs from '../DDLogs';

export default {
  sendCounter(counterName, tags) {
    clientWithoutErrorHandling.post('/api/send-counter', { counterName, tags }, {
      validateStatus: status => status === 200 || status === 403,
    }).then(response => {
      if (response.status === 403) {
        DDLogs.info('Failed increasing metric because user is not logged in', { metric: counterName, tags });
      }
    }).catch(err => DDLogs.error('Failed increasing metric', {
      err, statusCode: err?.response?.status, errStatusText: err?.response?.statusText, metric: counterName, tags,
    }));
  },
  sendLoadTimeMetrics(metrics) {
    clientWithoutErrorHandling.post('/api/send-load-time-metrics', { metrics })
      .catch(err => DDLogs.error('Failed sending load time metrics', { err }));
  },
};
