<template>
  <div class="balance-details">
    <div class="amount">
      <span>{{balancedAmount}}</span>
      <span class="nis-currency">₪</span>
    </div>
    <div class="expected-amount">
      <span>היה צפוי לצאת: </span>
      <span class="original-amount">{{originalAmount}} ₪</span>
    </div>
  </div>
</template>

<script>
import moneyUtils from '@/utils/money';
import TransactionDisplayUtils from '../../cashflow-view/generic-components/TransactionDisplayUtils';

export default {
  name: 'BalanceChangeDetails',
  mixins: [TransactionDisplayUtils],
  props: {
    insight: {
      type: Object,
      required: true,
    },
  },
  computed: {
    balancedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.balancedAmount);
    },
    originalAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.originalAmount);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .balance-details {

    .amount {
      font-size: 46px;
      line-height: 46px;
      font-weight: bold;
      margin-bottom: 20px;

      .nis-currency {
        font-size: 32px;
        font-weight: bold;
      }
    }

    .expected-amount {
      .original-amount {
        font-weight: bold;
      }
    }
  }

</style>
