import _ from 'lodash';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import { DateLocales } from '@riseupil/common-utils';

const state = {
  actionsStack: [],
};

function _applyActions(currentCashflow, actionsStack) {
  return _.reduce(
    actionsStack,
    (cashflow, action) => reactiveCashflow.optimus[action.action](cashflow, action.actionArguments, DateLocales.EN_IL),
    _.cloneDeep(currentCashflow),
  );
}

const actions = {
  async applyOptimisticStack({ state, commit, dispatch, rootGetters }) {
    const currentCashflow = rootGetters['cashflowView/presentedBudget'];
    const newCashflow = _applyActions(currentCashflow, state.actionsStack);
    commit('cashflowView/setCashflowOptimistic', newCashflow, { root: true });
    dispatch('cashflowView/resetBudgetTree', {}, { root: true });
    commit('emptyOptimisticActionsStack');
  },
  async appendAction({ commit }, { action, actionArguments }) {
    commit('appendAction', { action, actionArguments });
  },
  async removeAction({ commit }, { actionArguments }) {
    commit('removeAction', { transactionId: actionArguments.transactionId });
  },
};

// mutations
const mutations = {
  appendAction(state, action) {
    state.actionsStack.push(action);
  },
  removeAction(state, { transactionId }) {
    state.actionsStack = _.reject(state.actionsStack, action => action.actionArguments.transactionId === transactionId);
  },
  emptyOptimisticActionsStack(state) {
    state.actionsStack = [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
