<template>
  <insight :insight="insight"
           :image-name="'demographic-info.svg'"
           :button-text="'קדימה'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div class="insight-text">
        <span class="subtitle">נשמח להכיר אותך יותר</span>
          - 3 שאלות קצרות יעזרו לנו להציג לך עוד תובנות על הכסף שלך.
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import DemographicInfoPopup from './components/DemographicInfoPopup';
import Insight from './components/Insight';

export default {
  name: 'DemographicInfoInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('DemographicInfoShown');
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      Segment.trackUserInteraction('ShowDemographicInfoClicked');
      this.openModal({
        component: DemographicInfoPopup,
        props: { insight: this.insight },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.insight-text {
  font-size: 19px;
  .subtitle {
    font-weight: bold;
  }
}
</style>
