<template>
  <div class="step-container">
    <Step>
      <template v-slot:header>
        <StepIndicator :current-step="currentStepNumber" :total-number-of-steps="totalStepNumber"/>
      </template>
      <template v-slot:content>
        <ScoreQuestion
            :title="title"
            :onScoreChanged="onScoreChanged"
            :lowScoreText="lowScoreText"
            :highScoreText="highScoreText"
            :defaultResult="score"
        />
      </template>
      <template v-slot:footer>
        <RiseupButton
            class="next-button"
            title="אפשר להמשיך"
            :action="onClicked"
            :bold="true"
            :disabled="!score"
        />
      </template>
    </Step>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { activationSurveyStepsConfig } from '@/pages/responsive-pages/authenticated/survey/activation-survey/consts';
import Step from '../../components/Step';
import StepIndicator from '../../components/StepIndicator';
import ScoreQuestion from '../../components/ScoreQuestion';

export default {
  name: 'ScoringStep',
  components: {
    Step,
    StepIndicator,
    ScoreQuestion,
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserGot(`ActivationSurvey${this.activeStep}_PageEntered`);
  },
  computed: {
    ...mapState('activationSurvey', ['answers', 'activeStep']),
    currentStep() {
      return activationSurveyStepsConfig[this.activeStep];
    },
    title() {
      return this.currentStep.question;
    },
    lowScoreText() {
      return this.currentStep.score && this.currentStep.score.lowScoreText;
    },
    highScoreText() {
      return this.currentStep.score && this.currentStep.score.highScoreText;
    },
    currentStepNumber() {
      return Number(this.currentStep.step.currentStep);
    },
    totalStepNumber() {
      return Number(this.currentStep.step.totalNumberOfSteps);
    },
    score() {
      return this.answers[this.activeStep];
    },
  },
  methods: {
    ...mapMutations('activationSurvey', ['setActiveStep', 'updateStepAnswer']),
    onScoreChanged(score) {
      this.updateStepAnswer({ stepName: this.activeStep, stepResults: score });
    },
    onClicked() {
      Segment.trackUserGot(`ActivationSurvey${this.activeStep}_NextClicked`, this.answers);
      this.setActiveStep(this.currentStep.nextStep(this.answers));
    },
  },
  watch: {
    activeStep() {
      Segment.trackUserGot(`ActivationSurvey${this.activeStep}_PageEntered`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.step-container {
  height: 100%;

  .next-button {
    height: 54px;
    width: 100%;
  }
}
</style>
