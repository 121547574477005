<template>
  <popup :close-action="keepPlan">
    <template v-slot:title>
למחוק את {{plan.name}} מהתוכניות שלך?
    </template>
    <template v-slot:content>
      <div class="delete-plan">
        הצבירה שלך עד כה לא תשמר בתוכניות הגדולות שלך.
        <riseup-button size="slim" title="כן למחוק" :action="approveDeletePlan" color="red" class="button"/>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';

export default {
  name: 'DeletePlanPopup',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Popup: BaseUI.Popup,
  },
  props: {
    plan: {
      type: Object,
      require: true,
    },
    onPlanDeleted: {
      type: Function,
      require: true,
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('planAhead', ['deletePlan', 'resetCurrentPlan']),

    async approveDeletePlan() {
      await this.deletePlan(this.plan);
      this.resetCurrentPlan();
      this.closeModal();
      this.onPlanDeleted();
    },
    keepPlan() {
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.delete-plan {
  font-size: 18px;
  line-height: 26px;
}
.button {
  margin-top: 48px;
}
</style>
