<template>
  <div class="month-opening-experience-popup">
    <colored-popup :overlayImage="require('@/assets/blue-flower-card.svg')" :close-action="close">
      <template v-slot:top-content>
        <div>
          <div class="ri-medium-bold-headline">לחשב תזרים מחדש</div>
          <div class="header-text ri-font-16 margin-top-s">
            צעד אחרי צעד, נתאים את תזרים נובמבר ונייצר תחושת בטחון כלכלי בתוך אי הוודאות שמקיפה אותנו.
          </div>
        </div>
      </template>
      <template v-slot:bottom-content>
        <bullet-block v-for="(section, i) of sections" :key="i" :title="section.title" :text="section.text" :bullet-text="i+1"/>
        <riseup-button class="margin-top-xl"
                       title="הבנתי, תודה!"
                       :action="understoodClicked"
                       size="slim"
                       bold/>
      </template>
    </colored-popup>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import BulletBlock
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/BulletBlock.vue';

export default {
  name: 'MonthOpeningExperienceInsightPopup',
  components: {
    BulletBlock,
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    insight: {
      type: Object,
      required: false,
    },
  },
  created() {
    Segment.trackUserInteraction('MonthOpeningExperienceInsightPopup_Shown');
  },
  data() {
    return {
      sections: [
        {
          title: 'נבדוק שצפי המשכורת עדכני',
          text: 'אם חל שינוי בהכנסות בגלל המצב, נעדכן את צפי ההכנסות הקבועות והמשתנות.',
        },
        {
          title: 'נעדכן צפי הוצאות בקטגוריות',
          text: 'מומלץ להתבסס על הוצאות אוקטובר שהסתיים ועל בסיסן להגדיר צפי הוצאות ריאלי ומייצג לחודש הקרוב.',
        },
        {
          title: 'נוודא שהשארנו יתרה מספיקה להוצאות משתנות החודש',
          text: 'כמה? גם פה מומלץ לוודא שנשאר לפחות סכום הדומה לכמות שיצאה בחודש אוקטובר',
        },
        {
          title: 'נראה אם אפשר לשים כסף בצד בסוף החודש',
          text: 'עכשיו, כשיש לנו יותר וודאות לגבי איך החודש יראה אפשר להגדיר יעד לחיסכון',
        },
        {
          title: 'נעבור על ההוצאות הקבועות הצפויות החודש',
          text: 'אם יש הוצאות שלא ירדו החודש בגלל המצב, נבטל את הצפי. נחליט אם להשתמש בסכום שהתפנה או שדווקא אפשר לחסוך אותו',
        },
      ],
    };
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('insights', ['markAsRead']),

    close() {
      Segment.trackUserInteraction('MonthOpeningExperienceInsightPopup_closed');
      this.closeModal();
    },
    understoodClicked() {
      Segment.trackUserInteraction('MonthOpeningExperienceInsightPopup_Understood_Clicked');
      if (this.insight) {
        this.markAsRead(this.insight);
      }
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">
.month-opening-experience-popup {
  text-align: right;

  .header-text {
    line-height: 24px;
  }
}
</style>
