import * as SavingsApi from '@/api/SavingsApi';

const state = {
  fund: undefined,
};

const getters = {
  hasFund: state => !!state.fund,
  monthlyDeposit: state => state.fund && state.fund.monthlyDepositSum,
  fundNumber: state => state.fund && state.fund.fundNumber,
};
const actions = {
  async initSavings({ commit }) {
    const fund = await SavingsApi.getSavingsInformation();
    commit('setFund', fund);
  },
};

const mutations = {
  setFund(state, fund) {
    state.fund = fund;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
