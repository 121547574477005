<script>

import { getBanks } from '@/constants/sourcesConfiguration';
import _ from 'lodash';

export default {
  name: 'CustomerBenefits',
  computed: {
    sortedCombinedBankSources() {
      const bankConfigurations = getBanks();
      return _.sortBy(bankConfigurations, b => b.sortPriority);
    },
  },
};

</script>
