import { client } from '../http';

export default {
  async fetchAllBudgets() {
    const response = await client.get('/api/budget');
    return response.data;
  },
  async fetchBudget({ budgetDate }) {
    const response = await client.get(`/api/budget/${budgetDate}`);
    return response.data;
  },
  async fetchPreviousBudgets({ budgetDate, numMonthsBack }) {
    const response = await client.get(`/api/budget/${budgetDate}/${numMonthsBack}`);
    return response.data;
  },
  async getCashflowStartDay() {
    const response = await client.get('/api/cashflow-start-day');
    const { cashflowStartDay } = response.data;
    return cashflowStartDay;
  },
};
