<template>
  <div class="change-transaction-category-popup">
    <colored-popup :class="categoryToVariant[selectedCategory.cashflowCategory]" :close-action="close">

      <template v-slot:top-content>
        <div class="transaction-title">
          <span class="name">{{ incomeOrExpense }} {{ selectedCategory.label }}</span>
        </div>
        <transaction-details :transaction="transaction" />
      </template>

      <template v-slot:bottom-content>
        <div class="question">איזו {{ incomeOrExpense }} זו?</div>
        <cashflow-category-radio-list v-if="useNewChangeCategoryRadioList"
                                      :options="transaction.splitFrom ? splitAvailableCategoriesWithoutSavings : availableCategories"
                                      :first-selected="selectedCategory"
                                      @select="onSelection"/>
        <cashflow-category-dropdown v-else
                                    :options="transaction.splitFrom ? splitAvailableCategoriesWithoutSavings : availableCategories"
                                    :first-selected="selectedCategory"
                                    :on-select="onSelection"/>
        <riseup-button :class="{ 'margin-top-ml' : useNewChangeCategoryRadioList }"
                       @click="onSave"
                       title="שמירה"
                       :disabled="sameCategorySelected"/>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import * as _ from 'lodash';
import TransactionDetails from './TransactionDetails.vue';
import Segment from '../../../../../../../../../../Segment';
import cashflowViewConsts from '../../../../../../../../../../constants/cashflow-view';

export default {
  name: 'ChangeTransactionCategoryPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    TransactionDetails,
    CashflowCategoryDropdown: BaseUI.V2Components.CashflowCategoryDropdown,
    CashflowCategoryRadioList: BaseUI.CashflowCategoryRadioList,
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserInteraction('ChangeTransactionCategoryPopup_Shown', {
      businessName: this.transaction.businessName,
      originalCategory: this.transaction.expense,
      transactionId: this.transaction.transactionId,
      isPlanAheadTransaction: !!this.transaction?.plan,
    });
  },
  computed: {
    ...mapState('editTransaction', ['transaction', 'selectedCategoryName', 'originalCategoryName']),
    ...mapGetters('editTransaction', [
      'sameCategorySelected', 'selectedCategory', 'availableCategories', 'splitAvailableCategories',
      'categoryToVariant', 'originalCategory', 'incomeOrExpense',
    ]),
    ...mapGetters('featureFlags', ['useNewChangeCategoryRadioList']),
    splitAvailableCategoriesWithoutSavings() {
      return _.reject(this.splitAvailableCategories, item => item.isSaving);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapMutations('editTransaction', ['setSelectedCategoryName', 'setSelectedCategoryId']),
    close() {
      Segment.trackUserInteraction('ChangeTransactionCategoryPopup_CloseClicked', {
        businessName: this.transaction.businessName,
        originalCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
      });
      this.$emit('close');
    },
    onSelection(category) {
      if (category.categoryName === cashflowViewConsts.CATEGORY_NAMES.TRACKING || category.planAheadCategory) {
        this.setSelectedCategoryId(category.categoryId);
      } else {
        this.setSelectedCategoryName(category.categoryName);
      }
    },
    onSave() {
      Segment.trackUserInteraction('ChangeTransactionCategoryPopup_SaveClicked', {
        businessName: this.transaction.businessName,
        originalCategory: this.transaction.expense,
        selectedCategory: this.selectedCategory.expense,
        transactionId: this.transaction.transactionId,
        isPlanAheadCategory: this.selectedCategory.planAheadCategory,
      });
      this.$emit('close');
      this.openModal({
        component: this.originalCategory.confirmationPopup || this.selectedCategory.targetPopup,
        props: {},
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.change-transaction-category-popup {
  text-align: right;

  .colored-top {

    .transaction-title {
      margin-bottom: 16px;
      line-height: 11px;

      .name, .dot{
        font-weight: bold;
      }
    }
  }

  .bottom {
    .question {
      color: $riseup_black;
      line-height: 13px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .cashflow-category-dropdown-v2 {
      margin-bottom: 40px;
    }

    .riseup-button {
      width: 100%;
    }
  }
}

</style>
