'use strict';

import axios from 'axios';

const client = axios.create({});
const clientWithoutErrorHandling = axios.create({});

export {
  client,
  clientWithoutErrorHandling,
};
