<template>
  <insight class="tracking-insight"
           :insight="insight"
           :image-name="'tracking_insight.svg'"
           :button-text="'אני רוצה לראות איך'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div class="content">
        <span>הוצאת בממוצע</span>
        <span class="bold">{{insight.trackingCategory.categoryAverage}} ₪ על הוצאות {{normalizedTrackingCategory.label}}</span>
        <span>בחודשים קודמים. אפשר להגדיר יעד חודשי ולהתחיל לחסוך!</span>
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Segment from '@/Segment';
import TrackingInsightPopup from './components/TrackingInsightPopup';
import Insight from './components/Insight';

export default {
  name: 'TrackingInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('TrackingInsight_Shown');
  },
  computed: {
    ...mapGetters('createTrackingCategory', ['availableTrackingCategories']),
    normalizedTrackingCategory() {
      return _.find(this.availableTrackingCategories, category => category.value === this.insight.trackingCategory.name) || {};
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      Segment.trackUserInteraction('TrackingInsight_Clicked');
      this.openModal({
        component: TrackingInsightPopup,
        props: {
          insight: this.insight,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.tracking-insight {
  .content {
    .bold {
      font-weight: bold;
    }
  }
}
</style>
