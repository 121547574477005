import { client } from '../http';

export default {
  async generateReferralToken(promotionCode) {
    const response = await client.post('/api/generate-referral-token', {
      promotionCode,
    });
    return response.data;
  },
};
