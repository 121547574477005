const PRONOUNS = {
  MALE: 'male',
  FEMALE: 'female',
  PLURAL: 'plural',
};

const TITLES = {
  VACATION: 'חופשה משפחתית',
  WEDDING: 'חתונה של חבר או חברה',
  EXPENSIVE: 'יום יקר לאללה ☹️',
  WORTH_IT: 'בילוי ששווה כל שקל!',
  CASHFLOWY: 'יום תזרימי במיוחד 😀',
  BLUE_WHITE: 'רק כחול לבן🇮🇱',
};

const STORY_SETTINGS = {
  [TITLES.VACATION]: {
    label: TITLES.VACATION,
    class: 'vacation',
    variant: PRONOUNS.FEMALE,
  },
  [TITLES.WEDDING]: {
    label: TITLES.WEDDING,
    class: 'wedding',
    variant: PRONOUNS.FEMALE,
  },
  [TITLES.EXPENSIVE]: {
    label: TITLES.EXPENSIVE,
    class: 'expensive',
    variant: PRONOUNS.MALE,
  },
  [TITLES.WORTH_IT]: {
    label: TITLES.WORTH_IT,
    class: 'worth-it',
    variant: PRONOUNS.MALE,
  },
  [TITLES.CASHFLOWY]: {
    label: TITLES.CASHFLOWY,
    class: 'cashflowy',
    variant: PRONOUNS.MALE,
  },
  [TITLES.BLUE_WHITE]: {
    label: TITLES.BLUE_WHITE,
    class: 'blue-white',
    variant: PRONOUNS.MALE,
  },
};

const DEFAULT_SETTINGS = {
  label: 'other',
  class: 'other',
  variant: 'male',
};

const TITLE_PREFIXES = {
  [PRONOUNS.MALE]: 'כמה עלה לי',
  [PRONOUNS.FEMALE]: 'כמה עלתה לי',
  [PRONOUNS.PLURAL]: 'כמה עלו לי',
};

function getStorySettingsWithDefault(title) {
  return STORY_SETTINGS[title] || DEFAULT_SETTINGS;
}

export default {
  STORY_SETTINGS,
  TITLE_PREFIXES,
  TITLES,
  getStorySettingsWithDefault,
};
