<template>
  <div class="edit-predicted-variable-income-popup">
    <popup :buttons="buttons" :close-action="closeFromX">
      <template v-slot:title>
        <div class="popup-title">עדכון צפי הכנסות משתנות</div>
      </template>
      <template v-slot:content>
        <div class="description">
          <div class="text">
            כמה כסף צפוי להיכנס {{ currentMonthText }}
            <b> בנוסף להכנסות הקבועות?</b>
          </div>
          <amount-input
              class="edit-input"
              label=""
              :error-message="errorMessage"
              :value="newPredictedVariableIncomeAmount"
              :placeholder="variableIncomeActualSum"
              :autofocus="false"
              @input="editPredictedVariableIncome"
          />
          <div class="progress-container">
            <div class="effect-text">איך זה ישפיע על סך הההכנסות הצפויות?</div>
            <div class="separator"></div>
            <cashflow-expense-progress class="progress-bar"
                                       cashflowCategory="fixed-income"
                                       :recommended-or-expected-amount="expectedAmount"
                                       :up-to-now-amount="upToNowAmount"
                                       :is-income="true"/>
            <div class="actuals">
              עד עכשיו נכנסו
              <b>{{ formatNumber(variableIncomeActualSum) }} ₪</b>
              הכנסות משתנות
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="cancel-button" @click="closeAction">ביטול</div>
      </template>

    </popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import dateUtils from '@/utils/dates';
import moneyUtils from '@/utils/money';
import CashflowExpenseProgress
  from '../../../../generic-components/cashflow-types/cashflow-types-base/CashflowExpenseProgress';

export default {
  name: 'EditPredictedVariableIncomePopup',
  components: {
    CashflowExpenseProgress,
    Popup: BaseUI.Popup,
    AmountInput: BaseUI.AmountInput,
  },
  props: {
    onCancel: {
      type: Function,
      default: () => {},
    },
    insight: {
      type: Object,
      required: false,
    },
  },
  created() {
    Segment.trackUserGot('EditPredictedVariableIncomePopup_Opened', { ...this.insight });
    if (this.variableIncomePredictionAmount) {
      this.newPredictedVariableIncomeAmount = Math.max(this.variableIncomePredictionAmount, this.variableIncomeActualSum);
    }
  },
  data() {
    return {
      errorMessage: undefined,
      newPredictedVariableIncomeAmount: undefined,
    };
  },
  computed: {
    ...mapState('cashflowView', ['cashflow', 'cashflowUITree', 'cashflowStartDay']),
    currentMonthText() {
      if (this.cashflowStartDay === 1) {
        return `ב${dateUtils.getHebrewMonth(this.cashflow.budgetDate)}`;
      }
      return `החודש (עד ה־${dateUtils.endOfMonthText(this.cashflow.budgetDate, this.cashflowStartDay)})`;
    },
    variableIncomeActualSum() {
      return Math.abs(this.cashflow.variableIncomeActualSum);
    },
    incomeEnvelopesBalanceSum() {
      return Math.abs(this.cashflow.incomeEnvelopesBalanceSum);
    },
    variableIncomePredictionAmount() {
      return Math.abs(this.cashflow.variableIncomePredictionAmount);
    },
    variableIncomeBalancedAmount() {
      return Math.abs(this.cashflow.variableIncomeBalancedAmount);
    },
    upToNowAmount() {
      return Math.abs(this.cashflowUITree[cashflowViewConsts.cashflowTypes.income].upToNowAmount);
    },
    expectedAmount() {
      return this.incomeEnvelopesBalanceSum + Math.max(this.newPredictedVariableIncomeAmount || 0, this.variableIncomeActualSum);
    },
    tooLowPredictedVariableIncomeMessage() {
      return `הסכום צריך להיות גבוה מ־${this.formatNumber(Math.ceil(this.variableIncomeActualSum))} ₪ שכבר נכנסו`;
    },
    allowToUpdateAmount() {
      return !this.errorMessage && this.variableIncomePredictionAmount !== this.newPredictedVariableIncomeAmount;
    },
    buttons() {
      return [
        {
          title: 'סיימתי',
          action: this.updateIncomePrediction,
          size: 'slim',
          disabled: !this.allowToUpdateAmount,
        },
      ];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('editCashflow', ['setIncomePredictionAmount']),
    editPredictedVariableIncome(value) {
      this.newPredictedVariableIncomeAmount = value !== null ? value : undefined;
    },
    updateIncomePrediction() {
      Segment.trackUserInteraction('EditPredictedVariableIncomePopup_Done', {
        oldPrediction: this.variableIncomePredictionAmount,
        newPrediction: this.newPredictedVariableIncomeAmount,
        ...this.insight,
      });
      this.setIncomePredictionAmount({ amount: -this.newPredictedVariableIncomeAmount, budgetDate: this.cashflow.budgetDate });
      this.closeModal();
    },
    closeFromX() {
      Segment.trackUserInteraction('EditPredictedVariableIncomePopup_Closed', { closeFromX: true, ...this.insight });
      this.closeModal();
    },
    closeAction() {
      Segment.trackUserInteraction('EditPredictedVariableIncomePopup_Closed', { ...this.insight });
      this.onCancel();
      this.closeModal();
    },
    formatNumber(number) {
      if (!_.isNil(number)) {
        return moneyUtils.formatAmount(_.round(number, 1));
      }
      return '';
    },
  },
  watch: {
    // eslint-disable-next-line
    newPredictedVariableIncomeAmount: _.debounce(function (value) {
      this.errorMessage = value < this.variableIncomeActualSum ? this.tooLowPredictedVariableIncomeMessage : undefined;
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';

.edit-predicted-variable-income-popup {
  font-size: 18px;

  .popup-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .description {
    line-height: 24px;
    text-align: right;

    .text {
      margin-bottom: 9px;
    }

    .edit-input {
      margin-bottom: 48px;
    }

    .progress-container {
      text-align: right;
      border: solid 1px $riseup_gray_0;
      border-radius: $input-field-border-radius;
      margin-top: 52px;
      margin-bottom: 8px;

      .effect-text {
        text-align: right;
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        padding: 12px 20px;
      }

      .separator {
        height: 1px;
        background: $riseup_gray_0;
        margin-bottom: 20px;
      }

      .progress-bar {
        padding: 0 20px;
      }
    }

    .actuals {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 16px;
      margin-right: 20px;
      margin-top: -4px;
    }
  }

  .cancel-button {
    cursor: pointer;
  }
}
</style>
