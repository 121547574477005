<template>
  <loading-popup :title="loadingTitle" :subtitle="loadingSubtitle"/>
</template>

<script>
import moment from 'moment';
import LoadingPopup from '@/base-components/onboarding/LoadingPopup';
import Segment from '@/Segment';

export default {
  name: 'LoadingPopupWithAsyncFallback',
  components: {
    LoadingPopup,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    loadingStartTime: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      loadingIsLong: false,
    };
  },
  async created() {
    const loadingTimeInSecs = moment().diff(moment(this.loadingStartTime), 'seconds');
    if (loadingTimeInSecs > 30) {
      await this.setLoadingIsLong();
    } else {
      setTimeout(async () => {
        await this.setLoadingIsLong();
      }, (30 - loadingTimeInSecs) * 1000);
    }
  },
  computed: {
    sourceTypeString() {
      return (this.source && this.source.type === 'bank') ? 'הבנק' : 'חברת האשראי';
    },
    loadingTitle() {
      if (this.loadingIsLong) {
        Segment.trackUserGot('CredentialsConnectionTakesTooLongLoaderShown', { sourceName: this.sourceName });
        return `החיבור לאתר ${this.sourceTypeString} לוקח קצת יותר זמן מהרגיל`;
      }
      Segment.trackUserGot('CredentialsConnectionLoaderShown', { sourceName: this.sourceName });
      return 'עוד רגע נמשיך...';
    },
    loadingSubtitle() {
      if (this.loadingIsLong) {
        return 'המערכת ממשיכה לנסות...';
      }
      return `המערכת מתחברת לאתר ${this.sourceTypeString}`;
    },
  },
  methods: {
    async setLoadingIsLong() {
      Segment.trackUserGot('TempCredentialsConnectionLoadingLong', { sourceName: this.sourceName });
      this.loadingIsLong = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
