<template>
  <div v-show="isOpen">
    <div class="page-overlay">
      <div class="overlay-lightbox" :class="lightboxClasses" ref="overlay">
        <div class="overlay-content" :class="overlayContentClasses">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import EventBus from '@/event-bus/event-bus';

export default {
  name: 'PageOverlay',
  props: {
    isOpen: Boolean,
    popupAlignment: {
      type: String,
      default: 'start',
      validator: val => ['start', 'center', 'full-screen', 'fit-height', 'menu'].includes(val),
    },
  },
  mounted() {
    EventBus.$on('reset-scroll', this.scrollTopCallback);
  },
  beforeDestroy() {
    EventBus.$off('reset-scroll', this.scrollTopCallback);
  },
  computed: {
    isFullscreenLayout() {
      return this.popupAlignment === 'full-screen' || this.popupAlignment === 'menu';
    },
    lightboxClasses() {
      return {
        center: this.popupAlignment === 'center',
        'full-screen': this.isFullscreenLayout,
        menu: this.popupAlignment === 'menu',
      };
    },
    overlayContentClasses() {
      return {
        'full-screen': this.isFullscreenLayout,
        'fit-height': this.popupAlignment === 'fit-height',
        menu: this.popupAlignment === 'menu',
      };
    },
  },
  methods: {
    scrollTopCallback() {
      this.$refs.overlay.scrollTop = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '../scss/mixins';

.page-overlay {
  width: 100%;
  height: 100%;
  position: fixed; // TODO add somthing from elsa that disable scroll from background
  display: flex;
  justify-content: center;

  .overlay-lightbox {
    @include responsive-padding;
    @include responsive-padding-top-bottom;
    @include responsive-width-and-margin;
    height: 100%;
    background-color: rgba(43, 50, 59, 0.84);
    font-family: SimplerPro;
    top: 0;
    right: 0;
    text-align: center;
    overflow-y: auto;
    scroll-behavior: smooth;
    display: flex;
    align-items: flex-start;

    &.center {
      align-items: center;
    }

    &.full-screen {
      padding: 0;
    }

    &.menu {
      background: initial;
    }

    .overlay-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      background: $riseup_white;
      border-radius: $popup-border-radius;

      &.fit-height {
        display: flex;
        flex-direction: column;
        max-height: 100%;
        overflow: scroll;
      }

      &.full-screen {
        height: 100%;
        border-radius: 0;
      }

      &.menu {
        background: initial;
      }
    }
  }
}

@media (min-width: 430px) {
  .page-overlay {
    .overlay-lightbox {
      height: calc(100vh - 100vh*0.1 - 35px - 20px);
    }
  }
}
</style>
