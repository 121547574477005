<template>
  <div class="migrate-to-obk-flow">
    <component v-if="currentStep" :is="currentStep.component" v-bind="currentStep.props" />
  </div>
</template>

<script>

import OpenBankingCelebrationPopup
  from '@/pages/responsive-pages/non-authenticated/open-banking/OpenBankingCelebrationPopup';
import BankCCWarningPopup from '@/base-components/credentials/credentials-list/BankCCWarningPopup';
import { mapActions, mapGetters } from 'vuex';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';

export default {
  name: 'MigrateToOBKFlow',
  data() {
    return {
      stepIndex: 0,
    };
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('credentialsNew', ['getCredsBankCCAccounts']),
    bankCCAccounts() {
      return this.getCredsBankCCAccounts(this.source.credentialsId);
    },
    currentStep() {
      return this.steps[this.stepIndex];
    },
    steps() {
      return [{
        component: OpenBankingCelebrationPopup,
        props: {
          source: this.source,
          action: () => {
            if (this.bankCCAccounts.length) {
              this.stepIndex += 1;
            } else {
              this.openOBKConnectionFlow();
            }
          },
        },
      },
      {
        component: BankCCWarningPopup,
        props: {
          source: this.source,
          bankCCAccounts: this.bankCCAccounts,
          openOBKConnectionFlow: () => this.openOBKConnectionFlow(),
        },
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    openOBKConnectionFlow() {
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: this.source.sourceName,
          closedBankingCredentialsId: this.source.credentialsId,
          preventLoadingCredentialsCheck: true,
        },
        popupAlignment: 'full-screen',
      });
    },
    handleClose() {
      this.closeModal();
    },
  },

};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../scss/mixins';

.migrate-to-obk-flow {

}
</style>
