<template>
  <div class="step">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="content">
      <slot class="content" name="content"></slot>
    </div>
    <div class="footer">
      <slot class="footer" name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',
};
</script>

<style lang="scss" scoped>
.step {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 24px;

  .header {
    display: flex;
    height: 24px;
  }

  .content {
    flex-grow: 1;
    margin-top: 16px;
    overflow-y: auto;
  }

  .footer {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
