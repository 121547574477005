'use strict';

import JSencrypt from 'jsencrypt';
import _ from 'lodash';

import config from '../../config/dev-static/config.json';

const Encryptor = new JSencrypt();
Encryptor.setPublicKey(atob(config.credentialsPublicKey));

function encrypt(obj) {
  return _.mapValues(obj, value => Encryptor.encrypt(JSON.stringify(value)));
}

export default {
  encrypt,
};
