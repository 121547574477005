import { client } from '../http';

/// ///////////////// Share Pages ////////////////////
async function getSharePage(sharePageId) {
  const response = await client.get(`/api/no-auth/share-page/${sharePageId}`);
  return response.data;
}

async function createSharePage(sharePage) {
  const response = await client.post('/api/share-page', sharePage);
  return response.data;
}

async function sendAnalytics(sharePageId, eventName) {
  await client.post(`/api/no-auth/share-page/analytics/${sharePageId}`, { eventName });
}

async function sendAnalyticsLoggedIn(sharePageId, eventName) {
  await client.post(`/api/share-page/analytics/${sharePageId}`, { eventName });
}

export default {
  getSharePage,
  createSharePage,
  sendAnalytics,
  sendAnalyticsLoggedIn,
};
