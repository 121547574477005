import { datadogLogs } from '@datadog/browser-logs';
import _ from 'lodash';
import config from './config';

const PII_FIELDS_BLACKLIST = [
  'firstName',
  'lastName',
  'phoneNumber',
  'question',
  'amount',
  'secondMemberFirstName',
  'secondMemberLastName',
  'accounts',
  'account',
];

/* eslint-disable */
const DDLogs = {
  init() {
    datadogLogs.init({
      clientToken: config.get().datadogLogsEnabled ? config.get().datadogLogsClientToken : '',
      forwardErrorsToLogs: true,
      site: 'datadoghq.com',
      service: 'elsa',
      version: config.get().commitHash,

      beforeSend: (log) => {
        // discard client side network errors
        if (log.http && log.http.status_code === 0) {
          return false
        }
      },
    });

  },

  addGlobalContext(ctxName, ctxValue) {
    DD_LOGS.addLoggerGlobalContext(ctxName, ctxValue);
  },

  log(message, data) {
    const dataWithoutPII = _.pickBy(data, (value, key) => !_.includes(PII_FIELDS_BLACKLIST, key));
    DD_LOGS.logger.log(message, dataWithoutPII);
  },

  info(message, data) {
    this.log(message, data);
  },

  error(message, data) {
    const dataWithoutPII = _.pickBy(data, (value, key) => !_.includes(PII_FIELDS_BLACKLIST, key));
    DD_LOGS.logger.error(message, { ...dataWithoutPII, originalError: data?.error });
  },

  logBusinessEvent(message, data) {
    const dataWithoutPII = _.pickBy(data, (value, key) => !_.includes(PII_FIELDS_BLACKLIST, key));
    DD_LOGS.logger.log(message, { ...dataWithoutPII, businessEvent: true });
  },

  errorBusinessEvent(message, data) {
    const dataWithoutPII = _.pickBy(data, (value, key) => !_.includes(PII_FIELDS_BLACKLIST, key));
    DD_LOGS.logger.error(message, { ...dataWithoutPII, originalError: data.error, businessEvent: true });
  },
};

export default DDLogs;
