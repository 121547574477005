<template>
  <base-ambassadors-card :share-button-title="'שיתוף עם חברים'" :link="link"
                         :segment-data="segmentData"
                         :share-text="shareText"
                         show-terms>
    <template v-slot:content>
      <div class="info">
        <div v-if="!isDuringCompetition" class="ri-large-body">
          <span>שלחו המלצה על רייזאפ </span>
          <span class="ri-large-bold-body">ותוכלו להרוויח עד ₪150 </span>
          <span>על כל חברה או חבר שיצטרפו! ועל הדרך תוכלו לפרגן להם </span>
          <span class="ri-large-bold-body">בחודש ראשון חינם ו 50% הנחה על החודשיים שאחרי.</span>
        </div>
        <div v-else class="ri-large-body">
          <span>ממליצים על רייזאפ ומרוויחים עד </span>
          <span class="ri-large-bold-body">₪150 </span>
          <span>על כל חבר או חברה שיצטרפו. ובנוסף, שלושת המשתתפים שיצרפו הכי הרבה חבר׳ה לרייזאפ </span>
          <span class="ri-large-bold-body"> בין ה-18.2 ל-2.3 יזכו בשוברים לאיקאה בשווי 35,000 ₪</span>
        </div>
      </div>
    </template>
  </base-ambassadors-card>
</template>

<script>
import BaseAmbassadorsCard
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/BaseAmbassadorsCard.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'AmbassadorsCard',
  components: {
    BaseAmbassadorsCard,
  },
  data() {
    return {
      shareText: 'היי, רציתי לספר לך על רייזאפ, שירות שממש עוזר לי לנהל את הכסף שלי, הרבה'
        + 'יותר קל להבין כמה כסף נכנס, כמה יצא, על מה ומתי כדאי לעצור…נראה לי'
        + 'שהשירות ממש יכול להתאים גם לך! מהלינק שלי גם מקבלים הטבה מיוחדת'
        + '- החודש הראשון בחינם ובנוסף יש 50% הנחה על החודשיים שאחרי, שווה לנסות, לא?',
    };
  },
  computed: {
    ...mapGetters('ambassadors', ['icrc']),
    ...mapState('ambassadors', ['link']),
    ...mapGetters('referrals', ['isDuringCompetition']),
    segmentData() {
      return {
        icrc: this.icrc,
        shareName: 'ambassadorLink',
      };
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    height: 113px;
  }
}

.info {
  text-align: right;
  width: 100%;
}
</style>
