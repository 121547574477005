<template>
<payment-form-page
  :initial-values="initialValues"
  :title="title"
  :price="hardCodedPrice"
  :payment-date="paymentDate"
  :purchase-loading="loading"
  cancelButtonText="חזרה לתזרים"
  purchase-button-text="לתשלום מאובטח"
  @tokenization-success="onPurchase"
  @cancel="onCancel"
>
  <template v-slot:disclaimers>
    <offering-terms :iteration-count="3"/>
  </template>
</payment-form-page>
</template>

<script>
import PaymentFormPage
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentForm/PaymentFormPage';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { PERSONAL_INFO_FIELDS } from '@/store/modules/payment';
import dateUtils from '@/utils/dates';
import OfferingTerms from '@/pages/responsive-pages/authenticated/offerings/OfferingTerms.vue';
import router from '@/router';
import BillyApi from '@/api/BillyApi';
import DDLogs from '@/DDLogs';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'PurchaseOfferingPayment',
  components: {
    PaymentFormPage,
    OfferingTerms,
  },
  props: {
    done: { type: Function, require: true },
  },
  async created() {
    this.initialValues[PERSONAL_INFO_FIELDS.firstName] = this.activeMemberFirstName;
    this.initialValues[PERSONAL_INFO_FIELDS.lastName] = this.activeMemberLastName;
    this.initialValues[PERSONAL_INFO_FIELDS.phoneNumber] = this.activeMemberPhoneNumber;
    await this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      textColor: BaseUI.Colors.riseupWhite,
      logoColor: BaseUI.Colors.riseupWhite,
      sticky: false,
    });
    await this.fetchPricing();
  },
  data() {
    return {
      initialValues: {},
      loading: false,
    };
  },
  computed: {
    ...mapState('session', ['activeMember']),
    ...mapGetters('session', ['householdName', 'activeMemberFirstName', 'activeMemberLastName', 'activeMemberPhoneNumber']),
    title() {
      return `היי ${this.householdName}, איזה כיף שממשיכים לצמוח יחד!`;
    },
    paymentDate() {
      return dateUtils.normalizeDate(new Date());
    },
    hardCodedPrice() {
      return 76; // TODO YY - remove hard coded
    },
  },
  methods: {
    ...mapMutations('payment', ['setTokenizationError']),
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('subscription', ['fetchPricing']),
    async onPurchase(paymeToken) {
      if (!this.loading) {
        this.loading = true;
        try {
          const response = await BillyApi.purchaseOffering({
            token: paymeToken.token,
            oneOffOfferings: [],
            subscriptionOfferings: [],
            bundleOfferings: [{
              offeringId: 'no-trial-discount',
              numberOfInstallments: 1,
            }],
          });
          if (response.result !== 'success') {
            DDLogs.errorBusinessEvent('Failed purchasing Offering', { error: response.result });
            this.loading = false;
            this.setTokenizationError(response.result);
            return;
          }
        } catch (error) {
          DDLogs.errorBusinessEvent('Failed purchasing Offering', { error });
          this.loading = false;
          this.setTokenizationError(error);
          return;
        }
        await this.done();
        this.loading = false;
      }
    },
    async onCancel() {
      await router.push({ path: 'home' });
    },
  },
};
</script>

<style scoped>

</style>
