<template>
  <div class="move-to-fixed-confirmation-popup">
    <popup :back-action="backAction">
      <template v-slot:title>
        <div class="title">
          <span class="selected-category">
            מה יקרה כשההוצאה הזו תעבור להפקדות לחיסכון?
          </span>
        </div>
      </template>
      <template v-slot:content>
        <div class="explanation">
          המערכת תצפה שגם בחודשים הבאים תהיה הפקדה כזו.
        </div>
        <riseup-button title="הבנתי, זו הכוונה שלי" size="slim" variant="primary" :action="onNext" class="cta"/>
      </template>
      <template v-slot:footer>
        <div class="cancel">
          <div class="cancel-text" v-on:click.stop="cancel">ביטול</div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import CashflowViewConsts from '@/mixins/CashflowViewConsts';
import SegmentTracker from '@/mixins/SegmentTracker';
import MoveTransactionToSaving from './MoveTransactionToSaving';
import MoveTransactionToSavingSummary from './MoveTransactionToSavingSummary';

export default {
  name: 'MoveToFixedSavingExplanationPopup',
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    transaction: { type: Object, required: true },
    categoryId: { type: String, required: false },
    categoryName: { type: String, required: true },
    saveAction: { type: Function, required: true },
  },
  mixins: [CashflowViewConsts, SegmentTracker],
  created() {
    this.initTracker('MoveToFixedSavingExplanationPopup', { transaction: this.transaction });
    this.track('Entered');
  },
  computed: {
    cashflowCategory() {
      return this.getCashflowCategory({
        categoryName: this.categoryName,
        categoryId: this.categoryId,
      });
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    cancel() {
      this.track('CancelClicked');
      this.closeModal();
    },
    backAction() {
      this.track('BackClicked');
      this.closeModal();
      this.openModal({
        component: MoveTransactionToSaving,
        props: {
          transaction: this.transaction,
          categoryId: this.categoryId,
          categoryName: this.categoryName,
        },
      });
    },
    async onNext() {
      this.track('NextClicked');
      this.closeModal();
      this.openModal({
        component: MoveTransactionToSavingSummary,
        props: {
          transaction: this.transaction,
          fromCategory: this.cashflowCategory,
          toCategory: this.fixedSavingCategory,
          backContext: { backComponent: 'MoveToFixedSavingExplanationPopup', props: this.$props },
        },
        eventHandlers: {
          save: event => {
            event.stopPropagation();
            this.onSave();
          },
        },
      });
    },
    onSave() {
      this.saveAction();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/category-color-mixins';
.move-to-fixed-confirmation-popup {
  .title {
    margin-top: 10px;
  }
  .explanation {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 26px;
  }
  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .cancel-text {
      color: $riseup_black;
      font-size: 18px;
      font-weight: normal;
    }
  }
}
</style>
