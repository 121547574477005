<template>
  <colored-popup overlayImage="/images/plan-ahead/popup-clouds.svg" :close-action="closeAction">
    <template v-slot:top-content>
      <div class="insight-header ri-large-headline">
        <div>וואו!</div>
        הגעת לסכום שרצית <span class="amount">בשביל {{ planName }}!</span>
      </div>
    </template>
    <template v-slot:bottom-content>
      <div class="ri-large-body text-align-right">
        התוכנית עברה ל״תוכניות שהסתיימו״, ולא יועבר אליה כסף יותר.
      </div>
      <callout class="margin-vertical-l text-align-right" iconPath="icons/heart-icon.svg" title="טיפ מאיתנו">
        <template v-slot:text>
          את ההוצאות שהוצאת על התוכנית הזו כדאי להגדיר כ״הוצאה לא תזרימית״.
        </template>
      </callout>
      <riseup-button class="margin-bottom-medium"
                     title="לצפייה בתוכנית שהסתיימה"
                     :action="editPlan"
                     size="slim"
                     bold />
    </template>
  </colored-popup>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import PlanDetailsPage from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanDetailsPage';

export default {
  name: 'PlanCompletedEnoughMoneyPopup',
  insightName: 'PlanCompletedEnoughMoney',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    Callout: BaseUI.Callout,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    plan: {
      type: Object,
      require: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('PlanCompletedEnoughMoneyPopUpLoaded', this.insight);
  },
  computed: {
    planName() {
      return this.plan.name;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    editPlan() {
      this.closeAction();
      Segment.trackUserInteraction('PlanAhead_Section_GoToEditPlan');
      this.openModal({
        component: PlanDetailsPage,
        props: {
          plan: this.plan,
        },
        popupAlignment: 'full-screen',
      });
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.insight-header {
  display: flex;
  flex-direction: column;
  height: 190px;
  color: $riseup_white;
  justify-content: center;
  align-items: center;
}

.text-align-right {
  text-align: right;
}
</style>
