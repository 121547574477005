<template>
  <div class="step-container">
    <Step>
      <template v-slot:content>
        <div class="content">
          <lottie-player class="animation" src="/animations/rainbow.json" :autoplay="true"/>
          <div class="title">איזה כיף שהצטרפת לשירות!</div>
          <div class="text">
            <b>נשמח לשמוע קצת על החוויה שלך</b>
            עד עכשיו,
            זה ייקח פחות מדקה.
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <RiseupButton
            class="next-button"
            title="לשאלון"
            :action="onClicked"
            :bold="true"
        />
      </template>
    </Step>

  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { activationSurveyStepsConfig } from '@/pages/responsive-pages/authenticated/survey/activation-survey/consts';
import Step from '../../components/Step';

export default {
  name: 'StartStep',
  components: {
    Step,
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserGot(`ActivationSurvey${this.activeStep}_PageEntered`);
  },
  computed: {
    ...mapState('activationSurvey', ['answers', 'activeStep']),
    currentStep() {
      return activationSurveyStepsConfig[this.activeStep];
    },
  },
  methods: {
    ...mapMutations('activationSurvey', ['setActiveStep']),
    onClicked() {
      Segment.trackUserGot(`ActivationSurvey${this.activeStep}_NextClicked`, this.answers);
      this.setActiveStep(this.currentStep.nextStep(this.answers));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.step-container {
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20%;

    .animation {
      padding: 4vw;
      margin-bottom: 30px;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      margin: 10px;
      text-align: center;
    }

    .text {
      margin-top: 10px;
      font-size: 18px;
      text-align: center;
      line-height: 24px;
    }
  }

  .next-button {
    width: 100%;
    height: 54px;
  }
}
</style>
