<template>
  <div class="advisory-icon-header">
    <img src="/images/advisory/advisory-icon.svg" class="icon">
  </div>
</template>

<script>
export default {
  name: 'AdvisoryIconHeader',
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.advisory-icon-header{
  display: flex;
  justify-content: flex-end;
  height: 62px;
  background-color: $riseup_dark_blue;
  border-bottom: 1px solid $riseup_dark_blue;

  .icon{
    padding: 15px 25px;
  }
}
</style>
