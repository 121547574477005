<template>
  <div class="radio-select">
    <div class="radio">
      <div v-if="allowOtherSelect" @click="select(otherOption)" class="radio-option other" :class="{ selected: isSelected(otherOption) }">
        <div class="radio-row">
          <div class="radio-button"></div>
          <div class="answer-text">אחר</div>
          <text-input :value="otherValue"
                      :placeholder="'אפשר לכתוב כאן...'"
                      :on-input="setCustomText"/>
        </div>
      </div>
      <div v-for="(option, index) in options" :key="index"
           @click="select(option.value)" class="radio-option" :class="{ selected: isSelected(option.value) }">
        <div class="radio-row">
          <div class="radio-button"></div>
          <div class="answer-col">
            <div class="answer-text margin-right-sm">{{ option.label }}</div>
            <div v-if="option.description" :class="{ selected: isSelected(option.value) }" class="answer-description margin-right-sm">
              {{ option.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

// This just needs to be a unique string that will not be used as an option.value
const OTHER_OPTION = '__#_INTERNAL_OTHER_OPTION_#__';

export default {
  name: 'RadioSelect',
  components: {
    TextInput: BaseUI.TextInput,
  },
  data() {
    return {
      selected: this.isInitialValueInOptions() ? this.startSelection : OTHER_OPTION,
      otherValue: this.isInitialValueInOptions() ? '' : this.startSelection,
    };
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    setAnswer: {
      type: Function,
      required: true,
    },
    startSelection: {
      required: false,
      default: null,
    },
    allowOtherSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    otherOption() {
      return OTHER_OPTION;
    },
  },
  methods: {
    isSelected(value) {
      return value === this.selected;
    },
    select(value) {
      this.selected = value;
      if (value === OTHER_OPTION) {
        this.setAnswer(this.otherValue);
      } else {
        this.setAnswer(value);
      }
    },
    setCustomText(text) {
      this.otherValue = text;
      this.setAnswer(text);
    },
    isInitialValueInOptions() {
      return this.options.map(option => option.value).includes(this.startSelection);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.radio-select {
  font-family: SimplerPro;

  .radio {
    font-size: 16px;

    .radio-option {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: $ri_spacing-sm 20px;
      cursor: pointer;
      margin-bottom: $ri_spacing-s;
      border: solid 1px $riseup_gray_0;
      border-radius: 16px;
      min-height: 76px;
      justify-content: center;

      .radio-row {
        display: flex;
        align-items: center;

        .answer-col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .radio-button {
          width: 20px;
          height: 20px;
          border: solid 1px $riseup_gray_0;
          border-radius: 10px;
          flex-shrink: 0;
        }

        .answer-text {
          font-size: 16px;
          color: $riseup_black;
          line-height: 24px;
        }
        .input-group::v-deep {
          height: auto;
          margin-bottom: 0;
          .input {
            border-top: none;
            border-right: none;
            border-left: none;
            border-radius: 0;
            padding: 0;
            font-size: 16px;
            line-height: 20px;
            height: 20px;
          }
        }
      }

      .answer-description {
        color: $riseup_gray_2;
        line-height: 24px;
      }

      &.selected {
        border: solid 1px $riseup_blue;

        .radio-button {
          border: solid 6px $riseup_blue;
        }

        .answer-text {
          color: $riseup_blue;
          font-weight: bold;
        }

        .answer-description {
          color: $riseup_blue;
        }
      }
    }
  }
}

</style>
