<template>
  <div class="get-payment-details-card">
    <card :hasHeaderPadding="false">
      <div class="content">
        <div class="ri-bold-title margin-bottom-sm"> לאן להעביר את הכסף?</div>
        <div class="ri-body margin-bottom-l">{{ description }}</div>
        <riseup-button :title="'להזנת פרטי חשבון'" :size="'slim'" class="payment-button" :action="openPopup"/>
      </div>
    </card>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import GetPaymentDetailsPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/GetPaymentDetailsPopup';

export default {
  name: 'GetPaymentDetailsCard',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Card: BaseUI.Card,
  },
  props: {
    needsPayment: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('GetPaymentDetailsCard_Shown');
  },
  computed: {
    ...mapGetters('session', ['activeMemberFirstName']),
    description() {
      return this.needsPayment
        ? 'גם עשית טוב וגם מגיע לך כסף על זה! כדי שנוכל להעביר לך את התשלום על החבר או החברה שהצטרפו, צריך להזין פרטי חשבון בנק.'
        : 'כדי שנוכל להעביר לך את התשלום על החברים שיצטרפו, נצטרך את פרטי חשבון הבנק שלך.';
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openPaymentPopup(isEditMode) {
      this.openModal({
        component: GetPaymentDetailsPopup,
        popupAlignment: 'full-screen',
        props: {
          afterSave: this.afterSave,
          isEditMode,
        },
      });
    },
    openPopup() {
      Segment.trackUserInteraction('AddPaymentDetails_Clicked');
      this.openPaymentPopup(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/border-radius";

.get-payment-details-card {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
  .content {
    margin: 20px 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .payment-button {
      width: 100%;
    }
  }
}

</style>
