<template>
  <div class="dd-header flex-column justify-content-center">
    <div class="flex-row space-between">
      <div class="text ri-medium-bold-headline ri-alte margin-right-l" v-if="authenticated">
        קופת הגמל להשקעה
        של {{ activeMemberFirstName }}
      </div>
      <div class="text ri-medium-bold-headline ri-alte margin-right-l" v-else-if="isReduceMonthlyDeposit">
        שינויים בחיסכון - מה שהכי נכון לך עכשיו
      </div>
      <div class="text ri-medium-bold-headline ri-alte margin-right-l" v-else>
        איזה כיף שהחיסכון שלך הולך לגדול!
      </div>
      <img :src="require('@/assets/dynamic-deposit/shapes.svg')" class="shapes"/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'DDHeader',
  props: {
    authenticated: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('session', ['activeMemberFirstName']),
    ...mapGetters('dynamicSavings', ['isReduceMonthlyDeposit']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.dd-header {
  height: 101px;
  background-color: $riseup_green;

  .text {
    text-align: right;
    color: $riseup_beige;
    align-self: center;
  }

  .shapes {
    margin-right: 10px;
  }
}
</style>
