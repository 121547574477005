<template>
  <insight :insight="insight"
           :animation-name="'Income.json'"
           :button-text="'לעדכון ופרטים נוספים'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>זיהינו שלקחת הלוואה של <span class="amount">{{ formattedAmount }} ₪.</span>
        המערכת הוציאה אותה מהתזרים של {{ budgetMonth }}</div>
    </template>
  </insight>
</template>

<script>
import TransactionInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/TransactionInsightPopup';
import CashflowUpdaterApi from '@/api/CashflowUpdaterApi';
import cashflowViewConsts from '@/constants/cashflow-view';
import { mapActions, mapState } from 'vuex';
import EventBus from '@/event-bus/event-bus';
import ExclusionToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/insights/ExclusionToaster';
import dateUtils from '@/utils/dates';
import moneyUtils from '@/utils/money';
import Insight from './components/Insight';

export default {
  name: 'LoanInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate']),
    budgetMonth() {
      return dateUtils.getShortHebrewMonth(this.insight.budgetDate);
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.incomeAmount, 0);
    },
    buttons() {
      return [
        {
          text: 'תשאירו מחוץ לתזרים',
          variant: 'primary',
          action: async () => {
            await CashflowUpdaterApi.excludeTransaction({ transactionId: this.insight.transactionId });
            await this.markAsRead(this.insight);
            this.openExclusionToaster('נשארה מחוץ לתזרים');
            this.closePopup();
          },
        },
        {
          text: 'תחזירו אותה לתזרים',
          variant: 'secondary',
          action: this.moveTransactionBackToCashflow,
        },
      ];
    },
    canDoOptimisticChange() {
      return this.insight.budgetDate === this.presentedBudgetDate;
    },
  },
  methods: {
    ...mapActions('editCashflow', ['moveExcludedToVariableIncome']),
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    buttonAction() {
      this.openModal({
        component: TransactionInsightPopup,
        props: {
          insight: this.insight,
          insightPrefix: 'הלוואה',
          insightPopupTitle: `זיהינו שלקחת הלוואה. המערכת הוציאה אותה מהתזרים של ${this.budgetMonth}`,
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_INCOME,
          insightPopupExplanation: 'הסיבה היא שברוב המקרים הלוואה לא משמשת למימון ההוצאות החודשיות השוטפות',
          buttons: this.buttons,
          showFeedbackFooter: true,
        },
      });
    },
    closePopup() {
      this.closeModal();
    },
    openExclusionToaster(action) {
      EventBus.$emit('open-toaster', {
        component: ExclusionToaster,
        props: {
          excludedAmount: 'loan',
          excludedAction: action,
        },
      });
    },
    async moveTransactionBackToCashflow() {
      await this.markAsRead(this.insight);
      if (this.canDoOptimisticChange) {
        await this.moveExcludedToVariableIncome({ transactionId: this.insight.transactionId });
      } else {
        await CashflowUpdaterApi.moveExcludedToVariableIncome({ transactionId: this.insight.transactionId });
      }
      this.openExclusionToaster('חזרה לתזרים');
      this.closePopup();
    },
  },
};

</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}
</style>
