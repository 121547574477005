<template>
  <div class="source-connection-details">
    <open-banking-details v-if="openBankingSupported && !updateMode && !showOldConnection" id="open-banking-credentials-details"
                          :name-already-used-error="nameAlreadyUsedError"
                          :set-name-already-in-use-error="setNameAlreadyInUseError"
                          :validate-name="validateName"
                          :source="source"
                          :show-logo="true"/>
    <div v-if="!preventClosedBankingConnection && nonObkCredsSupported && openBankingSupported  && !updateMode && !showOldConnection">
      <div class="separator">
        <div class="line"></div>
        <div class="or">או</div>
        <div class="line"></div>
      </div>
      <div class="creds-container">
        <riseup-button class="creds-btn"
                       variant="secondary"
                       title="לחיבור עם שם משתמש וסיסמה"
                       @click="showCredentialDetails"/>
      </div>
    </div>
    <div v-if="migrationToOBKFromInvalidCreds" class="update-creds-btn-container">
        <riseup-button class="update-creds-btn"
                       variant="secondary"
                       title="לעדכן סיסמה ולהשאר עם חיבור רגיל"
                       @click="updateExistingCredentials"/>
    </div>
    <credentials-details id="open-banking-credentials"
                         v-else-if="!isAllOBKSourcesValid || (nonObkCredsSupported && showOldConnection) || updateMode"
                         :invalid-credentials="invalidCredentials"
                         :source="source"
                         :name-already-used-error="nameAlreadyUsedError"
                         :set-name-already-in-use-error="setNameAlreadyInUseError"
                         :save-credentials-error="saveCredentialsError"
                         :update-mode="updateMode"
                         :on-save="onSave"
                         :show-logo="true">
      <template v-slot:title>
        <div class="titles-container">
          <div class="title">חיבור עם פרטי התחברות</div>
          <div class="secondary-title">
חיבור חשבון באמצעות
            <span class="bold">פרטי ההתחברות הקבועים</span>
            שלך ל{{ formattedSourceName }}
          </div>
        </div>
      </template>
    </credentials-details>
    <open-banking-incident-info v-if="!openBankingSupported" :source="source" :preventClosedBankingConnection="preventClosedBankingConnection"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import OpenBankingDetails from '@/base-components/onboarding/openBanking/OpenBankingDetails';
import CredentialsDetails from '@/base-components/onboarding/CredentialsDetails';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';
import Segment from '@/Segment';
import OpenBankingMixin from '@/base-components/onboarding/openBanking/OpenBankingMixin';
import OpenBankingIncidentInfo from '@/base-components/onboarding/openBanking/OpenBankingIncidentInfo';

export default {
  name: 'SourceConnectionDetails',
  mixins: [OpenBankingMixin],
  components: {
    OpenBankingDetails,
    OpenBankingIncidentInfo,
    CredentialsDetails,
    RiseupButton: BaseUI.V2Components.RiseupButton,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    nameAlreadyUsedError: {
      type: Boolean,
      default: false,
    },
    saveCredentialsError: {
      type: Boolean,
      default: false,
    },
    setNameAlreadyInUseError: {
      type: Function,
    },
    invalidCredentials: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    validateName: {
      type: Function,
    },
    updateMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    preventOBkConnection: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    const sourceIdForFf = this.source.id === 'discountNew' ? 'discount' : this.source.id;
    this.openBankingSupported = !!this.obkCredsSupported
      && !this.preventOBkConnection
      && this.isOpenBankingSupported({ sourceId: sourceIdForFf })
      && this.isSourceOpenToCustomer(sourceIdForFf);
    this.showOldConnection = this.showOldConnection || (!this.openBankingSupported && !this.preventClosedBankingConnection);
  },
  data() {
    return {
      openBankingSupported: true,
      showOldConnection: false,
    };
  },
  computed: {
    ...mapGetters('credentials', ['closedBankingCredentialsToUpdate', 'isAllOBKSourcesValid']),
    ...mapGetters('featureFlags', ['preventPoalimClosedBanking']),
    obkCredsSupported() {
      return this.source.openBanking;
    },
    nonObkCredsSupported() {
      return this.source.credentials;
    },
    formattedSourceName() {
      const { displayName, id } = this.source;
      if (id === 'leumicard') {
        return displayName;
      }
      return this.source.indefiniteDisplayName;
    },
    migrationToOBKFromInvalidCreds() {
      return !this.openBankingSupported && this.closedBankingCredentialsToUpdate?.status === 'passwordInvalid';
    },
    preventClosedBankingConnection() {
      return !this.closedBankingCredentialsToUpdate && this.preventPoalimClosedBanking && this.source.id === 'poalim';
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapMutations('credentials', ['setClosedBankingCredentialsIdToUpdate']),

    showCredentialDetails() {
      Segment.trackUserGot('SourceConnection_BackToCredsClicked', { sourceName: this.source.sourceName });
      if (this.closedBankingCredentialsToUpdate) {
        this.updateExistingCredentials();
      } else {
        this.showOldConnection = true;
      }
    },
    updateExistingCredentials() {
      Segment.trackUserGot('SourceConnection_ConnectRegularCredsClicked', { sourceName: this.source.sourceName });
      this.closeModal();
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceToUpdate: this.closedBankingCredentialsToUpdate,
          updateMode: true,
        },
        popupAlignment: 'full-screen',
      });
      this.setClosedBankingCredentialsIdToUpdate(null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/typography';
@import '@/scss/mixins';

.source-connection-details {
  background-color: $riseup_white;
  .titles-container {
    .title {
      color: $riseup_black;
      margin-bottom: 16px;
      font-family: SimplerProAlte, sans-serif;
      font-size: $ri-font-size-24;
      line-height: $ri-font-size-32;
      font-weight: $ri-font-weight-bold;
    }

    .secondary-title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;

      .bold {
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }

  .separator {
    @include responsive-padding;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    color: $riseup_gray_2;

    .line {
      height: 1px;
      width: 100%;
      background-color: $riseup_gray_1;
    }

    .or {
      font-family: SimplerPro;
      color: $riseup_black;
      margin: 0 15px;
    }
  }
  .creds-container{
    margin-top: 45px;
    padding: 0 24px;
    .creds-btn{
      width: 100%;
      margin-bottom: 30px;
    }

    ::v-deep .riseup-button .button-titles .button-title {
      font-size: 18px;
    }
  }

  .update-creds-btn-container{
    margin-top: 45px;
    padding: 0 24px;

    .update-creds-btn{
      width: 100%;
    }

    ::v-deep .riseup-button .button-titles .button-title {
      font-size: 18px;
    }
  }
}
</style>
