import { render, staticRenderFns } from "./MenuTile.vue?vue&type=template&id=2bcbb326&scoped=true"
import script from "./MenuTile.vue?vue&type=script&lang=js"
export * from "./MenuTile.vue?vue&type=script&lang=js"
import style0 from "./MenuTile.vue?vue&type=style&index=0&id=2bcbb326&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bcbb326",
  null
  
)

export default component.exports