<template>
  <div class="create-tracking-category-popup">
    <colored-popup class="primary" :close-action="close" :has-top-padding="false">
      <template v-slot:top-content>
        <div class="top-content">
          <div class="title">
            <div>כל הכבוד {{householdName}},</div>
            <div v-if="isPredictedCashflowPositive">להציב לעצמך יעד אחד החודש זה צעד ראשון בדרך לצמיחה כלכלית!</div>
            <div v-else>להציב לעצמך יעד אחד החודש זה צעד ענק בדרך לתזרים חיובי!</div>
          </div>
          <div class="lottie-container">
            <lottie-player class="lottie-player"
                           src="/animations/challenge-popup.json" :autoplay="true" :loop="true"/>
          </div>
        </div>
      </template>

      <template v-slot:bottom-content>
        <bullet-block v-for="(section, i) of sections" :key="i" :title="section.title" :text="section.text" :bullet-text="i+1"/>
        <riseup-button :action="next"
                       :variant="'primary'"
                       :size="'slim'"
                       title="קדימה לדרך"/>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import BulletBlock
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/BulletBlock';
import { mapGetters } from 'vuex';
import Segment from '@/Segment';

const LIMIT_NEGATIVE_ONLY = 'negative-only';

const sections = [
  {
    title: 'מגדירים יעד חודשי',
    text: 'בוחרים קטגוריה אחת להתמקד בה',
  },
  {
    title: 'מתעדכנים בוואטסאפ',
    text: 'בהודעות השבועיות יופיע בדיוק כמה נשאר',
    limit: LIMIT_NEGATIVE_ONLY,
  },
  {
    title: 'מצמצמים ומתחילים לחסוך',
    text: 'עמדת ביעד? כבר חסכת כסף!',
  },
];

export default {
  name: 'ChallengeIntroPopup',
  created() {
    Segment.trackUserInteraction('ChallengeIntroPopup_Shown');
  },
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    BulletBlock,
  },
  computed: {
    ...mapGetters('session', ['householdName']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    isPredictedCashflowPositive() {
      return this.predictedBalanceAtEndOfMonth > 0;
    },
    sections() {
      return this.isPredictedCashflowPositive
        ? sections.filter(s => s.limit !== LIMIT_NEGATIVE_ONLY)
        : sections;
    },
  },
  methods: {
    next() {
      Segment.trackUserInteraction('ChallengeIntroPopup_Next_Clicked');
      this.$emit('done-clicked');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

  .bullet-block:last-of-type {
    margin-bottom: 30px;
  }

  .top-content {

    .title {
      line-height: 27px;
    }

    .lottie-container{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: -45px;

      .lottie-player{
        width: 200px;
      }
    }
  }

</style>
