<template>
  <insight :insight="insight"
           animation-name="hever-benefit.json"
           button-text="לפרטים נוספים"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>במועדון חֶבֶר? מגיעה לך הטבה על מנוי רייזאפ: 50% הנחה ל־6 חודשים!</div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import router from '@/router';
import Segment from '@/Segment';
import Insight from './components/Insight';

export default {
  name: 'HeverBenefitInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    async buttonAction() {
      Segment.trackUserInteraction('HeverBenefitInsight_ButtonClicked');
      await this.markAsRead(this.insight);
      router.push({ path: '/hever_benefit' });
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
