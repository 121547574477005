<template>
  <div class="move-to-saving-summary">
    <popup :title="title"
           :close-action="close"
           :back-action="backAction">
      <template v-slot:content>
        <change-category-path :old-category="fromCategory" :new-category="toCategory" />
        <transaction-details :transaction="transaction" />
        <riseup-button title="אישור" size="slim" :action="onSave" class="cta"/>
      </template>
    </popup>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import SegmentTracker from '@/mixins/SegmentTracker';
import MovePredictionWithActualToSaving from './MovePredictionWithActualToSaving';
import MoveTransactionToSaving from './MoveTransactionToSaving';
import MoveOneTimeTransactionToSaving from './MoveOneTimeTransactionToSaving';
import MoveToFixedSavingExplanationPopup from './MoveToFixedSavingExplanationPopup';
import ChangeCategoryPath from '../ChangeCategoryPath.vue';
import TransactionDetails from '../TransactionDetails';

export default {
  name: 'MoveTransactionToSavingSummary',
  components: {
    Popup: BaseUI.Popup,
    ChangeCategoryPath,
    TransactionDetails,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    transaction: { type: Object, required: true },
    fromCategory: { type: Object, required: true },
    toCategory: { type: Object, required: true },
    backContext: { type: Object, required: false },
    title: { type: String, default: 'ההוצאה תעבור' },
  },
  mixins: [SegmentTracker],
  created() {
    this.initTracker(
      'MoveTransactionToSavingSummary_',
      {
        transaction: this.transaction,
        fromCategory: { cashflowCategory: this.fromCategory.cashflowCategory, categoryName: this.fromCategory.categoryName },
        toCategory: { cashflowCategory: this.toCategory.cashflowCategory, categoryName: this.toCategory.categoryName },
      },
    );
    this.track('Entered');
  },
  computed: {
    BACK_COMPONENTS() {
      return {
        MovePredictionWithActualToSaving,
        MoveOneTimeTransactionToSaving,
        MoveToFixedSavingExplanationPopup,
        MoveTransactionToSaving,
      };
    },
    backAction() {
      if (this.backContext) {
        return () => {
          this.track('BackClicked');
          this.closeModal();
          this.openModal({
            component: this.BACK_COMPONENTS[this.backContext.backComponent],
            props: this.backContext.props,
          });
        };
      }
      return undefined;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    close() {
      this.track('CloseClicked');
      this.closeModal();
    },
    async onSave(event) {
      this.track('SaveClicked');
      this.$emit('save', event);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">

@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/category-color-mixins';

.move-to-saving-summary {

  .change-category-path {
    margin-bottom: 40px;
  }

  .details {
    margin-bottom: 12px;
  }

  .cta {
    margin-top: 40px;
  };
}

</style>
