import moment from 'moment';
import { OFFERING_TYPES } from '@/api/BillyApi';
import { SIGNUP_OFFERING_IDS } from './oneOff-Offerings';

export default class LiorFrenkelAudioCourse {
  id = SIGNUP_OFFERING_IDS.AUDIO_COURSE;

  total = 0;

  freeAddOn = true;

  skipPurchaseInfoPage = true;

  subscriptionStartDate = moment();

  offeringCard = {
    buttonCtaText: 'להצטרף לתוכנית',
    moreInfoLink: 'https://signup.riseup.co.il/flow/lior_frenkle/',
    offeringBullets: [
      '5 פרקים שבועיים ישירות בווטסאפ',
      'טיפים לשינוי הרגלים',
      'כלים וצעדים פשוטים לשינוי כלכלי',
      'הקורס חינם ובלעדי',
    ],
    isOneOffPayment: true,
    price: 0, // will be filled later from backend
  };

  purchaseInfo() {
    return {
      buttonText: 'הרשמה והתחלת תקופת נסיון',
      details: {
        offerId: SIGNUP_OFFERING_IDS.AUDIO_COURSE,
        title: '5 פרקי פודקאסט',
        subTitle: 'כל הכלים, הטיפים והצעדים כדי להיות בטוב עם כסף',
        amount: 0, // will be filled later from backend
        type: OFFERING_TYPES.ONE_OFF,
      },
    };
  }

  insertPaymentDetailsPage = {
    title: 'התחלת תקופת הניסיון',
    buttonCtaText: 'הרשמה והתחלת תקופת הניסיון',
  };

  successPurchasePageInfo = {
    checkedItems: [{
      text: 'הרשמה לרייזאפ',
      checked: true,
    }, {
      text: 'הצטרפות לתוכנית',
      checked: true,
    }, {
      text: 'חיבור חשבון בנק וקבלת תזרים',
      checked: false,
    }, {
      text: 'התחלת התוכנית',
      checked: false,
    }],
  };

  isAvailable({ offerIds }) {
    return (offerIds.includes(this.id));
  }

  startDate() {
    return undefined;
  }
}
