<template>
  <full-screen-modal title="hello" :close-action="closeActionClicked" class="get-payment-details-popup">
    <template v-slot:content>
      <div class="modal-content margin-ml">
        <div v-if="isEditMode" class="ri-medium-bold-headline margin-bottom-l">עריכת פרטי חשבון לתשלום</div>
        <div v-else class="ri-medium-bold-headline">פרטי חשבון לקבלת תשלום</div>
        <div class="description ri-body" v-if="!isEditMode">
           {{activeMemberFirstName}}, כדי שנוכל לשלם לך על ההמלצות ששלחת אפשר לבחור חשבון בנק קיים מהתזרים או להוסיף חדש.
         </div>

        <div class="bank-account-picker" v-if="!isEditMode">
          <dropdown :placeholder="'בחירת חשבון בנק'"
                    :options="bankAccountsOptions"
                    :on-select="onSelect" />
        </div>

        <bank-details-form v-if="selectedBankLabel || isEditMode" :on-submit="onSubmit" :loading="loadReferrals"
                           :server-error="paymentDetailsError" :initialData="initialData" :key="selectedBankLabel"/>
      </div>
    </template>
  </full-screen-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BankDetailsForm
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/referral-receive-payments/BankDetailsForm.vue';
import Segment from '@/Segment';
import FullScreenModal from '@/base-components/FullScreenModal.vue';
import _ from 'lodash';
import Dropdown
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/referral-receive-payments/DropdownInput.vue';

export default {
  name: 'GetPaymentDetailsPopup',
  components: {
    FullScreenModal,
    BankDetailsForm,
    Dropdown,
  },
  data() {
    return {
      selectedBankLabel: null,
    };
  },
  props: {
    afterSave: {
      type: Function,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    Segment.trackUserGot('GetPaymentDetailsPopup_Shown');
  },
  computed: {
    ...mapGetters('session', ['activeMemberFirstName']),
    ...mapState('referrals', ['paymentDetailsError', 'loadReferrals', 'referrerInfo']),
    ...mapGetters('referrals', ['hasPaymentDetails', 'bankAccountOwner', 'bankId', 'bankAccountNumber', 'idNumber', 'bankBranch']),
    ...mapGetters('credentials', ['connectedBankCredentials']),
    bankAccountDetails() {
      return _.flatMap(this.connectedBankCredentials, bank => {
        const { idNumber } = bank;
        return _.chain(bank.accounts)
          .filter(account => account.sourceType === 'bank')
          .map(account => ({
            ...this.parseBankAccountNumber(account.accountNumberPiiValue),
            idNumber,
            bankAccountNumber: account.accountNumberPiiValue,
            source: account.source,
            bankCode: bank.bankCode,
          }))
          .value();
      });
    },
    bankAccountsOptions() {
      const bankAccountsLabels = _.map(this.bankAccountDetails, account => ({
        label: account.bankAccountNumber,
        selectedLabel: account.bankAccountNumber,
      }));

      return [...bankAccountsLabels, { label: '+ חשבון חדש לקבלת תשלום', selectedLabel: 'חשבון חדש לקבלת תשלום', color: '#5D7AFD' }];
    },
    selectedBankDetails() {
      const existingBankDetails = _.find(this.bankAccountDetails, { bankAccountNumber: this.selectedBankLabel });
      if (!existingBankDetails) {
        return null;
      }
      return {
        idNumber: { value: existingBankDetails.idNumber, disabled: !!existingBankDetails.idNumber },
        branchNumber: { value: existingBankDetails.branchNumber, disabled: !!existingBankDetails.branchNumber },
        accountNumber: { value: existingBankDetails.accountNumber, disabled: true },
        bankCode: { value: existingBankDetails.bankCode, disabled: true },
      };
    },
    existingPaymentDetailsToEdit() {
      return {
        name: { value: this.bankAccountOwner },
        idNumber: { value: this.idNumber },
        bankCode: { value: this.bankId },
        branchNumber: { value: this.bankBranch },
        accountNumber: { value: this.bankAccountNumber },
      };
    },
    initialData() {
      if (this.isEditMode) {
        return this.existingPaymentDetailsToEdit;
      }
      return this.selectedBankDetails;
    },
  },
  methods: {
    ...mapActions('referrals', ['savePaymentDetails', 'init']),
    ...mapActions('modalRootStore', ['closeModal']),
    async onSubmit(paymentDetails) {
      Segment.trackUserInteraction('GetPaymentDetailsPopup_SubmitClicked');
      await this.savePaymentDetails(paymentDetails);
      if (!this.paymentDetailsError) {
        await this.init();
        this.closeModal();
        this.afterSave();
      }
    },
    closeActionClicked() {
      Segment.trackUserInteraction('GetPaymentDetailsPopup_CloseButtonClicked');
      this.closeModal();
    },
    onSelect(selectedBankAccount) {
      this.selectedBankLabel = selectedBankAccount.label;
    },
    parseBankAccountNumber(bankAccountNumber) {
      const bankAccountNumberParts = bankAccountNumber.split('-');
      return (bankAccountNumberParts.length >= 1)
        ? {
          branchNumber: bankAccountNumberParts[bankAccountNumberParts.length - 2] ?? '',
          accountNumber: bankAccountNumberParts[bankAccountNumberParts.length - 1],
        } : {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";
@import "~@riseupil/base-ui/src/scss/mixins";

.get-payment-details-popup {
  text-align: right;
  .modal-content {
    width: 100%;
    height: 100%;
    @include responsive-padding;
    overflow: scroll;
    overflow-y: auto;
    scroll-behavior: smooth;

    .bank-account-picker {
      margin: $ri-spacing-ml 0 $ri-spacing-l;
      width: 100%;
    }

    .description {
      margin-top: 4px;
    }

  }
}

</style>
