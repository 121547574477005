<template>
  <div class="insight">
    <card :has-footer-padding="false" :has-content-padding="false" :has-header-padding="false">
      <template v-slot:header>
        <div class="image-container">
          <lottie-player v-if="animationName"
                         ref="lottiePlayer"
                         :class="backgroundVariant"
                         :src="`/animations/${animationName}`" :autoplay="true" :loop="loop"/>
          <img class="top-image" v-if="imageName" :src="`/images/insights/${imageName}`"/>
          <icon v-if="showDismiss" class="dismiss-button" icon-name="cancel" size="24px" @click="dismissInsight()" :color="iconColor" fill/>
        </div>
      </template>
      <div class="insight-body">
        <div class="insight-text">
          <slot name="insight-text"/>
        </div>
        <riseup-button class="riseup-button"
                       :action="insightButtonClicked"
                       :size="'slim'"
                       :title="buttonText"
                       :variant="'primary'"/>
      </div>
    </card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import InsightsApi from '@/api/InsightsApi';
import EventBus from '@/event-bus/event-bus';
import DismissInsightToaster from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/insights/DismissInsightToaster.vue';
import cashflowViewConsts from '@/constants/cashflow-view';

export default {
  name: 'Insight',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    Card: BaseUI.Card,
    Icon: BaseUI.Icon,
  },
  props: {
    insight: { type: Object },
    animationName: { type: String },
    imageName: { type: String },
    buttonText: { type: String },
    buttonAction: { type: Function },
    backgroundVariant: {
      type: String,
      validator: val => ['red'].includes(val),
      required: false,
    },
    loop: {
      type: Boolean,
      default: true,
    },
    showDismiss: { type: Boolean, default: true },
  },
  created() {
    Segment.trackUserGot('InsightLoaded', this.insight);
  },
  beforeDestroy() {
    this.$refs.lottiePlayer?.disconnectedCallback();
  },
  computed: {
    iconColor() {
      return BaseUI.Colors.riseupWhite;
    },
  },
  methods: {
    ...mapActions('insights', ['clearInsight', 'dismiss', 'addInsight']),
    insightButtonClicked() {
      Segment.trackUserInteraction('InsightButtonClicked', this.insight);
      InsightsApi.sendInsightEvent(this.insight, 'insightButtonClicked');
      this.buttonAction();
    },
    dismissInsight() {
      const timeoutId = setTimeout(() => {
        this.dismiss(this.insight);
        Segment.trackUserInteraction('InsightDismissed', this.insight);
      }, cashflowViewConsts.TOASTER_TIMEOUT);
      EventBus.$emit('open-toaster', {
        component: DismissInsightToaster,
        props: {
          undo: () => {
            clearTimeout(timeoutId);
            this.addInsight(this.insight);
          },
        },
      });
      // We want the UI to remove the insight immediately and this does that
      this.clearInsight(this.insight);
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/spacings';
  @import '@/scss/mixins';
  .insight {
    @include responsive-padding;
    padding-top: 30px;
    padding-bottom: 16px;
    background-color: $riseup_gray_01;
    justify-content: center;
    display: inline-block;

    .image-container {
      background: $riseup_white;
      // The lottie player is a shadow node so we cannot override it's css. It uses grid-inline so we need to move it with relative position
      lottie-player {
        position: relative;
        top: -3px;

        &.red {
          background: $expense_red1;
        }
      }
      .top-image {
        width: 100%;
      }
      .dismiss-button {
        position: absolute;
        top: 12px;
        left: 12px;
        opacity: 0.8;
        cursor: pointer;
      }
    }
    .insight-body {
      background: $riseup_white;
      font-family: SimplerPro;
      direction: rtl;
      padding: $ri-spacing-sm $ri-spacing-ml $ri-spacing-ml $ri-spacing-ml;

      .insight-text {
        display: flex;
        align-items: center;
        min-height: 60px;
        font-size: 20px;
        line-height: 26px;
      }

      .riseup-button {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
</style>
