<template>
  <div class="category-block-with-toggle" :class="{ disabled: category.isCheckedByDefault, checked: category.isChecked}" v-on:click="toggle">
    <img v-if="category.isChecked" :src="require('@/assets/icons/check-green-icon.svg')" class="checkmark">
    <div v-else class="bullet-mark"/>
    <category-block class-name="toggle-mode" :category="category" :select-category="toggle"/>
  </div>
</template>

<script>

import CategoryBlock from '@/pages/responsive-pages/authenticated/navigation-pages/tracking-categories/CategoryBlock';

export default {
  name: 'CategoryBlockWithToggle',
  components: {
    CategoryBlock,
  },
  props: {
    category: {
      type: Object,
      require: true,
    },
    toggleCategory: {
      type: Function,
      require: true,
    },
  },
  methods: {
    toggle() {
      if (!this.category.isCheckedByDefault) {
        this.toggleCategory(this.category.label);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
  .category-block-with-toggle {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 28px;
    }

    &.disabled {
      opacity: 0.6;
      cursor: default;
    }

    .bullet-mark {
      border: 1px solid $riseup_gray_2;
      border-radius: 50%;
      height: 25px;
      width: 25px;
    }

    .checkmark {
      height: 25px;
      width: 25px;
    }

    ::v-deep.toggle-mode {
      .arrow {
        display: none;
      }
    }
  }
  .tracking-categories-flow #popup .bottom.content-padding {
    padding-right: 14px;
  }

  .tracking-categories-flow #popup .colored-top {
    padding-bottom: 0;
    padding-top: 63px;
  }
</style>
