<template>
  <div class="progress-bar">
    <div class="progress-bar-labels">
      <div class="right-side">
        <div class="label" :class="{ 'grayed-out': grayedOut }">{{ rightLabel }}</div>
        <div class="number bold" :class="[cashflowCategory, {'grayed-out': grayedOut}]">
          <div v-if="isExceedingAmount" class="icon">
            <img :src="require(`@/assets/icons/${exclamationMarkPath}`)">
          </div>
          <span v-if="placeholder">{{ placeholder }}</span>
          <decimal-number v-else :value="rightAmount" /> ₪
        </div>
      </div>

      <div v-if=!hideLeftLabel class="left-side" :class="{ 'grayed-out': grayedOut }">
        <div class="label">{{ leftLabel }}</div>
        <div class="number" :class="{ 'grayed-out': grayedOut }">
          <span v-if="placeholder">{{ placeholder }}</span>
          <animated-decimal-number v-else :value="totalAmount" /> ₪
        </div>
      </div>
    </div>

    <animated-bar :grayed-out="grayedOut"
                  :totalAmount="totalAmount"
                  :up-to-now-amount="rightAmount"
                  :cashflow-category="cashflowCategory"
                  :animate-in-viewport="animateInViewport"/>
    <div v-if="isExceedingAmount" class="exceeding-warning" :class="{ 'grayed-out': grayedOut, danger }">
      חריגה של {{ formatAmount(Math.abs(amountsDiff)) }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moneyUtils from '../../../../../../../../../utils/money';
import AnimatedBar from '../../../../../../../../../base-components/AnimatedBar.vue';
import AnimatedDecimalNumber from '../../../../../../../../../base-components/AnimatedDecimalNumber.vue';
import DecimalNumber from '../../../../../../../../../base-components/DecimalNumber.vue';

export default {
  name: 'ProgressBar',
  components: {
    AnimatedDecimalNumber,
    AnimatedBar,
    DecimalNumber,
  },
  props: {
    rightLabel: {
      type: String,
      required: true,
    },
    rightAmount: {
      type: Number,
      required: false,
    },
    leftLabel: {
      type: String,
      required: true,
    },
    leftAmount: {
      type: Number,
      required: false,
    },
    showWarningIfExceedingAmount: {
      type: Boolean,
      required: false,
    },
    hideLeftLabel: {
      type: Boolean,
      default: false,
    },
    cashflowCategory: {
      type: String,
      required: false,
    },
    grayedOut: {
      type: Boolean,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    animateInViewport: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isExceedingAmount() {
      return this.showWarningIfExceedingAmount && this.rightAmount > this.leftAmount;
    },
    amountsDiff() {
      return this.leftAmount - this.rightAmount;
    },
    totalAmount() {
      return _.max([this.leftAmount, 0]);
    },
    exclamationMarkPath() {
      if (this.grayedOut) {
        return 'exclemation-mark-gray.svg';
      }
      if (this.cashflowCategory === 'tracking') {
        return 'exclemation-mark-blue.svg';
      }
      return 'exclemation-mark-red.svg';
    },
    danger() {
      return this.cashflowCategory === 'variable';
    },
  },
  methods: {
    formatAmount(amount) {
      const formattedAmount = moneyUtils.formatAmountWithFraction(amount);
      return `${formattedAmount} ₪`;
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../scss/category-color-mixins';

  .progress-bar {
    margin-top: 15px;

    .progress-bar-labels {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      color: $riseup_black;
      font-size: 10px;

      .left-side {
        text-align: left;

        &.grayed-out {
          color: $riseup_gray_2;
        }
      }

      .label {
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 11px;

        &.grayed-out {
          color: $riseup_gray_2;
        }
      }

      .number {
        font-size: 18px;
        line-height: 21px;
        @include category-color;

        &.bold {
          font-weight: bold;
        }

        &.grayed-out {
          color: $riseup_gray_2;
        }

        .icon {
          display: inline-block;
          margin-left: 2px;
          vertical-align: middle;
          margin-bottom: 5px;
          height: 13px;
        }
      }
    }

    .danger-bar {
      height: 15px;
    }

    .exceeding-warning {
      margin-top: 14px;
      text-align: left;
      font-size: 12px;
      font-weight: bold;
      line-height: 10px;

      &.danger {
        color: $riseup_danger_red;
      }

      &.grayed-out {
        color: $riseup_gray_2;
      }
    }
  }
</style>
