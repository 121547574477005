<template>
  <insight class="economy-balance"
           :insight="insight"
           :animation-name="animationName"
           :button-action="buttonAction"
           :button-text="'הבנתי, תודה'">
    <template v-slot:insight-text>
      <span class="text" :class="{ 'break-line': !hasRemainingWeeklyVariables }">
        <span class="bold">{{ insightNoticeText }}</span>
        {{ insightTextBeforeAmount }}<span class="amount">{{ formattedAmount }} ₪ </span><span>{{ insightTextAfterAmount }}</span>
      </span>
    </template>
  </insight>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';
import Insight from './components/Insight';

export default {
  name: 'EconomyBalanceInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapGetters('cashflowView', ['remainingWeeklyVariables']),
    amountDifference() {
      return Math.abs(this.insight.originalAmount - this.insight.balancedAmount);
    },
    animationName() {
      if (this.insight.positiveBalance) {
        return 'economy-positive-balance.json';
      }
      return 'economy-negative-balance.json';
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.amountDifference, 0);
    },
    insightTextBeforeAmount() {
      if (!this.hasRemainingWeeklyVariables) {
        return 'הוצאת ';
      }
      if (this.insight.positiveBalance) {
        return 'יתרת המשתנות שלך גדלה ב-';
      }
      return 'יש לך פחות ';
    },
    insightTextAfterAmount() {
      if (!this.hasRemainingWeeklyVariables) {
        return `${this.economyMoreOrLess} על קניות בסופר בשבוע שעבר`;
      }
      if (this.insight.positiveBalance) {
        return 'כי הוצאת פחות על קניות בסופר בשבוע שעבר';
      }
      return 'ביתרת המשתנות כי הוצאת יותר על קניות בסופר בשבוע שעבר';
    },
    economyMoreOrLess() {
      if (this.insight.positiveBalance) {
        return 'פחות';
      }
      return 'יותר';
    },
    insightNoticeText() {
      if (this.insight.positiveBalance) {
        return 'איזה יופי!';
      }
      return 'כדאי לשים לב,';
    },
    hasRemainingWeeklyVariables() {
      return this.remainingWeeklyVariables > 0;
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    async buttonAction() {
      await this.markAsRead(this.insight);
    },
  },
};

</script>

<style lang="scss" scoped>

.economy-balance {
  .text {
    &.break-line {
      white-space: pre-line;
    }
    .bold {
      font-weight: bold;
    }
    .amount {
      font-weight: bold;
      white-space: pre;
    }
  }
}
</style>
