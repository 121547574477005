<template>
  <sticky-cta-page :cta-props="{buttonProps: { title: 'הזמנה דרך ווטסאפ'}}" @click="inviteMemberClicked">
    <img src="/images/advisory/mislaka-consent-header.svg" />
    <div class="mislaka-invite-member-to-cashflow">
      <div class="padding-horizontal-ml">
        <div class="ri-body top-title margin-vertical-ml">מעולה!</div>
        <div class="ri-large-headline margin-bottom-xs">כדי ליצור תוכנית משותפת לשניכם, צריך להוסיף את השותפ.ה למינוי.</div>
        <div class="ri-large-body margin-bottom-s">מי שיצטרף אליך יראה את המידע בתזרים, יקבל את כל ההודעות בדיוק כמוך, ללא תוספת תשלום.</div>
        <div class="ri-large-body">
          <span>הכנו לך הודעת</span>
          <span class="preview" v-on:click="showPreview">הזמנה אישית</span>
          <span>כל מה שצריך הוא לשלוח אותה בווטסאפ.</span>
        </div>
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions, mapGetters, mapState } from 'vuex';
import shareUtils from '@/utils/share';
import config from '@/config';
import InviteMemberHelpPopup from '@/base-components/onboarding/help-popups/InviteMemberHelpPopup';

export default {
  name: 'MislakaInviteMemberToCashflow',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  computed: {
    ...mapState('session', ['inviteToken']),
    ...mapGetters('session', ['inviteMessage']),
    inviteMemberLink() {
      return `${config.get().elsaPublicUrl}/invite?inviteToken=${this.inviteToken}`;
    },
  },
  created() {
    Segment.trackUserGot('MislakaInviteMemberToCashflow_Entered');
  },
  methods: {
    ...mapActions('session', ['initInviteToken']),
    ...mapActions('modalRootStore', ['openModal']),
    async inviteMemberClicked() {
      Segment.trackUserGot('MislakaInviteMemberToCashflow_ShareMemberInviteClicked');
      this.loading = true;
      await this.initInviteToken();
      this.loading = false;
      await shareUtils.shareLink(this.inviteMemberLink, this.inviteMessage, {});
      this.$emit('next');
    },
    showPreview() {
      Segment.trackUserInteraction('InviteMemberPreviewClicked');
      this.openModal({
        component: InviteMemberHelpPopup,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.mislaka-invite-member-to-cashflow{
  height: 100%;
  text-align: right;

  .top-title{
    color: $riseup_blue;
  }

  .image {
    height: 130px;
    width: 130px;
  }

  .preview {
    color: $riseup_blue;
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }
}

</style>
