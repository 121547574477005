<template>
  <div class="step-container">
    <div v-if="shouldAskNonCalendarCashflow" class="churn-last-chance margin-vertical-l margin-horizontal-ml">
      <span class="header ri-body ">תודה! חשבנו שיעניין אותך לדעת,</span>
      <div class="ri-large-headline margin-top-sm margin-bottom-l">
        אפשר לשנות את יום תחילת התזרים ל־10 בחודש!
      </div>
      <div class="ri-body margin-bottom-l">
        כמה קליקים והתזרים שלך יתחיל לעבוד מה־10 בחודש! גם חודשי העבר ישתנו בהתאם
      </div>
      <img :src="require('@/assets/cashflow-start-day-walkthrough/calendar.svg')" class="image"/>
      <buttons-with-or-wrapper>
        <template v-slot:firstButton>
          <riseup-button :action="goToChangeCfStartDay"
                         class="margin-top-l"
                         size="slim"
                         title="לעבור לתזרים החל מה-10"
                         variant="primary"/>
        </template>
        <template v-slot:secondButton>
          <riseup-button :action="()=>shouldAskNonCalendarCashflow = false"
                         size="slim"
                         title="להמשיך עם התזרים הרגיל"
                         variant="secondary"/>
        </template>
      </buttons-with-or-wrapper>
    </div>
    <Step v-else>
      <template v-slot:content>
        <div class="content">
          <lottie-player class="animation" src="/animations/thanks.json" :autoplay="true"/>
          <div class="title">תודה ששיתפת אותנו</div>
          <div class="text">התשובות שלך יעזרו לנו להמשיך לשפר את השירות!</div>
        </div>
      </template>
      <template v-slot:footer>
        <RiseupButton
          :action="onClicked"
          :bold="true"
          class="next-button"
          title="לתזרים"/>
      </template>
    </Step>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import {
  ACTIVATION_SURVEY_STEPS,
  CALENDAR_CASHFLOW_UNDERSTANDING_OPTION,
} from '@/pages/responsive-pages/authenticated/survey/activation-survey/consts';
import router from '@/router';
import ButtonsWithOrWrapper from '@/base-components/ButtonsWithOrWrapper.vue';
import Step from '../../components/Step';
import Intercom from '../../../../../../Intercom';

export default {
  name: 'EndStep',
  components: {
    ButtonsWithOrWrapper,
    Step,
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserGot(`ActivationSurvey${ACTIVATION_SURVEY_STEPS.End}_PageEntered`, this.answers);
    Intercom.update(this.customerId, this.householdName, { activationSurveyCompleted: true });
    this.shouldAskNonCalendarCashflow = this.answers[ACTIVATION_SURVEY_STEPS.UnderstandableFollowup]
        && this.answers[ACTIVATION_SURVEY_STEPS.UnderstandableFollowup].includes(CALENDAR_CASHFLOW_UNDERSTANDING_OPTION)
        && this.enableChangeCashflowStartDay;
  },
  computed: {
    ...mapState('activationSurvey', ['answers']),
    ...mapGetters('session', ['householdName']),
    ...mapGetters('featureFlags', ['enableChangeCashflowStartDay']),
    ...mapState('session', ['customerId']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    onClicked() {
      Segment.trackUserGot('ActivationSurvey_EndClicked');
      router.push({ path: '/sr' });
    },
    goToChangeCfStartDay() {
      Segment.trackUserGot('ActivationSurvey_ChangeCfStartDayClicked');
      router.push({ path: '/cashflow-start-day-settings' });
    },
  },
  data() {
    return {
      shouldAskNonCalendarCashflow: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.step-container {
  height: 100%;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20%;

    .animation {
      padding: 4vw;
      margin-bottom: 30px;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      margin: 10px;
    }

    .text {
      margin-top: 10px;
      font-size: 18px;
      text-align: center;
      line-height: 24px;
    }

    .touch-point {
      margin-top: 24px;
    }
  }

  .next-button {
    width: 100%;
    height: 54px;
  }

  .image {
    display: flex;
    margin: 0 auto 16px;
  }
}

.header {
  color: $riseup_blue;
}
</style>
