<template>
  <div class="delete-creds-summary-popup">
    <colored-line-popup v-bind="coloredLinePopupProps" :class="coloredLinePopupVariant">
      <template v-slot:top-content>
        <connected-credentials-details :source="source"/>
      </template>
      <template v-slot:bottom-content>
        <div class="title">{{ titleText }}</div>
        <div class="subtitle" v-if="showConfirmation">המערכת לא תכלול את החשבון
          <span class="bold">וכל ההכנסות וההוצאות הקשורות אליו </span>בבניית התזרים בעתיד</div>
        <div class="buttons-wrapper">
          <riseup-button v-bind="primaryButtonProps"/>
        </div>
      </template>
      <template v-slot:footer>
        <div class="cancel" v-on:click="closePopup">
          <span class="delete-text">ביטול</span>
        </div>
      </template>
    </colored-line-popup>
  </div>
</template>

<script>
import Segment from '@/Segment';
import ConnectedCredentialsDetails from '@/base-components/credentials/credentials-list/ConnectedCredentialsDetails';
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';

export default {
  name: 'DeleteCredsPopup',
  components: {
    ColoredLinePopup: BaseUI.ColoredLinePopup,
    RiseupButton: BaseUI.RiseupButton,
    ConnectedCredentialsDetails,
  },
  props: {
    source: Object,
  },
  data() {
    return {
      loading: false,
      showConfirmation: false,
    };
  },
  computed: {
    titleText() {
      return this.showConfirmation ? 'מה יקרה כשהחשבון ימחק מהתזרים?' : 'למחוק את החשבון?';
    },
    coloredLinePopupProps() {
      if (this.showConfirmation) {
        return {
          backAction: () => { this.showConfirmation = false; },
        };
      }
      return {
        closeAction: this.closePopup,
      };
    },
    primaryButtonProps() {
      const props = { color: 'red' };
      if (this.showConfirmation) {
        props.title = 'מחק את החשבון';
        props.action = this.deleteAccount;
        props.loading = this.loading;
      } else {
        props.title = 'כן, זאת הכוונה שלי';
        props.action = this.goToCredsConfirmationPopup;
      }
      return props;
    },
    coloredLinePopupVariant() {
      return this.source.status === 'valid' ? 'success' : 'danger';
    },
  },
  methods: {
    ...mapActions('credentials', ['deleteCredentials']),
    closePopup() {
      Segment.trackUserInteraction('DeleteCreds_CloseClicked', { sourceName: this.source.sourceName, name: this.source.name });
      this.$emit('close');
    },
    async deleteAccount() {
      this.loading = true;
      Segment.trackUserInteraction('DeleteCreds_Confirmed', { sourceName: this.source.sourceName, name: this.source.name });
      await this.deleteCredentials({ credentialsId: this.source.credentialsId });
      this.$emit('close');
      this.loading = false;
    },
    goToCredsConfirmationPopup() {
      Segment.trackUserInteraction('DeleteCreds_DeleteClicked', { sourceName: this.source.sourceName, name: this.source.name });
      this.showConfirmation = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../scss/mixins';

.delete-creds-summary-popup {
  text-align: right;
  .title {
    font-size: 22px;
    line-height: 24px;
    font-weight: bold;
    font-family: SimplerPro;
    margin-bottom: 25px;
  }
  .subtitle {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 30px;
    .bold {
      font-weight: bold;
    }
  }

  .buttons-wrapper {
    .riseup-button {
      width: 100%;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .delete-text {
      font-weight: normal;
      font-size: 18px;
      color: $riseup_black;
    }
  }
}
</style>
