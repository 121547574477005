<template>
  <div class="info-tool-tip">
    <base-tool-tip :icon-path="iconPath">
      <div class="info-text">
        <div>
          <span class="text">{{ text }}</span>
          <span class="popup-text" :class="{underline:underLineLink}" v-on:click="openPopup">{{ popupText }}</span>
        </div>
      </div>
    </base-tool-tip>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseToolTip from './BaseToolTip.vue';
import Segment from '../../Segment';

export default {
  name: 'InfoToolTip',
  props: {
    text: {
      type: String,
    },
    iconPath: {
      type: String,
      default: '/images/info-icon-blue.svg',
    },
    popupText: {
      type: String,
    },
    eventName: {
      type: String,
    },
    popupComponent: {
      type: Object,
    },
    popupProps: {
      type: Object,
    },
    underLineLink: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseToolTip,
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openPopup() {
      if (this.eventName) {
        Segment.trackUserInteraction(this.eventName);
      }
      this.openModal({
        component: this.popupComponent,
        props: this.popupProps,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .info-tool-tip {
    width: 100%;

    .info-text {
      display: flex;
      align-items: center;
      font-family: SimplerPro;
      font-size: 14px;
      line-height: 1.36;
      height: 100%;

      .text{
      }

      .popup-text{
        font-weight: bold;
        color: $riseup_blue;
        cursor: pointer;
      }

      .underline {
        text-decoration: underline;
      }
    }
  }
</style>
