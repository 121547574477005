<template>
  <div class="account-check-list-item" v-on:click="toggleCheck(account._id)">
    <div class="account-details">
      <img class="bank-icon" :src="require('@/assets/icons/bank-icon.svg')">
      <div class="account-number">{{ account.accountNumberPiiValue }}</div>
    </div>
    <div class="checkbox">
      <div v-if="!account.isIncluded" class="circle" :class="{ 'checked': account.isIncluded }"/>
      <img v-else :src="require('@/assets/icons/icon-check-blue.svg')">
    </div>
  </div>
</template>

<script>

export default {
  name: 'AccountCheckListItem',
  props: {
    account: {
      type: Object,
      required: true,
    },
    toggleCheck: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.account-check-list-item {
  @include responsive-padding;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  background-color: $riseup_white;

  .account-details {
    display: flex;
    flex-direction: row;
    align-items: center;

    .bank-icon {
      width: 24px;
      height: 24px;
      margin-left: 15px;
    }

    .account-number {
      font-size: 20px;
      font-family: SimplerPro;
      display: flex;
      align-self: end;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;

    .circle {
      display: inline-block;
      height: 32px;
      width: 32px;
      vertical-align: middle;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $riseup_gray_2;
      background-color: $riseup_white;
    }
  }
}
</style>
