<template>
  <transaction-insight-popup
    :buttons="buttons"
    :cashflow-category="cashflowCategory"
    :insight="insight"
    :insight-popup-explanation="indentIncomeExplanation"
    :insight-popup-title="''"
    :insight-prefix="'הסכום שנכנס'"
    :show-feedback-footer="false">
  </transaction-insight-popup>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import TransactionInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/TransactionInsightPopup.vue';
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '@/Segment';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import DDLogs from '@/DDLogs';

export default {
  name: 'IndentIncomePopup',
  components: { TransactionInsightPopup },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('IndentIncomePopup_Shown');
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay', 'presentedBudgetDate']),
    cashflowCategory() {
      return cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_INCOME;
    },

    buttons() {
      return [
        {
          text: 'הבנתי, תודה',
          action: async () => {
            Segment.trackUserInteraction('indentIncomePopup_Clicked');
            this.markAsRead(this.insight).catch(() => {
              DDLogs.error('Failed to mark indent income insight as read', this.insight);
            });
            this.closeModal();
          },
        },
      ];
    },
    indentIncomeExplanation() {
      return `ההכנסה הגיעה לחשבון שלך ב${getSourceConfigurationBySourceName(this.insight.source).indefiniteDisplayName} והיא תופיע בתזרים הבא שלך`;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('insights', ['markAsRead']),
  },
};
</script>

<style lang="scss" scoped>

::v-deep #popup .footer {
  padding: 0;
  border-radius: 11px;
}

.footer-button {
  border-radius: 11px;
}

</style>
