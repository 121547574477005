<template>
  <insight :insight="insight"
           :animation-name="'merge-insight.json'"
           :button-text="'להשלמת חיבור האשראי'"
           :background-variant="'red'"
           :loop="false"
           :show-dismiss="false"
           :button-action="()=>openMergeCaseInvestigationsPopup(insight.mergeInvestigations)">
    <template v-slot:insight-text>
      <div>
        <span>כדי לראות את פירוט ההוצאות המלא והמדויק שלך, חשוב להשלים את</span>
        <span class="bold">חיבור כל כרטיסי האשראי לשירות</span>
      </div>
    </template>
  </insight>
</template>

<script>

import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';
import PapaMergeQuestionsPopupsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/PapaMergeQuestionsPopupsMixin.vue';
import Insight from './components/Insight';

export default {
  name: 'NewPapasMergeQuestionsInsight',
  components: {
    Insight,
  },
  mixins: [PapaMergeQuestionsPopupsMixin],
  props: {
    insight: { type: Object, required: true },
  },
  computed: {
    ...mapGetters('mergeInvestigationQuestions', ['allPapasAnswered', 'answeredPapas']),
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('insights', ['clearInsight']),
    closePopup() {
      Segment.trackUserInteraction('NewPapasMergeQuestionPopupClosed', this.insight);
      this.closeModal();
    },
  },
  watch: {
    allPapasAnswered(newValue) {
      if (newValue) {
        this.clearInsight(this.insight);
        this.closeModal();
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
