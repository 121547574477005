<template>
  <insight :insight="insight"
            :animation-name="'merge-insight.json'"
           button-text="להשלמת חיבור האשראי"
           :background-variant="'red'"
           :loop="false"
           :show-dismiss="false"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        <span>החשבון ב{{bankName}} כבר מחובר בחיבור ישיר, נשאר רק </span>
        <span class="bold">לחבר את {{creditCardNamesText}}</span>
        <span>כדי לקבל תזרים מלא</span>
      </div>
    </template>
  </insight>
</template>

<script>

import Segment from '@/Segment';
import _ from 'lodash';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import { mapActions } from 'vuex';
import MissingBankCCsAfterOBKMigrationPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/MissingBankCCsAfterOBKMigrationPopup';
import Insight from './components/Insight';

export default {
  name: 'MissingBankCCsAfterOBKMigrationInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('MissingBankCCsAfterOBKMigrationInsight_Shown');
  },
  computed: {
    missingBankCCs() {
      return _.map(this.insight.missingBankCCs, bankCC => {
        const sourceConfig = getSourceConfigurationBySourceName(bankCC.source);
        return {
          ...bankCC,
          displayName: sourceConfig.displayName,
        };
      });
    },
    missingBankCCsBySource() {
      return _.groupBy(this.missingBankCCs, bankCC => bankCC.source);
    },
    bankName() {
      return this.missingBankCCs[0].bankName;
    },
    credentialsId() {
      return this.missingBankCCs[0].credentialsId;
    },
    hasMultipleSourcesToConnect() {
      return _.size(this.missingBankCCsBySource) > 1;
    },
    creditCardNamesText() {
      return this.hasMultipleSourcesToConnect ? 'חשבונות האשראי המקושרים אליו' : `חשבון האשראי של ${this.missingBankCCs[0].displayName}`;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    buttonAction() {
      this.openModal({
        component: MissingBankCCsAfterOBKMigrationPopup,
        props: {
          missingBankCCsBySource: this.missingBankCCsBySource,
          credentialsId: this.credentialsId,
          bankName: this.bankName,
          creditCardNamesText: this.creditCardNamesText,
          insight: this.insight,
        },
      });
      Segment.trackUserInteraction('MissingBankCCsAfterOBKMigrationInsight_Clicked');
    },
  },
};

</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
