<template>
  <advisory-step :step="currentStep" @next="next" @back="back" @close="close" />
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import AdvisoryStep from './AdvisoryStep';

export default {
  name: 'AdvisorySteps',
  components: {
    AdvisoryStep,
  },
  props: {
    backwards: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    Segment.trackUserGot('AdvisorySteps');
  },
  data() {
    return {
      index: this.backwards ? 2 : 0,
    };
  },
  computed: {
    steps() {
      return [
        {
          number: 1,
          header: 'שלב 1',
          title: 'כללל הכסף שלך, מול העיניים',
          subTitle: 'המערכת שלנו תבנה תמונה של כל ההון שלך: יש לך קופת חיסכון לבת מצווה בשוויץ? הפרישו לך קופת גמל בעבודת מלצרות מגיל 20? '
              + 'אנחנו נמצא את כל הכספים שיש לך גם מחוץ לבנק ונעשה בהם סדר.',
          image: 'advisory-plan',
          buttonText: 'מתאים לי!',
        },
        {
          number: 2,
          header: 'שלב 2',
          title: 'הצוות של רייזאפ כאן בשבילך!',
          subTitle: 'זה הזמן לאתגר אותנו עם כל החלומות במגירה! אנחנו ניקח את כל המידע ונבנה לך תכנית פרקטית שאפשר'
              + ' ליישם בקלות כדי לקרב אותך צעד צעד למטרות שלך. כל החישובים עלינו.',
          image: 'advisory-dreams',
          preventImagePadding: true,
          buttonText: 'פשיייי',
        },
        {
          number: 3,
          header: 'שלב 3',
          title: 'איתך כל הדרך, עד למטרות שלך',
          subTitle: 'בפגישת זום אישית נציג לך תוכנית ברורה לכל מטרה, נהיה שם לכל שאלה עד שיהיה לך את כל מה שצריך כדי לצאת לדרך!',
          image: 'advisory-meeting',
          buttonText: 'עד כדי כך?!',
        },
      ];
    },
    currentStep() {
      return this.steps[this.index];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    next() {
      if (this.index === 2) {
        this.$emit('next');
      } else {
        this.index++;
        Segment.trackUserGot(`AdvisorySteps_Step${this.currentStep.number}`);
      }
    },
    back() {
      if (this.index === 0) {
        this.$emit('back');
      } else {
        this.index--;
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
