<template>
  <div class="change-category-path">
    <div class="category-text-wrapper">
      <div class="category-text" :class="oldCategory.cashflowCategory">מ{{ oldCategory.pluralLabel }}</div>
      <div class="category-text" :class="newCategory.cashflowCategory">ל{{ newCategory.pluralLabel }}</div>
    </div>
    <div class="category-path">
      <div class="circle" :class="oldCategory.cashflowCategory"></div>
      <div class="gradient-line" :class="[`right-${oldCategory.cashflowCategory}`, `left-${newCategory.cashflowCategory}`]"></div>
      <div class="left-arrow fa fa-caret-left" :class="newCategory.cashflowCategory"></div>
      <div class="circle" :class="newCategory.cashflowCategory"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ChangeCategoryPath',
  props: {
    oldCategory: {
      required: false,
      type: Object,
    },
    newCategory: {
      required: false,
      type: Object,
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/category-color-mixins';

  .change-category-path {
    direction: rtl;

    .category-text-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 4px;

      .category-text {
        font-size: 16px;
        font-family: SimplerPro;
        font-weight: bold;

        @include category-color;
      }
    }

    .category-path {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .gradient-line {
        height: 1px;
        width: 85%;

        &.right-tracking {
          &.left-variable {
            background: linear-gradient(to left, $riseup_blue, $riseup_yellow),
          }

          &.left-tracking {
            background: linear-gradient(to left, $riseup_blue, $riseup_blue),
          }
          &.left-fixed {
            background: linear-gradient(to left, $riseup_blue, $riseup_red),
          }
          &.left-excluded-expense {
            background: linear-gradient(to left, $riseup_blue, $riseup_gray_1),
          }
          &.left-fixed-saving {
            background: linear-gradient(to left, $riseup_blue, $riseup_savings),
          }
        }

        &.right-variable {
          &.left-tracking {
            background: linear-gradient(to left, $riseup_yellow, $riseup_blue),
          }

          &.left-variable {
            background: linear-gradient(to left, $riseup_yellow, $riseup_yellow),
          }

          &.left-fixed {
            background: linear-gradient(to left, $riseup_yellow, $riseup_red),
          }

          &.left-excluded-expense {
            background: linear-gradient(to left, $riseup_yellow, $riseup_gray_1),
          }

          &.left-fixed-saving {
            background: linear-gradient(to left, $riseup_yellow, $riseup_savings),
          }
        }

        &.right-fixed {
          &.left-tracking {
            background: linear-gradient(to left, $riseup_red, $riseup_blue),
          }

          &.left-variable {
            background: linear-gradient(to left, $riseup_red, $riseup_yellow),
          }

          &.left-fixed {
            background: linear-gradient(to left, $riseup_red, $riseup_red),
          }

          &.left-excluded-expense {
            background: linear-gradient(to left, $riseup_red, $riseup_gray_1),
          }

          &.left-fixed-saving {
            background: linear-gradient(to left, $riseup_red, $riseup_savings),
          }
        }

        &.right-variable-income {
          &.left-fixed-income {
            background: linear-gradient(to left, $riseup_green, $riseup_green),
          }

          &.left-excluded-income {
            background: linear-gradient(to left, $riseup_green, $riseup_gray_1),
          }
        }

        &.right-fixed-income {
          &.left-variable-income {
            background: linear-gradient(to left, $riseup_green, $riseup_green),
          }
        }

        &.right-excluded-expense {
          &.left-variable {
            background: linear-gradient(to left, $riseup_gray_1, $riseup_yellow),
          }

          &.left-tracking {
            background: linear-gradient(to left, $riseup_gray_1, $riseup_blue),
          }

          &.left-fixed {
            background: linear-gradient(to left, $riseup_gray_1, $riseup_red),
          }
        }

        &.right-excluded-income {
          &.left-variable-income {
            background: linear-gradient(to left, $riseup_gray_1, $riseup_green),
          }
          &.left-fixed-income {
            background: linear-gradient(to left, $riseup_gray_1, $riseup_green),
          }
        }

        &.right-fixed-saving {
          &.left-fixed {
            background: linear-gradient(to left, $riseup_savings, $riseup_red),
          }

          &.left-one-time-saving {
            background: linear-gradient(to left, $riseup_savings, $riseup_savings),
          }
        }

        &.right-one-time-saving {
          &.left-fixed-saving {
            background: linear-gradient(to left, $riseup_savings, $riseup_savings),
          }

          &.left-variable {
            background: linear-gradient(to left, $riseup_savings, $riseup_yellow),
          }
        }

      }

      .left-arrow {
        color: $riseup_yellow;
        transform: scale(1.5,1);
        margin-left: 1px;

        @include category-color;
      }

      .circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;

        @include category-background-color;
      }
    }
  }

</style>
