<template>
  <span class="animated-decimal-number">
    <animated-number :value="integerPart" :formatter="format"/><span class="decimal">.<animated-number :value="decimalPart" :formatter="format"/>
  </span>
  </span>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import money from '../utils/money';

export default {
  name: 'AnimatedDecimalNumber',
  components: {
    AnimatedNumber: BaseUI.AnimatedNumber,
  },
  props: {
    value: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
    },
  },
  computed: {
    decimalPart() {
      return this.internalValue && money.extractRoundedDecimalPart(this.internalValue, 1);
    },
    integerPart() {
      return this.internalValue && money.extractRoundedIntegerPart(this.internalValue, 1);
    },
  },
  methods: {
    format(value) {
      return money.formatAmountWithFraction(value, 0);
    },
  },
};
</script>

<style>
.decimal {
  font-size: 10px;
}
</style>
