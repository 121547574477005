<template>
  <div class="full-screen-modal">
    <riseup-header v-if="showRiseupLogo" />
    <div v-else class="header">
      <div v-if="closeAction || disabledClose" class="close-wrapper" :class="{ disabled: disabledClose }" v-on:click="closeModal">
        <img :src="require('@/assets/icons/ic-close.svg')" class="close">
      </div>
      <div v-if="backAction" class="back-wrapper" v-on:click="backAction">
        <img class="back-triangle" src="/images/nav/left-arrow.svg"/>
        <span class="back">חזרה</span>
      </div>
    </div>
    <div class="modal-body" :class="{supportOverflow}" ref="modalBody">
      <div v-if="hasContentSlot" class="content">
        <slot name="content"></slot>
      </div>
      <div class="buttons-wrapper" v-if="buttons">
        <riseup-button v-for="(button, key) in buttons" :key="`button-${key}`" class="modal-button"
                       :action="button.action"
                       :title="button.title"
                       :subtitle="button.subtitle"
                       :variant="button.variant"
                       :disabled="button.disabled"
                       :size="button.size"
                       :loading="button.loading"/>
      </div>
    </div>
  </div>
</template>

<script>

import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import BaseUI from '@riseupil/base-ui';
import RiseupHeader from '@/base-components/app-header/RiseupHeader';
import DDLogs from '@/DDLogs';

export default {
  name: 'FullScreenModal',
  props: {
    closeAction: {
      type: Function,
      required: false,
    },
    disabledClose: {
      type: Boolean,
      default: false,
    },
    backAction: {
      type: Function,
      required: false,
    },
    title: {
      type: String,
      default: null,
    },
    buttons: {
      type: Array,
      required: false,
    },
    supportOverflow: {
      type: Boolean,
      default: false,
    },
    showRiseupLogo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RiseupButton: BaseUI.RiseupButton,
    RiseupHeader,
  },
  created() {
    if (this.showRiseupLogo && (this.closeAction || this.backAction)) {
      DDLogs.error('showRiseupLogo can be turned on only when closeAction and backAction are turned off');
    }
  },
  mounted() {
    EventBus.$on('reset-scroll', this.scrollTopCallback);
  },
  beforeDestroy() {
    EventBus.$off('reset-scroll', this.scrollTopCallback);
  },
  methods: {
    async setLoadingIsLong() {
      Segment.trackUserGot('TempCredentialsConnectionLoadingLong', { sourceName: this.source.sourceName });
      this.loadingIsLong = true;
    },
    closeModal() {
      if (!this.disabledClose) {
        this.closeAction();
      }
    },
    scrollTopCallback() {
      this.$refs.modalBody.scrollTop = 0;
    },
  },
  computed: {
    hasContentSlot() {
      return !!this.$slots.content;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/riseup-z-index';
  @import '../scss/mixins';

  .full-screen-modal {
    width: 100%;
    height: 100%;

    .header {
      z-index: $app-header;
      position: -webkit-sticky;
      position: sticky;
      background-color: $riseup_white;
      top: 0;
      height: 54px;
      width: 100%;
      border-bottom: solid 1px $riseup_gray_0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      .close-wrapper {
        color: $riseup_black;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 20px;

        .close {
          height: 25px;
          width: 25px;
        }
        &.disabled {
          cursor: auto;
          .close {
            opacity: 30%;
          }
        }
      }
      .back-wrapper {
        color: $riseup_black;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        margin-right: 20px;

        .back-triangle {
          width: 9.5px;
          height: 15px;
          margin-left: 8px;
        }

        .back {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }

    .modal-body {
      height: calc(100% - 54px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &.supportOverflow {
        overflow-y: auto;
      }

      .content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .success {
          margin: 35px 0 40px;
          width: 160px;
          height: 160px;
        }

        .title {
          font-size: 20px;
          font-weight: bold;
          font-family: SimplerPro;
          margin-bottom: 15px;
        }

        .subtitle {
          font-family: SimplerPro;
          font-size: 16px;
        }
      }

      .buttons-wrapper {
        background-color: $riseup_white;
        @include responsive-padding-top-bottom;
        @include responsive-padding;
        width: 100%;

        .modal-button {
          width: 100%;
        }

        .modal-button:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
</style>
