<template>
  <div v-if="!isTargetFixed" class="move-from-excluded-confirmation-popup">
    <popup :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:title>
        <span class="selected-category" :class="selectedCategory.cashflowCategory">להחזיר את ה{{ incomeOrExpense }} הזו לתזרים של החודש?</span>
      </template>
      <template v-slot:content>
        <div class="ri-body">
          <div v-if="!isPlanAheadTransaction" class="warning">
            זה אומר שה{{ incomeOrExpense }} הזאת<span class="ri-bold-body"> תופיע בחישוב התזרים של החודש!</span>
          </div>
          <div v-else class="warning">זה אומר שה{{ incomeOrExpense }} הזאת הזאת
            <span class="bold"> תופיע בחישוב התזרים של החודש, ולא תשוייך לתוכנית שבחרת!</span>
          </div>
          <div v-if="isIncomeCategory">הכנסה שהיא חלק מחישוב התזרים היא הכנסה שהתכוונת לממן איתה את ההוצאות של החודש.</div>
          <div v-else>הוצאה שהיא חלק מחישוב התזרים היא הוצאה שהתכוונת לממן מההכנסות של אותו החודש.</div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="cancel">
          <div class="delete-text" v-on:click="close">ביטול</div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import cashflowViewConsts from '@/constants/cashflow-view';
import SegmentTracker from '@/mixins/SegmentTracker';
import * as HamsterApi from '@/api/HamsterApi';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';
import ChangeTransactionCategoryPopup from './ChangeTransactionCategoryPopup.vue';
import MoveFromExcludedSummaryPopup from './MoveFromExcludedSummaryPopup.vue';
import MoveToFixedConfirmationPopup from './MoveToFixedConfirmationPopup.vue';

export default {
  name: 'MoveFromExcludedConfirmationPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  mixins: [SegmentTracker],
  created() {
    this.initTracker('MoveFromExcludedConfirmationPopup_');
    this.track('Shown', this.logData);
    if (this.isTargetFixed) {
      this.$emit('close');
      this.openModal({
        component: MoveToFixedConfirmationPopup,
        props: {},
      });
    }
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapGetters('editTransaction', [
      'selectedCategory', 'originalCategory', 'ENRICHMENT_INPUT_TYPES', 'categoryToVariant', 'isIncomeCategory', 'incomeOrExpense',
    ]),
    buttonOptions() {
      return [{
        title: 'כן, להחזיר לתזרים',
        size: 'slim',
        action: () => this.onSave(),
        variant: 'primary',
      }];
    },
    isTargetFixed() {
      return this.selectedCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_INCOME;
    },
    isPlanAheadTransaction() {
      return !!this.transaction?.plan;
    },
    logData() {
      return {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        isIncomeCategory: this.isIncomeCategory,
        transactionId: this.transaction.transactionId,
        isPlanAheadTransaction: this.isPlanAheadTransaction,
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('editCashflow', ['setComment']),
    back() {
      this.track('BackClicked', this.logData);
      this.$emit('close');
      this.openModal({
        component: ChangeTransactionCategoryPopup,
        props: {},
      });
    },
    onSave() {
      this.track('AnswerClicked', this.logData);
      this.$emit('close');
      // We need to clean the comment if it was a savings comment
      const comment = _.replace(this.transaction.customerComment, 'חיסכון 💰', '');
      // And tell hamster this is not a saving transaction
      HamsterApi.unmarkAsSavingTransaction(this.transaction.transactionId)
        .then(cashflowNormalizer.setSavingTransactions);

      this.setComment({ transactionId: this.transaction.transactionId, comment });
      this.openModal({
        component: MoveFromExcludedSummaryPopup,
        props: {},
      });
    },
    close() {
      this.track('CloseClicked', this.logData);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';

  .move-from-excluded-confirmation-popup {
    .warning {
      color: $riseup_danger_red;
      margin-bottom: 16px;
      .bold {
        font-weight: bold;
      }
    }

    .cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .delete-text {
        color: $riseup_black;
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

</style>
