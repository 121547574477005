import { client } from '../http';

async function fetchActions() {
  const response = await client.get('/api/actions');
  return response.data;
}

async function setCheckedState(type, checked) {
  const response = await client.put('/api/actions/checked', { type, checked });
  return response.data;
}

export default {
  fetchActions,
  setCheckedState,
};
