<template>
  <insight :insight="insight"
           :image-name="'first_cf_tips_header.svg'"
           :button-text="'קדימה'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>3 פעולות קטנות שאפשר לעשות כבר עכשיו כדי להיות על זה מהרגע הראשון!</div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import ActivationInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/ActivationInsightPopup';
import Insight from './components/Insight';

export default {
  name: 'ActivationInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('ActivationInsight_Shown');
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      Segment.trackUserInteraction('ActivationInsight_Clicked');
      this.openModal({
        component: ActivationInsightPopup,
        props: {
          insight: this.insight,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
