<template>
  <div></div>
</template>

<script>
import Segment from '@/Segment';
import router from '@/router';

const destinationsMapping = {
  tavplus: 'https://tavplus-business.mltp.co.il/',
};
export default {
  name: 'UrlRedirect',

  created() {
    const { destination } = this.$route.query;
    const url = destinationsMapping[destination];
    if (url) {
      Segment.trackUserGot('ExternalUrlRedirect', { url });
      window.location.href = url;
    } else {
      router.push({ path: '/sr' });
    }
  },
};

</script>

<style lang="scss" scoped>

</style>
