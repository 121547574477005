<template>
  <div class="advisory-step padding-bottom-ml">
    <div class="content">
      <div class="padding-horizontal-ml">
      <advisory-top-header @close="close" @back="back" variant="blue"/>
      <Stepper :current-step="step.number + 2" :total-steps="flowStepsNumber"/>
      <div class="ri-title margin-top-ml margin-bottom-sm">{{ step.header }}</div>
      <div class="ri-small-display margin-bottom-xs">{{ step.title }} </div>
      <div class="ri-large-body">{{ step.subTitle }} </div>
      </div>
      <div class="image-container" :class="{ 'padding-horizontal-ml': !step.preventImagePadding }">
        <img :src="`/images/advisory/${step.image}.svg`"/>
      </div>
    </div>
    <div class="padding-horizontal-ml">
      <riseup-button :action="next"
                     :size="'slim'"
                     :title="step.buttonText || 'להמשיך'"
                     :variant="'primary'"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import AdvisoryTopHeader from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AdvisoryTopHeader';
import AdvisoryMixin from '@/mixins/AdvisoryMixin.vue';

export default {
  name: 'AdvisoryStep',
  components: {
    AdvisoryTopHeader,
    RiseupButton: BaseUI.RiseupButton,
    Stepper: BaseUI.Stepper,
  },
  mixins: [AdvisoryMixin],
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  methods: {
    next() {
      this.$emit('next');
    },
    back() {
      this.$emit('back');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.advisory-step {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #142AA0;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    text-align: start;
    color: $riseup_white;

    img {
      width: 100%;
    }

    .headline {
      line-height: 36px;
      font-size: 40px;
      font-weight: bold;
    }
  }

  .image-container {
    flex: 1;
    margin: 0 auto;
    align-items: center;
    display: flex;
  }
}
</style>
