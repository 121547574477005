import { client } from '../http';

export default {
  async getOTSResult(jobId) {
    const response = await client.get(`/api/ots-results/${jobId}`);
    return response.data;
  },
  async createLLMJob({ flowConfigId, promptId }) {
    const response = await client.post(
      '/api/llm-jobs',
      { flowConfigId, promptId },
    );
    return response.data;
  },
};
