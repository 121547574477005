<template>
  <div class="existing-credit-card padding-bottom-l">
    <div class="card">
      <div class="ri-label">כרטיס לחיוב</div>
      <div class="ri-bold-title margin-vertical-m">{{ cardMask }}</div>
    </div>
    <div class="small-button ri-label" @click="() => $emit('otherCardClick')" >לשלם בכרטיס אחר</div>
  </div>
</template>

<script>

export default {
  name: 'ExistingCreditCardDetails',
  props: {
    cardMask: {
      type: String,
      require: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.existing-credit-card {
  .small-button {
    cursor: pointer;
    text-decoration: underline;
    color: $riseup_blue;
  }
}
</style>
