<template>
  <button class="menu-tile" @click.stop="$emit('click')">
    <icon v-if="iconProps" class="icon" v-bind="iconProps" size="32px" variant="rounded" />
    <img v-else-if="imgSrc" :src="imgSrc" :alt="text"/>
    <span class="ri-large-body margin-top-m">{{ text }}</span>
  </button>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'MenuTile',
  components: {
    Icon: BaseUI.Icon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    iconProps: {
      type: Object,
      required: false,
    },
    imgSrc: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '~@riseupil/base-ui/src/scss/spacings';

$tile-size: 140px;
$icon-size: 32px;

.menu-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: $riseup_gray_05;
  border-radius: $card-border-radius;
  border: none;
  height: $tile-size;
  padding: $ri-spacing-m $ri-spacing-sm;
  cursor: pointer;
  overflow: hidden;
  span {
    text-align: center;
    color: $riseup_black;
    line-height: 22px;
  }

  .icon {
    display: flex;
    align-items: center;
    flex: 1;
  }
  img {
    width: $icon-size;
    flex: 1;
  }
}
</style>
