<template>
  <insight :insight="insight"
           :image-name="'negative_balance.svg'"
           :button-text="'ספרו לי עוד'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        <span class="bold">אמנם העו״ש במינוס אבל התזרים החודשי חיובי.</span>
        <span> זו לא טעות, זה רק אומר שאנחנו בדרך הנכונה!</span>
      </div>
    </template>
  </insight>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import NegativeBalanceInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/NegativeBalanceInsightPopup';
import Segment from '@/Segment';
import { mapActions } from 'vuex';

export default {
  name: 'NegativeBalanceInsight.vue',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('NegativeBalanceInsightShown');
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      this.openModal({
        component: NegativeBalanceInsightPopup,
        props: {
          insight: this.insight,
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.RISEUP_GOAL,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.bold {
  font-weight: bold;
}

</style>
