<template>
  <div class="toaster">
    <div class="content">
      <div class="text">
        <slot name="text"/>
        {{ text }}
      </div>
      <img v-if="!hasUndo" class="close-button" :src="require('@/assets/close-toaster.svg')" @click="closeToaster()">
      <div v-else class="undo-button" @click="undo()">ביטול</div>
    </div>
  </div>
</template>

<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import EventBus from '@/event-bus/event-bus';

export default {
  name: 'Toaster',
  props: {
    hasUndo: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  created() {
    setTimeout(() => this.closeToaster(), cashflowViewConsts.TOASTER_TIMEOUT);
  },
  methods: {
    closeToaster() {
      this.$emit('close-toaster');
      EventBus.$emit('close-toaster');
    },
    undo() {
      this.$emit('undo-action');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/border-radius";
@import '../scss/global-variables';
@import '../scss/mixins';

.toaster {
  height: 46px;
  width: 100%;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 20px;
    background-color: $riseup_dark_blue_2;
    border-radius: $ri-border-radius-small;
    color: white;
    box-shadow: 2px 2px 8px rgba(186, 188, 191, 0.25);
    .close-button {
      cursor: pointer;
    }
    .undo-button {
      cursor: pointer;
      font-weight: bold;
    }
  }
}
</style>
