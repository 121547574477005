<template>
  <div class="decimal-number">{{int}}<span :class="decimalSize">{{decimal}}</span></div>
</template>

<script>
import moneyUtils from '../utils/money';

export default {
  name: 'DecimalNumber',
  props: {
    value: {
      required: true,
      type: Number,
    },
    decimalSize: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    int() {
      const integer = Math.abs(moneyUtils.extractRoundedIntegerPart(this.value, 1));
      return moneyUtils.formatAmount(integer);
    },
    decimal() {
      const number = Math.abs(moneyUtils.extractRoundedDecimalPart(this.value, 1));
      return `.${number}`;
    },
  },
};
</script>

<style scoped>
.decimal-number {
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  white-space: nowrap;
  direction: ltr;
}

.small {
  font-size: 10px;
}

.large {
  font-size: 12px;
}

</style>
