<template>
  <info-tool-tip class="security-info-tooltip"
                 :text="'המידע שלך מאובטח תחת הסטנדרטים הגבוהים ביותר של אבטחת מידע'"
                 icon-path="/images/onboarding/icons/privacy.svg"/>
</template>

<script>
import InfoToolTip from '@/base-components/onboarding/InfoToolTip';

export default {
  name: 'SecurityInfoTooltip',
  components: {
    InfoToolTip,
  },
};
</script>

<style lang="scss" scoped>

.security-info-tooltip {
  text-align: right;
}

</style>
