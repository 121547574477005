<template>
  <colored-popup :class="'light-blue'" :back-action="onBack" :dark-text="true">
    <template v-slot:top-content>
      <div class="centered">
        <lottie-player class="balance-money-dime-rise"
                       src="/animations/balance-money-step-2.json"
                       :autoplay="true"
                       :loop="false"/>
        <div class="title margin-top-medium">אפשר להנות מריבית בנק ישראל שעלתה!</div>
      </div>
    </template>

    <template v-slot:bottom-content>
      <div class="body-text">
        אם הכסף שלך יושקע בריבית דומה לזו של בנק ישראל<span v-if="positiveOsh">{{` (${currentInterest})`}}</span>,
        <span class="bold">יוכל להיות לך רווח של {{ possibleProfit }}<span v-if="positiveOsh"> ₪</span> בשנה הקרובה</span>.
        <div class="margin-top-medium-large margin-bottom-x-large">
          👈 כמה צעדים פשוטים, והכסף יכול לעבוד בשבילך
        </div>
      </div>
      <riseup-button
        title="כמה להשאיר בעו״ש?"
        :action="onCalculatorClicked"
        size="slim"
        :bold="true"
      />
      <riseup-button
        class="margin-top-large"
        title="אני רוצה לראות אפשרויות"
        :action="onNext"
        size="slim"
        variant="secondary"
        :bold="true"
      />
      <div class="disclaimer margin-top-large margin-bottom-medium">
        החישוב המוצג להמחשה בלבד לפי ריבית בנק ישראל כיום. האמור אינו מהווה ייעוץ ו/או תחליף לייעוץ פיננסי המתחשב
        בנתונים ובצרכים של אדם ואין באמור משום הבטחת תשואה או רווח. דמי הניהול נקבעים לפי ערוץ החיסכון הנבחר.
        המידע המוצג ביחס לאפשרויות חיסכון, כפוף לתנאי השימוש/החשבון המלאים המופיעים אצל נותן השירות
      </div>
    </template>
    <template v-slot:footer v-if="!hideSnooze">
      <div class="centered">
        <a class="link-button" @click="remindMeLaterClicked">
          מעניין, תזכירו לי אח"כ
        </a>
      </div>
    </template>
  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';

export default {
  name: 'BalanceMoneyStep2',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    onBack: {
      type: Function,
      required: true,
    },
    onNext: {
      type: Function,
      required: true,
    },
    onDismiss: {
      type: Function,
      required: true,
    },
    onCalculatorClicked: {
      type: Function,
      required: true,
    },
    stepName: {
      type: String,
      required: true,
    },
    hideSnooze: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('BalanceMoneyFlow_StepEntered', { step: this.stepName });
  },
  computed: {
    ...mapGetters('balance', ['totalOshBalance']),
    ...mapGetters('featureFlags', ['israelBankInterest']),
    positiveOsh() {
      return this.totalOshBalance > 0;
    },
    currentInterest() {
      return `${this.israelBankInterest}%`;
    },
    possibleProfit() {
      if (!this.positiveOsh) {
        return this.currentInterest;
      }
      return moneyUtils.formatAmount(Math.round(this.totalOshBalance * (this.israelBankInterest / 100)));
    },
  },
  methods: {
    remindMeLaterClicked() {
      this.onDismiss(true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/balance-money/balance-money-styles";

</style>
