import SumsumApi from '../../api/SumsumApi';

const EATING_OUT_VS_SUPERMARKET_LOCAL_STOTAGE_KEY = '2022-08-eating-out-vs-supermarket';
const FOOD_RATIO_LOCAL_STORAGE_KEY = '2022-08-food-ratio';

const state = {
  benchmark: {},
  eatingOutVsSupermarketPollAnswer: null,
  foodRatioPollAnswer: null,
};

const getters = {
  cashflows: state => state.benchmark.cashflows,
};

const actions = {
  async fetchBenchmark({ commit }) {
    const benchmark = await SumsumApi.getSupermarketBenchmark();
    commit('setEatingOutVsSupermarketPollAnswer', localStorage.getItem(EATING_OUT_VS_SUPERMARKET_LOCAL_STOTAGE_KEY));
    commit('setFoodRatioPollAnswer', localStorage.getItem(FOOD_RATIO_LOCAL_STORAGE_KEY));
    commit('setBenchmark', { benchmark });
  },
  setEatingOutVsSupermarketPollAnswer({ commit }, answer) {
    localStorage.setItem(EATING_OUT_VS_SUPERMARKET_LOCAL_STOTAGE_KEY, answer);
    commit('setEatingOutVsSupermarketPollAnswer', answer);
  },
  setFoodRatioPollAnswer({ commit }, answer) {
    localStorage.setItem(FOOD_RATIO_LOCAL_STORAGE_KEY, answer);
    commit('setFoodRatioPollAnswer', answer);
  },
};

const mutations = {
  setBenchmark(state, { benchmark }) {
    state.benchmark = benchmark;
  },
  setEatingOutVsSupermarketPollAnswer(state, answer) {
    state.eatingOutVsSupermarketPollAnswer = answer;
  },
  setFoodRatioPollAnswer(state, answer) {
    state.foodRatioPollAnswer = answer;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
