<template>
  <loader v-if="loading"/>
  <div v-else class="create-credentials-flow">
    <source-type-selection-modal v-if="componentName === componentNames.sourceTypeSelectionModal"
                                 :close-action="closeAction"
                                 @source-type-selected="handleSourceTypeSelected" />
    <source-selection-modal v-if="componentName === componentNames.sourceSelectionModal"
                            :source-type="usedSourceType" :close-action="closeAction"
                            :set-selected-source="setSelectedSource"/>
    <connection-flow-modal v-else-if="componentName === componentNames.connectionFlowModal"
                           v-bind="connectionFlowModalProps"/>
    <account-investigation-modal v-else-if="componentName === componentNames.accountInvestigationModal"
                                 :next="goToNextComponent"/>
    <credentials-connection-success v-else-if="componentName === componentNames.credentialsConnectionSuccess"
                                    :source-name="sourceToConnect" :source-type="usedSourceType"
                                    :close-action="closeAction"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import SourceTypeSelectionModal
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/SourceTypeSelectionModal';
import _ from 'lodash';
import Segment from '@/Segment';
import featureFlag from '@/api/featureFlag';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import AsyncFallbackPopup from '@/pages/responsive-pages/authenticated/onboard2/AsyncFallbakPopup';
import MetricsApi from '@/api/MetricsApi';
import Loader from '@/base-components/Loader';
import SourceSelectionModal from './SourceSelectionModal';
import ConnectionFlowModal from './ConnectionFlowModal';
import CredentialsConnectionSuccess from './CredentialsConnectionSuccess';
import AccountInvestigationModal from './AccountInvestigationModal';

const componentNames = {
  sourceTypeSelectionModal: 'source-type-selection-modal',
  sourceSelectionModal: 'source-selection-modal',
  connectionFlowModal: 'connection-flow-modal',
  accountInvestigationModal: 'account-investigation-modal',
  credentialsConnectionSuccess: 'credentials-connection-success',
};

const CurrentStep = {
  Credentials: 'Credentials',
  AccountInvestigation: 'AccountInvestigation',
};

export default {
  name: 'CreateCredentialsFlow',
  data() {
    return {
      componentName: null,
      loading: false,
      sourceToConnect: null,
      accountInvestigationFinished: false,
      selectedSourceType: null,
    };
  },
  components: {
    SourceTypeSelectionModal,
    AccountInvestigationModal,
    SourceSelectionModal,
    ConnectionFlowModal,
    CredentialsConnectionSuccess,
    Loader,
  },
  props: {
    sourceType: {
      type: String,
      required: false,
    },
    allowBackToSourceSelection: {
      type: Boolean,
      default: true,
    },
    preventOBkConnection: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.skipCCInOB = (await featureFlag.getTreatment('skip_onboarding_credit_cards')) === 'on';
    this.componentName = this.sourceType ? this.componentNames.sourceSelectionModal : this.componentNames.sourceTypeSelectionModal;
    Segment.trackUserGot('CreateCredentialsFlowEntered');
    Segment.trackUserGot('CreateCredentialsFlowEntered_Marketing');
    if (this.sourceType === 'bank' && this.pageParams && this.pageParams.currentStep === CurrentStep.AccountInvestigation) {
      this.componentName = componentNames.accountInvestigationModal;
      this.sourceToConnect = this.sourceNameFromAccountQuestions;
    } else if (this.sourceName) {
      this.componentName = componentNames.connectionFlowModal;
      this.sourceToConnect = this.displayName;
    }
  },
  computed: {
    ...mapState('accountInvestigationOnboarding', ['accountQuestions']),
    ...mapState('onboardingState', ['saveCredentialsError', 'isLoading', 'pageParams', 'connectionSuccess',
      'nameAlreadyUsedError', 'pageName', 'asyncTimeoutReached', 'loadingStartTime']),
    ...mapGetters('onboardingState', ['invalidCredentials', 'sourceName', 'obkConnectionError', 'displayName']),
    usedSourceType() {
      return this.sourceType || this.selectedSourceType;
    },
    componentNames() {
      return componentNames;
    },
    sourceNameFromAccountQuestions() {
      if (this.accountQuestions.length > 0) {
        return getSourceConfigurationBySourceName(this.accountQuestions[0].source).sourceName;
      }
      return null;
    },
    componentOrderBank() {
      return [
        componentNames.sourceSelectionModal,
        componentNames.connectionFlowModal,
        componentNames.accountInvestigationModal,
        componentNames.credentialsConnectionSuccess,
      ];
    },
    componentOrderCreditCard() {
      return [
        componentNames.sourceSelectionModal,
        componentNames.connectionFlowModal,
        componentNames.credentialsConnectionSuccess,
      ];
    },
    source() {
      return getSourceConfigurationBySourceName(this.sourceName);
    },
    connectionFlowModalProps() {
      const propsToPass = {
        sourceType: this.usedSourceType,
        closeAction: this.closeAction,
        invalidCredentials: this.invalidCredentials,
        nameAlreadyUsedError: this.nameAlreadyUsedError,
        setNameAlreadyInUseError: this.setNameAlreadyInUseError,
        validateName: this.validateName,
        saveCredentialsError: this.saveCredentialsError || this.obkConnectionError,
        next: this.goToNextComponent,
        sourceName: this.sourceName,
        isLoading: this.isLoading,
        asyncTimeoutReached: this.asyncTimeoutReached,
        onConnectionSuccess: this.handleConnectionSuccess,
        onSave: this.saveCredentials,
        onTimeout: this.openAsyncFallbackPopup,
        loadingStartTime: this.loadingStartTime,
        preventOBkConnection: this.preventOBkConnection,
      };
      if (this.allowBackToSourceSelection) {
        propsToPass.backAction = this.backToSourceSelection;
      }
      return propsToPass;
    },
  },
  methods: {
    ...mapActions('onboardingState', ['validateName', 'bankDetailsSubmit', 'creditCardDetailsSubmit', 'bankDetailsSubmitDirect']),
    ...mapMutations('onboardingState', ['setPageParams', 'setConnectionSuccess', 'setNameAlreadyInUseError']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    async openAsyncFallbackPopup() {
      this.openModal({
        component: AsyncFallbackPopup,
        popupAlignment: 'center',
      });
      MetricsApi.sendCounter('onboarding-async-fallback');
    },
    async saveCredentials({
      sourceName,
      credentials,
      name,
      sourceType,
      provider,
    }) {
      if (this.pageName === 'bankDetails') {
        return this.bankDetailsSubmit({
          sourceName,
          credentials,
          name,
          sourceType,
          provider,
        });
      }
      return this.creditCardDetailsSubmit({
        sourceName,
        credentials,
        name,
        sourceType,
        provider,
      });
    },
    async closeAction() {
      this.loading = true;
      this.sourceToConnect = null;
      this.componentName = null;
      this.setConnectionSuccess(false);
      this.setPageParams({ sourceName: null });
      this.$emit('close');
      this.loading = false;
    },
    goToNextComponent() {
      const sourceType = this.usedSourceType;
      const componentOrder = sourceType === 'bank' ? this.componentOrderBank : this.componentOrderCreditCard;
      const currentComponentIndex = _.findIndex(componentOrder, component => component === this.componentName);
      this.componentName = componentOrder[currentComponentIndex + 1];
    },
    async backToSourceSelection() {
      this.componentName = this.componentNames.sourceSelectionModal;
      this.sourceToConnect = null;
    },
    async setSelectedSource(source) {
      this.sourceToConnect = source.sourceName;
      this.setPageParams({ sourceName: source.sourceName });
      this.goToNextComponent();
    },
    handleSourceTypeSelected(sourceType) {
      this.selectedSourceType = sourceType;
      this.goToNextComponent();
    },
    handleConnectionSuccess() {
      this.setConnectionSuccess(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.create-credentials-flow {
  height: 100%;
}

</style>
