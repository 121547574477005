import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';

export const colorVariants = {
  BLUE: 'blue',
  PINK: 'pink',
  GREEN: 'green',
};

export const profileShapeColors = {
  blue: Colors.riseupDarkBlue,
  pink: Colors.riseupDarkRed,
  green: Colors.riseupDarkGreen,
};

export const colorVariantOptions = Object.keys(colorVariants);

export const shapeVariants = {
  FLOWER: 'flower',
  EIGHT_POINTS_STAR: 'eightPointsStar',
  EIGHT_LEAVES_FLOWER: 'eightLeavesFlower',
};

export const profileShapeShapes = {
  flower: '/images/shapes/flower.svg',
  eightPointsStar: '/images/shapes/rounded-eight-points-star.svg',
  eightLeavesFlower: '/images/shapes/eight-leaves-flower.svg',
};

export const shapeVariantsOptions = Object.keys(shapeVariants);
