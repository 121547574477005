import _ from 'lodash';
import Intercom from '@/Intercom';

const state = {
  modals: [],
};

const getters = {
  modalsToShow(state) {
    const firstIndexToShow = _.findLastIndex(state.modals, modal => !modal.showLastModal);
    return _.map(state.modals, (modal, index) => _.extend({ show: index >= firstIndexToShow }, modal));
  },
  showingModal(state) {
    return state.modals.length > 0;
  },
};

const actions = {
  openModal({ commit }, { component, props = null, popupAlignment = null, eventHandlers = null, showLastModal = false }) {
    if (popupAlignment === 'menu') {
      const menuAlreadyOpen = _.find(state.modals, modal => modal.popupAlignment === 'menu');
      if (menuAlreadyOpen) {
        return;
      }
    }
    document.body.classList.add('modal-open');
    Intercom.hideLauncher();
    Intercom.hide();
    commit('addModal', { component, props, popupAlignment, eventHandlers, showLastModal });
  },
  closeModal({ state, commit }) {
    if (state.modals.length === 1) {
      document.body.classList.remove('modal-open');
      Intercom.showLauncher();
    }
    commit('popModal');
  },
  closeAllModals({ state, commit }) {
    document.body.classList.remove('modal-open');
    Intercom.showLauncher();
    commit('clearAllModals');
  },
  changeCurrentModalProps({ commit }, { props = null, popupAlignment = null }) {
    commit('changeProps', { props, popupAlignment });
  },
};

// mutations
const mutations = {
  addModal(state, { component, props, popupAlignment, eventHandlers, showLastModal }) {
    state.modals.push({ component, props, popupAlignment, eventHandlers, showLastModal });
  },
  popModal(state) {
    state.modals.pop();
  },
  changeProps(state, { props, popupAlignment }) {
    _.last(state.modals).props = props;
    _.last(state.modals).popupAlignment = popupAlignment;
  },
  clearAllModals(state) {
    state.modals = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
