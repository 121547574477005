<script>
import _ from 'lodash';
import moment from 'moment';
import moneyUtils from '../../../../../../../utils/money';

export default {
  name: 'TransactionDisplayUtils',
  methods: {
    getTransactionAmount(transaction) {
      const amount = transaction.incomeAmount > 0 ? transaction.incomeAmount : transaction.billingAmount;
      if (amount >= 1000) {
        return moneyUtils.formatAmountWithFraction(amount, 0);
      }
      return moneyUtils.formatAmountWithFraction(amount);
    },
    getTransactionAmountWithoutFraction(transaction) {
      return Number(transaction.incomeAmount > 0 ? transaction.incomeAmount : transaction.billingAmount);
    },
    formatAmount(amount) {
      return !_.isNil(amount) ? `${amount} ₪` : amount;
    },
    formatAmountWithFractionAndCurrency(number, fractionDigits = 0) {
      return this.formatAmount(moneyUtils.formatAmountWithFraction(number, fractionDigits));
    },
    formatDate(date) {
      return moment(date).format('D.M.YY');
    },
    getTransactionAccount(transaction) {
      const transactionFrom = transaction.sourceType === 'checkingAccount' ? 'חשבון' : 'כרטיס';
      return `${transactionFrom} ${transaction.accountNumberPiiValue}`;
    },
    getTransactionPayment(transaction) {
      return transaction.paymentNumber > 0 ? `- תשלום ${transaction.paymentNumber}/${transaction.totalNumberOfPayments}` : '';
    },
    getPaymentAppDetails(transaction) {
      const transferFromOrToText = transaction.isIncome ? 'העברה מ' : 'העברה ל';
      const paymentAppComment = transaction.paymentAppComment || '';
      const addresseeText = transaction.paymentAppAddressee && `${transferFromOrToText}${transaction.paymentAppAddressee}`;
      const commentText = paymentAppComment
        && (addresseeText ? ` '${paymentAppComment}'` : paymentAppComment);
      return (addresseeText || commentText) && `${addresseeText}${commentText}`.trim();
    },
  },
};
</script>
