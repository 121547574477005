import _ from 'lodash';

function findLow(array, value) {
  const index = _.findIndex(array, v => v === value);
  if (index >= 0) {
    return index;
  }
  return _findLowForValueNotInArray(array, value);
}

function _findLowForValueNotInArray(array, value) {
  const index = _.findLastIndex(array, v => v < value);
  if (index < 0) {
    return 0;
  }
  return index;
}

export default {
  findLow,
};
