<template>
  <popup class="failed-payment-popup">
    <template v-slot:content>
      <div class="failed-payment-popup-content">
        <div class="image-wrapper">
          <img :src="require('@/assets/generic-faliure.png')" class="generic-failure-asset-in-popup">
        </div>
        <div class="ri-medium-bold-headline margin-top-m">התשלום לא עבר</div>
        <div class="ri-body margin-bottom-l">אפשר לנסות שוב עם כרטיס אחר</div>
        <riseup-button
          :action="onTryWithAnotherCard"
          :title="'לשלם בכרטיס אחר'"
          :size="'slim'"
          class="margin-bottom-s"/>
        <riseup-button
          :action="onClose"
          :variant="'secondary'"
          :title="'ביטול'"
          :size="'slim'"
          class="reconnect-button"/>
      </div>
    </template>
  </popup>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'FailedPaymentPopup',
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    onClose: {
      type: Function,
      require: true,
    },
    onTryWithAnotherCard: {
      type: Function,
      require: true,
    },
  },
  async created() {
    Segment.trackUserGot('FailedPaymentPopupShown');
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.failed-payment-popup {
  border-radius: 16px;
  .failed-payment-popup-content {
    text-align: center;
    .image-wrapper {
      width: 100%;
    }
  }
}
</style>
