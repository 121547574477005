<template>
  <div class="credentials-with-async-fallback">
    <slot name="credentials"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import LoadingPopupWithAsyncFallback
  from '@/pages/responsive-pages/authenticated/onboard2/LoadingPopupWithAsyncFallback';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';

export default {
  name: 'CredentialsWithAsyncFallback',
  props: {
    handleBack: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    asyncTimeoutReached: {
      type: Boolean,
      required: true,
    },
    onTimeout: {
      type: Function,
      required: true,
    },
    onSuccess: {
      type: Function,
    },
    sourceName: {
      type: String,
    },
    loadingStartTime: {
      type: Date,
    },
    saveCredentialsError: {
      type: Boolean,
    },
  },
  async created() {
    if (this.isLoading && !this.asyncTimeoutReached) {
      this.openLoadingPopup();
    } else if (this.isLoading && this.asyncTimeoutReached) {
      await this.onTimeout();
    }
    Segment.trackUserGot('CreateCredentialsEntered', {
      sourceType: this.source && this.source.type,
      sourceName: this.source && this.source.sourceName,
      isObkSourceDisabled: this.isObkSourceDisabled,
    });
  },
  computed: {
    ...mapGetters('credentials', ['getDisabledOBKSources']),
    isObkSourceDisabled() {
      return _.includes(this.getDisabledOBKSources, this.source.id);
    },
    source() {
      return getSourceConfigurationBySourceName(this.sourceName);
    },
  },
  methods: {
    ...mapActions('credentials', ['fetchCredentials']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    openLoadingPopup() {
      this.openModal({
        component: LoadingPopupWithAsyncFallback,
        popupAlignment: 'center',
        props: { source: this.source, loadingStartTime: this.loadingStartTime },
        showLastModal: true,
      });
    },
  },
  watch: {
    isLoading(newValue, oldValue) {
      DDLogs.log('isLoading', { oldValue, newValue });
      if (newValue) {
        this.openLoadingPopup();
      } else if (oldValue && !newValue) {
        this.closeModal();
        this.fetchCredentials();
        if (this.onSuccess) {
          this.onSuccess();
        }
      }
    },
    asyncTimeoutReached(newValue) {
      if (newValue) {
        this.closeModal();
        this.onTimeout();
      }
    },
    saveCredentialsError(newValue) {
      if (newValue) {
        this.closeModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
