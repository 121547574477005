<template>
  <connection-failure-base class="consent-failure" :close-action="closeAction" :buttons="buttons">
    <template v-slot:title>
        <div>החיבור לא הצליח,</div>
        <div>אפשר לנסות שוב!</div>
    </template>
    <template v-if="jitConsentFailure" v-slot:subtitle>
      צוות התמיכה שלנו כאן בצ׳אט יישמח לעזור לך להשלים את חיבור החשבון
    </template>
  </connection-failure-base>
</template>

<script>
import Intercom from '@/Intercom';
import Segment from '@/Segment';
import { setObkRetry } from '@/base-components/credentials/credentials-retry-utils';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow.vue';
import CreateCredentialsFlow from '@/pages/responsive-pages/authenticated/onboard2/steps/create-credentials-flow/CreateCredentialsFlow.vue';
import { mapActions, mapGetters } from 'vuex';
import ConnectionFailureBase from './ConnectionFailureBase';

export default {
  name: 'ConsentFailure',
  components: {
    ConnectionFailureBase,
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('OBKConsentApplyFailureShown', { source: this.sourceName, isOnboarding: !this.onboardingCompleted });
    Intercom.hideLauncher();
  },
  computed: {
    ...mapGetters('onboardingState', ['onboardingCompleted']),
    ...mapGetters('featureFlags', ['jitConsentFailure']),
    buttons() {
      const buttons = [{
        action: this.tryAgain,
        title: 'לנסות שוב',
        size: 'slim',
      }];
      if (this.jitConsentFailure) {
        buttons.push({
          id: 'obk-connection-help-button',
          action: this.getHelp,
          // eslint-disable-next-line global-require
          icon: require('@/assets/icons/chat_icon_teriatry.svg'),
          title: 'לצ׳אט עם הצוות',
          size: 'slim',
          variant: 'secondary',
        });
      }
      return buttons;
    },
    sourceName() {
      return this.source.displayName;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    tryAgain() {
      setObkRetry(this.sourceName);
      Segment.trackUserGot('OBKConsentApplyFailure_TryAgainClicked', { source: this.sourceName, isOnboarding: !this.onboardingCompleted });
      this.openModal({
        component: this.onboardingCompleted ? AddCredentialsFlow : CreateCredentialsFlow,
        props: {
          sourceNameToAdd: this.sourceName,
        },
        popupAlignment: 'full-screen',
      });
    },
    getHelp() {
      Segment.trackUserGot('OBKConsentApplyFailure_CXButtonClicked', { source: this.sourceName, isOnboarding: !this.onboardingCompleted });
      Intercom.show();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../scss/mixins';

  .consent-failure {
    .title {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      font-family: SimplerPro;
      margin-bottom: 24px;
    }
    .subtitle {
      font-size: 18px;
      line-height: 25px;
    }
  }

</style>
