<template>
  <div class="move-from-fixed-confirmation-popup">
    <popup :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:title>
        <span>
          המערכת לא תצפה {{ incomeOrExpense }} כ<span class="title" :class="originalCategory.cashflowCategory">"קבועה"</span> בעתיד מ-
        </span>
      </template>
      <template v-slot:content>
        {{ transaction.businessName }}
      </template>
      <template v-slot:footer>
        <div class="cancel">
          <div class="delete-text" v-on:click="close">ביטול</div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '../../../../../../../../../../Segment';
import ChangeTransactionCategoryPopup from './ChangeTransactionCategoryPopup.vue';
import MoveToExcludedSummaryPopup from './MoveToExcludedSummaryPopup.vue';
import MoveFromFixedSummaryPopup from './MoveFromFixedSummaryPopup.vue';

export default {
  name: 'MoveFromFixedConfirmationPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserInteraction('MoveFromFixedConfirmationPopup_Shown', { businessName: this.transaction.businessName });
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapGetters('editTransaction', ['selectedCategory', 'originalCategory', 'categoryToVariant', 'incomeOrExpense', 'ENRICHMENT_INPUT_TYPES']),
    buttonOptions() {
      return [{
        title: 'הבנתי, אפשר להמשיך',
        size: 'slim',
        action: this.onSave,
        variant: 'primary',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapMutations('editTransaction', ['setCategoryRuleType']),
    back() {
      Segment.trackUserInteraction('MoveFromFixedConfirmationPopup_BackClicked', {
        businessName: this.transaction.businessName,
      });
      this.$emit('close');
      this.openModal({
        component: ChangeTransactionCategoryPopup,
        props: {},
      });
    },
    close() {
      Segment.trackUserInteraction('MoveFromFixedConfirmationPopup_CloseClicked', {
        businessName: this.transaction.businessName,
      });
      this.$emit('close');
    },
    onSave() {
      Segment.trackUserInteraction('MoveFromFixedConfirmationPopup_AnswerClicked', {
        businessName: this.transaction.businessName,
        transactionExpense: this.transaction.expense,
        incomeOrExpense: this.incomeOrExpense,
      });

      const nextComponent = this.getNextComponent();

      this.setCategoryRuleType(this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID);
      this.$emit('close');
      this.openModal({
        component: nextComponent,
        props: {},
      });
    },
    getNextComponent() {
      if (this.selectedCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE) {
        return MoveToExcludedSummaryPopup;
      } if (this.selectedCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE) {
        return this.selectedCategory.finalPopup;
      }
      if (this.selectedCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE_INCOME) {
        return MoveFromFixedSummaryPopup;
      }
      return this.selectedCategory.targetPopup;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';
  .title {
    @include category-color;
  }

  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .delete-text {
      color: $riseup_black;
      font-size: 18px;
      font-weight: normal;
    }
  }
</style>
