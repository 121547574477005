<template>
  <div class="transaction-check-list">
    <div v-for="(transaction, index) in transactionList" :key="index" class="transaction-row">
      <transaction-check-list-item :transaction="transaction" :toggleCheck="toggleCheck" :showDate="true"/>
      <separator v-if="index < transactionList.length - 1"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import TransactionCheckListItem from './TransactionCheckListItem.vue';

export default {
  name: 'TransactionCheckList',
  components: {
    Separator: BaseUI.Separator,
    TransactionCheckListItem,
  },
  props: {
    transactionList: {
      type: Array,
      required: true,
    },
    toggleCheck: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.transaction-check-list {
}

</style>
