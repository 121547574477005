<template>
  <div class="footer">
    <div class="footer-options">
      <span class="copyright"><span class="symbol">©</span> riseup</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyrightFooter',
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  .footer {
    color: $riseup_yellow;
    font-size: 16px;
    line-height: 14px;
    padding-top: 3px;

    .footer-options {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .copyright {
        direction: ltr;
        .symbol {
          font-size: 13px;
        }
      }
    }

    a {
      color: $riseup_yellow;
    }

    a:visited {
      color: $riseup_yellow;
    }
  }

</style>
