<template>
  <div>
    <img class="full-width" :src="image">
    <div class="payment-using-existing-card">
      <div v-if="showAdvisoryNightOffering" class="padding-horizontal-ml margin-vertical-ml">
        <advisory-night-offering-callout  />
      </div>
      <card class="payment-using-existing-card-card margin-vertical-m margin-horizontal-ml" :has-footer-padding="false">
        <template v-slot:default>
          <div class="update-credit-card-wrapper margin-vertical-l">
            <div class="ri-medium-bold-headline">פרטי התשלום</div>
            <credit-card-details class="update-credit-card" :onClicked="onEnterCreditCard" :enable-other-card="true" />
          </div>
          <separator />
          <payment-details class="margin-vertical-l" :price="selectedOffering.price.amount" :payment-date="currentDateFormatted" />
          <separator />
          <payment-installments :price="selectedOffering.price.amount" :payment-date="currentDate" />
        </template>
      </card>
      <payment-terms v-if="configs.showTerms" />
      <div class="flex-column padding-horizontal-ml" v-if="configs.showCallout">
        <advisory-next-step-callout />
      </div>
    </div>
  </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import Segment from '@/Segment';
import moment from 'moment';
import CreditCardDetails from '@/pages/responsive-pages/authenticated/navigation-pages/payment/CreditCardDetails';
import PaymentTerms from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentTerms';
import AdvisoryNextStepCallout
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/AdvisoryNextStepCallout';
import { mapGetters, mapState } from 'vuex';
import AdvisoryNightOfferingCallout
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/AdvisoryNightOfferingCallout';
import PaymentInstallments from '@/pages/responsive-pages/authenticated/subscription/PaymentInstallments.vue';
import PaymentDetails from '@/pages/responsive-pages/authenticated/subscription/PaymentDetails.vue';

export default {
  name: 'PaymentUsingExistingCard',
  components: {
    PaymentDetails,
    PaymentInstallments,
    AdvisoryNightOfferingCallout,
    AdvisoryNextStepCallout,
    PaymentTerms,
    CreditCardDetails,
    Card: BaseUi.Card,
    Separator: BaseUi.Separator,
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      state: null,
    };
  },
  props: {
    onEnterCreditCard: {
      type: Function,
      require: true,
    },
    image: {
      type: String,
      require: true,
    },
  },
  async created() {
    Segment.trackUserGot('PaymentUsingExistingCardEntered');
  },
  computed: {
    ...mapGetters('oneOffOfferings', ['configs', 'showAdvisoryNightOffering']),
    ...mapState('oneOffOfferings', ['selectedOffering']),
    currentDate() {
      return moment();
    },
    currentDateFormatted() {
      return this.currentDate.format('D.M.YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
  .payment-using-existing-card {
    .update-credit-card-wrapper {
      text-align: start;
    }
    .payment-using-existing-card-card {
      width: inherit;
    }
    .update-credit-card {
      padding-bottom: 0;
    }
  }
</style>
