/* eslint-disable max-len */
const Wrong2faAccountErrorScreen = () => import('@/pages/responsive-pages/single-factor-authenticated/2fa-login/Wrong2faAccountErrorScreen');
const Complete2faLogin = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/Complete2faLoginScreen.vue');
const Done2FAScreen = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/2faLoginDone.vue');
const TwoFactorMigration = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/2faMigration.vue');
const LoginWithGoogleOnFacebook = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/LoginWithGoogleInFacebookBrowser.vue');
const ResetPassword = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/2faResetPassword.vue');
const ResetPasswordSent = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/2faResetPasswordSent.vue');
const ResetPasswordDone = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/2faResetPasswordDone.vue');
const ResetPasswordLinkExpired = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/2faResetPasswordExpiredLink.vue');
const ResetPasswordError = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/2faResetPasswordError.vue');
const Signup2faDoneScreen = () => import('../pages/responsive-pages/single-factor-authenticated/2fa-login/Signup2faDoneScreen.vue');

// eslint-disable-next-line import/prefer-default-export
export const partialAuthRoutes = [
  {
    path: '/facebook-dead-end',
    name: 'LoginWithGoogleInFacebookBrowser',
    component: LoginWithGoogleOnFacebook,
  },
  {
    path: '/add-2fa-done',
    name: '2faLoginDone',
    component: Done2FAScreen,
  },
  {
    path: '/wrong-2fa-account-error',
    name: 'wrong2faAccountErrorScreen',
    component: Wrong2faAccountErrorScreen,
  },
  {
    path: '/complete-2fa-login',
    name: '2faLoginComplete',
    component: Complete2faLogin,
  },
  {
    path: '/2fa-migration',
    name: '2faMigration',
    component: TwoFactorMigration,
  },
  {
    path: '/signup-2fa-done',
    name: '2faSignupDone',
    component: Signup2faDoneScreen,
  },
  {
    path: '/2fa-Reset-Password',
    name: 'TwofaResetPassword',
    component: ResetPassword,
  },
  {
    path: '/2fa-Reset-Password-Sent',
    name: 'TwofaResetPasswordSent',
    component: ResetPasswordSent,
  },
  {
    path: '/2fa-Reset-Password-Done',
    name: 'TwofaResetPasswordDone',
    component: ResetPasswordDone,
  },

  {
    path: '/2fa-Link-Expired',
    name: 'TwofaResetPasswordExpiredLink',
    component: ResetPasswordLinkExpired,
  },
  {
    path: '/2fa-Reset-Password-Error',
    name: 'TwofaResetPasswordError',
    component: ResetPasswordError,
  },
];
