<template>
  <loader v-if="autoSkip" />
  <full-screen-modal v-else :disabled-close="true" :buttons="buttons">
    <template v-slot:content>
      <div class="account-investigation">
        <div class="description">
          <div class="title">אילו חשבונות בנק לחבר ולכלול בתזרים החודשי שלך?</div>
          <info-tool-tip
            class="tool-tip-wrapper"
            :text="'אילו חשבונות בנק לכלול בתזרים החודשי ואילו עדיף שלא'"
            :popup-text="'אפשר לקרוא כאן.'"
            :event-name="'AccountSelectionTooltipClicked'"
            :popup-component="accountInvestigationHelpPopup"/>
        </div>
        <separator/>
        <account-check-list :account-list="accountQuestions" :toggle-check="toggleAccountIsIncluded"/>
      </div>
    </template>
  </full-screen-modal>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex';

import Loader from '@/base-components/Loader';
import FullScreenModal from '@/base-components/FullScreenModal';
import InfoToolTip from '@/base-components/onboarding/InfoToolTip';
import AccountInvestigationHelpPopup from '@/base-components/onboarding/help-popups/AccountInvestigationHelpPopup';
import AccountCheckList from '@/base-components/onboarding/AccountCheckList';
import Segment from '@/Segment';

export default {
  name: 'AccountInvestigationModal',
  components: {
    AccountCheckList,
    FullScreenModal,
    InfoToolTip,
    Separator: BaseUI.Separator,
    Loader,
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isClicked: false,
      autoSkip: false,
    };
  },
  async created() {
    await this.fetchAccounts();
    Segment.trackUserGot('AccountInvestigationEntered', { numberOfAccounts: this.accountQuestions.length });
    Segment.trackUserGot('AccountInvestigationEntered_Marketing');
    if (this.accountQuestions.length === 0) {
      this.autoSkip = true;
      this.next();
    }
    if (this.accountQuestions.length === 1) {
      this.autoSkip = true;
      Segment.trackUserInteraction('AccountSelectionAutoAdvance');
      await this.doneAnswering();
    }
  },
  computed: {
    ...mapState('accountInvestigationOnboarding', ['accountQuestions']),
    ...mapState('onboardingState', ['isLoading']),
    ...mapGetters('accountInvestigationOnboarding', ['noIncludedAccounts', 'accountInputs']),
    accountInvestigationHelpPopup() {
      return AccountInvestigationHelpPopup;
    },
    buttons() {
      return [
        {
          loading: this.isLoading,
          title: 'אפשר להמשיך',
          disabled: this.isClicked || (!this.isLoading && this.noIncludedAccounts),
          action: this.doneAnswering,
          size: 'small',
        },
      ];
    },
  },
  methods: {
    ...mapMutations('accountInvestigationOnboarding', ['toggleAccountIsIncluded']),
    ...mapActions('accountInvestigationOnboarding', ['fetchAccounts']),
    ...mapActions('onboardingState', ['bankDetailsAccountInvestigationSubmit']),
    async doneAnswering() {
      this.isClicked = true;
      const totalNumberOfAccounts = this.accountQuestions.length;
      const selectedNumberOfAccounts = this.accountQuestions.filter(accountQuestion => accountQuestion.isIncluded).length;
      Segment.trackUserInteraction('AccountSelectionNextClicked', { totalNumberOfAccounts, selectedNumberOfAccounts });
      const onboardingState = await this.bankDetailsAccountInvestigationSubmit(this.accountInputs);
      if (!this.loadingRequiered(onboardingState)) {
        this.next();
      }
    },
    loadingRequiered(onboardingState) {
      return onboardingState.pageParams?.loading;
    },
  },
  watch: {
    async isLoading(newValue, oldValue) {
      if (oldValue && !newValue) {
        this.next();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.account-investigation {
  background-color: $riseup_white;
  .description {
    @include responsive-padding;
    margin-top: 40px;
    color: $riseup_black;

    .title {
      text-align: right;
      font-size: 22px;
      font-weight: bold;
      line-height: 1.27;
    }

    .tool-tip-wrapper {
      text-align: right;
      margin: 20px 0 40px;
    }
  }
  .button-container {
    @include responsive-padding-top-bottom;
    @include responsive-padding;

    .done-btn {
      width: 100%;
      white-space: nowrap;
    }
  }
}
</style>
