<template>
  <div class="inline-block">
  <insight :insight="insight"
           class="inline-block"
           :animation-name="!insight.snoozed ? 'MonthlySaving.json' : 'BalanceMoney.json'"
           :button-text="!insight.snoozed ? 'אני רוצה לשמוע עוד' : 'לאפשרויות שלי'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div v-if="!insight.snoozed">
        זיהנו שיש לך <span class="bold">{{ balance }} ₪</span> בעו״ש, הכסף כרגע <span class="bold">נשחק ומאבד מערכו</span>.
      </div>
      <div v-else>
        הכסף שלך בעו״ש נשחק, וביקשת שנזכיר לך מה אפשר לעשות.
      </div>
    </template>
  </insight>
    <image-prefetch img-src="/images/saving-zone/balance-money/riseup_plus_fair.svg"/>
    <image-prefetch img-src="/images/saving-zone/balance-money/bank.svg"/>
    <icon class="hidden" name="check" />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';
import ImagePrefetch from '@/base-components/ImagePrefetch';
import BaseUI from '@riseupil/base-ui';
import BalanceMoneyFlow from '../personalized-savings/balance-money/BalanceMoneyFlow';
import Insight from './components/Insight';

export default {
  name: 'BalanceMoneyInsight',
  components: {
    ImagePrefetch,
    Insight,
    Icon: BaseUI.Icon,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    this.initBalance();
  },
  methods: {
    ...mapActions('balance', ['initBalance']),
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      this.openModal({ component: BalanceMoneyFlow, props: { insight: this.insight, origin: 'Insight' } });
    },
  },
  computed: {
    ...mapGetters('balance', ['totalOshBalance']),
    balance() {
      return moneyUtils.formatAmount(Math.round(this.totalOshBalance));
    },
  },
};

</script>

<style scoped lang="scss">
@import "src/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/balance-money/balance-money-styles";

</style>
