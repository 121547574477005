<template>
  <div>
    <div class="dead-end">
        <div class="content">
          <span class="title r-typo-headline">בשביל להגיע ללינק שרצית, צריך לפתוח אותו בדפדפן אחר</span>
          <span class="text">רק צריך להעתיק את הלינק ולהדביק בדפדפן מחוץ לאפליקציית פייסבוק</span>
          <riseup-button
            subtitle="להעתיק את הלינק"
            :action="copyToClipboard"
            :bold="true"/>
        </div>
      </div>
      <div class="support-footer">
        <div class="support">יש לך שאלה?</div>
        <a class="support-link" href="mailto:support@riseup.co.il?subject=יש לי שאלה" target="_blank">לעזרה מהצוות</a>
      </div>
    </div>
</template>

<script>

import DDLogs from '@/DDLogs';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import TextCopiedToaster from './navigation-pages/cashflow-app/toasters/TextCopiedToaster';

export default {
  name: 'DeadEnd',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
    };
  },
  methods: {
    copyToClipboard() {
      this.$copyText(window.location.href).then(e => {
        Segment.trackUserGot('CopyLinkInDeadEnd');
        DDLogs.log('The customer copied the link');
        EventBus.$emit('open-toaster', {
          component: TextCopiedToaster,
          props: {
          },
        });
      }, e => {
        Segment.trackUserGot('FailedCopyLinkInDeadEnd');
        DDLogs.log('The customer failed to copy the link');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../scss/mixins';

.dead-end {

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  .content {
    padding: 50px 30px 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .mini-title {
      padding-top: 40px;
    }

    .riseup-button {
      height: 75px;
      font-size: 20px;
      align-self: flex-end;
      margin-top: 45px;
      width: 100%;
    }

    .text {
      margin-top: 12px  ;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
    }
  }

  .support-footer {
    font-family: SimplerPro;
    font-size: 16px;
    line-height: 28px;
    color: #6F6F6F;
    width: 100%;
    bottom: 0;
    padding: 0 30px 30px 30px;
    text-align: center;
    margin-top: 40px;
    .separator {
      margin-bottom: 10px;
      display: inline-block;
      width: 15px;
      height: 1px;
      background-color: $riseup_gray_0;
    }
    .support-link {
      margin-top: 8px;
      color: $riseup_blue;
      cursor: pointer;
    }
  }
}

</style>
