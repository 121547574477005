<template>
  <div class="papa-merge-question">
    <img v-if="cardSource" :src="require(`@/assets/bank-logos/${cardSourceImage}`)" class="card-source-image"  />
    <img v-else :src="require('@/assets/icons/credit_card_24dp.svg')" class="unknown-card-source-svg" />
    <div class="account-number">חשבון {{accountNumber}}</div>
    <div class="transaction-details">
      <div class="date">{{transactionDate}}</div>
      <div class="business-name">{{businessName}}</div>
      <div class="amount">{{ billingAmount }} ₪</div>
    </div>
    <div class="buttons-section">
      <riseup-button v-if="showAddCreditCardButton"
                     :title="'לחיבור חשבון אשראי'"
                     :size="'slim'"
                     :action="connectCreditCard"
                     :variant="'primary'"/>
      <riseup-button v-if="showMarkAsLoanButton"
                     class="mark-as-loan-btn"
        :title="'זה החזר הלוואה'"
                     :size="'slim'"
                     :action="markAsLoan"
                     :variant="'secondary'"/>
    </div>
  </div>
</template>

<script>
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import TransactionDisplayUtils
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/TransactionDisplayUtils';
import money from '@/utils/money';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import MarkPapaAsLoan
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/merge-investigation-in-app/MarkPapaAsLoan';
import PapaMergeQuestionsMixin from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/PapaMergeQuestionsMixin.vue';

export default {
  name: 'PapaMergeQuestion',
  mixins: [TransactionDisplayUtils, PapaMergeQuestionsMixin],
  components: { RiseupButton: BaseUI.RiseupButton },
  props: {
    question: {
      type: Object,
    },
    showMarkAsLoanButton: {
      type: Boolean,
      default: false,
    },
    showAddCreditCardButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cardSource() {
      return this.question.cardSource;
    },
    cardSourceImage() {
      return getSourceConfigurationBySourceName(this.cardSource).logoFile;
    },
    accountNumber() {
      return this.question.accountNumberPiiValue;
    },
    transactionDate() {
      return this.formatDate(this.question.billingDate);
    },
    billingAmount() {
      return money.formatAmountWithFraction(this.question.billingAmount);
    },
    businessName() {
      return this.question.businessName;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    connectCreditCard() {
      Segment.trackUserInteraction('PapaMergeInsight_AddCardClicked', {
        amount: this.billingAmount,
        transactionId: this.question.transactionId,
        businessName: this.businessName,
        billingDate: this.transactionDate,
      });
      this.addCard({
        question: this.question,
        sourceType: 'card',
        sourceName: this.cardSource,
      });
    },
    markAsLoan() {
      Segment.trackUserInteraction('PapaMergeInsight_MarkedAsLoan', {
        amount: this.billingAmount,
        transactionId: this.question.transactionId,
        businessName: this.businessName,
        billingDate: this.transactionDate,
      });
      this.openModal({
        component: MarkPapaAsLoan,
        props: {
          question: this.question,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.papa-merge-question {
  .card-source-image {
    width: 150px;
    margin-bottom: 12px;
  }

  .unknown-card-source-svg {
    width: 34px;
    margin-bottom: 10px;
  }

  .account-number {
    font-size: 22px;
    font-weight: bold;
  }

  .transaction-details{
    margin-top: 24px;
    line-height: 24px;
    font-size: 18px;
  }

  .buttons-section{
    margin-top: 26px;

    .mark-as-loan-btn{
      margin: 15px 0 25px 0;
    }
  }
}
</style>
