<template>
  <insight :animation-name="'BalanceMoney.json'"
           :button-action="buttonAction"
           :button-text="'לפרטים נוספים'"
           :insight="insight">
    <template v-slot:insight-text>
      <div>
        <span class="bold">הגיעה הכנסה של {{ formatIncome }} ₪</span>
        <span>לחשבון שלך ב{{ sourceDisplayName }}.
          ההכנסה תופיע בתזרים הבא שלך</span>
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import IndentedIncomePopup from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/IndentedIncomePopup.vue';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import money from '@/utils/money';
import Insight from './components/Insight';

export default {
  name: 'IndentedIncomeInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    sourceDisplayName() {
      return getSourceConfigurationBySourceName(this.insight.source).indefiniteDisplayName;
    },
    formatIncome() {
      return money.formatAmountWithFraction(this.insight.incomeAmount, 0);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      this.openModal(
        {
          component: IndentedIncomePopup,
          props: {
            insight: this.insight,
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}

.bold {
  font-weight: bold;
}
</style>
