<template>
  <div>
    <img class="spinner" src="/images/onboarding/spinner-big.svg"/>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .spinner {
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

</style>
