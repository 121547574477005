<template>
  <div class="purchase-offering-done">
    <dashboard-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="headline-container">
          <div class="ri-large-body">פרטי התשלום התקבלו בהצלחה</div>
          <div class="ri-small-display">
            <div>איזה כיף </div>
            <div>שהצטרפת</div>
          </div>
        </div>
      </template>
      <template v-slot:dashboard>
        <div class="margin-ml">
          <property class="margin-bottom-m" name="סוג מינוי" :value="subscriptionTypeText"/>
          <property class="margin-bottom-m" name="סך הכל לתשלום" :value="price"/>
          <property class="margin-bottom-m" name="תאריך לחיוב ראשון" :value="formattedNextPaymentDate"/>
          <property class="margin-bottom-m" name="כרטיס אשראי לחיוב" :value="newFormattedCardNumberMask"/>
          <property class="margin-bottom-xs" name="אימייל לשליחת קבלות" :value="activeMember.emailPiiValue"/>
          <div class="link" @click="redirectToPersonalInfo">עריכה</div>
        </div>
      </template>
    </dashboard-page>
    <div class="margin-ml">
      <riseup-button :title="backToCashflowButtonText" :action="backToCashflow" :size="'small'"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import router from '@/router';
import { BACKGROUND_POSITION } from '@riseupil/base-ui/src/utils/layout-consts';
import Property from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/Property.vue';
import Segment from '@/Segment';

export default {
  name: 'PurchaseOfferingDone',
  components: {
    Property,
    RiseupButton: BaseUI.RiseupButton,
    DashboardPage: BaseUI.Pages.DashboardPage,
  },
  async created() {
    await this.setAppHeaderProps({
      backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      textColor: BaseUI.Colors.riseupWhite,
      logoColor: BaseUI.Colors.riseupWhite,
      sticky: false,
      rightActions: [],
    });
    await this.fetchCreditCardMask();
    Segment.trackUserGot('PurchaseOfferingDoneEntered');
    Segment.trackUserGotToPixel('SD');
  },
  computed: {
    ...mapGetters('subscription', ['newFormattedCardNumberMask', 'subscriptionTypSelectedText', 'formattedNextPaymentDate']),
    ...mapGetters('onboardingState', ['onboardingCompleted']),
    ...mapState('session', ['activeMember']),
    subscriptionTypeText() {
      return 'חודשי עם שלושה חודשים ראשונים במחיר מיוחד';
    },
    price() {
      return '76 ₪';
    },
    backToCashflowButtonText() {
      return this.onboardingCompleted ? 'חזרה לתזרים' : 'אפשר להמשיך';
    },
    imageHeaderProps() {
      return {
        // eslint-disable-next-line global-require
        imgSrc: require('@/assets/subscription/after-payment-header.svg'),
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
        bottomSeparator: false,
        textColor: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
        backgroundPosition: BACKGROUND_POSITION.BOTTOM,
      };
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    ...mapActions('subscriptionState', ['initSubscriptionState']),
    ...mapActions('subscription', ['fetchCreditCardMask']),
    async backToCashflow() {
      Segment.trackUserInteraction('PurchaseOfferingDone_BackToCashflowClicked');
      await this.initSubscriptionState();
      await this.$router.push({ path: '/sr' });
    },
    redirectToPersonalInfo() {
      router.push({ path: '/personal-info' });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "~@riseupil/base-ui/src/scss/riseup-colors";
  .purchase-offering-done {

    .headline-container {
      text-align: start;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex: 1;
    }

    ::v-deep .title.with-overlay {
      align-items: unset;
    }

    .link {
      cursor: pointer;
      text-decoration: underline;
      color: $riseup_blue;
    }

    .riseup-button {
      width: 100%;
      margin-top: 10px;
    }
  }
</style>
