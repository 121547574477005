<template>
  <arrow-selection-item :imageSource="imageSource"
                        :text="text"
                        @click="raiseClickEvent"
                        class="source-type-selection-item">
  </arrow-selection-item>
</template>

<script>
import ArrowSelectionItem from '@/base-components/ArrowSelectionItem';

export default {
  name: 'SourceTypeSelectionItem',
  components: {
    ArrowSelectionItem,
  },
  props: {
    text: { type: String, required: true },
    imageSource: { type: String, required: true },
  },
  methods: {
    raiseClickEvent() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>

.source-type-selection-item {
  font-size: 20px;
}
</style>
