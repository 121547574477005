<template>
  <callout class="callout" iconPath="end-of-year/gift.svg" title="ציפור לילה, מגיעה לך הטבה">
    <template v-slot:text>
      <div class="ri-label">
        ברכישת תוכנית ייעוץ אישית עד 9:00 בבוקר (ביום ו׳ 16.2), ניתן לך חודש אחד במינוי במתנה.
      </div>
    </template>
  </callout>
</template>

<script>

import BaseUI from '@riseupil/base-ui';

export default {
  name: 'AdvisoryNightOfferingCallout',
  components: {
    Callout: BaseUI.Callout,
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.callout {
  text-align: start;
  background-color: $riseup_light_green;
  padding-right: $ri-spacing-m;
  padding-left: $ri-spacing-m;
}

</style>
