import { client } from '../http';

export default {
  async getCommunicationState() {
    const response = await client.get('/api/communication-state');
    return response.data;
  },

  async updateCommunicationStateForMember(communicationState) {
    const response = await client.put('/api/communication-state/auto-send/pause-member', communicationState);
    return response.data;
  },
};
