import * as _ from 'lodash';
import BalanceApi from '@/api/BalanceApi';

const state = {
};

const actions = {
  async showAboutDynamicDepositPopup({ state }) {
    const balances = await BalanceApi.fetchBalances();
    const totalBalance = _.sumBy(balances, item => item.balance);
    return totalBalance > 0;
  },
};

// mutations
const mutations = {
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
