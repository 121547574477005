<template>
  <base-tool-tip class="info-tooltip" icon-path="/images/info-icon-blue.svg">
    <div>
      <span class="ri-bold-label">עבור משתמשי iPhone,</span>
      <span>יש לחבר את החשבון באמצעות דפדפן Safari או מהמחשב</span>
    </div>
  </base-tool-tip>
</template>

<script>
import BaseToolTip from '@/base-components/onboarding/BaseToolTip.vue';
import Segment from '@/Segment';

export default {
  name: 'LeumiIphoneChromeAlert',
  components: {
    BaseToolTip,
  },
  created() {
    Segment.trackUserGot('LeumiIphoneChromeAlertShown');
  },
};
</script>

<style scoped lang="scss">

.info-tooltip{
  margin-top: 5px;
  margin-bottom: 35px;
  font-style: normal;
  font-size: 14px;
  text-align: right;
  line-height: 20px;
}

</style>
