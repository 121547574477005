<template>
  <insight :insight="insight"
           :animation-name="insightParams.animationName"
           :button-text="'לעדכון ופרטים נוספים'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>זיהינו שה{{ insightParams.expenseOrIncomeText }} {{ insightParams.fromOrTo }} <span class="bold">"{{ shortenedBusinessName }}"</span>
        היתה {{ insightParams.highOrLow }} ב-
        <span class="amount">{{ formattedAmount }} ₪</span>
        מהצפוי<span v-if="hasRemainingWeeklyVariables">, יתרת המשתנות {{ insightParams.increaseOrDecreaseText }} בהתאם</span></div>
    </template>
  </insight>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TransactionInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/TransactionInsightPopup';
import cashflowViewConsts from '@/constants/cashflow-view';
import CashflowUpdaterApi from '@/api/CashflowUpdaterApi';
import EventBus from '@/event-bus/event-bus';
import BalanceToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/insights/BalanceToaster';
import moneyUtils from '@/utils//money';
import Insight from './components/Insight';

export default {
  name: 'FixBalanceInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapGetters('cashflowView', ['remainingWeeklyVariables']),
    buttons() {
      const buttons = [
        {
          text: 'הבנתי, תודה',
          variant: 'primary',
          action: async () => {
            await this.markAsRead(this.insight);
            this.openToaster(false);
            this.closePopup();
          },
        }];
      if (this.insight.sequenceId) {
        buttons.push({
          text: 'זה שינוי חד פעמי',
          variant: 'secondary',
          action: async () => {
            await this.markAsRead(this.insight);
            await this.ignoreAmountInPrediction();
            this.openToaster(true);
            this.closePopup();
          },
        });
      }

      return buttons;
    },
    shortenedBusinessName() {
      if (this.insight.businessName.length > 15) {
        return `${this.insight.businessName.slice(0, 15)}...`;
      }
      return this.insight.businessName;
    },
    insightParams() {
      if (this.insight.isIncome) {
        if (this.insight.positiveBalance) {
          // eslint-disable-next-line max-len
          return { expenseOrIncomeText: 'הכנסה', fromOrTo: 'מ', increaseOrDecreaseText: 'גדלה', highOrLow: 'גבוהה', animationName: 'fixed-income-increased.json' };
        }
        // eslint-disable-next-line max-len
        return { expenseOrIncomeText: 'הכנסה', fromOrTo: 'מ', increaseOrDecreaseText: 'קטנה', highOrLow: 'נמוכה', animationName: 'fixed-income-decreased.json' };
      }
      if (this.insight.positiveBalance) {
        // eslint-disable-next-line max-len
        return { expenseOrIncomeText: 'הוצאה', fromOrTo: 'על ', increaseOrDecreaseText: 'גדלה', highOrLow: 'נמוכה', animationName: 'fixed-expense-decreased.json' };
      }
      // eslint-disable-next-line max-len
      return { expenseOrIncomeText: 'הוצאה', fromOrTo: 'על', increaseOrDecreaseText: 'קטנה', highOrLow: 'גבוהה', animationName: 'fixed-expense-increased.json' };
    },
    amountDifference() {
      return Math.abs(this.insight.originalAmount - this.insight.balancedAmount);
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.amountDifference, 0);
    },
    insightPrefix() {
      return this.insight.expense ? `${this.insightParams.expenseOrIncomeText} קבועה ${this.insight.expense}`
        : `${this.insightParams.expenseOrIncomeText} קבועה`;
    },
    cashflowCategory() {
      return this.insight.isIncome ? cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_INCOME : cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED;
    },
    popupTitle() {
      return `זיהינו שה${this.insightParams.expenseOrIncomeText} החודש היתה
      ${this.insightParams.highOrLow} ב-${this.formattedAmount} ₪`;
    },
    hasRemainingWeeklyVariables() {
      return this.remainingWeeklyVariables > 0;
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    async ignoreAmountInPrediction() {
      await CashflowUpdaterApi.ignoreAmountInPrediction({
        transactionId: this.insight.transactionId,
        sequenceId: this.insight.sequenceId,
      });
    },
    buttonAction() {
      this.openModal({
        component: TransactionInsightPopup,
        props: {
          insight: this.insight,
          insightPrefix: this.insightPrefix,
          insightPopupTitle: this.popupTitle,
          cashflowCategory: this.cashflowCategory,
          buttons: this.buttons,
          showFeedbackFooter: true,
        },
      });
    },
    closePopup() {
      this.closeModal();
    },
    openToaster(oneTime) {
      EventBus.$emit('open-toaster', {
        component: BalanceToaster,
        props: {
          isIncome: this.insight.isIncome,
          oneTime,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
.amount {
  font-weight: bold;
  white-space: pre;
}
</style>
