const TRANSITION_NAME = 'fade';
const NO_TRANSITION_NAME = 'none';

const state = {
  visible: true,
  component: undefined,
  componentProps: undefined,
  appearAndDisappearTransitionName: TRANSITION_NAME,
};

const actions = {
  setBottomCorner({ commit }, { component, props, withTransition = true }) {
    commit('setAppearAndDisappearTransitionName', withTransition);
    commit('setComponent', component);
    commit('setComponentProps', props);
  },
  clearBottomCorner({ commit }, withTransition = true) {
    commit('setAppearAndDisappearTransitionName', withTransition);
    commit('setComponent', undefined);
    commit('setComponentProps', undefined);
  },
  toggleBottomCornerVisibility({ commit, state }, visible) {
    commit('setVisible', visible);
  },
};

const mutations = {
  setComponent(state, value) {
    state.component = value;
  },
  setComponentProps(state, value) {
    state.componentProps = value;
  },
  setAppearAndDisappearTransitionName(state, withTransition) {
    state.appearAndDisappearTransitionName = withTransition ? TRANSITION_NAME : NO_TRANSITION_NAME;
  },
  setVisible(state, value) {
    state.visible = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
