<template>
  <transition name="menu" appear>
    <div v-if="isOpen" class="navigation-overlay">
      <div class="fullscreen overlay"/>
      <div class="fullscreen">
        <div class="content-wrapper">
          <div class="overlay-content" v-click-outside="closeModal">
            <navigation-menu :close-modal="closeModal"/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import NavigationMenu from './NavigationMenu.vue';
import ClickOutside from '../../directives/click-outside';

export default {
  name: 'NavigationOverlay',
  props: {
    isOpen: Boolean,
  },
  components: {
    NavigationMenu,
  },
  directives: {
    ClickOutside,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .menu-enter-active, .menu-leave-active {
    transition: all 0.3s;
    .overlay-content {
      transition: all 0.3s ease-in-out;
    }
    .overlay {
      transition: all 0.3s;
    }
  }
  .menu-enter, .menu-leave-to {
    .overlay-content {
      transform: translateX(100%);
    }
    .overlay {
      opacity: 0;
    }
  }
  .menu-enter-to, .menu-leave {
    .overlay-content {
      transform: translateX(0);
    }
    .overlay {
      opacity: 1;
    }
  }

  .fullscreen {
    font-family: SimplerPro;
    position: fixed; // TODO add somthing from elsa that disable scroll from background
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    &.overlay {
      background-color: rgba(43, 50, 59, 0.6);
    }

    .content-wrapper {
      max-width: 420px;
      width: 100%;

      .overlay-content {
        height: 100%;
        width: calc(100% - 42px);
        max-width: 100%;
      }
    }
  }

  @media (min-width: 430px) {
    .navigation-overlay {
      width: 100%;
      margin: calc(100vh*0.05) 0;
      display: flex;
      justify-content: center;

      .fullscreen {
        width: 400px;
        height: calc(100vh - 100vh*0.1 - 35px - 20px);
      }
    }
  }
</style>
