<template>
  <div class="churn-goodbye-page">
    <img :src="require('@/assets/generic-success.png')" class="margin-vertical-m success-lottie"/>
    <div class="ri-large-headline">החשבון שלך וכל הפרטים הקשורים אליו יימחקו תוך 48 שעות</div>
  </div>
</template>

<script>

export default {
  name: 'ChurnGoodbyePage',
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.churn-goodbye-page {
  padding: 50px 28px 30px;
  text-align: center;

  .success-lottie {
    height: 200px;
  }
}
</style>
