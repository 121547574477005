const QUESTION_NAMES = {
  FINANCIAL_CONTROL: 'financialControl',
  HOUSEHOLD: 'household',
  AVERAGE_INCOME: 'averageIncome',
  HANDLE_UNEXPECTED_INCOME: 'handleUnexpectedIncome',
  HANDLE_UNEXPECTED_EXPENSE: 'handleUnexpectedExpense',
  JOB_TO_BE_DONE: 'jobToBeDone',
};

const ANSWER_OPTIONS = {
  [QUESTION_NAMES.FINANCIAL_CONTROL]: {
    FULL_CONTROL: 'fullControl',
    SOME_CONTROL: 'someControl',
    NO_CONTROL: 'noControl',
  },
  [QUESTION_NAMES.HOUSEHOLD]: {
    JUST_ME: 'justMe',
    PARTNER: 'partner',
    KIDS: 'kids',
  },
  [QUESTION_NAMES.AVERAGE_INCOME]: {
    OVER_BAR: 'overBar',
    UNDER_BAR: 'underBar',
    NO_FIXED_INCOME: 'noFixedIncome',
  },
  [QUESTION_NAMES.HANDLE_UNEXPECTED_INCOME]: {
    COVER_LOANS: 'coverLoans',
    SAVINGS: 'savings',
    BALANCE: 'balance',
  },
  [QUESTION_NAMES.HANDLE_UNEXPECTED_EXPENSE]: {
    CALM: 'calm',
    STRESSED: 'stressed',
    ANXIOUS: 'anxious',
  },
  [QUESTION_NAMES.JOB_TO_BE_DONE]: {
    FINANCIAL_CLARITY: 'financialClarity',
    CLOSE_THE_MONTH: 'closeTheMonth',
    START_SAVING: 'startSaving',
  },
};

const FIRST_QUESTION_NAME = QUESTION_NAMES.HOUSEHOLD;
const WINE_AND_DINE_VERSION = '1'; // bump the version when changing questions / answers keys!

export default {
  QUESTION_NAMES,
  ANSWER_OPTIONS,
  FIRST_QUESTION_NAME,
  WINE_AND_DINE_VERSION,
};
