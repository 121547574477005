<template>
  <component :is="depositComponent"
             v-bind="{nextStep, showErrorPage }" />
</template>

<script>
import { mapGetters } from 'vuex';
import DDChooseMonthlyAmount from './DDChooseMonthlyAmount';
import DDChooseOpportunisticAmount from './DDChooseOpportunisticAmount';
import DDReduceMonthlyAmount from './DDReduceMonthlyAmount';

export default {
  name: 'DDChooseAmount',
  components: {
    DDChooseMonthlyAmount,
    DDChooseOpportunisticAmount,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    showErrorPage: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters('dynamicSavings', ['isMonthlyDeposit', 'isReduceMonthlyDeposit']),
    depositComponent() {
      if (this.isMonthlyDeposit) {
        return DDChooseMonthlyAmount;
      }
      if (this.isReduceMonthlyDeposit) {
        return DDReduceMonthlyAmount;
      }
      return DDChooseOpportunisticAmount;
    },
  },
};
</script>
