<template>
  <div>
    <div v-if="stepNumber" class="dot">
      {{stepNumber}}
    </div>
    <div class="slot">
      <slot name="text" > </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OpenBankingDescriptionStep',
  props: {
    stepNumber: {
      type: String,
      required: false,
    },
  },
  components: {
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.dot {
  height: 32px;
  width: 32px;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
  background-color: $riseup-light_blue;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  color:$riseup_blue;
  font-weight: 700;
  padding-top: 3px;
}

.slot {
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 8px;
  margin-bottom: 20px;
}
</style>
