<template>
  <div class="challenge-flow">
    <challenge-intro-popup v-if="currentComponent === componentNames.challengeSetupPopup" @done-clicked="showNext" @close="close" />
    <set-category-challenge v-else-if="currentComponent === componentNames.setCategoryChallengePopup"
                            @done-clicked="showNext" @back-clicked="showPrevious"/>
    <set-category-challenge-input-popup v-else-if="currentComponent === componentNames.setCategoryChallengeInputPopup"
                                        @done-clicked="save" @back-clicked="showPrevious"/>
    <challenge-selected-celebration-popup
      v-else-if="currentComponent === componentNames.challengeSelectionCelebrationPopup" @close="close" />
  </div>
</template>

<script>
import _ from 'lodash';
import SetCategoryChallenge
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/SetCategoryChallenge';
import { mapActions } from 'vuex';
import ChallengeIntroPopup
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/ChallengeIntroPopup';
import SetCategoryChallengeInputPopup
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/SetCategoryChallengeInputPopup';
import ChallengeSelectedCelebrationPopup
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/ChallengeSelectedCelebrationPopup';
import Segment from '@/Segment';

const pages = {
  challengeSetupPopup: 'challengeSetupPopup',
  setCategoryChallengePopup: 'setCategoryChallengePopup',
  setCategoryChallengeInputPopup: 'setCategoryChallengeInputPopup',
  challengeSelectionCelebrationPopup: 'challengeSelectionCelebrationPopup',
};

const componentsMap = {
  challengeSetupPopup: {
    name: pages.challengeSetupPopup,
    next: pages.setCategoryChallengePopup,
  },
  setCategoryChallengePopup: {
    name: pages.setCategoryChallengePopup,
    next: pages.setCategoryChallengeInputPopup,
    prev: pages.challengeSetupPopup,
  },
  setCategoryChallengeInputPopup: {
    name: pages.setCategoryChallengeInputPopup,
    next: pages.challengeSelectionCelebrationPopup,
    prev: pages.setCategoryChallengePopup,
  },
  challengeSelectionCelebrationPopup: {
    name: pages.challengeSelectionCelebrationPopup,
  },
};

export default {
  name: 'ChallengeFlow',
  components: {
    ChallengeIntroPopup,
    SetCategoryChallenge,
    SetCategoryChallengeInputPopup,
    ChallengeSelectedCelebrationPopup,
  },
  props: {
    insight: {
      type: Object,
    },
    sourceComponent: {
      type: String,
    },
  },
  async created() {
    Segment.trackUserInteraction('ChallengeInsightPopup_Shown', { sourceComponent: this.sourceComponent });
  },
  data() {
    return {
      currentComponent: componentsMap.challengeSetupPopup.name,
    };
  },
  computed: {
    componentNames() {
      return _.mapValues(componentsMap, c => c.name);
    },
  },
  methods: {
    ...mapActions('createTrackingCategory', ['reset']),
    ...mapActions('insights', ['markAsRead']),
    showNext() {
      this.currentComponent = componentsMap[this.currentComponent].next;
    },
    showPrevious() {
      Segment.trackUserInteraction('ChallengeInsightPopup_Back_Clicked', { sourceComponent: this.sourceComponent });
      this.currentComponent = componentsMap[this.currentComponent].prev;
    },
    save() {
      if (this.insight) {
        this.markAsRead(this.insight);
      }
      this.showNext();
    },
    close() {
      this.reset();
      Segment.trackUserInteraction('ChallengeInsightPopup_Close_Clicked', { sourceComponent: this.sourceComponent });
      this.$emit('close');
    },
  },
};
</script>

<style scoped>

</style>
