<template>
  <div :class="classes" :style="{ 'background-color': backgroundColor }">
    <div class="header">
      <div class="ri-font-40" v-if="headerText">
        <div>{{headerText}}</div>
      </div>
    </div>
    <div class="image" v-if="imageUrl">
      <img :src="`/images/${imageUrl}`">
    </div>
    <div class="frame">
      <slot></slot>
    </div>
    <div class="tail">
      <div class="logo"><img :src="require(`@/assets/logo-horizontal-${logoColor}.svg`)"></div>
    </div>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ShareFrame',
  props: {
    backgroundColor: {
      type: String,
      default: BaseUI.Colors.riseupYellow,
    },
    logoColor: {
      type: String,
      default: 'green',
    },
    imageUrl: {
      type: String,
      default: null,
    },
    headerText: {
      type: String,
      default: null,
    },
  },
  computed: {
    classes() {
      return `share-frame ${this.variant}`;
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';

.share-frame {
  display: flex;
  flex-direction: column;
  padding: 50px 50px 25px;

  .header{
    width: 70%;
    font-weight: 900;
    line-height: 45px;
    color: $riseup_dark_green;
  }
  .image {
    min-height: 150px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  .frame {
    background-color: $riseup_white;
    margin-bottom: 25px;
    border-radius: $ri-border-radius-large;
  }

  .tail {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    .logo img {
      width: 100%;
      height: 32px;
    }
  }
}

</style>
