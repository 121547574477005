<template>
  <insight :insight="insight"
           button-text="לעדכן צפי הכנסות"
           imageName="income_increased.svg"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div class="text">
        <b> ידוע לך על הכנסות משתנות שייכנסו גם החודש{{ cashflowStartAdjustment }}?</b>
        אם כן, מומלץ לעדכן את התזרים כבר עכשיו!
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import dateUtils from '@/utils/dates';
import Insight from './components/Insight';
import EditPredictedVariableIncomePopup from
      '../cashflow-view/current-month/components/cashflow-edit/edit-predicted-variable-income/EditPredictedVariableIncomePopup';

export default {
  name: 'PredictedVariableIncomeInsight',
  components: {
    Insight,
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
  },
  created() {
    Segment.trackUserInteraction('PredictedVariableIncomeInsight_Shown', {});
  },
  computed: {
    ...mapState('cashflowView', ['cashflowStartDay', 'presentedBudgetDate']),
    cashflowStartAdjustment() {
      if (this.cashflowStartDay === 1) {
        return '';
      }
      const endDate = dateUtils.endOfMonthText(this.presentedBudgetDate, this.cashflowStartDay);
      return `(עד ה־${endDate})`;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('insights', ['markAsRead']),
    buttonAction() {
      Segment.trackUserInteraction('PredictedVariableIncomeInsight_Clicked');
      this.openModal({
        component: EditPredictedVariableIncomePopup,
        props: {
          insight: this.insight,
          onCancel: () => {
            this.markAsRead(this.insight);
          },
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.text {

}
</style>
