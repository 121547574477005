<template>
  <sticky-cta-page :cta-props="{buttonProps: { title: 'בחזרה לתזרים'}}" @click="onContinueClicked">
    <img src="/images/advisory/mislaka-consent-header.svg" />
    <div class="mislaka-after-consent-share">
      <div class="padding-horizontal-ml">
        <div class="ri-body top-title margin-top-ml margin-bottom-sm">לתכנן את העתיד ביחד</div>
        <div class="ri-large-headline margin-bottom-xs">מצוין, וידאת שהתמונה הכלכלית תכלול גם אותך וגם את {{otherMemberFirstName}}.</div>
        <div class="ri-large-body margin-bottom-ml">
          עכשיו אנחנו על זה ונעדכן אותך ואת {{otherMemberFirstName}} בווטסאפ בהמשך התהליך.
        </div>
        <img src="/images/advisory/mislaka-after-share.png" class="margin-bottom-ml image" />
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>
import { COLOR_VARIANT } from '@riseupil/base-ui/src/utils/layout-consts';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapGetters } from 'vuex';

export default {
  name: 'MislakaAfterConsentShare',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  computed: {
    ...mapGetters('session', ['otherMemberFirstName']),
    headerProps() {
      return {
        backgroundColor: COLOR_VARIANT.DARK_BLUE,
        leftActions: [{
          type: 'CLOSE',
          onClick: () => this.$emit('close'),
        }],
      };
    },
  },
  created() {
    Segment.trackUserGot('MislakaAfterConsentShare_Entered');
  },
  methods: {
    onContinueClicked() {
      Segment.trackUserGot('MislakaAfterConsentShare_ContinueClicked');
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.mislaka-after-consent-share{
  height: 100%;
  text-align: right;

  .top-title{
    color: $riseup_blue;
  }

  .image {
    height: 130px;
    width: 130px;
  }
}

</style>
