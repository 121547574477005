import moment from 'moment';
import cashflowHistory from '@/store/utilities/cashflow-history';
import { HOME_PATH } from '@/router/authenticated';

// eslint-disable-next-line import/prefer-default-export
export function addCashflowBudgetDateToParams(routeName, to) {
  if (!to.params.budgetDate) {
    const budgetDate = to.query.msg === 'eom' ? 'previous' : 'current';
    return {
      path: `${routeName}/${budgetDate}`,
      query: to.query,
      params: to.params,
      replace: true,
    };
  }
  // If budget date is in the future, use 'current' budget date
  if (to.params.budgetDate && moment(to.params.budgetDate).isAfter(cashflowHistory.getCurrentBudgetDate())) {
    return {
      path: `${HOME_PATH}/current`,
      query: to.query,
      params: to.params,
      replace: true,
    };
  }
  return undefined;
}
