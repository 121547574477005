import { client } from '../http';

export async function markAsSavingTransaction(transactionId) {
  const res = (await client.post('/api/hamster/saving-transactions', { transactionId })).data;
  return res;
}

export async function getCustomerSavingTransactions() {
  return (await client.get('/api/hamster/saving-transactions')).data;
}

export async function unmarkAsSavingTransaction(transactionId) {
  const res = (await client.delete(`/api/hamster/saving-transactions/${transactionId}`)).data;
  return res;
}

export async function getRiseupPool() {
  return (await client.get('/api/hamster/riseup-pool')).data;
}

export async function getCustomerProgress() {
  return (await client.get('/api/hamster/customer-progress')).data;
}

export async function getCashflowTrends() {
  return (await client.get('/api/hamster/cashflow-trends')).data;
}

export async function getCategoryTrend(categoryId) {
  return (await client.get(`/api/hamster/category-trends/${categoryId}`)).data;
}

export async function excludeTransactionsFromPool(excludedFromPoolTransactionIds) {
  await client.post('/api/hamster/riseup-pool/exclude-transactions', { excludedFromPoolTransactionIds });
}
