import Vue from 'vue';
import _ from 'lodash';

import AccountInvestigatorApi from '../../api/AccountInvestigatorApi';

const state = {
  accountQuestions: [],
};

const getters = {
  noUnansweredAccountQuestions: state => _.isEmpty(_.filter(state.accountQuestions, q => !q.answered)),
};

const actions = {
  async fetchQuestions({ commit }) {
    const accountQuestions = await AccountInvestigatorApi.fetchAccountInvestigationQuestions();
    commit('setAccountQuestions', accountQuestions);
  },
  async setAccountStatus({ commit }, { accountStatus, accountDetails }) {
    try {
      commit('setAccountStatus', { accountStatus, accountDetails, answered: true });
    } catch (e) {
      commit('setAccountStatus', { accountStatus: '', accountDetails, answered: false });
    }
  },
  async saveAccountInputs({ state }) {
    await AccountInvestigatorApi.setAccountsStatus({ accountAnswers: _.map(state.accountQuestions, _extractAccountInput) });
  },
};

const mutations = {
  setAccountQuestions(state, accountQuestions) {
    state.accountQuestions = accountQuestions;
  },
  setAccountStatus(state, { accountStatus, accountDetails, answered }) {
    const { accountNumberPiiId, source } = accountDetails;
    const accountAnswered = _.find(state.accountQuestions, q => q.accountNumberPiiId === accountNumberPiiId && q.source === source);
    Vue.set(accountAnswered, 'answered', answered);
    Vue.set(accountAnswered, 'accountStatus', accountStatus);
  },
};

function _extractAccountInput(accountQuestion) {
  return {
    account: accountQuestion.accountNumberPiiValue,
    accountNumberPiiId: accountQuestion.accountNumberPiiId,
    accountStatus: accountQuestion.accountStatus,
    source: accountQuestion.source,
    sourceType: accountQuestion.sourceType,
  };
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
