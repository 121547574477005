import _ from 'lodash';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import { DateLocales } from '@riseupil/common-utils';
import moment from 'moment';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';
import cashflowViewConsts from '@/constants/cashflow-view';
import dateUtils from '@/utils/dates';

const {
  CATEGORY_NAMES,
  CASHFLOW_CATEGORIES,
} = cashflowViewConsts;

// eslint-disable-next-line import/prefer-default-export
export function getTransactionsForSearch(budgets, plans) {
  const cashflows = _.chain(budgets)
    .map(budget => {
      const reactiveCf = reactiveCashflow.createCashflow(budget, DateLocales.EN_IL);
      const normalizedCashflow = cashflowNormalizer.normalizeCashflow(reactiveCf);
      return [budget.budgetDate, { normalizedCashflow, reactiveCashflow: reactiveCf }];
    })
    .fromPairs()
    .value();

  return _.chain(cashflows)
    .flatMap(({ normalizedCashflow }, budgetDate) => cashflowToTransactions(normalizedCashflow, budgetDate, plans))
    .value();
}

function _extractTransactionFromCashflowType(cashflow, type, subtype) {
  const categories = subtype ? cashflow[type][subtype] : cashflow[type];
  return _.chain(categories.categories)
    .flatMap(category => category.transactions)
    .value();
}

function _extractTransactionFromCashflowTypeEconomy(cashflow) {
  const categories = cashflow.economy;
  return categories ? _.chain(categories.categories)
    .flatMap(category => _.map(category.transactions, t => _.merge(t, {
      categoryName: CATEGORY_NAMES.TRACKING_CATEGORY,
      cashflowCategory: CASHFLOW_CATEGORIES.TRACKING,
    })))
    .value() : [];
}

function _extractTransactionFromCashflowTypeFixed(cashflow) {
  const categories = cashflow.fixedExpense;
  return _.chain(categories.categories)
    .flatMap(category => category.transactions)
    .map(t => _.merge(t, {
      categoryName: CATEGORY_NAMES.FIXED_EXPENSE,
      cashflowCategory: CASHFLOW_CATEGORIES.FIXED,
      isPredictedTransaction: true,
      fixedExpenseCategoryName: t.expense,
    }))
    .value();
}

function _extractTransactionFromExcluded(cashflow) {
  const excludedTransactions = [
    ..._extractTransactionFromCashflowType(cashflow, 'excluded', 'excludedExpense'),
    ..._extractTransactionFromCashflowType(cashflow, 'excluded', 'excludedIncome'),
  ];
  return _.forEach(excludedTransactions, t => {
    t.isExcluded = true;
    t.categoryName = t.isIncome ? CATEGORY_NAMES.EXCLUDED_INCOME : CATEGORY_NAMES.EXCLUDED_EXPENSE;
    t.cashflowCategory = t.isIncome ? CASHFLOW_CATEGORIES.EXCLUDED_INCOME : CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE;
  });
}

function _extractTransactionFromTrackingCategory(cashflow) {
  return _.chain(cashflow.trackingCategory)
    .flatMap(track => track.categories)
    .flatMap(category => _.map(category.transactions, t => _.merge(t, {
      categoryName: CATEGORY_NAMES.TRACKING_CATEGORY,
      cashflowCategory: CASHFLOW_CATEGORIES.TRACKING,
      trackingCategoryName: t.trackingCategory.name === 'כלכלה' ? 'סופר' : t.trackingCategory.name,
    })))
    .value();
}

function _extractTransactionFromIncome(cashflow) {
  const fixedIncomeEnvelopes = _extractTransactionFromCashflowType(cashflow, 'income', 'fixedIncome');
  const fixedIncomeTransactions = _.chain(fixedIncomeEnvelopes)
    .forEach(t => {
      t.categoryName = CATEGORY_NAMES.FIXED_INCOME;
      t.isPredictedTransaction = true;
      t.cashflowCategory = CASHFLOW_CATEGORIES.FIXED_INCOME;
    })
    .value();
  const variableIncomeTransactions = _.chain(_extractTransactionFromCashflowType(cashflow, 'income', 'variableIncome'))
    .forEach(t => {
      t.categoryName = CATEGORY_NAMES.VARIABLE_INCOME;
      t.cashflowCategory = CASHFLOW_CATEGORIES.VARIABLE_INCOME;
    })
    .value();
  return [...fixedIncomeTransactions, ...variableIncomeTransactions];
}

function _extractTransactionFromVariableExpense(cashflow) {
  return _.chain(cashflow)
    .get('variableExpense.monthly.realExpenses.categories')
    .flatMap(category => category.transactions)
    .filter()
    .map(t => _.merge(t, {
      categoryName: CATEGORY_NAMES.VARIABLE_EXPENSE,
      cashflowCategory: CASHFLOW_CATEGORIES.VARIABLE,
    }))
    .value();
}

function _extractTransactionFromOneTimeSaving(cashflow) {
  return _.chain(cashflow.oneTimeSaving)
    .flatMap(track => track.categories)
    .flatMap(category => _.map(category.transactions, t => _.merge(t, {
      categoryName: CATEGORY_NAMES.ONE_TIME_SAVING,
      cashflowCategory: CASHFLOW_CATEGORIES.ONE_TIME_SAVING,
    })))
    .value();
}

function _extractTransactionFromFixedSaving(cashflow) {
  const categories = cashflow.fixedSaving;
  return _.chain(categories.categories)
    .map(category => category.transactions)
    .flatten()
    .map(t => _.merge(t, {
      categoryName: CATEGORY_NAMES.FIXED_SAVING,
      cashflowCategory: CASHFLOW_CATEGORIES.FIXED_SAVING,
      isPredictedTransaction: true,
    }))
    .value();
}

function _extractTransactionFromSavings(cashflow) {
  return [
    ..._extractTransactionFromOneTimeSaving(cashflow),
    ..._extractTransactionFromFixedSaving(cashflow),
  ];
}

// all possible date formats for search optimization
function _getDatePermutations(transactionDate, budgetDate) {
  return `${budgetDate} ${transactionDate.format('DD-MM-YYYY')} ${transactionDate.format('DD/MM/YYYY')} ${transactionDate.format('DD.MM.YYYY')} `
        + `${transactionDate.format('D-M-YY')} ${transactionDate.format('D/M/YY')} ${transactionDate.format('D.M.YY')} `
        + `${dateUtils.getMonthAndYear(transactionDate)} ${dateUtils.getMonthAndYear(budgetDate)} `;
}

// trying to keep the transactions as lean as possible, to reduce the heap size
const TRANSACTION_REQUIRED_FIELDS = [
  'accountNumberPiiValue', 'sourceType', 'predictedAmount', 'sequenceCustomerComment',
  'name', 'monthsInterval', 'transactionId', 'isSaving', 'splitFrom', 'incomeAmount',
  'billingAmount', 'isPostponed', 'isTemp', 'customerComment', 'businessName', 'transactionDate',
  'paymentNumber', 'totalNumberOfPayments', 'paymentAppAddressee', 'paymentAppComment', 'billingDate',
];
const SEARCH_ADDITIONAL_FIELDS = ['categoryName', 'cashflowCategory', 'isPredictedTransaction', 'fixedExpenseCategoryName', 'trackingCategoryName'];

function cashflowToTransactions(cashflow, budgetDate, plans) {
  const transactions = [
    ..._extractTransactionFromCashflowTypeEconomy(cashflow),
    ..._extractTransactionFromExcluded(cashflow),
    ..._extractTransactionFromCashflowTypeFixed(cashflow),
    ..._extractTransactionFromIncome(cashflow),
    ..._extractTransactionFromTrackingCategory(cashflow),
    ..._extractTransactionFromVariableExpense(cashflow),
    ..._extractTransactionFromSavings(cashflow),
  ];
  return _.chain(transactions)
    .reject(t => t.isPapa)
    .map(t => {
      const transactionDate = moment(t.transactionDate ?? t.actual?.transactionDate);
      return {
        ..._.pick(t, [...TRANSACTION_REQUIRED_FIELDS, ...SEARCH_ADDITIONAL_FIELDS]),
        actual: t.actual ? _.pick(t.actual, TRANSACTION_REQUIRED_FIELDS)
          : undefined,
        amount: Math.trunc(getAmount(t)),
        cardNumber: t.accountNumberPiiValue ?? t.actual?.accountNumberPiiValue,
        businessName: t.businessName ?? t.actual?.businessName ?? t.name,
        fuzzyDate: _getDatePermutations(transactionDate, budgetDate),
        originalAmount: -t.originalAmount,
        planName: plans.find(plan => plan._id === t.plan)?.name,
        transactionId: t.actual?.transactionId ?? t.transactionId ?? t.sequenceId,
        budgetDate,
        customerComment: t.customerComment ?? t.actual?.customerComment,
        paymentAppComment: t.paymentAppComment ?? t.actual?.paymentAppComment,
        paymentAppAddressee: t.paymentAppAddressee ?? t.actual?.paymentAppAddressee,
      };
    })
    .value();
}

function getAmount(t) {
  let transaction = t;
  if (t.isPredictedTransaction) {
    if (t.actual) {
      transaction = t.actual;
    } else {
      if (t.isIncome && t.predictedIncome) {
        return t.predictedIncome;
      }
      if (t.predictedExpense) {
        return -t.predictedExpense;
      }
      return t.predictedAmount;
    }
  }
  if (transaction.billingAmount) {
    return `-${transaction.billingAmount}`;
  }
  if (transaction.incomeAmount) {
    return transaction.incomeAmount;
  }
  return 0;
}
