<template>
  <base-credit-card :host-field-id="containerName"
                    :title="'תוקף'"
                    :error-message="expiration.error"
                    :focused="focused"
                    :empty="expiration.isEmpty"/>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';

import BaseCreditCard from './BaseCreditCard.vue';

export default {
  name: 'Expiration',
  components: {
    BaseCreditCard,
  },
  data() {
    return {
      containerName: 'expiration-container',
      blurredOnce: false,
      focused: false,
      displayErrorMessage: false,
      errorMessage: '',
    };
  },
  async mounted() {
    const expiration = this.fields.create(window.PayMe.fields.EXPIRATION, {
      placeholder: ' ',
      styles: {
        base: {
          'font-size': '20px',
          'text-align': 'right',
          color: '#2b323b',
          '::placeholder': {
            color: '#9b9b9b',
          },
        },
      },
    });
    expiration.mount(`#${this.containerName}`);

    expiration.on('keyup', this.setKeyUpResult);
    expiration.on('blur', this.markAsBlurred);
    expiration.on('focus', this.markAsFocused);
  },
  computed: {
    ...mapState('subscription', ['fields', 'creditCard']),
    ...mapGetters('subscription', ['creditCardFields']),
    expiration() {
      return this.creditCard[this.creditCardFields.expiration];
    },
  },
  methods: {
    ...mapActions('subscription', ['setCreditCardField']),
    setKeyUpResult(event) {
      const { isValid, isEmpty } = event;
      this.setCreditCardField({ field: this.creditCardFields.expiration, isValid, isEmpty });
    },
    markAsBlurred() {
      this.blurredOnce = true;
      this.focused = false;
    },
    markAsFocused() {
      this.focused = true;
    },
  },
};
</script>
