const state = {
  userAgreed: null,
};

const getters = {};

const actions = {
  async fetchUserAgreed({ commit, rootState }) {
    const { customerId } = rootState.session;
    const userAgreed = JSON.parse(localStorage.getItem(`${customerId}-userAgreed`)) || false;
    commit('setUserAgreed', { userAgreed });
  },
  async setUserAgreed({ commit, rootState }, { userAgreed }) {
    const { customerId } = rootState.session;
    localStorage.setItem(`${customerId}-userAgreed`, userAgreed);
    commit('setUserAgreed', { userAgreed });
  },
};

const mutations = {
  setUserAgreed(state, { userAgreed }) {
    state.userAgreed = userAgreed;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
