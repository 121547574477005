import BaseUI from '@riseupil/base-ui';
import { shapeVariants } from '@/pages/responsive-pages/authenticated/navigation-pages/settings/profileShape.consts';

// eslint-disable-next-line import/prefer-default-export
export const settingsProfileVariants = [{
  colorName: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
  image: shapeVariants.FLOWER,
}, {
  colorName: BaseUI.LayoutConsts.COLOR_VARIANT.PINK,
  image: shapeVariants.EIGHT_POINTS_STAR,
}, {
  colorName: BaseUI.LayoutConsts.COLOR_VARIANT.GREEN,
  image: shapeVariants.EIGHT_LEAVES_FLOWER,
}];
