<template>
  <div class="dropdown-input" >
    <input-dropdown :options="options" :value="selected" :first-selected="firstSelected"
                    :on-select="onSelect" :placeholder="placeholder" :disabled="disabled"/>
    <div class="top-right-label-wrapper" :class="{ disabled, selected }">{{ labelText }}</div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'DropdownInput',
  components: {
    InputDropdown: BaseUI.InputDropdown,
  },
  props: {
    labelText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      require: false,
    },
    firstSelected: {
      type: Object,
      require: true,
    },
    options: {
      type: Array,
      require: true,
    },
    onSelect: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      selected: this.firstSelected,
    };
  },
  methods: {
    onChange(value) {
      this.selected = value;
      this.onSelect(value);
    },
  },
  watch: {
    firstSelected(newValue, oldValue) {
      this.selected = this.firstSelected;
    },
  },
};
</script>

<style lang="scss">
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/typography';
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/border-radius';
@import '~vue-select/dist/vue-select.css';

.dropdown-input {
  position: relative;
  .top-right-label-wrapper {
    z-index: 9999;
    position: absolute;
    top: -18px;
    font-size: $ri-font-size-14;
    color: $riseup_blue;
    transition: 300ms ease all;
    right: 0;
    &:not(.selected) {
      position: absolute;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      padding-right: $ri-spacing-sm;
      pointer-events: none;
      transition: 300ms ease all;
      color: $riseup_gray_2;
      font-size: $ri-font-size-20;
    }
    &.disabled {
      color: $riseup_gray_2;
    }
  }
}

</style>
