<template>
  <div class="navigation-menu-header">
    <div class="flex-row full-width">
      <profile-shape :shape="settingsVariant.image" :color="settingsVariant.colorName" :size="48"/>
      <div class="members-details ri-label">
        <span class="ri-alte ellipsis-text" :class="longHouseholdName ? 'ri-bold-title' : 'ri-medium-bold-headline'">
          {{ memberName }}
        </span>
        <span class="last-updated ri-label">עדכון אחרון: {{ lastUpdated }}</span>
      </div>
      <button class="settings-button" @click.stop="openSettings" ref="settings">
        <icon icon-name="settings" fill size="24px" :color="settingsIconColor" :optical-size="24"/>
      </button>
    </div>
    <trial-state-snackbar v-if="!isHundredDaysPromotion" class="margin-top-sm" :close-modal="closeModal" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import ProfileShape from '@/pages/responsive-pages/authenticated/navigation-pages/settings/ProfileShape';
import {
  settingsProfileVariants,
} from '@/pages/responsive-pages/authenticated/navigation-pages/settings/settings.consts';
import _ from 'lodash';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import TrialStateSnackbar from '@/base-components/app-header/TrialStateSnackbar';

export default {
  name: 'NavigationMenuHeader',
  components: {
    TrialStateSnackbar,
    ProfileShape,
    Icon: BaseUI.Icon,
  },
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      settingsIconColor: BaseUI.Colors.riseupBlue,
    };
  },
  computed: {
    ...mapGetters('session', ['householdName', 'numberOfMembers']),
    ...mapGetters('cashflowView', ['budgetUpdatedAt']),
    ...mapGetters('benefits', ['isHundredDaysPromotion']),
    ...mapState('session', ['activeMember', 'members']),
    memberName() {
      return this.numberOfMembers > 1 ? this.householdName : `${this.activeMember.firstNamePiiValue} ${this.activeMember.lastNamePiiValue}`;
    },
    lastUpdated() {
      const updateHour = moment(this.budgetUpdatedAt)
        .format('H:mm');
      const updateDate = moment(this.budgetUpdatedAt)
        .format('D.MM');
      return `${updateDate} ${updateHour} `;
    },
    settingsVariant() {
      const memberIndex = (_.chain(this.members)
        .sortBy(m => m.phoneNumberPiiValue)
        .findIndex({ phoneNumberPiiValue: this.activeMember?.phoneNumberPiiValue })
        .value());
      return settingsProfileVariants[memberIndex ?? 0];
    },
    longHouseholdName() {
      return this.memberName.length > 14;
    },
  },
  methods: {
    focus() {
      this.$refs.settings.focus();
    },
    openSettings() {
      Segment.trackUserInteraction('NavigationMenu_SettingsClicked');
      this.$router.push({
        name: 'Settings',
        query: this.$route.query,
      });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/border-radius';

.navigation-menu-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .members-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding: 0 $ri-spacing-sm;
  }

  .subtitle {
    display: flex;
    flex-direction: column;
    color: $riseup_gray_60;
  }

  .settings-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    background: $riseup_gray_05;
    border: none;
    border-radius: $ri-border-radius-medium;
    cursor: pointer;
  }
  .last-updated {
    color: $riseup_gray_60;
  }
}
</style>
