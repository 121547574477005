import { client } from '../http';

export default {
  async createTimeLimitedOffer(offerId, offerValidForHours) {
    const response = await client.post('/api/time-limited-offers/', { offerId, offerValidForHours });
    return response.data;
  },
  async getTimeLimitedOffers() {
    const response = await client.get('/api/time-limited-offers/');
    return response.data;
  },
  async deleteTimeLimitedOffer(offerId) {
    const response = await client.delete(`/api/time-limited-offers/${offerId}`);
    return response.data;
  },
};
