<template>
  <colored-popup :class="'light-blue'" :back-action="onBack" :dark-text="true">
    <template v-slot:top-content>
      <div class="centered">
        <img class="balance-money-logos" src="/images/saving-zone/balance-money/jerubank.svg"/>
        <div class="title margin-top-large">פקדון קצר מועד בבנק ירושלים</div>
      </div>
    </template>

    <template v-slot:bottom-content>
      <div class="body-text margin-bottom-x-large">
        <div class="bold accent-text margin-bottom-large">כדי לקבל את ההטבות יש להזין באתר הבנק את קוד ההטבה riseup
        </div>
        <div class="bold margin-bottom-small">מה חשוב לדעת?</div>
        <bullet-block class="margin-bottom-medium" text-class-names="font-16" :title="'סוג הריבית:'"
                      text="משתנה בהתאם לריבית הפריים. אם היא עולה, הריבית בפיקדון תעלה, ולהפך."
                      :bullet-text="1"/>
        <bullet-block class="margin-bottom-medium" :title="'גובה הריבית:'"
                      text="4.3%, כלומר פריים (6% נכון להיום) פחות 1.7%."
                      :bullet-text="2"/>
        <bullet-block class="margin-bottom-medium" :title="'נזילות'"
                      text="הנזילות היא חודשית, אפשר למשוך את הכסף לחשבון הבנק בהתראה של 35 ימים."
                      :bullet-text="3"/>
        <bullet-block class="margin-bottom-medium" :title="'כדאי להכיר'"
                      text="רייזאפ תקבל מבנק ירושלים 0.15% מכל הפקדה (על כל 1,000 ש״ח שיופקדו, רייזאפ תקבל 1.5 ₪)."
                      :bullet-text="4"/>
      </div>
      <riseup-button
        class="margin-bottom-medium"
        title="לפתיחת פקדון בבנק"
        :action="onDismissClicked"
        size="slim"
        :bold="true"
      />
    </template>
  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import BulletBlock from '../../cashflow-view/current-month/components/challenge-flow/BulletBlock';

const BANK_LINK = 'https://www.bankjerusalem.co.il/savingsanddeposits/Pages/pikadon-online.aspx?key=coupon'
  + '&partner=riseupjb#/form?stage=1&lobby=1&partner=riseupjb&key=coupon&product=60&depositType=one&color=%237d5d93/';

export default {
  name: 'PakamStepInfo',
  components: {
    BulletBlock,
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    onBack: {
      type: Function,
      required: true,
    },
    onDismiss: {
      type: Function,
      required: true,
    },
    stepName: {
      type: String,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('BalanceMoneyFlow_StepEntered', { step: this.stepName });
  },
  computed: {
    firstBulletText() {
      return 'לקוחות רייזאפ יקבלו את ריבית בנק ישראל מינוס 1.7%, או בכתיבה בנקאית: P-1.7%.'
       + ' ריבית בנק ישראל היום (P) היא 6%. בהתאם לזה, הריבית תהיה 4.3% על הכסף שיופקד בבנק ירושלים.';
    },
  },
  methods: {
    onDismissClicked() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedOpenBankJerusalem');
      window.open(BANK_LINK, '_blank');
      this.onDismiss(false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/balance-money/balance-money-styles";

</style>
