<template>
  <div class="payment-details">
    <div class="amount-date-wrapper margin-bottom-xs">
      <div class="wrapper">
        <div class="ri-label">סך הכל לתשלום</div>
        <div class="prices-container">
          <div v-if="strikePrice" class="text strike-price ri-bold-title margin-left-s">{{ strikePrice }} ₪</div>
          <div class="text padding-top-xs padding-bottom-s ri-bold-title">{{ price }} ₪</div>
        </div>
      </div>
      <div class="wrapper date">
        <div class="ri-label">תאריך לחיוב</div>
        <div class="text padding-top-xs padding-bottom-s ri-bold-title">{{ paymentDate }}</div>
      </div>
    </div>
    <discount-note v-if="discount" :text="discountText" />
  </div>
</template>

<script>

import DiscountNote from '@/pages/responsive-pages/authenticated/subscription/DiscountNote';

export default {
  name: 'PaymentDetails',
  components: {
    DiscountNote,
  },
  props: {
    price: {
      type: Number,
      require: true,
    },
    paymentDate: {
      type: String,
      require: true,
    },
    discount: {
      type: Boolean,
      require: true,
    },
    strikePrice: {
      type: Number,
      require: false,
    },
    discountText: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.payment-details {
  width: 100%;
  text-align: start;

  .amount-date-wrapper {
    display: flex;
  }

  .wrapper {
    flex-grow: 1;

    .text {
      padding: 12px 0;
      line-height: 1;
    }

    .prices-container {
      display: flex;

      .strike-price {
        text-decoration: line-through;
        color: $riseup_gray_1;
        padding-right: 10px;
      }
    }

    &.date {
      margin-right: 50px;
    }
  }
}
</style>
