import _ from 'lodash';

export const SPLIT_TRANSACTION_INCOME = 'הכנסה';
export const SPLIT_TRANSACTION_EXPENSE = 'הוצאה';
export const SPLIT_TRANSACTION_INCOME_PLURAL = 'הכנסות';
export const SPLIT_TRANSACTION_EXPENSE_PLURAL = 'הוצאות';

export function findCategory(splitTransaction, availableCategories) { // eslint-disable-line
  if (splitTransaction.placement === 'excluded') {
    return _.find(availableCategories, category => category.categoryName === 'excludedExpense'
      || category.categoryName === 'excludedIncome');
  }
  if (splitTransaction.placement === 'variable') {
    return _.find(availableCategories, category => _.includes(['variableIncome', 'variable'], category.categoryName));
  }
  if (splitTransaction.placement === 'trackingCategory') {
    return _.find(availableCategories, category => category.categoryId === splitTransaction.trackingCategory.id);
  }
  return _.find(availableCategories, category => category.categoryId === splitTransaction.placement
    || category.categoryName === splitTransaction.placement);
}

export function findOriginalParentCategory(splitTransaction, availableCategories) { // eslint-disable-line
  if (splitTransaction.placementBeforeSplit.placement === 'trackingCategory') {
    const { trackingCategoryId } = splitTransaction.placementBeforeSplit;
    const category = _.find(availableCategories, category => category.categoryId === trackingCategoryId);
    if (!category) {
      return _findVariableCategory(availableCategories);
    }
    return _.find(availableCategories, category => category.categoryId === splitTransaction.placementBeforeSplit.trackingCategoryId);
  }
  if (splitTransaction.placementBeforeSplit.placement === 'variable') {
    return _findVariableCategory(availableCategories);
  }
  return _.find(availableCategories, category => category.categoryName === splitTransaction.placementBeforeSplit.placement);
}

function _findVariableCategory(availableCategories) {
  return _.find(availableCategories, category => _.includes(['variableIncome', 'variable'], category.categoryName));
}

export function transactionAmount(transaction) {
  return transaction.billingAmount || transaction.incomeAmount;
}
