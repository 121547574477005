import { client } from '../http';

export default {
  async authenticate({ phoneNumber, code }) {
    return await client.post('/api/no-auth/signup/authenticate', { phoneNumber, code });
  },
  async createMember({ firstName, lastName, phoneNumber, email, enableMarketingEmails }) {
    return await client.post('/api/invite-auth/create-member', { firstName, lastName, phoneNumber, email, enableMarketingEmails });
  },
  async createCustomer({
    firstName, lastName, utmParams, email, enableMarketingEmails, icrcParam, fbcId, jtbdMarketingCode, product,
  }) {
    return await client.post('/api/signup-auth/create-customer', {
      firstName,
      lastName,
      utmParams,
      email,
      enableMarketingEmails,
      icrc: icrcParam,
      fbcId,
      jtbdMarketingCode,
      product,
    });
  },
  async saveCustomerAnswers(answers) {
    return await client.post('/api/partial-auth/save-lead-qualification-answers', {
      answers,
    });
  },
  async sendBotiIntro() {
    return await client.post('/api/signup/send-boti-intro');
  },
};
