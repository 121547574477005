const LOCAL_STORAGE_DISMISSED_KEY = 'riseup.eomcelebration';

const state = {
  celebrationDismissed: null,
};

const getters = {};

const actions = {
  fetchCelebrationDismissed({ commit }) {
    const cashflowDate = localStorage.getItem(LOCAL_STORAGE_DISMISSED_KEY);
    commit('setCelebrationDismissed', { cashflowDate });
  },
  setCelebrationDismissed({ commit }, cashflowDate) {
    localStorage.setItem(LOCAL_STORAGE_DISMISSED_KEY, cashflowDate);
    commit('setCelebrationDismissed', { cashflowDate });
  },
};

const mutations = {
  setCelebrationDismissed(state, { cashflowDate }) {
    state.celebrationDismissed = cashflowDate;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
