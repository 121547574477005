<template>
  <div class="activation-insight-popup-action">
    <div class="action" v-on:click="onClick">
      <img :class="iconClass" :src="require(`@/assets/icons/${icon}`)"/>
      <div class="title">{{ title }}</div>
      <img :src="require('@/assets/icons/icon_left_arrow.svg')"/>
    </div>
    <separator v-if="!isLast"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'ActivationInsightPopupAction',
  components: {
    Separator: BaseUI.Separator,
  },
  props: {
    icon: { type: String, required: true },
    title: { type: String, required: true },
    action: { type: Function, required: true },
    isLast: { type: Boolean, required: false, default: false },
  },
  computed: {
    iconClass() {
      return this.icon.replace('.svg', '');
    },
  },
  methods: {
    onClick() {
      Segment.trackUserInteraction('ActivationInsightPopupAction_Clicked');
      this.action();
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .activation-insight-popup-action {
    .action {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 17px;
      margin-bottom: 17px;
      cursor: pointer;

      .title {
        font-size: 18px;
        font-weight: bold;
      }

      .active-challenge {
        height: 40px;
        width: 40px;
      }
    }
  }

</style>
