<template>
  <div class="source-logo">
    <div class="logo-container">
      <img class="logo-image" :src="require(`@/assets/bank-logos/${source.logoFileStacked}`)">
    </div>
    <div v-if="name" class="source-display-name">{{ name }}</div>
    <div v-else class="source-display-name">{{ source.displayName }}</div>
  </div>
</template>

<script>

import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';

export default {
  name: 'SourceLogo',
  props: {
    sourceName: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
  },
  computed: {
    source() {
      return getSourceConfigurationBySourceName(this.sourceName);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .source-logo {
    .logo-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;

      .logo-image {
        height: 36px;
      }
    }

    .source-display-name {
      font-size: 20px;
      font-family: SimplerPro;
      color: $riseup_black;
    }
  }
</style>
