<template>
  <div class="credentials-input-field" v-click-outside="() => setShowPassword(false)">
    <ValidationProvider :rules="validations" slim ref="validation-provider">
      <div class="input-group">
        <input ref="input"
          class="input-container"
          :class="{ empty: empty, error: validationError || error }"
          :value="fieldValue"
          :type="inputType"
          v-model="value"
          v-on:input="updateInput($event.target.value)"
          autocapitalize="none"/>
        <label class="field-name">{{ fieldName }}</label>
        <label v-if="validationError || errorMessage" class="top-left-label">{{ validationError || errorMessage }}</label>
      </div>
      <div v-if="isPasswordField" class="image-wrapper">
        <img v-if="showPassword" src="/images/eye_blue.svg" v-on:click="onEyeClick(false)"/>
        <img v-else src="/images/eye.svg" v-on:click="onEyeClick(true)"/>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>

import ClickOutside from '@/directives/click-outside';

export default {
  name: 'CredentialsInputField',
  directives: {
    ClickOutside,
  },
  props: {
    error: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
    fieldValue: {
      type: String,
    },
    updateInput: {
      type: Function,
    },
    fieldName: {
      type: String,
    },
    fieldType: {
      type: String,
      validator: val => ['text', 'password', 'number'].includes(val),
    },
    validations: {
      type: String,
    },
    field: {
      type: Object,
    },
  },
  data() {
    return {
      showPassword: false,
      validationError: null,
      value: '',
    };
  },
  computed: {
    empty() {
      return !this.fieldValue || this.fieldValue === '';
    },
    isPasswordField() {
      return this.fieldType === 'password';
    },
    inputType() {
      if (this.isPasswordField && !this.showPassword) {
        return 'password';
      }
      if (this.fieldType === 'number') {
        return 'tel';
      }
      return 'text';
    },
  },
  methods: {
    setShowPassword(showPassword) {
      this.showPassword = showPassword;
    },
    onEyeClick(showPassword) {
      this.setShowPassword(showPassword);
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    async validate() {
      this.validationError = null;
      const isValid = await this.$refs['validation-provider'].validate();
      if (!isValid.valid) {
        [this.validationError] = isValid.errors;
        const textField = this.$el;
        if (textField) {
          textField.classList.add('animation');
          setTimeout(() => textField.classList.remove('animation'), 1000);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@riseupil/base-ui/src/scss/border-radius';
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
    .credentials-input-field {
      position: relative;

      .input-group {
        height: 56px;
        position: relative;

        .top-left-label {
          position: absolute;
          top: -13px;
          right: -2px;
          width: 100%;
          text-align: left;
          font-size: 11px;
          color: $riseup_gray_2;
        }

        .input-container {
          color: $riseup_black;
          font-family: SimplerPro;
          font-size: 20px;
          width: 100%;
          height: 100%;
          padding: 10px 10px 10px 5px;
          display: block;
          border: 1px solid $riseup_gray_2;
          border-radius: $input-field-border-radius;

          &:focus {
            outline: none;
            border: 1px solid $riseup_blue;
          }

          &:focus ~ .field-name,
          &:valid ~ .field-name {
            position: relative;
            top: -75px;
            font-size: 12px;
            color: $riseup_blue;
            transition: 300ms ease all;
          }

          &.empty:not(:focus):not(:disabled) ~ .field-name {
            color: $riseup_gray_2;
            font-size: 20px;
            font-weight: normal;
            position: relative;
            pointer-events: none;
            right: 10px;
            top: -38px;
            transition: 300ms ease all;
          }

          &:not(.empty):not(:focus):not(:disabled):not(.error) {
            border: 1px solid $riseup_blue;
          }

          &:not(.empty).error {
            border: 1px solid $riseup_danger_red;
          }

          &:not(.empty).error ~ .field-name {
            color: $riseup_danger_red;
          }

        }
      }
      .image-wrapper {
        position: absolute;
        top: 17px;
        left: 20px;
      }
    }
</style>
