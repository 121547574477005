<template>
  <div class="missing-credit-cards">
    <div class="single-card-container flex-row align-center" v-for="cardNumber of missingCardNumbers" :key="cardNumber">
      <img :src="require('@/assets/icons/credit_card_24dp_gray.svg')"
           class="margin-left-xs"/>
      <span class="card-number">{{ cardNumber }} ••••</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissingCreditCards',
  props: {
    missingCardNumbers: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/spacings";

.missing-credit-cards{
  .single-card-container:not(:last-child){
    margin-bottom: $ri-spacing-xs;
  }

  .card-number{
    margin-top: 3px;
  }
}
</style>
