<template>
  <div class="move-from-fixed-summary-popup">
    <popup :title="`ה${incomeOrExpense} תעבור`"
           :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:content>
        <change-category-path :old-category="originalCategory" :new-category="selectedCategory" />
        <transaction-details :transaction="transaction" />
      </template>
    </popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import MoveFromFixedConfirmationPopup from './MoveFromFixedConfirmationPopup.vue';
import TransactionDetails from './TransactionDetails.vue';
import ChangeCategoryPath from './ChangeCategoryPath.vue';
import Segment from '../../../../../../../../../../Segment';
import TransactionMovedToaster from '../../../../../toasters/TransactionMovedToaster';

export default {
  name: 'MoveFromFixedSummaryPopup',
  components: {
    Popup: BaseUI.Popup,
    TransactionDetails,
    ChangeCategoryPath,
  },
  created() {
    Segment.trackUserInteraction('MoveFromFixedSummaryPopup_Shown', {
      businessName: this.transaction.businessName,
      expenseCategory: this.transaction.expense,
      isIncomeCategory: this.isIncomeCategory,
      transactionId: this.transaction.transactionId,
    });
  },
  computed: {
    ...mapState('editTransaction', ['transaction', 'categoryRuleType']),
    ...mapGetters('editTransaction', ['selectedCategory', 'originalCategory', 'categoryToVariant', 'isIncomeCategory', 'incomeOrExpense']),
    buttonOptions() {
      return [{
        title: 'אישור',
        size: 'slim',
        action: this.onSave,
        variant: 'primary',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('editCashflow', ['moveFixedToVariable']),
    back() {
      Segment.trackUserInteraction('MoveFromFixedSummaryPopup_BackClicked', {
        businessName: this.transaction.businessName,
        transactionId: this.transaction.transactionId,
      });
      this.$emit('close');
      this.openModal({
        component: MoveFromFixedConfirmationPopup,
        props: {},
      });
    },
    async onSave() {
      Segment.trackUserInteraction('MoveFromFixedSummaryPopup_ConfirmClicked', {
        businessName: this.transaction.businessName,
        transactionId: this.transaction.transactionId,
        transactionExpense: this.transaction.expense,
      });
      this.$emit('close');
      await this.moveFixedToVariable({
        transactionId: this.transaction.transactionId,
      });
      EventBus.$emit('open-toaster', {
        component: TransactionMovedToaster,
        props: {
          changedTransaction: this.transaction,
          targetCategory: this.selectedCategory,
        },
      });
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';

  .move-from-fixed-summary-popup {

    .change-category-path {
      margin-bottom: 40px;
    }

    .transaction-details {
      margin-bottom: 24px;
    }

    .explanation {
      font-weight: bold;
      line-height: 16px;

      @include category-color;
    }
  }

</style>
