<template>
  <div class="wrapper" :class="variant">
    <div class="title ri-small-body">
      {{ title }}
    </div>
    <div class="counters">
      <label-counter label="התחילו תקופת נסיון" :count="activationNumber.toString()" />
      <label-counter label="המשיכו למנוי" :count="firstPaymentNumber.toString()" />
      <label-counter label="הרווחת החודש" :count="`${totalToPay}`" :isPayment="true" />
    </div>
  </div>
</template>

<script>
import LabelCounter from './LabelCounter';

export default {
  name: 'ReferralCounter',
  components: {
    LabelCounter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    activationNumber: {
      type: Number,
      required: true,
    },
    firstPaymentNumber: {
      type: Number,
      required: true,
    },
    variant: {
      type: String,
      default: '',
      validator: value => ['', 'withBackground'].includes(value),
    },
  },
  computed: {
    totalToPay() {
      const activationRewardAmount = 50;
      const firstPaymentRewardAmount = 100;
      return this.activationNumber * activationRewardAmount + this.firstPaymentNumber * firstPaymentRewardAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: $ri-spacing-ml;
  padding: $ri-spacing-ml;

  &.withBackground {
    background-color: $riseup_gray_01;
  }

  &:not(:last-child) {
    padding-bottom: $ri-spacing-m;
    border-bottom: 1px solid $riseup_gray_0;
  }

  .title {
    border-radius: 3px;
    background-color: $riseup_light_blue;
    flex-grow: 0;
    color: $riseup_black;
    width: fit-content;
    padding: 2px 6px;
  }

  .counters {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: $ri-spacing-ml;
  }
}
</style>
