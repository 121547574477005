<template>
  <div class="alert-page">
    <div>
      <img class="top-visual" :class="imgStyle" :src="img"/>
      <div class="ri-large-headline ri-alte margin-bottom-sm">{{ title }}</div>
      <div class="ri-large-body">
        <slot name="content"/>
      </div>
      <div class="button" v-if="showButton">
        <riseup-button @click="buttonAction" :title="buttonTitle" :size="'small'" :disabled="buttonDisabled" :variant="buttonVariant"/>
      </div>
    </div>
    <support-footer v-if="showSupportFooter" class="flex-column align-center margin-top-ml"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'AlertPage',
  components: {
    RiseupButton: BaseUI.V2Components.RiseupButton,
    SupportFooter: BaseUI.SupportFooter,
  },
  props: {
    img: {
      type: String,
      required: true,
    },
    imgStyle: {
      type: String,
      default: 'large',
      validator: val => ['small', 'large'].includes(val),
    },
    title: {
      type: String,
      required: true,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    buttonAction: Function,
    buttonTitle: String,
    buttonVariant: {
      type: String,
      default: 'primary',
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    showSupportFooter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
  @import '../scss/mixins';

  .alert-page {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1;
    overflow-y: auto;
  }

  .top-visual {
    margin-bottom: 24px;
  }

  img {
    max-width: 200px;
    &.large {
      max-width: 280px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .title {
    @include title();
    font-weight: bold;
    margin-bottom: 12px;
  }

  .content {
    line-height: 24px;
    margin-bottom: 24px;

    .bold {
      font-weight: bold;
    }
  }

  .button {
    margin-top: 40px;
  }

</style>
