<template>
  <div class="action-tool-tip">
    <base-tool-tip :icon-path="iconPath">
      <div class="tool-tip-text">
          <div class="text">{{ text }}<span class="action-text" v-on:click="handleClick">{{ actionText }}</span></div>
      </div>
    </base-tool-tip>
  </div>
</template>

<script>

import BaseToolTip from './BaseToolTip.vue';

export default {
  name: 'ActionToolTip',
  components: {
    BaseToolTip,
  },
  props: {
    text: {
      type: String,
    },
    iconPath: {
      type: String,
    },
    actionText: {
      type: String,
    },
    handleAction: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.handleAction();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

  .action-tool-tip {
    width: 100%;

    .tool-tip-text {
      font-size: 14px;
      font-family: SimplerPro;
      text-align: right;
      line-height: 20px;

      .action-text {
         font-weight: bold;
         color: $riseup_blue;
         font-weight: bold;
         cursor: pointer;
         text-decoration: underline;
         margin-bottom: 5px;
       }
    }
  }
</style>
