import _ from 'lodash';
import moment from 'moment';
import BillyApi from '@/api/BillyApi';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';

export const OFFERINGS = {
  ADVISORY: 'advisory',
  ADVISORY_PACKAGE: 'advisory-package',
  COUPLES: 'couples',
  ON_IT_TOGETHER_30_DAYS: '30-days',
  ON_IT_TOGETHER_100_DAYS: '100-days',
  LIOR_FRENKEL_AUDIO_COURSE: 'lior-frenkel-audio-course',
  BOOST: 'boost',
};

const items = {
  [OFFERINGS.ADVISORY]:
  [{
    title: 'העבודה הקשה, עלינו',
    subTitle: 'אנחנו נבנה לך תכנית ברורה שתגשים את כל המטרות שלך.',
  },
  {
    title: 'מדברים תכל׳ס',
    subTitle: 'בפגישת זום אישית נעבור יחד על התכנית וניתן לך צעדים פרקטיים כדי לצאת לדרך!',
  },
  {
    title: 'כל ההון שלך, זמין ברייזאפ',
    subTitle: 'המערכת שלנו תאסוף בשבילך את כל החסכונות, הקרנות והפקדונות שלך - כל הכספים והנכסים שנמצאים בבנק ומחוצה לו.',
  }],
  [OFFERINGS.ADVISORY_PACKAGE]:
    [{
      title: 'פגישת היכרות',
      subTitle: 'בפגישת זום נלמד להכיר אותך טוב יותר כדי להתאים לך את המשך התוכנית.',
    },
    {
      title: 'פגישת Boost',
      subTitle: 'ננתח לעומק את התזרים שלך ונזהה אפשרויות לשיפור ולחיסכון.',
    },
    {
      title: 'נגדיר את המטרות שלך לעתיד',
      subTitle: 'בעזרת שאלון ייחודי, נבין מה החלומות והיעדים שלך.',
    },
    {
      title: 'נמפה את ההון שלך בצורה אוטומטית',
      subTitle: 'ונבין מה הנכסים שאיתם אפשר להתקדם. ',
    },
    {
      title: 'פגישת זום עם יועץ כלכלי',
      subTitle: 'שיחבר בין התזרים החודשי, מיפוי ההון והיעדים, ויבנה איתך תוכנית כלכלית לשנים הבאות.',
    },
    {
      title: 'פגישת מעקב והתעדכנות',
      subTitle: 'נעזור לך לעקוב אחרי ההתקדמות, וכמה חודשים מאוחר יותר ניפגש להתעדכן ונעשה התאמות לפי הצורך.',
    }],
  [OFFERINGS.COUPLES]:
    [{
      title: 'ערכה לדייט כלכלי רומנטי',
      subTitle: 'ערכה דיגיטלית לשימוש מתי שתרצו',
    },
    {
      title: 'מופע הומוריסטי ומלמד',
      subTitle: 'בהנחיית גברת רביע, בזום, ב־25.2',
    },
    {
      title: 'הנחייה ליצירת תוכנית כלכלית זוגית',
      subTitle: 'נלמד להפוך את הכסף למטרה משותפת',
    }],
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]:
    [{
      title: 'תכנית אימון ייחודית למשך 30 יום',
      subTitle: '2 דקות ביום כדי להגיע לחסכון מיידי',
    },
    {
      title: 'תוכן ואתגרים שבועיים',
      subTitle: 'כלים וטיפים כדי לקחת את הכסף בידיים',
    },
    {
      title: 'ליווי בקבוצת וואטסאפ ייעודית',
      subTitle: 'ליווי קבוצתי מגדיל את הסיכויים להצלחה',
    }],
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]:
    [{
      title: 'תכנית אימון ייחודית למשך 100 יום',
      subTitle: '2 דקות ביום כדי להגיע לחסכון מיידי',
    },
    {
      title: 'תוכן ואתגרים שבועיים',
      subTitle: 'כלים וטיפים כדי לקחת את הכסף בידיים',
    },
    {
      title: 'ליווי בקבוצת וואטסאפ ייעודית',
      subTitle: 'ליווי קבוצתי מגדיל את הסיכויים להצלחה',
    }],
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]:
    [{
      title: '5 פרקים יומיים ',
      subTitle: 'אחת ליום ינחת אצלך בווטסאפ פרק בהנחיית ליאור פרנקל',
    },
    {
      title: 'נעשה סדר בכסף',
      subTitle: 'עם טיפים לשינוי הרגלים',
    },
    {
      title: 'נגלה איך לתת לכסף לעבוד בשבילנו',
      subTitle: 'נקבל כלים פשוטים לשינוי כלכלי'
      ,
    }],
  [OFFERINGS.BOOST]:
    [{
      title: 'יד ביד',
      subTitle: 'שיחה של שעה בזום 1-על-1 עם מומחית תזרים',
    },
    {
      title: 'כל מה שצריך לדעת ומהר',
      subTitle: 'ללמוד את שיטת התזרים במהירות ואת כל היכולות שיש לשירות של רייזאפ להציע',
    },
    {
      title: 'במיוחד בשבילך',
      subTitle: 'להתאים את התזרים למצב המשפחתי, לאורח החיים הייחודי שלך ובהתאם למטרות שלך!'
      ,
    }],
};

const title = {
  [OFFERINGS.ADVISORY]: [
    'תכנית ייעוץ אישית',
  ],
  [OFFERINGS.ADVISORY_PACKAGE]: [
    'סדרת פגישות ייעוץ',
    'לבניית תוכנית אישית',
  ],
  [OFFERINGS.COUPLES]: [
    'זוגיות בלי חשבון',
    'תוכנית בשלושה מפגשים',
  ],
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]: [
    'על זה ביחד',
    'תוכנית ליווי קבוצתית',
  ],
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]: [
    'על זה ביחד',
    'תוכנית ליווי קבוצתית',
  ],
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]: [
    'להיות בטוב עם הכסף',
    'עם ליאור פרנקל ורייזאפ',
  ],
  [OFFERINGS.BOOST]: [
    'שיחה 1-על-1 עם מומחה',
    'מתחילים בבוסט',
  ],
};

const header = {
  [OFFERINGS.ADVISORY]: [
    'רוצה להגשים',
    'חלומות?',
  ],
  [OFFERINGS.ADVISORY_PACKAGE]: [
    'שנתחיל לתכנן',
    'את העתיד שלך?',
  ],
  [OFFERINGS.COUPLES]: [
    'פרטי התוכנית',
  ],
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]: [
    'פרטי התוכנית',
  ],
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]: [
    'פרטי התוכנית',
  ],
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]: [
    'פרטי התוכנית',
  ],
  [OFFERINGS.BOOST]: [
    'שיחה 1-על-1 עם מומחה!',
    ' - מתחילים בבוסט',
  ],
};

const subHeader = {
  [OFFERINGS.ADVISORY]: 'במחיר מיוחד ללקוחות רייזאפ!',
  [OFFERINGS.ADVISORY_PACKAGE]: '',
  [OFFERINGS.COUPLES]: '',
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]: '',
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]: '',
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]: '',
  [OFFERINGS.BOOST]: '',
};

const fullPriceBeforeDiscount = {
  [OFFERINGS.ADVISORY]: 950,
  [OFFERINGS.ADVISORY_PACKAGE]: 2200,
  [OFFERINGS.COUPLES]: null,
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]: null,
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]: null,
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]: null,
  [OFFERINGS.BOOST]: null,
};

const paymentButtonTitle = {
  [OFFERINGS.ADVISORY]: 'להמשיך',
  [OFFERINGS.ADVISORY_PACKAGE]: '',
  [OFFERINGS.COUPLES]: '',
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]: '',
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]: '',
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]: '',
  [OFFERINGS.BOOST]: '',
};

const images = {
  [OFFERINGS.ADVISORY]: {
    landing: '/images/advisory/advisory-landing.svg',
    payment: '/images/advisory/advisory-payment.svg',
    bullet: '/images/advisory/advisory-list-item-new.svg',
  },
  [OFFERINGS.ADVISORY_PACKAGE]: {
    landing: '/images/advisory/advisory-landing.svg',
    payment: '/images/advisory/advisory-payment.svg',
    bullet: '/images/advisory/advisory-list-item-new.svg',
  },
  [OFFERINGS.COUPLES]: {
    landing: '/images/one-off-offerings/couples-landing.svg',
    payment: '/images/one-off-offerings/couples-payment.svg',
    bullet: '/images/one-off-offerings/couples-bullet.svg',
  },
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]: {
    landing: '/images/one-off-offerings/30-days-landing.svg',
    payment: '/images/one-off-offerings/30-days-payment.svg',
    bullet: '/images/one-off-offerings/30-days-bullet.svg',
  },
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]: {
    landing: '/images/one-off-offerings/30-days-landing.svg',
    payment: '/images/one-off-offerings/30-days-payment.svg',
    bullet: '/images/one-off-offerings/30-days-bullet.svg',
  },
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]: {
    landing: '/images/one-off-offerings/30-days-landing.svg',
    payment: '/images/one-off-offerings/30-days-payment.svg',
    bullet: '/images/one-off-offerings/30-days-bullet.svg',
  },
  [OFFERINGS.BOOST]: {
    landing: '/images/one-off-offerings/30-days-landing.svg',
    payment: '/images/one-off-offerings/30-days-payment.svg',
    bullet: '/images/one-off-offerings/30-days-bullet.svg',
  },
};

const config = {
  [OFFERINGS.ADVISORY]: {
    showChat: true,
    showShare: false,
    showTerms: true,
    showCallout: false,
    termsUrl: 'https://riseup-assets.s3.eu-west-1.amazonaws.com/plan_ahead_agreement.pdf',
    shareRoute: 'advisory-plan',
  },
  [OFFERINGS.ADVISORY_PACKAGE]: {
    showChat: true,
    showShare: true,
    showTerms: true,
    showCallout: true,
    termsUrl: 'https://riseup-assets.s3.eu-west-1.amazonaws.com/plan_ahead_agreement.pdf',
    shareRoute: 'advisory-package',
  },
  [OFFERINGS.COUPLES]: {
    showChat: false,
    showShare: false,
    showTerms: true,
    showCallout: false,
    termsUrl: 'https://riseup-assets.s3.eu-west-1.amazonaws.com/moneymoonv2agreement.pdf',
  },
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]: {
    showChat: false,
    showShare: false,
    showTerms: false,
    showCallout: false,
    termsUrl: '',
  },
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]: {
    showChat: false,
    showShare: false,
    showTerms: false,
    showCallout: false,
    termsUrl: '',
  },
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]: {
    showChat: false,
    showShare: false,
    showTerms: false,
    showCallout: false,
    termsUrl: '',
  },
  [OFFERINGS.BOOST]: {
    showChat: false,
    showShare: false,
    showTerms: false,
    showCallout: false,
    termsUrl: '',
  },
};

const variant = {
  [OFFERINGS.ADVISORY]: 'dark-blue',
  [OFFERINGS.ADVISORY_PACKAGE]: 'dark-blue',
  [OFFERINGS.COUPLES]: 'saturated-red',
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]: 'blue',
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]: 'blue',
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]: 'blue',
  [OFFERINGS.BOOST]: 'blue',
};

const textVariant = {
  [OFFERINGS.ADVISORY]: 'saturated-red',
  [OFFERINGS.ADVISORY_PACKAGE]: 'white',
  [OFFERINGS.COUPLES]: 'white',
  [OFFERINGS.ON_IT_TOGETHER_30_DAYS]: 'white',
  [OFFERINGS.ON_IT_TOGETHER_100_DAYS]: 'white',
  [OFFERINGS.LIOR_FRENKEL_AUDIO_COURSE]: 'white',
  [OFFERINGS.BOOST]: 'white',

};

const state = {
  initialized: false,
  oneOffOfferings: {},
  selectedOffering: {},
  installmentsCount: 1,
};

const getters = {
  offeringsTypes: () => OFFERINGS,
  showAdvisoryNightOffering: state => state.selectedOffering?.id === OFFERINGS.ADVISORY && moment().isBefore(moment(new Date('2024-02-16 09:00'))),
  fullPriceBeforeDiscount: state => fullPriceBeforeDiscount[state.selectedOffering?.id],
  displayItems: state => items[state.selectedOffering?.id],
  title: state => title[state.selectedOffering?.id],
  header: state => header[state.selectedOffering?.id],
  images: state => images[state.selectedOffering?.id],
  variant: state => variant[state.selectedOffering?.id],
  textVariant: state => textVariant[state.selectedOffering?.id],
  subHeader: state => subHeader[state.selectedOffering?.id],
  configs: state => config[state.selectedOffering?.id],
  paymentButtonTitle: state => paymentButtonTitle[state.selectedOffering?.id],
};

const actions = {
  async init({ state, dispatch, commit }) {
    if (!state.initialized) {
      await dispatch('getOfferings');
      commit('setInitialized', true);
    }
  },
  async getOfferings({ commit }) {
    const { oneOffOfferings } = await BillyApi.getOfferings();
    commit('setOneOffOfferings', oneOffOfferings);
  },
  async purchaseOneOffOffering({ state, commit }, { paymeToken }) {
    try {
      const { id } = state.selectedOffering;
      await BillyApi.purchaseOffering({
        token: paymeToken && paymeToken.token,
        oneOffOfferings: [{ offeringId: id, numberOfInstallments: state.installmentsCount }],
        subscriptionOfferings: [],
        bundleOfferings: [],
      });
      Segment.trackUserGot(paymeToken ? 'Advisory_Succeeded_Different_Card' : 'Advisory_Succeeded_Existing_Card', { offeringId: id });
    } catch (error) {
      DDLogs.errorBusinessEvent('Purchase one off offering failed', { error });
      throw new Error(error);
    }
  },
};

const mutations = {
  selectOffering(state, { offeringId }) {
    state.selectedOffering = _.find(state.oneOffOfferings, offering => offering.id === offeringId);
  },
  setInstallmentsCount(state, installmentsCount) {
    state.installmentsCount = installmentsCount;
  },
  setInitialized(state, initializedState) {
    state.initialized = initializedState;
  },
  setOneOffOfferings(state, oneOffOfferings) {
    state.oneOffOfferings = oneOffOfferings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
