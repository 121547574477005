<template>
  <card class="competition-card padding-vertical-ml" v-if="showCompetitionCard">
    <div class="title ri-bold-title">להמליץ להרוויח ולסדר את הבית</div>
    <tag class="dates ri-small-body" v-bind="tagProps"/>
    <label-counter :font-color="counterColor" variant="bold" label="חברות וחברים" label-second-line="שהצטרפו" :count="referralsCount.toString()" />
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import moment from 'moment';
import { mapGetters } from 'vuex';
import LabelCounter from './LabelCounter';

export default {
  name: 'CompetitionCard',
  components: {
    Card: BaseUI.Card,
    Tag: BaseUI.Tag,
    LabelCounter,
  },
  data() {
    return {
      counterColor: BaseUI.Colors.riseupWhite,
    };
  },
  computed: {
    ...mapGetters('referrals', ['competitionDates', 'competitionEvents', 'showCompetitionCard']),
    referralsCount() {
      return this.competitionEvents?.activations || 0;
    },
    tagProps() {
      const formattedStartDate = moment(this.competitionDates.start).format('D/MM');
      const formattedEndDate = moment(this.competitionDates.end).format('D/MM');
      return {
        text: `${formattedEndDate} - ${formattedStartDate}`,
        textColor: BaseUI.Colors.riseupBlack,
        backgroundColor: BaseUI.Colors.riseupLightBlue,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/spacings';

.competition-card {
  background-image: url('~@public/images/ambassadors/competition-banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  // important to override card background color
  background-color: $riseup-blue !important;

  ::v-deep.content {
    display: flex;
    flex-direction: column;
    gap: $ri-spacing-ml;

    .title {
      color: $riseup-yellow;
    }

    .dates {
      width: fit-content;
      height: 16px;
      // Using unusual padding (1px) to center the text vertically
      padding: 1px $ri-spacing-xs;
    }
  }
}

</style>
