<template>
  <div :class="['icon-container', color]" :style="{ '--icon-size': `${size}px` }">
    <img :src="`/images/plan-ahead/plan-image/${icon}.png`" alt="Plan icon"/>
  </div>
</template>

<script>
import PlansIconsMap, { PlanIconDefaultColor } from './plans-constanst';

export default {
  name: 'PlanIcon',
  props: {
    planType: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 48,
    },
  },
  computed: {
    color() {
      return (PlansIconsMap[this.planType] ? PlansIconsMap[this.planType].color : PlanIconDefaultColor);
    },
    icon() {
      return (PlansIconsMap[this.planType] ? PlansIconsMap[this.planType].icon : 'general');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors.scss";

.icon-container {
  height: var(--icon-size);
  width: var(--icon-size);
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @each $variant, $color in $variant-to-color {
    &.#{$variant} {
      background-color: $color;
    }
  }

  img {
    width: calc(var(--icon-size) - 8px);
    height: calc(var(--icon-size) - 8px);
  }
}
</style>
