<template>
  <div class="hever-ambassadors-card" ref="hever">
    <base-ambassadors-card :share-text="shareText" :segment-data="segmentData"
                      :share-button-title="'שיתוף עם עמיתי חֶבֶר'" :header-path="'ambassadors/hever-header.svg'" :link="heverLink">
      <template v-slot:content>
        <div class="info">
          <div class="title ri-bold-title">להעביר את זה הלאה</div>
          <div class="ri-body">
            שלחו המלצה על רייזאפ לעמיתי מועדון חֶבֶר ותוכלו לפרגן גם להם ב -
            <span class="bold">50% הנחה ל-6 חודשים</span> ועל הדרך <span class="bold">להרוויח עד ₪150</span>
          </div>
        </div>
      </template>
    </base-ambassadors-card>
  </div>
</template>

<script>
import BaseAmbassadorsCard from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/BaseAmbassadorsCard.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'HeverAmbassadorsCard',
  components: {
    BaseAmbassadorsCard,
  },
  mounted() {
    if (this.$route.query.partners_scroll) {
      this.$nextTick(() => {
        this.$refs.hever.$el.scrollIntoView({ behavior: 'smooth' });
      });
    }
  },
  computed: {
    ...mapGetters('ambassadors', ['icrc', 'heverLink']),
    segmentData() {
      return {
        icrc: this.icrc,
        shareName: 'ambassadorPartnersLink',
      };
    },
    shareText() {
      return 'היי,\n'
          + 'נראה לי שהשירות של רייזאפ יכול להתאים לך, ויש להם כרגע *מבצע מטורף לעמיתי ׳חבר׳ - 50% הנחה ל-6 חודשים והחודש הראשון חינם.*\n'
          + 'רוצה לנסות?';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.hever-ambassadors-card {
  .info {
    .title {
      margin-bottom: 8px;
    }
      .bold {
        font-weight: bold;
      }

  }
}

</style>
