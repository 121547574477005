<template>
  <div class="credit-card-details-form">
    <loader v-if="asyncLoading" class="cc-loader"/>
    <div v-else >
      <div v-if="!paymeLoadingError">
        <card-number-field/>
        <div class="row">
          <expiration-field class="expiration"/>
          <div class="spacer"></div>
          <cvv-field/>
        </div>
        <social-id/>
      </div>
      <div v-else class="init-error">
        <div>נראה שיש בעיה עם מערכת התשלום</div>
        <div>אפשר לרענן את העמוד ולנסות שוב</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loader from '@/base-components/Loader';
import CardNumberField from './CardNumberV1.vue';
import ExpirationField from './ExpirationV1.vue';
import CvvField from './CVVV1.vue';
import SocialId from './SocialIdV1.vue';

export default {
  name: 'CreditCardDetailsForm',
  components: {
    Loader,
    CardNumberField,
    ExpirationField,
    CvvField,
    SocialId,
  },
  data() {
    return {
      asyncLoading: true,
      paymeLoadingError: false,
    };
  },
  beforeDestroy() {
    this.teardownPaymeInterface();
  },
  async created() {
    await this.initPaymeInterface();
    if (!this.fieldsInstance) {
      this.paymeLoadingError = true;
      this.$emit('init-failed');
    }
    this.asyncLoading = false;
  },
  computed: {
    ...mapState('payment', ['fieldsInstance']),
  },
  methods: {
    ...mapActions('payment', ['initPaymeInterface', 'teardownPaymeInterface']),
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.row {
  display: flex;
  justify-content: space-between;
}

.expiration {
  margin-left: $ri-spacing-sm;
}

.cc-loader {
  margin-bottom: $ri-spacing-l;
}

.init-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  color: $riseup_danger_red;

  div {
    padding: $ri-spacing-xxs;
  }
}
</style>
