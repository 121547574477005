<template>
  <popup :close-action="closeAction" class="bank-cc-warning-popup" :buttons="buttons" :or-between-buttons="true">
    <template v-slot:content>
      <img :src="require('@/assets/red-eight-pointed-alert-flower.svg')" class="alert-icon-in-popup"/>
      <div class="title">
        <div>
          כדאי לשים לב:
        </div>
        <div>
          {{title}}
        </div>
      </div>
      <div class="subtitle">
        <span>בחיבור ישיר המערכת לא תקבל את פירוט עסקאות האשראי מהבנק. לכן,</span>
        <span v-if="multipleBankCCAccounts" class="bold">כדי לקבל תזרים מלא, צריך לחבר את חשבון האשראי בנפרד.</span>
        <span v-else class="bold">כדי לקבל תזרים מלא, צריך לחבר את חשבונות האשראי בנפרד.</span>
      </div>
    </template>
    <template v-slot:footer>
      <div class="footer-btn" @click="closeAction">ביטול וחזרה לתזרים</div>
    </template>
  </popup>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import Segment from '@/Segment';

export default {
  name: 'BankCCWarningPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  props: {
    bankCCAccounts: {
      type: Array,
      required: true,
    },
    openOBKConnectionFlow: {
      type: Function,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('OBKMigrationBankCCWarning_Entered', { sourceName: this.sourceName });
  },
  computed: {
    title() {
      return this.multipleBankCCAccounts > 1 ? 'יש כרטיסי אשראי שמקושרים לחשבון הבנק הזה.'
        : 'יש כרטיס אשראי שמקושר לחשבון הבנק הזה.';
    },
    multipleBankCCAccounts() {
      return this.bankCCAccounts.length;
    },
    sourceName() {
      return this.source.sourceName;
    },
    buttons() {
      return [{
        title: 'לחבר את החשבון בחיבור ישיר',
        size: 'slim',
        loading: this.loading,
        action: () => {
          Segment.trackUserGot('OBKMigrationBankCCWarning_ConnectOBKCliked', { sourceName: this.sourceName });
          this.openOBKConnectionFlow();
        },
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeAllModals']),
    closeAction() {
      Segment.trackUserGot('OBKMigrationBankCCWarning_CloseClicked', { sourceName: this.sourceName });
      this.closeAllModals();
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

  .bank-cc-warning-popup {
    text-align: right;

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 125px;
      margin-bottom: 15px;
    }

    .title {
      font-size: 22px;
      font-weight: bold;
    }

    .subtitle {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 16px;
    }

    .bold {
      font-weight: bold;
    }

    .footer-btn{
      display: flex;
      justify-content: center;
      font-size: 18px;
      cursor: pointer;
    }
  }

</style>
