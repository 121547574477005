<template>
  <div class="open-banking">
    <div>חיבור ישיר</div>
  </div>
</template>

<script>

export default {
  name: 'OpenBankingTag',
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .open-banking {
    font-size: 13px;
    line-height: 18px;
    color: $riseup_black;
    font-weight: normal;
    float: left;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 4px;
    height: 19px;
    padding: 1px 7px;
    border: 1px solid $riseup_gray_1;
  }
</style>
