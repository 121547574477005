<template>
  <div class="mislaka-share-consent">
    <img src="/images/advisory/mislaka-consent-header.svg" />
    <div class="padding-horizontal-ml content">
      <div class="ri-body top-title margin-bottom-sm margin-top-ml">רגע לפני שנמשיך</div>
      <div class="ri-large-headline margin-bottom-xs">כדי להשלים את התמונה, נצטרך גם את האישור של {{otherMemberFirstName}}.</div>
      <div class="ri-large-body margin-bottom-l">
        כל מה שצריך לעשות זה ללחוץ על הכפתור ולשתף עם {{otherMemberFirstName}} את הטופס לאישור פנייה למסלקה הפנסיונית.
      </div>
      <div class="btns-container padding-bottom-ml">
        <riseup-button class="button border-radius-medium margin-bottom-m" :action="shareToOther"
                       :size="'slim'"
                       :title="shareTitle"
                       icon="/images/advisory/advisory-share-white.svg"
        />
        <riseup-button class="button border-radius-medium" :action="backToCashflow"
                       :size="'slim'"
                       title="בחזרה לתזרים"
                       :variant="'secondary'"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import Segment from '@/Segment';
import config from '@/config';
import shareUtils from '@/utils/share';
import { mapGetters } from 'vuex';

export default {
  name: 'MislakaShareConsent',
  components: {
    RiseupButton: BaseUi.RiseupButton,
  },
  async created() {
    Segment.trackUserGot('MislakaShareConsent_Entered');
  },
  ...mapGetters('session', ['otherMemberFirstName']),
  computed: {
    ...mapGetters('session', ['householdName', 'otherMemberFirstName']),
    shareTitle() {
      return `לשתף עם ${this.otherMemberFirstName}`;
    },
  },
  methods: {
    async shareToOther() {
      Segment.trackUserInteraction('MislakaShareConsent_ShareClicked');
      const shareLinkUrl = `${config.get().elsaPublicUrl}/mislaka-consent?secondMember=true`;
      // eslint-disable-next-line max-len
      const text = `היי ${this.otherMemberFirstName},\n\n התחלנו תהליך ייעוץ אישי עם רייזאפ 💫\n\nבשביל מיפוי התמונה הכלכלית שלנו, עם כל הקרנות, הקופות והחסכונות, *צריך לתת אישור לקבלת מידע מהמסלקה הפנסיונית.*`;
      await shareUtils.shareLink(shareLinkUrl, text, {});
      this.$emit('next');
    },
    backToCashflow() {
      Segment.trackUserInteraction('MislakaShareConsent_BackToCashlfowClicked');
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

@import "~@riseupil/base-ui/src/scss/riseup-colors";

.mislaka-share-consent{
  text-align: right;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .top-title{
    color: $riseup_blue;
  }

  .content{
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .btns-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
}
</style>
