<template>
  <div class="terms-of-use ri-label">שיתוף הקישור מהווה הסכמה
    <a class="ri-bold-label" href="https://www.riseup.co.il/ambassadorsregulations" target="_blank">לתנאי התקנון</a>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
};
</script>

<style lang="scss" scoped>
@import "@riseupil/base-ui/src/scss/riseup-colors";
@import "@riseupil/base-ui/src/scss/spacings";

.terms-of-use {
  margin-top: $ri-spacing-ml;
  text-align: center;
  color: $riseup_gray_3;

  a {
    color: $riseup_gray_3;
  }

}
</style>
