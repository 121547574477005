<template>
  <toaster @close-toaster="closeSummaryToaster">
    <template v-slot:text>
      <span v-if="!isSaving">המערכת {{ predictionString }} את <span class="bold">{{ amountTypeString }}</span> בחודשים הבאים</span>
      <span v-else>{{ amountTypeString }} עברה ל<span class="bold">הפקדות לחיסכון</span></span>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../../base-components/Toaster';

export default {
  name: 'MightBeFixedToaster',
  components: {
    Toaster,
  },
  props: {
    isIncome: {
      type: Boolean,
      required: true,
    },
    isFixed: {
      type: Boolean,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    amountTypeString() {
      return this.isIncome ? 'ההכנסה' : 'ההוצאה';
    },
    predictionString() {
      return this.isFixed ? 'תצפה' : 'לא תצפה';
    },
  },
  methods: {
    closeSummaryToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
