import DDLogs from '@/DDLogs';
import { clientWithoutErrorHandling } from '../http';

const CHURN_PREDICTION_DATA_TIMEOUT_MS = 2000;

export default {
  async getChurnPrediction() {
    try {
      const response = await clientWithoutErrorHandling.get('/api/cashflow-models/churn/data', { timeout: CHURN_PREDICTION_DATA_TIMEOUT_MS });
      return response.data;
    } catch (error) {
      DDLogs.error('Error calling cashflow models service', { error });
      return { modelScore: null, behaviorHighlights: null, financialHighlights: null, personHighlights: null };
    }
  },
};
