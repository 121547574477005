import AdvisoryApi from '@/api/AdvisoryApi';

const STEPS_NUMBER = 6;
const PACKAGE_STEPS_NUMBER = 11;
const ANSWERS_LOCAL_STORAGE_KEY = 'advisory-questions';

const state = {
  initializedAdvisory: false,
  advisoryEligibilities: [],
  answers: {
    savings: {},
    future: {},
  },
};

const getters = {
  eligibleForAdvisory: state => state.advisoryEligibilities.length > 0,
  selectedAnswers: state => state.answers,
  stepsNumber: () => STEPS_NUMBER,
  packageStepsNumber: () => PACKAGE_STEPS_NUMBER,
  advisoryFlowSharedUTMParams: () => ({
    utm_source: 'in-product',
  }),
};

const actions = {
  async initAdvisory({ dispatch, commit }) {
    if (state.initializedAdvisory) {
      return;
    }
    await dispatch('fetchAdvisoryEligibilities');
    const answers = localStorage.getItem(ANSWERS_LOCAL_STORAGE_KEY);
    if (answers) {
      commit('setAnswers', JSON.parse(answers));
    }
    commit('setInitializedAdvisory', true);
  },
  async fetchAdvisoryEligibilities({ commit }) {
    const advisoryEligibilities = await AdvisoryApi.fetchAdvisoryEligibilities();
    commit('setAdvisoryEligibilities', advisoryEligibilities);
  },
  setSavingAnswer({ commit }, answer) {
    commit('setAnswer', { name: 'savings', answer });
  },
  setFutureAnswer({ commit }, answer) {
    commit('setAnswer', { name: 'future', answer });
  },
};

const mutations = {
  setInitializedAdvisory(state, initializedAdvisory) {
    state.initializedAdvisory = initializedAdvisory;
  },
  setAdvisoryEligibilities(state, advisoryEligibilities) {
    state.advisoryEligibilities = advisoryEligibilities;
  },
  setAnswer(state, { name, answer }) {
    const updatedDate = { ...state.answers, [name]: answer };
    localStorage.setItem(ANSWERS_LOCAL_STORAGE_KEY, JSON.stringify(updatedDate));
    state.answers[name] = answer;
  },
  setAnswers(state, answers) {
    state.answers = answers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
