import _ from 'lodash';
import moment from 'moment';
import { updateSignupOfferingStatusChosen } from '@/api/SignupOfferingsStatusApi';
import BillyApi from '@/api/BillyApi';
import SubscriptionStateApi from '@/api/SubscriptionStateApi';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import HundredDays from '@/store/modules/signup-offerings/hundred-days';
import ThirtyDays from '@/store/modules/signup-offerings/thirty-days';
import LiorFrenkelAudioCourse from '@/store/modules/signup-offerings/lior-frenkel-audio-course';
import Boost from '@/store/modules/signup-offerings/boost';
import { fillSubscriptionOfferingsDisplayInfo, SUBSCRIPTIONS_IDS } from '@/store/modules/signup-offerings/cashflow-subscription';
import { fillOneOffOfferingDisplayInfo } from '@/store/modules/signup-offerings/oneOff-Offerings';

const state = {
  plansDisplayInfo: {},
  subscriptionOfferingsDisplayInfo: {},
  initialized: false,
  subscriptionFirstBillingDate: null,
  signupOfferings: [],
  selectedSubscriptionOfferId: null,
};

const getters = {
  selectedSubscriptionOffering: state => state.subscriptionOfferingsDisplayInfo[state.selectedSubscriptionOfferId],
  hasSignupOfferings: state => !_.isEmpty(state.plansDisplayInfo),
  offeringCards: state => _
    .chain(state.plansDisplayInfo)
    .values()
    .map(offering => ({
      ...offering.offeringCard,
      name: offering.name,
      description: offering.description,
      id: offering.id,
      startDate: offering.startDate,
      skipPurchaseInfoPage: offering.skipPurchaseInfoPage,
    }))
    .value(),
  purchaseInfo: state => signupOfferingId => {
    const offering = state.plansDisplayInfo[signupOfferingId];
    return {
      ...offering.purchaseInfo,
      name: offering.name,
      description: offering.description,
      startDate: offering.startDate,
    };
  },
  purchasePageInfo: (state, getters) => signupOfferingId => {
    const offering = state.plansDisplayInfo[signupOfferingId];
    const subscriptionOffering = getters.selectedSubscriptionOffering;
    return {
      total: offering.freeAddOn ? subscriptionOffering.amount : offering.purchaseInfo.details.amount,
      subscriptionStartDate: subscriptionOffering.subscriptionStartDate.format('D.M.YYYY'),
      title: offering.insertPaymentDetailsPage.title,
      buttonCtaText: offering.insertPaymentDetailsPage.buttonCtaText,
      paymentDate: offering.freeAddOn ? subscriptionOffering.subscriptionStartDate.format('D.M.YYYY') : moment().format('D.M.YYYY'),
      subscriptionOfferingPrice: subscriptionOffering.amount,
    };
  },
  successPurchaseCheckedItems: state => signupOfferingId => {
    const offering = state.plansDisplayInfo[signupOfferingId];
    return offering?.successPurchasePageInfo?.checkedItems ?? [];
  },
  formattedFirstSubscriptionBillingDate: state => moment(state.subscriptionFirstBillingDate).format('D.M.YYYY'),
};

const actions = {
  async init({ state, getters, dispatch, commit }, { offerIds, featureFlagAvailableOfferings, subscriptionState }) {
    if (!state.initialized) {
      commit('setSignupOfferings', [new HundredDays(),
        new LiorFrenkelAudioCourse(),
        new ThirtyDays(subscriptionState),
        new Boost()]);
      const { subscriptionOfferings, oneOffOfferings } = await BillyApi.getOfferings();
      await dispatch('getAvailableSubscriptionOfferings', { subscriptionOfferings, subscriptionState });
      await dispatch('getAvailableOneOffOfferings', { offerIds, featureFlagAvailableOfferings, oneOffOfferings });
      const offeringsIds = getters.offeringCards.map(offering => offering.id);
      Segment.trackUserGot('AvailableOfferings_Initialized', { offerings: JSON.stringify(offeringsIds), isOfferIdInQueryParam: offerIds.length > 0 });
      await dispatch('getSubscriptionFirstBillingDate');
      commit('setInitialized', true);
    }
  },
  async updateSignupOfferingStatusChosen() {
    await updateSignupOfferingStatusChosen();
  },

  async getSubscriptionFirstBillingDate({ commit }) {
    const { firstBillingDate } = await SubscriptionStateApi.fetchFirstBillingDate();
    commit('setSubscriptionFirstBillingDate', firstBillingDate);
  },
  /**
   * @function getAvailableSubscriptionOfferings - Get the available subscription offerings
   * @param {subscriptionOfferings} - array of subscription offerings from the backend
   * @param {subscriptionState} - subscription state - pre-trial, trial, dormant, subscribed etc.
   */
  async getAvailableSubscriptionOfferings({ commit }, { subscriptionOfferings, subscriptionState }) {
    const subscriptionOfferingsDisplayInfo = fillSubscriptionOfferingsDisplayInfo(subscriptionOfferings, subscriptionState);
    commit('setSubscriptionOfferingsDisplayInfo', { subscriptionOfferings: subscriptionOfferingsDisplayInfo });
    // we currently support only monthly subscription.
    commit('setSelectedSubscriptionOfferId', { subscriptionOfferId: SUBSCRIPTIONS_IDS.MONTHLY });
  },
  /**
   * @function getAvailableOneOffOfferings - Get the available one-off offerings
   * @param {offerIds} - an array of the offer ids in the url, if the customer got through a specific offer
   * @param {featureFlagAvailableOfferings} - feature flag available offerings for a user
   * @param {oneOffOfferings} -  array of one-off offerings from the backend
   * */
  async getAvailableOneOffOfferings({ commit }, { offerIds, featureFlagAvailableOfferings, oneOffOfferings }) {
    const oneOffOfferingsIds = oneOffOfferings.map(offer => offer.id);
    const calculatedOfferings = await Promise.all(
      state.signupOfferings.map(async offer => {
        const hasOfferInBackend = oneOffOfferingsIds.includes(offer.purchaseInfo().details.offerId);
        return {
          ...offer,
          purchaseInfo: offer.purchaseInfo(),
          isAvailable: hasOfferInBackend && await offer.isAvailable({ offerIds, featureFlagAvailableOfferings }),
          startDate: await offer.startDate(),
        };
      }),
    );
    // If the query parameter includes offer IDs, display only the available offerings corresponding to these IDs.
    if (offerIds.length > 0) {
      const availableSignupOfferings = _.chain(calculatedOfferings)
        .filter(offer => offerIds.includes(offer.id))
        .filter(offer => offer.isAvailable)
        .map(offer => fillOneOffOfferingDisplayInfo(offer, oneOffOfferings))
        .keyBy('id')
        .value();
      if (!availableSignupOfferings) {
        DDLogs.log(`signup offerings with query param ids ${offerIds} not found in the available offerings.`);
        return;
      }
      commit('setPlansDisplayInfo', { offerings: availableSignupOfferings });
    } else {
      const availableSignupOfferings = _.chain(calculatedOfferings)
        .filter(offer => offer.isAvailable)
        .map(offer => fillOneOffOfferingDisplayInfo(offer, oneOffOfferings))
        .keyBy('id')
        .value();
      commit('setPlansDisplayInfo', { offerings: availableSignupOfferings });
    }
  },
  async purchaseSignupOffering({ state, getters }, {
    token,
    signupOfferingId,
  }) {
    const signupOffering = getters.purchaseInfo(signupOfferingId);
    const offeringsToPurchase = {
      subscriptionOfferings: [{
        offeringId: state.selectedSubscriptionOfferId,
      }],
      bundleOfferings: [],
      oneOffOfferings: [{
        offeringId: signupOffering.details.offerId,
      }],
    };

    await BillyApi.purchaseOfferings({
      token,
      ...offeringsToPurchase,
    });
  },
};

const mutations = {
  setSubscriptionOfferingsDisplayInfo(state, { subscriptionOfferings }) {
    state.subscriptionOfferingsDisplayInfo = subscriptionOfferings;
  },
  setSelectedSubscriptionOfferId(state, { subscriptionOfferId }) {
    state.selectedSubscriptionOfferId = subscriptionOfferId;
  },
  setPlansDisplayInfo(state, { offerings }) {
    state.plansDisplayInfo = offerings;
  },
  setSubscriptionFirstBillingDate(state, firstBillingDate) {
    state.subscriptionFirstBillingDate = firstBillingDate;
  },
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
  setSignupOfferings(state, signupOfferings) {
    state.signupOfferings = signupOfferings;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
