<template>
  <merge-investigation-popup class="mark-papas-as-approved"
                               :title="'מה יקרה עכשיו?'"
                             :back-action="cancel"
                             :footer-action="cancel"
                             :footer-title="'בטל'"
                              :show-contact-c-x-button="false">
    <template v-slot:content>
      <div class="content">
        <div class="mark-as-approved-warning">
          <div>
            <span>המערכת</span>
            <span class="bold">לא תשאל שוב לגבי החיוב.</span>
          </div>
          <div>הסכום הכולל ימשיך להופיע ללא הפירוט.</div>
        </div>
        <base-tool-tip class="tooltip" icon-path="/images/info-icon-blue.svg">
          <div class="info-text">
            <div class="bold">לאיזה מקרה זה מומלץ?</div>
            <ul class="bullets">
              <li>נראה לי שהכרטיס כבר מחובר</li>
              <li>לא מעניין אותי לראות את פירוט העסקאות</li>
              <li>זה החזר הלוואה</li>
            </ul>
          </div>
        </base-tool-tip>
        <riseup-button class="approve-button"
                       :title="'הבנתי, זו הכוונה שלי'"
                       :size="'slim'"
                       :action="markAllQuestionsAsApproved"
                       :variant="'primary'" />
      </div>
    </template>
  </merge-investigation-popup>
</template>

<script>

import BaseToolTip from '@/base-components/onboarding/BaseToolTip';
import MergeInvestigationPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/MergeInvestigationPopup';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import PapaMergeQuestionsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/PapaMergeQuestionsMixin';
import { mapActions } from 'vuex';

export default {
  name: 'ConfirmMarkPapasAsApproved',
  components: { BaseToolTip, MergeInvestigationPopup, RiseupButton: BaseUI.RiseupButton },
  mixins: [PapaMergeQuestionsMixin],
  props: {
    papaQuestions: { type: Array },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal', 'closeAllModals']),
    cancel() {
      Segment.trackUserInteraction('MergeQuestions_Mark_As_Approved_Cancel_Clicked', { source: this.$options.name });

      this.closeModal();
    },
    markAllQuestionsAsApproved() {
      Segment.trackUserInteraction('PapaMergeInsight_MarkAllAsApprovedClicked');
      this.papaQuestions.forEach(question => {
        this.approvePapaMergeQuestion(question);
      });
      this.closeAllModals();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.mark-papas-as-approved {
  .content{

    .mark-as-approved-warning {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 30px;
    }

    .info-text {
      font-size: 14px;
      line-height: 18px;

      .bullets{
        padding-right: 10px;
      }
    }

    .bold{
      font-weight: bold;
    }

    .approve-button {
      margin-top: 25px;
    }
  }
}

</style>
