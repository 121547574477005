import cashflowNormalizer from '../utilities/cashflow-normalizer';
import applicationStateApi from '../../api/ApplicationStateApi';
import customerState from '../../constants/customer-state';

const state = {
  notificationState: null,
  cashflowState: null,
  activationDate: null,
  barState: null,
};

const getters = {
  notificationTypes: () => customerState.NOTIFICATION_STATE,
  cashflowTypes: () => customerState.CASHFLOW_STATE,
  barTypes: () => customerState.BAR_STATE,
  isActiveNotification: state => state.notificationState && state.notificationState !== customerState.NOTIFICATION_STATE.NONE,
};

const actions = {
  async initApplicationState({ commit, dispatch }) {
    const applicationState = await applicationStateApi.fetchApplicationState();
    commit('setCashflowState', applicationState.cashflowState);
    dispatch('calcAndSetNotificationState', applicationState);
    cashflowNormalizer.setActivationDate(applicationState.activationDate);
    commit('setActivationDate', applicationState.activationDate);
    dispatch('cashflowView/resetBudgetTree', null, { root: true });
  },
  calcAndSetNotificationState({ commit, state, getters }, applicationState) {
    commit('setNotificationState', applicationState.notificationState);
  },
};

// mutations
const mutations = {
  setNotificationState(state, notificationState) {
    state.notificationState = notificationState;
  },
  setCashflowState(state, cashflowState) {
    state.cashflowState = cashflowState;
  },
  setBarState(state, barState) {
    state.barState = barState;
  },
  setActivationDate(state, activationDate) {
    state.activationDate = activationDate;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
