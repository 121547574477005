<template>
  <div :class="`profile-icon-container ${shape}`" :style="{backgroundColor, '--shape-size': size }">
    <colored-svg :color="whiteColor" :svg-src="shapePath"/>
  </div>
</template>

<script>
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';
import BaseUI from '@riseupil/base-ui';
import {
  colorVariantOptions,
  profileShapeColors,
  profileShapeShapes,
  shapeVariantsOptions,
} from './profileShape.consts';

export default {
  name: 'ProfileShape',
  components: {
    ColoredSvg: BaseUI.ColoredSvg,
  },
  data() {
    return {
      whiteColor: Colors.riseupWhite,
    };
  },
  props: {
    color: {
      type: String,
      required: true,
      validate: value => colorVariantOptions.includes(value),
    },
    shape: {
      type: String,
      required: true,
      validate: value => shapeVariantsOptions.includes(value),
    },
    size: {
      type: Number,
      default: 64,
    },
  },
  computed: {
    backgroundColor() {
      return profileShapeColors[this.color];
    },
    shapePath() {
      return profileShapeShapes[this.shape];
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(var(--shape-size) * 1px);
  height: calc(var(--shape-size) * 1px);
  border-radius: 50%;

  div {
    width: calc(var(--shape-size) / 2 * 1px);
    height: calc(var(--shape-size) / 2 * 1px);
  }
}
</style>
