<template>
  <riseup-button
    class="dd-button"
    :title="title"
    @click="action"
    :loading="loading"
    :disabled="disabled"/>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'DynamicDepositButton',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    action: Function,
    title: String,
    loading: Boolean,
    disabled: Boolean,
  },
};
</script>
