<template>
  <full-screen-modal :close-action="close">
    <template v-slot:content>
      <snack-bar class="plan-snack-bar" v-if="showSnackBar" variant="info">
        <template v-slot:primary>
          <div class="ri-bold-label padding-horizontal-ml">
            כדאי לעדכן את הסכום החודשי בתפריט <u class="edit-link" @click="openEditPlanFlow">העריכה</u>
          </div>
        </template>
      </snack-bar>
      <dashboard-page class="plan-edit-page" :image-header-props="imageHeaderProps">
        <template v-slot:header>
          <div class="header-container">
            <div class="image-container">
              <img class="plan-image" :src="`/images/plan-ahead/plan-image/${image}.png`"/>
            </div>
            <div class="header-text ri-large-headline ri-alte">
              <div>{{ currentPlan.name }}</div>
              <div>{{ targetAmount }} ₪</div>
              <div class="ri-body">{{ deadline }} · {{ monthlyTarget }}  ₪ בחודש</div>
            </div>
          </div>
        </template>
        <template v-slot:dashboard>
          <div class="plan-progress">
            <progress-bar
              :left-amount="currentPlan.targetAmount" left-label="היעד שלי" right-label="שמתי בצד"
              :right-amount="currentPlan.currentAmount" cashflow-category="variable" />
            <plan-status-note class="note"
                              size="small"
                              isBold
                              :text="noteText"
                              :is-on-track="isPlanOnTrack"
                              :textColor="textColor"
                              :planCompleted="planCompleted"
                              :cashflowIsNegative="cashflowIsNegative" />
          </div>
        </template>
        <plan-history class="history" :plan="plan" />
      </dashboard-page>
    </template>
  </full-screen-modal>
</template>

<script>
import FullScreenModal from '@/base-components/FullScreenModal';
import { mapActions, mapState, mapGetters } from 'vuex';
import BaseUi from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';
import dateUtils from '@/utils/dates';
import Segment from '@/Segment';
import moment from 'moment';
import ProgressBar from '../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/ProgressBar';
import PlanStatusNote from './PlanStatusNote';
import PlansIconsMap, { PlanIconDefaultColor } from './plans-constanst';
import EditPlanFlow from './plans-flow/EditPlanFlow';
import PlanHistory from './history/PlanHistory';

const baseImageHeaderProps = {
  imgHeight: 450,
  bottomSeparator: false,
};
const actionButtonProps = {
  text: 'עריכה',
  overrideIconProps: {
    iconName: 'edit',
    fill: true,
    size: '14px',
    opticalSize: 24,
  },
  showLeftArrow: false,
  textColor: BaseUi.Colors.riseupWhite,
  borderColor: BaseUi.Colors.riseupWhite,
  backgroundColor: 'transparent',
};
export default {
  name: 'PlanDetailsPage',
  components: {
    PlanStatusNote,
    FullScreenModal,
    ProgressBar,
    PlanHistory,
    SnackBar: BaseUi.SnackBar,
    DashboardPage: BaseUi.Pages.DashboardPage,
  },
  props: {
    plan: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      buttonColor: BaseUi.Colors.riseupWhite,
    };
  },
  async created() {
    Segment.trackUserGot('PlanAhead_PlanPage_Shown');
    await this.updateCurrentPlan(this.plan);
  },
  computed: {
    ...mapState('planAhead', ['currentPlan']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    imageHeaderProps() {
      const deadlineIsInTheFuture = moment(this.plan.deadline).isAfter(moment(), 'month');
      return {
        ...baseImageHeaderProps,
        backgroundColor: this.color,
        actionButtonProps: deadlineIsInTheFuture || !this.planCompleted ? {
          ...actionButtonProps,
          onClick: this.openEditPlanFlow,
        } : undefined,
      };
    },
    image() {
      return PlansIconsMap[this.currentPlan.type] ? PlansIconsMap[this.currentPlan.type].icon : 'general';
    },
    deadline() {
      return dateUtils.getMonthAndYear(this.plan.deadline);
    },
    monthlyTarget() {
      return moneyUtils.formatAmountWithFraction(this.currentPlan.monthlyTarget, 0);
    },
    targetAmount() {
      return moneyUtils.formatAmountWithFraction(this.plan.targetAmount, 0);
    },
    isPlanOnTrack() {
      if (this.plan.currentAmount >= this.plan.targetAmount) {
        return true;
      }
      return this.plan.isOnTrackThisMonth && this.plan.onTrack;
    },
    textColor() {
      if (this.planCompleted && this.isPlanOnTrack) {
        return 'green';
      }
      if (this.planCompleted && !this.isPlanOnTrack) {
        return 'black';
      }
      if (this.cashflowIsNegative) {
        return 'black';
      }

      return this.isPlanOnTrack ? 'green' : 'black';
    },
    noteText() {
      if ((this.planCompleted) && (this.isPlanOnTrack)) {
        return 'הגעת ליעד של התוכנית!';
      }
      if ((this.planCompleted) && (!this.isPlanOnTrack)) {
        return 'הגעת למועד התוכנית';
      }
      if (this.cashflowIsNegative) {
        return 'התוכנית תופעל בתזרים החיובי הבא';
      }
      if ((this.planOngoing) && (this.isPlanOnTrack)) {
        return 'נראה שהכל בכיוון הנכון';
      }
      if ((this.planOngoing) && (!this.isPlanOnTrack)) {
        return 'נראה שלא יהיה אפשר להגיע לסכום במועד שרצית';
      }

      return '';
    },
    planCompleted() {
      return this.plan.state === 'done';
    },
    planOngoing() {
      return this.plan.state === 'ongoing';
    },
    color() {
      return PlansIconsMap[this.plan.type] ? PlansIconsMap[this.plan.type].color : PlanIconDefaultColor;
    },
    cashflowIsNegative() {
      return this.predictedBalanceAtEndOfMonth < 0;
    },
    showSnackBar() {
      return !this.planCompleted && !this.plan.onTrack;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('planAhead', ['updatePlan', 'updateCurrentPlan', 'resetCurrentPlan', 'deletePlan']),
    async editPlan() {
      await this.updatePlan();
    },
    async openEditPlanFlow() {
      Segment.trackUserInteraction('PlanAhead_PlanPage_Edit_Clicked');
      await this.closeModal();
      this.openModal({
        component: EditPlanFlow,
        props: {
          onDone: this.editPlan,
          plan: this.plan,
        },
        popupAlignment: 'full-screen',
      });
    },
    async close() {
      await this.closeModal();
      await this.resetCurrentPlan();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.plan-snack-bar {
  width: 100%;

  .edit-link {
    cursor: pointer;
  }
}

.plan-edit-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  position: relative; // to allow child menu to be positioned on top

  .header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin-top: $ri-spacing-ml;
    }

    .plan-image {
      height: 210px;
    }

    .header-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: right;
      margin-bottom: $ri-spacing-ml;
    }
  }

  .data-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .plan-progress {
    width: auto;
    text-align: right;
    margin: $ri-spacing-ml $ri-spacing-ml $ri-spacing-sm $ri-spacing-ml;
    .note {
      display: flex; justify-content: flex-end;
      margin-top: 15px;
      text-align: left;
    }
  }

  .history {
    flex: 1;
  }

  .history {
    margin-top: $ri-spacing-l;
  }
}

</style>
