<template>
  <toaster @close-toaster="closeToaster" class="insight-feedback-sent-toaster">
    <template v-slot:text>
      <div>
        <span class="bold">הפידבק נשלח.</span>
        <span>תודה!</span>
      </div>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'InsightFeedbackSentToaster',
  components: {
    Toaster,
  },
  methods: {
    closeToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
.insight-feedback-sent-toaster{
  .bold{
    font-weight: bold;
  }
}

</style>
