<template>
  <div class="expandable-summary-row" :class="{'out-of-date': outOfDate, 'expanded': isExpanded }" tabindex="-1">
    <div class="sections-label" :class="{'predicted-category-expanded-shadow': isExpanded && withShadow,
     'not-clickable': !isExpandable}"
         v-on:click="toggleExpend()"
         @keyup.enter.stop="toggleExpend()"
         role="button"
         tabindex="0">
      <div class="header-text" :class="textStyle"  v-if="headerText">
        {{ headerText }}
        <Icon class="update-indicator padding-right-xs" :class="{visible: hasUpdate}" icon-name="circle" fill size="8px" :color="indicatorColor"/>
      </div>
      <slot name="header"></slot>
      <div class="flex-row">
        <expand-arrow v-if="isExpandable" :expanded="expanded" :greyed-out="outOfDate" :size="arrowSize"/>
        <Icon v-if="!headerText" class="update-indicator padding-right-xs slot" :class="{visible: hasUpdate}"
              icon-name="circle" fill
              size="8px"
              :color="indicatorColor"/>
      </div>
    </div>
    <collapse-transition>
      <div v-show="isExpanded">
        <slot name="content"></slot>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import _ from 'lodash';
import ExpandArrow from '../../../../../../../../../base-components/ExpandArrow';

export default {
  name: 'ExpandableSummaryRow',
  components: {
    ExpandArrow,
    CollapseTransition: BaseUI.CollapseTransition,
    Icon: BaseUI.Icon,
  },
  props: {
    headerText: {
      type: String,
      required: false,
    },
    isExpandable: {
      type: Boolean,
      default: true,
    },
    initiallyExpanded: {
      type: Boolean,
      default: false,
    },
    outOfDate: {
      type: Boolean,
      default: false,
    },
    hasUpdate: {
      type: Boolean,
      default: false,
    },
    arrowSize: {
      type: String,
      required: false,
      validator: val => ['medium', 'small'].includes(val),
    },
    textStyle: {
      type: String,
      required: false,
      validator: val => ['medium', 'small'].includes(val),
      default: 'small',
    },
    withShadow: {
      type: Boolean,
      required: false,
      default: true,
    },
    type: {
      type: String,
      required: false,
      default: 'not-undefined',
    },
    cardTypeId: {
      type: String,
      required: false,
    },
    isSubCategory: {
      type: Boolean,
      required: false,
      default: false,
    },
    itemsKeys: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
      indicatorColor: BaseUI.Colors.riseupDarkGreen,
    };
  },
  created() {
    EventBus.$on('expand-to-edit', this.onExpandToEdit);
    EventBus.$on('scroll-to', this.scrollToElement);
  },
  beforeDestroy() {
    EventBus.$off('expand-to-edit', this.onExpandToEdit);
    EventBus.$off('scroll-to', this.scrollToElement);
  },
  computed: {
    isExpanded() {
      return this.expanded && this.isExpandable;
    },
  },
  methods: {
    toggleExpend() {
      if (this.outOfDate) {
        return;
      }
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.$emit('expanded', this.hasUpdate);
      }
    },
    onExpandToEdit({ type, cardTypeId, noScroll, selectedItemKey }) {
      // If there was no selection sent to this function then contains selection needs to be true
      const containsSelection = _.isNil(selectedItemKey) || _.isNil(this.itemsKeys) || this.itemsKeys.includes(selectedItemKey);
      const isProperType = type === this.type || cardTypeId === this.cardTypeId;

      if (isProperType && containsSelection) {
        this.expanded = true;
        if (!noScroll) {
          this.$nextTick(() => setImmediate(() => this.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })));
        }
      }
    },
    scrollToElement({ type }) {
      if (type === this.type) {
        this.$nextTick(() => setImmediate(() => this.$el.scrollIntoView({ behavior: 'smooth', block: 'center' })));
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/riseup-vars';
@import '../../../../../../../../../scss/mixins';

.expandable-summary-row {
  .sections-label {
    color: $riseup_black;
    display: grid;
    width: 100%;
    cursor: pointer;
    font-size: 14px;
    grid-template-columns: 1fr 24px;
    column-gap: 10px;
    padding: 0 17px 0 13px;
    align-items: center;

    .header-text {
      font-weight: bold;
      font-size: 14px;
      padding: 18px 7px 18px 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      &.medium {
        font-size: 18px;
      }
    }

    &.not-clickable {
      cursor: default;
    }

    &.predicted-category-expanded-shadow {
      margin-bottom: 3px;
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    }
  }

  &.out-of-date {
    color: $riseup_gray_2;
    .header-text {
      color: $riseup_gray_2;
    }
    .sections-label {
      cursor: default;
    }
  }

  .update-indicator {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.visible {
      opacity: 1;
    }
  }
}

</style>
