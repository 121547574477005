import { client, clientWithoutErrorHandling } from '../http';

export default {
  async createBenefitByPromoCode(promoCode) {
    const response = await client.post('/api/partial-auth/benefits/promo-code', { promoCode });
    return response.data;
  },

  async createBenefitByPromoSource(promoSource, pending) {
    const response = await clientWithoutErrorHandling.post('/api/benefits/promo-source', { promoSource, pending });
    return response.data;
  },

  async getEligibleBenefits() {
    const response = await client.get('/api/benefits/eligible');
    return response.data;
  },

  async getActiveBenefit() {
    const response = await client.get('/api/benefits/active');
    return response.data;
  },

  async redeemBenefit(benefitId) {
    const response = await client.put(`/api/benefits/redeem/${benefitId}`);
    return response.data;
  },

  async dismissMoneyBackGuarantee() {
    const response = await client.post('/api/subscription-state/money-back/dismissed');
    return response.data;
  },

  async offeredMoneyBackGuarantee() {
    const response = await client.post('/api/subscription-state/money-back/offered');
    return response.data;
  },

  async isBenefitInSource(sourceName, benefitPromotion) {
    const response = await client.get(`/api/benefits/${benefitPromotion}/in-source/${sourceName}`);
    return response.data;
  },

  async createAndApplyBenefit(promotionName) {
    const response = await client.post('/api/create-and-apply-benefit', { benefit: promotionName });
    return response.data && response.data[0];
  },
};
