import moment from 'moment';

const COOL_DOWN_DURATION_IN_MONTHS = 1;
const MIN_DAYS_SINCE_TRIAL = 5;
const MAX_DAYS_SINCE_TRIAL = 60;

const state = {
  lastShown: null,
  trialDismissed: false,
};

const getters = {
  shouldShowTouchPoint: state => !state.lastShown || moment().diff(moment(state.lastShown), 'month') >= COOL_DOWN_DURATION_IN_MONTHS,
};

const actions = {
  shouldShowTrialPromotion({ state, getters }, { inTrialSince }) {
    return !state.trialDismissed
            && moment().diff(moment(inTrialSince), 'days') >= MIN_DAYS_SINCE_TRIAL
            && moment().diff(moment(inTrialSince), 'days') <= MAX_DAYS_SINCE_TRIAL;
  },
  init({ commit }) {
    const lastShown = localStorage.getItem('riseup.ambassadorsCtaLastShown');
    const trialDismissed = localStorage.getItem('riseup.trialAmbassadorsPromotionDismissed') === 'true';
    commit('setLastShown', lastShown);
    commit('setTrialDismissed', trialDismissed);
  },
  updateLastShown({ commit, state }) {
    commit('setLastShown', new Date());
    localStorage.setItem('riseup.ambassadorsCtaLastShown', state.lastShown);
  },
  dismissTrialPromotion({ commit }) {
    commit('setTrialDismissed', true);
    localStorage.setItem('riseup.trialAmbassadorsPromotionDismissed', true);
  },
};

// mutations
const mutations = {
  setLastShown(state, lastShown) {
    state.lastShown = lastShown;
  },
  setTrialDismissed(state, trialDismissed) {
    state.trialDismissed = trialDismissed;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
