const InvalidReasons = {
  InvalidCredentialsTag: 'InvalidCredentialsTag',
  AccountIsBlockedTag: 'AccountIsBlockedTag',
  RequiresPasswordChange: 'RequiresPasswordChange',
  Multiple: 'multiple',
};

const ConnectedCredentialsCardInvalid = {
  [InvalidReasons.InvalidCredentialsTag]: 'הפרטים לא מעודכנים',
  [InvalidReasons.AccountIsBlockedTag]: 'הגישה לחשבון נחסמה',
  [InvalidReasons.RequiresPasswordChange]: 'הסיסמה לא בתוקף',
};

const CredentialsDetailsInvalid = {
  InvalidCredentials: 'נראה שאחד מפרטי החיבור לא נכון',
  PasswordExpired: 'הסיסמה לא בתוקף',
  AccountBlocked: 'החיבור לחשבון נחסם',
  Unknown: 'קרתה תקלה בשמירת הנתונים, אפשר לנסות שוב',
};

export default {
  InvalidReasons,
  ConnectedCredentialsCardInvalid,
  CredentialsDetailsInvalid,
};
