<template>
  <div class="advisory-package-intro-1 padding-bottom-ml">
    <div class="content">
      <div class="padding-horizontal-ml">
        <advisory-top-header @close="close" @back="close" variant="blue"/>
        <Stepper :current-step="1" :total-steps="packageStepsNumber"/>
        <div class="ri-title margin-top-ml">
          חבילת ייעוץ אישי ברייזאפ
        </div>
        <div class="ri-small-display margin-top-ml ri-alte-bold">
          <span>בחבילת הייעוץ החדשה שלנו, נבנה לך</span>
          <span class="pink">תוכנית אישית לשנים הבאות</span>
        </div>
        <img src="/images/advisory/package-tomer-story.svg" class="tomer-image margin-top-l"/>
      </div>
    </div>
    <div class="padding-horizontal-ml">
      <riseup-button :action="next"
                     :size="'slim'"
                     title="להמשיך"
                     :variant="'primary'"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapGetters } from 'vuex';
import Segment from '@/Segment';
import AdvisoryTopHeader from '../AdvisoryTopHeader';

export default {
  name: 'AdvisoryPackageIntro1',
  components: {
    AdvisoryTopHeader,
    RiseupButton: BaseUI.RiseupButton,
    Stepper: BaseUI.Stepper,
  },
  created() {
    Segment.trackUserGot('AdvisoryPackageIntro1');
  },
  methods: {
    next() {
      this.$emit('next');
    },
    close() {
      this.$emit('close');
    },
  },
  computed: {
    ...mapGetters('advisory', ['packageStepsNumber']),
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.advisory-package-intro-1 {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #142AA0;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    text-align: start;
    color: $riseup_white;

    .pink {
      color: #F89BB4;
    }

    .tomer-image {
      width: 100%;
    }
  }
}
</style>
