import Vue from 'vue';
import _ from 'lodash';
import consts from '../utilities/constants';
import InvestigatorQuestionsApi from '../../api/InvestigatorQuestionsApi';

const state = {
  predictedIncomeQuestions: [],
  actualIncomeQuestions: [],
};

const getters = {
  fixedIncomes: state => _.filter(state.actualIncomeQuestions, { isFixed: true }),
  canceledIncomes: state => _.filter(state.predictedIncomeQuestions, { isFixed: false }),
  numOfFixed: (state, getters) => _.filter(state.predictedIncomeQuestions, { isFixed: true }).length + getters.fixedIncomes.length,
  incomeInputs: (state, getters) => ({
    [consts.INPUT_TYPES.BUDGET_CATEGORY_INPUT]: _.map(getters.fixedIncomes, createFixedExpenseInput),
    [consts.INPUT_TYPES.CANCEL_ENVELOPE_INPUT]: _.map(getters.canceledIncomes, createCanceledEnvelopeInput),
  }),
};

const actions = {
  async fetchIncomeQuestions({ commit }) {
    const incomeQuestions = await InvestigatorQuestionsApi.fetchIncomeQuestions();

    const actualIncomeQuestions = _.chain(incomeQuestions)
      .filter({ type: 'actual' })
      .map(question => ({ ...question, isFixed: false }))
      .value();
    commit('setActualIncomeQuestions', actualIncomeQuestions);

    const predictedIncomeQuestions = _.chain(incomeQuestions)
      .filter({ type: 'predicted' })
      .map(question => ({ ...question, isFixed: true }))
      .value();
    commit('setPredictedIncomeQuestions', predictedIncomeQuestions);
  },
};

function createFixedExpenseInput(actualExpenseQuestion) {
  return { transactionId: actualExpenseQuestion.transactionId, budgetCategory: 'fixed' };
}

function createCanceledEnvelopeInput(predictedExpenseQuestion) {
  return { envelopeId: predictedExpenseQuestion.envelopeId };
}

const mutations = {
  setActualIncomeQuestions(state, actualIncomeQuestions) {
    state.actualIncomeQuestions = actualIncomeQuestions;
  },
  setPredictedIncomeQuestions(state, predictedIncomeQuestions) {
    state.predictedIncomeQuestions = predictedIncomeQuestions;
  },
  setActualIsFixed(state, { transactionId, isFixed }) {
    const actual = _.find(state.actualIncomeQuestions, { transactionId });
    Vue.set(actual, 'isFixed', isFixed);
  },
  setPredictedIsFixed(state, { envelopeId, isFixed }) {
    const actual = _.find(state.predictedIncomeQuestions, { envelopeId });
    Vue.set(actual, 'isFixed', isFixed);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
