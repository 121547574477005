import _ from 'lodash';

function isMobile() {
  const mobileAgents = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];
  return _.some(mobileAgents, toMatchItem => navigator.userAgent.match(toMatchItem));
}

function isIPhone() {
  return navigator.userAgent.match(/iPhone/i);
}

export default {
  isMobile,
  isIPhone,
};
