import LoginApi from '@/api/LoginApi';
import Segment from '@/Segment';
import DDLogs from '../../DDLogs';

const state = {
  socketMessage: '',
};

const mutations = {
  socket_connect(state) {
    DDLogs.log('Socket Connect');
  },

  socket_versionUpdated(state, message) {
    DDLogs.log(`Socket version updated ${message}`);
    state.socketMessage = message;
  },

  async socket_disconnect(state, reason) {
    DDLogs.log(`Socket Disconnect - reason ${reason}`);
    if (reason === 'io server disconnect') {
      Segment.trackUserGot('TempSocketDisconnectServerIO');
      await LoginApi.logCookies();
    }
  },

  socket_error(state, error) {
    DDLogs.log(`Socket error ${error}`);
  },

  socket_reconnect(state, reconnect) {
    DDLogs.log('Socket reconnect', reconnect);
  },

  socket_reconnect_error(state, error) {
    DDLogs.log(`Socket reconnect error ${error}`);
  },

  socket_reconnect_failed(state) {
    DDLogs.log('Socket reconnect failed');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
