<template>
  <div class="counter-wrapper" :style="{'--counter-font-color': fontColor}">
    <div class="number-wrapper">
      <span :class="variant === 'bold' ? 'ri-medium-bold-headline' : 'ri-medium-headline'">
        {{ count }}
      </span>
      <span v-if="isPayment" class="payment-symbol ri-body">₪</span>
    </div>
    <span class="ri-label">
      {{ labelInTwoLines[0] }}
    </span>
    <span class="ri-label">
      {{ labelInTwoLines[1] }}
    </span>
  </div>
</template>

<script>
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';

export default {
  name: 'LabelCounter',
  props: {
    count: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    labelSecondLine: {
      type: String,
      required: false,
    },
    isPayment: {
      type: Boolean,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'normal',
      validate: val => ['normal', 'bold'].includes(val),
    },
    fontColor: {
      type: String,
      required: false,
      default: Colors.riseupBlack,
    },
  },
  computed: {
    labelInTwoLines() {
      if (this.labelSecondLine) {
        return [this.label, this.labelSecondLine];
      }
      const [first, ...rest] = this.label.split(' ');
      const second = rest.join(' ');
      return [first, second];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';

.counter-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--counter-font-color);

  .number-wrapper {
    display: flex;
    flex-direction: row;

    .payment-symbol {
      align-self: flex-end;
    }
  }
}
</style>
