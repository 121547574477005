<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AddCredentialsFlow from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';

export default {
  name: 'PapaMergeQuestionMixin',
  computed: {
    ...mapState('mergeInvestigationQuestions', ['papaInputTypes']),
    ...mapGetters('mergeInvestigationQuestions', ['allPapasAnswered']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeAllModals']),
    ...mapActions('editCashflow', ['setComment']),
    ...mapActions('mergeInvestigationQuestions', ['setClassifiedPapaQuestionInput']),
    ...mapActions('actions', ['setSetupAccountActionCheckedState']),

    approvePapaMergeQuestion(question) {
      this.answerPapaMergeQuestion({ question, input: this.papaInputTypes.approved });
    },
    addCard({ question, sourceType, sourceName, onSuccess }) {
      this.openModal({
        component: AddCredentialsFlow,
        props: {
          sourceNameToAdd: sourceName,
          allowBackToSourceSelection: false,
          knownSourceType: sourceType,
        },
        eventHandlers: {
          success: () => {
            this.answerPapaMergeQuestion({ question, input: this.papaInputTypes.addCreds });
            if (onSuccess) {
              onSuccess();
            }
          },
          timeout: () => {
            this.closeAllModals();
          },
        },
        showLastModal: true,
        popupAlignment: 'full-screen',
      });
    },
    markPapaMergeQuestionAsLoan(question) {
      this.answerPapaMergeQuestion({ question, input: this.papaInputTypes.loan });
      this.setComment({ transactionId: question.transactionId, comment: 'החזר הלוואה' });
    },
    async answerPapaMergeQuestion({ question, input }) {
      await this.setClassifiedPapaQuestionInput({ question, input });
      this.setSetupAccountActionCheckedState({ allPapaQuestionsAnswered: this.allPapasAnswered });
    },
  },
};
</script>
