// sentry logging

import Vue from 'vue';
import _ from 'lodash';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import config from './config';

const BLACKLISTED_DOMAINS = ['segment.io', 'mixpanel', 'datadog'];

const ERRORS_TO_IGNORE = [
  'Request failed with status code 403',
  'Invalid phone number',
];

/* eslint-disable */
const SentryModule = {
  init() {
    if (config.get().sentryEnabled) {
      Sentry.init({
        dsn: config.get().sentryDsn,
        integrations: [
          new Integrations.Vue({Vue, attachProps: true, logErrors: config.get().logErrors === 'true'}),
          new Integrations.CaptureConsole({ levels: ['error']})
        ],
        beforeBreadcrumb(breadcrumb, hint) {
          if (breadcrumb.category === 'xhr' &&
            (_isDomainBlacklisted(breadcrumb.data.url) || _isInvalidToken(breadcrumb))) {
            return null;
          }
          if (_mightContainSensitiveData(breadcrumb)) {
            return null;
          }
          return breadcrumb;
        },
        ignoreErrors: ERRORS_TO_IGNORE,
      })
    }
  },
  addBreadcrumb(category, message, level) {
    Sentry.addBreadcrumb({
      category,
      message,
      level,
    });
  },
  initCustomerId(customerId) {
    Sentry.setTag('customerId', customerId );
  },
};

function _mightContainSensitiveData(breadcrumb) {
  // Don't log errors from these components to sentry since they might contain
  // extra vue fields which include it's props with the customer password
  return (breadcrumb.category === 'sentry'
    && (_.includes(breadcrumb.message, 'CredentialsInputField.vue') || _.includes(breadcrumb.message, 'SecretInputField.vue')));
}

function _isInvalidToken(breadcrumb) {
  return breadcrumb.data.status_code === 403;
}

function _isDomainBlacklisted(url) {
  return _.some(BLACKLISTED_DOMAINS, domain => {
      return _.includes(url, domain);
    });
}

export default SentryModule;
