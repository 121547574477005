<template>
  <div class="redirect-to-open-banking-popup" :class="{ loading }">
    <popup :buttons="buttons" :close-action="emitCloseEvent">
      <template v-slot:title>
        <img :src="require('@/assets/red-eight-pointed-alert-flower.svg')" class="alert-icon-in-popup"/>
        <div class="popup-title">
          <div>חשוב לדעת,</div>
          <div>{{ title }}</div>
        </div>
      </template>
      <template v-slot:content>
        <component :is="descriptionComponent" :source="source"/>
      </template>
    </popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import OpenBankingDefaultDescription from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/OpenBankingDefaultDescription';
import OpenBankingIsracardDescription from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/OpenBankingIsracardDescription';
import OpenBankingLeumiDescription from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/OpenBankingLeumiDescription';
import OpenBankingMizrahiDescription from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/OpenBankingMizrahiDescription';
import OpenBankingPepperDescription
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/OpenBankingPepperDescription';
import OpenBankingAmexDescription
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/OpenBankingAmexDescription';
import OpenBankingBankDescription
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/OpenBankingBankDescription';
import ConsentActionsMixin from '@/base-components/onboarding/openBanking/ConsentActionsMixin';
import Segment from '../../../Segment';

export default {
  name: 'RedirectToOpenBankingPopup',
  mixins: [ConsentActionsMixin],
  props: {
    idNumber: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
    credentialsToUpdateMetaData: {
      type: Object,
      required: false,
    },
  },
  async created() {
    Segment.trackUserGot('RedirectToOBKPopup', { idNumber: this.idNumber, source: this.source.sourceName });
  },
  data() {
    return {
      loading: false,
    };
  },
  components: {
    Popup: BaseUI.Popup,
  },
  computed: {
    title() {
      return this.source.openBanking.redirectPopup.title;
    },
    buttons() {
      return [
        {
          title: this.source.openBanking.redirectPopup.buttonTitle,
          variant: 'primary',
          action: () => {
            this.loading = true;
            this.redirectToGetAccessCode({
              idNumber: this.idNumber,
              source: this.source,
              name: this.name,
              credentialsToUpdateMetaData: this.credentialsToUpdateMetaData,
            });
          },
          loading: this.loading,
          size: 'slim',
        },
      ];
    },
    sourceToDescriptionMapping() {
      return {
        isracard: OpenBankingIsracardDescription,
        leumi: OpenBankingLeumiDescription,
        mizrahi: OpenBankingMizrahiDescription,
        pepper: OpenBankingPepperDescription,
        amex: OpenBankingAmexDescription,
        poalim: OpenBankingBankDescription,
        yahav: OpenBankingBankDescription,
        massad: OpenBankingBankDescription,
        benleumi: OpenBankingBankDescription,
        ubank: OpenBankingBankDescription,
        pagi: OpenBankingBankDescription,
        otsar: OpenBankingBankDescription,
      };
    },

    descriptionComponent() {
      return this.sourceToDescriptionMapping[this.source.id] || OpenBankingDefaultDescription;
    },
  },
  methods: {
    emitCloseEvent() {
      Segment.trackUserInteraction('RedirectToOpenBankingPopup_CloseClicked', { idNumber: this.idNumber, source: this.source.sourceName });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

  .redirect-to-open-banking-popup {

    &.loading{
      opacity: 0.5;
      pointer-events: none;
    }

    .popup-title {
      font-family: SimplerPro;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 32px;
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 125px;
    }

    ::v-deep .riseup-button.slim .button-titles .button-title {
      font-size: 16px;
    }
  }
</style>
