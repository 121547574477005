<script>

import { mapGetters, mapState } from 'vuex';

const Flavors = {
  accountSettings: 'account-settings',
  subscriptionType: 'subscription-type',
  creditCard: 'credit-card',
  signupOffering: 'signup-offering',
  signup: 'signup',
  signupTimeline: 'signup-timeline',
};

export default {
  name: 'BenefitMixin',
  computed: {
    ...mapState('benefits', ['benefitType', 'benefitAmount', 'benefitPercentage', 'redeemed']),
    ...mapGetters('benefits', ['BENEFIT_TYPES', 'discountedPrice', 'monthsText']),
    isGenericFreeMonthBenefit() {
      return this.benefitType === this.BENEFIT_TYPES.FREE_MONTHS && !this.redeemed;
    },
    isPercentageDiscountBenefit() {
      return this.benefitType === this.BENEFIT_TYPES.PERCENTAGE_DISCOUNT && !this.redeemed;
    },
    benefitSpecificText() {
      if (this.isPercentageDiscountBenefit) {
        return `${this.monthString} ב־${this.benefitPercentage}% הנחה (${this.discountedPrice} ₪)`;
      }
      return this.monthString;
    },
    monthString() {
      if (this.benefitAmount === 1) {
        return 'חודש ראשון';
      }
      if (this.benefitAmount === 2) {
        return 'חודשיים ראשונים';
      }
      return `${this.benefitAmount} חודשים ראשונים`;
    },
    moreMonthsString() {
      if (this.benefitAmount === 1) {
        return 'חודש נוסף';
      }
      if (this.benefitAmount === 2) {
        return 'חודשיים נוספים';
      }
      return `${this.benefitAmount} חודשים נוספים`;
    },
    percentageBenefitSignupOfferingText() {
      const monthsText = (this.benefitAmount === 1) ? 'חודש ראשון' : `${this.benefitAmount}  חודשים ראשונים`;
      return `${monthsText} ב-${this.benefitPercentage}% הנחה (${this.discountedPrice} ₪) בנוסף לתקופת הנסיון.`;
    },
    percentageBenefitSignupTimelineText() {
      const monthsText = (this.benefitAmount === 1) ? 'חודש ראשון' : `${this.monthsText}  ראשונים`;
      return `בסיום תקופת הנסיון, יהיו לך ${monthsText} ב-${this.benefitPercentage}% הנחה (${this.discountedPrice} ₪).`;
    },
    flavors() {
      return Flavors;
    },
  },
};

</script>
