<template>
  <div class="dd-celebrate">
    <div>
      <d-d-header :authenticated="true"/>
    </div>
    <div class="content">
      <div v-if="isReduceMonthlyDeposit">
        <img :src="require('@/assets/black-and-white-snapping-finger.png')" class="margin-top-m"/>
        <div>
          <div class="dd-headline-label ri-bold-body">זהו, סיימנו</div>
          <div class="ri-large-headline margin-bottom-s">עדכון הוראת הקבע הצליח!</div>
          <div class="text">
            <div> הקטנת את החיסכון החודשי שלך במיטב</div>
            <div> מ-{{ formattedMonthlyDeposit }} ₪
              ל-<span class="emphasise">{{ formattedDepositSum }} ₪</span>.
              מייל ממיטב עם כל
            </div>
            <div>הפרטים כבר מחכה לך.</div>
          </div>
          <div class="button">
            <dynamic-deposit-button variant="primary"
                                    title="חזרה לתזרים"
                                    :action="closeAction"
                                    :loading="loading"/>
          </div>
        </div>
      </div>
      <div v-else>
        <img :src="require('@/assets/black-and-white-pot.png')" class="margin-top-m"/>
        <div class="celebration-text">
          <div class="dd-headline-label ri-bold-body">זהו, סיימנו</div>
          <div class="ri-large-headline margin-bottom-s">
            <div>{{ activeMemberFirstName }} עשית את זה,</div>
            <div>העתיד שלך מודה לך!</div>
          </div>
          <div class="text" v-if="isOpportunisticDeposit">
            <div>
              העברת <span class="emphasise">{{ formattedDepositSum }} ₪</span> לחיסכון שלך במיטב.
            </div>
            <div>
              מייל ממיטב עם כל הפרטים כבר מחכה לך.
            </div>
          </div>
          <div class="text" v-else>
            <div> הגדלת את החיסכון החודשי שלך במיטב</div>
            <div> מ-{{ formattedMonthlyDeposit }} ₪
              ל-<span class="emphasise">{{ formattedDepositSum }} ₪</span>!
              מייל ממיטב עם כל
            </div>
            <div>הפרטים כבר מחכה לך.</div>
          </div>
        </div>
        <div class="button">
          <img class="preload-share-image" :src="require('@/assets/dynamic-deposit/celebrate-share.svg')"/>
          <share-button filename="dynamic-deposit-celebrate" :share-name="shareName"
                        :share-component="shareComponent"
                        :segment-extra-props="shareSegmentExtraProps" v-slot="slotProps"
                        :show-popup="true">
            <dynamic-deposit-button variant="primary"
                                    title="לשתף ולחגוג"
                                    :action="slotProps.onClick"
                                    :loading="slotProps.loading"/>
          </share-button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import EventBus from '@/event-bus/event-bus';
import Segment from '@/Segment';
import { mapGetters, mapActions } from 'vuex';
import DDLogs from '@/DDLogs';
import moneyUtils from '@/utils/money';
import ShareButton from '@/base-components/share/ShareButton';
import DDCelebrateAsset from './DDCelebrateAsset';
import DDHeader from './DDHeader';
import DynamicDepositButton from './DynamicDepositButton';

export default {
  name: 'DDCelebrate',
  components: {
    DDHeader,
    DynamicDepositButton,
    ShareButton,
  },
  data() {
    return {
      loading: false,
      shareComponent: DDCelebrateAsset,
    };
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    insight: {
      type: Object,
      require: true,
    },

  },
  created() {
    Segment.trackUserInteraction('DynamicDeposit_Congratulations_Entered');
    Segment.trackUserInteraction('ShareImpression', { shareName: this.shareName });
    EventBus.$emit('reset-scroll');
    if (this.insight) {
      this.markAsRead(this.insight).catch(error => {
        DDLogs.error('Failed to mark insight as read', { insight: this.insight, error });
      });
    }
    this.initSavingZone().catch(error => {
      DDLogs.error('Failed refreshing saving zone', { error });
    });
  },
  computed: {
    ...mapGetters('dynamicSavings', ['isOpportunisticDeposit', 'isReduceMonthlyDeposit', 'formattedDepositSum']),
    ...mapGetters('savings', ['monthlyDeposit']),
    ...mapGetters('session', ['activeMemberFirstName']),
    shareName() {
      return 'DynamicDeposit';
    },
    formattedMonthlyDeposit() {
      return moneyUtils.formatAmount(this.monthlyDeposit);
    },
    shareSegmentExtraProps() {
      return {};
    },
  },
  methods: {
    ...mapActions('savingZone', ['initSavingZone']),
    ...mapActions('savings', ['initSavings']),
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['closeModal']),

    closeAction() {
      this.loading = true;
      Segment.trackUserInteraction('DynamicDeposit_Congratulations_Closed');
      this.closeModal();
      this.initSavings().catch(error => {
        DDLogs.error('Failed refreshing saving', { error });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  text-align: right;
  margin: 0 30px 30px 30px;

  img {
    width: 85px;
  }

  .text {
    font-size: 18px;
    line-height: 26px;
  }

  .emphasise {
    font-weight: bold;
  }

  .button {
    margin-top: 30px;

    .preload-share-image {
      width: 0;
      height: 0;
    }
  }
}
</style>
