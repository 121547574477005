<template>
  <div class="loader">
    <lottie-player src="/animations/new-brand-loader.json" autoplay loop class="margin-bottom-m"
                   preserveAspectRatio="xMidYMax slice"/>
  </div>
</template>

<script>

import _ from 'lodash';
import router from '@/router';
import Segment from '@/Segment';

export default {
  name: 'AdvisoryFlowExternal',
  created() {
    const utmParams = _.pickBy(router.currentRoute.query, (value, key) => (key.startsWith('utm_') && value));
    Segment.trackUserInteraction('AdvisoryFlowExternal_Entered', { ...utmParams });
    window.location.href = 'https://signup.riseup.co.il/flow/future/';
  },
};
</script>

<style scoped lang="scss">

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
