<template>
  <popup :close-action="closeAction" class="open-banking-celebration-popup" :buttons="buttons">
    <template v-slot:content>
      <div class="flex-column align-center">
        <img :src="require('@/assets/great-success.png')" class="great-success-asset-in-popup">
      </div>
      <div class="title">
        בלי סיסמאות ובלי עדכונים – יצאנו לדרך עם הבנקאות פתוחה!
      </div>
      <div class="subtitle">
        כל מה שצריך זה לתת פעם אחת הסכמה לקריאת הנתונים. ההסכמה תהיה בתוקף לשלוש שנים, וכמובן שאפשר לבטל אותה לפני.
      </div>
    </template>
  </popup>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import ConsentActionsMixin from '@/base-components/onboarding/openBanking/ConsentActionsMixin';

export default {
  name: 'OpenBankingCelebrationPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  mixins: [ConsentActionsMixin],
  props: {
    source: {
      type: Object,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('OBKCelebrationPopupShown', { source: this.sourceName });
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    sourceName() {
      return this.source.displayName;
    },
    buttons() {
      return [{
        title: 'לחבר את החשבון בחיבור ישיר',
        action: () => {
          Segment.trackUserGot('OBKCelebrationPopupClicked', { source: this.sourceName });
          this.loading = true;
          this.closeAction();
          this.action();
          this.loading = false;
        },
        size: 'slim',
        loading: this.loading,
      }];
    },
  },
  methods: {
    closeAction() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.open-banking-celebration-popup {
  text-align: center;

  .title {
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
    text-align: center;
  }

  .subtitle {
    font-size: 16px;
    text-align: center;
    margin-bottom: 15px;
    line-height: 22px;
  }
}

</style>
