<template>
  <div class="create-tracking-category-popup-flow">
    <create-tracking-category-popup v-if="currentComponent === componentNames.createTrackingCategoryPopup"
                                    @done-clicked="showNext()" @close="close()"/>
    <create-other-tracking-category-popup v-if="currentComponent === componentNames.createOtherTrackingCategoryPopup"
                                    @done-clicked="showNext()" @back-clicked="showPrevious()"/>
    <create-tracking-category-amount-popup v-else-if="currentComponent === componentNames.createTrackingCategoryAmountPopup"
                                           v-on:close="close()" @back-clicked="showPrevious()"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CreateTrackingCategoryPopup from './CreateTrackingCategoryPopup.vue';
import CreateOtherTrackingCategoryPopup from './CreateOtherTrackingCategoryPopup.vue';
import CreateTrackingCategoryAmountPopup from './CreateTrackingCategoryAmountPopup.vue';

const componentNames = {
  createTrackingCategoryPopup: 'create-tracking-category-popup',
  createOtherTrackingCategoryPopup: 'create-other-tracking-category-popup',
  createTrackingCategoryAmountPopup: 'create-tracking-category-amount-popup',
};

export default {
  name: 'CreateTrackingCategoryPopupFlow',
  components: {
    CreateTrackingCategoryPopup,
    CreateOtherTrackingCategoryPopup,
    CreateTrackingCategoryAmountPopup,
  },
  data() {
    return {
      currentComponent: componentNames.createTrackingCategoryPopup,
    };
  },
  computed: {
    ...mapState('createTrackingCategory', ['isCustomTrackingCategory']),
    componentNames() {
      return componentNames;
    },
  },
  methods: {
    ...mapActions('createTrackingCategory', ['reset']),
    showNext() {
      if (this.currentComponent === this.componentNames.createTrackingCategoryPopup
        && this.isCustomTrackingCategory) {
        this.currentComponent = componentNames.createOtherTrackingCategoryPopup;
      } else if (this.currentComponent === this.componentNames.createOtherTrackingCategoryPopup
              || this.currentComponent === this.componentNames.createTrackingCategoryPopup) {
        this.currentComponent = componentNames.createTrackingCategoryAmountPopup;
      }
    },
    showPrevious() {
      if (this.currentComponent === this.componentNames.createTrackingCategoryAmountPopup
        && this.isCustomTrackingCategory) {
        this.currentComponent = componentNames.createOtherTrackingCategoryPopup;
      } else if (this.currentComponent === this.componentNames.createTrackingCategoryAmountPopup) {
        this.currentComponent = componentNames.createTrackingCategoryPopup;
      } else if (this.currentComponent === this.componentNames.createOtherTrackingCategoryPopup) {
        this.currentComponent = componentNames.createTrackingCategoryPopup;
      }
    },
    close() {
      this.reset();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .create-tracking-category-popup-flow {
    display: flex;
    flex-direction: column;
  }
</style>
