<template>
  <div class="personal-info-field">
    <customer-input
      :labelText="label"
      :value="value"
      :has-error="hasError"
      :onInput="onInput"
      :top-left-label="errorMessage"
    />
  </div>
</template>

<script>
import CustomerInput from '../../../../../../base-components/card-parts/CustomerInput.vue';

export default {
  name: 'PersonalInfoFieldV2',
  components: {
    CustomerInput,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    isValid: {
      type: Function,
      required: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    initialValue: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      value: this.initialValue,
      hasError: false,
    };
  },
  methods: {
    onInput(input) {
      const isNewInputInvalid = !!this.isValid && !this.isValid(input);
      if (isNewInputInvalid && !this.hasError) {
        this.hasError = true;
        this.$emit('validationChange', { field: this.fieldName, hasError: true });
      } else if (!isNewInputInvalid && this.hasError) {
        this.hasError = false;
        this.$emit('validationChange', { field: this.fieldName, hasError: false });
      }
      this.value = input;
      this.$emit('input', input);
    },
  },
};
</script>
