<template>
  <merge-investigation-popup class="papa-merge-questions-actions-selection"
                             :title="title"
                             :footer-action="footerAction"
                             :layout="'no-content-padding'"
                             :cx-button-title="cxButtonTitle"
                             :show-contact-c-x-button="showCxButton">
    <template v-slot:content>
      <div class="papas-details">
        <div v-for="(question, i) of notAnsweredPapaQuestions" :key="i">
          <papa-merge-question class="papa-merge-question"
                               :question="question"
                               :show-mark-as-loan-button="showMarkAsLoanButton"
          />
          <separator v-if="multiplePapaMergeQuestions || !showCxButton" class="questions-separator" />
        </div>
      </div>
    </template>
  </merge-investigation-popup>

</template>

<script>
import PapaMergeQuestion from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/merge/PapaMergeQuestion';
import BaseUI from '@riseupil/base-ui';
import MergeInvestigationPopup from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/MergeInvestigationPopup';
import { mapActions, mapGetters } from 'vuex';
import ConfirmMarkPapasAsApproved
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/merge-investigation-in-app/ConfirmMarkPapasAsApproved';
import _ from 'lodash';
import Segment from '@/Segment';

export default {
  name: 'PapasMergeQuestionsActionSelection',
  components: { PapaMergeQuestion, Separator: BaseUI.Separator, MergeInvestigationPopup },
  props: {
    showMarkAsLoanButton: {
      type: Boolean,
      default: false,
    },
    papaMergeQuestions: {
      type: Array,
      required: true,
    },
    firstTitle: {
      type: String,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('PapaMergeQuestion_ActionSelectionEntered', { source: this.$options.name });
  },
  computed: {
    ...mapGetters('mergeInvestigationQuestions', ['answeredPapas']),
    ...mapGetters('featureFlags', ['jitMergeInvestigation']),
    notAnsweredPapaQuestions() {
      return _.filter(this.papaMergeQuestions, q => !this.answeredPapas.find(answeredPapa => answeredPapa.transactionId === q.transactionId));
    },
    multiplePapaMergeQuestions() {
      return this.notAnsweredPapaQuestions.length > 1;
    },
    title() {
      return this.multiplePapaMergeQuestions ? `${(this.firstTitle)} אם אלו כרטיסים, כדאי לחבר אותם לשירות`
        : `${(this.firstTitle)} אם זה כרטיס אשראי, כדאי לחבר אותו לשירות`;
    },
    cxButtonTitle() {
      return this.multiplePapaMergeQuestions ? 'משהו אחר? דברו עם הצוות' : 'להתייעץ עם הצוות';
    },
    showCxButton() {
      return !this.showMarkAsLoanButton && this.jitMergeInvestigation;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    footerAction() {
      Segment.trackUserInteraction('PapaMergeQuestion_Dont_Show_Again_Clicked', { source: this.$options.name });
      this.openModal({
        component: ConfirmMarkPapasAsApproved,
        props: {
          papaQuestions: this.notAnsweredPapaQuestions,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.papa-merge-questions-actions-selection {

  .papas-details {

    .papa-merge-question {
      padding: 30px 24px 0 24px;
    }

    .questions-separator {
      margin-top: 22px;
      height: 12px;
    }
  }

  ::v-deep .riseup-button .button-content{
    padding: 0;
  }

  ::v-deep .riseup-button .button-content .button-icon{
    margin-left: 4px;
  }
}

</style>
