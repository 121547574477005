<template>
  <div class="move-to-fixed-bi-monthly-check-popup">
    <popup :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:title>
        <span>כל כמה זמן ההוצאה הזו הולכת לרדת?</span>
      </template>
    </popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import MoveToFixedConfirmationPopup from './MoveToFixedConfirmationPopup.vue';
import MoveToFixedSummaryPopup from './MoveToFixedSummaryPopup.vue';
import Segment from '../../../../../../../../../../Segment';

export default {
  name: 'MoveToFixedBiMonthlyCheckPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserInteraction('MoveToFixedBiMonthlyCheckPopup_Shown', {
      businessName: this.transaction.businessName,
      expenseCategory: this.transaction.expense,
      transactionId: this.transaction.transactionId,
    });
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapGetters('editTransaction', ['categoryToVariant']),
    buttonOptions() {
      return [{
        title: 'כל חודש',
        size: 'slim',
        action: () => this.setMonthsDiffAndCallNextStep(1),
        variant: 'primary',
      }, {
        title: 'פעם בחודשיים',
        size: 'slim',
        action: () => this.setMonthsDiffAndCallNextStep(2),
        variant: 'secondary',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapMutations('editTransaction', ['setMonthsDiff']),
    back() {
      Segment.trackUserInteraction('MoveToFixedBiMonthlyCheckPopup_BackClicked', {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
      });
      this.$emit('close');
      this.openModal({
        component: MoveToFixedConfirmationPopup,
      });
    },
    setMonthsDiffAndCallNextStep(monthsDiff) {
      const segmentEventName = monthsDiff === 2 ? 'MoveToFixedBiMonthlyCheckPopup_ClickedBiMonthly' : 'MoveToFixedBiMonthlyCheckPopup_ClickedMonthly';
      Segment.trackUserInteraction(segmentEventName, {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
        monthsDiff,
      });
      this.setMonthsDiff(monthsDiff);
      this.$emit('close');
      this.openModal({
        component: MoveToFixedSummaryPopup,
      });
    },
    close() {
      Segment.trackUserInteraction('MoveToFixedBiMonthlyCheckPopup_CloseClicked', {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
      });
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/category-color-mixins';

  .move-to-fixed-bi-monthly-check-popup {

    .explanation {
      line-height: 16px;
      white-space: pre-line;
    }
  }

</style>
