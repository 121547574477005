<script>

const banksData = {
  4: 'יהב',
  9: 'בנק הדואר',
  10: 'לאומי',
  11: 'דיסקונט',
  12: 'הפועלים',
  13: 'אגוד',
  14: 'אוצר החייל',
  17: 'מרכנתיל',
  18: 'one-zero',
  20: 'מזרחי',
  26: 'יובנק',
  31: 'הבינלאומי',
  46: 'מסד',
  52: 'אגודת ישראל',
  54: 'ירושלים',
};

export default {
  name: 'Banks',
  data() {
    return {
      banksData,
    };
  },
  methods: {
    formatBankAccount(bankDetails) {
      return `${bankDetails.bankAccountNumber}, בנק ${banksData[bankDetails.bankNumber]}, סניף ${bankDetails.bankBranch}`;
    },
  },
};
</script>
