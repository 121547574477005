<template>
  <insight :insight="insight"
           :button-text="insight.config.insightButtonText || 'ספרו לי עוד'"
           v-bind:[getBackgroundType(insight)]="insight.config.insightImage"
           :button-action="() => buttonAction(insight)">
    <template v-slot:insight-text>
      <insight-builder-renderer :sections="insight.config.insightBody" :insight-data="insight.config.data"></insight-builder-renderer>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import InsightLib from '@riseupil/insight-lib';
import Insight from './components/Insight';
import InsightsBuilderPopup from './InsightsBuilderPopup';

export default {
  name: 'InsightsBuilder',
  components: {
    Insight,
    InsightBuilderRenderer: InsightLib.InsightBuilderRenderer,
  },
  props: {
    insight: { type: Object },
  },
  methods: {
    getBackgroundType(insight) {
      return insight.config.insightImage.includes('.json') ? 'animation-name' : 'image-name';
    },
    ...mapActions('modalRootStore', ['openModal']),
    async buttonAction(insight) {
      await this.openModal({
        component: InsightsBuilderPopup,
        props: {
          insight,
          insightConfig: insight.config,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
