import BotiApi from '../../api/BotiApi';

const state = {
  pausedMembers: [],
};

const actions = {
  async getCommunicationState({ commit }) {
    const data = await BotiApi.getCommunicationState();
    if (data.autoSend?.pausedMembers) {
      commit('setPausedMembers', data.autoSend.pausedMembers);
    }
  },
  async updateAutoSendStateForMember({ commit, state }, {
    pauseAutoSend,
    reason = undefined,
    pauseExpirationDate = undefined,
  }) {
    const data = pauseAutoSend ? { pauseAutoSend, pauseExpirationDate, reason } : { pauseAutoSend };
    await BotiApi.updateCommunicationStateForMember(data);
  },
};

const getters = {
  memberCommunicationState: state => memberId => state.pausedMembers.find(member => member.memberId === memberId),
};

const mutations = {
  setPausedMembers(state, pausedMembers) {
    state.pausedMembers = pausedMembers;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
