<template>
  <insight :insight="insight"
           :animation-name="'Income.json'"
           :button-text="'אז מה לעשות?'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>המערכת זיהתה הכנסה של <span class="amount">{{ formattedAmount }} ₪</span>
        <span class="bold">והוציאה אותה מהתזרים. </span>מומלץ להשאיר רק הכנסות שיממנו את החודש.
      </div>
    </template>
  </insight>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';
import HighIncomeExclusionPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/HighIncomeExclusionPopup.vue';
import Insight from './components/Insight';

export default {
  name: 'HighIncomeInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapGetters('cashflowView', ['excluded']),
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.incomeAmount, 0);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('insights', ['clearInsight']),
    buttonAction() {
      this.openModal(
        {
          component: HighIncomeExclusionPopup,
          props: {
            transaction: this.insight,
            insight: this.insight,
          },
        },
      );
    },
  },
  watch: {
    excluded(newValue) {
      const stillExcludedHighIncome = _.chain(newValue)
        .filter(item => item.excludeReason === 'highIncomeExclusion' || item.excludeReason === 'relativeIncomeExclusion')
        .some({ transactionId: this.insight.transactionId })
        .value();
      if (!stillExcludedHighIncome) {
        this.clearInsight(this.insight);
      }
    },
  }
  ,
};
</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}

.bold {
  font-weight: bold;
}
</style>
