<template>
  <share-frame class="dd-celebrate-frame" :backgroundColor="backgroundColor" logo-color="white">
    <d-d-celebrate-share-component/>
  </share-frame>
</template>

<script>

import ShareFrame from '@/base-components/share/ShareFrame';
import BaseUI from '@riseupil/base-ui';
import DDCelebrateShareComponent from './DDCelebrateShareComponent';

export default {
  name: 'DDCelebrateAsset',
  components: {
    ShareFrame,
    DDCelebrateShareComponent,
  },
  data() {
    return {
      backgroundColor: BaseUI.Colors.riseupDarkGreen,
    };
  },
};

</script>

<style lang="scss" scoped>

.dd-celebrate-frame {
  width: 500px;
}

</style>
