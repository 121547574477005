<template>
  <div class="personal-info-field">
    <customer-input
      :labelText="label"
      :value="personalInfoField.value"
      :has-error="!personalInfoField.isValid"
      :onInput="setPersonalInfo"
      :top-left-label="errorMessage"/>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';

import CustomerInput from '../../../../../base-components/card-parts/CustomerInput.vue';

export default {
  name: 'PersonalInfoField',
  components: {
    CustomerInput,
  },
  props: {
    label: String,
    fieldName: String,
    isValid: Function,
    errorMessage: String,
    initialValue: { type: String, default: null },
  },
  created() {
    if (this.initialValue) {
      this.setPersonalInfo(this.initialValue);
    }
  },
  computed: {
    ...mapState('subscription', ['personalInfo']),
    personalInfoField() {
      return this.personalInfo[this.fieldName];
    },
  },
  methods: {
    ...mapActions('subscription', ['setPersonalInfoField']),
    setPersonalInfo(input) {
      this.setPersonalInfoField({ field: this.fieldName, value: input, isValid: input && this.isValid(input) });
    },
  },
};
</script>
