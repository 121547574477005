import BaseUI from '@riseupil/base-ui';
import ChangeTransactionCategorySettingsPopup from '../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/ChangeTransactionCategorySettingsPopup.vue'; // eslint-disable-line
import MoveToFixedConfirmationPopup from '../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/MoveToFixedConfirmationPopup.vue'; // eslint-disable-line
import MoveToExcludedConfirmationPopup from '../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/MoveToExcludedConfirmationPopup.vue'; // eslint-disable-line
import MoveFromExcludedConfirmationPopup from '../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/MoveFromExcludedConfirmationPopup.vue'; // eslint-disable-line
// eslint-disable-next-line max-len
import MoveFromFixedConfirmationPopup from '../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/MoveFromFixedConfirmationPopup';
import ChangeTransactionCategorySummaryPopup
  from '../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/cashflow-edit/edit-transaction/ChangeTransactionCategorySummaryPopup'; // eslint-disable-line

const CATEGORY_NAMES = {
  VARIABLE: 'variable',
  VARIABLE_INCOME: 'variableIncome',
  VARIABLE_EXPENSE: 'variableExpense',
  FIXED_EXPENSE: 'fixedExpense',
  FIXED_INCOME: 'fixedIncome',
  FIXED_SAVING: 'fixedSaving',
  ONE_TIME_SAVING: 'oneTimeSaving',
  EXCLUDED_INCOME: 'excludedIncome',
  EXCLUDED_EXPENSE: 'excludedExpense',
  TRACKING: 'tracking',
  TRACKING_CATEGORY: 'trackingCategory',
};

const TRACKING_CATEGORY_TYPE = {
  ECONOMY: 'כלכלה',
};

const CASHFLOW_CATEGORIES = {
  VARIABLE: 'variable',
  FIXED: 'fixed',
  FIXED_INCOME: 'fixed-income',
  FIXED_SAVING: 'fixed-saving',
  ONE_TIME_SAVING: 'one-time-saving',
  TRACKING: 'tracking',
  VARIABLE_INCOME: 'variable-income',
  EXCLUDED_INCOME: 'excluded-income',
  EXCLUDED_EXPENSE: 'excluded-expense',
  RISEUP_GOAL: 'riseup-goal',
};

const CATEGORY_STYLE = {
  [CASHFLOW_CATEGORIES.TRACKING]: 'primary',
  [CASHFLOW_CATEGORIES.VARIABLE]: 'warning',
  [CASHFLOW_CATEGORIES.FIXED]: 'fixed',
  [CASHFLOW_CATEGORIES.VARIABLE_INCOME]: 'success',
  [CASHFLOW_CATEGORIES.FIXED_INCOME]: 'success',
  [CASHFLOW_CATEGORIES.RISEUP_GOAL]: 'success',
  [CASHFLOW_CATEGORIES.EXCLUDED_INCOME]: 'black',
  [CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE]: 'black',
  [CASHFLOW_CATEGORIES.ONE_TIME_SAVING]: 'saving',
  [CASHFLOW_CATEGORIES.FIXED_SAVING]: 'saving',
};

const OTHER_CATEGORY = 'אחר';
const FIXED_SAVING_CATEGORY_NAME = 'לחסכון';
const FIXED_SAVING_CATEGORY_NAMES = ['לחסכון', 'השקעה וחיסכון'];
const SAVING_LABEL = 'הפקדה לחיסכון';
const SAVING_PLURAL_LABEL = 'הפקדות לחיסכון';

const PAYMENT_METHODS_FIXED_EXPENSE_CATEGORIES = [
  'תשלומים',
  'העברות',
  'שיק',
  'מזומן',
];

const TRANSFERABLE_FIXED_INCOME_CATEGORIES = [
  'משכורת',
  'דיור',
  'קצבאות',
];

// These are the top 11 categories that customers moved expenses to recently
const TRANSFERABLE_FIXED_EXPENSE_CATEGORIES = [
  FIXED_SAVING_CATEGORY_NAME,
  'ביטוח',
  'דיגיטל',
  'הלוואה',
  'תשלומים',
  'אחר',
  'תרומה',
  'מנויים',
  'חינוך',
  'דיור',
];

const cashflowTypes = {
  income: 'income',
  car: 'car',
  economy: 'economy',
  fixedExpense: 'fixedExpense',
  fixedSaving: 'fixedSaving',
  variableExpense: 'variableExpense',
  oneTimeSaving: 'oneTimeSaving',
  excluded: 'excluded',
  riseupGoal: 'riseupGoal',
  trackingCategory: 'trackingCategory',
};

const categoryNameToTransactionCategory = {
  [CATEGORY_NAMES.FIXED_INCOME]: 'הכנסות קבועות',
  [CATEGORY_NAMES.VARIABLE_INCOME]: 'הכנסות משתנות',
  [CATEGORY_NAMES.VARIABLE]: 'הוצאות משתנות',
  [CATEGORY_NAMES.FIXED_EXPENSE]: 'הוצאות קבועות',
  [CATEGORY_NAMES.FIXED_SAVING]: 'הפקדות קבועות לחיסכון',
  [CATEGORY_NAMES.ONE_TIME_SAVING]: 'הפקדות חד-פעמיות לחיסכון',
  [CATEGORY_NAMES.EXCLUDED_EXPENSE]: 'הוצאות לא תזרימיות',
  [CATEGORY_NAMES.EXCLUDED_INCOME]: 'הכנסות לא תזרימיות',
};

export default {
  cashflowTypes,
  categoryNameToTransactionCategory,
  envelopeTimeFrames: {
    weekly: 'weekly',
    monthly: 'monthly',
  },
  weekTimeFrames: {
    past: 'past',
    current: 'current',
    future: 'future',
  },
  CATEGORIES: [
    { categoryName: CATEGORY_NAMES.VARIABLE, expense: 'משתנות', cashflowCategory: CASHFLOW_CATEGORIES.VARIABLE, icon: 'change_circle', color:BaseUI.IconSquareColorVariants.YELLOW, label: 'משתנה', pluralLabel: 'משתנות', style: CATEGORY_STYLE[CASHFLOW_CATEGORIES.VARIABLE], targetPopup: ChangeTransactionCategorySettingsPopup, finalPopup: ChangeTransactionCategorySummaryPopup  }, // eslint-disable-line
    { categoryName: CATEGORY_NAMES.VARIABLE_INCOME, expense: 'הכנסות משתנות', cashflowCategory: CASHFLOW_CATEGORIES.VARIABLE_INCOME, icon: 'change_circle', color:BaseUI.IconSquareColorVariants.GREEN, label: 'משתנה', pluralLabel: 'משתנות', style: CATEGORY_STYLE[CASHFLOW_CATEGORIES.VARIABLE_INCOME], targetPopup: ChangeTransactionCategorySettingsPopup, }, // eslint-disable-line
    { categoryName: CATEGORY_NAMES.FIXED_EXPENSE, cashflowCategory: CASHFLOW_CATEGORIES.FIXED, icon: 'calendar_today', label: 'קבועה', color:BaseUI.IconSquareColorVariants.PINK, pluralLabel: 'קבועות', style: CATEGORY_STYLE[CASHFLOW_CATEGORIES.FIXED], confirmationPopup: MoveFromFixedConfirmationPopup, targetPopup: MoveToFixedConfirmationPopup  }, // eslint-disable-line
    { categoryName: CATEGORY_NAMES.FIXED_INCOME, cashflowCategory: CASHFLOW_CATEGORIES.FIXED_INCOME, icon: 'calendar_today', color:BaseUI.IconSquareColorVariants.GREEN, label: 'קבועה', pluralLabel: 'קבועות', style: CATEGORY_STYLE[CASHFLOW_CATEGORIES.FIXED_INCOME], confirmationPopup: MoveFromFixedConfirmationPopup, targetPopup: MoveToFixedConfirmationPopup, }, // eslint-disable-line
    { categoryName: CATEGORY_NAMES.FIXED_SAVING, cashflowCategory: CASHFLOW_CATEGORIES.FIXED_SAVING, icon: 'database', color:BaseUI.IconSquareColorVariants.PINK, pluralLabel: SAVING_PLURAL_LABEL, }, // eslint-disable-line
    { categoryName: CATEGORY_NAMES.EXCLUDED_EXPENSE, cashflowCategory: CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE, icon: 'block', color:BaseUI.IconSquareColorVariants.GRAY, label: 'לא תזרימית', pluralLabel: 'לא תזרימיות', style: CATEGORY_STYLE[CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE], confirmationPopup: MoveFromExcludedConfirmationPopup, targetPopup: MoveToExcludedConfirmationPopup }, // eslint-disable-line
    { categoryName: CATEGORY_NAMES.EXCLUDED_INCOME, cashflowCategory: CASHFLOW_CATEGORIES.EXCLUDED_INCOME, icon: 'block', color:BaseUI.IconSquareColorVariants.GRAY, label: 'לא תזרימית', pluralLabel: 'לא תזרימיות', confirmationPopup: MoveFromExcludedConfirmationPopup, style: CATEGORY_STYLE[CASHFLOW_CATEGORIES.EXCLUDED_INCOME], targetPopup: MoveToExcludedConfirmationPopup, }, // eslint-disable-line
  ],
  CATEGORY_NAMES,
  CASHFLOW_CATEGORIES,
  TRACKING_CATEGORY_TYPE,
  CATEGORY_STYLE,
  AVAILABLE_CASHFLOW_CATEGORIES: {
    [CASHFLOW_CATEGORIES.ONE_TIME_SAVING]: [
      CASHFLOW_CATEGORIES.VARIABLE,
      CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE,
      CASHFLOW_CATEGORIES.TRACKING,
    ],
    [CASHFLOW_CATEGORIES.VARIABLE]: [
      CASHFLOW_CATEGORIES.VARIABLE,
      CASHFLOW_CATEGORIES.FIXED,
      CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE,
      CASHFLOW_CATEGORIES.TRACKING,
    ],
    [CASHFLOW_CATEGORIES.FIXED]: [
      CASHFLOW_CATEGORIES.VARIABLE,
      CASHFLOW_CATEGORIES.FIXED,
      CASHFLOW_CATEGORIES.TRACKING,
      CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE,
    ],
    [CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE]: [
      CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE,
      CASHFLOW_CATEGORIES.VARIABLE,
    ],
    [CASHFLOW_CATEGORIES.EXCLUDED_INCOME]: [
      CASHFLOW_CATEGORIES.EXCLUDED_INCOME,
      CASHFLOW_CATEGORIES.VARIABLE_INCOME,
      CASHFLOW_CATEGORIES.FIXED_INCOME,
    ],
    [CASHFLOW_CATEGORIES.TRACKING]: [
      CASHFLOW_CATEGORIES.VARIABLE,
      CASHFLOW_CATEGORIES.TRACKING,
      CASHFLOW_CATEGORIES.FIXED,
      CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE,
    ],
    [CASHFLOW_CATEGORIES.VARIABLE_INCOME]: [
      CASHFLOW_CATEGORIES.VARIABLE_INCOME,
      CASHFLOW_CATEGORIES.FIXED_INCOME,
      CASHFLOW_CATEGORIES.EXCLUDED_INCOME,
    ],
    [CASHFLOW_CATEGORIES.FIXED_INCOME]: [
      CASHFLOW_CATEGORIES.VARIABLE_INCOME,
      CASHFLOW_CATEGORIES.FIXED_INCOME,
    ],
  },
  PAYMENT_METHODS_FIXED_EXPENSE_CATEGORIES,
  TRANSFERABLE_FIXED_INCOME_CATEGORIES,
  TRANSFERABLE_FIXED_EXPENSE_CATEGORIES,
  OTHER_CATEGORY,
  FIXED_SAVING_CATEGORY_NAME,
  FIXED_SAVING_CATEGORY_NAMES,
  SAVING_LABEL,
  SAVING_PLURAL_LABEL,
  DEFAULT_CASHFLOW_START_DAY: 1,
  TOASTER_TIMEOUT: 4500,
  // determined in johnnycash: https://github.com/riseupil/johnnycash/commit/b38ca25d07e546854b7accbf1a8519df0a043533
  GOOD_CASHFLOWS_START: '2022-01',
  // minimal window for displaying the past months graphs
  MONTHS_BACK_TO_LOAD: 6,
};
