<template>
  <div v-if="!isLoading" class="assets-and-liabilities">
    <advisory-icon-header variant="blue"/>
    <dashboard-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="header">
          <div class="ri-small-display">הכסף שלי</div>
          <div class="subtitle ri-body margin-top-xs margin-bottom-m">יתרות עו״ש, פקדונות, הלוואות, ניירות ערך ומשכנתאות</div>
        </div>
      </template>
      <template v-slot:dashboard>
        <an-l-total-balances />
      </template>
      <template>
        <div class="margin-top-xl">
          <tabbed-section :tabs="tabs" @tab-click="tabClicked" class="padding-horizontal-ml">
            <div v-if="selectedTabKey === ASSETS_TAB_KEY">
                <an-l-assets />
            </div>
            <div v-else-if="selectedTabKey === LIABILITIES_TAB_KEY">
              <an-l-liabilities />
            </div>
          </tabbed-section>
          <div class="margin-vertical-l ri-font-14 padding-horizontal-ml">
            <base-tool-tip class="ri-label" icon-path="/images/info-icon-blue.svg">
              <div>המידע מוצג כפי שהתקבל מהבנק וחברות האשראי. יכולים להיות פערים במידע.</div>
              <div>
                <span class="ri-font-weight-bold">עדכון אחרון: </span>
                <span>{{lastUpdateDate}}</span>
              </div>
            </base-tool-tip>
          </div>
        </div>
      </template>
    </dashboard-page>

  </div>
  <div class="loader" v-else>
    <lottie-player src="/animations/new-brand-loader.json" autoplay loop class="margin-bottom-m"
                   preserveAspectRatio="xMidYMax slice"/>
    <div class="ri-body">טוענים את הנתונים ממקורות המידע</div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { BACKGROUND_POSITION } from '@riseupil/base-ui/src/utils/layout-consts';
import { mapActions } from 'vuex';
import dateUtils from '@/utils/dates';
import moment from 'moment';
import BaseToolTip from '@/base-components/onboarding/BaseToolTip';
import AdvisoryIconHeader from './AdvisoryIconHeader';
import AnLTotalBalances from './AnLTotalBalances.vue';
import AnLAssets from './AnLAssets.vue';
import AnLLiabilities from './AnLLiabilities.vue';

const ASSETS_TAB_KEY = 'assets';
const LIABILITIES_TAB_KEY = 'liabilities';

export default {
  name: 'AssetsAndLiabilities',
  components: {
    TabbedSection: BaseUI.TabbedSection,
    DashboardPage: BaseUI.Pages.DashboardPage,
    BaseToolTip,
    AdvisoryIconHeader,
    AnLTotalBalances,
    AnLAssets,
    AnLLiabilities,
  },
  async created() {
    await this.fetchAssetsAndLiabilities();
    this.isLoading = false;
  },
  data() {
    return {
      isLoading: true,
      ASSETS_TAB_KEY,
      LIABILITIES_TAB_KEY,
      selectedTabKey: ASSETS_TAB_KEY,
    };
  },
  computed: {
    imageHeaderProps() {
      return {
        backgroundColor: 'dark-blue',
        bottomSeparator: false,
        textColor: 'white',
        imgSrc: '/images/advisory/anl-background-image.svg',
        backgroundPosition: BACKGROUND_POSITION.BOTTOM,
      };
    },
    tabs() {
      return [
        {
          key: ASSETS_TAB_KEY,
          text: 'פירוט הנכסים',
        },
        {
          key: LIABILITIES_TAB_KEY,
          text: 'פירוט ההתחייבויות',
        },
      ];
    },
    lastUpdateDate() {
      const date = dateUtils.normalizeDate(moment());
      const time = moment().format('HH:mm');
      return ` ${date}, בשעה ${time}`;
    },
  },
  methods: {
    ...mapActions('assetsAndLiabilities', ['fetchAssetsAndLiabilities']),
    tabClicked(key) {
      this.selectedTabKey = key;
    },
  },
};
</script>

<style lang="scss" scoped>

.assets-and-liabilities{
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .header {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right;

    .subtitle{
      width: 200px;
    }
  }

  .last-update-date{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 12px;
    height: 36px;
    white-space: pre;
  }
}

.loader{
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  lottie-player {
    height: 200px;
    margin-top: -150px;
  }
}

</style>
