import { client } from '../http';

export async function fetchPapasQuestions() {
  const response = await client.get('/api/investigator/questions/merge/papas');
  return response.data;
}

export async function fetchSonsQuestions() {
  const response = await client.get('/api/investigator/questions/merge/sons');
  return response.data;
}

export async function answerPapaQuestion({ businessName, accountNumberPiiId, transactionId, input }) {
  return await client.post('/api/investigator/answers/merge', {
    papaAccountMergeInput: [{ businessName, accountNumberPiiId, transactionId, input }],
  });
}

export async function answerSonQuestion({ chunkId, input, accountNumberPiiId }) {
  return await client.post('/api/investigator/answers/merge', {
    sonsMergeInput: [{ chunkId, input, accountNumberPiiId }],
  });
}

export async function answerMultipleQuestions(papasMergeInput, sonsMergeInput) {
  return await client.post('/api/investigator/answers/merge', { papasMergeInput, sonsMergeInput });
}

export async function fetchClassifiedMergeInvestigations() {
  const response = await client.get('/api/investigator/questions/merge/classified-investigations');
  return response.data;
}
