<template>
  <div class="update-credit-card-button">
    <div class="card">
      <div class="text">כרטיס לחיוב</div>
      <div class="card-number">{{ newFormattedCardNumberMask }}</div>
    </div>
    <div v-if="enableOtherCard" class="small-button" @click="onClicked" >לשלם בכרטיס אחר</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CreditCardDetails',
  props: {
    onClicked: {
      type: Function,
      require: true,
    },
    enableOtherCard: {
      type: Boolean,
      require: true,
    },
  },
  computed: {
    ...mapGetters('subscription', ['newFormattedCardNumberMask']),
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../../../../node_modules/@riseupil/base-ui/src/scss/riseup-colors";

  .update-credit-card-button {
    width: 100%;
    padding: 24px 0;

    .card {
      line-height: 1.5;
      color: $riseup_black;

      .text {
        font-size: 14px;
        font-family: SimplerPro;
      }

      .card-number {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 24px;
      }
    }

    .button {
      cursor: pointer;
    }

    .small-button {
      cursor: pointer;
      font-size: 14px;
      text-decoration: underline;
      color: $riseup_blue;
      margin-top: -16px;
    }
  }
</style>
