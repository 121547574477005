<template>
  <info-tool-tip-help-popup class="bank-creds-help-popup"
    :title="'מהם פרטי ההתחברות לבנק?'"
    :cx-dude="{ name: 'guy', displayName: 'גיא' }"
    :source-name="sourceName"
    :popup-name="'BankCredsHelpPopup'"
    :on-close="onClose">
    <template v-slot:description>
      <div class="description">
        <div>
          כדי לחבר את חשבון הבנק ל-riseup צריך לספק למערכת את
          <span class="bold">פרטי המשתמש הקבועים</span>
          (כולל סיסמא קבועה) איתם נכנסים לאתר או לאפליקציה של הבנק.
        </div>
        <div>&nbsp;</div>
        <div class="bold">כדאי לדעת:</div>
        יש בנקים וחברות אשראי שמאפשרים להתחבר בטביעת אצבע או בקוד זמני שנשלח לנייד.
        <span class="bold">בכל מקרה, תמיד אפשר ליצור באופן חד פעמי פרטי משתמש</span>
        עם סיסמא קבועה ולהכניס אותם אצלנו במערכת.
        <div>&nbsp;</div>
        <div class="bold">שכחת את שם המשתמש או הסיסמא?</div>
        אפשר לשחזר אותם דרך אתר הבנק.
      </div>
    </template>
  </info-tool-tip-help-popup>
</template>

<script>

import InfoToolTipHelpPopup from '../InfoToolTipHelpPopup.vue';

export default {
  name: 'BankCredsHelpPopup',
  components: {
    InfoToolTipHelpPopup,
  },
  props: {
    sourceName: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .bank-creds-help-popup {
    .description {
      a {
        color: $riseup_blue;
        font-weight: bold;
      }
      .bold {
        font-weight: bold;
      }
    }
  }

</style>
