<template>
  <toaster @close-toaster="closeTextCopiedToaster" class="text-copied-toaster">
    <template v-slot:text>
      <div>
        <span> מעולה! הלינק הועתק בהצלחה</span>
      </div>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'textCopiedToaster',
  components: {
    Toaster,
  },
  props: {
  },
  methods: {
    closeTextCopiedToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
