import { client } from '../http';

async function createChallenge(type, extraParams) {
  const response = await client.post('/api/create-journey', { type, extraParams: { ...extraParams } });
  return response.data;
}

async function fetchActiveChallenge() {
  const response = await client.get('/api/journeys');
  return response.data;
}

export default {
  createChallenge,
  fetchActiveChallenge,
};
