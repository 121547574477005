<template>
  <callout class="callout" iconPath="icons/heart.svg" title="מה השלב הבא">
    <template v-slot:text>
      <div class="ri-label">אחרי התשלום נשלח לך הודעה בווטסאפ ונתחיל את התהליך האישי לבניית התוכניות שלך!
      </div>
    </template>
  </callout>
</template>

<script>

import BaseUI from '@riseupil/base-ui';

export default {
  name: 'AdvisoryNextStepCallout',
  components: {
    Callout: BaseUI.Callout,
  },
};
</script>

<style lang="scss" scoped>

.callout {
  text-align: start;
}

</style>
