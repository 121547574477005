<template>
  <div class="riseup-goal-done">
    <img class="margin-vertical-l" :src="require('@/assets/high-five-hands-drawing.png')" alt="success"/>
    <div class="title">כל הכבוד! הגדרת יעד חודשי</div>
    <div class="subtitle">לתכנן כמה כסף פנוי רוצים להשאיר בסוף החודש זה צעד משמעותי בדרך לצמיחה כלכלית.</div>
    <riseup-button :variant="'primary'" :size="'slim'" title="לסיום וחזרה לתזרים" :action="done"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '@/router';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'RiseupGoalDonePopup',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserInteraction('SettingRiseupGoalDone');
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    done() {
      this.closeModal();
      router.push({ path: '/home' });
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.riseup-goal-done {
  padding: 0 24px 24px;
  font-size: 18px;
  line-height: 25px;
  .title {
    font-weight: bold;
  }
  .subtitle {
    margin-bottom: 16px;
  }
}
</style>
