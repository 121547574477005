import { client, clientWithoutErrorHandling } from '../http';

export default {
  async generateCode({ phoneNumber, force }) {
    return await client.post('/api/no-auth/login/generate-code', { phoneNumber, force });
  },
  async authenticate({ phoneNumber, code }) {
    return await client.post('/api/no-auth/login/authenticate', { phoneNumber, code });
  },
  async isLoggedIn() {
    const response = await clientWithoutErrorHandling.get('/api/logged-in/', {
      validateStatus: status => status === 200 || status === 403,
    });
    return response.status === 200;
  },
  async isOtsIlLoggedIn() {
    const response = await clientWithoutErrorHandling.get('/api/ots-il/logged-in/', {
      validateStatus: status => status === 200 || status === 403,
    });
    return response.status === 200;
  },
  async isPartialLoggedIn() {
    const response = await clientWithoutErrorHandling.get('/api/partial-auth/logged-in/', {
      validateStatus: status => status === 200 || status === 403,
    });
    return response.status === 200;
  },
  async is2faRequired() {
    const response = await client.get('/api/partial-auth/is2faRequired');
    return response.data.required;
  },
  async loginAs({ customerToken }) {
    return await client.post('/api/no-auth/login-as', { customerToken });
  },
  async logout() {
    await client.get('/api/logout');
    window.location.href = '/api/no-auth/logout';
  },
  async logCookies() {
    return await client.get('/api/log-cookies');
  },
  async removeToken() {
    return await client.get('/api/no-auth/remove-cookie');
  },
};
