export default {
  NOTIFICATION_STATE: {
    UPDATE_CREDENTIALS: 'updateCredentials',
    NEW_CREDENTIALS: 'newCredentials',
    ACCOUNT_QUESTIONS: 'accountQuestions',
    MERGE_QUESTIONS: 'mergeQuestions',
    TECHNICAL_ERROR: 'technicalError',
    SYSTEM_BUG: 'systemBug',
    BANK_CC_BUG: 'bankCcBug',
    CUSTOMER_BUG: 'customerBug',
    TRIAL_EXPIRED: 'trialExpired',
    DORMANT_AFTER_CANCELED: 'dormantAfterCanceled',
    MALICIOUS_MEMBER_JOINED: 'maliciousMemeberJoined',
    NONE: 'none',
    PROCESSING_CASHFLOW: 'processingCashflow',
    LOADING_CREDENTIALS: 'loadingCredentials',
  },
  CASHFLOW_STATE: {
    OUT_OF_DATE: 'outOfDate',
    ACTIVE: 'active',
    BLOCKED: 'blocked',
    BUG: 'bug',
  },
  BAR_STATE: {
    OUT_OF_DATE: 'outOfDate',
    NOT_ACCURATE: 'notAccurate',
    NOT_AVAILABLE: 'notAvailable',
    TRIAL_EXPIRED: 'trialExpired',
    PROCESSING: 'processing',
  },
};
