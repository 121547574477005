<template>
  <div class="anl-total-balances padding-ml">
    <div class="assets-balance">
      <div class="ri-bold-body">סך הנכסים</div>
      <anl-balance :balance="assetsSum" size="small" />
    </div>
    <separator class="margin-vertical-s"/>
    <div class="liabilities-balance">
      <div class="ri-bold-body">סך ההלוואות</div>
      <anl-balance :balance="loansSum" size="small" />
    </div>
    <template v-if="mortgagesSum != 0">
      <separator class="margin-vertical-s"/>
      <div class="liabilities-balance">
        <div class="ri-bold-body">משכנתאות</div>
        <anl-balance :balance="mortgagesSum" custom-variant="black" size="small" />
      </div>
    </template>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import AnlBalance from './AnlBalance.vue';

export default {
  name: 'AnLTotalBalances',
  components: {
    Separator: BaseUI.Separator,
    AnlBalance,
  },
  computed: {
    ...mapGetters('assetsAndLiabilities', ['assetsSum', 'loansSum', 'mortgagesSum']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.anl-total-balances{
  .green{
    color: $riseup_green;
  }

  .red{
    color: $riseup_danger_red;
  }
}

</style>
