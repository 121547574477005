<template>
  <insight :insight="insight"
           :animation-name="'merge-insight.json'"
           :button-text="'להשלמת חיבור האשראי'"
           :background-variant="'red'"
           :loop="false"
           :show-dismiss="false"
           :button-action="()=>openMergeCaseInvestigationsPopup(insight.mergeInvestigations)">
    <template v-slot:insight-text>
      <div>
        <span class="bold">למערכת חסר פירוט עסקאות</span>
        <span v-if="cardSource && source">של חיוב{{ isMultipleInvestigations ? 'י' : '' }} אשראי מחברת <span class="bold">{{
            cardSource
          }}</span>
          שמופיע{{ isMultipleInvestigations ? 'ים' : '' }} בחשבון <span class="bold">בנק {{ source }}</span></span>
        <span v-else-if="source">של חיוב{{ isMultipleInvestigations ? 'י' : '' }} אשראי שמופיע{{ isMultipleInvestigations ? 'ים' : '' }} בחשבון <span
          class="bold">בנק {{ source }}</span></span>
        <span v-else>של חיובי אשראי שמופיעים בחשבונות הבנק שלך</span>
      </div>
    </template>
  </insight>
</template>

<script>

import _ from 'lodash';
import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';
import PapaMergeQuestionsMixin from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/PapaMergeQuestionsMixin';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import PapaMergeQuestionsPopupsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/PapaMergeQuestionsPopupsMixin.vue';
import Insight from './components/Insight';

export default {
  name: 'AllClassifiedPapasMergeQuestionsInsight',
  components: {
    Insight,
  },
  mixins: [PapaMergeQuestionsMixin, PapaMergeQuestionsPopupsMixin],
  props: {
    insight: { type: Object, required: true },
  },
  computed: {
    ...mapGetters('mergeInvestigationQuestions', ['allPapasAnswered', 'answeredPapas']),
    cardSource() {
      const cardsSources = _.chain(this.insight.mergeInvestigations)
        .map(item => item.cardSource)
        .uniq()
        .compact()
        .value();
      if (cardsSources.length === 1) {
        return getSourceConfigurationBySourceName(cardsSources[0]).displayName;
      }
      return null;
    },
    source() {
      const sources = _.uniq(_.map(this.insight.mergeInvestigations, 'source'));
      if (sources.length === 1) {
        return getSourceConfigurationBySourceName(sources[0]).sourceName;
      }
      return null;
    },
    isMultipleInvestigations() {
      return this.insight.mergeInvestigations;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('insights', ['clearInsight']),
    closePopup() {
      Segment.trackUserInteraction('PreviouslyMergedPapasMergeQuestionPopupClosed', this.insight);
      this.closeModal();
    },
  },
  watch: {
    allPapasAnswered(newValue) {
      if (newValue) {
        this.clearInsight(this.insight);
        this.closeModal();
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
