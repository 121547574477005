<template>
  <div class="dd-celebrate-share-component">
    <div><img class="celebrate-logo" :src="require('@/assets/dynamic-deposit/celebrate-share.png')"/></div>
    <div class="title margin-top-sm">
      <div>{{activeMemberFirstName}},</div>
      <div>העתיד שלך מודה לך!</div>
    </div>
    <div class="text" v-if="isOpportunisticDeposit">העברת עוד {{ formattedDepositSum }} ₪ לחיסכון שלך והגדלת את המתנה שלך לעצמך.</div>
    <div class="text" v-else>עדכנת את החיסכון החודשי שלך ל-{{ formattedDepositSum }} ₪ והגדלת את המתנה שלך לעצמך.</div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import moneyUtils from '@/utils/money';

export default {
  name: 'DDCelebrateSahreComponent',
  computed: {
    ...mapGetters('dynamicSavings', ['isOpportunisticDeposit', 'formattedDepositSum']),
    ...mapGetters('savings', ['monthlyDeposit']),
    ...mapGetters('session', ['activeMemberFirstName']),
    formattedMonthlyDeposit() {
      return moneyUtils.formatAmount(this.monthlyDeposit);
    },
  },
};

</script>

<style lang="scss" scoped>

.dd-celebrate-share-component {
  display: flex;
  flex-direction: column;
  align-items: center;

  .celebrate-logo {
    margin-top: 35px;
    width: 280px;
    min-height: 180px;
  }

  .title {
    font-weight: bold;
    width: 100%;
    font-size: 24px;
    text-align: center;
    margin-bottom: 15px;
  }

  .text {
    margin-bottom: 30px;
    padding: 0px 50px;
    text-align: center;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
  }
}

</style>
