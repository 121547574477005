<template>
  <dynamic-deposit-step :authenticated="true" :step="this.$options.name">
    <template v-slot:content>
      <div class="dd-headline-label ri-bold-body">
        פרטים
      </div>
      <div class="ri-large-headline margin-bottom-s" v-if="isOpportunisticDeposit">
        ביקשת להעביר
        <span>{{ formattedDepositSum }} ₪</span>
        לחיסכון
      </div>
      <div class="ri-large-headline margin-bottom-s" v-else-if="isMonthlyDeposit || isReduceMonthlyDeposit">
        בחרת לעדכן את הוראת הקבע שלך ולהעביר לחיסכון
        <span>{{ formattedDepositSum }} ₪</span>
        כל חודש
      </div>

      <div class="first-details-section">
        <div class="details-headline title">מחשבון הבנק שלך</div>
        <div>{{ formatBankAccount(bankDetails) }}</div>
      </div>

      <div class="details-section">
        <div class="details-headline title">לקופת הגמל להשקעה שלך</div>
        <div>{{ formatedFundDetails }}</div>
      </div>

      <div class="details-section">
        <div class="details-headline title">מתי זה יקרה?</div>
        <div v-if="isOpportunisticDeposit">תוך 5 ימי עסקים</div>
        <div v-else-if="isMonthlyDeposit || isReduceMonthlyDeposit">
          <div>הטיפול בבקשה לוקח 5 ימים,</div>
          <div>ההעברה תתבצע ב-15 לחודש</div>
        </div>
      </div>

    </template>
      <template v-slot:next-button>
        <dynamic-deposit-button title="חתימה וסיימנו!" :action="nextClicked" :loading="loading"/>
      </template>
  </dynamic-deposit-step>
</template>

<script>

import Segment from '@/Segment';
import { mapGetters } from 'vuex';
import DynamicDepositStep from './DynamicDepositStep';
import Banks from './data/Banks';
import DynamicDepositButton from './DynamicDepositButton';

export default {
  name: 'DDConfirmDetails',
  components: {
    DynamicDepositButton,
    DynamicDepositStep,
  },
  mixins: [Banks],
  data() {
    return {
      loading: false,
    };
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('DynamicDeposit_PreCheckout_Entered');
  },
  computed: {
    ...mapGetters('dynamicSavings', ['bankDetails', 'fundDetails', 'formattedDepositSum', 'isOpportunisticDeposit',
      'isMonthlyDeposit', 'isReduceMonthlyDeposit']),
    formatedFundDetails() { return `${this.fundDetails.fundNumber}, מיטב`; },
  },
  methods: {
    nextClicked() {
      Segment.trackUserInteraction('DynamicDeposit_PreCheckout_NextClicked');
      this.nextStep();
    },
  },
};
</script>

<style lang="scss" scoped>

  .details-headline {
    font-weight: bold;
    line-height: 28px;
  }

  .first-details-section {
    margin-top: 15px;
    font-size: 18px;
    line-height: 24px;
  }

  .details-section {
    margin-top: 25px;
    font-size: 18px;
    line-height: 24px;
  }

  .information {
    line-height: 24px;
  }

</style>
