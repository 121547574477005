<template>
    <div class="open-banking-pepper-description">
      <div class="connection-end-description">
        <span>בסיום חיבור מוצלח של החשבון,</span>
        <span class="bold">תועברו אוטומטית חזרה לרייזאפ.</span>
      </div>
      <div class="connection-back-description">
        <span>אם נראה שהחיבור לא הושלם,</span>
        <span class="bold">מומלץ לחזור אלינו</span>
        <span>לקבלת תמיכה.</span>
      </div>
    </div>
</template>

<script>
export default {
  name: 'OpenBankingDefaultDescription',
  props: {
    source: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.open-banking-pepper-description {

  font-size: 16px;
  line-height: 22px;

  .connection-end-description {
    margin-bottom: 27px;
  }

  .connection-back-description {
    margin-bottom: 16px;
  }

  .bold {
    font-weight: bold;
  }
}
</style>
