<template>
  <div class="change-transaction-category-summary-popup">
    <popup :title="'ההוצאה תעבור'"
           :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:content>
        <change-category-path :old-category="originalCategory" :new-category="selectedCategory" />
        <transaction-details :transaction="transaction" />
        <div v-if="explanation" class="explanation" :class="selectedCategory.cashflowCategory">{{ explanation }}</div>
      </template>
    </popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import ChangeTransactionCategorySettingsPopup from './ChangeTransactionCategorySettingsPopup.vue';
import TransactionDetails from './TransactionDetails.vue';
import ChangeCategoryPath from './ChangeCategoryPath.vue';
import Segment from '../../../../../../../../../../Segment';
import cashflowViewConsts from '../../../../../../../../../../constants/cashflow-view';
import TransactionMovedToaster from '../../../../../toasters/TransactionMovedToaster';

export default {
  name: 'ChangeTransactionCategorySummaryPopup',
  components: {
    Popup: BaseUI.Popup,
    TransactionDetails,
    ChangeCategoryPath,
  },
  created() {
    Segment.trackUserInteraction('ChangeTransactionCategorySummaryPopup_Shown', {
      businessName: this.transaction.businessName,
      transactionId: this.transaction.transactionId,
    });
  },
  computed: {
    ...mapState('editTransaction', ['transaction', 'categoryRuleType']),
    ...mapGetters('editTransaction', ['selectedCategory', 'originalCategory', 'ENRICHMENT_INPUT_TYPES', 'categoryToVariant', 'valueForPredicate']),
    isEnrichByBusinessName() {
      return this.categoryRuleType === this.ENRICHMENT_INPUT_TYPES.BUSINESS_NAME;
    },
    explanation() {
      if (this.categoryRuleType === this.ENRICHMENT_INPUT_TYPES.BUSINESS_NAME) {
        return `כל ההוצאות מ"${this.transaction.businessName}" יופיעו ב${this.selectedCategory.pluralLabel}`;
      }
      if (this.categoryRuleType === this.ENRICHMENT_INPUT_TYPES.PAYMENT_APP_ADDRESSEE) {
        return `כל ההעברות ל"${this.transaction.paymentAppAddressee}" יופיעו ב${this.selectedCategory.pluralLabel}`;
      }
      return undefined;
    },
    buttonOptions() {
      return [{
        title: 'אישור',
        size: 'slim',
        action: this.onSave,
        variant: 'primary',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('editCashflow', ['moveTransaction']),
    back() {
      Segment.trackUserInteraction('ChangeTransactionCategorySummaryPopup_BackClicked', {
        businessName: this.transaction.businessName,
        originalCategory: this.transaction.expense,
        transactionId: this.transaction.transactionId,
      });
      this.$emit('close');
      this.openModal({
        component: ChangeTransactionCategorySettingsPopup,
        props: {},
      });
    },
    trackInteraction() {
      if (this.originalCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED) {
        Segment.trackUserInteraction('MoveFromFixedSummaryPopup_ConfirmClicked', {
          businessName: this.transaction.businessName,
          transactionId: this.transaction.transactionId,
          transactionExpense: this.transaction.expense,
        });
      } else if (this.originalCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_EXPENSE) {
        Segment.trackUserInteraction('MoveFromExcludedSummaryPopup_ConfirmClicked', {
          businessName: this.transaction.businessName,
          expenseCategory: this.transaction.expense,
          transactionId: this.transaction.transactionId,
        });
      } else {
        Segment.trackUserInteraction('ChangeTransactionCategorySummaryPopup_ConfirmClicked', {
          businessName: this.transaction.businessName,
          originalCategory: this.transaction.expense,
          selectedCategory: this.selectedCategory.expense,
          applyTo: this.categoryRuleType,
          transactionId: this.transaction.transactionId,
        });
      }
    },
    async onSave() {
      this.trackInteraction();
      this.$emit('close');
      this.moveTransaction({
        originalCategory: this.originalCategory,
        selectedCategory: this.selectedCategory,
        transaction: this.transaction,
        categoryRuleType: this.categoryRuleType,
        valueForPredicate: this.valueForPredicate,
      });
      EventBus.$emit('open-toaster', {
        component: TransactionMovedToaster,
        props: {
          changedTransaction: this.transaction,
          targetCategory: this.selectedCategory,
        },
      });
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';

  .change-transaction-category-summary-popup {

    .change-category-path {
      margin-bottom: 40px;
    }

    .transaction-details {
      margin-bottom: 24px;
    }

    .explanation {
      font-weight: bold;
      line-height: 16px;

      @include category-color;
    }
  }

</style>
