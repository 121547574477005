<template>
  <insight :insight="insight"
           animation-name="riseup-goal.json"
           button-text="קביעת יעד"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        <span class="bold">התזרים החודשי שלך חיובי.</span>
        <span>אפשר לקבוע יעד חודשי ל{{ monthName }} ולהשאיר סכום קטן בצד בדרך לשינוי גדול!</span>
      </div>
    </template>
  </insight>
</template>

<script>
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import cashflowHistory from '@/store/utilities/cashflow-history';
// eslint-disable-next-line max-len
import RiseupGoalDonePopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/RiseupGoalDonePopup';
import EditMonthlyGoalAmount
  from '../cashflow-view/current-month/components/cashflow-edit/edit-riseup-goal/EditMonthlyGoalAmount';

export default {
  name: 'RiseupGoalInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('RiseupGoalInsightShown');
  },
  computed: {
    monthName() {
      return cashflowHistory.getFullMonthName(this.presentedMonth);
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal']),
    async buttonAction() {
      this.openModal({
        component: EditMonthlyGoalAmount,
        props: {
          onSaveCb: this.onSaveRiseUpGoal,
        },
      });
      await this.markAsRead(this.insight);
    },
    onSaveRiseUpGoal() {
      Segment.trackUserInteraction('EditRisepGoalAmountUponRiseupGoalInsight');
      this.openModal({
        component: RiseupGoalDonePopup,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.bold {
  font-weight: bold;
}

</style>
