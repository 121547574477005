import _ from 'lodash';
import Vue from 'vue';
import router from '@/router/index';
import SignupApi from '../../api/SignupApi';
import LoginApi from '../../api/LoginApi';
import phoneNumber from '../../utils/phoneNumber';
import Segment from '../../Segment';
import questions from '../../pages/responsive-pages/non-authenticated/wine-and-dine/lead-qualification/consts/questions';

const signupFlowSteps = {
  enterDetails: 'enter-details',
  enterCode: 'enter-code',
};

const errorTypes = {
  memberAlreadyExists: 'MEMBER_ALREADY_EXISTS',
  cookiesDisabled: 'COOKIES_DISABLED',
};

const state = {
  currentFlowStep: null,
  // always fetch before use in case the customer refreshed and then continued with the flow
  utmParams: {},
  icrcParam: undefined,
  fbcId: undefined,
  phoneNumber: '',
  firstName: '',
  lastName: '',
  code: '',
  createdCustomerId: null,
  signupStage: null,
  email: '',
  enableMarketingEmails: '',
  serverErrors: {
    generateCode: '',
    createCustomer: '',
    authenticate: '',
    sendBotiIntro: '',
  },
  segmentEventPrefix: '',
  productName: 'cashflow',
};

const getters = {
  signupFlowSteps: () => signupFlowSteps,
  phoneNumber: state => state.phoneNumber.replace(/\D/g, ''),
  amplitudeUtmParams: state => _transformUtmForAmplitude(state.utmParams),
  errorTypes: () => errorTypes,
};

const actions = {
  fetchUtmParamsFromRoute({ commit }) {
    const utmParams = _.pickBy(router.currentRoute.query, (value, key) => (key.startsWith('utm_') && value));
    const icrcParam = router.currentRoute.query.icrc;
    const fbcId = router.currentRoute.query.fbclid;
    commit('setUtmParams', { utmParams });
    commit('setIcrcParam', { icrcParam });
    commit('setFbcIdParam', { fbcId });
  },
  async setCode({ commit }, code) {
    commit('setCode', { code });
  },
  async generateCode({ state, dispatch, commit }, force = false) {
    if (phoneNumber.validatePhoneNumber(state.phoneNumber)) {
      try {
        await LoginApi.generateCode({ phoneNumber: state.phoneNumber, force });
      } catch (e) {
        dispatch('catchServerError', { e, functionName: 'generateCode' });
      }
    } else {
      commit('setError', { error: 'INVALID_PHONE_NUMBER', functionName: 'generateCode' });
      throw new Error('Invalid phone number');
    }
  },
  forceGenerateCode({ dispatch }) {
    dispatch('generateCode', true);
  },
  async createCustomer({ state, dispatch, commit, rootGetters }) {
    if (!state.utmParams.utm_term) {
      const referrerEoy = localStorage.getItem('riseup.utm_referrer_eoy');
      if (referrerEoy) {
        commit('setUtmParams', { utmParams: { ...state.utmParams, utm_term: referrerEoy } });
      }
    }
    try {
      const jtbdMarketingCode = rootGetters['leadQualification/getJTBDAnswerCode'];
      const resp = await SignupApi.createCustomer({
        firstName: state.firstName,
        lastName: state.lastName,
        phoneNumber: state.phoneNumber,
        email: state.email,
        enableMarketingEmails: state.enableMarketingEmails,
        code: state.code,
        utmParams: state.utmParams,
        icrcParam: state.icrcParam,
        fbcId: state.fbcId,
        jtbdMarketingCode,
        product: state.productName,
      });
      const { customerId } = resp.data;
      commit('setCreatedCustomerId', customerId);
    } catch (e) {
      dispatch('catchServerError', { e, functionName: 'createCustomer' });
    }
  },
  async saveCustomerAnswers({ state }) {
    const answers = { ...this.state.leadQualification.answers, wndVersion: questions.WINE_AND_DINE_VERSION };

    if (!_.isEmpty(answers)) {
      Segment.trackUserInteraction('LQAnswers', answers);
      await SignupApi.saveCustomerAnswers(answers);
    }
  },
  async authenticate({ commit, state, dispatch }, code) {
    commit('setCode', { code });
    try {
      await LoginApi.authenticate({ phoneNumber: state.phoneNumber, code });
    } catch (e) {
      dispatch('catchServerError', { e, functionName: 'authenticate' });
    }
  },
  async sendBotiIntro({ dispatch }) {
    try {
      await SignupApi.sendBotiIntro();
    } catch (e) {
      dispatch('catchServerError', { e, functionName: 'sendBotiIntro' });
    }
  },
  catchServerError({ commit, state }, { e, functionName }) {
    if (e.response && e.response.status === 400) {
      commit('setError', { error: e.response.data, functionName });
    } else if (e.response && e.response.status === 409) {
      commit('setError', { error: errorTypes.cookiesDisabled, functionName });
    } else {
      commit('setError', { error: 'UNKNOWN_ERROR', functionName });
    }
    throw e;
  },
};

const mutations = {
  setUtmParams(state, { utmParams }) {
    state.utmParams = utmParams;
  },
  setIcrcParam(state, { icrcParam }) {
    state.icrcParam = icrcParam;
  },
  setFbcIdParam(state, { fbcId }) {
    state.fbcId = fbcId;
  },
  setPhoneNumber(state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  setFirstName(state, firstName) {
    state.firstName = firstName;
  },
  setLastName(state, lastName) {
    state.lastName = lastName;
  },
  setEmail(state, email) {
    state.email = email; // eslint-disable-line
  },
  setEnableMarketingEmails(state, enableMarketingEmails) {
    state.enableMarketingEmails = enableMarketingEmails; // eslint-disable-line
  },
  setCode(state, { code }) {
    state.code = code;
  },
  setError(state, { error, functionName }) {
    Vue.set(state.serverErrors, functionName, error);
  },
  resetError(state, functionName) {
    Vue.set(state.serverErrors, functionName, '');
  },
  setCurrentFlowStep(state, currentFlowStep) {
    state.currentFlowStep = currentFlowStep;
  },
  setCreatedCustomerId(state, customerId) {
    state.createdCustomerId = customerId;
  },
  setSegmentEventPrefix(state, segmentEventPrefix) {
    state.segmentEventPrefix = segmentEventPrefix;
  },
  setProductName(state, productName) {
    state.productName = productName;
  },
};

// Amplitude overrides some of the utm params, and it doesn't matter that we do the identify call, so we override the key names specifically for that
// https://community.segment.com/t/637f2h/initial_utm-gets-overwritten
function _transformUtmForAmplitude(utmParams) {
  return _.mapKeys(utmParams, (v, k) => `signup_${k}`);
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
