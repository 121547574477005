import { client, clientWithoutErrorHandling } from '../http';

export default {
  async getAssets() {
    const response = await client.get('/api/clearing-house/assets');
    return response.data.assets;
  },
  async createConsent({
    firstName,
    lastName,
    phoneNumber,
    city,
    idNumber,
    street,
  }) {
    await clientWithoutErrorHandling.post('/api/clearing-house/consent', {
      firstName,
      lastName,
      phoneNumber,
      city,
      idNumber,
      street,
    });
  },
  async validateConsent(paymentToken) {
    const response = await clientWithoutErrorHandling.post('/api/clearing-house/validate-consent', {
      paymentToken,
    });
    return response.data;
  },
};
