<template>
  <div class="missing-bank-ccs-popup">
    <colored-popup
      class="danger"
      :close-action="closeAction">
      <template v-slot:top-content  >
        <div class="insight-header">
          <span>כדי לקבל תזרים מלא, צריך לחבר את</span>
          <span>{{creditCardNamesText}}</span>
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="insight-content">
          <div v-for="(sourceName, i) of missingSourceNames" :key="i" class="source">
            <img class="logo-image" :src="require(`@/assets/bank-logos/${getLogoBySourceName(sourceName)}`)">
            <div v-for="(bankCC, i) of missingBankCCsBySource[sourceName]" :key="i" class="accounts">
              <div class="account-number">{{bankCC.accountNumberPiiValue}} ••••</div>
            </div>
          </div>
          <div>
            <div>העברת בהצלחה את חשבון {{bankName}}</div>
            <div>{{bankAccountNumber}} לחיבור ישיר.</div>
            <br />
            <div>
              <span>בחיבור ישיר המערכת לא מקבלת את פירוט עסקאות האשראי מהבנק, ולכן</span>
              <span class="bold">צריך לחבר את {{ creditCardNamesText }}.</span>
            </div>
            <riseup-button v-for="(button, index) of buttons" :key="index"
                           class="continue-btn"
                           :action="button.action"
                           :variant="button.variant"
                           :title="button.text"
                           :size="'slim'"/>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div @click="dismissInsight" class="footer-btn">הבנתי, אל תציגו לי יותר</div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';

export default {
  name: 'MissingBankCCsAfterOBKMigrationPopup.vue',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    missingBankCCsBySource: { type: Object, required: true },
    bankName: { type: String, required: true },
    credentialsId: { type: String, required: true },
    creditCardNamesText: { type: String, required: true },
    insight: { type: Object, required: true },
  },
  created() {
    Segment.trackUserGot('MissingBankCCsAfterOBKMigrationPopupLoaded');
  },
  computed: {
    ...mapState('credentials', ['credsToAccounts']),
    bankAccountNumber() {
      const creds = _.find(this.credsToAccounts, { credentialsId: this.credentialsId });
      const bankAccount = _.chain(creds.accountNumberPiiIds).filter(account => account.sourceType === 'bank').first().value();
      return bankAccount && bankAccount.accountNumberPiiValue;
    },
    missingSourceNames() {
      return _.keys(this.missingBankCCsBySource);
    },
    buttons() {
      return [
        {
          text: 'לחיבור חשבון האשראי',
          variant: 'primary',
          action: () => {
            Segment.trackUserInteraction('MissingBankCCsAfterOBKMigrationPopup_ConnectBankCCClicked');
            this.openModal({
              component: AddCredentialsFlow,
              props: {
                sourceNameToAdd: _.first(this.missingSourceNames),
              },
              popupAlignment: 'full-screen',
            });
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('insights', ['markAsRead']),
    getLogoBySourceName(sourceName) {
      return getSourceConfigurationBySourceName(sourceName).logoFile;
    },
    closeAction() {
      Segment.trackUserInteraction('MissingBankCCsAfterOBKMigrationPopup_CloseClicked');
      this.closeModal();
    },
    dismissInsight() {
      Segment.trackUserInteraction('MissingBankCCsAfterOBKMigrationPopup_CloseClicked', this.insight);
      this.closeModal();
      this.markAsRead(this.insight);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.missing-bank-ccs-popup {
  text-align: right;

  .insight-header {
    color: $riseup_white;
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
  }

  .insight-content {
    font-size: 18px;
    line-height: 26px;
    .source {
      margin-bottom: 32px;

      img {
        margin-bottom: 16px;
      }
    }

    .account-number {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 24px;
    }

    .continue-btn {
      margin-top: 26px;
    }

    .logo-image{
      width: 175px;
    }
  }

  .footer-btn {
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: $riseup_black;
    cursor: pointer;
  }

}

</style>
