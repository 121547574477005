import Vue from 'vue';
import * as moment from 'moment';
import Segment from '@/Segment';

const CHURN_SURVEY_KEY = 'riseup.churnSurvey';
const SURVEY_FLAVORS = {
  OPEN_BANKING: 'open-banking',
  TEN_TO_TEN: 'ten-to-ten',
  YEARLY_SUBSCRIPTION: 'yearly-subscription',
  FREE_MONTH_BEFORE_CHURN: 'free-month-before-churn',
};

const DEFAULT_FLAVOR = SURVEY_FLAVORS.FREE_MONTH_BEFORE_CHURN;

const state = {
  answers: {},
  flavor: DEFAULT_FLAVOR,
  intent: null,
};

const getters = {
  surveyFlavors: () => SURVEY_FLAVORS,
};

const actions = {
  setSurveyAnswer({ commit }, { questionName, answer }) {
    commit('setAnswer', { questionName, answer });
  },
  setCustomerIntent({ commit }, intent) {
    commit('setIntent', intent);
  },
  async saveChurnSurveyDetails({ rootState, dispatch }) {
    await dispatch('subscriptionState/fetchSubscriptionState', {}, { root: true });
    const { subscriptionState, since } = rootState.subscriptionState;
    const { customerId } = rootState.session;
    localStorage.setItem(CHURN_SURVEY_KEY, JSON.stringify({
      id: customerId,
      state: subscriptionState,
      onStateSince: since,
    }));
  },
  reportToSegment({ state }, eventName) {
    const churnCustomerDetails = JSON.parse(localStorage.getItem(CHURN_SURVEY_KEY));
    if (churnCustomerDetails && churnCustomerDetails.id) {
      Segment.trackUserInteraction(eventName, {
        customerId: churnCustomerDetails.id,
        subscriptionState: churnCustomerDetails.state,
        timeOnPreviousState: moment.duration(moment().diff(churnCustomerDetails.onStateSince)).humanize(),
        intent: state.intent,
        ...state.answers,
      });
    } else {
      Segment.trackUserInteraction('ChurnSurveyHasNoCustomerId');
    }
  },
};

const mutations = {
  setIntent(state, intent) {
    state.intent = intent;
  },
  setAnswer(state, { questionName, answer }) {
    Vue.set(state.answers, questionName, answer);
    if (state.answers.q2Answer === 'יש יותר מדי עדכוני סיסמאות' || state.answers.q1Answer === 'לא מרגיש בטוח') {
      state.flavor = SURVEY_FLAVORS.OPEN_BANKING;
    } else if (state.answers.q2Answer === 'לא מתאים לי תזרים מה-1 בחודש') {
      state.flavor = SURVEY_FLAVORS.TEN_TO_TEN;
    } else if (state.answers.q1Answer === 'השירות לא מתאים לי בתקופה הנוכחית') {
      state.flavor = SURVEY_FLAVORS.FREE_MONTH_BEFORE_CHURN;
    } else if (state.answers.q1Answer === 'השירות יקר מדי' && state.answers.q2Answer !== 'אין לי כסף לשלם על זה כרגע') {
      state.flavor = SURVEY_FLAVORS.YEARLY_SUBSCRIPTION;
    } else {
      state.flavor = DEFAULT_FLAVOR;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
