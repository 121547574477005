<template>
  <insight :insight="insight"
           :animation-name="'merge-insight.json'"
           button-text="לחבר את הכרטיס החסר"
           :background-variant="'red'"
           :loop="false"
           :show-dismiss="false"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        <span>ההסכמה שנתת ב{{sourceName}} אושרה, אבל</span>
        <span class="ri-font-weight-bold">חלק מהכרטיסים שהיו קודם חסרים עכשיו, </span>
        <span>ולכן התזרים לא מעודכן</span>
      </div>
    </template>
  </insight>
</template>

<script>

import Segment from '@/Segment';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import { mapActions } from 'vuex';

import MissingRegularCCsAfterOBKMigrationPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/MissingRegularCCsAfterOBKMigrationPopup';
import Insight from './components/Insight';

export default {
  name: 'MissingRegularCCsAfterOBKMigrationInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('MissingRegularCCsAfterOBKMigrationInsight_Shown');
  },
  computed: {
    missingRegularCCs() {
      return this.insight.missingRegularCCs;
    },
    sourceName() {
      return getSourceConfigurationBySourceName(this.missingRegularCCs[0].source).displayName;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      this.openModal({
        component: MissingRegularCCsAfterOBKMigrationPopup,
        props: {
          missingCCs: this.missingRegularCCs,
          insight: this.insight,
        },
      });
      Segment.trackUserInteraction('MissingRegularCCsAfterOBKMigrationInsight_Clicked');
    },
  },
};

</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
