<script>
import DDLogs from '@/DDLogs';
import Segment from '@/Segment';

export default {
  name: 'PaymentHelper',
  methods: {
    creditCardFormInitFailed() {
      DDLogs.error(`Failed to initialize credit card details form in ${this.$options.name}`);
      Segment.trackUserGot('CreditCardDetailsFormFailedToLoad', { componentName: this.$options.name });
    },
  },
};
</script>
