// @ts-nocheck
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import BaseUI from '@riseupil/base-ui';
import cookies from 'vue-cookies';
import Vue2TouchEvents from 'vue2-touch-events';
import VueClipboard from 'vue-clipboard2';
import CustomerApi from '@/api/CustomersApi';
import App from './App.vue';
import Segment from './Segment';
import DDLogs from './DDLogs';
import Intercom from './Intercom';
import Sentry from './Sentry';
import router from './router';
import store from './store';
import config from './config';
import interceptors from './api/interceptors';
import validator from './rules/validators';

import './style';

const RELOAD_LOCAL_STORAGE_KEY = 'reload-riseup-';

function getTriesRetryLocalStorageKey(currentHash) {
  return `${RELOAD_LOCAL_STORAGE_KEY}-${currentHash}`;
}

const currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

async function _validateLatestVersion() {
  DDLogs.log('checking latest app version');
  const latestDeployedHash = config.get().hash;
  const isLastestAppVersion = currentHash === latestDeployedHash;
  DDLogs.log(isLastestAppVersion ? 'Client has latest app version' : 'Client has an older version', { currentHash, latestDeployedHash });
  if (!isLastestAppVersion) {
    return await _tryReloadApp();
  }

  return isLastestAppVersion;
}

async function _getCustomerId() {
  try {
    const activeMember = await CustomerApi.getActiveMember();
    return activeMember?.customerId;
  } catch {
    return undefined;
  }
}

async function _tryReloadApp() {
  const customerId = await _getCustomerId();
  const localStorageKey = getTriesRetryLocalStorageKey(currentHash);
  const clientAlreadyRetried = localStorage.getItem(localStorageKey);
  if (!clientAlreadyRetried) {
    DDLogs.log('Reloading App', { customerId, currentHash });
    localStorage.setItem(localStorageKey, 'true');
    window.location.reload();
    return false;
  }
  DDLogs.log('App was already reloaded once, not reloading again!', { customerId, currentHash });
  return true;
}

config.fetch().then(async () => {
  DDLogs.init();
  // check android
  if (await _validateLatestVersion()) {
    Vue.config.productionTip = false;
    Vue.use(Segment);
    Vue.use(Vue2TouchEvents);
    Vue.use(Intercom);
    Vue.use(cookies);
    Vue.use(BaseUI);
    Vue.use(VueClipboard);
    Sentry.init();
    interceptors.init();
    validator.init();
    const links = document.querySelectorAll('link[rel*=\'icon\']');
    // eslint-disable-next-line no-param-reassign
    links.forEach(link => { link.href = `/${config.get().favicon}`; });

    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      router,
      store,
      components: { App },
      template: '<App/>',
    });
  }
});
