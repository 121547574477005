<template>
  <benefit-notification-box-base :icon-path="iconText" :description="description" :title="title" :color="color"/>
</template>

<script>
import BenefitNotificationBoxBase from '@/base-components/BenefitNotificationBoxBase.vue';
import BenefitMixin from '@/pages/responsive-pages/authenticated/subscription/V2/BenefitMixin.vue';
import { mapState } from 'vuex';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';

export default {
  name: 'BenefitNotificationBoxV2',
  components: {
    BenefitNotificationBoxBase,
  },
  props: {
    flavor: {
      type: String,
      validator: val => ['account-settings', 'subscription-type', 'credit-card', 'signup-offering', 'signup', 'signup-timeline'].includes(val),
      required: true,
    },
  },
  mixins: [
    BenefitMixin,
  ],
  computed: {
    ...mapState('subscription', ['pricing', 'type']),
    description() {
      if (this.flavor === this.flavors.accountSettings) {
        return this.isGenericFreeMonthBenefit
          ? `${this.monthString} בחינם. כדי לממש את ההטבה, צריך לבחור סוג מינוי ולעדכן אמצעי תשלום.`
          : `${this.benefitSpecificText}. כדי לממש את ההטבה, צריך לבחור סוג מינוי ולעדכן אמצעי תשלום.`;
      }
      if (this.flavor === this.flavors.signup) {
        return this.isGenericFreeMonthBenefit
          ? `${this.monthString} בחינם.`
          : `${this.benefitSpecificText}.`;
      }
      if (this.flavor === this.flavors.subscriptionType) {
        const subscriptionTypeText = this.type === SUBSCRIPTION_TYPES.YEARLY
          ? 'ולאחר מכן מעבר לתשלום שנתי.'
          : 'ולאחר מכן תשלום רגיל.';
        return this.isGenericFreeMonthBenefit
          ? `${this.monthString} בחינם, ${subscriptionTypeText}`
          : `${this.benefitSpecificText}, ${subscriptionTypeText}`;
      }
      if (this.flavor === this.flavors.signupOffering) {
        const details = this.isGenericFreeMonthBenefit
          ? `${this.moreMonthsString} בחינם אחרי תקופת הנסיון. `
          : `${this.percentageBenefitSignupOfferingText} `;
        return `${details}כדי לממש את ההטבה יש לעדכן אמצעי תשלום.`;
      }
      if (this.flavor === this.flavors.signupTimeline) {
        const details = this.isGenericFreeMonthBenefit
          ? `${this.moreMonthsString} בחינם אחרי תקופת הנסיון. `
          : `${this.percentageBenefitSignupTimelineText} `;
        return `${details} לאחר מכן מעבר למינוי בתשלום רגיל.`;
      }
      return this.isGenericFreeMonthBenefit // this.flavor === this.flavors.creditCard
        ? `${this.monthString} בחינם. כדי לממש את ההטבה צריך לעדכן אמצעי תשלום.`
        : `${this.benefitSpecificText}. כדי לממש את ההטבה צריך לעדכן אמצעי תשלום.`;
    },
    title() {
      if (this.flavor === this.flavors.accountSettings) {
        return 'מגיעה לך הטבה בדמי המינוי!';
      }
      if (this.flavor === this.flavors.signup) {
        return 'מגיעה לך הטבה בדמי המינוי!';
      }
      if (this.flavor === this.flavors.subscriptionType) {
        return 'המינוי יתחיל בסיום ההטבה';
      }
      if (this.flavor === this.flavors.signupOffering) {
        return ' מגיעה לך הטבה בדמי המינוי!';
      }
      if (this.flavor === this.flavors.signupTimeline) {
        return ' מגיעה לך הטבה!';
      } // this.flavor === this.flavors.creditCard
      return 'מגיעה לך הטבה בדמי המינוי!';
    },
    iconText() {
      if ([this.flavors.accountSettings, this.flavors.creditCard, this.flavors.signupOffering, this.flavors.signupTimeline].includes(this.flavor)) {
        return 'end-of-year/gift.svg';
      }
      // flavor = flavors.subscriptionType | signup
      return 'icons/heart-icon.svg';
    },
    color() {
      if ([this.flavors.accountSettings, this.flavors.creditCard, this.flavors.signupOffering, this.flavors.signupTimeline].includes(this.flavor)) {
        return 'green';
      }
      // flavor = flavors.subscriptionType | signup
      return 'blue';
    },
  },
};
</script>

<style scoped lang="scss">
</style>
