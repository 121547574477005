<template>
  <div class="credentials-account-list">
    <div class="header">
      <div class="source-description">
        <div class="checkmark-holder">
          <img src="/images/v.svg" class="checkmark">
        </div>
        <div class="source-name-wrapper">
          <div class="card-title">{{ sourceName }}</div>
          <div class="subtitle">{{ name }}</div>
        </div>
      </div>
    </div>
    <div v-if="accounts" class="source-accounts">
      <div class="account" v-for="(account, index) in orderedAccounts" :key="account.accountNumberPiiValue" :class="{ excluded: account.isExcluded }">
        <span class="circle">{{ index + 1 }}</span>
        <span class="account-number">{{ typeof account === 'object' ? account.accountNumberPiiValue : account }}</span>
        <span class="card-stars" v-if="type === 'card'">****</span>
        <span class="not-connected" v-if="account.isExcluded"> [ לא מחובר לתזרים ]</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'ConnectedCredentialsDetails',
  props: {
    source: Object,
  },
  computed: {
    sourceName() {
      return this.source.displayName;
    },
    name() {
      return this.source.name;
    },
    accounts() {
      return this.source.accounts;
    },
    type() {
      return this.source.type;
    },
    orderedAccounts() {
      return _.sortBy(this.accounts, account => !!account.isExcluded);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .credentials-account-list {
    text-align: right;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .source-description {
        display: flex;

        .source-name-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .card-title {
            @include card-title;
            margin-bottom: 5px;
          }
        }

        .checkmark-holder {
          margin-left: 15px;
        }
      }
    }

    .source-accounts {
      margin-top: 18px;

      .account {
        height: 30px;
        padding-top: 15px;

        .circle {
          display: inline-block;
          height: 16px;
          width: 16px;
          line-height: 18px;
          font-weight: bold;
          font-size: 8px;
          vertical-align: middle;
          text-align: center;
          border-radius:8px;
          color: $riseup_white;
          background: $riseup_green;
          margin-left: 12px;
          margin-bottom: 3px;
        }

        .account-number {
          font-size: 16px;
        }

        &.excluded {
          color: $riseup_gray_2;

          .circle {
            background: $riseup_gray_1;
          }
        }

        .not-connected {
          margin-right: 10px;
        }
      }
    }
  }
</style>
