<template>
  <div class="advisory-review-2 padding-bottom-ml">
    <div class="content">
      <div class="padding-horizontal-ml">
        <advisory-top-header @close="close" @back=back variant="light-blue"/>
        <Stepper :current-step="stepperFiller" :total-steps="flowStepsNumber"/>
        <div class="ri-title margin-top-ml margin-bottom-sm">{{ header }}</div>
      </div>
      <img src="/images/advisory/advisory-review-image.svg"/>
      <div class="review-item ri-alte-bold ri-small-display padding-horizontal-ml">
        <div class="margin-bottom-s">
          <span>תומר עזר לי והסביר איך</span>
          <span class="blue">ליישם הכל בצורה הכי פרקטית.</span>
          <span> זה מה שהכי אהבתי. עבר איתי נקודה נקודה והסביר מה צריך לעשות צעד אחרי צעד.״</span>
        </div>
        <div class="ri-bold-body">— הודיה, לקוחת רייזאפ Future</div>
      </div>
    </div>
    <div class="padding-horizontal-ml btn">
      <riseup-button :action="next"
                     :size="'slim'"
                     title="להמשיך"
                     :variant="'primary'"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '@/Segment';
import AdvisoryMixin from '@/mixins/AdvisoryMixin.vue';
import AdvisoryTopHeader from './AdvisoryTopHeader';

export default {
  name: 'AdvisoryReview2',
  components: {
    AdvisoryTopHeader,
    RiseupButton: BaseUI.RiseupButton,
    Stepper: BaseUI.Stepper,
  },
  mixins: [AdvisoryMixin],
  data() {
    return {
      isSaver: false,
    };
  },
  props: {
    stepperFiller: {
      type: Number,
      default: 8,
    },
  },
  created() {
    Segment.trackUserGot('AdvisoryReview2');
    this.isSaver = this.cashflowUITree[cashflowViewConsts.cashflowTypes.fixedSaving]?.categories.length > 0;
  },
  methods: {
    ...mapActions('advisory', ['setSavingAnswer']),
    next() {
      this.$emit('next');
    },
    back() {
      this.$emit('back');
    },
    close() {
      this.$emit('close');
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    ...mapGetters('advisory', ['selectedAnswers']),
    header() {
      return 'מאות לקוחות רייזאפ כבר צועדים  לעבר היעדים שלהם. קבלו את הודיה:';
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.advisory-review-2 {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $riseup_light_blue;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    flex: 1;
    text-align: start;

    .review-item {
      position: absolute;
      z-index: 1;
      top: 250px;

      .blue {
        color: $riseup_blue;
      }
    }
  }

  .btn{
    z-index: 2;
  }
}
</style>
