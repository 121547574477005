<template>
  <full-screen-modal :close-action="closeAction" :back-action="computedStepBack" :supportOverflow="true">
    <template v-slot:content>
      <div class="dynamic-deposit-flow">
        <component :is="currentStep.component"
                   v-bind="currentStep.props"
                   class="component"/>
      </div>
    </template>
  </full-screen-modal>
</template>

<script>
import Segment from '@/Segment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import FullScreenModal from '@/base-components/FullScreenModal';
import DDChooseAmount from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/DDChooseAmount';
import DDEnterIdNumber from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/DDEnterIdNumber';
import DDEnterOTP from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/DDEnterOTP';
import DDConfirmDetails from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/DDConfirmDetails';
import DDSignature from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/DDSignature';
import DDCelebrate from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/DDCelebrate';
import DDErrorPage from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/DDErrorPage';
import DDLogs from '@/DDLogs';

export default {
  name: 'DynamicDepositFlow',
  components: {
    FullScreenModal,
  },
  props: {
    insight: {
      type: Object,
    },
  },
  data() {
    return {
      currentIndex: 0,
      loading: true,
      generalError: false,
    };
  },
  async created() {
    await this.initSavings();
    this.setDepositSum(undefined);
  },
  computed: {
    ...mapGetters('dynamicSavings', ['isMonthlyDeposit', 'isReduceMonthlyDeposit']),
    chooseAmountStepName() {
      if (this.isMonthlyDeposit) {
        return 'ChooseMonthlyAmount';
      }
      if (this.isReduceMonthlyDeposit) {
        return 'ReduceMonthlyAmount';
      }
      return 'ChooseOpportunisticAmount';
    },
    steps() {
      return [
        {
          component: DDChooseAmount,
          props: {
            nextStep: this.nextStep,
            showErrorPage: this.showErrorPage,
          },
          name: this.chooseAmountStepName,
        },
        {
          component: DDEnterIdNumber,
          props: {
            nextStep: this.nextStep,
            showErrorPage: this.showErrorPage,
          },
          name: 'EnterSocialIdNumber',
          stepBack: this.stepBack,
        },
        {
          component: DDEnterOTP,
          props: {
            nextStep: this.nextStep,
            showErrorPage: this.showErrorPage,
          },
          name: 'EnterOTP',
          stepBack: this.stepBack,
        },
        {
          component: DDConfirmDetails,
          props: {
            nextStep: this.nextStep,
          },
          stepBack: () => {
            this.currentIndex = 0;
          },
          name: 'PreCheckout',
        },
        {
          component: DDSignature,
          props: {
            nextStep: this.nextStep,
            showErrorPage: this.showErrorPage,
          },
          stepBack: this.stepBack,
          name: 'Signature',
        },
        {
          component: DDCelebrate,
          props: {
            insight: this.insight,
            nextStep: this.closeAction,
            showErrorPage: this.showErrorPage,
          },
          name: 'Congratulations',
        },
      ];
    },
    currentStep() {
      if (this.generalError) {
        return {
          component: DDErrorPage,
          props: {
            insight: this.insight,
          },
        };
      }
      return this.steps[this.currentIndex];
    },
    computedStepBack() {
      if (this.steps[this.currentIndex].stepBack) {
        return this.steps[this.currentIndex].stepBack;
      }
      return undefined;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('savings', ['initSavings']),
    ...mapMutations('dynamicSavings', ['setDepositSum']),
    showErrorPage() {
      Segment.trackUserInteraction('DynamicDeposit_StepFailed', { currentStep: this.steps[this.currentIndex].name });
      this.generalError = true;
    },
    closeAction() {
      Segment.trackUserInteraction(`DynamicDeposit_${this.steps[this.currentIndex].name}_Closed`);
      this.closeModal();
      this.initSavings().catch(error => {
        DDLogs.error('Failed refreshing saving', { error });
      });
    },
    nextStep() {
      if (!(this.currentIndex === this.steps.length - 1)) {
        this.currentIndex++;
      }
    },
    stepBack() {
      if (!(this.currentIndex === 0)) {
        this.currentIndex--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.dynamic-deposit-flow {
  background-color: $riseup_white;
  width: 100%;

  .component {
    margin-bottom: 30px;
  }
}
</style>
