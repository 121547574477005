<template>
  <div v-if="visible" class="sticky-container">
    <div :class="['positioning-container', {bounce: !!component}]">
      <Transition :name="appearAndDisappearTransitionName">
        <component :is="component" v-bind="componentProps"/>
      </Transition>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
  name: 'BottomCornerPopup',
  computed: {
    ...mapState('bottomCornerPopup', ['component', 'componentProps', 'appearAndDisappearTransitionName', 'visible']),
  },
};
</script>

<style lang="scss" scoped>
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.sticky-container {
  position: sticky;
  bottom: 0;
  float: left;

  .fade-enter-active, .fade-leave-active {
    transition: opacity 10ms;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  .positioning-container {
    position: absolute;
    bottom: 0;
    left: 0;

    &.bounce {
      animation: bounce 2s ease infinite;
    }

  }
}
</style>
