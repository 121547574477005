<template>
  <div class="demographic-info-done">
    <lottie-player src="/animations/Check.json" :autoplay="true" :loop="true"/>
    <div class="title">תודה!</div>
    <div class="title bottom">אנחנו על זה, תובנות חדשות על הכסף שלך יגיעו בקרוב.</div>
    <riseup-button :variant="'primary'" :size="'slim'" :title="'סגור'" :action="close"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'DemographicInfoPopup',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserInteraction('DemographicInfoDoneShown');
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    close() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.demographic-info-done {
  padding: 24px;
  .title {
    font-size: 18px;
    line-height: 25px;
    font-weight: bold;
    &.bottom {
      margin-bottom: 56px;
    }
  }
}
</style>
