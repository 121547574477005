import { client } from '../http';

export async function createDemographicInfo(demographicInfo) {
  const response = await client.post('/api/demographic-info', demographicInfo);
  return response.data;
}

export async function getDemographicInfo() {
  const response = await client.get('/api/demographic-info');
  return response.data;
}

export async function updateCashflowStartDay(cashflowStartDay) {
  const response = await client.post('/api/cashflow-start-day', { cashflowStartDay });
  return response.data;
}
