import consts from '@/api/constants';
import credentialsEncryptor from '@/utils/credentialsEncryptor';
import { client } from '../http';

async function fetchOnboardingState() {
  const response = await client.get('/api/partial-auth/onboarding-state');
  return response.data;
}

async function onboardingPageSubmit(pageName) {
  const response = await client.post(`/api/onboarding-state/page-submit/${pageName}`);
  return response.data;
}

async function bankDetailsAccountInvestigationSubmit(inputs) {
  const response = await client.post('/api/onboarding-state/bank-details/submit/account-investigation', { inputs });
  return response.data;
}

async function incomeInvestigationSubmit(inputs, financialProfileScore) {
  const response = await client.post('/api/onboarding-state/income-investigation/submit', { inputs, financialProfileScore });
  return response.data;
}

async function highIncomeExclusionSubmit(includedIncomes) {
  const response = await client.post('/api/onboarding-state/high-income-exclusion/submit', { includedIncomes });
  return response.data;
}

async function expenseInvestigationSubmit(inputs) {
  const response = await client.post('/api/onboarding-state/expense-investigation/submit', { inputs });
  return response.data;
}

async function bankDetailsSubmitCredentials({ sourceName, credentials, name, sourceType, provider }) {
  const encryptedCredentials = _safeEncrypt(credentials, { name, sourceName });
  const response = await client.post('/api/onboarding-state/bank-details/submit/credentials', {
    encryptedCredentials, name, user: consts.CUSTOMER_USER, sourceName, sourceType, provider,
  });
  return response.data;
}

async function bankDetailsSubmitDirect() {
  const response = await client.post('/api/onboarding-state/bank-details/submit/direct', {});
  return response.data;
}

async function bankDetailsSubmitOpenBankingApprovedConsent(sourceName, credentialsId) {
  const response = await client.post('/api/onboarding-state/bank-details/submit/open-banking/approved-consent', { sourceName, credentialsId });
  return response.data;
}

async function bankDetailsSubmitOpenBankingCreateConsent(redirectUrl, sourceName, sourceType) {
  const response = await client.post(
    '/api/onboarding-state/bank-details/submit/open-banking/consent/',
    { redirectUrl, sourceName, sourceType },
  );
  return response.data;
}

async function creditCardDetailsSubmitOpenBankingApprovedConsent(sourceName, credentialsId) {
  const response = await client.post('/api/onboarding-state/credit-card-details/submit/open-banking/approved-consent', { sourceName, credentialsId });
  return response.data;
}

async function creditCardDetailsSubmitOpenBankingCreateConsent(redirectUrl, sourceName) {
  const response = await client.post(
    '/api/onboarding-state/credit-card-details/submit/open-banking/consent/',
    { redirectUrl, sourceName },
  );
  return response.data;
}

async function bankDetailsSubmitOpenBankingPartiallyAuthorised() {
  const response = await client.post('/api/onboarding-state/bank-details/submit/open-banking/partially-authorised/', {});
  return response.data;
}

async function creditCardDetailsSubmit({ sourceName, credentials, name, sourceType, provider }) {
  const encryptedCredentials = _safeEncrypt(credentials, { name, sourceName });
  const response = await client.post('/api/onboarding-state/credit-card-details/submit', {
    encryptedCredentials, name, user: consts.CUSTOMER_USER, sourceName, sourceType, provider,
  });
  return response.data;
}

async function forwardLookingInformationSubmit() {
  const response = await client.post('/api/onboarding-state/forward-looking-information/submit', {});
  return response.data;
}

async function planAheadSubmit() {
  const response = await client.post('/api/onboarding-state/plan-ahead/submit', {});
  return response.data;
}

async function oshBalanceInformationSubmit() {
  const response = await client.post('/api/onboarding-state/osh-balance-information/submit', {});
  return response.data;
}

async function securityInformationSubmit() {
  const response = await client.post('/api/onboarding-state/security-information/submit', {});
  return response.data;
}

async function openBankingSwitchToCredentials() {
  const response = await client.post('/api/onboarding-state/partially-authorised/submit/switch-to-credentials/');
  return response.data;
}

async function openBankingContinue() {
  const response = await client.post('/api/onboarding-state/partially-authorised/submit/continue/');
  return response.data;
}

function _safeEncrypt(object, logData) {
  const encrypted = credentialsEncryptor.encrypt(object);
  if (encrypted === 'false') {
    throw new Error(`Failed encrypting object ${JSON.stringify(logData)}`);
  }
  return encrypted;
}

export default {
  fetchOnboardingState,
  onboardingPageSubmit,
  bankDetailsAccountInvestigationSubmit,
  incomeInvestigationSubmit,
  highIncomeExclusionSubmit,
  expenseInvestigationSubmit,
  bankDetailsSubmitCredentials,
  bankDetailsSubmitDirect,
  creditCardDetailsSubmit,
  bankDetailsSubmitOpenBankingApprovedConsent,
  bankDetailsSubmitOpenBankingCreateConsent,
  bankDetailsSubmitOpenBankingPartiallyAuthorised,
  openBankingSwitchToCredentials,
  openBankingContinue,
  creditCardDetailsSubmitOpenBankingCreateConsent,
  creditCardDetailsSubmitOpenBankingApprovedConsent,
  forwardLookingInformationSubmit,
  planAheadSubmit,
  oshBalanceInformationSubmit,
  securityInformationSubmit,
};
