<template>
  <info-tool-tip-help-popup class="visa-cal-creds-help-popup"
    :title="'איך לחבר חשבון ויזה כאל?'"
    :cx-dude="{ name: 'yael', displayName: 'יעל' }"
    :source-name="'ויזה כאל'"
    :popup-name="'VisaCalCredsHelpPopup'"
    :on-close="onClose">
    <template v-slot:description>
      <div class="description">
        <div>
        כדי לחבר את ויזה כאל ל-riseup צריך לספק למערכת את פרטי המשתמש הקבועים (כולל סיסמא קבועה) איתם נכנסים לאתר או לאפליקציה של כאל.
        </div>
        <div>&nbsp;</div>
        <div class="bold">יש לך קוד מזהה אינטרנט?</div>
        אם אין לך, כל מה שצריך לעשות זה להתחבר לחשבון באתר כאל <a target="_blank" rel="noopener noreferrer"
           href="https://cal4u.cal-online.co.il/digitalwallet/#!/digital/login/dw.internetId/////////">בלינק הזה</a>
        עם קוד זמני שנשלח ב-SMS, ואז לרשום לעצמך בצד את הקוד המזהה שרואים על המסך.
        <div>&nbsp;</div>
        <div class="bold">שכחת את שם המשתמש והסיסמא הקבועה?</div>
        אפשר לשחזר אותם <a target="_blank" rel="noopener noreferrer"
           href="https://services.cal-online.co.il/card-holders/Screens/AccountManagement/PasswordRestoring.aspx">בלינק הזה</a>
        <div>&nbsp;</div>
        <div class="bold">אין לך שם משתמש וסיסמא קבועה?</div>
        אפשר ליצור אותם <a target="_blank" rel="noopener noreferrer"
           href="https://services.cal-online.co.il/Card-Holders/SCREENS/AccountManagement/Joining.aspx">בלינק הזה</a>
      </div>
    </template>
  </info-tool-tip-help-popup>
</template>

<script>

import InfoToolTipHelpPopup from '../InfoToolTipHelpPopup.vue';

export default {
  name: 'VisaCalCredsHelpPopup',
  components: {
    InfoToolTipHelpPopup,
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .visa-cal-creds-help-popup {
    .description {
      a {
        color: $riseup_blue;
        font-weight: bold;
      }
      .bold {
        font-weight: bold;
      }
    }
  }

</style>
