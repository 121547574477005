<template>
  <card :has-content-padding="false">
    <div class="credentials-card">
      <snack-bar v-if="showStatus" :variant="headerVariant" class="header no-left-padding">
        <template v-slot:primary>
          <div class="header-text-container">
            <div class="bold">{{ headerText }} </div>
            <div v-if="displayHeaderTextDetails" class="header-text-details">{{ headerTextDetails }}</div>
          </div>
        </template>
        <template v-slot:secondary>
          <credentials-menu v-if="showMenu" :source="source" />
        </template>
      </snack-bar>
      <open-banking-tag v-if="source.isOBK"/>
      <div class="top-container margin-bottom-l">
        <source-logo :source-name="sourceName" :name="name" class="logo-container"/>
        <slot name="buttons"></slot>
      </div>
      <div v-if="source.displayIncidentTooltip" class="obk-failure-message">
        <div class="obk-failure-warning message">
          <img src="/images/info-icon-blue.svg" class="warning-icon">
          <div class="warning-text">
        <span class="bold">
          יש תקלה ב{{ source.indefiniteDisplayName }}.
        </span>
            אנחנו בקשר עם הצוות הטכני של {{ source.displayName }} כדי להחזיר את השירות כמה שיותר מהר.
          </div>
        </div>
      </div>
      <separator/>
      <expandable-connected-account-list
        :title="dropDownText"
        :disabled="disabled"
        :accounts="accounts"
        :source-type="sourceType"
        :always-expand="alwaysExpand"
      />
    </div>
  </card>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import SourceLogo from '@/base-components/onboarding/SourceLogo';
import ExpandableConnectedAccountList from '@/base-components/onboarding/ExpandableConnectedAccountList';
import Segment from '@/Segment';
import InvalidCredsConsts from '@/constants/invalid-creds';
import OpenBankingTag from '@/base-components/onboarding/openBanking/OpenBankingTag';

import { mapActions } from 'vuex';
import ConsentActionsMixin from '@/base-components/onboarding/openBanking/ConsentActionsMixin.vue';
import CredentialsMenu from '../credentials/credentials-list/CredentialsMenu';

export default {
  name: 'CredentialsCard',
  components: {
    Separator: BaseUI.Separator,
    SnackBar: BaseUI.SnackBar,
    Card: BaseUI.Card,
    ExpandableConnectedAccountList,
    SourceLogo,
    CredentialsMenu,
    OpenBankingTag,
  },
  mixins: [ConsentActionsMixin],
  data() {
    return {
      loading: false,
    };
  },
  props: {
    source: { type: Object, required: false },
    enableCredentialsUpdate: { type: Boolean, required: false, default: false },
    showStatus: { type: Boolean, default: true },
    alwaysExpand: { type: Boolean, default: false },
    shouldUpdate: { type: Boolean, default: false },
    showMenu: { type: Boolean, default: true },
  },
  async created() {
    if (this.status === 'passwordInvalid') {
      Segment.trackUserGot('CredentialsCardShownInvalid', {
        reason: this.source?.statusExtraData?.reason,
        sourceName: this.sourceName,
      });
    }
  },
  computed: {
    name() {
      return this.source.name;
    },
    sourceName() {
      return this.source.sourceName;
    },
    sourceType() {
      return this.source.type;
    },
    status() {
      return this.source.status;
    },
    accounts() {
      return this.source.accounts;
    },
    disabled() {
      return !this.isConnected;
    },
    headerVariant() {
      if (this.status === 'partiallyAuthorized') {
        return 'incomplete';
      }
      if (this.status === 'unvalidated') {
        return 'warning';
      }
      if (!this.isConnected || this.shouldUpdate) {
        return 'danger';
      }
      return 'success';
    },
    headerText() {
      if (this.shouldUpdate) {
        return 'צריך לחדש את ההסכמה';
      }

      if (this.status === 'partiallyAuthorized') {
        return 'ממתין לאישור שותפ.ה בבנק';
      }

      return ` חשבון ${this.connectedOrDisconnectedText}`;
    },
    displayHeaderTextDetails() {
      return (this.status === 'passwordInvalid') || this.isObkFailed;
    },
    headerTextDetails() {
      if (this.isObkFailed) {
        return ` יש תקלה ב${this.source.indefiniteDisplayName}`;
      }
      if (this.source && this.source.openBankingConsentId && this.status === 'passwordInvalid') {
        return 'ההסכמה לא בתוקף';
      }
      return InvalidCredsConsts.ConnectedCredentialsCardInvalid[this.source.errorReason] || '';
    },
    connectedOrDisconnectedText() {
      if (this.status === 'unvalidated') {
        return 'מתעדכן...';
      }
      return this.isConnected ? 'מחובר' : 'לא מחובר.';
    },
    isConnected() {
      return !['passwordInvalid', 'obkSourceFailure'].includes(this.status);
    },
    dropDownText() {
      return this.sourceType === 'bank' ? 'חשבונות כלולים בתזרים' : 'כרטיסים כלולים בתזרים';
    },
    isObkFailed() {
      return this.status === 'obkSourceFailure';
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('credentials', ['deleteCredentials']),
    replaceAll(str, find, replace) {
      return str.split(find).join(replace);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '../../scss/mixins';
.credentials-card {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: $riseup_white;
  border-radius: $card-border-radius;

  .header {
    @include responsive-padding;
    display: flex;
    align-items: center;
    height: 40px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding-left: 15px;
    font-size: 14px;
    &.no-left-padding {
      padding-left: 0;
    }

    .header-text-container {
      display: flex;
      justify-content: flex-start;
      width: 100%;

      .header-text-details {
        margin-right: 4px;
      }
    }

    @media (min-width: 430px) {
      padding-left: 15px;
    }

    .toggle-menu {
      position: relative;
      padding: 20px;
      right: 20px;
      cursor: pointer;
    }
  }

  .obk-failure-message {
    margin: 0 24px 24px 24px;
    line-height: 18px;
    border-radius: 8px;
  }

  .top-container {
    @include responsive-padding-top;
    @include responsive-padding;

    .button-container {
      .connect-credit-card-button {
        width: 100%;
      }
    }

    .customer-id-field {
      font-size: 20px;
      direction: ltr;
      text-align: right;
    }
  }

  .reconnect-button {
    margin-top: 10px;
    width: 100%;
    height: 50px;

    ::v-deep .button-titles .button-title {
      font-size: 16px;
    }
  }

  .action {
    display: flex;
    align-items: center;
    font-family: SimplerPro;
    font-size: 14px;
    padding: 0 8px;
    cursor: pointer;
    width: calc(100% - 48px);
    margin: 24px;
    height: 50px;
  }

  .obk-failure-warning {
    background-color: rgba($riseup_light_blue, 0.5);
  }

  .warning-text {
    font-size: 14px;
    padding: 17px 0 17px 17px;
    line-height: 20px;
    font-weight: 400;

  }

  .warning-icon {
    padding: 19px;
    height: 100%;
  }

  .renew-consent-message {
    background-color: rgba($riseup_light_blue, 0.5);
  }

  .message {
    line-height: 18px;
    border-radius: 8px;
    display: flex;
  }

  .logo-container {
    width: 100%;
    height: 100%;
    object-fit: contain;

    .logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .bold {
    font-weight: bold;
  }
}

</style>
