import Vue from 'vue';
import pocketMoneyUtils from '@/pages/responsive-pages/non-authenticated/pocket-money/pocket-money-utils';

const state = {
  currentPage: 0,
  parent: '',
  parentFirstName: '',
  parentFamilyName: '',
  interestAmount: 12,
  childName: '',
  childGrade: null,
  currentMoney: 100,
  phoneNumber: '',
  email: '',
};

const getters = {
  dailyAmount: state => pocketMoneyUtils.calcInterestAffect(state.interestAmount, state.currentMoney, 1),
  weeklyAmount: state => pocketMoneyUtils.calcInterestAffect(state.interestAmount, state.currentMoney, 7),
  endOfCurrentSchoolYear: state => pocketMoneyUtils.getEndOfYearAmount(state.currentMoney, state.interestAmount, 0),
};

const actions = {
  set({ commit }, { prop, value }) {
    commit('setState', { prop, value });
  },
  nextPage({ commit, state }) {
    commit('setCurrentPage', state.currentPage + 1);
  },
  backPage({ commit, state }) {
    commit('setCurrentPage', state.currentPage - 1);
  },
};

const mutations = {
  setState(state, { prop, value }) {
    Vue.set(state, prop, value);
  },
  setCurrentPage(state, value) {
    state.currentPage = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
