<template>
  <insight :insight="insight"
                    :animation-name="'OpportunisticSaving.json'"
                    :button-text="'קדימה'"
                    :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        {{budgetMonth}} הסתיים בתזרים חיובי של <span class="amount">{{formattedAmount}} ₪!</span>
        אפשר להגדיל את קופת הגמל להשקעה שלך בקלות דרכנו.
      </div>
    </template>
  </insight>
</template>

<script>

import Segment from '@/Segment';
import { mapActions } from 'vuex';
import moment from 'moment';
import moneyUtils from '@/utils/money';
import dateUtils from '@/utils/dates';
import MeitavOpportunisticSavingPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/savings/MeitavOpportunisticSavingPopup';
import cashflowViewConsts from '@/constants/cashflow-view';
import Insight from './components/Insight';

export default {
  name: 'MeitavOpportunisticSavingInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object, required: true },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.proposedOpportunisticSavingAmount, 0);
    },
    budgetMonth() {
      return dateUtils.getHebrewMonth(moment(this.insight.budgetDate).subtract(1, 'month').format('YYYY-MM'));
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    buttonAction() {
      this.openModal({
        component: MeitavOpportunisticSavingPopup,
        props: {
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE_INCOME,
          insight: this.insight,
          closeAction: this.closePopup,
        },
      });
    },
    closePopup() {
      Segment.trackUserInteraction('MeitavOpportunisticSavingPopupClosed', this.insight);
      this.closeModal();
    },
  },
};

</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}
</style>
