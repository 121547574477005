/* eslint-disable max-len */
import ChurnGoodbyePage from '@/pages/responsive-pages/non-authenticated/churn/ChurnGoodbyePage';
import { addCashflowBudgetDateToParams } from '@/router/budget-date-param-helper';

const InviteAuthenticateToken = () => import('../pages/responsive-pages/invite-authenticated/InviteAuthenticateToken.vue');
const DemoCashflowApp = () => import('../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/DemoCashflowApp.vue');
const PocketMoney = () => import('../pages/responsive-pages/non-authenticated/pocket-money/PocketMoney');
const SignupFlow = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/signup/SignupFlow.vue');
const SignupFlowV3 = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/signup-v3/SignupFlowV3.vue');
const SignupFlowV3Ots = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/signup-v3/SignupFlowV3Ots.vue');
const LeadQualificationSurvey = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/lead-qualification/LeadQualificationFlow.vue');
const LoginEnterPhoneNumber = () => import('../pages/responsive-pages/non-authenticated/login/LoginEnterPhoneNumber.vue');
const LoginEnterCode = () => import('../pages/responsive-pages/non-authenticated/login/LoginEnterCode.vue');
const LoginAs = () => import('../pages/responsive-pages/non-authenticated/login/LoginAs.vue');
const WineAndDineFlow = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/WineAndDineFlow.vue');
const GeneratePromotionFlow = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/GeneratePromotionFlow.vue');
const FinishLQ = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/lead-qualification/FinishLQ.vue');
const SignupPartners = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/signup/SignupPartners.vue');
const SharedPage = () => import('../pages/responsive-pages/non-authenticated/share/SharedPage');
const OtherProductsIntroductionPage = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/OtherProductsIntroduction.vue');
const PassRouteThrough = () => import('../pages/responsive-pages/non-authenticated/wine-and-dine/from-other-products/PassRouteThrough.vue');

const DormantInsights = () => import('../pages/responsive-pages/non-authenticated/DormantInsights.vue');
const WhatsappTest = () => import('../pages/responsive-pages/non-authenticated/WhatsappTest.vue');

const DISCOUNT_PROMO_CODE = 'discount';

// eslint-disable-next-line import/prefer-default-export
export const noAuthRoutes = [
  {
    path: '/signup',
    name: 'WineAndDineFlow',
    component: WineAndDineFlow,
    async beforeEnter(to, from, next) {
      // convert discount utm to promo code
      if (to.query.utm_source === 'Discount_app' && !to.query.promoCode) {
        const query = { promoCode: DISCOUNT_PROMO_CODE, ...to.query };
        next({ path: to.path, query });
      } else {
        next();
      }
    },
    props: route => {
      const props = {};
      if (route.query.refToken) {
        props.referralData = { householdName: route.query.hn, referralToken: route.query.refToken };
      }
      if (route.query.promoCode) {
        props.promoCode = route.query.promoCode;
      }
      return props;
    },
  },
  {
    path: '/generate-promo',
    name: 'GeneratePromotionFlow',
    component: GeneratePromotionFlow,
    props: route => {
      const props = {};
      if (route.query.promoSource) {
        props.promoSource = route.query.promoSource;
      }
      return props;
    },
  },
  {
    path: '/dormant-insights',
    name: 'DormantInsights',
    component: DormantInsights,
  },
  {
    path: '/whatsapp-test',
    name: 'WhatsappTest',
    component: WhatsappTest,
  },
  {
    path: '/signup/lq/vp/:componentName',
    name: 'LeadQualificationSurvey',
    component: LeadQualificationSurvey,
    props: route => ({ componentName: route.params.componentName, componentType: 'valueProp' }),
  },
  {
    path: '/signup/lq/finish',
    name: 'FinishLQ',
    component: FinishLQ,
  },
  {
    path: '/signup/lq/question/:componentName',
    name: 'LeadQualificationSurvey',
    component: LeadQualificationSurvey,
    props: route => ({ componentName: route.params.componentName, componentType: 'question' }),
  },
  {
    path: '/demo/:budgetDate?',
    name: 'Demo',
    component: DemoCashflowApp,
    beforeEnter(to, _, next) {
      return next(addCashflowBudgetDateToParams('demo', to));
    },
  },
  {
    path: '/pocket-money',
    name: 'PocketMoney',
    component: PocketMoney,
  },
  {
    path: '/signup/details/v3',
    name: 'SignupV3',
    component: SignupFlowV3,
    props: route => ({
      promoCode: route.query.promoCode,
      offerId: route.query.offerId,
      hundredDaysChallenge: route.query.hundredDaysChallenge,
    }),
  },
  {
    path: '/signup/ots',
    name: 'SignupV3Ots',
    component: SignupFlowV3Ots,
  },
  {
    path: '/signup/details',
    name: 'Signup',
    component: SignupFlow,
    props: route => ({ promoCode: route.query.promoCode }),
  },
  {
    path: '/signup/partners',
    name: 'SignupPartners',
    component: SignupPartners,
    props: route => ({
      icrc: route.query.icrc,
      utm_params: {
        utm_source: route.query.utm_source,
        utm_medium: route.query.utm_medium,
        utm_campaign: route.query.utm_campaign,
      },
    }),
  },
  {
    path: '/goodbye',
    name: 'GoodbyePage',
    component: ChurnGoodbyePage,
  },
  {
    path: '/internal/login-as',
    name: 'LoginAs',
    component: LoginAs,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginEnterPhoneNumber,
  },
  {
    path: '/login-enter-code',
    name: 'LoginEnterCode',
    component: LoginEnterCode,
  },
  {
    path: '/ots-login',
    name: 'OtsLogin',
    component: LoginEnterPhoneNumber,
    async beforeEnter(to, from, next) {
      const { params } = { redirectTo: 'ots' };
      next({ query: params });
    },
  },
  {
    path: '/invite',
    name: 'MemberInvite',
    component: InviteAuthenticateToken,
  },
  {
    path: '/shared/:sharePageId',
    name: 'SharedPage',
    component: SharedPage,
    props: route => ({
      sharePageId: route.params.sharePageId,
    }),
  },
  {
    path: '/introduction',
    name: 'OtherProductsIntroduction',
    component: PassRouteThrough,
    children: [
      {
        path: '/',
        component: OtherProductsIntroductionPage,
        props: { currentPage: 'Introduction' },
      },
      {
        path: '/full-image',
        component: OtherProductsIntroductionPage,
        props: { currentPage: 'FullImage' },
      },
      {
        path: '/organized-categories',
        component: OtherProductsIntroductionPage,
        props: { currentPage: 'OrganizedCategories' },
      },
      {
        path: '/personalized-categories',
        component: OtherProductsIntroductionPage,
        props: { currentPage: 'PersonalizeCategories' },
      },
      {
        path: '/reducible-expenses',
        component: OtherProductsIntroductionPage,
        props: { currentPage: 'ReducibleExpenses' },
      },
      {
        path: '/one-month-free',
        component: OtherProductsIntroductionPage,
        props: { currentPage: 'OneMonthFree' },
      },
      {
        path: '/few-more-details',
        component: OtherProductsIntroductionPage,
        props: { currentPage: 'FewMoreDetails' },
      },
    ],
  },
];
