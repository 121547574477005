/* eslint-disable max-len */
import auth0 from '@/utils/auth0';
import DDLogs from '@/DDLogs';
import OpenBankingLandingPage from '@/pages/responsive-pages/non-authenticated/open-banking/OpenBankingLandingPage.vue';
import store from '@/store';

const OtsResults = () => import(/* webpackChunkName: "authenticated-base" */ '@/pages/responsive-pages/authenticated/ots/OtsResults.vue');
const AgamFlow = () => import(/* webpackChunkName: "authenticated-base" */ '@/pages/responsive-pages/authenticated/ots/AgamFlow');
const OnboardFlowOts = () => import(/* webpackChunkName: "authenticated-base" */ '../pages/responsive-pages/authenticated/onboard2/OnboardFlowOts');

// eslint-disable-next-line import/prefer-default-export
export const otsAuthenticatedRoutes = [
  {
    path: '/agam',
    name: 'AgamFlow',
    component: AgamFlow,
  },
  {
    path: '/ots-results',
    name: 'OtsResults',
    component: OtsResults,
  },
  {
    path: 'decode-and-redirect/:encodedUrl',
    name: 'OtsDecodeAndRedirect',
    async beforeEnter(to, from, next) {
      const { path, params } = auth0.decodeUrl(to.params.encodedUrl);
      DDLogs.log('Redirecting path', { redirectedFrom: to.path });
      next({ path: `/${path}`, query: params });
    },
  },
  {
    path: '/ots-ob',
    name: 'OtsOnboard',
    component: OnboardFlowOts,
    async beforeEnter(to, from, next) {
      await store.dispatch('credentials/fetchObkSourceConfigurations');
      next();
    },
  },
  {
    path: '/ots-welcome-back/:source',
    name: 'OpenBankingLandingPage',
    component: OpenBankingLandingPage,
    meta: {
      preventRouterBack: true,
    },
  },
  {
    path: '*',
    redirect: { name: 'OtsAuthenticateToken' },
  },
];
