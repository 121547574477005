<template>
  <div class="terms margin-l">
        <check-box :toggle="toggleEnabledTerms" :initially-checked="enableTerms">
          <template v-slot:title>
            <div class="approve-terms-text">
              קראתי ואישרתי את
              <span class="link" @click="openTerms">תנאי המסלול המלאים.</span>
            </div>
          </template>
        </check-box>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUi from '@riseupil/base-ui';

export default {
  name: 'PaymentTerms',
  components: {
    CheckBox: BaseUi.CheckBox,
  },
  computed: {
    ...mapState('subscription', ['enableTerms']),
    ...mapGetters('oneOffOfferings', ['configs']),
  },
  methods: {
    ...mapActions('subscription', ['toggleEnabledTerms']),
    async openTerms() {
      await window.open(this.configs.termsUrl, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
  .terms {
    font-size: 14px;
    line-height: 20px;

    .approve-terms-text {
      margin-right: 15px;
    }

    .link {
      text-decoration: underline;
      cursor: pointer;
      color: $riseup_blue;
    }
  }
</style>
