<template>
  <popup :close-action="closeActionClicked" class="share-popup">
    <template v-slot:content>
      <div class="popup-content">
        <div class="scaled-share-asset" ref="scaledWrapper">
          <component :is="shareComponent" v-bind="shareComponentProps" class="shared-asset" ref="shareAsset"></component>
        </div>
        <div class="popup-button">
          <riseup-button :action="onShare"
                         :icon="require('@/assets/share-popup/share.svg')"
                         :disabled="isSharing"
                         class="native-share-button"
                         title="לשתף עם חברים"
                         variant="primary"
                         size="slim"/>
        </div>
      </div>
    </template>
  </popup>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import shareEvents from '@/utils/shareEvents';
import shareUtils from '@/utils/share';

export default {
  name: 'SharePopup',
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    shareComponent: {
      type: Object,
      required: true,
    },
    shareComponentProps: {
      type: Object,
    },
    filename: {
      type: String,
      required: true,
    },
    segmentData: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      isSharing: false,
      shareAssetPromise: null,
      shareCanvas: null,
      listener: null,
    };
  },
  created() {
    shareEvents.trackSharePopupShown(this.segmentData);
  },
  mounted() {
    // Seems like the html2canvas function takes a lot of CPU before it starts it async work, so if we're running it without the wait,
    // the whole popup is not being shown on the screen for a short period of time, making it feels like the app is stuck
    // I added this waitFor since it solves the problem, but TBH, I would prefer to wait for the right event (it's not 'mounted')
    // so we'll know the popup is shown and then run this function
    this.shareAssetPromise = this.createShareAssetPromise();
    this.listener = new ResizeObserver(this.updateAssetScale);
    this.listener.observe(this.$refs.shareAsset.$el);
  },
  beforeDestroy() {
    this.listener.disconnect();
  },
  methods: {
    ...mapActions('share', ['setCelebrationDismissed']),
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('sharePage', ['createSharePage']),
    async createShareAssetPromise() {
      this.shareCanvas = await shareUtils.createShareAsset(this.$refs.shareAsset.$el, (doc, el) => {
        // We need to revert the transform we did for fitting the modal
        // eslint-disable-next-line no-param-reassign
        el.style.transform = 'none';
      });
    },
    closeActionClicked() {
      shareEvents.trackSharePopupClosed(this.segmentData);
      this.closeModal();
    },
    async onShare() {
      shareEvents.trackNativeShareClicked(this.segmentData);
      await this.shareAsset();
    },
    async downloadImage() {
      await shareUtils.downloadCanvas(this.shareCanvas, `${this.filename}.png`, this.segmentData);
    },
    async shareAsset() {
      this.isSharing = true;
      try {
        await this.shareAssetPromise;
        await shareUtils.shareImage(this.shareCanvas, `${this.filename}.png`, this.segmentData);
      } finally {
        this.isSharing = false;
      }
    },
    updateAssetScale() {
      const ratio = this.$refs.scaledWrapper.offsetWidth / this.$refs.shareAsset.$el.offsetWidth;
      const height = this.$refs.shareAsset.$el.offsetHeight * ratio;
      this.$refs.scaledWrapper.style.height = `${height}px`;
      this.$refs.shareAsset.$el.style.transform = `scale(${ratio})`;
    },
  },
};

</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.popup.share-popup {
  .scaled-share-asset {
    // This height is required because since we want to make the popup short by default. The reason is that usually
    // in development mode, we might have a scroll if the dev tools are open which and this element is higher by default.
    // The alternative is to listen to resize events and start recalculating the size of the element to make it in the middle
    height: 50px;
    margin: 24px 0;
    .shared-asset {
      transform-origin: top right;
    }
  }
  .popup-button {
    margin-top: 24px;
  }
}

</style>
