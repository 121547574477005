<template>
  <div class="transaction-summary-popup">
    <popup :title="title"
           :close-action="close"
           :back-action="backAction"
           :buttons="buttons">
      <template v-slot:content>
        <change-category-path :old-category="fromCategory" :new-category="toCategory" />
        <transaction-details class="details" :transaction="transaction" />
      </template>
      <template v-slot:footer>
        <div class="cancel" v-if="cancelButton">
          <div class="cancel-text" v-on:click.stop="cancelButton.action">{{ cancelButton.text }}</div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import SegmentTracker from '@/mixins/SegmentTracker';
import ChangeCategoryPath from '../../cashflow-view/current-month/components/cashflow-edit/edit-transaction/ChangeCategoryPath';
import TransactionDetails from '../../cashflow-view/current-month/components/cashflow-edit/edit-transaction/TransactionDetails';

export default {
  name: 'TransactionSummaryPopup',
  components: {
    Popup: BaseUI.Popup,
    ChangeCategoryPath,
    TransactionDetails,
  },
  props: {
    transaction: { type: Object, required: true },
    fromCategory: { type: Object, required: true },
    toCategory: { type: Object, required: true },
    title: { type: String, default: 'ההוצאה תעבור' },
    backAction: { type: Function, required: false },
    cancelButton: { type: Object, required: false },
    buttons: { type: Array, required: false },
  },
  mixins: [SegmentTracker],
  created() {
    this.initTracker(
      'TransactionSummaryPopup_',
      {
        transaction: this.transaction,
        fromCategory: { cashflowCategory: this.fromCategory.cashflowCategory, categoryName: this.fromCategory.categoryName },
        toCategory: { cashflowCategory: this.toCategory.cashflowCategory, categoryName: this.toCategory.categoryName },
      },
    );
    this.track('Entered');
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    close() {
      this.track('CloseClicked');
      this.closeModal();
    },
  },
};
</script>

<style scoped lang="scss">

@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/category-color-mixins';

.transaction-summary-popup {

  .change-category-path {
    margin-bottom: 40px;
  }

  .details {
    margin-bottom: 40px;
  }

  .cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .cancel-text {
      color: $riseup_black;
      font-size: 18px;
      font-weight: normal;
    }
  }
}

</style>
