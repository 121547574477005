<template>
  <div class="unsupported-desktop" v-if="showUnsupportedDesktop">
    <img :src="require('@/assets/red-eight-pointed-alert-flower.svg')" class="alert-icon-in-popup">
    <div class="unsupported-title">
      להשלמת חיבור חשבון {{ source.displayName }}
      נצטרך לעבור למכשיר הנייד
    </div>
    <div class="unsupported-subtitle">
      החיבור נעשה בעזרת האפליקציה של בנק {{ source.displayName }} ולכן לא נתמך במחשב.
      <span class="bold">אפשר להמשיך בקלות בעזרת הלינק שיש לך בוואטסאפ</span>
    </div>
  </div>
  <div class="dead-end" v-else-if="isFacebookApp">
    <dead-end/>
  </div>
  <div v-else-if="source.openBanking" class="open-banking-credentials-details">
    <div class="page-content">
      <img v-if="showLogo" class="logo-image" :src="require(`@/assets/bank-logos/${source.logoFile}`)">
      <div class="direct-connection-title">
        <span class="direct-connection-text">חיבור ישיר</span>
      </div>
      <div class="title">
        <span>חיבור חשבון {{source.openBanking.title}}</span>
        <span class="bold">בקלות, בלי שם משתמש וסיסמה</span>
      </div>
      <ValidationObserver ref="observer" slim>
      <credentials-input-field :class="{'input-container': !useNewCredNameText}"
                               :field-name="inputNameText"
                               :validations="'min-length:2'"
                               :error="validateByNameAlreadyUsed"
                               :error-message="nameAlreadyUsedErrorMessage"
                               :field-type="'text'"
                               :field-value="name"
                               :update-input="updateName"/>
      <div v-if="useNewCredNameText" class="ri-label cred-name-disclaimer">כאן צריך לבחור שם לחשבון שישמש אותך בהמשך במערכת רייזאפ</div>
      <credentials-input-field
                               class="input-container"
                               ref="identification"
                               :field-name="'מספר תעודת זהות'"
                               field-type="number"
                               :error="preventConsentCreationForSameIdNumber"
                               :error-message="idAlreadyUsedErrorMessage"
                               :field-value="credentials['idNumber']"
                               :validations="'id'"
                               :update-input="updateCredentials('idNumber')"/>
      </ValidationObserver>
      <leumi-iphone-chrome-alert v-if="showLeumiIPhoneChromeAlert"/>
      <base-tool-tip v-else-if="isBank" class="info-tooltip" icon-path="/images/info-icon-blue.svg">
        <div class="ri-bold-label">מדובר בחשבון משותף?</div>
        <div>מספר הזהות פה צריך להיות מקושר לפרטי המשתמש ב{{sourceName}}</div>
      </base-tool-tip>
      <div class="button-wrapper">
        <riseup-button class="save-button" :disabled="!buttonEnabled" :title="buttonTitle" @click="saveOpenBanking"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUi from '@riseupil/base-ui';
import DeadEnd from '@/pages/responsive-pages/authenticated/DeadEnd';
import ApproveConsentOverridePopup from '@/base-components/onboarding/openBanking/ApproveConsentOverridePopup';
import AggregatorApi from '@/api/AggregatorApi';
import AgentsUtils from '@/utils/agentsUtils';
import OpenBankingCelebrationPopup
  from '@/pages/responsive-pages/non-authenticated/open-banking/OpenBankingCelebrationPopup';
import RedirectToOpenBankingPopup from '@/base-components/onboarding/openBanking/RedirectToOpenBankingPopup';
import BaseToolTip from '@/base-components/onboarding/BaseToolTip';
import { shouldTryAgainObk } from '@/base-components/credentials/credentials-retry-utils';
import ConsentActionsMixin from '@/base-components/onboarding/openBanking/ConsentActionsMixin';
import { BROWSER_NAMES, getBrowser } from '@/utils/incognito-utils';
import CredentialsInputField from '../CredentialsInputField.vue';
import Segment from '../../../Segment';
import LeumiIphoneChromeAlert from './LeumiIphoneChromeAlert';

export default {
  name: 'OpenBankingDetails',
  components: {
    CredentialsInputField,
    RiseupButton: BaseUi.V2Components.RiseupButton,
    DeadEnd,
    BaseToolTip,
    LeumiIphoneChromeAlert,
  },
  mixins: [ConsentActionsMixin],
  props: {
    source: {
      type: Object,
      required: true,
    },
    nameAlreadyUsedError: {
      type: Boolean,
      default: false,
    },
    setNameAlreadyInUseError: {
      type: Function,
    },
    validateName: {
      type: Function,
      required: true,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      credentials: {},
      name: '',
      showError: false,
      consentExistsForIdNumber: false,
    };
  },
  created() {
    Segment.trackUserGot('ExperimentAssignment', {
      experimentId: 'cred-input-name',
      treatment: this.useNewCredNameText ? 'nickname' : 'control',
    });
  },
  computed: {
    ...mapState('onboardingState', ['pageParams']),
    ...mapGetters('onboardingState', ['onboardingCompleted']),
    ...mapGetters('credentials', ['closedBankingCredentialsToUpdate']),
    ...mapGetters('featureFlags', ['useNewCredNameText', 'showLeumiChromeIphoneAlert']),
    inputNameText() {
      return this.useNewCredNameText ? 'איך לקרוא לחשבון הזה?' : 'שם בעל.ת החשבון?';
    },
    showUnsupportedDesktop() {
      return this.source && this.source.openBanking && !this.source.openBanking.allowDesktop && !this.isMobile();
    },
    userAgent() {
      return navigator.userAgent || navigator.vendor || window.opera;
    },
    isFacebookApp() {
      return (this.userAgent.indexOf('FBAN') > -1) || (this.userAgent.indexOf('FBAV') > -1);
    },
    isValidName() {
      return this.name.length > 1;
    },
    nameAlreadyUsedErrorMessage() {
      // Allow using the same name if we also allow to use the same ID (In case we are in cashflow and updating current consent)
      return this.nameAlreadyUsedError ? 'כבר השתמשת בשם הזה' : null;
    },
    validateByNameAlreadyUsed() {
      return this.preventConsentCreationForSameIdNumber && this.nameAlreadyUsedError;
    },
    preventConsentCreationForSameIdNumber() {
      return this.consentExistsForIdNumber && !this.onboardingCompleted;
    },
    idAlreadyUsedErrorMessage() {
      return this.preventConsentCreationForSameIdNumber ? 'כבר השתמשת במספר תעודת הזהות הזה' : null;
    },
    isValidIdNumber() {
      return this.credentials.idNumber && this.credentials.idNumber.length > 8;
    },
    buttonEnabled() {
      return this.isValidName && this.isValidIdNumber;
    },
    buttonTitle() {
      return `לחיבור ישיר ב${this.source.indefiniteDisplayName}`;
    },
    sourceName() {
      return this.source.displayName;
    },
    isBank() {
      return this.source.type === 'bank';
    },
    migratingFromPasswords() {
      return !!this.closedBankingCredentialsToUpdate;
    },
    showLeumiIPhoneChromeAlert() {
      return this.source.id === 'leumi'
        && this.showLeumiChromeIphoneAlert
        && AgentsUtils.isIPhone()
        && getBrowser() === BROWSER_NAMES.CHROME;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    isMobile() {
      return AgentsUtils.isMobile();
    },
    updateCredentials(fieldKey) {
      return value => {
        this.$set(this.credentials, fieldKey, value);
      };
    },
    updateName(name) {
      this.name = name;
      this.setNameAlreadyInUseError(false);
    },
    async saveOpenBanking() {
      this.showError = true;
      Segment.trackUserInteraction('OBKConnectClicked', { sourceName: this.source.sourceName, migratingFromPasswords: this.migratingFromPasswords });
      await this.validateName({ sourceName: this.source.sourceName, name: this.name, isOpenBanking: true });
      const valid = await this.$refs.observer.validate();
      this.$refs.identification.validate();
      if (valid && !this.validateByNameAlreadyUsed) {
        const validConsent = await this.getCredentialsConsent();
        this.consentExistsForIdNumber = !!validConsent;
        if (!this.preventConsentCreationForSameIdNumber) {
          if (this.consentExistsForIdNumber) {
            this.openApproveConsentOverridePopup(validConsent);
          } else if (this.onboardingCompleted || shouldTryAgainObk(this.source.sourceName)) {
            this.openModal({
              component:
              RedirectToOpenBankingPopup,
              props: {
                idNumber: this.credentials.idNumber,
                name: this.name,
                source: this.source,
              },
            });
          } else {
            this.openModal({
              component: OpenBankingCelebrationPopup,
              props: {
                action: () => {
                  this.redirectToGetAccessCode({
                    idNumber: this.credentials.idNumber,
                    name: this.name,
                    source: this.source,
                  });
                },
                source: this.source,
              },
            });
          }
        }
      }
    },
    openApproveConsentOverridePopup(existingValidConsent) {
      this.openModal({
        component: ApproveConsentOverridePopup,
        props: {
          idNumber: this.credentials.idNumber,
          name: this.name,
          source: this.source,
          existingValidConsent,
        },
      });
    },
    async getCredentialsConsent() {
      const { credentialsConsent } = await AggregatorApi.getCredentialsConsent({
        bankIdentifier: this.source.openBanking.aggregatorBankId,
        userIdInBank: this.credentials.idNumber,
      });
      return credentialsConsent;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/spacings';
  @import '../../../scss/mixins';

  .unsupported-desktop {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    @include responsive-padding;
    .unsupported-image {
      width: 130px;
      height: 90px;
      margin: 50px auto;
    }
    .unsupported-title{
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
      margin-bottom: 10px;
      text-align: right;
    }
    .unsupported-subtitle{
      font-size: 18px;
      line-height: 26px;
      text-align: right;
      .bold {
        font-weight: bold;
      }
    }
  }

  .dead-end {
    height: 100%;
  }
  .open-banking-credentials-details {
    width: 100%;

    .info-tooltip{
      margin-top: 5px;
      margin-bottom: 35px;
      font-style: normal;
      font-size: 14px;
      text-align: right;
      line-height: 20px;
    }

    .error-bar {
      @include responsive-padding;
    }

    .page-content {
      padding: 30px;
      @include responsive-padding;

      .logo-image {
        height: 40px;
        margin-bottom: 28px;
      }

      .direct-connection-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        .direct-connection-text {
          font-size: 24px;
          line-height: 28px;
          font-weight: bold;
          margin-left: 12px;
        }

        .direct-connection-badge {
          font-size: 13px;
          border-radius: 4px;
          padding: 3px 7px;
          background-color: $riseup_orange;
          color: $riseup_white;
          font-weight: bold;
        }
      }

      .title {
        color: $riseup_black;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;

        .bold {
          font-weight: bold;
        }
      }

      .tool-tip-wrapper {
        margin-bottom: 35px;
      }

      .input-container {
        margin-bottom: 30px;
      }

      .cred-name-disclaimer {
        margin: $ri-spacing-xxs $ri-spacing-xs $ri-spacing-ml 0;
      }

      .button-wrapper {
        .save-button {
          width: 100%;
        }
      }
    }
  }

</style>
