<template>
  <full-screen-modal :close-action="closeAction">
    <template v-slot:content>
      <div class="source-type-selection">
        <div class="description">
          <div class="title ri-large-headline ri-alte">איזה חשבון תרצו לחבר לשירות?</div>
          <security-info-tooltip />
        </div>
        <separator/>
        <source-type-selection-item text="חשבון בנק"
                                    :image-source="require('@/assets/icons/account_balance_24dp.svg')"
                                    @click="()=>handleSourceTypeSelected('bank')" />
        <separator/>
        <source-type-selection-item text="כרטיס אשראי"
                                    :image-source="require('@/assets/icons/credit_card_24dp.svg')"
                                    @click="()=>handleSourceTypeSelected('cc')" />
        <separator/>
      </div>
    </template>
  </full-screen-modal>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import FullScreenModal from '@/base-components/FullScreenModal';
import EventBus from '@/event-bus/event-bus';
import SecurityInfoTooltip from '@/base-components/SecurityInfoTooltip';
import SourceTypeSelectionItem from './SourceTypeSelectionItem';

export default {
  name: 'SourceTypeSelectionModal',
  components: {
    SourceTypeSelectionItem,
    FullScreenModal,
    SecurityInfoTooltip,
    Separator: BaseUI.Separator,
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleSourceTypeSelected(sourceType) {
      this.$emit('source-type-selected', sourceType);
    },
  },
  mounted() {
    EventBus.$emit('reset-scroll');
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';
.source-type-selection {
  width: 100%;
  .description {
    @include responsive-padding;
    margin: 40px 0 20px 0;
    color: $riseup_black;

    .title {
      text-align: right;
    }

    .tool-tip-wrapper {
      text-align: right;
      margin: 20px 0 40px;
    }
  }

  .credentials-card {
    margin-bottom: 25px;
  }
}
</style>
