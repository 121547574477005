import { client } from '../http';

async function fetchCreditCardQuestions() {
  const response = await client.get('/api/investigator/onboarding/questions/cc');
  return response.data;
}

async function fetchCreditCardExpenses() {
  const response = await client.get('/api/onboarding/cc/expenses');
  return response.data;
}

export default {
  fetchCreditCardQuestions,
  fetchCreditCardExpenses,
};
