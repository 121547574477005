<template>
  <div class="menu-item ri-title" v-on:click="onClick" :class="{ disabled, selected }"
       role="menuitem" tabindex="0" @keyup.enter="onClick" :aria-label="title">
    <div class="icon-container">
      <icon :icon-name="iconName" fill size="24px" :color="iconColor" :optical-size="24"/>
    </div>
    <span class="menu-item-title">
      {{ title }}
      <tag v-if="showNewBadge" text="חדש!" :background-color="tagBgColor" :text-color="tagTextColor" />
    </span>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'MenuItem',
  components: {
    Tag: BaseUI.Tag,
    Icon: BaseUI.Icon,
  },
  data() {
    return {
      tagBgColor: BaseUI.Colors.riseupGreen,
      tagTextColor: BaseUI.Colors.riseupWhite,
      iconColor: BaseUI.Colors.riseupDarkGreen,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconName: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      required: true,
      default: false,
    },
    showNewBadge: {
      type: Boolean,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/border-radius';

.menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $ri-spacing-xs;
  cursor: pointer;
  border-radius: $card-border-radius;
  height: 54px;

  &.selected {
    background: $riseup_beige;
    .icon-container {
      background: $riseup_light_yellow;
    }
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  .icon-container {
    width: 40px;
    height: 40px;
    border-radius: $ri-border-radius-medium;
    background: $riseup_gray_05;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: $ri-spacing-s;
    margin-right: $ri-spacing-xs;
  }

  .menu-item-title {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
  }
}
</style>
