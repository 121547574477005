<template>
  <insight :insight="insight"
           :animation-name="'MonthlySaving.json'"
           :button-text="'מה עכשיו?'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div v-if="!insight.hasSavingEnvelope">
        לפי 6 החודשים האחרונים, נראה שאפשר להעביר כל חודש לחיסכון לפחות
        <span class="amount">{{ formattedAmount }} ₪</span >. כל הכבוד!
      </div>
      <div v-else>
         לפי 6 החודשים האחרונים, נראה שאפשר להעביר כל חודש לחיסכון לפחות
        <span class="amount">עוד {{ formattedAmount }} ₪</span >. כל הכבוד!
      </div>
    </template>
  </insight>
</template>

<script>
import MonthlySavingPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/savings/MonthlySavingPopup';
import cashflowViewConsts from '@/constants/cashflow-view';
import { mapActions } from 'vuex';
import moneyUtils from '@/utils/money';
import Insight from './components/Insight';

export default {
  name: 'MonthlySavingInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.proposedMonthlySavingAmount, 0);
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    buttonAction() {
      const component = MonthlySavingPopup;
      this.openModal({
        component,
        props: {
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
          insight: this.insight,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}
</style>
