import moment from 'moment';
import { OFFERING_TYPES } from '@/api/BillyApi';
import { SIGNUP_OFFERING_IDS } from './oneOff-Offerings';

export default class Boost {
  id = SIGNUP_OFFERING_IDS.BOOST;

  total = 0;

  subscriptionStartDate = moment();

  offeringCard = {
    buttonCtaText: 'להתחיל באחד על אחד',
    buttonCtaIcon: 'person_raised_hand',
    moreInfoLink: 'https://signup.riseup.co.il/flow/boost-product/',
    offeringBullets: [
      'שיחת zoom אישית 1 על 1',
      'מעבר משותף על התזרים וההוצאות',
      'התאמה אישית של התזרים והקטגוריות',
      'הדרכה והתאמת תזרים אישי',
    ],
    isOneOffPayment: true,
    price: 0, // will be filled later from backend
  };

  purchaseInfo() {
    return {
      buttonText: 'הרשמה והתחלת תקופת נסיון',
      details: {
        offerId: 'boost',
        title: 'שיחה עם מומחה',
        subTitle: 'פגישת ייעוץ  והתאמה אישית של התזרים',
        amount: 0, // will be filled later from backend
        type: OFFERING_TYPES.ONE_OFF,
      },
    };
  }

  insertPaymentDetailsPage = {
    title: 'הרשמה לפגישת ייעוץ ומינוי',
    buttonCtaText: 'הרשמה למינוי',
  };

  successPurchasePageInfo = {
    checkedItems: [{
      text: 'הרשמה למינוי',
      checked: true,
    }, {
      text: 'הצטרפות לתוכנית',
      checked: true,
    }, {
      text: 'קביעת פגישה עם מומחה',
      checked: false,
    }, {
      text: 'התחלת התוכנית',
      checked: false,
    }],
  };

  isAvailable({ offerIds, featureFlagAvailableOfferings }) {
    if (offerIds.includes(this.id)) {
      return true;
    }
    return featureFlagAvailableOfferings.includes(this.id);
  }

  startDate() {
    return undefined;
  }
}
