<template>
  <dynamic-deposit-step :authenticated="false" :step="this.$options.name">
    <template v-slot:content>
      <div class="dd-headline-label ri-bold-body">הזדהות</div>
      <div class="ri-large-headline margin-bottom-s">מה מספר תעודת הזהות שלך?</div>
      <div>
        <default-text-input
          :label="'מספר ת.ז. (9 ספרות)'"
          :value="idNumber"
          :error-message="errorMessage"
          input-type="tel"
          @input="setLocalIdNumber"/>
        <default-text-input
          :label="'מספר טלפון'"
          :value="activeMemberPhoneNumber"
          :readonly="true"/>
      </div>
    </template>
    <template v-slot:next-button>
      <dynamic-deposit-button
        title="שלחו לי קוד אימות"
        :action="verifyIdNumberAndContinue"
        :loading="loading"
        :disabled="!idNumber || !!errorMessage"/>
    </template>
  </dynamic-deposit-step>
</template>

<script>

import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapMutations } from 'vuex';
import * as SavingsApi from '@/api/SavingsApi';
import DynamicDepositStep from './DynamicDepositStep';
import DynamicDepositButton from './DynamicDepositButton';

export default {
  name: 'DDEnterIdNumber',
  components: {
    DynamicDepositButton,
    DefaultTextInput: BaseUI.DefaultTextInput,
    DynamicDepositStep,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    showErrorPage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      idNumber: '',
      errorMessage: '',
    };
  },
  created() {
    Segment.trackUserInteraction('DynamicDeposit_EnterSocialIdNumber_Entered');
  },
  computed: {
    ...mapGetters('session', ['activeMemberPhoneNumber']),
  },
  methods: {
    ...mapMutations('dynamicSavings', ['setIdNumber']),
    setLocalIdNumber(id) {
      this.errorMessage = '';
      this.idNumber = id;
      if (!/^\d+$/.test(id)) {
        this.errorMessage = 'אפשר להזין כאן רק ספרות';
      }
    },
    async verifyIdNumberAndContinue() {
      Segment.trackUserInteraction('DynamicDeposit_EnterSocialIdNumber_NextClicked');
      this.loading = true;
      try {
        const { status, data } = await SavingsApi.verifySocialId(this.idNumber);
        if (status === SavingsApi.STATUS.SUCCESS) {
          this.setIdNumber(this.idNumber);
          this.nextStep();
        } else if (status === SavingsApi.STATUS.FAILURE && data === SavingsApi.VERIFY_SOCIAL_ID_NUMBER_ERROR_TYPES.SOCIAL_ID_VERIFICATION_FAILED) {
          Segment.trackUserInteraction('DynamicDeposit_EnterSocialIdNumber_ValidationFailed');
          this.errorMessage = 'תעודת הזהות שהוזנה לא תואמת את מספר הטלפון שלך';
        } else {
          this.showErrorPage();
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
