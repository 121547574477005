<template>
  <div class="merge-question-popup">
    <colored-popup
      class="danger"
      :close-action="closeAction"
      :back-action="backAction"
      :layout="layout">
      <template v-slot:top-content>
        <div class="merge-investigation-header">
          {{title}}
        </div>
      </template>
      <template v-slot:bottom-content>
        <slot name="content" />
        <div v-if="showContactCXButton" class="buttons-section" :class="{'with-padding': layout==='no-content-padding'}">
          <riseup-button v-if="showContactCXButton"
                         class="action-btn"
                         :title="cxButtonTitle"
                         :size="'slim'"
                         :action="handleIntercomClickAction"
                         :variant="'secondary'"
                         :icon="require('@/assets/icons/chat_icon_teriatry.svg')"
                         :id="'cx-just-in-time-new-papas-merge'" />
        </div>
      </template>
      <template v-slot:footer>
        <div @click="footerAction" class="footer-btn">{{ footerTitle }}</div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import Intercom from '@/Intercom';
import { mapActions } from 'vuex';

export default {
  name: 'MergeInvestigationPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    title: String,
    backAction: { type: Function },
    footerTitle: { type: String, default: 'הבנתי, אל תציגו לי יותר' },
    footerAction: { type: Function },
    cxButtonTitle: { type: String, default: 'להתייעץ עם הצוות' },
    showContactCXButton: { type: Boolean, default: true },
    layout: { type: String, default: 'content-padding' },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    handleIntercomClickAction() {
      Segment.trackUserInteraction('JustInTimeCX_Clicked', { source: this.$options.name });
      Intercom.showNewMessage('');
    },
    closeAction() {
      Segment.trackUserInteraction('MergeInvestigationPopup_CloseClicked', { source: this.$options.name });
      this.closeModal();
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.merge-question-popup {
  text-align: right;

  .close-button {
    width: 100%;
  }

  .merge-investigation-header {
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    color: $riseup_white;
  }

  .buttons-section {
    margin-top: 15px;

    &.with-padding{
      padding: 0 24px 22px 24px;
    }
  }

  .footer-btn {
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: $riseup_black;
    cursor: pointer;
  }
}

</style>
