<template>
  <insight :insight="insight"
           :animation-name="'OpportunisticSaving.json'"
           :button-text="'קדימה'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        נראה שיש לך אפשרות לחסוך
        <span class="amount">עוד {{ formattedAmount }} ₪</span>
        כל חודש! אפשר להגדיל את קופת הגמל להשקעה שלך בקלות דרכנו.
      </div>
    </template>
  </insight>
</template>

<script>

import Segment from '@/Segment';
import { mapActions } from 'vuex';
import moneyUtils from '@/utils/money';
import MeitavMonthlySavingPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/savings/MeitavMonthlySavingPopup';
import cashflowViewConsts from '@/constants/cashflow-view';
import Insight from './components/Insight';

export default {
  name: 'MeitavMonthlySavingInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object, required: true },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.proposedMonthlySavingAmount, 0);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    buttonAction() {
      this.openModal({
        component: MeitavMonthlySavingPopup,
        props: {
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
          insight: this.insight,
          closeAction: this.closePopup,
        },
      });
    },
    closePopup() {
      Segment.trackUserInteraction('MeitavMonthlySavingPopupClosed', this.insight);
      this.closeModal();
    },
  },
};

</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}
</style>
