<template>
  <div class="payment-installments padding-vertical-l">
    <div :class="`ri-font-14 margin-bottom-sm margin-bottom-sm`">מספר תשלומים</div>
    <div class="flex-row">
      <div v-for="installmentNumber of selectedOffering.possibleNumberOfInstallments" :key="installmentNumber"
           :class="['installment-option', 'margin-left-s', 'padding-ml', { selected: installmentsCount === installmentNumber }]"
           @click="() => setInstallmentsCount(installmentNumber)">{{ installmentNumber.toString() }}</div>
    </div>
    <div v-if="installmentsCount >= 2" class="margin-top-l">
      <div v-for="installment of installmentsCount" :key="installment">
        <div class="ri-font-14 margin-bottom-s">
          תשלום {{ installmentNumberHebrew(installment) }}  <span class="installment-date padding-right-xs">
          {{ installmentDate(installment - 1) }}</span>
        </div>
        <div class="ri-font-20 ri-font-weight-bold">{{ formattedInstallmentsPrice }} ₪</div>
        <seperator v-if="installment !== installmentsCount" class="margin-top-sm margin-bottom-sm" />
      </div>
    </div>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import moneyUtils from '@/utils/money';
import { mapMutations, mapState } from 'vuex';
import moment from 'moment';

const DATE_FORMAT = 'D.M.YYYY';

export default {
  name: 'PaymentInstallments',
  components: {
    Seperator: BaseUI.Separator,
  },
  props: {
    price: {
      type: Number,
      required: true,
    },
    paymentDate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('oneOffOfferings', ['selectedOffering', 'installmentsCount']),
    formattedInstallmentsPrice() {
      return moneyUtils.formatAmountWithFraction(this.price / this.installmentsCount, 1);
    },
  },
  methods: {
    ...mapMutations('oneOffOfferings', ['setInstallmentsCount']),
    installmentDate(monthsToAdd) {
      return moment(this.paymentDate)
        .add(monthsToAdd, 'month')
        .format(DATE_FORMAT);
    },
    installmentNumberHebrew(installmentNum) {
      switch (installmentNum) {
      case 1:
        return 'ראשון';
      case 2:
        return 'שני';
      case 3:
        return 'שלישי';
      case 4:
        return 'רביעי';
      default:
        return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.payment-installments {
  width: 100%;
  text-align: start;

  .installment-date {
    color: $riseup_gray_3;
  }

  .installment-option {
    display: flex;
    justify-content: center;
    flex: 1;
    cursor: pointer;
    border: 1px solid $riseup_gray_20;
    border-radius: 14px;
    height: 54px;
    align-items: center;

    &:last-child {
      margin-left: 0;
    }

    &.selected {
      font-weight: bold;
      color: $riseup_blue;
      border: 1px solid $riseup_blue;
    }

  }
}
</style>
