import _ from 'lodash';
import moment from 'moment';
import router from '@/router';
import phoneNumberUtils from '@/utils/phoneNumber';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import cashflowModelsApi from '@/api/CashflowModels';
import Segment from '../../Segment';
import Intercom from '../../Intercom';
import Sentry from '../../Sentry';
import DDLogs from '../../DDLogs';
import CustomerApi from '../../api/CustomersApi';
import CustomerActivityApi from '../../api/CustomerActivityApi';
import HansApi from '../../api/HansApi';

const DAYS_IN_TRIAL_VALUE_FOR_CUSTOMERS_NOT_IN_TRIAL = -1;
const DAYS_LEFT_TRIAL_VALUE_FOR_CUSTOMERS_NOT_IN_TRIAL = -1;

function _getDaysSince(subscriptionState) {
  return moment().diff(moment(subscriptionState.since), 'days');
}

const state = {
  authenticated: false,
  inviteToken: '',
  customerId: null,
  members: [],
  activeMember: null,
  activeMemberPhoneNumber: null,
  isRestrictedCustomer: false,
  segmentEventPrefix: '',
};

const getters = {
  firstName: (state, getters) => getters.primaryMember.firstNamePiiValue,
  activeMemberFirstName: state => state.activeMember.firstNamePiiValue,
  activeMemberLastName: state => state.activeMember.lastNamePiiValue,
  activeMemberFullName: state => `${state.activeMember.firstNamePiiValue} ${state.activeMember.lastNamePiiValue}`,
  activeMemberPhoneNumber: state => phoneNumberUtils.visualizePhoneNumber(state.activeMemberPhoneNumber),
  activeMemberEmail: state => state.activeMember.emailPiiValue,
  numberOfMembers: state => state.members.length,
  householdName: state => {
    const customerPrimaryMember = _.find(state.members, m => m.primary);
    const secondMemberCreated = _.chain(state.members)
      .filter(m => !m.primary)
      .sortBy(m => m.created)
      .first()
      .value();
    return _.compact([
      customerPrimaryMember && customerPrimaryMember.firstNamePiiValue,
      secondMemberCreated && secondMemberCreated.firstNamePiiValue,
    ]).join(' ו');
  },
  primaryMember: state => {
    const primaryMember = _.find(state.members, { primary: true }) || {};
    return {
      firstNamePiiValue: primaryMember.firstNamePiiValue,
      lastNamePiiValue: primaryMember.lastNamePiiValue,
      phoneNumberPiiValue: phoneNumberUtils.visualizePhoneNumber(primaryMember.phoneNumberPiiValue),
    };
  },
  memberIsPrimary: state => state.activeMember.primary === true,
  otherMemberFirstName: state => _.chain(state.members)
    .reject(m => m.firstNamePiiValue === state.activeMember.firstNamePiiValue)
    .map('firstNamePiiValue')
    .first()
    .value(),
  otherMembers: state => _.chain(state.members)
    .reject(m => m.firstNamePiiValue === state.activeMember.firstNamePiiValue)
    .map(member => ({ name: member.firstNamePiiValue, phone: member.phoneNumberPiiValue }))
    .value(),

  // eslint-disable-next-line max-len
  inviteMessage: () => 'היי! *נרשמתי ל-riseup!*\nאני מחכה לך בתזרים המשותף שלנו 🎯\nכל מה שצריך לעשות בשביל להצטרף אליי זה *ללחוץ על הלינק המצורף* 👇\n',
};

function _getTrialStats(subscriptionState) {
  const daysInTrial = subscriptionState.state === 'trial' ? _getDaysSince(subscriptionState) : DAYS_IN_TRIAL_VALUE_FOR_CUSTOMERS_NOT_IN_TRIAL;
  const daysUntilEndOfTrial = subscriptionState.state === 'trial'
    ? subscriptionState.daysLeftIncludingPromotion : DAYS_LEFT_TRIAL_VALUE_FOR_CUSTOMERS_NOT_IN_TRIAL;
  return {
    daysInTrial,
    daysUntilEndOfTrial,
  };
}

function _bootIntercom(
  subscriptionState,
  members,
  customerDetails,
  householdName,
  numberOfMembers,
  onboardingStatus,
  activatedAt,
  invalidCredentialsState,
  featureFlagAttributes,
  cashflowTabsEnabled,
  churnPredictionData,
) {
  const { daysInTrial, daysUntilEndOfTrial } = _getTrialStats(subscriptionState);
  const primaryMember = _.find(members, { primary: true });
  const { modelScore, behaviorHighlights, financialHighlights, personHighlights } = churnPredictionData;
  Intercom.boot(
    customerDetails.customerId,
    householdName,
    {
      userHash: customerDetails.intercomUserHash,
      isOnboarding: onboardingStatus !== 'completed',
      activatedAt,
      daysInTrial,
      daysUntilEndOfTrial,
      daysInInvalidCredentials: invalidCredentialsState ? Math.abs(moment(invalidCredentialsState.since)
        .diff(moment(), 'days')) : 0,
      email: primaryMember ? primaryMember.emailPiiValue : null,
      phone: primaryMember.phoneNumberPiiValue,
      firstName1: primaryMember.firstNamePiiValue,
      numberOfMembers,
      mfaEnabled: _.every(state.members, 'oidc'),
      modelScore,
      behaviorHighlights,
      financialHighlights,
      personHighlights,
      ...featureFlagAttributes,
    },
    cashflowTabsEnabled,
  ).then(() => Intercom.showLauncher());
}

const actions = {
  async initRiseupAccount({ commit }, phoneNumber) {
    const members = await CustomerApi.getMembers();
    const activeMember = members.find(m => phoneNumberUtils.comparePhoneNumbers(m.phoneNumberPiiValue, phoneNumber));
    commit('setActiveMember', activeMember);
    commit('setAuthenticated', true);
  },
  async init({ commit, getters, dispatch, rootGetters, rootState }, { incognito, name, segmentEventPrefix }) {
    try {
      performanceService.markStart(PerformanceMarks.INIT_SESSION);
      const [{ restrictedCustomerData: customerDetails, members, activeMember, onboardingStatus },
        invalidCredsState, churnPredictionData] = await Promise.all([
        CustomerApi.getCustomerSessionData(),
        CustomerApi.getCustomerState().then(async consolidatedResponse => {
          const { onboardingState, invalidCredentialsState, timeLimitedOffers, ...subscriptionStateVars } = consolidatedResponse;
          await Promise.all(_.compact([
            onboardingState && dispatch('onboardingState/setOnboardingState', onboardingState, { root: true }),
            dispatch('subscriptionState/setSubscriptionStateVariables', subscriptionStateVars, { root: true }),
            commit('timeLimitedOffers/setTimeLimitedOffers', timeLimitedOffers, { root: true }),
          ]));
          return invalidCredentialsState;
        }),
        cashflowModelsApi.getChurnPrediction(),
      ]);
      const subscriptionState = {
        since: rootState.subscriptionState.since,
        state: rootState.subscriptionState.subscriptionState,
      };
      const { activatedAt } = rootState.onboardingState;

      dispatch('featureFlags/logTreatments', customerDetails.customerId, { root: true });

      commit('setActiveMember', activeMember);
      commit('setActiveMemberPhoneNumber', activeMember.phoneNumberPiiValue);
      commit('setCustomerId', customerDetails.customerId);
      commit('setAuthenticated', true);
      commit('setIsRestrictedCustomer', customerDetails.isRestrictedCustomer);
      commit('setMembers', members);
      commit('setSegmentEventPrefix', segmentEventPrefix);

      Segment.setIncognito(incognito);
      Segment.identifyCustomer(customerDetails.customerId);
      DDLogs.addGlobalContext('customerId', customerDetails.customerId);
      if (!incognito) {
        _bootIntercom(
          subscriptionState,
          members,
          customerDetails,
          getters.householdName,
          getters.numberOfMembers,
          onboardingStatus,
          activatedAt,
          invalidCredsState,
          rootGetters['featureFlags/featureFlagAttributes'],
          rootGetters['featureFlags/cashflowTabsEnabled'],
          churnPredictionData,
        );
        Sentry.initCustomerId(customerDetails.customerId);
        dispatch('addCustomerActivity', name);
      }
    } catch (e) {
      DDLogs.error('Error initializing customer session', e);
      if (e.response && e.response.status === 403) {
        // Added this in order to avoid sentry issues ith 403 errors
        console.log('Could not authenticate', e);
      }
    } finally {
      performanceService.markEnd(PerformanceMarks.INIT_SESSION);
    }
  },
  async initInviteToken({ commit, getters }) {
    if (getters.memberIsPrimary) {
      const { token } = await HansApi.generateInviteToken();
      commit('setInviteToken', token);
    }
  },
  async updateActiveMemberEmail({ dispatch }, email) {
    await CustomerApi.updateActiveMemberEmail(email);
    const { incognito } = router.history.current.query;
    // calling init again to get the updated info and report the new email to intercom
    await dispatch('init', { incognito });
  },
  async addCustomerActivity({ state }, pageName) {
    if (!state.activeMember) {
      DDLogs.error('Session state might not be initialized properly - no active member found');
      return;
    }
    await CustomerActivityApi.setCustomerActivity({
      activityTime: moment(),
      details: {
        activityType: 'visitPage',
        pageName,
        memberId: state.activeMember._id,
      },
    });
  },
};

const mutations = {
  setAuthenticated(state, authenticateRespond) {
    state.authenticated = authenticateRespond;
  },
  setActiveMember(state, member) {
    state.activeMember = member;
  },
  setActiveMemberPhoneNumber(state, phoneNumber) {
    state.activeMemberPhoneNumber = phoneNumber;
  },
  setCustomerId(state, customerId) {
    state.customerId = customerId;
  },
  setMembers(state, members) {
    state.members = members;
  },
  setInviteToken(state, inviteToken) {
    state.inviteToken = inviteToken;
  },
  setIsRestrictedCustomer(state, isRestrictedCustomer) {
    state.isRestrictedCustomer = isRestrictedCustomer;
  },
  setSegmentEventPrefix(state, segmentEventPrefix) {
    state.segmentEventPrefix = segmentEventPrefix;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
