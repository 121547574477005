<script>
export default {
  mounted() {
    this.observer = this.observer || new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.onEnterViewport();
          this.observer.unobserve(entry.target);
        }
      },
      { threshold: 1.0 },
    );
    this.observer.observe(this.$el);
  },
  beforeDestroy() {
    this.observer?.disconnect();
  },
  data() {
    return {
      observer: null,
    };
  },
};
</script>
