<template>
  <colored-popup
    :close-action="close"
    class="danger sons-merge-questions-actions-selection"
  >
    <template v-slot:top-content>
      <div class="header">
        יכול להיות שיש לך חשבון בנק נוסף שצריך לחבר לתזרים?
      </div>
    </template>
    <template v-slot:bottom-content>
      <div class="sons-details">
        <img :src="require(`@/assets/bank-logos/${logoFileName}`)" class="card-logo">
        <div class="card-number">{{ investigation.accountNumberPiiValue }} ····</div>
        <div class="text">
          <span class="bold">נראה שהחיובים של כרטיס האשראי הזה יורדים מחשבון בנק שלא מחובר לתזרים.</span>
          <div class="second-line"><span>אם יש חשבון בנק כזה, צריך לחבר אותו כדי לקבל תזרים מדויק.</span></div>
        </div>
        <riseup-button :action="openConnectedBanksModal"
                       :variant="'primary'"
                       :size="'slim'"
                       :title="'לחיבור חשבון בנק'"/>
        <riseup-button :action="openExcludeCardModal" :size="'slim'"
                       :title="'להוציא את הכרטיס מהתזרים'"
                       :variant="'secondary'"
                       class="button"/>
      </div>
    </template>
    <template v-slot:footer>
      <div class="footer-btn" @click="openIgnoreInvestigationModal">הבנתי, אל תציגו לי יותר</div>
    </template>
  </colored-popup>

</template>

<script>
import { mapActions, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import ConnectedBanksSonInvestigationModal
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/merge/ConnectedBanksSonInvestigationModal.vue';
import ExcludeCardModal from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/merge/ExcludeCardModal.vue';
import IgnoreSonInvestigationModal
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/merge/IgnoreSonInvestigationModal.vue';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';

export default {
  name: 'SonsMergeInsightPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    investigation: {
      type: Object,
      required: true,
    },
    insightId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('mergeInvestigationQuestions', ['cardInputTypes']),
    logoFileName() {
      return getSourceConfigurationBySourceName(this.investigation.source).logoFile;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('mergeInvestigationQuestions', ['setSonQuestionInput']),

    openConnectedBanksModal() {
      Segment.trackUserInteraction('SonsMergeInvestigationConnectedBanksClicked', this.investigation);
      this.openModal({
        component: ConnectedBanksSonInvestigationModal,
        props: {
          investigation: this.investigation,
          insightId: this.insightId,
        },
      });
    },

    openExcludeCardModal() {
      Segment.trackUserInteraction('SonsMergeInvestigationExcludeCardClicked', this.investigation);
      this.openModal({
        component: ExcludeCardModal,
        props: {
          investigation: this.investigation,
          insightId: this.insightId,
        },
      });
    },
    openIgnoreInvestigationModal() {
      Segment.trackUserInteraction('SonsMergeInvestigationIgnoreClicked', this.investigation);
      this.openModal({
        component: IgnoreSonInvestigationModal,
        props: {
          investigation: this.investigation,
          insightId: this.insightId,
        },
      });
    },
    close() {
      Segment.trackUserInteraction('SonsMergeInsightCloseClicked', this.investigation);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.sons-merge-questions-actions-selection {

  text-align: right;

  .header {
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    color: $riseup_white;
  }

  .card-logo {
    margin: 5px 0 10px;
    width: 150px;
  }

  .card-number {
    margin: 5px 0 10px;
    font-size: 24px;
    line-height: 22px;
  }

  .text {
    margin: 25px 0;
    font-size: 18px;
    line-height: 23px;
  }

  .button {
    margin: 15px 0 0 0;
  }

}

.second-line {
  margin-top: 25px;
}

.footer-btn {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: $riseup_black;
  cursor: pointer;
}</style>
