<script>
import Segment from '../Segment';

export default {
  name: 'SegmentTracker',
  data() {
    return { eventBaseName: '', eventBaseProps: {} };
  },
  methods: {
    initTracker(eventBaseName, eventBaseProps) {
      this.eventBaseName = eventBaseName;
      this.eventBaseProps = eventBaseProps;
    },
    track(eventName, eventData = {}) {
      Segment.trackUserInteraction(`${this.eventBaseName}${eventName}`, { ...this.eventBaseProps, ...eventData });
    },
  },
};

</script>
