function validatePhoneNumber(phoneNumber) {
  return /^0[59][0-9]{8}$/.test(phoneNumber);
}

function visualizePhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return null;
  }
  const trimmedPhoneNumber = phoneNumber.replace(/-|\s/g, '').slice(-9);
  return `0${trimmedPhoneNumber.slice(0, 2)}-${trimmedPhoneNumber.slice(-7)}`;
}

function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return null;
  }
  const trimmedPhoneNumber = phoneNumber.replace(/-|\s/g, '').slice(-9);
  return `0${trimmedPhoneNumber}`;
}

function comparePhoneNumbers(phoneNumber1, phoneNumber2) {
  return formatPhoneNumber(phoneNumber1) === formatPhoneNumber(phoneNumber2);
}

export default {
  visualizePhoneNumber,
  formatPhoneNumber,
  validatePhoneNumber,
  comparePhoneNumbers,
};
