<template>
  <div class="input-group">
    <input class="input"
           ref="input"
           @input="onInputInternal"
           @blur="onBlur"
           @focus="onFocus"
           :value="value"
           :disabled="disabled"
           :autofocus="autofocus"
           :type="inputType"
           :pattern="inputPattern"
           :placeholder="placeholder"
           :class="{ empty: emptyValue, errorMessage, disabled }"
           :maxlength="maxLength"
           :autocomplete="autocomplete"
    />
    <div class="top-right-label-wrapper" :class="{ disabled }">
      <label class="top-right-label">{{ errorMessage || labelText }}</label>
    </div>
  </div>
</template>

<script>

import _ from 'lodash';

export default {
  // TODO replace with design system instance
  name: 'TextInput',
  mounted() {
    if (this.autofocus) {
      this.$refs.input.focus();
    }
  },
  props: {
    labelText: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
    onInput: {
      type: Function,
    },
    maxLength: {
      type: Number,
      default: 1000,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    inputType: {
      type: String,
      default: '',
    },
    inputPattern: {
      type: String,
    },
    autocomplete: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    propagateEvents: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    emptyValue() {
      return _.isEmpty(this.value);
    },
  },
  methods: {
    onInputInternal(event) {
      if (this.propagateEvents) {
        this.$emit('input', event);
      } else {
        this.onInput(event.target.value);
      }
    },
    onBlur(event) {
      if (this.propagateEvents) {
        this.$emit('blur', event);
      }
    },
    onFocus(event) {
      if (this.propagateEvents) {
        this.$emit('focus', event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/typography';
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/border-radius';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-group {
  position: relative;
  width: 100%;
  height: 60px;

  > .input {
    font-family: SimplerPro;
    width: 100%;
    height: 100%;
    font-size: $ri-font-size-20;
    line-height: $ri-font-size-20;
    border: 1px solid $riseup_black;
    border-radius: $input-field-border-radius;
    color: $riseup_black;
    padding-right: 10px;

    &:focus {
      outline: none;
      border: 1px solid $riseup_blue;
    }

    &::placeholder {
      color: $riseup_gray_0;
    }

    &:not(.empty):not(:focus) ~ .top-right-label-wrapper,
    &:focus ~ .top-right-label-wrapper {
      position: absolute;
      top: -18px;
      font-size: $ri-font-size-14;
      color: $riseup_blue;
      transition: 300ms ease all;
      right: 0;

      &.disabled {
        color: $riseup_gray_2;
      }
    }

    &:not(.empty):not(:focus):not(.disabled) {
      border: 1px solid $riseup_blue;
      &.errorMessage {
        border: 1px solid $riseup_danger_red;
      }
    }

    &.disabled {
      color: $riseup_gray_2;
      border: 1px solid $riseup_gray_2;
      background-color: $riseup_gray_4;
    }

    &:not(.empty).errorMessage {
      border: 1px solid $riseup_danger_red;
    }

    &:not(.empty).errorMessage ~ .top-right-label-wrapper {
      color: $riseup_danger_red;
    }

    &.empty:not(:focus):not(.disabled) ~ .top-right-label-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      padding-right: $ri-spacing-sm;
      pointer-events: none;
      transition: 300ms ease all;
      .top-right-label {
        color: $riseup_gray_2;
        font-size: $ri-font-size-20;
      }
    }
  }
}

</style>
