<template>
  <div class="anl-balance">
      <div class="margin-top-xs" :class="variant">
        <span :class="{'ri-font-40': size === 'large', 'ri-font-22 ri-font-weight-bold': size === 'small'}">{{balanceFormatted}}</span>
        <span class="ri-font-weight-bold" :class="{'ri-font-24': size === 'large', 'ri-font-22': size === 'small'}">₪</span>
      </div>
    </div>
</template>

<script>

import money from '@/utils/money';

export default {
  name: 'AnlBalance',
  props: {
    balance: {
      type: Number,
      required: true,
    },
    customVariant: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: 'large',
    },
  },
  computed: {
    variant() {
      if (this.customVariant) {
        return this.customVariant;
      }

      if (this.balance === 0) {
        return 'black';
      }
      return this.balance >= 0 ? 'green' : 'red';
    },
    balanceFormatted() {
      return `${money.formatAmountWithFraction(this.balance, 0)}${this.balance >= 0 ? '' : '-'}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.anl-balance{
  .green{
    color: $riseup_green;
  }

  .red{
    color: $riseup_danger_red;
  }

  .black{
    color: $riseup_black;
  }
}

</style>
