<template>
  <div class="advisory-savings-questions padding-bottom-ml">
    <div class="content">
      <div class="padding-horizontal-ml">
        <advisory-top-header @close="close" @back=back variant="beige"/>
        <Stepper :current-step="stepperFiller" :total-steps="flowStepsNumber"/>
        <div class="ri-title margin-top-ml margin-bottom-sm">{{ upperTitle }}</div>
        <div class="ri-small-display margin-top-ml margin-bottom-l ri-alte-bold">{{ title }}</div>
        <div
          :class="['answer margin-bottom-s padding-right-m',
          { selected: selectedAnswers.savings && selectedAnswers.savings.number === answer.number }]"
          v-for="answer of answers"
          @click="() => select(answer)"
          :key="answer.number">
          {{ answer.text }}
        </div>
      </div>
    </div>
    <div class="padding-horizontal-ml">
      <riseup-button :action="next"
                     :size="'slim'"
                     title="להמשיך"
                     :bold="false"
                     :disabled="!hasAnswer"
                     :variant="'primary'"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters, mapState } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '@/Segment';
import AdvisoryMixin from '@/mixins/AdvisoryMixin.vue';
import AdvisoryTopHeader from './AdvisoryTopHeader';

export default {
  name: 'AdvisorySavingsQuestions',
  components: {
    AdvisoryTopHeader,
    RiseupButton: BaseUI.RiseupButton,
    Stepper: BaseUI.Stepper,
  },
  mixins: [AdvisoryMixin],
  data() {
    return {
      isSaver: false,
    };
  },
  props: {
    stepperFiller: {
      type: Number,
      default: 9,
    },
  },
  created() {
    this.isSaver = this.cashflowUITree[cashflowViewConsts.cashflowTypes.fixedSaving]?.categories.length > 0;
    Segment.trackUserGot('AdvisorySavingsQuestions', { isSaver: this.isSaver });
  },
  methods: {
    ...mapActions('advisory', ['setSavingAnswer']),
    next() {
      this.$emit('next');
    },
    back() {
      this.$emit('back');
    },
    close() {
      this.$emit('close');
    },
    select(answer) {
      this.setSavingAnswer(answer);
      Segment.trackUserInteraction('AdvisorySavingsQuestions__Answered', { answer });
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflowUITree']),
    ...mapGetters('advisory', ['selectedAnswers']),
    title() {
      return this.isSaver
        ? 'יש מטרות מסוימות לחיסכון שלך?'
        : 'יש מטרות מסוימות לכסף שנשאר בצד כל חודש?';
    },
    upperTitle() {
      return this.isSaver
        ? 'לחסוך כל חודש זה מעולה.'
        : 'לסיים את החודש בתזרים חיובי זה מעולה.';
    },
    answers() {
      return [{
        number: 1,
        text: 'כן, יש לי מטרות ברורות לשנים הבאות',
      },
      {
        number: 2,
        text: 'יש לי כמה רעיונות, אבל זה עדיין מעורפל',
      },
      {
        number: 3,
        text: 'לא, אין לי שום מטרה ספציפית',
      }];
    },
    hasAnswer() {
      return this.selectedAnswers.savings?.number;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.advisory-savings-questions {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $riseup_beige;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    text-align: start;

    .answer {
      display: flex;
      align-items: center;
      height: 76px;
      border-radius: 12px;
      text-align: start;
      border: 1px solid #D6D4D2;
      cursor: pointer;

      &.selected {
        color: $riseup_blue;
        border: 1px solid $riseup_blue;
        font-weight: bold;
      }
    }
  }
}
</style>
