<template>
  <div class="input-group">
    <input
      v-on:input="onInput($event.target.value)"
      v-on:focus="onFocus()"
      v-bind:value="value"
      :autofocus="autofocus"
      :class="{empty: emptyValue, error: hasError}"
      :maxlength="maxlength ? maxlength : 'number'"
    />
    <label class="field-name">{{ labelText }}</label>
    <label v-if="topLeftLabel" class="top-left-label">{{ topLeftLabel }}</label>
  </div>
</template>

<script>

import _ from 'lodash';
import Segment from '../../Segment';

export default {
  name: 'CustomerInput',
  props: [
    'autofocus',
    'labelText',
    'value',
    'onInput',
    'maxlength',
    'hasError',
    'topLeftLabel',
  ],
  computed: {
    emptyValue() {
      return _.isEmpty(this.value);
    },
  },
  methods: {
    onFocus() {
      Segment.trackUserInteraction('InputFocused', {
        labelText: this.labelText,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';
@import '@riseupil/base-ui/src/scss/border-radius';

  .input-group {
    position: relative;
    margin-bottom: 20px;
    width: 100%;
    height: 40px;

    .top-left-label {
      position: absolute;
      top: -12px;
      margin: 0;
      width: 100%;
      text-align: left;
      font-size: 11px;
      color: $riseup_gray_2;
      right: 0;
    }

    > input {
      font-family: SimplerPro;
      padding: 0 8px;
      width: 100%;
      height: 100%;
      font-size: 20px;
      line-height: 20px;
      border: 1px solid $riseup_gray_2;
      border-radius: $input-field-border-radius;
      color: $riseup_black;

      &:focus {
        outline: none;
        border: 1px solid #4a90e2;
      }

      &:not(.empty):not(:focus) ~ .field-name,
      &:focus ~ .field-name {
        position: absolute;
        top: -13px;
        font-size: 12px;
        font-family: SimplerPro;
        color: $riseup_blue;
        transition: 300ms ease all;
        right: 0;
      }

      &:not(.empty):not(:focus) {
        border: 1px solid $riseup_blue;
      }

      &:not(.empty).error {
        border: 1px solid $riseup_danger_red;
      }

      &:not(.empty).error ~ .field-name {
        color: $riseup_danger_red;
      }

      &.empty:not(:focus) ~ .field-name {
        position: absolute;
        right: 10px;
        top: 6px;
        color: $riseup_gray_2;
        font-size: 20px;
        pointer-events: none;
        transition: 300ms ease all;
        line-height: 1.5;
      }
    }
  }

</style>
