<template>
  <connection-success :buttons="buttons" is-new-brand>
    <template v-slot:title>
      {{title}}
    </template>
    <template v-slot:subtitle>
      {{subtitle}}
    </template>
  </connection-success>
</template>

<script>
import { mapGetters } from 'vuex';
import ConnectionSuccess from '@/base-components/ConnectionSuccess';
import { cleanScrapeRetry } from '@/base-components/credentials/credentials-retry-utils';

export default {
  name: 'CredentialsConnectionsSuccess',
  components: {
    ConnectionSuccess,
  },
  props: {
    sourceType: {
      type: String,
      required: true,
    },
    sourceName: {
      type: String,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },
  created() {
    cleanScrapeRetry(this.sourceName);
  },
  computed: {
    ...mapGetters('session', ['firstName']),
    title() {
      if (this.sourceType === 'bank') {
        return 'מצויין, חשבון הבנק חובר בהצלחה!';
      }
      return 'מצויין, חשבון האשראי חובר בהצלחה!';
    },
    subtitle() {
      return `חשבון ${this.sourceName} יופיע כחלק מהתזרים שלך`;
    },
    buttons() {
      return [
        {
          title: 'אפשר להמשיך',
          action: this.closeAction,
          size: 'small',
        },
      ];
    },
  },
};
</script>
