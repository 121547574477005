<template>
      <div class="tokenize-error-v2">
        <div class="top">
          <div class="margin-left-xs">
            <img :src="require('@/assets/icons/alert-icon.svg')">
          </div>
          <div class="ri-large-bold-body red">
            לא הצלחנו לחייב את הכרטיס
          </div>
        </div>
        <div class="margin-top-xxs ri-large-body">
          אפשר לרענן את העמוד ולנסות להשתמש בכרטיס אחר.
        </div>
      </div>
</template>

<script>
export default {
  name: 'TokenizeErrorV2',
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
 .tokenize-error-v2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 16px;
    border-radius: 3px;
    background-color: rgba($riseup_gray_0, 0.4);

    .top {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .red {
        color: $riseup_danger_red;
      }
    }
  }
</style>
