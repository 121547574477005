<template>
  <info-tool-tip-help-popup class="account-investigation-help-popup"
    :title="'אילו חשבונות בנק צריך לכלול בתזרים?'"
    :cx-dude="{ name: 'nofar', displayName: 'נופר' }"
    :popup-name="'AccountSelectionHelpPopup'"
    :on-close="onClose">
    <template v-slot:description>
      <div class="description">
        <div class="bold">חשבונות שיש לכלול בתזרים</div>
        החשבונות העיקריים איתם מתנהל משק הבית שלך בשוטף,
        אליהם נכנסת המשכורת הקבועה שלך ומהם יורדות ההוצאות שלך.
        <div>&nbsp;</div>
        <div class="bold">חשבונות שאין צורך לכלול בתזרים</div>
        חשבונות משניים שאינם חלק מהתנהלות הפרטית היומ-יומית שלך כגון חשבון השקעות או חשבון עסקי.
        חשבונות אלו לא צריך לחבר ל-riseup ולכלול כחלק מהתזרים החודשי.
      </div>
    </template>
  </info-tool-tip-help-popup>
</template>

<script>

import InfoToolTipHelpPopup from '../InfoToolTipHelpPopup.vue';

export default {
  name: 'AccountInvestigationHelpPopup',
  components: {
    InfoToolTipHelpPopup,
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .account-investigation-help-popup {
    .description {
      a {
        color: $riseup_blue;
        font-weight: bold;
      }
      .bold {
        font-weight: bold;
      }
    }
  }

</style>
