<template>
  <navigation-menu-item
    v-if="eligibleForPlanAhead"
    title="התוכניות שלי"
    target="plan-ahead"
    v-on:navigate="$emit('navigate')"
    icon-name="target" />
</template>

<script>
import { mapActions, mapState } from 'vuex';

import NavigationMenuItem from '@/base-components/app-header/NavigationMenuItem';

export default {
  name: 'PlanAheadMenuItem',
  components: {
    NavigationMenuItem,
  },
  created() {
    this.initEligibleForPlanAhead();
  },
  computed: {
    ...mapState('planAhead', ['eligibleForPlanAhead']),
  },
  methods: {
    ...mapActions('planAhead', ['initEligibleForPlanAhead']),
  },
};
</script>
