<template>
  <div class="list-item margin-bottom-ml">
    <img class="bullet padding-left-m" :src="image"/>
    <div>
      <div class="ri-bold-body">{{item.title}}</div>
      <div class="ri-body">{{item.subTitle}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.list-item {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: start;

  .bullet {
    align-self: start;
  }
}

</style>
