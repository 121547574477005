import DDLogs from '@/DDLogs';

export function shouldTryAgainObk(sourceName) {
  const shouldTryAgainObk = localStorage.getItem(`obk-scrape-retry-${sourceName}`);
  DDLogs.log('shouldTryAgainObk', { sourceName, shouldTryAgainObk: !!shouldTryAgainObk });
  return shouldTryAgainObk !== 'true';
}

export function setObkRetry(sourceName) {
  DDLogs.log('setObkRetry', { sourceName });
  localStorage.setItem(`obk-scrape-retry-${sourceName}`, true);
}

export function cleanScrapeRetry(sourceName) {
  DDLogs.log('cleanScrapeRetry', { sourceName });
  localStorage.removeItem(`obk-scrape-retry-${sourceName}`);
}
