import _ from 'lodash';
import Vue from 'vue';

function formatAmount(amount) {
  if (amount) {
    return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
  return amount;
}

function formatAmountWithFraction(value, fractionDigits = 1) {
  const amount = Math.abs(Number(value));
  const result = amount.toLocaleString(undefined, { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits });
  return result.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function signedFormatAmountWithFraction(value, fractionDigits = 1) {
  return `${formatAmountWithFraction(value, fractionDigits)}${value < 0 ? '-' : ''}`;
}

Vue.filter('signedFormatAmountWithFraction', signedFormatAmountWithFraction);
Vue.filter('formatAmountWithFraction', formatAmountWithFraction);
Vue.filter('shekel', value => `${value} ₪`);

function extractRoundedDecimalPart(number, precision) {
  return (10 * (_.round(number, precision))) % 10;
}

function extractRoundedIntegerPart(number, precision) {
  return Math.trunc(_.round(number, precision));
}

export default {
  formatAmount,
  formatAmountWithFraction,
  signedFormatAmountWithFraction,
  extractRoundedDecimalPart,
  extractRoundedIntegerPart,
};
