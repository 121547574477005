<template>
  <div class="create-other-tracking-category-popup">
    <colored-popup :class="variant" :back-action="back">

      <template v-slot:top-content>
        <div class="title">
          הוספת קטגוריה למעקב
        </div>
      </template>

      <template v-if="firstStep" v-slot:bottom-content>
        <text-input :labelText="'שם הקטגוריה'"
                    :value="customCategoryName"
                    :on-input="onInput" />
      </template>

      <template v-else v-slot:bottom-content>
        <div class="question">איזה הוצאות יהיו במעקב תחת <span class="bold">{{selectedTrackingCategory.label}}</span>?</div>

        <div class="transaction-list-container">
          <transaction-check-list :transaction-list="availableTransactions" :toggleCheck="checkTransaction"/>
        </div>
      </template>

      <template v-slot:footer>
        <div class="button-container">
          <riseup-button :action="onSave"
                         :variant="'primary'"
                         :title="'המשך'"
                         :size="'slim'"
                         :disabled="isButtonDisabled"/>
        </div>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '../../../../../../../../../../Segment';
import cashflowViewConsts from '../../../../../../../../../../constants/cashflow-view';
import TransactionCheckList from '../../../../../../../../../../base-components/TransactionCheckList';

const STEPS = {
  NAME: 'select-name',
  CATEGORIES: 'select-transactions',
};

export default {
  name: 'CreateOtherTrackingCategoryPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    TextInput: BaseUI.TextInput,
    TransactionCheckList,
  },
  created() {
    Segment.trackUserInteraction('CreateOtherTrackingCategory_Entered', {});
  },
  data() {
    return {
      firstStep: true,
      customCategoryName: '',
    };
  },
  computed: {
    ...mapState('createTrackingCategory', ['selectedTrackingCategory', 'availableTransactions']),
    variant() {
      return cashflowViewConsts.CATEGORY_STYLE[cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING];
    },
    isButtonDisabled() {
      return this.firstStep ? !this.customCategoryName : !this.selectedTrackingCategory.value;
    },
    step() {
      return this.firstStep ? STEPS.NAME : STEPS.CATEGORIES;
    },
  },
  methods: {
    ...mapActions('createTrackingCategory', ['selectTrackingCategory', 'reset']),
    ...mapMutations('createTrackingCategory', ['checkTransaction']),
    onInput(input) {
      this.selectTrackingCategory({ ...this.selectedTrackingCategory, label: input, value: input });
      this.customCategoryName = input;
    },
    back() {
      if (!this.firstStep) {
        this.firstStep = true;
      } else {
        this.$emit('back-clicked');
      }
      Segment.trackUserInteraction('CreateOtherTrackingCategory_BackClicked', { step: this.step });
    },
    onSave() {
      if (this.firstStep && this.availableTransactions.length > 0) {
        this.firstStep = false;
      } else {
        this.selectTrackingCategory({ ...this.selectedTrackingCategory, value: this.customCategoryName.trim() });
        this.$emit('done-clicked');
      }
      Segment.trackUserInteraction('CreateOtherTrackingCategory_SaveClicked', { step: this.step });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .create-other-tracking-category-popup {
    display: flex;
    flex-direction: column;
    text-align: right;

      ::v-deep .colored-top {
        color: $riseup_white;

        .title {
          font-size: 22px;
          font-weight: bold;
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        margin-top: 26px;

        .question {
          margin-bottom: 13px;
          line-height: 20px;

          .bold {
            font-weight: bold;
          }
        }

        .transaction-list-container {

          .transaction-row-content {
            padding: 0;
          }

          .separator-wrapper {
            padding: 0;
          }
        }
      }

      > .separator {
        height: 1px;
      }

      .footer {
        .riseup-button {
          width: 100%;
        }
      }
  }

</style>
