<template>
  <div class="steps-container">
    {{ currentStep }} מתוך {{ totalNumberOfSteps }}
  </div>
</template>

<script>

export default {
  name: 'StepIndicator',
  props: {
    currentStep: {
      type: Number,
      required: true,
    },
    totalNumberOfSteps: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.steps-container {
  font-size: 16px;
  line-height: 24px;
  color: $riseup_blue;
}
</style>
