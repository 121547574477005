<template>
  <popup :buttons="buttons">
    <template v-slot:content>
      <div class="content">

        <lottie-player class="check-animation" src="/animations/Check.json" :autoplay="true"/>
        <div class="bold">מעולה!</div>
        <span>נזכיר לך בעוד שבוע</span>

      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';

export default {
  name: 'SnoozeInsightComplete',
  components: {
    Popup: BaseUI.Popup,
  },
  computed: {
    buttons() {
      return [
        {
          title: 'סגור',
          action: this.closePopup,
          size: 'slim',
        },
      ];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    closePopup() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 22px;
  line-height: 24px;
  padding-bottom: 32px;

  .check-animation {
    width: 85%;
  }
}

</style>
