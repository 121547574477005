<template>
  <toaster @close-toaster="closeSummaryToaster">
    <template v-slot:text>
      <span>קטגוריה למעקב <span class="bold">{{ trackingCategoryName }}</span> נוספה לתזרים</span>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'NewTrackingCategoryToaster',
  components: {
    Toaster,
  },
  props: {
    trackingCategoryName: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeSummaryToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
