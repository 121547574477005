<template>
  <div class="fees-insight-popup">
    <insight-details-popup :insight-popup-explanation="insightPopupTitle"
                           :cashflow-category="cashflowCategory"
                           :insight="insight"
                           :buttons="buttons"
                           :showFeedbackFooter="showFeedbackFooter"
                           :close-action="close">
      <template v-slot:top-content>
        <div class="insight-prefix">
          <span class="bold">סה״כ עמלות</span>
          <span>שיורדות כל חודש</span>
        </div>
        <div class="transactions-sum">{{formatAmount(insight.transactionsSum)}} ₪</div>
      </template>
      <template v-slot:bottom-content>
        <div class="insight-popup-title">{{ insightPopupTitle }}</div>
        <div class="transactions-container">
          <div v-for="transaction in sortedInsightTransactions" :key="transaction.transactionId" class="transaction">
            <div class="business-name">{{ transaction.businessName }}</div>
            <div class="bold transaction-amount">{{formatAmount(transaction.amount)}} ₪</div>
          </div>
        </div>
        <callout iconPath="icons/heart.svg" class="insight-callout" title="טיפ מאיתנו">
          <template v-slot:text>
            לבטל עמלות זה אפשרי ואפילו פשוט!
            מספיק להקדיש לזה 15 דקות ולהתחיל לחסוך
          </template>
        </callout>
        <insight-feedback-smiley @smile-feedback="smileFeedback" @sad-feedback="sadFeedback"/>
      </template>
    </insight-details-popup>
  </div>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapGetters } from 'vuex';
import moneyUtils from '../../../../../../../utils/money';
import InsightDetailsPopup from './InsightDetailsPopup';
import InsightFeedbackSmiley from '../../../../../../../base-components/InsightFeedbackSmiley';

export default {
  name: 'FeesInsightPopup',
  components: {
    InsightFeedbackSmiley,
    InsightDetailsPopup,
    Callout: BaseUI.Callout,
  },
  props: {
    insight: { type: Object, required: true },
    cashflowCategory: { type: String, required: true },
    insightPopupTitle: { type: String, required: true },
    buttons: { type: Array, required: false },
    showFeedbackFooter: { type: Boolean, default: false },
  },
  created() {
    this.sendSegmentEvent('PersonalizedFirstWeekInsightPopupShown');
  },
  computed: {
    ...mapGetters('editTransaction', ['categoryToVariant']),
    headerVariant() {
      return this.categoryToVariant[this.cashflowCategory];
    },
    sortedInsightTransactions() {
      return [...this.insight.transactions].sort((t1, t2) => t2.amount - t1.amount);
    },
  },
  methods: {
    sendSegmentEvent(eventName, extraProps = {}) {
      Segment.trackUserInteraction(eventName, { ...this.insight, ...extraProps });
    },
    close() {
      this.sendSegmentEvent('PersonalisedFirstWeekInsightPopupCloseClicked');
      this.$emit('close');
    },
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 1);
    },
    smileFeedback() {
      Segment.trackUserInteraction('PersonalisedFirstWeekInsightPopupSmileFeedback');
    },
    sadFeedback() {
      Segment.trackUserInteraction('PersonalisedFirstWeekInsightPopupSadFeedback');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.bold {
  font-weight: bold;
}

.fees-insight-popup {
  .insight-prefix {
    margin-bottom: 16px;
    line-height: 11px;
  }

  .transactions-sum {
    font-size: 46px;
    font-weight: bold;
  }

  .insight-popup-title {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 28px;
    font-weight: normal;
  }

  .transactions-container {
    .transaction {
      display: flex;
      font-size: 16px;
      line-height: 32px;

      .business-name {
        flex: 2 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .transactions-amount {
        flex: 1 0 0;
        text-align: left;
        margin-right: 15px;
      }
    }
  }

  .insight-callout {
    background-color: $riseup_light_blue;
    border-radius: 8px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
</style>
