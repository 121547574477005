<script>

import { mapGetters, mapState } from 'vuex';

export default {
  name: 'AdvisoryMixin',
  computed: {
    ...mapGetters('advisory', ['stepsNumber', 'packageStepsNumber']),
    ...mapState('oneOffOfferings', ['selectedOffering']),
    ...mapGetters('oneOffOfferings', ['offeringsTypes']),
    flowStepsNumber() {
      return this.selectedOffering.id === this.offeringsTypes.ADVISORY_PACKAGE
        ? this.packageStepsNumber
        : this.stepsNumber;
    },
  },
};

</script>
