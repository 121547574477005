<template>
  <div class="arrow-selection-item" @click="handleClick">
    <div class="content-wrapper">
      <img class="selection-item-img" :class="imageSize === 'large' ? 'resized-image' : ''" :src="imageSource" />
      <span v-if="text">{{text}}</span>
    </div>
    <div class="arrow-wrapper">
      <img class="arrow-image" src="/images/onboarding/icons/left-arrow.svg">
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArrowSelectionItem',
  props: {
    imageSource: { type: String, required: true },
    imageSize: { type: String, required: false, default: 'small' },
    text: { type: String, required: false },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.arrow-selection-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @include responsive-padding;
  height: 90px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: $riseup_white;

  .content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .selection-item-img {
      margin-left: 10px;
    }

    .resized-image {
      height: 40px;
    }
  }

  .arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 30px;
    color: $riseup_black;
    cursor: pointer;

    .arrow-image {
      height: 16px;
    }
  }
}
</style>
