import reactiveCashflow from '@riseupil/reactive-cashflow';
import { DateLocales } from '@riseupil/common-utils';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import cashflowNormalizer from '../../utilities/cashflow-normalizer';
import CashflowUpdaterApi from '../../../api/CashflowUpdaterApi';
import cashflowViewConsts from '../../../constants/cashflow-view';

const actions = {
  async moveTransaction({ dispatch, commit, rootGetters }, { originalCategory, selectedCategory, transaction, categoryRuleType, valueForPredicate }) {
    performanceService.markStart(PerformanceMarks.MOVE_TRANSACTION);
    await dispatch('addEditedTransaction', transaction.transactionId);
    const moveToTracking = selectedCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING;
    const moveFromTracking = originalCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING;
    const moveFromFixed = originalCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED;
    const moveToVariable = selectedCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE;
    const moveToOneTimeSaving = selectedCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.ONE_TIME_SAVING;
    if (moveFromFixed && moveToVariable) {
      await dispatch('moveFixedToVariable', {
        transactionId: transaction.transactionId,
      });
    } else if (moveToTracking || moveToOneTimeSaving) {
      await dispatch('changeTransactionCategory', {
        transaction,
        trackingCategoryId: selectedCategory.categoryId,
        matchBy: categoryRuleType,
        value: valueForPredicate,
        originalCategory,
      });
    } else if (moveFromTracking && moveToVariable) {
      await dispatch('moveFromTrackingToVariable', {
        transaction,
        expense: selectedCategory.expense,
        matchBy: categoryRuleType,
        value: valueForPredicate,
      });
    } else {
      await dispatch('changeTransactionCategoryOld', {
        transaction,
        expense: selectedCategory.expense,
        applyTo: categoryRuleType,
        originalCategory,
      });
    }
    performanceService.markEndAndLog(PerformanceMarks.MOVE_TRANSACTION);
  },
  async changeEnvelopeExpenseCategory({ dispatch }, { envelopeId, sequenceId, expenseCategory, isIncome }) {
    await dispatch('applyAction', { action: 'changeEnvelopeExpenseCategory', params: { envelopeId, sequenceId, expenseCategory, isIncome } });
  },
  async changeTransactionCategoryOld({ dispatch }, { expense, transaction, applyTo, originalCategory }) {
    const params = {
      businessName: transaction.businessName,
      transactionId: transaction.transactionId,
      applyTo,
      expense,
      popEnvelope: originalCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
    };
    await dispatch('applyAction', { action: 'changeCategory', params });
  },
  async changeTransactionCategory({ dispatch }, { trackingCategoryId, matchBy, value, originalCategory }) {
    const popEnvelope = [
      cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
      cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED_SAVING,
    ].includes(originalCategory.cashflowCategory);
    await dispatch('applyAction', {
      action: 'changeTransactionCategory',
      params: { trackingCategoryId, matchBy, value, popEnvelope },
    });
  },
  async moveFromTrackingToVariable({ dispatch }, { transaction, expense, matchBy, value }) {
    const moveFromTrackingParams = {
      matchBy,
      value,
    };
    await dispatch('applyAction', { action: 'moveFromTracking', params: moveFromTrackingParams });
  },
  async moveFixedToVariable({ commit, getters, dispatch }, { transactionId }) {
    dispatch('applyAction', { action: 'moveFixedToVariable', params: { transactionId } });
  },
  async moveExpenseToFixed({ commit, getters, dispatch }, { transactionId, monthsDiff = 1, expense }) {
    dispatch('applyAction', { action: 'moveExpenseToFixed', params: { transactionId, monthsDiff, expense } });
  },
  async moveIncomeToFixed({ commit, getters, dispatch }, { transactionId }) {
    dispatch('applyAction', { action: 'moveIncomeToFixed', params: { transactionId } });
  },
  async moveVariableIncomeToExcluded({ commit, getters, dispatch }, { transactionId }) {
    dispatch('applyAction', { action: 'excludeVariableIncome', params: { transactionId } });
  },
  async excludeExpense({ commit, getters, dispatch }, { transactionId }) {
    dispatch('applyAction', { action: 'excludeExpense', params: { transactionId } });
  },
  async moveVariableExpenseToExcluded({ commit, getters, dispatch }, { transactionId }) {
    dispatch('applyAction', { action: 'excludeVariableExpense', params: { transactionId } });
  },
  async moveExcludedToVariableIncome({ commit, getters, dispatch }, { transactionId }) {
    dispatch('applyAction', { action: 'moveExcludedToVariableIncome', params: { transactionId } });
  },
  async unexcludeTransaction({ commit, getters, dispatch }, { transactionId }) {
    dispatch('applyAction', { action: 'unexcludeTransaction', params: { transactionId } });
  },
  async excludeIncome({ commit, getters, dispatch }, { transactionId }) {
    dispatch('applyAction', { action: 'excludeIncome', params: { transactionId } });
  },
  async moveExcludedToVariableExpense({ commit, getters, dispatch }, { transactionId }) {
    dispatch('applyAction', { action: 'moveExcludedToVariableExpense', params: { transactionId } });
  },
  async cancelEnvelope({ commit, dispatch }, { envelopeId, sequenceId }) {
    dispatch('applyAction', { action: 'cancelEnvelope', params: { envelopeId, sequenceId } });
  },
  async disableTrackingCategory({ commit, dispatch }, { envelopeId, trackingCategoryId }) {
    dispatch('applyAction', { action: 'disableTrackingCategory', params: { envelopeId, trackingCategoryId } });
  },
  async updateEnvelopeAmount({ commit, dispatch }, { envelopeId, amount, applyOnBudgetDate, sequenceId }) {
    dispatch('applyAction', { action: 'updateEnvelopeAmount', params: { envelopeId, amount, applyOnBudgetDate, sequenceId } });
  },
  async setComment({ commit, dispatch }, { transactionId, comment }) {
    dispatch('applyAction', { action: 'setTransactionComment', params: { transactionId, comment } });
  },
  async setSequenceComment({ commit, dispatch }, { sequenceId, comment }) {
    dispatch('applyAction', { action: 'setSequenceComment', params: { sequenceId, comment } });
  },
  async updateRiseupGoal({ commit, dispatch }, { budgetDate, amount }) {
    dispatch('applyAction', { action: 'updateRiseupGoal', params: { budgetDate, amount } });
  },
  async splitTransaction({ commit, dispatch }, { transactionId, slices, actionType }) {
    await dispatch('applyActionSync', { action: 'splitTransaction', params: { transactionId, slices, actionType } });
  },
  async deactivateTrackingCategory({ commit, dispatch }, { envelopeId, trackingCategoryId }) {
    await dispatch('applyAction', { action: 'deactivateTrackingCategory', params: { envelopeId, trackingCategoryId } });
  },
  async setIncomePredictionAmount({ commit, dispatch }, { budgetDate, amount }) {
    await dispatch('applyAction', { action: 'setIncomePredictionAmount', params: { budgetDate, amount } });
  },
  async applyAction({ commit, dispatch, rootGetters }, { action, params }) {
    if (params.transactionId) {
      await dispatch('addEditedTransaction', params.transactionId);
    }
    const currentCashflow = rootGetters['cashflowView/presentedBudget'];
    if (!_appliesToDifferentMonth(currentCashflow.budgetDate, action, params)) {
      const newCashflow = reactiveCashflow.optimus[action](currentCashflow, params, DateLocales.EN_IL);
      await commit('cashflowView/setCashflowOptimistic', newCashflow, { root: true });
      await dispatch('cashflowView/resetBudgetTree', {}, { root: true });
    }
    CashflowUpdaterApi[action](params);
  },
  async applyActionSync({ commit, dispatch, rootGetters }, { action, params }) {
    if (params.transactionId) {
      await dispatch('addEditedTransaction', params.transactionId);
    }
    const currentCashflow = rootGetters['cashflowView/presentedBudget'];
    if (!_appliesToDifferentMonth(currentCashflow.budgetDate, action, params)) {
      const newCashflow = reactiveCashflow.optimus[action](currentCashflow, params, DateLocales.EN_IL);
      await commit('cashflowView/setCashflowOptimistic', newCashflow, { root: true });
      await dispatch('cashflowView/resetBudgetTree', {}, { root: true });
    }
    await CashflowUpdaterApi[action](params);
  },
  addEditedTransaction({ commit }, transactionId) {
    cashflowNormalizer.addEditedTransaction(transactionId);
  },
};

function _appliesToDifferentMonth(budgetDate, action, params) {
  return (action === 'updateEnvelopeAmount' && !!params.applyOnBudgetDate && params.applyOnBudgetDate !== budgetDate);
}

export default {
  namespaced: true,
  actions,
};
