<template>
  <component :is="selectedInsight" :insight="insight"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import AllClassifiedPapasMergeQuestionsInsight
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/AllClassifiedPapasMergeQuestionsInsight';
import MissingBankCCsAfterOBKMigrationInsight
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/MissingBankCCsAfterOBKMigrationInsight';
import indentedIncomeInsight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/IndentedIncomeInsight.vue';
import MissingRegularCCsAfterOBKMigrationInsight
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/MissingRegularCCsAfterOBKMigrationInsight';
import AdvisoryInsight
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/AdvisoryInsight';
import CashflowStartDayInsight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/CashflowStartDayInsight.vue';
import PromoteSemiAnnualInsight
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/PromoteSemiAnnualInsight';
import PromoteAnnualInsight from './PromoteAnnualInsight';
import NewPapasMergeQuestionsInsight from './NewPapasMergeQuestionsInsight';
import BalanceMoneyInsight from './BalanceMoneyInsight';
import FeesInsight from './FeesInsight';
import NegativeBalanceInsight from './NegativeBalanceInsight';
import RiseupGoalInsight from './RiseupGoalInsight';
import SetMonthlyGoalInsight from './SetMonthlyGoalInsight';
import BalancedMonthlyGoalInsight from './BalancedMonthlyGoalInsight';
import ChallengeInsight from './ChallengeInsight';
import HighIncomeInsight from './HighIncomeInsight';
import LoanInsight from './LoanInsight';
import HighExpenseInsight from './HighExpenseInsight';
import HighExpenseExclusionInsight from './HighExpenseExclusionInsight.vue';
import EconomyBalanceInsight from './EconomyBalanceInsight';
import ZeroBalanceInsight from './ZeroBalanceInsight.vue';
import MightBeFixedInsight from './MightBeFixedInsight';
import FixBalanceInsight from './FixBalanceInsight';
import RecurringBalanceInsight from './RecurringBalanceInsight';
import ActivationInsight from './ActivationInsight';
import TrackingInsight from './TrackingInsight';
import OpportunisticSavingInsight from './OpportunisticSavingInsight';
import MonthlySavingInsight from './MonthlySavingInsight';
import DemographicInfoInsight from './DemographicInfoInsight';
import RollingCreditInsight from './RollingCreditInsight';
import SonsMergeInsight from './SonsMergeInsight';
import MeitavOpportunisticSavingInsight from './MeitavOpportunisticSavingInsight';
import MeitavMonthlySavingInsight from './MeitavMonthlySavingInsight';
import JoinAmbassadorsInsight from './JoinAmbassadorsInsight';
import TrackingCategoriesSelectionInsight from './TrackingCategoriesSelectionInsight';
import PredictedVariableIncomeEOMInsight from './PredictedVariableIncomeEOMInsight';
import PredictedVariableIncomeInsight from './PredictedVariableIncomeInsight';
import HeverBenefitInsight from './HeverBenefitInsight';
import InsightsBuilder from './InsightsBuilder';
import ReferralMissingPaymentDetailsInsight from './ReferralMissingPaymentDetailsInsight.vue';
import PlanAheadInsight from './PlanAheadInsight';
import MyProgressInsight from './MyProgressInsight.vue';
import RecalculatePlansInsight from './RecalculatePlansInsight';
import MonthOpeningExperienceInsight from './MonthOpeningExperienceInsight';
import BoxToSavingsInsight from './BoxToSavingsInsight';
import InsightsCenterInsight from './InsightsCenterInsight';

export default {
  name: 'InsightFactory',
  components: {
    HighIncomeInsight,
    LoanInsight,
    HighExpenseInsight,
    EconomyBalanceInsight,
    MightBeFixedInsight,
    FixBalanceInsight,
    ZeroBalanceInsight,
    RecurringBalanceInsight,
    ActivationInsight,
    OpportunisticSavingInsight,
    MonthlySavingInsight,
    DemographicInfoInsight,
    FeesInsight,
    NegativeBalanceInsight,
    RollingCreditInsight,
    MeitavOpportunisticSavingInsight,
    MeitavMonthlySavingInsight,
    ChallengeInsight,
    RiseupGoalInsight,
    SetMonthlyGoalInsight,
    BalancedMonthlyGoalInsight,
    TrackingCategoriesSelectionInsight,
    PredictedVariableIncomeEOMInsight,
    PredictedVariableIncomeInsight,
    MissingBankCCsAfterOBKMigrationInsight,
    MissingRegularCCsAfterOBKMigrationInsight,
    HeverBenefitInsight,
    ReferralMissingPaymentDetailsInsight,
    PlanAheadInsight,
    CashflowStartDayInsight,
    InsightsBuilder,
    MonthOpeningExperienceInsight,
    BoxToSavingsInsight,
    MyProgressInsight,
    InsightsCenterInsight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapGetters('insights', ['INSIGHT_NAMES']),
    allInsightComponents() {
      return {
        [this.INSIGHT_NAMES.LoanExclusion]: LoanInsight,
        [this.INSIGHT_NAMES.HighIncomeExclusion]: HighIncomeInsight,
        [this.INSIGHT_NAMES.RelativeIncomeExclusion]: HighIncomeInsight,
        [this.INSIGHT_NAMES.FeesInsight]: FeesInsight,
        [this.INSIGHT_NAMES.NegativeBalanceInsight]: NegativeBalanceInsight,
        [this.INSIGHT_NAMES.RiseupGoalInsight]: RiseupGoalInsight,
        [this.INSIGHT_NAMES.SetMonthlyGoalInsight]: SetMonthlyGoalInsight,
        [this.INSIGHT_NAMES.BalancedMonthlyGoalInsight]: BalancedMonthlyGoalInsight,
        [this.INSIGHT_NAMES.ChallengeInsight]: ChallengeInsight,
        [this.INSIGHT_NAMES.HighExpense]: HighExpenseInsight,
        [this.INSIGHT_NAMES.RelativeHighExpense]: HighExpenseInsight,
        [this.INSIGHT_NAMES.HighExpenseExclusion]: HighExpenseExclusionInsight,
        [this.INSIGHT_NAMES.RelativeExpenseExclusion]: HighExpenseExclusionInsight,
        [this.INSIGHT_NAMES.EconomyBalance]: EconomyBalanceInsight,
        [this.INSIGHT_NAMES.MightBeFixed]: MightBeFixedInsight,
        [this.INSIGHT_NAMES.FixedBalance]: FixBalanceInsight,
        [this.INSIGHT_NAMES.ZeroBalance]: ZeroBalanceInsight,
        [this.INSIGHT_NAMES.RecurringBalance]: RecurringBalanceInsight,
        [this.INSIGHT_NAMES.Activation]: ActivationInsight,
        [this.INSIGHT_NAMES.TrackingCategories]: TrackingInsight,
        [this.INSIGHT_NAMES.OpportunisticSaving]: OpportunisticSavingInsight,
        [this.INSIGHT_NAMES.MonthlySaving]: MonthlySavingInsight,
        [this.INSIGHT_NAMES.DemographicInfo]: DemographicInfoInsight,
        [this.INSIGHT_NAMES.RollingCreditInsight]: RollingCreditInsight,
        [this.INSIGHT_NAMES.SonsMergeInsight]: SonsMergeInsight,
        [this.INSIGHT_NAMES.MeitavOpportunisticSaving]: MeitavOpportunisticSavingInsight,
        [this.INSIGHT_NAMES.MeitavMonthlySaving]: MeitavMonthlySavingInsight,
        [this.INSIGHT_NAMES.NewPapasMergeInsight]: NewPapasMergeQuestionsInsight,
        [this.INSIGHT_NAMES.AllClassifiedPapasMergeInsight]: AllClassifiedPapasMergeQuestionsInsight,
        [this.INSIGHT_NAMES.BalanceMoneyInsight]: BalanceMoneyInsight,
        [this.INSIGHT_NAMES.JoinAmbassadorsInsight]: JoinAmbassadorsInsight,
        [this.INSIGHT_NAMES.TrackingCategoriesSelectionInsight]: TrackingCategoriesSelectionInsight,
        [this.INSIGHT_NAMES.PredictedVariableIncomeEOMInsight]: PredictedVariableIncomeEOMInsight,
        [this.INSIGHT_NAMES.PredictedVariableIncomeInsight]: PredictedVariableIncomeInsight,
        [this.INSIGHT_NAMES.MissingBankCCsAfterOBKMigrationInsight]: MissingBankCCsAfterOBKMigrationInsight,
        [this.INSIGHT_NAMES.MissingRegularCCsAfterOBKMigrationInsight]: MissingRegularCCsAfterOBKMigrationInsight,
        [this.INSIGHT_NAMES.HeverBenefitInsight]: HeverBenefitInsight,
        [this.INSIGHT_NAMES.PersonalizedInsight]: InsightsBuilder,
        [this.INSIGHT_NAMES.ReferralMissingPaymentDetails]: ReferralMissingPaymentDetailsInsight,
        [this.INSIGHT_NAMES.PlanCompletedEnoughMoney]: PlanAheadInsight,
        [this.INSIGHT_NAMES.PlanCompletedNotEnoughMoney]: PlanAheadInsight,
        [this.INSIGHT_NAMES.RecalculatePlans]: RecalculatePlansInsight,
        [this.INSIGHT_NAMES.IndentedIncome]: indentedIncomeInsight,
        [this.INSIGHT_NAMES.Advisory]: AdvisoryInsight,
        [this.INSIGHT_NAMES.PromoteAnnual]: PromoteAnnualInsight,
        [this.INSIGHT_NAMES.PromoteSemiAnnual]: PromoteSemiAnnualInsight,
        [this.INSIGHT_NAMES.CashflowStartDay]: CashflowStartDayInsight,
        [this.INSIGHT_NAMES.MonthOpeningExperienceInsight]: MonthOpeningExperienceInsight,
        [this.INSIGHT_NAMES.BoxToSavingsInsight]: BoxToSavingsInsight,
        [this.INSIGHT_NAMES.MyProgressHowMuchISavedInsight]: MyProgressInsight,
        [this.INSIGHT_NAMES.InsightsCenterInsight]: InsightsCenterInsight,
      };
    },
    selectedInsight() {
      return this.allInsightComponents[this.insight.insightName];
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
