import { client } from '../http';

async function fetchAdvisoryEligibilities() {
  const response = await client.get('/api/advisory-eligibility');
  return response.data;
}

export default {
  fetchAdvisoryEligibilities,
};
