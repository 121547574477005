<template>
  <div>
    <payment-form-page v-if="index === 0" :image-header-props="imageHeaderProps" :initialValues="initialValues"
      title="אימות בקשה למסלקה פנסיונית" :show-payment-info-section="false" purchase-button-text="אישור ואימות"
      @tokenization-success="onTokenizeSucceeded" @cancel="redirectToCashflow"
    >
      <template v-slot:title>
        <div class="ri-body">
          <div>היי {{activeMemberFirstName}},</div>
          <div class="margin-vertical-m">    נראה שלא קיים במערכת חיבור ״בנקאות פתוחה״ עם תעודת הזהות שלך.
            כדי שנוכל להשלים את את תהליך משיכת הנתונים מהמסלקה הפנסיונית, נצטרך לאמת את הזהות שלך בדרך אחרת.</div>
          <div>    הזיהוי יבוצע באמצעות כרטיס אשראי המשויך לתעודת הזהות איתה הגשת את הבקשה למשיכת מידע מהמסלקה. כרטיס האשראי לא יחויב.</div>
        </div>
      </template>
      <template v-if="idsDontMatch" v-slot:errors>
        <div class="margin-bottom-sm ri-bold-body">
          תעודת הזהות אינה תואמת את תעודת הזהות איתה נתת הסכמה למסלקה הפנסיונית. אנא נסו שוב.
        </div>
      </template>
    </payment-form-page>
    <mislaka-consent-success v-if="index===1" @next="redirectToCashflow" />
  </div>
</template>

<script>
import PaymentFormPage
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentForm/PaymentFormPage';
import { BACKGROUND_POSITION } from '@riseupil/base-ui/src/utils/layout-consts';
import Colors from '@riseupil/base-ui/src/scss/riseup-js-colors.module.scss';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { PERSONAL_INFO_FIELDS } from '@/store/modules/payment';
import ClearingHouseApi from '@/api/ClearingHouseApi';
import MislakaConsentSuccess
  from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaConsentSuccess';
import router from '@/router';
import Segment from '@/Segment';

export default {
  name: 'AdvisoryPaymentAuthentication',
  components: { MislakaConsentSuccess, PaymentFormPage },
  created() {
    Segment.trackUserInteraction('AdvisoryPaymentAuthentication_Entered');
  },
  data() {
    return {
      imageHeaderProps: {
        backgroundColor: Colors.riseupGreen,
        // eslint-disable-next-line global-require
        imgSrc: '/images/advisory/advisory-header.svg',
        imgHeight: 130,
        backgroundPosition: BACKGROUND_POSITION.TOP_LEFT,
      },
      index: 0,
      idsDontMatch: false,
    };
  },
  computed: {
    ...mapState('session', ['activeMember']),
    ...mapGetters('session', ['activeMemberFirstName']),
    ...mapGetters('payment', ['socialId']),
    initialValues() {
      return {
        [PERSONAL_INFO_FIELDS.firstName]: this.activeMember.firstNamePiiValue,
        [PERSONAL_INFO_FIELDS.lastName]: this.activeMember.lastNamePiiValue,
        [PERSONAL_INFO_FIELDS.phoneNumber]: this.activeMember.phoneNumberPiiValue.replace('+972', '0'),
      };
    },
  },
  methods: {
    ...mapMutations('payment', ['setPaymeToken']),
    async onTokenizeSucceeded(paymeToken) {
      const { token } = paymeToken;
      const { isValid } = await ClearingHouseApi.validateConsent(token);
      if (isValid) {
        Segment.trackUserInteraction('AdvisoryPaymentAuthentication_Authenticated');
        this.index = 1;
      } else {
        Segment.trackUserInteraction('AdvisoryPaymentAuthentication_AuthenticationFailed');
        this.setPaymeToken(undefined);
        this.idsDontMatch = true;
      }
    },
    redirectToCashflow() {
      router.push({ path: '/sr', query: this.$route.query });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
