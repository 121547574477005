<template>
  <insight :insight="insight"
           animation-name="challenge-insight.json"
           :button-text="buttonText"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div v-if="availableChallengeCategories > 1">
        <span>המערכת זיהתה</span>
        <span class="bold">{{availableChallengeCategories}} סוגי הוצאות </span>
        <span> שאפשר לצמצם כבר החודש.</span>
        <span v-if="isPredictedCashflowPositive">{{bigChangeText}}</span>
        <span v-else> מומלץ להגדיר לאחת מהן יעד חודשי ולהיות על זה!</span>
      </div>
      <div v-else>
        <span>המערכת זיהתה</span>
        <span class="bold">קטגוריה שאפשר לצמצם</span>
        <span>כבר החודש.</span>
        <span v-if="isPredictedCashflowPositive">{{bigChangeText}}</span>
        <span v-else> מומלץ להגדיר לה יעד חודשי ולהיות על זה!</span>
      </div>
    </template>
  </insight>
</template>

<script>
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';
import ChallengeFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/current-month/components/challenge-flow/ChallengeFlow';

export default {
  name: 'ChallengeInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('ChallengeInsight_Shown');
  },
  computed: {
    ...mapGetters('createTrackingCategory', ['currentDefaultTrackingCategories']),
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    isPredictedCashflowPositive() {
      return this.predictedBalanceAtEndOfMonth > 0;
    },
    availableChallengeCategories() {
      return this.currentDefaultTrackingCategories.length;
    },
    buttonText() {
      return this.isPredictedCashflowPositive
        ? 'להציב יעד ולהתחיל לחסוך'
        : 'קדימה';
    },
    bigChangeText() {
      return ' שינוי גדול מתחיל בצעד קטן - זה הזמן להגדיר יעד חודשי!';
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      Segment.trackUserInteraction('ChallengeInsight_Clicked');
      this.openModal({
        component: ChallengeFlow,
        props: {
          insight: this.insight,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.bold {
  font-weight: bold;
}

</style>
