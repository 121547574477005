import * as HamsterApi from '@/api/HamsterApi';

const state = {
  initialized: false,
  cashflowTrends: null,
  customerProgress: null,
};

const getters = {
  totalCashflowsAndSavingsSinceActivation: state => state.customerProgress.totalCashflowsAndSavingsSinceActivation,
  availableCategoryTrends: state => state.cashflowTrends.trackingCategories,
};

const actions = {
  async initCustomerProgress({ commit }) {
    if (state.initialized) {
      return;
    }
    const [customerProgress, cashflowTrends] = await Promise.all([
      HamsterApi.getCustomerProgress(),
      HamsterApi.getCashflowTrends(),
    ]);
    commit('setCustomerProgress', customerProgress);
    commit('setCashflowTrends', cashflowTrends);
    commit('setInitialized', true);
  },
};

const mutations = {
  setCustomerProgress(state, customerProgress) {
    state.customerProgress = customerProgress;
  },
  setCashflowTrends(state, cashflowTrends) {
    state.cashflowTrends = cashflowTrends;
  },
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
