<template>
  <info-tool-tip-help-popup class="cc-issuer-help-popup"
    :title="'איך לדעת מהי חברת האשראי שצריך לחבר?'"
    :cx-dude="{ name: 'nofar', displayName: 'נופר' }"
    :popup-name="'ExpenseInvestigationHelpPopup'"
    :on-close="onClose">
    <template v-slot:description>
      <div class="description">
        כדי לדעת באיזו חברת אשראי לבחור צריך לחפש <span class="bold">על כל כרטיסי האשראי שלך </span> את אחד מהלוגואים שמופיעים ברשימה.

        <span class="bold">לדוגמא:</span>
      </div>
      <img class="cc-example-image" :src="require('@/assets/cc-example.svg')"/>
    </template>
  </info-tool-tip-help-popup>
</template>

<script>

import InfoToolTipHelpPopup from '../InfoToolTipHelpPopup.vue';

export default {
  name: 'CCIssuerHelpPopup',
  components: {
    InfoToolTipHelpPopup,
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .cc-issuer-help-popup {
    .description {
      white-space: pre-line;

      .bold {
        font-weight: bold;
      }
    }
    .cc-example-image {
      margin: 20px 0 47px;
    }
  }

</style>
