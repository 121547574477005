import { client } from '../http';

async function setCategoryAsShown(trackingCategoryId) {
  const response = await client.post('/api/tracking-categories-display', { display: 'show', trackingCategoryId });
  return response.data;
}

async function setTrackingCategoriesSelectionAsDone() {
  const response = await client.post('/api/tracking-categories-display/done', {});
  return response.data;
}

async function updateTrackingCategory({ categoryId, category }) {
  const response = await client.put(`/api/tracking-categories/${categoryId}`, category);
  return response.data;
}

export async function updateTrackingCategoriesDisplaySettings(order) {
  const response = await client.post('/api/tracking-categories/display-settings', { order });
  return response.data;
}

export async function getTrackingCategoriesDisplaySettings() {
  const response = await client.get('/api/tracking-categories/display-settings');
  return response.data;
}

export default {
  setCategoryAsShown,
  setTrackingCategoriesSelectionAsDone,
  updateTrackingCategory,
  updateTrackingCategoriesDisplaySettings,
  getTrackingCategoriesDisplaySettings,
};
