<template>
  <div class="your-referral-status">
    <card class="status-card" :has-content-padding="false">
      <template v-slot:header>
        <div class="status-header ri-bold-title">
          מצב ההמלצות שלך
        </div>
      </template>
      <template v-slot:default>
        <referral-counter
          :activation-number="pastThreeMonthsRefferalsToPay[0].activations"
          :first-payment-number="pastThreeMonthsRefferalsToPay[0].firstPayments"
          :title="pastThreeMonthsRefferalsToPay[0].name"/>
        <expandable-summary-row text-style="medium"
                                :with-shadow=true
                                :is-expandable="true"
                                :initially-expanded="hasPreviousMonthsReferrals"
                                @expanded="onExpand">
          <template v-slot:header>
            <div class="expandable-header">
              <span class="ri-bold-label">החודשים האחרונים</span>
            </div>
          </template>
          <template v-slot:content>
            <template v-for="(month, index) in previousMonths">
              <referral-counter
                :key="index"
                :activation-number="month.activations"
                :first-payment-number="month.firstPayments"
                :title="month.name"
                :variant="'withBackground'"/>
            </template>
          </template>
        </expandable-summary-row>
      </template>
    </card>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import ExpandableSummaryRow
  from '../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/ExpandableSummaryRow.vue';
import ReferralCounter from './ReferralCounter';

export default {
  name: 'YourReferralStatus',
  components: {
    ExpandableSummaryRow,
    ReferralCounter,
    Card: BaseUI.Card,
  },
  methods: {
    onExpand() {
      Segment.trackUserInteraction('YourReferralStatus_Expanded');
    },
  },
  computed: {
    ...mapGetters('referrals', ['pastThreeMonthsRefferalsToPay']),
    previousMonths() {
      return this.pastThreeMonthsRefferalsToPay.slice(1);
    },
    hasPreviousMonthsReferrals() {
      return this.pastThreeMonthsRefferalsToPay
        && _.some(this.pastThreeMonthsRefferalsToPay, month => month.activations > 0 || month.firstPayments > 0);
    },

  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/riseup-colors';

.your-referral-status {
  .status-card {
    padding-top: 26px;

    .status-header {
      color: $riseup_black;

      .icon-and-text {
        display: flex;
        align-items: center;
        padding: 0;
      }
    }
  }

  .expandable-header {
    display: flex;
    align-items: center;
    height: 56px;
    padding-right: 7px;
  }
}

</style>
