import * as _ from 'lodash';
import moment from 'moment';
import DDLogs from '@/DDLogs';
import config from '@/config';
import Segment from '@/Segment';
import ChallengesApi from '../../api/ChallengesApi';

const state = {
  activeChallengesData: [],
  hundredDaysStartDate: undefined,
  loading: false,
  hasError: false,
};

const actions = {
  async initChallenges({ commit }) {
    commit('setLoading', true);
    try {
      const activeChallengesData = await ChallengesApi.getActiveChallengesData();
      commit('setActiveChallengesData', activeChallengesData);
    } catch (error) {
      DDLogs.log('Something went wrong when fetching challenge. Look at logs for challenges service', { error });
      commit('setError', true);
    } finally {
      commit('setLoading', false);
    }
  },
  async getHundredDaysInfo({ commit }, challengeId) {
    const hundredDaysChallenge = await ChallengesApi.getChallengeNoAuth(challengeId);
    if (hundredDaysChallenge && hundredDaysChallenge.startDate) {
      commit('setHundredDaysStartDate', hundredDaysChallenge.startDate);
    }
  },
  async joinChallenge({ commit }, { challengeId }) {
    try {
      commit('setLoading', true);
      await ChallengesApi.addChallengesProduct();
      const memberAdditionStatuses = await ChallengesApi.createParticipants(challengeId);
      if (_.some(memberAdditionStatuses, ({ status }) => status === 'failed')) {
        commit('setError', true);
      }
      Segment.trackUserGot('CustomerAddedToChallenge', { challengeId });
    } catch (e) {
      commit('setError', true);
      DDLogs.error(
        'Failed adding customer to challenge',
        {
          error: e,
          challengeId,
        },
      );
    } finally {
      commit('setLoading', false);
    }
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, isError) {
    state.hasError = isError;
  },
  setActiveChallengesData(state, activeChallengesData) {
    state.activeChallengesData = activeChallengesData;
  },
  setHundredDaysStartDate(state, startDate) {
    state.hundredDaysStartDate = startDate;
  },
};

const getters = {
  challengesDataWithLinks: state => _.map(state.activeChallengesData, challengeData => ({
    ...challengeData,
    link: `${config.get().challengesFeUrl}/challenges/${encodeURIComponent(challengeData.challengeId)}`,
  })),
  canJoinHundredDaysProgram: state => state.hundredDaysStartDate && moment(state.hundredDaysStartDate).isSameOrAfter(moment(), 'day'),
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
