<template>
  <div class="opportunistic-saving-insight-popup">
    <insight-details-popup
                      :cashflow-category="cashflowCategory"
                      :insight="insight"
                      :buttons="buttons"
                      :showFeedbackFooter="false"
                      :close-action="closeAction">
      <template v-slot:top-content>
        <div class="insight-header">
          <div>סיימת את {{budgetMonth}} בתזרים חיובי של</div>
          <div class="amount">{{ formattedAmount }} <span class="nis">₪</span></div>
        </div>
      </template>
      <template v-slot:bottom-content>
        <div>
          <div class="insight-content">
            זו הזדמנות טובה להעביר כסף לחיסכון! אנחנו נדאג לעדכן אותך כשיהיו עוד הזדמנויות לחסוך.
          </div>
          <callout iconPath="icons/heart-icon.svg" title="טיפ מאיתנו" class="insight-callout">
            <template v-slot:text>
              רובנו נבזבז כסף שיושב סתם בעו״ש. לכן, כדאי להעביר לפחות חלק ממנו לחיסכון.
            </template>
          </callout>
        </div>
      </template>
      <template v-slot:footer>
        <div @click="dismissInsight" class="footer-btn">הבנתי, אל תציגו לי יותר</div>
      </template>
    </insight-details-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import moment from 'moment';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import moneyUtils from '@/utils/money';
import dateUtils from '@/utils/dates';
import InsightDetailsPopup from '../InsightDetailsPopup';

export default {
  name: 'OpportunisticSavingPopup',
  components: {
    InsightDetailsPopup,
    Callout: BaseUI.Callout,
  },
  props: {
    insight: { type: Object, require: true },
    cashflowCategory: { type: String, required: true },
    buttons: { type: Array, required: false },
    closeAction: { type: Function, required: true },
  },
  created() {
    Segment.trackUserGot('OpportunisticSavingPopupLoaded', this.insight);
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.proposedOpportunisticSavingAmount, 0);
    },
    budgetMonth() {
      return dateUtils.getHebrewMonth(moment(this.insight.budgetDate).subtract(1, 'month').format('YYYY-MM'));
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('insights', ['markAsRead']),
    thumbsUp() {
      Segment.trackUserInteraction('OpportunisticSavingPopupThumbsUp', { ...this.insight });
    },
    thumbsDown() {
      Segment.trackUserInteraction('OpportunisticSavingPopupThumbsDown', { ...this.insight });
    },
    dismissInsight() {
      Segment.trackUserInteraction('OpportunisticSavingPopupDismissed', this.insight);
      this.closeModal();
      this.markAsRead(this.insight).catch(error => {
        DDLogs.error('Failed to mark insight as read', { insight: this.insight, error });
      });
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .opportunistic-saving-insight-popup {
    text-align: right;

    .close-button {
      width: 100%;
    }

    .insight-header {
      color: $riseup_white;
      font-size: 16px;
      text-align: right;

      .amount {
        font-size: 46px;
        font-weight: bold;
        margin-top: 10px;
        .nis {
          font-size: 32px;
        }
      }
    }

    .insight-content {
      text-align: right;
      font-size: 22px;
    }

    .insight-callout {
      margin-top: 33px;
    }

    .footer-btn {
      margin-bottom: unset;
      margin-top: unset;
    }
  }

</style>
