<template>
  <form class="form" @submit.prevent="submit">
    <div class="row">
      <text-input :label-text="'על שם מי החשבון'"
                   input-type="text"
                   :value="name"
                   :disabled="loading"
                   :error-message="nameError"
                   :propagateEvents="true"
                   @input="setName"
                   @blur="validateName"
                   @focus="() => clearError('nameError')"/>
    </div>
    <div class="row">
      <text-input :label-text="'תעודת זהות'"
                   input-type="number"
                   :value="idNumber"
                   :disabled="isDisabled('idNumber')"
                   :error-message="idNumberError"
                   :propagateEvents="true"
                   @input="setIdNumber"
                   @blur="validateIdNumber"
                   @focus="() => clearError('idNumberError')"/>
    </div>
    <div class="row">
      <dropdown :options="availableBanks"
                :first-selected="selectedBankObject"
                :disabled="isDisabled('bankCode')"
                :label-text="'שם הבנק'"
                :on-select="setBank" />
      <text-input class="branch-number"
                 :label-text="'סניף'"
                 input-type="number"
                 :disabled="isDisabled('branchNumber')"
                 :value="branchNumber"
                 :error-message="branchNumberError"
                 :propagateEvents="true"
                 @input="setBranchNumber"
                 @blur="validateBranchNumber"
                 @focus="() => clearError('branchNumberError')"/>
    </div>
    <div class="row">
      <text-input class="input-container"
                 :label-text="'מספר חשבון בנק'"
                 input-type="number"
                 :disabled="isDisabled('accountNumber')"
                 :value="accountNumber"
                 :error-message="accountNumberError"
                 :propagateEvents="true"
                 @input="setAccountNumber"
                 @blur="validateAccountNumber"
                 @focus="() => clearError('accountNumberError')"/>
    </div>
    <div class="buttons">
      <riseup-button class="submit-button margin-bottom-ml"
                     type="submit"
                     :disabled="!canSubmit || loading"
                     :loading="loading"
                     title="עדכון אמצעי קבלת תשלום"
                     :action="() => {/*we don't do anything since the action is done on form submit*/}"/>
    </div>
  </form>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import _ from 'lodash';
import Banks
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/data/Banks.vue';
import Dropdown
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/referral-receive-payments/DropdownInput.vue';
import TextInput
  from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/referral-receive-payments/TextInput.vue';
import BankAccounts from '@/mixins/BankAccounts.vue';

export default {
  name: 'BankDetailsForm',
  mixins: [Banks, BankAccounts],
  components: {
    RiseupButton: BaseUi.RiseupButton,
    Dropdown,
    TextInput,
  },
  props: {
    initialData: {
      name: {
        value: String,
        disabled: {
          type: Boolean,
          default: false,
        },
      },
      idNumber: {
        value: Number,
        disabled: {
          type: Boolean,
          default: false,
        },
      },
      bankCode: {
        value: Number,
        disabled: {
          type: Boolean,
          default: false,
        },
      },
      branchNumber: {
        value: Number,
        disabled: {
          type: Boolean,
          default: false,
        },
      },
      accountNumber: {
        value: String,
        disabled: {
          type: Boolean,
          default: false,
        },
      },
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    serverError: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      name: this.initialData?.name?.value || '',
      idNumber: this.initialData?.idNumber?.value.toString() || '',
      bankCode: this.initialData?.bankCode?.value.toString() || '',
      branchNumber: this.initialData?.branchNumber?.value.toString() || '',
      accountNumber: this.initialData?.accountNumber?.value.toString() || '',
      nameError: '',
      idNumberError: '',
      branchNumberError: '',
      accountNumberError: '',
    };
  },
  computed: {
    availableBanks() {
      return _.map(this.banksData, (key, value) => ({
        label: key,
        selectedLabel: key,
        value: value.toString(),
      }));
    },
    selectedBankObject() {
      return _.find(this.availableBanks, { value: this.bankCode });
    },
    isIdNumberValid() {
      return this.idNumber ? this.idNumber.match(/^\d{7,9}$/) : true;
    },
    isBranchNumberValid() {
      return this.branchNumber ? this.branchNumber.match(/^\d{1,3}$/) : true;
    },
    isAccountNumberValid() {
      return this.accountNumber ? this.accountNumber.match(/^\d{1,10}$/) : true;
    },
    isNameValid() {
      // eslint-disable-next-line no-useless-escape
      return this.name ? this.name.match(/^[^\^\<\>\{\}\[\]\\\/\d"]+$/) : true;
    },
    canSubmit() {
      return !this.loading
        && this.isNameValid
        && this.isIdNumberValid
        && this.isBranchNumberValid
        && this.isAccountNumberValid
        && this.name.length > 0
        && this.bankCode.length > 0;
    },
  },
  methods: {
    isDisabled(field) {
      return this.loading || (this.initialData && this.initialData[field]?.disabled);
    },
    validateName() {
      this.nameError = '';
      if (!this.isNameValid) {
        this.nameError = 'שם לא תקין';
      }
    },
    setName(e) {
      this.name = e.target.value;
    },
    setIdNumber(e) {
      this.idNumber = e.target.value;
    },
    clearError(errorKey) {
      this[errorKey] = '';
    },
    validateIdNumber() {
      this.idNumberError = '';
      if (!this.isIdNumberValid) {
        this.idNumberError = 'תעודת זהות לא תקינה';
      }
    },
    setBank(bankOption) {
      this.bankCode = bankOption.value;
    },
    setBranchNumber(e) {
      this.branchNumber = e.target.value;
    },
    validateBranchNumber() {
      this.branchNumberError = '';
      if (!this.isBranchNumberValid) {
        this.branchNumberError = 'סניף לא תקין';
      }
    },
    setAccountNumber(e) {
      this.accountNumber = e.target.value;
    },
    validateAccountNumber() {
      this.accountNumberError = '';
      if (!this.isAccountNumberValid) {
        this.accountNumberError = 'חשבון בנק לא תקין';
      }
    },
    submit() {
      if (!this.canSubmit) {
        return;
      }
      this.onSubmit({
        bankAccountOwner: this.name,
        idNumber: this.idNumber,
        bankCode: this.bankCode,
        bankBranch: this.branchNumber,
        accountNumber: this.accountNumber,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/spacings';

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: $ri-spacing-l;

  .row {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: $ri-spacing-ml;

    &>:first-child {
      // making sure the first child in the row will stretch
      flex-grow: 1;
    }

    .branch-number {
      width: 100px;
    }
  }
  .buttons {
    .submit-button {
      width: 100%;
      height: 64px;
    }
  }
}
</style>
