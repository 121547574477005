import { render, staticRenderFns } from "./AdvisoryReview1.vue?vue&type=template&id=6010830b&scoped=true"
import script from "./AdvisoryReview1.vue?vue&type=script&lang=js"
export * from "./AdvisoryReview1.vue?vue&type=script&lang=js"
import style0 from "./AdvisoryReview1.vue?vue&type=style&index=0&id=6010830b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6010830b",
  null
  
)

export default component.exports