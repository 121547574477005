<template>
  <div class="property">
      <div class="ri-label margin-bottom-xs">{{ name }}</div>
    <div class="ri-bold-title margin-bottom-xxs">
      <span class="strike-value padding-left-xs" v-if="strikeValue">{{ strikeValue }}</span>
      <span :class=" valueColor">{{ value }}</span>
    </div>
    <div v-if="subTitle" class="ri-title">{{ subTitle }}</div>
  </div>
</template>

<script>
export default {
  name: 'Property',
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    strikeValue: {
      type: String,
      required: false,
    },
    valueColor: {
      type: String,
      required: false,
    },
    subTitle: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

  .property {
    display: flex;
    flex-direction: column;

    .strike-value {
      color: $riseup_gray_40;
      text-decoration: line-through;
    }

    .red {
      color:$riseup_danger_dark_red
    }
  }

</style>
