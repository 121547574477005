<template>
  <colored-popup :class="'light-blue'" v-bind="popupActions" dark-text>
    <template v-slot:top-content>
        <div class="centered title margin-top-large margin-bottom-medium">
          מומלץ להשאיר בעו״ש יותר מחודש אחד של ההוצאות החודשיות שלך
        </div>
    </template>

    <template v-slot:bottom-content>
      <div class="body-text">
        <div class="bold margin-bottom-medium">כמה כדאי לי להשאיר בעו״ש?</div>
        <template v-for="index in optionIndexes" >
          <selectable-riseup-button :key="index" class="margin-bottom-medium" :on-select="() => onSelect(index)" :is-selected="selected === index">
            <div class="radio-button-text">סכום שמכסה <span class="bold">{{indexToMonth[index]}} מחייה</span> שלי</div>
          </selectable-riseup-button>
        </template>
        <div class="calc-result-container margin-top-large">
          <animated-number class="sum" :value="sum" :formatter="format"/>
          <div v-for="circleIndex in circlesIndexes" :class="`circle layer-${circleIndex}`" :key="circleIndex"/>
        </div>
        <div class="disclaimer margin-top-x-large">*לפי ממוצע שלושת החודשים האחרונים</div>
      </div>
    </template>
    <template v-slot:footer>
        <riseup-button
          :title="ctaText"
          :action="onNextClicked"
          bold />
    </template>
  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapState } from 'vuex';
import moneyUtils from '@/utils/money';
import SelectableRiseupButton from '@/base-components/SelectableRiseupButton';
import _ from 'lodash';
import moment from 'moment';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import { DateLocales } from '@riseupil/common-utils';

export default {
  name: 'BalanceMoneyCalculatorStep',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    AnimatedNumber: BaseUI.AnimatedNumber,
    SelectableRiseupButton,
  },
  data() {
    return {
      selected: 1,
      indexToMonth: [
        'חודש',
        'שני חודשי',
        'שלושה חודשי',
      ],
    };
  },
  props: {
    onBack: {
      type: Function,
      required: false,
    },
    onNext: {
      type: Function,
      required: false,
    },
    stepName: {
      type: String,
      required: true,
    },
    deepLink: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    Segment.trackUserInteraction('BalanceMoneyFlow_StepEntered', { step: this.stepName });
  },
  computed: {
    ...mapState('cashflowView', ['allCashflows']),
    ctaText() {
      return this.deepLink ? 'הבנתי, תודה' : 'אני רוצה לראות אפשרויות';
    },
    popupActions() {
      if (this.deepLink) {
        return {
          'close-action': () => this.$emit('close'),
        };
      }
      return {
        'back-action': this.onBack,
      };
    },
    expenseAverage() {
      return _.chain(this.allCashflows)
        .orderBy(cashflow => moment(cashflow.budgetDate, 'YYYY-MM'), 'desc')
        .drop(1) // exclude current budget date
        .slice(0, 3)
        .map(cashflow => reactiveCashflow.createCashflow(cashflow, DateLocales.EN_IL))
        .map(rCashflow => rCashflow.expenseActualSum)
        .mean()
        .value();
    },
    sum() {
      return this.expenseAverage * (this.selected + 1);
    },
    optionIndexes() {
      return _.range(this.indexToMonth.length);
    },
    circlesIndexes() {
      return _.range(this.selected, -1, -1);
    },
  },
  methods: {
    onSelect(selectedOption) {
      Segment.trackUserInteraction('BalanceMoneyFlow_CalculatorOptionSelected', { option: selectedOption });
      this.selected = selectedOption;
    },
    onNextClicked() {
      if (this.deepLink) {
        this.$emit('close');
        return;
      }
      Segment.trackUserInteraction('BalanceMoneyFlow_CalculatorNextStepClicked', { selection: this.selected });
      this.onNext();
    },
    format(value) {
      return `${moneyUtils.formatAmountWithFraction(value, 0)} ₪`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./balance-money-styles";
@import "~@riseupil/base-ui/src/scss/typography";

$circle-size: 180px;

.body-text {
  display: flex;
  flex-direction: column;
}

.radio-button-text {
  font-size: $ri-font-size-regular;
  line-height: 20px;
  width: 100%;
}

.calc-result-container {
  width: 100%;
  height: $circle-size;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .disclaimer {
    margin-top: 48px;
  }
  .sum {
    color: $riseup_white;
    font-size: $ri-font-size-24;
    font-weight: bold;
    z-index: 2;
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(16px);
    }
  }

  .circle {
    position: absolute;
    width: $circle-size;
    height: $circle-size;
    background-color: $riseup_yellow;
    border-radius: 50%;
    z-index: 1;

    &.layer-1 {
      background-color: $riseup_light_yellow;
      animation: 0.3s ease-in-out forwards slideInFromLeft;
    }
    &.layer-2 {
      margin-left: 32px;
      animation: 0.3s ease-in-out forwards slideInFromLeft;
      background-color: $riseup_beige;
    }
  }
}
</style>
