<template>
  <papas-merge-questions-action-selection :papa-merge-questions="papaMergeQuestions" :show-mark-as-loan-button="true"
                                          first-title="לחיוב הזה חסר פירוט."/>

</template>

<script>

import PapasMergeQuestionsActionSelection from './PapasMergeQuestionsActionSelection';

export default {
  name: 'SpecificPapaMergeQuestionsActionSelection',
  components: { PapasMergeQuestionsActionSelection },

  props: {
    papaMergeQuestions: {
      type: Array,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
