<template>
  <div class="after-payment">
    <dashboard-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="headline-container">
          <div class="ri-large-body margin-top-ml">פרטי התשלום התקבלו בהצלחה</div>
          <div class="ri-small-display margin-bottom-l">
            <div>פרטי התוכנית</div>
          </div>
        </div>
      </template>
      <template v-slot:dashboard>
        <div class="margin-ml">
          <property class="margin-bottom-m" name="סוג התוכנית" :value="title[0]" :sub-title="title[1]" />
          <property class="margin-bottom-m" name="סך הכל לתשלום" :value="`${selectedOffering.price.amount} ₪`"/>
          <property class="margin-bottom-m" name="תאריך לחיוב" :value="currentDate"/>
          <property class="margin-bottom-m" name="כרטיס אשראי לחיוב" :value="newFormattedCardNumberMask"/>
          <property class="margin-bottom-xs" name="אימייל לשליחת קבלות" :value="activeMember.emailPiiValue"/>
          <div class="link" @click="redirectToPersonalInfo">עריכה</div>
        </div>
      </template>
    </dashboard-page>
  </div>
</template>

<script>
import Segment from '@/Segment';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import Property from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/Property.vue';
import router from '@/router';
import { BACKGROUND_POSITION } from '@riseupil/base-ui/src/utils/layout-consts';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'AfterPayment',
  components: {
    Property,
    DashboardPage: BaseUI.Pages.DashboardPage,
  },
  props: {
    onEnterCreditCard: {
      type: Function,
      require: true,
    },
  },
  async created() {
    Segment.trackUserGot('AfterPaymentEntered', { offeringId: this.selectedOffering?.id });
  },
  computed: {
    ...mapGetters('oneOffOfferings', ['title', 'images']),
    ...mapState('oneOffOfferings', ['selectedOffering']),
    ...mapGetters('subscription', ['newFormattedCardNumberMask']),
    ...mapState('session', ['activeMember']),
    imageHeaderProps() {
      return {
        // eslint-disable-next-line global-require
        imgSrc: this.images?.landing,
        bottomSeparator: false,
        textColor: 'white',
        backgroundPosition: BACKGROUND_POSITION.BOTTOM,
      };
    },
    currentDate() {
      return moment().format('D.M.YYYY');
    },
  },
  methods: {
    redirectToPersonalInfo() {
      router.push({ path: '/personal-info' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
  .after-payment {
    text-align: start;
    flex: 1;

    .headline-container {
      text-align: start;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }

    ::v-deep .title.with-overlay {
      align-items: unset;
    }

    .image {
      height: 130px;
      width: 200px;
    }

    .blue-text {
      color: $riseup_blue;
    }

    .link {
      cursor: pointer;
      text-decoration: underline;
      color: $riseup_blue;
    }
  }
</style>
