<template>
  <div class="predicted-variable-income-eom-popup">
    <popup title="רוצה לעדכן את צפי ההכנסות המשתנות שלך?"
           :close-action="close"
           :buttons="buttons">
      <template v-slot:content>
        <div class="text">
          הגדרת צפי בסך
          <span class="no-wrap"> {{ formatNumber(variableIncomePredictionAmount) }} ₪ </span>
          <b>
            והמערכת עדיין מצפה להכנסות משתנות בסך
            <span class="no-wrap"> {{ formatNumber(variableIncomePredictionAmount - variableIncomeActualSum) }} ₪{{
                cashflowStartDay === 1 ?
                  '.' : ''
              }}
            </span>
          </b>
          <span v-if="cashflowStartDay!==1">שיגיעו עד ה־{{ endOfCashflowDate }}.</span>
        </div>
        <div class="text">
          שנחכה עוד קצת, או שצריך לעדכן על שינוי בתוכניות?
        </div>
        <div class="progress-container">
          <div class="effect-text">סך כל ההכנסות</div>
          <div class="separator"></div>
          <cashflow-expense-progress class="progress-bar"
                                     cashflowCategory="fixed-income"
                                     :recommended-or-expected-amount="expectedAmount"
                                     :up-to-now-amount="upToNowAmount"
                                     :is-income="true"/>
          <div class="actuals">
            עד עכשיו נכנסו
            <b>{{ formatNumber(variableIncomeActualSum) }} ₪</b>
            הכנסות משתנות
          </div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import _ from 'lodash';
import dateUtils from '@/utils/dates';
import moneyUtils from '@/utils/money';
import cashflowViewConsts from '@/constants/cashflow-view';
import CashflowExpenseProgress
  from '../../cashflow-view/generic-components/cashflow-types/cashflow-types-base/CashflowExpenseProgress';
import EditPredictedVariableIncomePopup
  from '../../cashflow-view/current-month/components/cashflow-edit/edit-predicted-variable-income/EditPredictedVariableIncomePopup';

export default {
  name: 'PredictedVariableIncomeEOMPopup',
  components: {
    CashflowExpenseProgress,
    Popup: BaseUI.Popup,
  },
  props: {
    insight: {
      type: Object,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('PredictedVariableIncomeEOMPopup_Shown');
  },
  computed: {
    ...mapState('cashflowView', ['cashflow', 'cashflowUITree', 'cashflowStartDay']),
    buttons() {
      return [
        {
          title: 'לעדכן את הצפי',
          variant: 'primary',
          size: 'slim',
          action: this.editPrediction,
        },
        {
          title: 'להשאיר ככה',
          variant: 'secondary',
          size: 'slim',
          action: this.cancel,
        },
      ];
    },
    variableIncomeActualSum() {
      return Math.abs(this.cashflow.variableIncomeActualSum);
    },
    variableIncomePredictionAmount() {
      return Math.abs(this.cashflow.variableIncomePredictionAmount);
    },
    incomeEnvelopesBalanceSum() {
      return Math.abs(this.cashflow.incomeEnvelopesBalanceSum);
    },
    upToNowAmount() {
      return Math.abs(this.cashflowUITree[cashflowViewConsts.cashflowTypes.income]?.upToNowAmount);
    },
    expectedAmount() {
      return Math.abs(this.cashflowUITree[cashflowViewConsts.cashflowTypes.income]?.recommendedOrExpectedAmount);
    },
    endOfCashflowDate() {
      return dateUtils.endOfMonthText(this.cashflow.budgetDate, this.cashflowStartDay);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('insights', ['markAsRead']),

    close() {
      Segment.trackUserInteraction('PredictedVariableIncomeEOMPopup_closed');
      this.closeModal();
    },
    cancel() {
      Segment.trackUserInteraction('PredictedVariableIncomeEOMPopup_Cancel_Clicked');
      this.markAsRead(this.insight);
      this.closeModal();
    },
    editPrediction() {
      Segment.trackUserInteraction('PredictedVariableIncomeEOMPopup_Edit_Clicked');
      this.closeModal();
      this.openModal({
        component: EditPredictedVariableIncomePopup,
      });
    },
    formatNumber(number) {
      if (!_.isNil(number)) {
        return moneyUtils.formatAmount(_.round(number, 1));
      }
      return '';
    },
  },
};
</script>

<style scoped lang="scss">

@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/border-radius';
@import '@/scss/category-color-mixins';

.predicted-variable-income-eom-popup {
  .text {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 24px;

    .no-wrap {
      white-space: nowrap;
    }
  }

  .progress-container {
    text-align: right;
    border: solid 1px $riseup_gray_0;
    border-radius: $input-field-border-radius;
    margin-top: 12px;
    margin-bottom: 20px;

    .effect-text {
      text-align: right;
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      padding: 12px 20px;
    }

    .separator {
      height: 1px;
      background: $riseup_gray_0;
      margin-bottom: 20px;
    }

    .progress-bar {
      padding: 0 20px;
    }

    .actuals {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 16px;
      margin-right: 20px;
      margin-top: -4px;
    }
  }
}

</style>
