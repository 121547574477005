import StartStep from './steps/StartStep';
import ScoringStep from './steps/ScoringStep';
import ReasonsStep from './steps/ReasonsStep';
import AnythingElseStep from './steps/AnythingElseStep';
import EndStep from './steps/EndStep';

export const ACTIVATION_SURVEY_STEPS = {
  Start: 'Start',
  Satisfaction: 'Satisfaction',
  Understandable: 'Understandable',
  UnderstandableFollowup: 'UnderstandableFollowup',
  Reflectiveness: 'Reflectiveness',
  ReflectivenessFollowup: 'ReflectivenessFollowup',
  Helpfulness: 'Helpfulness',
  HelpfulnessFollowup: 'HelpfulnessFollowup',
  AnythingElse: 'AnythingElse',
  End: 'End',
};

export const CALENDAR_CASHFLOW_UNDERSTANDING_OPTION = 'תזרים מה־1 עד ה־1 בחודש לא מסתדר';
export const activationSurveyStepsConfig = {
  [ACTIVATION_SURVEY_STEPS.Start]: {
    component: StartStep,
    nextStep: _answers => ACTIVATION_SURVEY_STEPS.Satisfaction,
  },
  [ACTIVATION_SURVEY_STEPS.Satisfaction]: {
    component: ScoringStep,
    score: { lowScoreText: 'נמוכה', highScoreText: 'גבוהה' },
    step: { currentStep: 1, totalNumberOfSteps: 5 },
    question: 'מה מידת שביעות הרצון שלך מרייזאפ עד עכשיו?',
    nameForCxSummary: 'שביעות רצון',
    nextStep: _answers => ACTIVATION_SURVEY_STEPS.Understandable,
  },
  [ACTIVATION_SURVEY_STEPS.Understandable]: {
    component: ScoringStep,
    step: { currentStep: 2, totalNumberOfSteps: 5 },
    question: 'עד כמה מובן האופן שבו התזרים מאורגן ומחושב?',
    nameForCxSummary: 'ארגון וחישוב התזרים',
    nextStep: answers => (answers[ACTIVATION_SURVEY_STEPS.Understandable] > 2
      ? ACTIVATION_SURVEY_STEPS.Reflectiveness
      : ACTIVATION_SURVEY_STEPS.UnderstandableFollowup),
  },
  [ACTIVATION_SURVEY_STEPS.UnderstandableFollowup]: {
    component: ReasonsStep,
    step: { currentStep: 2, totalNumberOfSteps: 5 },
    question: 'תודה, נשמח להבין יותר. מה בתזרים  היה לא מובן?',
    options: [
      'לא מסתדר לי מול היתרה שלי בעו״ש',
      CALENDAR_CASHFLOW_UNDERSTANDING_OPTION,
      'החלוקה להוצאות משתנות, קבועות וכו׳',
      'חישוב יתרת ההוצאות לא ברור',
    ],
    nextStep: _answers => ACTIVATION_SURVEY_STEPS.Reflectiveness,
  },
  [ACTIVATION_SURVEY_STEPS.Reflectiveness]: {
    component: ScoringStep,
    step: { currentStep: 3, totalNumberOfSteps: 5 },
    question: 'עד כמה התזרים משקף נכון את ההכנסות וההוצאות שלך?',
    nameForCxSummary: 'דיוק הכנסות והוצאות',
    nextStep: answers => (answers[ACTIVATION_SURVEY_STEPS.Reflectiveness] > 2
      ? ACTIVATION_SURVEY_STEPS.Helpfulness
      : ACTIVATION_SURVEY_STEPS.ReflectivenessFollowup),
  },
  [ACTIVATION_SURVEY_STEPS.ReflectivenessFollowup]: {
    component: ReasonsStep,
    step: { currentStep: 3, totalNumberOfSteps: 5 },
    question: 'תודה, נשמח להבין יותר. מה נראה לא נכון בתזרים?',
    options: [
      'ההכנסות בתזרים לא מדויקות',
      'ההוצאות בתזרים לא מדויקות',
      'ההוצאות לא נמצאות בקטגוריה הנכונה',
    ],
    nextStep: _answers => ACTIVATION_SURVEY_STEPS.Helpfulness,
  },
  [ACTIVATION_SURVEY_STEPS.Helpfulness]: {
    component: ScoringStep,
    step: { currentStep: 4, totalNumberOfSteps: 5 },
    question: 'נכון לעכשיו, עד כמה נראה לך שהתזרים יכול לעזור לך?',
    nameForCxSummary: 'עד כמה נראה לך שהתזרים יכול לעזור',
    nextStep: answers => (answers[ACTIVATION_SURVEY_STEPS.Helpfulness] > 2
      ? ACTIVATION_SURVEY_STEPS.AnythingElse
      : ACTIVATION_SURVEY_STEPS.HelpfulnessFollowup),
  },
  [ACTIVATION_SURVEY_STEPS.HelpfulnessFollowup]: {
    component: ReasonsStep,
    step: { currentStep: 4, totalNumberOfSteps: 5 },
    question: 'תודה, נשמח להבין יותר. למה לדעתך התזרים לא יכול לעזור לך?',
    options: [
      'ציפיתי לקבל משהו אחר בתזרים',
      'לא ברור איך הוא משתלב לי ביומיום',
      'חסרים לי טיפים ועצות מה לעשות',
      'בן/בת הזוג שלי עדיין לא איתי בזה',
    ],
    nextStep: _answers => ACTIVATION_SURVEY_STEPS.AnythingElse,
  },
  [ACTIVATION_SURVEY_STEPS.AnythingElse]: {
    component: AnythingElseStep,
    question: 'יש עוד משהו שהיית רוצה שנדע?',
    nameForCxSummary: 'יש עוד משהו שהיית רוצה שנדע?',
    nextStep: _answers => ACTIVATION_SURVEY_STEPS.End,
  },
  [ACTIVATION_SURVEY_STEPS.End]: { component: EndStep },
};
