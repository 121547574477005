import customersApi from '../../api/CustomersApi';

const state = {
  churnValidated: false,
};

const actions = {
  churnCustomer() {
    return customersApi.churn();
  },
  churnThroughMemberInvite() {
    return customersApi.churnThroughMemberInvite();
  },
  async setChurnValidated({ commit }, churnValidated) {
    commit('setChurnValidated', { churnValidated });
  },
};

// mutations
const mutations = {
  setChurnValidated(state, { churnValidated }) {
    state.churnValidated = churnValidated;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
