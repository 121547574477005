<template>
  <div class="insight-feedback-smiley">
      <div class="title">
        <span v-if="!answered">המידע הזה עזר לך?</span>
        <span v-else>תודה!</span>
      </div>
      <div class="smilies">
        <div :class="{ button: true, 'button-after-selection': answered }" @click="like">
          <img v-if="!dislikeClicked" :src="require('@/assets/icons/smiling-smiley-blue.svg')"/>
          <img v-else :src="require('@/assets/icons/smiling-smiley-grey.svg')"/>
        </div>
        <div :class="{ button: true, 'button-after-selection': answered }" @click="dislike">
          <img v-if="!likeClicked" :src="require('@/assets/icons/sad-smiley-blue.svg')"/>
          <img v-else :src="require('@/assets/icons/sad-smiley-grey.svg')"/>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'InsightFeedbackSmiley',
  data() {
    return {
      answered: false,
      likeClicked: false,
      dislikeClicked: false,
    };
  },
  computed: {
    buttons() {
      return [
        {
          title: 'סגור',
          variant: 'primary',
          action: this.closeActionClicked,
        },
      ];
    },
  },
  methods: {
    like() {
      this.$emit('smile-feedback');
      this.answered = true;
      this.likeClicked = true;
    },
    dislike() {
      this.$emit('sad-feedback');
      this.answered = true;
      this.dislikeClicked = true;
    },
  },
};
</script>

<style scoped lang="scss">
  @import "~@riseupil/base-ui/src/scss/riseup-colors";

  .insight-feedback-smiley {
    font-family: SimplerPro;

    .title {
      text-align: center;
      font-size: 18px;
      margin-bottom: 15px;
    }
    .smilies {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 48%;
        border: 2px solid $riseup_blue;
        border-radius: 128px;
        cursor: pointer;
      }

      .button-after-selection {
        border-color: $riseup_gray_0;
        pointer-events:none;
      }
    }
    .sub-title {
      margin-bottom: 20px;
      text-align: center;
      font-size: 22px;
    }
  }
</style>
