<template>
  <div>
    <personal-info-field-v2
      class="field margin-bottom-l"
      label="שם פרטי"
      field-name="payerFirstName"
      :initialValue="firstNameInitialValue"
      :isValid="value => value.length >= 2"
      @input="(newValue) => onInput('payerFirstName', newValue)"
      @validationChange="onValidationChange"
    />
    <personal-info-field-v2
      class="field margin-bottom-l"
      label="שם משפחה"
      field-name="payerLastName"
      :initialValue="lastNameInitialValue"
      :isValid="value => value.length >= 2"
      @input="(newValue) => onInput('payerLastName', newValue)"
      @validationChange="onValidationChange"
    />
    <personal-info-field-v2
      class="field margin-bottom-l"
      label="מס׳ נייד"
      field-name="payerPhone"
      :initialValue="phoneNumberInitialValue"
      :isValid="validatePhoneNumber"
      @input="(newValue) => onInput('payerPhone', newValue)"
      @validationChange="onValidationChange"
    />
  </div>
</template>

<script>
import phoneNumberUtils from '@/utils/phoneNumber';
import PersonalInfoFieldV2 from './PersonalInfoFieldV2.vue';

export default {
  name: 'PersonalInfoFormV2',
  components: {
    PersonalInfoFieldV2,
  },
  props: {
    firstNameInitialValue: {
      type: String,
      required: false,
    },
    lastNameInitialValue: {
      type: String,
      required: false,
    },
    phoneNumberInitialValue: {
      type: String,
      required: false,
    },
  },
  methods: {
    onInput(field, input) {
      this.$emit('input', { field, value: input });
    },
    onValidationChange(args) {
      this.$emit('validationChange', args);
    },
    validatePhoneNumber(value) {
      return phoneNumberUtils.validatePhoneNumber(value);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
