import Vue from 'vue';
import { getTransactionsForSearch } from '@/store/utilities/cashflow-search';

const state = {
  searching: false,
  transactionsForSearch: null,
};

const actions = {
  async initCashflowSearch({ commit }, { allCashflows, plans }) {
    const transactionsForSearch = getTransactionsForSearch(allCashflows, plans);
    commit('setTransactionsForSearch', transactionsForSearch);
  },
  async setSearching({ commit, dispatch }, searching) {
    commit('setSearching', searching);
    await dispatch('bottomCornerPopup/toggleBottomCornerVisibility', !searching, { root: true });
  },
  async addTransactionsForSearch({ commit, state }, { newCashflows, plans }) {
    const transactionsForSearch = getTransactionsForSearch(newCashflows, plans);
    commit('setTransactionsForSearch', [...state.transactionsForSearch, ...transactionsForSearch]);
  },
};

// mutations
const mutations = {
  setTransactionsForSearch(state, transactionsForSearch) {
    Vue.set(state, 'transactionsForSearch', transactionsForSearch);
  },
  setSearching(state, searching) {
    state.searching = searching;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
