<template>
  <bottom-sheet v-bind="sheetProps" @close="closeMenu">
    <template v-slot:menuItems v-if="menuItems">
      <bottom-sheet-menu-item v-for="menuItem in menuItems" :key="menuItem.text" @click="() => onItemClick(menuItem)"
                              :text="menuItem.text" :icon-props="menuItem.iconProps" :disabled="menuItem.disabled"/>
    </template>
    <template v-slot:header v-else>
      אין אפשרויות זמינות
    </template>
  </bottom-sheet>
</template>

<script>

import BaseUi from '@riseupil/base-ui';

export default {
  name: 'SimpleBottomSheetMenu',
  components: {
    BottomSheet: BaseUi.BottomSheet,
    BottomSheetMenuItem: BaseUi.BottomSheetMenuItem,
  },
  props: {
    sheetProps: {
      type: Object,
      required: false,
    },
    menuItems: {
      type: Array, // of { text: string, iconProps: Object, disabled: boolean, onClick: Function, keepOpenOnClick: boolean }
      required: true,
    },
  },
  methods: {
    closeMenu() {
      this.$emit('close');
    },
    onItemClick(item) {
      if (!item.keepOpenOnClick) {
        this.closeMenu();
      }
      if (item.onClick) {
        item.onClick();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
