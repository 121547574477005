<template>
  <insight :insight="insight"
           :image-name="'join_ambassador_insight_header.svg'"
           :button-text="'ספרו לי עוד'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        <span>המליצו על רייזאפ לחברים -</span>
        <span class="bold">חודש ראשון חינם ועוד חודשיים ב - 50% הנחה.</span>
      </div>
    </template>
  </insight>
</template>

<script>
import Segment from '@/Segment';
import router from '@/router';
import { mapActions } from 'vuex';
import Insight from './components/Insight';

export default {
  name: 'JoinAmbassadorInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('JoinAmbassadorsInsight_Shown');
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    buttonAction() {
      Segment.trackUserInteraction('JoinAmbassadorsInsight_Clicked');
      this.markAsRead(this.insight);
      router.push({ path: '/ambassadors' });
    },
  },
};

</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
