<template>
  <div class="payment">
    <page-header v-bind="pageHeaderProps" />
    <payment-using-existing-card v-if="useExistingPaymentMethod" :onEnterCreditCard="onEnterCreditCard" :image="images.payment" />
    <payment-using-different-card v-if="enterNewCreditCard" :image="images.payment" />
    <after-payment v-if="afterPayment"/>
    <div class="full-width padding-ml">
      <riseup-button :title="buttonText" :action="onSubmit" :variant="'primary'" :size="'slim'" :disabled="disableButton"
      :loading="submitLoading" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import BaseUi from '@riseupil/base-ui';
import Segment from '@/Segment';
import router from '@/router';
import AfterPayment from '@/pages/responsive-pages/authenticated/navigation-pages/payment/AfterPayment';
import PaymentUsingExistingCard
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentUsingExistingCard';
import PaymentUsingDifferentCard
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentUsingDifferentCard';
import FailedPaymentPopup from '@/pages/responsive-pages/authenticated/navigation-pages/payment/FailedPaymentPopup';

const PAYMENT_STATES = {
  useExistingPaymentMethod: 'use-existing-payment-method',
  enterNewCreditCard: 'enter-new-credit-card',
  afterPayment: 'after-payment',
};

export default {
  name: 'PaymentPage',
  components: {
    AfterPayment,
    PaymentUsingExistingCard,
    PaymentUsingDifferentCard,
    RiseupButton: BaseUi.RiseupButton,
    PageHeader: BaseUi.PageHeader,
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      state: null,
    };
  },
  props: {
    onClose: {
      type: Function,
      require: true,
    },
    insight: {
      type: Object,
      required: false,
    },
  },
  async created() {
    Segment.trackUserGot('PaymentPageEntered', { offeringId: this.selectedOffering.id });
    await this.fetchCreditCardMask();
    this.state = this.cardNumberMask ? PAYMENT_STATES.useExistingPaymentMethod : PAYMENT_STATES.enterNewCreditCard;
  },
  computed: {
    ...mapState('subscription', ['tokenizationError', 'enableTerms', 'cardNumberMask']),
    ...mapState('oneOffOfferings', ['selectedOffering']),
    ...mapGetters('oneOffOfferings', ['offeringsTypes', 'images', 'variant', 'configs']),
    ...mapGetters('featureFlags', ['showMislakaConsentScreen']),
    useExistingPaymentMethod() {
      return this.state === PAYMENT_STATES.useExistingPaymentMethod;
    },
    disableButton() {
      return this.configs.showTerms && !this.enableTerms;
    },
    enterNewCreditCard() {
      return this.state === PAYMENT_STATES.enterNewCreditCard;
    },
    afterPayment() {
      return this.state === PAYMENT_STATES.afterPayment;
    },
    buttonText() {
      return this.afterPayment ? 'בחזרה לתזרים' : 'אישור ותשלום';
    },
    pageHeaderProps() {
      return {
        backgroundColorVariant: this.variant,
        textColor: BaseUi.Colors.riseupWhite,
        sticky: true,
        showLogo: false,
        bottomBorder: true,
        rightActions: [{
          type: BaseUi.HeaderActions.BACK,
          onClick: this.onBack,
        }],
        leftActions: [{
          type: BaseUi.HeaderActions.CLOSE,
          onClick: this.close,
        }],
      };
    },
  },
  methods: {
    ...mapActions('subscription', ['reset', 'fetchCreditCardMask', 'createPaymentToken']),
    ...mapActions('oneOffOfferings', ['purchaseOneOffOffering', 'init']),
    ...mapActions('modalRootStore', ['closeModal', 'openModal']),
    ...mapActions('insights', ['markAsRead']),
    ...mapMutations('subscription', ['setTokenizationError']),
    ...mapMutations('oneOffOfferings', ['selectOffering']),
    async onBack() {
      if (this.enterNewCreditCard) {
        this.state = PAYMENT_STATES.useExistingPaymentMethod;
      } else {
        this.$emit('back');
      }
    },
    async onPopupClose() {
      this.reset();
      this.closeModal();
    },
    close() {
      this.reset();
      this.$emit('close');
    },
    onEnterCreditCard() {
      this.state = PAYMENT_STATES.enterNewCreditCard;
    },
    onTryWithAnotherCard() {
      this.onEnterCreditCard();
      this.onPopupClose();
    },
    async onSubmit() {
      if (this.state === PAYMENT_STATES.afterPayment) {
        await this.close();
        await router.push({ path: '/home' });
      } else if (!this.submitLoading) {
        this.submitLoading = true;
        Segment.trackUserInteraction('PaymentClicked', { offeringId: this.selectedOffering.id });
        await this.purchase();
        if (this.tokenizationError) {
          return;
        }
        Segment.trackUserGot('PaymentSucceed', { offeringId: this.selectedOffering.id });
        if (this.insight) {
          await this.markAsRead(this.insight);
        }
        // For now we open move customers to mislaka consent screen by feature flag.
        // In future we will move all customers
        // in advisory package offering we want to show the after payment page
        if (this.selectedOffering.id === this.offeringsTypes.ADVISORY) {
          router.push({ path: '/mislaka-consent' });
        }
      }
    },
    async purchase() {
      try {
        if (this.state === PAYMENT_STATES.useExistingPaymentMethod) {
          await this.purchaseOneOffOffering({ paymeToken: null });
        } else {
          const paymeToken = await this.createPaymentToken();
          await this.purchaseOneOffOffering({ paymeToken });
        }
        this.state = PAYMENT_STATES.afterPayment;
      } catch (error) {
        this.setTokenizationError(error);
      } finally {
        this.submitLoading = false;
      }
    },
  },
  watch: {
    tokenizationError(newValue, oldValue) {
      if (newValue) {
        this.openModal({
          component: FailedPaymentPopup,
          popupAlignment: 'center',
          props: {
            onClose: this.onPopupClose,
            onTryWithAnotherCard: this.onTryWithAnotherCard,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
  .payment {
    display: flex;
    flex-direction: column;
    height: 100%;

    .full-width {
      width: 100%;
    }
  }
</style>
