<template>
  <div class="saving-insight-popup">
    <insight-details-popup
      :cashflow-category="cashflowCategory"
      :insight="insight"
      :buttons="buttons"
      :showFeedbackFooter="false"
      :close-action="closeAction">

      <template v-slot:top-content>
        <div class="insight-header">
          <div>סיימת את {{ budgetMonth }} בתזרים חיובי של</div>
          <div class="amount">{{ formattedAmount }} <span class="nis">₪</span></div>
        </div>
      </template>

      <template v-slot:bottom-content>
        <div>
          <div class="insight-content">
            <div>
              <span class="emphasise green">יש!</span>
              זו הזדמנות להגדיל את המתנה שלך לעצמך ולהעביר כסף לקופת הגמל להשקעה שלך
              <span class="emphasise">במיטב.</span>
            </div>
            <div class="text-buttom">אפשר להעביר החל מ-100 ₪
              <span class="emphasise">וזה לוקח פחות מדקה.</span>
            </div>
          </div>
          <callout iconPath="icons/heart-icon.svg" title="טיפ מאיתנו" class="insight-callout">
            <template v-slot:text>
              רובנו נבזבז כסף שיושב סתם בעו״ש. לכן, כדאי להעביר לפחות חלק ממנו לחיסכון.
            </template>
          </callout>
        </div>
      </template>
      <template v-slot:footer>
        <div @click="dismissInsight" class="footer-btn">הבנתי, אל תציגו לי יותר</div>
      </template>
    </insight-details-popup>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DDLogs from '@/DDLogs';
import BaseUI from '@riseupil/base-ui';
import moment from 'moment';
import Segment from '@/Segment';
import moneyUtils from '@/utils/money';
import dateUtils from '@/utils/dates';
import DynamicDepositFlow from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/DynamicDepositFlow';
import InsightDetailsPopup from '../InsightDetailsPopup';

export default {
  name: 'MeitavOpportunisticSavingPopup',
  components: {
    InsightDetailsPopup,
    Callout: BaseUI.Callout,
  },
  props: {
    insight: { type: Object, require: true },
    cashflowCategory: { type: String, required: true },
    closeAction: { type: Function, required: true },
  },
  created() {
    Segment.trackUserGot('MeitavOpportunisticSavingPopupLoaded', this.insight);
  },

  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.proposedOpportunisticSavingAmount, 0);
    },
    budgetMonth() {
      return dateUtils.getHebrewMonth(moment(this.insight.budgetDate).subtract(1, 'month').format('YYYY-MM'));
    },
    buttons() {
      return [
        {
          text: 'להעביר לחיסכון!',
          variant: 'primary',
          action: () => {
            Segment.trackUserInteraction('MeitavOpportunisticSavingConfirmed', this.insight);
            this.setOpportunisticDeposit();
            this.closeModalAndGoToFlow();
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('insights', ['markAsRead']),
    ...mapMutations('dynamicSavings', ['setProposedSavingsAmount', 'setOpportunisticDeposit']),
    closeModalAndGoToFlow() {
      this.setProposedSavingsAmount(moneyUtils.extractRoundedIntegerPart(this.insight.proposedOpportunisticSavingAmount));
      this.closeModal();
      this.openModal({
        component: DynamicDepositFlow,
        popupAlignment: 'full-screen',
        props: { insight: this.insight },
      });
    },
    dismissInsight() {
      Segment.trackUserInteraction('MeitavOpportunisticSavingPopupDismissed', this.insight);
      this.closeModal();
      this.markAsRead(this.insight).catch(error => {
        DDLogs.error('Failed to mark insight as read', { insight: this.insight, error });
      });
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.saving-insight-popup {
  text-align: right;

  .insight-header {
    color: $riseup_white;
    font-size: 16px;
    text-align: right;

    .amount {
      font-size: 46px;
      font-weight: bold;
      margin-top: 10px;

      .nis {
        font-size: 32px;
      }
    }
  }

  .emphasise {
    font-weight: bold;
  }

  .green {
    color: $riseup_green;
  }

  .insight-content {
    text-align: right;
    font-size: 22px;
    line-height: 28px;

    .text-buttom {
      margin-top: 24px;
    }
  }

  .insight-callout {
    margin-top: 33px;
  }

  .footer-btn {
    margin-bottom: unset;
    margin-top: unset;
  }
}

</style>
