import { client } from '../http';

async function fetchBalances() {
  const response = await client.get('/api/current-balance');
  return response.data;
}

async function fetchCardDebts() {
  const response = await client.get('/api/current-credit-card-debt');
  return response.data;
}

export default {
  fetchBalances,
  fetchCardDebts,
};
