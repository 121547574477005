<template>
  <colored-popup
    :back-action="back"
    class="danger align-right"
  >
    <template v-slot:top-content>
      <div class="header">
        מה יקרה עכשיו?
      </div>
    </template>
    <template v-slot:bottom-content>

      <div class="description">
        <span class="bold">העסקאות ימשיכו להופיע בתזרים,<br/></span>
        <span>והמערכת לא תשאל שוב את השאלה הזאת.</span>
      </div>
      <ToolTip icon-path="/images/info-icon-blue.svg" class="tooltip">
        <span class="bold">לאיזה מקרה זה מומלץ?</span>
        <ul class="bullet-list">
          <li>חשבון הבנק כבר מחובר</li>
          <li>הכרטיס מקושר לחשבון בנק שאני לא רוצה לראות בתזרים</li>
        </ul>
      </ToolTip>
      <riseup-button :action="approveCard" :size="'slim'" :title="'הבנתי, זאת הכוונה שלי'" :variant="'secondary'"/>
    </template>
    <template v-slot:footer>
      <div class="footer-btn" @click="cancel">ביטול</div>
    </template>
  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import ToolTip from '@/base-components/onboarding/BaseToolTip.vue';
import { mapActions, mapState } from 'vuex';
import { answerSonQuestion } from '@/api/MergeInvestigatorApi';
import Segment from '@/Segment';

export default {
  name: 'IgnoreSonInvestigationModal',
  components: {
    ToolTip,
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    investigation: {
      type: Object,
      required: true,
    },
    insightId: {
      type: String,
    },
  },
  computed: {
    ...mapState('mergeInvestigationQuestions', ['cardInputTypes']),
  },
  methods: {
    ...mapActions('modalRootStore', ['closeAllModals', 'closeModal']),
    ...mapActions('insights', ['clearInsight']),
    async approveCard() {
      Segment.trackUserInteraction('SonsMergeInvestigationIgnoreCardAcceptClicked', { investigation: this.investigation });
      await answerSonQuestion({
        chunkId: this.investigation.chunkId,
        input: this.cardInputTypes.approvedAccount,
        accountNumberPiiId: this.investigation.accountNumberPiiId,
      });
      if (this.insightId) {
        await this.clearInsight({ insightId: this.insightId });
      }
      this.closeAllModals();
    },
    back() {
      Segment.trackUserInteraction('SonsMergeInvestigationIgnoreCardBackClicked', { investigation: this.investigation });
      this.closeModal();
    },
    cancel() {
      Segment.trackUserInteraction('SonsMergeInvestigationIgnoreCardCancelClicked', { investigation: this.investigation });
      this.closeAllModals();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.align-right {
  text-align: right;
}

.header {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: $riseup_white;
}

.description {
  font-size: 18px;
  line-height: 24px;
}

.tooltip {
  font-size: 14px;
  line-height: 18px;
  margin-top: 30px;
  margin-bottom: 35px;
}

.bullet-list {
  padding-inline-start: 20px;
}

.footer-btn {
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: $riseup_black;
  cursor: pointer;
}
</style>
