import moment from 'moment';

const LOCAL_STORAGE_KEY = 'riseup.suggestOfferingsLastShown';

const MIN_DAYS_SINCE_TRIAL = 7;

const state = {
  isShown: false,
};

const getters = {
  shouldShowOfferingsSuggestions: state => ({
    inTrial,
    inTrialSince,
  }) => inTrial
      && !state.isShown
      && (moment().diff(moment(inTrialSince), 'days') >= MIN_DAYS_SINCE_TRIAL),
};

const actions = {
  init({ commit }) {
    const isShown = !!localStorage.getItem(LOCAL_STORAGE_KEY);
    commit('setIsShown', isShown);
  },
  ctaDismissed({ commit }) {
    commit('setIsShown', true);
    localStorage.setItem(LOCAL_STORAGE_KEY, moment().toString());
  },

};

// mutations
const mutations = {
  setIsShown(state, isShown) {
    state.isShown = isShown;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
