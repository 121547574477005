import referralApi from '../../api/ReferralApi';
import config from '../../config';

const state = {
  token: null,
  referralLink: null,
};

const actions = {
  async init({ commit }, householdName) {
    const { elsaPublicUrl } = config.get();
    const { token } = await referralApi.generateReferralToken('1monthfree');
    commit('setToken', token);
    commit(
      'setReferralLink',
      `${elsaPublicUrl}/signup?refToken=${encodeURIComponent(state.token)}&hn=${encodeURIComponent(householdName)}&utm_source=app_referral`,
    );
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  setReferralLink(state, link) {
    state.referralLink = link;
  },
};

export default {
  namespaced: true,
  actions,
  state,
  mutations,
};
