import config from './config';
import DDLogs from './DDLogs';

let paymeInterface = null;

async function init() {
  await import('../public/scripts/hostedfields.js');

  if (window.PayMe) {
    const fieldsInstance = await window.PayMe.create(
      config.get().tokenizationKey,
      { testMode: config.get().tokenizationMode === 'true', language: 'he' },
    );
    const fields = await fieldsInstance.hostedFields();
    paymeInterface = { fieldsInstance, fields };
  } else {
    DDLogs.error('Failed to initialize Payme: global variable is undefined');
  }
}

const PaymeInterface = {
  async initOrGet() {
    if (!paymeInterface) {
      await init();
    }
    return paymeInterface;
  },

  async teardown() {
    if (paymeInterface) {
      paymeInterface.fieldsInstance.teardown();
      await init();
    }
    return paymeInterface;
  },
};
export default PaymeInterface;
