import _ from 'lodash';

const state = {
  loadingComponents: [],
};

const getters = {
  contentLoading: state => state.loadingComponents.length !== 0,
};

const actions = {
  startContentLoading({ commit }, componentName) {
    commit('addLoadingComponent', componentName);
  },
  doneContentLoading({ state, commit }, componentName) {
    commit('removeLoadingComponent', componentName);
  },
};

const mutations = {
  addLoadingComponent(state, componentName) {
    if (!_.includes(state.loadingComponents, componentName)) {
      state.loadingComponents.push(componentName);
    }
  },
  removeLoadingComponent(state, componentName) {
    const index = _.indexOf(state.loadingComponents, componentName);
    state.loadingComponents.splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
