<template>
  <div class="start-save-popup">
    <insight-details-popup
      :cashflow-category="cashflowCategory"
      :insight="insight"
      :showFeedbackFooter="false"
      :back-action="backAction">

      <template v-slot:top-content>
        <div class="header">
          <div>החיסכון הדינמי של RiseUp עם </div>
          <div class="title">קופת גמל להשקעה</div>
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="content">
          <component :is="step.component" v-bind="step.props" />
        </div>
      </template>
    </insight-details-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import InsightDetailsPopup from '../../InsightDetailsPopup';
import MonthlySavingPopup from '../MonthlySavingPopup';
import AboutKgl from './AboutKgl';
import AboutMeitav from './AboutMeitav';

const STEPS = {
  KglExplained: 0,
  MeitavExplained: 1,
};

export default {
  name: 'StartSave',
  components: {
    InsightDetailsPopup,
    AboutKgl,
    AboutMeitav,
  },
  props: {
    insight: { type: Object, require: true },
    cashflowCategory: { type: String, required: true },
  },
  data() {
    return { currentStep: STEPS.KglExplained };
  },
  computed: {
    kglExplained() {
      return this.currentStep === STEPS.KglExplained;
    },
    step() {
      if (this.kglExplained) {
        return {
          component: AboutKgl,
          props: {
            insight: this.insight,
            nextStep: this.nextAction,
          },
        };
      }
      return {
        component: AboutMeitav,
        props: {
          insight: this.insight,
        },
      };
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['closeModal', 'openModal']),

    backAction() {
      if (this.kglExplained) {
        this.closeModal();
        this.openModal({
          component: MonthlySavingPopup,
          props: {
            cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
            insight: this.insight,
          },
        });
        return;
      }
      this.currentStep = STEPS.KglExplained;
    },
    nextAction() {
      this.currentStep = STEPS.MeitavExplained;
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.start-save-popup {
  text-align: right;

  .header {
    color: $riseup_white;
    font-size: 16px;
    text-align: right;

    .title {
      font-size: 32px;
      font-weight: bold;
      margin-top: 10px;
    }
  }

  .content {
    text-align: right;
    font-size: 22px;
    line-height: 28px;
  }
}

</style>
