<template>
  <toaster @close-toaster="closeSummaryToaster">
    <template v-slot:text>
      <span v-if="oneTime">המערכת לא תעדכן את הצפי בחודשים הבאים</span>
      <span v-else>המערכת עדכנה את <span class="bold">הצפי של {{ balanceAmountString }}</span></span>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../../base-components/Toaster';

export default {
  name: 'BalanceToaster',
  components: {
    Toaster,
  },
  props: {
    isIncome: {
      type: Boolean,
      required: true,
    },
    oneTime: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    balanceAmountString() {
      return this.isIncome ? 'ההכנסה' : 'ההוצאה';
    },
  },
  methods: {
    closeSummaryToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
