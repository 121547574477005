<template>
  <div class="tool-tip">
    <div class="icon-wrapper">
      <img :src="iconPath">
    </div>
    <div class="text-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    iconPath: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .tool-tip {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    background-color: $riseup_light_blue;

    .icon-wrapper {
      padding-top: 4px;
      margin-left: 15px;
    }

    .text-wrapper {
      flex-grow: 1;
    }
  }
</style>
