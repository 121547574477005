<template>
  <div>
  <obk-description-partner-note-step step-number="1" />
  <open-banking-description-step step-number="2">
    <template v-slot:text>
      בסיום חיבור מוצלח בלאומי,
      <span class="text-bold">תועברו אוטומטית חזרה לרייזאפ.</span>
      אם יש בעיה,
      <span class="text-bold">מומלץ לחזור אלינו</span>
      לקבלת תמיכה.
    </template>
  </open-banking-description-step>
  </div>
</template>

<script>
import OpenBankingDescriptionStep from '@/base-components/onboarding/openBanking/OpenBankingDescriptionStep';
import ObkDescriptionPartnerNoteStep
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/ObkDescriptionPartnerNoteStep';

export default {
  name: 'OpenBankingIsracardDescription',
  components: { ObkDescriptionPartnerNoteStep, OpenBankingDescriptionStep },
};
</script>

<style scoped lang="scss">
.text-bold {
  font-weight: bold;
}
</style>
