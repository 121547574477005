<template>
  <navigation-menu-item
    v-if="showSavingZone"
    title="חיסכון לטווח הארוך"
    target="saving-zone"
    icon-name="forest"
    v-on:navigate="$emit('navigate')"/>
</template>

<script>
import CustomerBenefits from '@/mixins/CustomerBenefits';
import NavigationMenuItem from '@/base-components/app-header/NavigationMenuItem';

export default {
  name: 'SavingZoneMenuItem',
  components: {
    NavigationMenuItem,
  },
  mixins: [CustomerBenefits],
  data() {
    return {
      showSavingZone: true,
    };
  },
  created() {
    this.showSavingZone = !this.registeredUsingSpecialSource;
  },
};
</script>

<style scoped lang="scss">
</style>
