<template>
  <div :class="['section', {'bottom-separator': bottomSeparator}, {'remove-bottom-padding': !hasBottomPadding}]">
    <span class="title ri-bold-title" v-if="title">{{ title }}</span>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'paymentFormSection',
  props: {
    title: {
      type: String,
      required: false,
    },
    hasBottomPadding: {
      type: Boolean,
      default: true,
    },
    bottomSeparator: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/riseup-colors';

.section {
  padding: $ri-spacing-l $ri-spacing-ml;
  display: flex;
  flex-direction: column;
  gap: $ri-spacing-l;
  width: 100%;

  &.bottom-separator {
    border-bottom: 1px solid $riseup_gray_0;
  }

  &.remove-bottom-padding {
    padding-bottom: 0;
  }

  // Overriding the default style of the inputs
  ::v-deep .input-group {
    margin: 0;
  }

  ::v-deep .field {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: $ri-spacing-l;
    }
  }
}
</style>
