import { client } from '../http';

export default {
  async setCustomerActivity(activity) {
    return await client.post('/api/customer-activity', { activity });
  },
  async getLatestHomepageVisit() {
    return client.get('/api/customer-activity/latest?activityType=visitPage&pageName=Home').then(res => res.data);
  },
};
