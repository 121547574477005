<template>
  <insight :insight="insight"
           animation-name="Expense.json"
           :button-text="'לעדכון ופרטים נוספים'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>זיהינו הוצאה שונה מהרגיל של <span class="amount">{{ formattedAmount }} ₪.</span>
        היא נמצאת בהוצאות המשתנות של {{ budgetMonth }}</div>
    </template>
  </insight>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CashflowUpdaterApi from '@/api/CashflowUpdaterApi';
import cashflowViewConsts from '@/constants/cashflow-view';
import TransactionInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/TransactionInsightPopup';
import EventBus from '@/event-bus/event-bus';
import ExclusionToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/insights/ExclusionToaster';
import dateUtils from '@/utils/dates';
import moneyUtils from '@/utils/money';
import Insight from './components/Insight';

export default {
  name: 'HighExpenseInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate']),
    buttons() {
      return [
        {
          text: 'תשאירו אותה בתזרים',
          variant: 'secondary',
          action: async () => {
            await CashflowUpdaterApi.moveToVariables(this.insight.transactionId);
            await this.markAsRead(this.insight);
            this.openExclusionToaster('נשארה בתזרים');
            this.closePopup();
          },
        },
        {
          text: 'תוציאו אותה מחוץ לתזרים',
          variant: 'secondary',
          action: async () => {
            await this.markAsRead(this.insight);
            if (this.canDoOptimisticChange) {
              await this.moveVariableExpenseToExcluded({ transactionId: this.insight.transactionId });
            } else {
              await CashflowUpdaterApi.excludeTransaction({ transactionId: this.insight.transactionId });
            }
            this.openExclusionToaster('הוצאה מחוץ לתזרים');
            this.closePopup();
          },
        },
      ];
    },
    canDoOptimisticChange() {
      return this.insight.budgetDate === this.presentedBudgetDate;
    },
    budgetMonth() {
      return dateUtils.getShortHebrewMonth(this.insight.budgetDate);
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.billingAmount, 0);
    },
  },
  methods: {
    ...mapActions('editCashflow', ['moveVariableExpenseToExcluded']),
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    buttonAction() {
      this.openModal({
        component: TransactionInsightPopup,
        props: {
          insight: this.insight,
          insightPrefix: 'סכום ההוצאה',
          insightPopupTitle: `זיהינו הוצאה גבוהה מהרגיל. היא נמצאת בהוצאות המשתנות של ${this.budgetMonth}`,
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE,
          insightPopupExplanation: 'אם לא התכוונת להשתמש בהכנסות של החודש כדי לממן את ההוצאה, צריך להוציא אותה מהתזרים',
          buttons: this.buttons,
        },
      });
    },
    closePopup() {
      this.closeModal();
    },
    openExclusionToaster(action) {
      EventBus.$emit('open-toaster', {
        component: ExclusionToaster,
        props: {
          excludedAmount: 'expense',
          excludedAction: action,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}
</style>
