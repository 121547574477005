<template>
  <navigation-overlay class="nav-bar-root" :isOpen="isOpen" v-on:close="handleClose"/>
</template>

<script>
import NavigationOverlay from '../base-components/app-header/NavigationOverlay.vue';
import NavigationBus from '../event-bus/navigation-bus';
import Intercom from '../Intercom';

export default {
  name: 'NavBarRoot',
  components: {
    NavigationOverlay,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    NavigationBus.$on('open', this.openNavBar);
    document.addEventListener('keyup', this.handleKeyup);
  },
  beforeDestroy() {
    NavigationBus.$off('open', this.openNavBar);
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    openNavBar() {
      document.body.classList.add('modal-open');
      Intercom.hideLauncher();
      this.isOpen = true;
    },
    handleClose() {
      document.body.classList.remove('modal-open');
      Intercom.showLauncher();
      this.isOpen = false;
    },
    handleKeyup(e) {
      if (e.keyCode === 27) {
        this.handleClose();
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-z-index';

  .nav-bar-root {
    z-index: $navigation-side-menu;
  }

</style>
