<template>
  <sticky-cta-page :cta-props="{buttonProps: { title: 'הבנתי, אפשר להמשיך'}}" @click="onContinueClicked">
    <image-header-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="advisory-road-title margin-bottom-l">
          <div class="ri-large-body margin-bottom-s">התשלום עבר בהצלחה.</div>
          <div class="ri-small-display">
            <div>יצאנו לדרך!</div>
            <div>אלו שלבי התכנית:</div>
          </div>
        </div>
      </template>
      <div class="padding-ml">
        <checked-items :items="checkedItems" class="margin-bottom-xs" />
      </div>
    </image-header-page>
  </sticky-cta-page>
</template>

<script>
import { BACKGROUND_POSITION, COLOR_VARIANT } from '@riseupil/base-ui/src/utils/layout-consts';
import BaseUI from '@riseupil/base-ui';
import CheckedItems from '@/base-components/CheckedItems';
import Segment from '@/Segment';

export default {
  name: 'AdvisoryRoad',
  components: {
    CheckedItems,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  computed: {
    imageHeaderProps() {
      return {
        backgroundColor: COLOR_VARIANT.DARK_BLUE,
        backgroundPosition: BACKGROUND_POSITION.BOTTOM_LEFT,
        bottomSeparator: false,
        imgSrc: '/images/advisory/advisory-road.svg',
      };
    },
    checkedItems() {
      return [{ text: 'הרשמה לתוכנית ייעוץ', checked: true }, { text: 'מיפוי התמונה הכלכלית שלך' },
        { text: 'שאלון מטרות כלכליות' }, { text: 'פגישת ייעוץ ובניית תוכנית' }, { text: 'מעקב ועידכון' }];
    },
  },
  created() {
    Segment.trackUserGot('AdvisoryRoad_Entered');
  },
  methods: {
    onContinueClicked() {
      Segment.trackUserGot('AdvisoryRoad_ContinueClicked');
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>

.advisory-road-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: right;
  height: 100%;
  width: 100%;
}

</style>
