import { client } from '../http';

export async function getPlans() {
  const response = await client.get('/api/plans');
  return response.data;
}

export async function createPlan(plan) {
  const response = await client.post('/api/plans', plan);
  return response.data;
}

export async function addPlanTransaction(planTransaction) {
  const response = await client.post('/api/plan-transaction', planTransaction);
  return response.data;
}

export async function deletePlan(planId) {
  const response = await client.delete(`/api/plans/${planId}`);
  return response.data;
}

export async function updatePlan(planId, planMetadata) {
  const response = await client.put(`/api/plans/${planId}`, planMetadata);
  return response.data;
}

export async function changePrioritiesForPlans(prioritizedPlans) {
  const response = await client.post('/api/plans/priority', { prioritizedPlans });
  return response.data;
}

export async function pausePlan(planId) {
  const response = await client.post(`/api/plans/${planId}/pause`);
  return response.data;
}

export async function resumePlan(planId) {
  const response = await client.post(`/api/plans/${planId}/resume`);
  return response.data;
}

export async function completePlan(planId) {
  const response = await client.post(`/api/plans/${planId}/complete`);
  return response.data;
}

export async function calculatedMonthlyAmount(deadline, targetAmount) {
  const response = await client.post('/api/plans/calculated-monthly-amount', { deadline, targetAmount });
  return response.data;
}

export async function getSuggestions() {
  const response = await client.get('/api/plans/suggestions');
  return response.data;
}

export async function saveCustomerOnboardingPlansSelection(selection) {
  await client.post('/api/ob-plans-selection', selection);
}

export async function getEligibleForPlanAhead() {
  const response = await client.get('/api/eligible-for-plan-ahead');
  return response.data.eligible;
}

export async function addPlanRelatedTransactions(planId, relatedTransactionIds) {
  await client.put(`/api/plans/${planId}/relate-transactions`, { relatedTransactionIds });
}

export async function updatePlanTransaction(planId, transactionId, transactionMetadata) {
  const response = await client.put(`/api/plan-transaction/${planId}/${transactionId}`, transactionMetadata);
  return response.data;
}

export async function removePlanRelatedTransactions(planId, relatedTransactionIds) {
  await client.put(`/api/plans/${planId}/remove-transactions`, { relatedTransactionIds });
}

export async function calculateCurrentPlanNewMonthlyAmount(plan, newDeadline, newTargetAmount, riseupGoal) {
  const response = await client.post('/api/plans/calculate-plan-with-new-target', {
    plan, newDeadline, newTargetAmount, riseupGoal,
  });
  return response.data;
}
