<template>
  <insight :insight="insight"
           :image-name="'promote-annual.svg'"
           button-text="ספרו לי עוד"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        ההטבה לרייזאפ נגמרה, ו{{daysText}} יתעדכן המינוי למחיר רגיל.  <span class="ri-bold-title">אפשר להמשיך להנות מהנחה עם מינוי שנתי!</span>
      </div>
    </template>
  </insight>
</template>

<script>
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import Segment from '@/Segment';
import moment from 'moment';
import router from '@/router';

export default {
  name: 'PromoteAnnualInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    daysText() {
      const daysDiff = moment(this.insight.endOfPromoDate).diff(moment(), 'day');
      if (daysDiff <= 1) {
        return 'מחר';
      }
      if (daysDiff <= 2) {
        return 'בעוד יומיים';
      }
      return `בעוד ${daysDiff} ימים`;
    },
  },
  methods: {
    buttonAction() {
      Segment.trackUserInteraction('PromoteAnnualInsight_ButtonClicked');
      router.push({ path: '/subscription/update' });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
