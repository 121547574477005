<template>
  <div class="move-to-excluded-confirmation-popup">
    <popup :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:title>
        <span class="selected-category" :class="selectedCategory.cashflowCategory">{{ title }}</span>
      </template>
      <template v-slot:content>
        <div v-if="!isPlanAheadCategory" class="explanation">
          <div class="warning">זה אומר שה{{ incomeOrExpense }} הזאת <span class="bold">לא תופיע בחישוב התזרים של החודש!</span></div>
          <div v-if="isIncomeCategory">הכנסה לא תזרימית היא הכנסה שלא התכוונת לממן איתה את ההוצאות של החודש.</div>
          <div v-else>הוצאה לא תזרימית היא הוצאה שלא התכוונת לממן אותה מההכנסות של החודש.</div>
        </div>
        <div v-else class="explanation ri-body margin-bottom-sm">
          <div class="margin-bottom-sm">זה אומר שההוצאה הזאת תסווג כהוצאה לא תזרימית, ו<span class="bold">לא תופיע בחישוב של החודש.</span></div>
          <div>זאת הוצאה שחסכת אליה בחודשים הקודמים והיא ממומנת מכסף שקיים בעו״ש.</div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="cancel">
          <div class="delete-text" v-on:click="close">ביטול</div>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import SegmentTracker from '@/mixins/SegmentTracker';
import ChangeTransactionCategoryPopup from './ChangeTransactionCategoryPopup.vue';
import MoveToExcludedSummaryPopup from './MoveToExcludedSummaryPopup.vue';

export default {
  name: 'MoveToExcludedConfirmationPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  mixins: [SegmentTracker],
  created() {
    this.initTracker('MoveToExcludedConfirmationPopup_');
    this.track('Shown', this.logData);
  },
  computed: {
    ...mapState('editTransaction', ['transaction']),
    ...mapGetters('editTransaction', ['selectedCategory', 'categoryToVariant', 'isIncomeCategory', 'incomeOrExpense']),
    buttonOptions() {
      return [{
        title: this.isPlanAheadCategory ? 'כן, לשייך את ההוצאה' : 'כן, להוציא מהתזרים',
        color: this.isPlanAheadCategory ? 'blue' : 'red',
        action: () => this.onSave(),
      }];
    },
    isPlanAheadCategory() {
      return this.selectedCategory.planAheadCategory;
    },
    title() {
      return this.isPlanAheadCategory ? `לשייך את ההוצאה הזאת לתוכנית ״${this.selectedCategory.label}״?`
        : `להוציא את ה${this.incomeOrExpense} הזו מהתזרים של החודש?`;
    },
    logData() {
      return {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        isIncomeCategory: this.isIncomeCategory,
        transactionId: this.transaction.transactionId,
        isPlanAheadCategory: this.isPlanAheadCategory,
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    back() {
      this.track('BackClicked', this.logData);
      this.$emit('close');
      this.openModal({
        component: ChangeTransactionCategoryPopup,
        props: {},
      });
    },
    onSave() {
      this.track('AnswerClicked', this.logData);
      this.$emit('close');
      this.openModal({
        component: MoveToExcludedSummaryPopup,
        props: {},
      });
    },
    close() {
      this.track('CloseClicked', this.logData);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';

  .move-to-excluded-confirmation-popup {

    .explanation {
      white-space: pre-line;
      .warning {
        color: $riseup_danger_red;
        margin-bottom: 16px;
        .bold {
          font-weight: bold;
        }
      }
    }
    .cancel {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .delete-text {
        color: $riseup_black;
        font-size: 18px;
        font-weight: normal;
      }
    }
  }

</style>
