import _ from 'lodash';
import BalanceApi from '../../api/BalanceApi';

const state = {
  initialized: false,
  balances: [],
  cardDebts: [],
};

const getters = {
  hasBalance: state => !_.isEmpty(state.balances),
  hasCreditCardDebt: state => !_.isEmpty(state.cardDebts),
  totalOshBalance: state => _.chain(state.balances).uniqBy('accountNumberPiiValue').sumBy('balance'),
  totalCreditCardsDebt: state => _.chain(state.cardDebts).uniqBy('accountNumberPiiValue').sumBy('amount'),
};

const actions = {
  async initBalance({ commit, state }) {
    if (state.initialized) {
      return;
    }
    const [balances, cardDebts] = await Promise.all([
      BalanceApi.fetchBalances(),
      BalanceApi.fetchCardDebts(),
    ]);
    commit('setBalances', balances);
    commit('setCardDebts', cardDebts);
    commit('setInitialized', true);
  },
};

// mutations
const mutations = {
  setBalances(state, balances) {
    state.balances = balances;
  },
  setCardDebts(state, cardDebts) {
    state.cardDebts = cardDebts;
  },
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
