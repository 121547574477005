<template>
  <div class="step">
    <div class="dot">
      {{ number }}
    </div>
    <div class="step-title">{{ title }}</div>
    <div class="content">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'RollingCreditStepContent',
  props: {
    number: Number,
    title: String,
    text: String,
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.step {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;

  .step-title {
    font-weight: bold;
  }

  .icon {
    width: 16px;
  }

  .dot {
    height: 32px;
    width: 32px;
    vertical-align: middle;
    text-align: center;
    border-radius: 50%;
    background-color: $riseup_danger_light_red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    color: $expense_red1;
    font-weight: 700;
    padding-top: 3px;
  }
}

</style>
