<template>
  <full-screen-modal :close-action="close" class="plan-edit-page">
    <template v-slot:content>
      <div class="title-bar ri-bold-body">עריכה</div>
      <image-header-page :image-header-props="imageHeaderProps">
        <template v-slot:header>
          <div class="plan-image">
            <img :src="`/images/plan-ahead/plan-image/${image}.png`"/>
          </div>
        </template>

        <div class="content-container">
          <div class="padding-top-ml ri-title">שם התוכנית</div>
          <div class="name-input">
            <default-text-input
              label=""
              :value="planNameAndType.name"
              :error-message="errorMessage"
              @input="onManualInput" />
          </div>

          <div class="ri-title">היעד שלי</div>
          <div class="amount-input">
            <amount-input v-model="targetAmount" :error-message="amountErrorMsg" label="" :autofocus="false" />
          </div>

          <a v-if="enablePlanAheadAddExistingFund" class="clickable-title ri-body" @click="openPopup" tabindex="0" @keyup.enter="openPopup">
            יש לך כבר חלק מהכסף &gt;
          </a>

          <card class="plan-progress">
            <progress-bar
              :left-amount="targetAmount"
              left-label="היעד שלי"
              right-label="שמתי כבר בצד"
              :right-amount="savedAmountTillNow"
              cashflow-category="variable" />
          </card>

          <div class="date-title padding-top-l">למתי</div>
          <date-picker @select="setDeadline"/>

          <div class="margin-top-xl margin-bottom-l">
            <monthly-amount-container
                              :isLoading="loading"
                              :calculated-monthly-amount="calculatedMonthlyAmount"
                              :is-current-plan-on-track="isCurrentPlanOnTrack"
                              :totalRemaining="totalRemaining"
                              :negativeCashflowExperience="cashflowIsNegative"
                              @entered-viewport="monthlyAmountContainerEnteredViewport"/>

            <riseup-button class="button margin-bottom-sm"
                           :disabled="saveDisabled"
                           title="סיימתי" size="slim" variant="primary" :action="doneClicked" />
            <riseup-button class="button margin-bottom-l" :disabled="loading"
                           title="מחיקה" size="slim" variant="primary" color="red" :action="removePlan" />
          </div>
        </div>

      </image-header-page>
    </template>
  </full-screen-modal>
</template>

<script>
import _ from 'lodash';
import { calculateCurrentPlanNewMonthlyAmount } from '@/api/PlanAheadApi';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import dateUtils from '@/utils/dates';
import moneyUtils from '@/utils/money';
import FullScreenModal from '@/base-components/FullScreenModal';
import { mapActions, mapGetters, mapState } from 'vuex';
import PlanDetailsPage from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanDetailsPage';
import EventBus from '@/event-bus/event-bus';
import ProgressBar
  // eslint-disable-next-line max-len
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/ProgressBar';
import PlanExistingFundPopup from './PlanExistingFundPopup.vue';
import EditPlanToaster from './PlanEditToaster';
import PlansIconsMap, { PlanIconDefaultColor } from '../plans-constanst';
import DatePicker from '../DatePicker';
import MonthlyAmountContainer from '../MonthlyAmountContainer';
import DeletePlanPopup from '../DeletePlanPopup';

export default {
  name: 'EditPlanFlow',
  components: {
    FullScreenModal,
    DefaultTextInput: BaseUI.DefaultTextInput,
    DatePicker,
    AmountInput: BaseUI.AmountInput,
    MonthlyAmountContainer,
    RiseupButton: BaseUI.RiseupButton,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    Card: BaseUI.Card,
    ProgressBar,
  },
  data() {
    return {
      planNameAndType: { name: '', type: undefined },
      errorMessage: '',
      targetAmount: null,
      deadline: null,
      loading: false,
      debounceMonthlyCalcFetch: null,
      enteredViewport: false,
      isCurrentPlanOnTrack: true,
      calculatedMonthlyAmount: this.plan.monthlyTarget,
      totalRemaining: '0',
    };
  },
  created() {
    Segment.trackUserGot('PlanAhead_Flow_Shown', { isEditMode: true });
    this.updateCurrentPlan(this.plan);
    this.setPlanNameAndType({ name: this.currentPlan.name, type: this.currentPlan.type });
    this.targetAmount = this.currentPlan.targetAmount;
    this.setDeadline(this.currentPlan.deadline);
    this.debounceMonthlyCalcFetch = _.debounce(async function debounceMonthlyCalc(newVal) {
      if (newVal) {
        await this.handleMonthlyCalculation();
      }
    }, 500);
  },
  props: {
    plan: {
      type: Object,
      require: false,
    },
    onDone: {
      type: Function,
      require: true,
    },
    closeOnShowPrevious: {
      type: Boolean,
      default: false,
    },
    isNewPlan: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('planAhead', ['currentPlan']),
    ...mapGetters('cashflowView', ['riseupGoal', 'predictedBalanceAtEndOfMonth']),
    ...mapGetters('featureFlags', ['enablePlanAheadAddExistingFund']),

    imageHeaderProps() {
      return {
        backgroundColor: this.color,
        imgHeight: 140,
        bottomSeparator: false,
      };
    },
    image() {
      return PlansIconsMap[this.planNameAndType.type] ? PlansIconsMap[this.planNameAndType.type].icon : 'general';
    },
    color() {
      return PlansIconsMap[this.planNameAndType.type] ? PlansIconsMap[this.planNameAndType.type].color : PlanIconDefaultColor;
    },
    cashflowIsNegative() {
      return this.predictedBalanceAtEndOfMonth < 0;
    },
    sumNonCustomerPlanTransactions() {
      return _.sumBy(this.currentPlan.planTransactions?.filter(transaction => transaction.creator !== 'customer'), 'amount');
    },
    customerPlanTransaction() {
      return this.currentPlan.planTransactions?.find(transaction => transaction.creator === 'customer');
    },
    savedAmountTillNow() {
      if (this.currentPlan.savedAmountObj) {
        return this.sumNonCustomerPlanTransactions + this.currentPlan.savedAmountObj.amount;
      }
      return this.currentPlan.currentAmount;
    },
    originalInitialAmountOrUpdatedInitialAmount() {
      // If the customer set a new value - we want to address her new value
      if (_.isNumber(this.currentPlan?.savedAmountObj?.amount)) {
        return this.currentPlan.savedAmountObj.amount;
      }
      // Otherwise, take the value from the current customer plan transaction
      if (this.customerPlanTransaction?.amount) {
        return this.customerPlanTransaction.amount;
      }
      return 0;
    },
    amountErrorMsg() {
      if (this.originalInitialAmountOrUpdatedInitialAmount + this.sumNonCustomerPlanTransactions > this.targetAmount) {
        return 'אי אפשר לקבוע סכום שהוא פחות ממה שכבר שמת בצד';
      }
      return undefined;
    },
    saveDisabled() {
      return !this.planNameAndType.name
          || !this.targetAmount
          || !this.deadline
          || this.loading
          || !!this.amountErrorMsg;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('planAhead', ['updateCurrentPlan', 'resetCurrentPlan']),
    onManualInput(newValue) {
      this.setPlanNameAndType({ name: newValue, type: 'general' });
    },
    setPlanNameAndType(newValue) {
      this.errorMessage = '';
      this.planNameAndType = newValue;
    },
    openPopup() {
      Segment.trackUserInteraction('AddAmountToPlan_Clicked');
      this.openModal({
        component: PlanExistingFundPopup,
        showLastModal: true,
        props: { handleMonthlyCalculation: this.handleMonthlyCalculation, maxAmount: this.targetAmount },
      });
    },
    async setDeadline(value) {
      this.deadline = dateUtils.formatCashflowDate(value);
      await this.handleMonthlyCalculation();
    },
    async monthlyAmountContainerEnteredViewport() {
      this.enteredViewport = true;
      await this.handleMonthlyCalculation();
    },
    async handleMonthlyCalculation() {
      if (this.deadline && this.targetAmount && this.enteredViewport) {
        this.loading = true;
        await this.setCalculatedMonthlyAmount();
      }
    },
    async setCalculatedMonthlyAmount() {
      const {
        onTrack, targetAmount, deadline, totalRemaining, monthlyTarget,
      } = await calculateCurrentPlanNewMonthlyAmount(this.currentPlan, this.deadline, this.targetAmount, this.riseupGoal);
      this.totalRemaining = moneyUtils.formatAmountWithFraction(totalRemaining, 0);
      this.isCurrentPlanOnTrack = onTrack;
      this.targetAmount = targetAmount;
      this.deadline = deadline;
      this.calculatedMonthlyAmount = monthlyTarget;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      Segment.trackUserInteraction('PlanAhead_Flow_EditPage_OnCalculationChanged', { isAble: this.isCurrentPlanOnTrack });
    },
    async doneClicked() {
      Segment.trackUserInteraction('PlanAhead_Flow_EditPage_DoneClicked', {
        isAble: this.isCurrentPlanOnTrack,
        planId: this.currentPlan._id,
      });
      await this.updateCurrentPlan({ ...this.planNameAndType, targetAmount: this.targetAmount, deadline: this.deadline });
      await this.onDone();
      this.resetCurrentPlan();
      this.closeModal();
      this.openToaster();
    },
    async close() {
      Segment.trackUserInteraction('PlanAhead_Flow_Close');
      this.currentPlan.savedAmountObj = undefined;
      await this.closeModal();
      await this.openDetailsPageModal();
    },
    openDetailsPageModal() {
      this.openModal({
        component: PlanDetailsPage,
        props: {
          plan: this.currentPlan,
        },
        popupAlignment: 'full-screen',
      });
    },
    openToaster() {
      EventBus.$emit('open-toaster', {
        component: EditPlanToaster,
        props: {
          name: this.planNameAndType.name,
        },
      });
    },
    async removePlan() {
      Segment.trackUserInteraction('PlanAhead_PlanPage_Delete_Clicked');
      await this.openModal({
        component: DeletePlanPopup,
        props: {
          onPlanDeleted: () => this.closeModal(),
          plan: this.plan,
        },
      });
    },
  },
  watch: {
    targetAmount(newVal) {
      if (this.deadline) {
        this.loading = true;
      }
      this.debounceMonthlyCalcFetch(newVal);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.plan-edit-page {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;

  .title-bar {
    width: 100%;
    padding: 12px 24px;
    text-align: start;
  }

  .plan-image {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
      height: 100px;
    }
  }

  .content-container {
    flex: 1;
    margin: 0 24px;
    height: 100%;
    justify-content: space-between;
    text-align: start;

    .name-input {
      margin-top: -13px;
    }

    .amount-input {
      margin-top: -13px;
    }

    .date-title {
      font-size: 20px;
      line-height: 28px;
      padding-bottom: 8px;
    }

    .plan-progress {
      margin-top: 22px;
      padding: 12px 0 24px 0;
      width: auto;
    }

    .clickable-title {
      color: $riseup_blue;
      padding-bottom: 0;
      cursor: pointer;
    }
  }
}
</style>
