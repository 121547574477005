<template>
  <div class="unknown-error-page">
    <alert-page :img="require('@/assets/generic-faliure.png')"
                :title="'משהו לא עובד כרגע...'"
                :showButton="false">
      <template v-slot:content>
          אפשר לנסות לרענן את העמוד או לחזור שוב מאוחר יותר.
      </template>
    </alert-page>
  </div>
</template>

<script>

import Segment from '../../Segment';
import AlertPage from '../../base-components/AlertPage.vue';
import MetricApi from '../../api/MetricsApi';

export default {
  name: 'ServerError',
  components: {
    AlertPage,
  },
  async created() {
    window.scrollTo(0, 0);
    Segment.trackUserGot('ServerErrorEntered');
    MetricApi.sendCounter('frontend.page.server-error.hits');
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .unknown-error-page {
  }
</style>
