<template>
  <insight :insight="insight"
           :animation-name="'riseup-goal.json'"
           button-text="לבחירת יעד"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>החודש התחיל, כדאי להחליט מעכשיו איך היית רוצה לסיים את החודש, <span class="bold">ולבחור את היעד החודשי שלך.</span></div>
    </template>
  </insight>
</template>

<script>
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import EventBus from '@/event-bus/event-bus';
import RiseupGoalUpdatedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/RiseupGoalUpdatedToaster';
import EditMonthlyGoalAmount
  from '../cashflow-view/current-month/components/cashflow-edit/edit-riseup-goal/EditMonthlyGoalAmount';

export default {
  name: 'SetMonthlyGoalInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('SetMonthlyGoalInsight_Shown');
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal']),
    async buttonAction() {
      this.openModal({
        component: EditMonthlyGoalAmount,
        props: {
          onSaveCb: this.onSaveRiseUpGoal,
        },
      });
    },
    onSaveRiseUpGoal() {
      Segment.trackUserInteraction('SetMonthlyGoalInsight_SavedGoal');
      this.markAsRead(this.insight);
      EventBus.$emit('open-toaster', {
        component: RiseupGoalUpdatedToaster,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.bold {
  font-weight: bold;
}

</style>
