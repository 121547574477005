<template>
  <riseup-button
    class="radio-riseup-button ri-body" :class="{ 'with-border': !isSelected }"
    :action="select"
    title=""
    :bold="false"
    :color="isSelected ? 'blue' : 'black'"
    :variant="isSelected ? 'primary' : 'secondary'">
    <slot></slot>
  </riseup-button>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'SelectableRiseupButton',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      selected: this.initialValue ?? null,
    };
  },
  props: {
    isSelected: {
      type: Boolean,
      required: true,
    },
    onSelect: {
      type: Function,
      required: true,
    },
  },
  methods: {
    select() {
      this.onSelect();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.radio-riseup-button {
  &.with-border {
   border: 1px solid $riseup_black;
  }
}
</style>
