import { client } from '../http';

export default {
  async fetchSubscriptionState() {
    const response = await client.get('/api/subscription-state');
    return response.data;
  },

  async fetchMoneyBackGuaranteeRecord() {
    const response = await client.get('/api/subscription-state/money-back/enriched');
    return response.data;
  },

  async fetchFirstBillingDate() {
    const response = await client.get('/api/subscription-state/first-billing-date');
    return response.data;
  },

  async simplifiedSubscriptionState() {
    const response = await client.get('/api/partial-auth/subscription-state-simplified');
    return response.data;
  },
};
