<template>
  <colored-popup class="primary"
                 :close-action="closeModal">
    <template v-slot:top-content>
      <div class="insight-header text-align-right ri-medium-bold-headline">
        כדי להגיע ליעד, אפשר לעדכן את הסכום החודשי לחודשים הבאים, בתפריט העריכה של התוכנית
      </div>
    </template>
    <template v-slot:bottom-content>
      <div class="ri-large-body text-align-right padding-bottom-ml">
        אם הסכום החודשי החדש לא יתאים לך, אפשר תמיד לשנות את היעד או את המועד כדי לעמוד בו.
      </div>
      <div class="ri-large-body text-align-right padding-bottom-ml">
        התוכניות שצריך לעדכן:
      </div>
      <div class="ri-large-body text-align-right">
        <div v-for="plan in plans" :key="plan._id" class="plan-row padding-bottom-ml">
          <plan-icon :plan-type="plan.type" :size="30"/>
          <div class="padding-horizontal-s ri-bold-title">{{ plan.name }}</div>
        </div>
      </div>
      <riseup-button    class="margin-bottom-medium margin-top-sm"
                        title="לעדכון התוכניות שלי"
                        :action="recalculatePlans"
                        size="slim"
                        :bold="true"/>
    </template>
    <template v-slot:footer>
      <div class="footer-button" @click="closePopup">הבנתי, תודה</div>
    </template>
  </colored-popup>
</template>

<script>
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import router from '@/router';
import EditPlanFlow from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/plans-flow/EditPlanFlow';
import PlanIcon from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanIcon';

export default {
  name: 'RecalculatePlansPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    PlanIcon,
  },
  props: {
    plans: { type: Array, require: true },
    closeAction: { type: Function, required: true },
  },
  created() {
    Segment.trackUserGot('RecalculatePlansPopupLoaded');
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('planAhead', ['updatePlan', 'updateCurrentPlan', 'resetCurrentPlan']),
    async recalculatePlans() {
      this.closeAction();
      if (this.plans.length === 1) {
        Segment.trackUserInteraction('RecalculatePlansPopup_GoToEditPlan');
        const plan = this.plans[0];
        this.openModal({
          component: EditPlanFlow,
          props: {
            onDone: this.editPlan,
            plan,
          },
          popupAlignment: 'full-screen',
        });
      } else {
        this.goToPlanAheadPage();
      }
    },
    async editPlan() {
      await this.updatePlan();
    },
    goToPlanAheadPage() {
      Segment.trackUserInteraction('RecalculatePlansPopup_GoToPlansDashboard');
      router.push({ path: '/plan-ahead' });
    },
    async closePopup() {
      Segment.trackUserGot('RecalculatePlansPopup_CloseClicked');
      await this.resetCurrentPlan();
      this.closeAction();
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .insight-header {
    color: $riseup_white;
  }

  .text-align-right {
    text-align: right;
  }

  .plan-row {
    display: flex;
  }
</style>
