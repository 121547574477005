export const TRIAL_PERIODS = {
  BEGINNING: 'beginning',
  MIDDLE: 'middle',
  FINAL: 'final',
};

export const SUBSCRIPTION_STATES = {
  TRIAL: 'trial',
  FREE: 'free',
  SUBSCRIBED: 'subscribed',
  DORMANT_AFTER_CANCELED: 'dormant-after-canceled',
  DORMANT_AFTER_TRIAL: 'dormant-after-trial',
  CANCELED: 'canceled',
  PRE_TRIAL: 'pre-trial',
};

export const SUBSCRIPTION_TYPES = {
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};
