<template>
  <insight :button-action="buttonAction"
           :image-name="'cashflow-start-day-10.svg'"
           :insight="insight"
           button-text="לפרטים נוספים">
    <template v-slot:insight-text>
      <span>המערכת זיהתה שרוב החיובים שלך יורדים ב־10 בחודש. <span class="bold">ידעת שאפשר להתחיל את התזרים ב־10 בחודש?</span></span>
    </template>
  </insight>
</template>

<script>
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import Segment from '@/Segment';
import { mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'CashflowStartDayInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserGot('AdvisoryInsight', { variant: this.insight.variant });
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    async buttonAction() {
      Segment.trackUserInteraction('CashflowStartDayInsight_buttonClicked');
      router.push({ name: 'CashflowStartDaySettings' });
      await this.markAsRead(this.insight);
    },
  },
};
</script>

<style scoped>

</style>
