import Segment from '@/Segment';

const state = {
  pageViewEventName: 'IntroductionFromOtherProducts_viewPage',
  skipEventName: 'IntroductionFromOtherProducts_skipClick',
  nextPageClickEventName: 'IntroductionFromOtherProducts_nextPageClick',
  loginPath: 'login?from=other-products',
};

const getters = {
  pageViewEventName: state => state.pageViewEventName,
  skipEventName: state => state.skipEventName,
  loginPath: state => state.loginPath,
};

const actions = {
  trackView({ state }, pageName) {
    Segment.trackUserGot(state.pageViewEventName, { pageName });
  },
  trackSkip({ state }, pageName) {
    Segment.trackUserInteraction(state.skipEventName, { pageName });
  },
  trackNextClick({ state }, fromPageName, toPageName) {
    Segment.trackUserInteraction(state.nextPageClickEventName, { fromPageName, toPageName });
  },
};

const mutations = {};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
