import ChallengeApi from '@/api/ChallengeApi';

const state = {
  initialized: false,
  activeChallenge: null,
};

const actions = {
  async fetchActiveChallenge({ commit, state }) {
    if (state.initialized) {
      return;
    }
    const activeChallenge = await ChallengeApi.fetchActiveChallenge();
    if (activeChallenge && activeChallenge.extraParams && activeChallenge.extraParams.categoryName) {
      commit('setActiveChallenge', activeChallenge.extraParams.categoryName);
    }
    commit('setInitialized', true);
  },
};

const mutations = {
  setActiveChallenge(state, activeChallenge) {
    state.activeChallenge = activeChallenge;
  },
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
