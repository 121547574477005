<template>
  <div class="dd-error-page">
    <div class="logo">
      <img :src="require('@/assets/generic-faliure.png')"/></div>
    <div class="content">
      <div class="header">יש בעיה...</div>
      <div class="text">
        <div>נתקלנו בבעיה בשליחת הבקשה למיטב.</div>
        <div>אל דאגה, אנחנו על זה. ניצור איתך קשר</div>
        <div>בהקדם כדי להשלים את התהליך.</div>
      </div>
      <riseup-button size="slim" :title="'חזרה לתזרים'" :action="closeAction" />
    </div>
  </div>
</template>

<script>

import Segment from '@/Segment';
import { mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import DDLogs from '@/DDLogs';

export default {
  name: 'DDErrorPage',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
  },
  created() {
    Segment.trackUserInteraction('DynamicDeposit_ErrorPage_Entered');
    if (this.insight) {
      this.markAsRead(this.insight).catch(error => {
        DDLogs.error('Failed to mark insight as read', { insight: this.insight, error });
      });
    }
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    ...mapActions('insights', ['markAsRead']),
    closeAction() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.dd-error-page {
  margin: 0px 30px;
  .logo {
    margin: 35px;
    img {
      width: 280px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .header {
      font-weight: bold;
      font-size: 26px;
      line-height: 26px;
      margin-bottom: 16px;
    }

    .text {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 36px;
    }
  }
}

</style>
