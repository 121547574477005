<template>
  <div class="move-to-excluded-summary-popup">
    <popup :title="`ה${incomeOrExpense} תעבור`"
           :buttons="buttonOptions"
           :back-action="back">
      <template v-slot:content>
        <change-category-path :old-category="originalCategory" :new-category="selectedCategory" />
        <transaction-details :transaction="transaction" />
      </template>
    </popup>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import { addPlanRelatedTransactions } from '@/api/PlanAheadApi';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';
import SegmentTracker from '@/mixins/SegmentTracker';
import Segment from '@/Segment';
import MoveToExcludedConfirmationPopup from './MoveToExcludedConfirmationPopup.vue';
import TransactionDetails from './TransactionDetails.vue';
import ChangeCategoryPath from './ChangeCategoryPath.vue';
import TransactionMovedToaster from '../../../../../toasters/TransactionMovedToaster';

export default {
  name: 'MoveToExcludedSummaryPopup',
  components: {
    Popup: BaseUI.Popup,
    TransactionDetails,
    ChangeCategoryPath,
  },
  mixins: [SegmentTracker],
  created() {
    this.initTracker('MoveToExcludedSummaryPopup_');
    this.track('Shown', this.logData);
  },
  computed: {
    ...mapState('editTransaction', ['transaction', 'categoryRuleType']),
    ...mapGetters('editTransaction', ['selectedCategory', 'originalCategory',
      'categoryToVariant', 'isIncomeCategory', 'incomeOrExpense', 'ENRICHMENT_INPUT_TYPES']),
    buttonOptions() {
      return [{
        title: 'אישור',
        size: 'slim',
        action: this.onSave,
        variant: 'primary',
      }];
    },
    logData() {
      return {
        businessName: this.transaction.businessName,
        expenseCategory: this.transaction.expense,
        isIncomeCategory: this.isIncomeCategory,
        transactionId: this.transaction.transactionId,
        isPlanAheadCategory: this.selectedCategory.planAheadCategory,
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('editCashflow', ['moveVariableIncomeToExcluded', 'moveVariableExpenseToExcluded', 'moveTransaction', 'excludeExpense']),
    ...mapMutations('editTransaction', ['setCategoryRuleType']),
    ...mapActions('planAhead', ['addTransactionToPlan']),
    back() {
      this.track('BackClicked', this.logData);
      this.$emit('close');
      this.openModal({
        component: MoveToExcludedConfirmationPopup,
        props: {},
      });
    },
    async onSave() {
      this.setCategoryRuleType(this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID);
      this.track('ConfirmClicked', this.logData);
      this.$emit('close');
      if (this.isIncomeCategory) {
        await this.moveVariableIncomeToExcluded({
          transactionId: this.transaction.transactionId,
        });
      } else {
        const popEnvelope = this.originalCategory.cashflowCategory === cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED;
        if (this.selectedCategory.planAheadCategory) {
          const planId = this.selectedCategory.plan._id;
          const { transactionId } = this.transaction;
          Segment.trackUserGot('AddTransactionToPlan', { planId, transactionId });
          cashflowNormalizer.addRelatedTransaction(planId, transactionId);
          this.addTransactionToPlan({ planId, transactionId });
        }
        await Promise.all(_.compact([
          this.excludeExpense({ transactionId: this.transaction.transactionId, popEnvelope }),
          this.selectedCategory.planAheadCategory && addPlanRelatedTransactions(this.selectedCategory.plan._id, [this.transaction.transactionId]),
        ]));
      }
      EventBus.$emit('open-toaster', {
        component: TransactionMovedToaster,
        props: {
          changedTransaction: this.transaction,
          targetCategory: this.selectedCategory,
        },
      });
    },
  },
};
</script>

<style lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../../../../../../scss/category-color-mixins';

  .move-to-excluded-summary-popup {

    .change-category-path {
      margin-bottom: 40px;
    }

    .transaction-details {
      margin-bottom: 24px;
    }

    .explanation {
      font-weight: bold;
      line-height: 16px;

      @include category-color;
    }
  }

</style>
