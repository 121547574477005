<template>
  <div class="loading-popup-content">
    <lottie-player src="/animations/new-brand-loader.json" autoplay loop class="margin-vertical-l"
                   preserveAspectRatio="xMidYMax slice"/>
    <div class="description">
      <div class="title">{{ title }}</div>
      <div class="subtitle">{{ subtitle }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoadingPopup',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.loading-popup-content {
  width: 100%;

  lottie-player {
    text-align: center;
  }

  .description {
    color: $riseup_black;
    width: 100%;
    text-align: center;
    margin-bottom: 36px;

    .title {
      line-height: 1.33;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      font-family: SimplerPro;
    }
    .subtitle {
      line-height: 1.33;
      font-size: 18px;
      font-family: SimplerPro;
    }
  }
}

</style>
