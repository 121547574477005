<script>
import cashflowViewConsts from '@/constants/cashflow-view';
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'MoveVariablesMixin',
  ...mapActions('editCashflow', ['moveTransaction']),
  methods: {
    async moveExistingTransactionsFromVariablesToCategory({ categoryName, trackingCategoryId }) {
      const { variableTransactions } = this.cashflow;
      const transaction = _.find(variableTransactions, t => t.expense === categoryName);
      if (transaction) {
        // if found relevant transaction in variables, move all transactions by expense in reactive-cashflow
        await this.moveTransaction({
          originalCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE,
          selectedCategory: {
            cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING,
            categoryId: trackingCategoryId,
          },
          transaction,
          categoryRuleType: 'expense',
          valueForPredicate: categoryName,
        });
      }
    },
  },
};
</script>
