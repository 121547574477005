<template>
  <colored-popup
    :back-action="back"
    class="danger align-right"
  >
    <template v-slot:top-content>
      <div class="header">
        מעולה, תודה על התשובה. אז מה קורה עכשיו?
      </div>
    </template>
    <template v-slot:bottom-content>
      <div class="description">
        <span>נראה שיש עיכוב בירידת חיוב בחשבון הבנק.</span>
        <span class="bold">ברגע שהחיובים יירדו התזרים יתעדכן.</span>
      </div>
      <ToolTip icon-path="/images/info-icon-blue.svg" class="tooltip">
        <span class="bold">למה קיבלתי את השאלה הזאת?</span>
        <span>לפעמים יש פער בין הבנק לחברת האשראי, אולי בגלל ניוד החשבון, שינוי מועד החיוב, ועוד.</span>
      </ToolTip>
      <riseup-button :action="accept" :size="'slim'" :title="'הבנתי, תודה'" :variant="'secondary'"/>
    </template>
  </colored-popup>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';
import ToolTip from '@/base-components/onboarding/BaseToolTip.vue';
import { answerSonQuestion } from '@/api/MergeInvestigatorApi';
import Segment from '@/Segment';

export default {
  name: 'BankAlreadyConnectedSonInvestigationModal',

  components: {
    ToolTip,
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    investigation: {
      type: Object,
      required: true,
    },
    insightId: {
      type: String,
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeAllModals', 'closeModal']),
    ...mapActions('mergeInvestigationQuestions', ['setSonQuestionInput']),
    ...mapActions('insights', ['clearInsight']),
    async accept() {
      Segment.trackUserInteraction('SonsMergeInvestigationBankAlreadyConnectedAcceptClicked', { investigation: this.investigation });
      await answerSonQuestion({
        chunkId: this.investigation.chunkId,
        input: this.cardInputTypes.approvedAccount,
        accountNumberPiiId: this.investigation.accountNumberPiiId,
      });
      if (this.insightId) {
        await this.clearInsight({ insightId: this.insightId });
      }
      this.closeAllModals();
    },
    back() {
      Segment.trackUserInteraction('SonsMergeInvestigationBankAlreadyConnectedBackClicked', { investigation: this.investigation });
      this.closeModal();
    },
  },
  computed: {
    ...mapState('mergeInvestigationQuestions', ['cardInputTypes']),
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.header {
  font-size: 22px;
  font-weight: bold;
  line-height: 28px;
  color: $riseup_white;
}

.align-right {
  text-align: right;
}

.description {
  font-size: 18px;
  line-height: 24px;
}

.tooltip {
  font-size: 14px;
  line-height: 18px;
  margin-top: 30px;
  margin-bottom: 35px;
}

</style>
