const VALUE_PROP_NAMES = {
  CONTROL: 'control',
  ONE_NUMBER: 'oneNumber',
  FINANCIAL_ADVICE: 'financialAdvice',
  CASHFLOW_GOAL: 'cashflowGoal',
  SAVING_WITH_RISEUP: 'savingWithRiseup',
};

const VALUE_PROPS = [
  {
    name: VALUE_PROP_NAMES.CASHFLOW_GOAL,
    animation: 'jumping-graph.json',
    backgroundColor: 'dark-green',
    title: 'עם riseup אפשר',
    subtitle: 'riseup מאפשר לך לראות צמיחה חיובית וחיסכון תוך 3 חודשים או פחות!',
  },
  {
    name: VALUE_PROP_NAMES.CONTROL,
    animation: 'seesaw.json',
    backgroundColor: 'light-blue',
    title: 'אפשר לעשות סדר במצב הכלכלי וליהנות משקט נפשי',
    subtitle: 'riseup מרכז את כל ההכנסות וההוצאות במקום אחד, ונותן לך תמונת מצב חודשית באופן אוטומטי ופשוט.',
  },
  {
    name: VALUE_PROP_NAMES.ONE_NUMBER,
    animation: 'one-number.json',
    backgroundColor: 'green',
    title: 'עם התזרים של riseup אפשר לדעת כמה כסף נשאר להוציא',
    subtitle: 'תזרים riseup יודע כבר בתחילת החודש כמה כסף ייכנס, כמה עומד לצאת ומציג לך מספר אחד שמראה כמה נשאר להוציא.',
  },
  {
    name: VALUE_PROP_NAMES.FINANCIAL_ADVICE,
    imageName: 'financial_advice_faces@2x.png',
    animation: 'financial-advice.json',
    title: 'אפשר להתייעץ בכל רגע שצריך',
    subtitle: 'הצוות שלנו מלווה אותך בתהליך הצמיחה הכלכלית שלך וזמין בשבילך בכל התלבטות כלכלית, קטנה או גדולה.',
  },
  {
    name: VALUE_PROP_NAMES.SAVING_WITH_RISEUP,
    animation: 'saving-with-riseup.json',
    title: 'פשוט יותר לחסוך עם רייזאפ',
    subtitle: 'נעדכן אותך כל חודש כמה כסף אפשר להעביר לחיסכון דרכנו בקליק.',
  },
];

const VALUE_PROPS_ORDER = [
  VALUE_PROP_NAMES.CASHFLOW_GOAL,
  VALUE_PROP_NAMES.CONTROL,
  VALUE_PROP_NAMES.ONE_NUMBER,
  VALUE_PROP_NAMES.FINANCIAL_ADVICE,
  VALUE_PROP_NAMES.SAVING_WITH_RISEUP,
];

export default {
  VALUE_PROPS,
  VALUE_PROP_NAMES,
  VALUE_PROPS_ORDER,
};
