<template>
  <div class="expandable-connected-account-list">
    <div class="title-with-expand-collapse" v-on:click="toggleExpand">
      <div class="title">{{ title }}</div>
      <div v-if="!alwaysExpand" class="image-container">
        <expand-arrow :expanded="isExpanded"/>
      </div>
    </div>
    <collapse-transition>
      <div v-if="accounts && isExpanded" class="connected-accounts">
        <div v-for="(account, index) in accounts" :key="index" class="account-row">
          <connected-bank-account v-if="account.sourceType === 'bank'" :account="account" :disabled="isAccountDisabled(account)"/>
          <connected-credit-card v-else :credit-card="account" :disabled="isAccountDisabled(account)"/>
          <separator v-if="index !== accounts.length -1"/>
        </div>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import ConnectedBankAccount from '@/base-components/onboarding/ConnectedBankAccount';
import ConnectedCreditCard from '@/base-components/onboarding/ConnectedCreditCard';
import ExpandArrow from '../ExpandArrow';

export default {
  name: 'ExpandableConnectedAccountList',
  components: {
    ExpandArrow,
    CollapseTransition: BaseUI.CollapseTransition,
    Separator: BaseUI.Separator,
    ConnectedBankAccount,
    ConnectedCreditCard,
  },
  props: {
    accounts: {
      type: Array,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    sourceType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    alwaysExpand: { type: Boolean, default: false },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  created() {
    if (this.alwaysExpand) {
      this.isExpanded = true;
    }
  },
  methods: {
    toggleExpand() {
      if (!this.alwaysExpand) {
        this.isExpanded = !this.isExpanded;
      }
    },
    isAccountDisabled(account) {
      return this.disabled || account.isExcluded;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.expandable-connected-account-list {

  .title-with-expand-collapse {
    color: $riseup_black;
    @include responsive-padding;
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 14px;
      font-weight: bold;
    }

    .image-container {
      height: 100%;
    }
  }

  .connected-accounts {
    background-color: $riseup_gray_01;
    box-shadow: inset 0 2px 6px -2px rgba(199, 199, 199, 0.5);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    .account-row {
      .row-content {
        color: $riseup_black;
        height: 72px;
        @include responsive-padding;
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo {
          width: 24px;
          height: 24px;
        }

        .account-number {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-right: 15px;
          font-size: 16px;
          font-weight: bold;
          font-family: SimplerPro;

          .dots {
            margin-right: 5px;
          }

          &.disabled {
            color: $riseup_gray_2
          }
        }
      }
    }
  }
}
</style>
