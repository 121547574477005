import SumsumApi from '@/api/SumsumApi';
import DDLogs from '@/DDLogs';

const state = {
  link: '',
  loading: false,
  hasCreationError: false,
};

const getters = {
  icrc: state => state.link.split('icrc=')[1],
  heverLink: (state, getters) => `https://riseup-partners.link/ris?icrc=${getters.icrc}`,
};

const actions = {
  async getLink({ commit }) {
    commit('setLoading', true);
    try {
      const link = await SumsumApi.getOrCreateAmbassadorLinkWithoutErrorHandling();
      commit('setLink', link);
    } catch (error) {
      DDLogs.error('ambassador get or create link failed', { error });
      commit();
      commit('setCreationError', true);
    } finally {
      commit('setLoading', false);
    }
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLink(state, link) {
    state.link = link;
  },
  setCreationError(state, creationError) {
    state.hasCreationError = creationError;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
