<template>
  <insight :insight="insight"
           button-text="לבדוק מה המצב"
           animationName="fixed-income-decreased.json"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div class="text">
        רגע לפני שנסכם את {{ hebrewMonth }}, כדאי
        <b> לוודא שצפי ההכנסות המשתנות שהגדרת עדיין נכון</b>
        או לעדכן אם צריך.
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Segment from '@/Segment';
import dateUtils from '@/utils/dates';
import PredictedVariableIncomeEOMPopup from './components/PredictedVariableIncomeEOMPopup';
import Insight from './components/Insight';

export default {
  name: 'PredictedVariableIncomeEOMInsight',
  components: {
    Insight,
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
  },
  created() {
    Segment.trackUserInteraction('PredictedVariableIncomeEOMInsight_Shown', {
      variableIncomePredictionAmount: this.insight?.variableIncomePredictionAmount,
      variableIncomeActualSum: this.insight?.variableIncomeActualSum,
      incomeEnvelopesPredictedSum: this.insight?.incomeEnvelopesPredictedSum,
      variableIncomePredictionAmountMinusVariableIncomeActualSum:
      this.insight?.variableIncomePredictionAmountMinusVariableIncomeActualSum,
    });
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      Segment.trackUserInteraction('PredictedVariableIncomeEOMInsight_Clicked');
      this.openModal({
        component: PredictedVariableIncomeEOMPopup,
        props: {
          insight: this.insight,
        },
      });
    },
  },
  computed: {
    ...mapState('cashflowView', ['cashflow']),
    hebrewMonth() {
      return dateUtils.getHebrewMonth(this.cashflow.budgetDate);
    },
  },
};

</script>

<style lang="scss" scoped>
.text {

}
</style>
