<script>
import { mapActions, mapState } from 'vuex';
import PapasMergeQuestionsActionSelection
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/merge/PapasMergeQuestionsActionSelection';
import { getLatestQuestionPerBusinessName } from '@/store/modules/mergeInvestigationQuestions';
import SpecificPapaMergeQuestionsActionSelection
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/merge/SpecificPapaMergeQuestionsActionSelection.vue';

export default {
  name: 'PapaMergeQuestionPopupsMixin',
  computed: {
    ...mapState('mergeInvestigationQuestions', ['classifiedMergeInvestigations']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),

    openInvestigationPopup(papaInvestigation) {
      this.openModal({
        component: SpecificPapaMergeQuestionsActionSelection,
        props: {
          papaMergeQuestions: [papaInvestigation],
        },
      });
    },

    openAllClassifiedMergeInvestigations() {
      this.openMergeCaseInvestigationsPopup(this.classifiedMergeInvestigations);
    },

    openMergeCaseInvestigationsPopup(papaMergeQuestions) {
      const latestQuestionPerBusinessName = getLatestQuestionPerBusinessName(papaMergeQuestions);
      this.openModal({
        component: PapasMergeQuestionsActionSelection,
        props: {
          papaMergeQuestions: latestQuestionPerBusinessName,
          showMarkAsLoanButton: false,
          firstTitle: `המערכת זיהתה חיוב${latestQuestionPerBusinessName.length > 1 ? 'י' : ''} אשראי ללא פירוט עסקאות.`,
        },
      });
    },
  },
};
</script>
