<template>
  <open-banking-description-step class="obk-description-partner-note-step" :step-number="stepNumber">
    <template v-slot:text>
      <span class="bold">יש לך חשבון עם שותפ.ה?</span>
      <div>
        צריך לוודא שזיהוי המשתמש מול הבנק מתאים לתעודת הזהות שהכנסת לפני רגע.
      </div>
    </template>
  </open-banking-description-step>
</template>

<script>
import OpenBankingDescriptionStep from '@/base-components/onboarding/openBanking/OpenBankingDescriptionStep';

export default {
  name: 'ObkDescriptionPartnerNoteStep',
  components: {
    OpenBankingDescriptionStep,
  },
  props: {
    stepNumber: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

.obk-description-partner-note-step {
  .bold {
    font-weight: bold;
  }
}

</style>
