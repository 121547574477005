import getCities from '@/api/CitiesApi';

const state = {
  cityNames: [],
};

const actions = {
  async loadAllCities({ commit }) {
    const allCities = await getCities();
    // The api to bring cities returns them with "spaces" at the end. We need to fix this in the source and then I will remove the "trim" here.
    const cityNames = allCities.map(({ cityName }) => cityName.trim());
    commit('setCityNames', cityNames);
  },
};

const mutations = {
  setCityNames(state, cityNames) {
    state.cityNames = cityNames;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
