<template>
  <image-header-page class="plan-name-and-suggestions-page" :image-header-props="imageHeaderProps">
    <template v-slot:header>
      <div class="icon-container">
        <img :src="`/images/plan-ahead/plan-image/${image}.png`"/>
      </div>
    </template>
    <div class="content-container">
      <plan-suggestions class="suggestions" @change="setNameAndType" :initialNameAndType="{name, type}"/>
      <riseup-button class="button" :disabled="!name" title="הבא" size="slim" variant="primary" :action="done"/>
    </div>
  </image-header-page>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import { mapState } from 'vuex';
import Segment from '@/Segment';
import PlansIconsMap, { PlanIconDefaultColor } from '../plans-constanst';
import PlanSuggestions from '../PlanSuggestions';

export default {
  name: 'PlanNameAndSuggestionsPage',
  components: {
    PlanSuggestions,
    RiseupButton: BaseUi.RiseupButton,
    ImageHeaderPage: BaseUi.Pages.ImageHeaderPage,
  },
  data() {
    return {
      name: '',
      type: undefined,
    };
  },
  created() {
    if (this.currentPlan.name) {
      this.name = this.currentPlan.name;
      this.type = this.currentPlan.type;
    }
  },
  computed: {
    ...mapState('planAhead', ['currentPlan', 'suggestions']),
    imageHeaderProps() {
      return {
        backgroundColor: this.color,
        bottomSeparator: false,
      };
    },
    image() {
      return PlansIconsMap[this.type] ? PlansIconsMap[this.type].icon : 'general';
    },
    color() {
      return PlansIconsMap[this.type] ? PlansIconsMap[this.type].color : PlanIconDefaultColor;
    },
  },
  methods: {
    setNameAndType(newValue) {
      this.name = newValue.name;
      this.type = newValue.type;
    },
    done() {
      const usedSuggestions = this.suggestions.map(s => s.name)
        .includes(this.name);
      Segment.trackUserInteraction('PlanAhead_Flow_NamePage_NextClicked', { usedSuggestions });
      this.$emit('done', {
        name: this.name,
        type: this.type,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.plan-name-and-suggestions-page {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;

  .icon-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 180px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 24px;
    height: 100%;
    justify-content: space-between;
  }

  .button {
    margin-bottom: 40px;
  }
}

</style>
