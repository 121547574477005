<template>
  <colored-popup class="light-blue" :close-action="onClose" :dark-text="true">
    <template v-slot:top-content>
      <div class="centered">
        <lottie-player class="balance-money-dime-fall"
                       src="/animations/balance-money-step-1.json"
                       :autoplay="true"
                       :loop="false"/>
        <div class="title margin-top-medium">הכסף שלך בעו״ש נשחק
          וחבל...
        </div>
      </div>
    </template>
    <template v-slot:bottom-content>
      <div class="body-text margin-bottom-medium">
        לפי ההערכות, בגלל האינפלציה של {{ inflationRate }}%, על כל
        <span class="bold">100 ₪ שנשאיר בעו״ש בשנה הקרובה נאבד יותר מ-{{moneyLoss}} ₪*</span>.
      <div class="margin-top-medium-large margin-bottom-x-large">
          *מבחינת כוח הקניה של הכסף
      </div>
      </div>
      <riseup-button
        class="margin-bottom-medium"
        title="מה אפשר לעשות?"
        :action="onNext"
        size="slim"
        :bold="true"
      />
    </template>

    <template v-slot:footer v-if="showFooter">
      <div class="centered">
        <a class="link-button" @click="onDismissClicked">
          הבנתי, אל תציגו לי יותר
        </a>
      </div>
    </template>
  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapGetters } from 'vuex';

export default {
  name: 'BalanceMoneyStep1',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    onClose: {
      type: Function,
      required: true,
    },
    onNext: {
      type: Function,
      required: true,
    },
    onDismiss: {
      type: Function,
      required: true,
    },
    origin: {
      type: String,
      require: true,
    },
    stepName: {
      type: String,
      require: true,
    },
  },
  computed: {
    ...mapGetters('featureFlags', ['inflationRate']),
    showFooter() {
      return this.origin === 'Insight';
    },
    moneyLoss() {
      return Math.floor(this.inflationRate);
    },
  },
  created() {
    Segment.trackUserInteraction('BalanceMoneyFlow_StepEntered', { step: this.stepName });
  },
  methods: {
    onDismissClicked() {
      this.onDismiss(false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/balance-money/balance-money-styles";

</style>
