<template>
  <div class="step-container">
    <Step>
      <template v-slot:header>
        <div class="header">ושאלה אחרונה לסיום</div>
      </template>
      <template v-slot:content>
        <div class="content-container">
          <div class="top-area">
            <div class="title">{{ title }}</div>
            <TextArea
                class="text-area"
                :autofocus="true"
                :value="freeText"
                :on-input="onTextAreaChanged"
                placeholder="נשמח לשמוע כל דבר שיכול לעזור לנו להשתפר"
            />
          </div>
          <check-box :initially-checked="requestCX" :toggle="toggleRequestCx">
            <template v-slot:title>
              <div class="cx-text"> אשמח לקבל עזרה מהצוות</div>
            </template>
          </check-box>
        </div>
      </template>
      <template v-slot:footer>
        <RiseupButton
            class="next-button"
            title="סיימתי"
            :action="onClicked"
            :bold="true"
            :loading="postResultLoading"
        />
      </template>
    </Step>

  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import {
  ACTIVATION_SURVEY_STEPS,
  activationSurveyStepsConfig,
} from '@/pages/responsive-pages/authenticated/survey/activation-survey/consts';
import Step from '../../components/Step';

export default {
  name: 'AnythingElseStep',
  components: {
    Step,
    RiseupButton: BaseUI.RiseupButton,
    TextArea: BaseUI.TextArea,
    CheckBox: BaseUI.CheckBox,
  },
  created() {
    Segment.trackUserGot(`ActivationSurvey${this.activeStep}_PageEntered`, this.answers);
  },
  data() {
    return {
      requestCX: false,
      postResultLoading: false,
    };
  },
  computed: {
    ...mapState('activationSurvey', ['answers', 'activeStep']),
    ...mapState('session', ['customerId']),
    currentStep() {
      return activationSurveyStepsConfig[this.activeStep];
    },
    title() {
      return this.currentStep.question;
    },
    freeText() {
      return this.answers[this.activeStep] || '';
    },
  },
  methods: {
    ...mapMutations('activationSurvey', ['setActiveStep', 'updateStepAnswer']),
    ...mapActions('activationSurvey', ['saveAnswers']),

    onTextAreaChanged(text) {
      this.updateStepAnswer({ stepName: this.activeStep, stepResults: text });
    },
    toggleRequestCx() {
      this.requestCX = !this.requestCX;
    },
    async postResults() {
      this.postResultLoading = true;
      await this.saveAnswers();
      this.postResultLoading = false;
    },
    async onClicked() {
      Segment.trackUserGot(`ActivationSurvey${this.activeStep}_NextClicked`, {
        ...this.answers,
        requestCX: this.requestCX,
      });

      if (this.requestCX) {
        await this.postResults();
      }
      Segment.trackUserGot('ActivationSurveySummary', {
        freeText: this.freeText,
        score: this.answers[ACTIVATION_SURVEY_STEPS.Satisfaction],
      }, this.customerId);
      this.setActiveStep(this.currentStep.nextStep(this.answers));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.step-container {
  height: 100%;

  .header {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $riseup_blue;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .top-area {
      height: 100%;
      width: 100%;

      .title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 28px;
        line-height: 32px;
      }

      .text-area {
        height: 30%;
      }
    }

    .cx-text {
      font-size: 16px;
      margin-right: 12px;
    }
  }

  .next-button {
    width: 100%;
    height: 54px;
  }
}
</style>
