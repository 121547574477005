import _ from 'lodash';
import AggregatorApi from '@/api/AggregatorApi';
import BalanceApi from '@/api/BalanceApi';
import ClearingHouseApi from '@/api/ClearingHouseApi';

const state = {
  loans: [],
  mortgages: [],
  savingsAccounts: [],
  oshBalances: [],
  securities: [],
  // קרן השתלמות
  educationFunds: [],
  // קופת גמל להשקעה
  gemelLeashkaa: [],
  // פוליסות חיסכון
  savingsPolicies: [],
  // חיסכון לכל ילד
  childSavings: [],
  mislakaConsentData: {},
};
const getters = {
  assets: (state, getters) => [...state.savingsAccounts, ...getters.positiveOshBalances, ...state.securities,
    ...state.educationFunds, ...state.gemelLeashkaa, ...state.savingsPolicies, ...state.childSavings],
  loansWithoutOshBalances: state => _.filter(state.loans, loan => loan.productType === 'Loan'),
  liabilities: state => [...state.loans, ...state.mortgages],
  assetsSum: (state, getters) => _calculateTotalBalance(getters.assets),
  loansSum: state => -1 * _calculateTotalBalance(state.loans),
  positiveOshBalances: state => _.filter(state.oshBalances, balance => balance.balance > 0),
  // negative osh balances are part of "loans"
  negativeOshBalances: state => _.filter(state.loans, loan => loan.productType === 'BalanceAccount'),
  mortgagesSumBySource: state => _.chain(state.mortgages)
    .groupBy(m => m.sourceIdentifier)
    .mapValues(mortgagesValues => _.chain(mortgagesValues)
      // eslint-disable-next-line no-unsafe-optional-chaining
      .reject(m => Number.isNaN(+m.balanceAmount?.amount))
      // eslint-disable-next-line no-unsafe-optional-chaining
      .sumBy(m => +m.balanceAmount?.amount).value())
    .map((value, key) => ({ sourceIdentifier: key, amount: value }))
    .value(),
  mortgagesSum: (state, getters) => _.sumBy(getters.mortgagesSumBySource, m => m.amount),
};

const actions = {
  async fetchAssetsAndLiabilities({ commit }) {
    const [anlData, oshBalances, clearingHouseAssets] = await Promise.all([
      AggregatorApi.getAssetsAndLiabilities(),
      BalanceApi.fetchBalances(),
      ClearingHouseApi.getAssets(),
    ]);
    commit('setLoans', anlData.loans);
    commit('setSecurities', _.reject(anlData.securities, security => security.balanceAmount?.amount === 'NA'));
    commit('setMortgages', anlData.mortgages);
    commit('setSavingsAccounts', anlData.savingsAccounts);
    commit('setOshBalances', oshBalances);
    commit('setEducationFunds', clearingHouseAssets.filter(a => a.productTypeId === '4'));
    commit('setGemelLeashkaa', clearingHouseAssets.filter(a => a.productTypeId === '9'));
    commit('setSavingsPolicies', clearingHouseAssets.filter(a => a.productTypeId === '5'));
    commit('setChildSavings', clearingHouseAssets.filter(a => a.productTypeId === '10'));
  },
  async createMislakaConsent({ commit, state }) {
    await ClearingHouseApi.createConsent(state.mislakaConsentData);
    commit('setMislakaConsentData', {});
  },
};

const mutations = {
  setLoans(state, loans) {
    state.loans = loans;
  },
  setMortgages(state, mortgages) {
    state.mortgages = mortgages;
  },
  setSavingsAccounts(state, savingsAccounts) {
    state.savingsAccounts = savingsAccounts;
  },
  setOshBalances(state, oshBalances) {
    state.oshBalances = oshBalances;
  },
  setSecurities(state, securities) {
    state.securities = securities;
  },
  setEducationFunds(state, educationFunds) {
    state.educationFunds = educationFunds;
  },
  setGemelLeashkaa(state, gemelLeashkaa) {
    state.gemelLeashkaa = gemelLeashkaa;
  },
  setSavingsPolicies(state, savingsPolicies) {
    state.savingsPolicies = savingsPolicies;
  },
  setChildSavings(state, childSavings) {
    state.childSavings = childSavings;
  },
  setMislakaConsentData(state, mislakaConsentData) {
    state.mislakaConsentData = mislakaConsentData;
  },
};

function _calculateTotalBalance(accounts) {
  return _.chain(accounts)
    // eslint-disable-next-line no-unsafe-optional-chaining
    .map(account => account.balance || +account.balanceAmount?.amount)
    .reject(balance => Number.isNaN(balance)).sum()
    .value();
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
