<template>
  <insight :insight="insight"
           :image-name="'fees_insights.svg'"
           :button-text="'ספרו לי עוד'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>זיהינו עמלות שמגיעות עד <span class="amount">{{ formatAmount(yearlyTransactionsSum) }} ₪</span>
         <span class="bold">בשנה. </span>אפשר לבטל אותן וברגע לחסוך בהוצאות הקבועות!</div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import cashflowViewConsts from '@/constants/cashflow-view';
import FeesInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/FeesInsightPopup';
import moneyUtils from '@/utils/money';
import Insight from './components/Insight';

export default {
  name: 'FeesInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('PersonalisedFirstWeekInsightShown');
  },
  computed: {
    buttons() {
      return [
        {
          text: 'תודה, אל תציגו לי יותר',
          variant: 'primary',
          action: async () => {
            await this.markAsRead(this.insight);
            Segment.trackUserInteraction('PersonalisedFirstWeekInsightDoNotShowAgain');
            this.closePopup();
          },
        },
      ];
    },
    yearlyTransactionsSum() {
      return this.insight.transactionsSum * 12;
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    buttonAction() {
      this.openModal({
        component: FeesInsightPopup,
        props: {
          insight: this.insight,
          insightPopupTitle: 'כדאי לצלצל לבנק ולחברת האשראי ולבקש לבטל את העמלות האלו:',
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
          buttons: this.buttons,
        },
      });
    },
    closePopup() {
      this.closeModal();
    },
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
  },
};

</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}

.bold {
  font-weight: bold;
}
</style>
