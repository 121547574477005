const ClickOutside = {
  bind(el, binding, vnode) {
    const vm = vnode.context;
    const callback = binding.value;

    el.clickOutsideEvent = event => { // eslint-disable-line
      if (!(el == event.target || el.contains(event.target))) { // eslint-disable-line
        return callback.call(vm, event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};

export default ClickOutside;
