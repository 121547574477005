<template>
  <div class="rolling-credit-community-insight-popup">
    <colored-popup class='danger' :close-action="closePopup">
      <template v-slot:top-content>
        <div class="header">
          <div v-if="isDebtTypeTotal"> ב{{ budgetMonth }} העברת חוב של</div>
          <div v-else>ב{{ budgetMonth }} גדל החוב בסכום של</div>
          <div class="amount">{{ formattedAmount }} <span class="nis">₪</span></div>
          <div class="header-card-info">בכרטיס {{ cardSource }} שמסתיים ב־{{ last4Digit }}</div>
        </div>
      </template>
      <template v-slot:bottom-content>
        <div class="content">
          <div class="content-text">
            חיוב אשראי חודשי קבוע הוא בעצם הלוואה עם ריביות גבוהות במיוחד. מומלץ למצוא אפשרויות אחרות ונשמח לעזור לך!
            <br/>
            <br/>
            הנה מה שכתבו חברי הקהילה:
          </div>
          <img class="comments-image" src="/images/insights/rolling-credit-comments.png" alt="תגובות נבחרות"/>
          <div class="link-to-fb" @click="linkToFacebookClicked">לפוסט המלא בקהילה</div>
          <riseup-button
            class="btn-next-popup"
            title="איך אפשר לצאת מזה?"
            :bold="true"
            :action="openNextPopup"/>
        </div>
      </template>
      <template v-slot:footer>
        <div class="rolling-footer" @click="understoodClicked">הבנתי, תודה</div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import moneyUtils from '@/utils/money';
import dateUtils from '@/utils/dates';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import RollingCreditCTAPopup from './RollingCreditCTAPopup';
import consts from './consts';

export default {
  name: 'RollingCreditCommunityPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
  },
  created() {
    Segment.trackUserGot('RollingCreditCommunityPopupLoaded', this.insight);
  },
  computed: {
    isDebtTypeTotal() {
      return this.insight.debtType === consts.DebtType.Total;
    },
    last4Digit() {
      return this.insight.accountNumberPiiValue;
    },
    cardSource() {
      return getSourceConfigurationBySourceName(this.insight.source)?.displayName;
    },
    DebtType() {
      return consts.DebtType;
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.debt, 0);
    },
    budgetMonth() {
      return dateUtils.getHebrewMonth(moment(this.insight.budgetDate)
        .subtract(1, 'month')
        .format('YYYY-MM'));
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    linkToFacebookClicked() {
      Segment.trackUserInteraction('RollingCreditCommunityPopupFBLinkClicked');
      window.open('https://www.facebook.com/groups/riseuptogethergroup/permalink/2833291693642859/', '_blank');
    },
    openNextPopup() {
      Segment.trackUserInteraction('RollingCreditCommunityPopupNextClicked');
      this.closeModal();
      this.openModal({
        component: RollingCreditCTAPopup,
        props: {
          insight: this.insight,
          onClose: async () => {
            this.closeModal();
            await this.markAsRead(this.insight);
          },
        },
      });
    },
    closePopup() {
      Segment.trackUserGot('RollingCreditCommunityPopupClosed', this.insight);
      this.closeModal();
    },
    async understoodClicked() {
      this.closeModal();
      Segment.trackUserGot('RollingCreditCommunityPopupUnderstoodClicked', this.insight);
      await this.markAsRead(this.insight);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.rolling-credit-community-insight-popup {
  text-align: right;

  .close-button {
    width: 100%;
  }

  .header {
    color: $riseup_white;
    font-size: 16px;
    font-weight: bold;

    .amount {
      font-size: 46px;
      font-weight: bold;;
      margin-top: 14px;

      .nis {
        font-size: 32px;
      }
    }

    .header-card-info {
      line-height: 20px;
      margin-top: 8px;
      font-weight: normal;
    }
  }

  .content {
    text-align: center;

    .content-text {
      text-align: right;
      font-size: 18px;
      line-height: 24px;
    }

    .comments-image {
      margin-top: 24px;
      width: 100%;
    }

    .link-to-fb {
      text-decoration: underline;
      cursor: pointer;
      margin-top: 20px;
    }

    .btn-next-popup {
      margin-top: 30px;
    }
  }

  .rolling-footer {
    cursor: pointer;
    text-align: center;

  }

  .rolling-footer:hover {
    color: blue;
  }
}
</style>
