<template>
  <div>
    <open-banking-description-step step-number="1">
      <template v-slot:text>
        <div class="bold">איך כדאי להתחבר לחשבון?</div>
        <div>
          <span>מומלץ להתחבר לחשבון שלך במזרחי</span>
          <span class="text-bold">באמצעות האתר</span>
          <span>ולא באמצעות האפליקציה.</span>
        </div>
      </template>
    </open-banking-description-step>
    <obk-description-partner-note-step step-number="2" />
    <obk-description-connect-supported-accounts-step step-number="3" />
    <obk-description-connect-unsupported-accounts-step step-number="4" />
  </div>
</template>

<script>
import OpenBankingDescriptionStep from '@/base-components/onboarding/openBanking/OpenBankingDescriptionStep';
import ObkDescriptionPartnerNoteStep
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/ObkDescriptionPartnerNoteStep';
import ObkDescriptionConnectSupportedAccountsStep
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/ObkDescriptionConnectSupportedAccountsStep';
import ObkDescriptionConnectUnsupportedAccountsStep
  from '@/base-components/onboarding/openBanking/obk-redirect-descriptions/ObkDescriptionConnectUnsupportedAccountsStep';

export default {
  name: 'OpenBankingIsracardDescription',
  components: {
    ObkDescriptionConnectUnsupportedAccountsStep,
    ObkDescriptionConnectSupportedAccountsStep,
    ObkDescriptionPartnerNoteStep,
    OpenBankingDescriptionStep,
  },
};
</script>

<style scoped lang="scss">
.text-bold {
  font-weight: bold;
}
</style>
