<template>
  <full-screen-modal :close-action="closeAction" :back-action="backAction" class="content-wrapper">
    <template v-slot:content>
      <credentials-with-async-fallback
        :is-loading="isLoading"
        :async-timeout-reached="asyncTimeoutReached"
        :save-credentials-error="saveCredentialsError"
        :source-name="sourceName"
        :on-timeout="onTimeout"
        :loading-start-time="loadingStartTime"
        class="content-wrapper">
        <template v-slot:credentials v-if="source">
          <source-connection-details
            :invalid-credentials="invalidCredentials"
            :source="source"
            :name-already-used-error="nameAlreadyUsedError"
            :set-name-already-in-use-error="setNameAlreadyInUseError"
            :save-credentials-error="saveCredentialsError"
            :validate-name="validateName"
            :update-mode="updateMode"
            :on-save="onSave"
            :prevent-o-bk-connection="preventOBkConnection"/>
        </template>
      </credentials-with-async-fallback>
    </template>
  </full-screen-modal>
</template>

<script>
import CredentialsWithAsyncFallback
  from '@/pages/responsive-pages/authenticated/onboard2/CredentialsWithAsyncFallback';
import FullScreenModal from '@/base-components/FullScreenModal';
import SourceConnectionDetails from '@/base-components/onboarding/openBanking/SourceConnectionDetails';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import OpenBankingMixin from '@/base-components/onboarding/openBanking/OpenBankingMixin';

export default {
  name: 'ConnectionFlowModal',
  mixins: [OpenBankingMixin],
  components: {
    SourceConnectionDetails,
    FullScreenModal,
    CredentialsWithAsyncFallback,
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    backAction: {
      type: Function,
      required: false,
    },
    sourceType: {
      type: String,
      required: true,
    },
    invalidCredentials: {
      type: Boolean,
      required: true,
    },
    saveCredentialsError: {
      type: Boolean,
      required: true,
    },
    nameAlreadyUsedError: {
      type: Boolean,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    setNameAlreadyInUseError: {
      type: Function,
      required: true,
    },
    sourceName: {
      type: String,
    },
    validateName: {
      type: Function,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    updateMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    next: {
      type: Function,
      required: true,
    },
    onConnectionSuccess: {
      type: Function,
      required: true,
    },
    asyncTimeoutReached: {
      type: Boolean,
      required: true,
    },
    onTimeout: {
      type: Function,
      required: true,
    },
    loadingStartTime: {
      type: Date,
    },
    preventOBkConnection: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    source() {
      return getSourceConfigurationBySourceName(this.sourceName);
    },
  },
  watch: {
    async isLoading(newValue, oldValue) {
      if (oldValue && !newValue && !this.saveCredentialsError && !this.invalidCredentials) {
        await this.onConnectionSuccess();
        this.next();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  text-align: right;
}
</style>
