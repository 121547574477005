<template>
  <insight :insight="insight"
           :animation-name="'tracking-categories-selection-insight.json'"
           :button-text="'להוספת הקטגוריות שלי'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>המערכת סיימה למיין את ההוצאות שלך,
        <span class="bold">עכשיו תורך לבחור את הקטגוריות שמעניינות אותך.</span>
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import TrackingCategoriesFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/tracking-categories/TrackingCategoriesFlow';
import Insight from './components/Insight';

export default {
  name: 'TrackingCategoriesSelectionInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserInteraction('TrackingCategoriesSelectionsInsight_Shown');
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    buttonAction() {
      Segment.trackUserInteraction('TrackingCategoriesSelectionsInsight_Clicked');
      this.openModal({
        component: TrackingCategoriesFlow,
        props: {
          insight: this.insight,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
