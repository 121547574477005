<template>
  <div class="create-tracking-category-popup">
    <colored-popup :class="variant" :close-action="close">

      <template v-slot:top-content>
        <div class="title ri-medium-bold-headline">
          הוספת קטגוריה למעקב
        </div>
      </template>

      <template v-slot:bottom-content>
        <div v-if="categoriesWithAverage.length" class="sub-title avg">
          קטגוריות שהמערכת זיהתה שבשימוש קבוע
        </div>
        <category-block v-for="category of categoriesWithAverage" :category="category"
                        :key="category.name" :select-category="onSelect" class-name="select-mode"/>
        <div v-if="categoriesWithAverage.length" class="sub-title no-avg">
          קטגוריות נוספות
        </div>
        <category-block v-for="category of categoriesWithoutAverage" :category="category"
                        :key="category.name" :select-category="onSelect" class-name="select-mode"/>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import CategoryBlock from '@/pages/responsive-pages/authenticated/navigation-pages/tracking-categories/CategoryBlock';
import Segment from '@/Segment';
import cashflowViewConsts from '../../../../../../../../../../constants/cashflow-view';

export default {
  name: 'CreateTrackingCategoryPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    CategoryBlock,
  },
  data() {
    return {
      customCategoryName: '',
    };
  },
  created() {
    Segment.trackUserInteraction('CreateTrackingCategory_Entered', {});
    this.setAvailableTransactions(this.presentedBudget);
  },
  computed: {
    ...mapState('createTrackingCategory', ['selectedTrackingCategory']),
    ...mapGetters('createTrackingCategory', ['availableTrackingCategories', 'customCategoryLabel', 'enrichedDefaultCategoriesWithAvgInfo']),
    ...mapGetters('cashflowView', ['presentedBudget']),
    variant() {
      return cashflowViewConsts.CATEGORY_STYLE[cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING];
    },
    categoriesWithAverage() {
      return this.enrichedDefaultCategoriesWithAvgInfo.filter(c => c.historyAverage);
    },
    categoriesWithoutAverage() {
      return this.enrichedDefaultCategoriesWithAvgInfo.filter(c => !c.historyAverage);
    },
  },
  methods: {
    ...mapActions('createTrackingCategory', ['selectTrackingCategory', 'setAvailableTransactions']),
    ...mapMutations('createTrackingCategory', ['setCustomTrackingCategory']),
    onSelect(selectedCategory) {
      if (selectedCategory.label === this.customCategoryLabel) {
        this.setCustomTrackingCategory(true);
      } else {
        this.setCustomTrackingCategory(false);
      }
      this.selectTrackingCategory(selectedCategory);
      Segment.trackUserInteraction('CreateTrackingCategory_SaveClicked', {
        selectedTrackingCategory: this.selectedTrackingCategory,
        availableTrackingCategories: this.availableTrackingCategories,
      });
      this.$emit('done-clicked');
    },
    close() {
      Segment.trackUserInteraction('CreateTrackingCategory_CloseClicked', {});
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.create-tracking-category-popup {
  text-align: right;

    .title {
      color: $riseup_white;
  }

  .bottom {
    .sub-title {
      margin-bottom: 10px;
      margin-top: 10px;
      font-size: 14px;
      line-height: 22px;

      &.no-avg {
        padding-top: 20px;
      }
    }
  }
}

::v-deep .category-block.select-mode {
  margin-bottom: 15px;
  .icon-name-container .name {
    padding-right: 8px;
  }
  .block {
    margin-right: 0;
  }
}

</style>
