<template>
  <div class="load-wrapper">
    <div class="spinner-wrapper">
      <spinner class="spinner"/>
    </div>
  </div>
</template>

<script>
import Spinner from './onboarding/Spinner';

export default {
  name: 'loader',
  components: {
    Spinner,
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .load-wrapper {
    width: 100%;
    height: 100%;

    .spinner-wrapper {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .load-bar {
    position: relative;
    width: 100%;
    height: 6px;
    background-color: $riseup_gray_0;
  }
  .bar {
    content: "";
    display: inline;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
  }
  .bar:nth-child(1) {
    background-color: $riseup_yellow;
    animation: loading 3s linear infinite;
  }

  @keyframes loading {
    from {left: 50%; width: 0;}
    to {left: 0; width: 100%;}
  }

</style>
