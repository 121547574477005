<template>
  <div id="app">
    <router-view></router-view>
    <stacked-modal-root id="stacked-modal-root"></stacked-modal-root>
    <transition name="fade">
      <div v-if="showToaster" class="toaster-container">
        <component :is="currentToasterComponent" v-bind="toasterProps" />
      </div>
    </transition>
    <nav-bar-root id="nav-bar-root"></nav-bar-root>
  </div>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import StackedModalRoot from './modal/StackedModalRoot.vue';
import NavBarRoot from './modal/NavBarRoot.vue';
import '@lottiefiles/lottie-player';

const listenerFunction = () => {};
function patchActiveButtonsOnMobile() {
  document.addEventListener('touchstart', listenerFunction, true);
}

function removeActiveButtonsOnMobile() {
  document.removeEventListener('touchstart', listenerFunction);
}

export default {
  name: 'App',
  components: {
    NavBarRoot,
    StackedModalRoot,
  },
  data() {
    return {
      showToaster: false,
      currentToasterComponent: null,
      toasterProps: null,
    };
  },
  created() {
    patchActiveButtonsOnMobile();
    EventBus.$on('open-toaster', this.openToaster);
    EventBus.$on('close-toaster', this.closeToaster);
  },
  beforeDestroy() {
    removeActiveButtonsOnMobile();
    EventBus.$off('open-toaster', this.openToaster);
    EventBus.$off('close-toaster', this.closeToaster);
  },
  methods: {
    openToaster({ component, props }) {
      this.currentToasterComponent = component;
      this.toasterProps = props;
      this.showToaster = true;
    },
    closeToaster() {
      this.showToaster = false;
    },
  },
};
</script>
<!-- Don't know why this is the only import option that actually works, but it works! -->
<style lang="scss" src="@riseupil/base-ui/src/scss/fonts.scss"/>

<style lang="scss">
@import './scss/mixins';
@import '~@riseupil/base-ui/src/scss/icons-font-classes';
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/riseup-z-index';

#app {
  direction: rtl;
  font-family: SimplerPro, 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $riseup_black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: 100%;
  font-kerning: none;

  #stacked-modal-root {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .toaster-container{
    @include responsive-width-and-margin;
    position: fixed;
    display: flex;
    justify-content: center;
    padding: 0 17px;
    width: 100%;
    top: 75px;
    z-index: $toast-message;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  #nav-bar-root {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  input, textarea, select { font-family: inherit; }
}
</style>
