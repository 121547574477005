<template>
  <div class="buttons-with-or-wrapper">
    <slot name="firstButton" />
    <div v-if="hasTwoButtonsSlots" class="separator-wrapper margin-vertical-m">
      <span class="separator"/>
      <span class="separator-text">או</span>
      <span class="separator"/>
    </div>
    <slot name="secondButton" />
  </div>
</template>

<script>
export default {
  name: 'ButtonsWithOrWrapper',
  computed: {
    hasTwoButtonsSlots() {
      return !!this.$slots.firstButton && !!this.$slots.secondButton;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.buttons-with-or-wrapper {
  .separator-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .separator {
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: $riseup_gray_2;
    }

    .separator-text {
      margin: 0 13px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}
</style>
