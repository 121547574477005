<template>
  <div class="credentials-details">
    <snack-bar class="error-bar" v-if="showErrorBar" :variant="'danger'">
      <template v-slot:primary>
        <div class="bold-text">{{ errorBarText }}</div>
      </template>
    </snack-bar>
    <div class="page-content">
      <div class="image-wrapper">
        <img v-if="showLogo" class="logo-image" :src="require(`@/assets/bank-logos/${source.logoFile}`)">
      </div>
      <slot name="title"/>
      <template v-if="!hasTitleSlot">
        <div class="title" v-if="source.type === 'bank'">
          כדי לחבר את חשבון הבנק צריך להזין את <span class="bold">פרטי המשתמש הקבועים</span> באתר או באפליקציה של הבנק:
        </div>
        <div class="title" v-else>
          כדי לחבר את כרטיס {{ source.displayName }} צריך להזין את
          <span class="bold">פרטי המשתמש הקבועים</span>
          שלך לאתר או לאפליקציה של {{ source.displayName }}.
        </div>
      </template>
      <info-tool-tip
        class="tool-tip-wrapper"
        :text="tooltipText"
        :popup-text="'הסבר מדויק.'"
        :event-name="'CredentialsInfoTooltipClicked'"
        :popup-component="credentialsHelpPopup"
        :popup-props="credentialsHelpPopupProps"
        icon-path="/images/info-icon-blue.svg"
        :under-line-link="true"/>
      <ValidationObserver ref="observer" slim>
        <credentials-input-field  v-if="!updateMode"
                                  :class="{'input-container': !useNewCredNameText}"
                                  :field-name="inputNameText"
                                  :validations="'min-length:2'"
                                  :error="nameAlreadyUsedError"
                                  :error-message="nameAlreadyUsedErrorMessage"
                                  :field-type="'text'"
                                  :field-value="name"
                                  :update-input="updateName"/>
        <div v-if="useNewCredNameText" class="ri-label cred-name-disclaimer">כאן צריך שם לחשבון לחשבון שישמש אותך בהמשך במערכת רייזאפ</div>
        <div v-for="(field, fieldKey) in fields" :key="fieldKey">
          <secret-input-field v-if="updateMode && !field.renew" :fieldText="field.name" :on-edit="() => handleEdit(field)" />
          <credentials-input-field v-else-if="field.type !== 'password'"
                                   ref="field"
                                   class="input-container"
                                   :field-name="field.name"
                                   :error="invalidCredentials && showError"
                                   :field-value="credentials[fieldKey]"
                                   :update-input="updateCredentials(fieldKey)"
                                   :field-type="field.type"
                                   :validations="field.validation"/>
          <credentials-input-field v-else
                                   ref="field"
                                   class="input-container"
                                   :error="invalidCredentials && showError"
                                   :field-name="field.name"
                                   :field-type="field.type"
                                   :field-value="credentials[fieldKey]"
                                   :validations="field.validation"
                                   :update-input="updateCredentials(fieldKey)"/>
        </div>
      </ValidationObserver>
      <div class="button-wrapper">
        <riseup-button
          class="save-button"
          :disabled="!buttonEnabled || loading"
          :title="buttonTitle"
          @click="saveCredentials"
          :size="'small'"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import SecretInputField from '@/base-components/card-parts/SecretInputField.vue';
import InvalidCredsConsts from '@/constants/invalid-creds';
import CredentialsInputField from './CredentialsInputField.vue';
import InfoToolTip from './InfoToolTip.vue';
import Segment from '../../Segment';
import BankCredsHelpPopup from './help-popups/BankCredsHelpPopup.vue';
import VisaCalCredsHelpPopup from './help-popups/VisaCalCredsHelpPopup.vue';
import IsracardCredsHelpPopup from './help-popups/IsracardCredsHelpPopup.vue';
import AmexCredsHelpPopup from './help-popups/AmexCredsHelpPopup.vue';
import LeumiCardMaxCredsHelpPopup from './help-popups/LeumiCardMaxCredsHelpPopup.vue';

export default {
  name: 'CredentialsDetails',
  components: {
    SecretInputField,
    CredentialsInputField,
    InfoToolTip,
    RiseupButton: BaseUi.RiseupButton,
    SnackBar: BaseUi.SnackBar,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    nameAlreadyUsedError: {
      type: Boolean,
      default: false,
    },
    saveCredentialsError: {
      type: Boolean,
      default: false,
    },
    setNameAlreadyInUseError: {
      type: Function,
    },
    invalidCredentials: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: true,
    },
    updateMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      credentials: {},
      name: '',
      showError: false,
      loading: false,
      fields: [],
    };
  },
  computed: {
    ...mapState('onboardingState', ['error']),
    ...mapState('credentials', ['credentialsErrorMessage']),
    ...mapGetters('featureFlags', ['useNewCredNameText']),
    inputNameText() {
      return this.useNewCredNameText ? 'איך לקרוא לחשבון הזה?' : 'שם בעל.ת החשבון?';
    },
    credentialsHelpPopup() {
      if (this.source.type === 'bank') {
        return BankCredsHelpPopup;
      }
      // credit card
      switch (this.source.sourceName) {
      case 'ישראכארט':
        return IsracardCredsHelpPopup;
      case 'ויזה כאל':
        return VisaCalCredsHelpPopup;
      case 'לאומי קארד':
        return LeumiCardMaxCredsHelpPopup;
      case 'אמריקן אקספרס':
        return AmexCredsHelpPopup;
      default:
        return null;
      }
    },
    credentialsHelpPopupProps() {
      if (this.source.type === 'bank') {
        return {
          sourceName: this.source.sourceName,
        };
      }
      // credit card
      return null;
    },
    tooltipText() {
      return 'לא ברור לך מה פרטי ההתחברות שלך? הכנו לך כאן';
    },
    buttonTitle() {
      return `לחיבור חשבון ${this.source.displayName}`;
    },
    credentialsForSave() {
      return _.mapValues(this.credentials, val => val.trim());
    },
    isValidName() {
      return this.name.length > 1;
    },
    nameAlreadyUsedErrorMessage() {
      return this.nameAlreadyUsedError ? 'כבר השתמשת בשם הזה' : null;
    },
    allFieldsHaveInput() {
      const sourceFields = this.source.fields || this.source.credentials.fields;
      const fieldsToValidate = this.updateMode ? _.pickBy(sourceFields, f => f.renew) : sourceFields;
      return _.every(_.keys(fieldsToValidate), fieldKey => this.credentials[fieldKey] && this.credentials[fieldKey].length > 3);
    },
    buttonEnabled() {
      return (this.updateMode || this.isValidName) && this.allFieldsHaveInput;
    },
    showErrorBar() {
      return (this.error === 'credentialsWereChanged') || (this.showError && (this.saveCredentialsError || this.invalidCredentials));
    },
    errorBarText() {
      if (this.saveCredentialsError) {
        Segment.trackUserGot('CredentialsSaveFailed');
        return 'קרתה תקלה בשמירת הנתונים, אפשר לנסות שוב';
      } if (this.invalidCredentials && this.credentialsErrorMessage) {
        Segment.trackUserGot('InvalidCredentials');
        return InvalidCredsConsts.CredentialsDetailsInvalid[this.credentialsErrorMessage];
      } if (this.error === 'credentialsWereChanged') {
        return 'פרטי החשבון השתנו, אפשר לנסות שוב';
      } if (this.invalidCredentials) {
        Segment.trackUserGot('InvalidCredentials');
        return InvalidCredsConsts.CredentialsDetailsInvalid.InvalidCredentials;
      }
      return '';
    },
    hasTitleSlot() {
      return !!this.$slots.title;
    },
  },
  methods: {
    handleEdit(field) {
      this.fields = {
        ...this.fields,
        [field.key]: {
          ...field,
          renew: true,
        },
      };
    },
    updateCredentials(fieldKey) {
      return value => {
        this.$set(this.credentials, fieldKey, value);
      };
    },
    updateName(name) {
      this.name = name;
      this.setNameAlreadyInUseError(false);
    },
    async saveCredentials() {
      this.loading = true;
      this.showError = true;
      Segment.trackUserInteraction('AccountSaveClickedOB', { sourceName: this.source.sourceName });
      const valid = await this.$refs.observer.validate();
      _.forEach(this.$refs.field, field => field.validate());
      if (valid) {
        await this.onSave({
          sourceName: this.source.sourceName,
          provider: this.source.sourceName,
          credentials: this.credentialsForSave,
          name: this.name,
          sourceType: this.source.type,
        });
      }
      this.loading = false;
    },
  },
  watch: {
    async showErrorBar(newValue, oldValue) {
      if (newValue) {
        window.scrollTo(0, 0);
      }
    },
  },
  created() {
    Segment.trackUserGot('ExperimentAssignment', {
      experimentId: 'cred-input-name',
      treatment: this.useNewCredNameText ? 'nickname' : 'control',
    });
    // todo-yy - remove once we finish migration
    this.fields = this.source.fields || this.source.credentials.fields;
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '../../scss/mixins';

.credentials-details {
  width: 100%;
  background-color: $riseup_white;

  .error-bar {
    @include responsive-padding;

    .bold-text {
      font-weight: bold;
    }
  }

  .page-content {
    padding: 20px;
    @include responsive-padding;

    .image-wrapper {
      margin-bottom: 20px;

      .logo-image {
        height: 40px;
      }

      .account-owner-name {
        font-size: 20px;
      }
    }

    .title {
      margin-bottom: 20px;
      color: $riseup_black;
      font-size: 18px;
      line-height: 1.44;

      .bold {
        font-weight: bold;
      }
    }

    .tool-tip-wrapper {
      margin-bottom: 35px;
    }

    .input-container {
      margin-bottom: 30px;
    }

    .cred-name-disclaimer {
      margin: $ri-spacing-xxs $ri-spacing-xs $ri-spacing-ml 0;
    }

    .button-wrapper {
      .save-button {
        width: 100%;
      }
    }
  }
}

</style>
