<template>
  <insight :insight="insight"
           :image-name="'blue-flower.svg'"
           button-text="לחשב תזרים מחדש"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div class="ri-title">
        לאור המצב, הנה 5 צעדים שיעזרו לך להתאים את התזרים למציאות החדשה
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import MonthOpeningExperienceInsightPopup from './components/MonthOpeningExperienceInsightPopup.vue';
import Insight from './components/Insight';

export default {
  name: 'MonthOpeningExperienceInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object, require: true },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('insights', ['markAsRead']),
    async buttonAction() {
      this.openModal({
        component: MonthOpeningExperienceInsightPopup,
        props: { insight: this.insight },
      });
    },
    closeAction() {
      Segment.trackUserInteraction('MonthOpeningExperienceInsightPopup_Closed', this.insight);
      this.markAsRead(this.insight);
      this.closeModal();
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
