import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import router from '@/router';

const state = {
  backgroundColorVariant: undefined,
  textColor: undefined,
  logoColor: undefined,
  sticky: true,
  showLogo: true,
  bottomBorder: true,
  rightActions: undefined,
  leftActions: undefined,
};

const DEFAULT_HEADER_PROPS = {
  backgroundColorVariant: BaseUI.LayoutConsts.COLOR_VARIANT.WHITE,
  textColor: BaseUI.Colors.riseupBlack,
  logoColor: BaseUI.Colors.riseupYellow,
  sticky: true,
  showLogo: true,
  bottomBorder: true,
  rightActions: [{
    type: BaseUI.HeaderActions.BACK,
    onClick: () => {
      if (router.canGoBack(router.currentRoute)) {
        router.back();
      } else {
        router.push({ path: '/sr', query: router.currentRoute.query });
      }
    },
  }],
  leftActions: undefined,
};

const getters = {
  pageHeaderProps: state => _.defaults({}, state, DEFAULT_HEADER_PROPS),
};

const actions = {
  setAppHeaderProps({ dispatch, commit }, props) {
    commit('setAppHeaderProps', props);
  },
  resetAppHeaderProps({ commit }) {
    commit('setAppHeaderProps', {
      backgroundColorVariant: undefined,
      textColor: undefined,
      logoColor: undefined,
      sticky: true,
      showLogo: true,
      bottomBorder: true,
      rightActions: undefined,
      leftActions: undefined,
    });
  },
};

const mutations = {
  setAppHeaderProps(state, props) {
    state.backgroundColorVariant = props.backgroundColorVariant;
    state.textColor = props.textColor;
    state.logoColor = props.logoColor;
    state.sticky = props.sticky;
    state.showLogo = props.showLogo;
    state.bottomBorder = props.bottomBorder;
    state.rightActions = props.rightActions;
    state.leftActions = props.leftActions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
