<template>
  <div class="info-tool-tip-help-popup">
    <popup :buttons="buttons" :close-action="this.closePopup">
      <template v-slot:title>
        <div class="cx-dude" v-if="cxDude">
          <img class="cx-dude-picture" :src="require(`@/assets/cx-avatars/${ cxDude.name }-avatar@3x.png`)">
          <div class="cx-dude-details">
            <span class="bold">{{ cxDude.displayName }}</span><br>
            צוות חווית לקוח.ה
          </div>
        </div>
        <div class="popup-title">{{ title }}</div>
      </template>
      <template v-slot:content>
        <div class="description">
          <slot name="description"/>
        </div>
      </template>
    </popup>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import Segment from '../../Segment';

export default {
  name: 'InfoToolTipHelpPopup',
  components: {
    Popup: BaseUI.Popup,
  },
  props: {
    sourceName: {
      type: String,
    },
    popupName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    cxDude: {
      type: Object,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('InfoToolTipHelpPopupShown', { popupPage: this.popupName, sourceName: this.sourceName });
  },
  computed: {
    buttons() {
      return [
        {
          title: 'הבנתי, תודה',
          action: this.closePopup,
          size: 'slim',
        },
      ];
    },
  },
  methods: {
    closePopup() {
      Segment.trackUserInteraction('InfoToolTipHelpPopupClosed', { popupPage: this.popupName, sourceName: this.sourceName });
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .info-tool-tip-help-popup {

    .action-icon {
      margin-bottom: 15px;
    }

    .cx-dude {
      display: flex;
      align-items: center;

      .cx-dude-picture {
        height: 36px;
        width: 36px;
        margin-left: 12px;
      }
      .cx-dude-details {
        margin-top: 4px;
        font-family: SimplerPro;
        font-size: 13px;
        line-height: 15px;
        color: $riseup_gray_2;
        .bold {
          font-weight: bold;
          color: $riseup_black;
        }
      }
    }

    .popup-title {
      margin: 20px 0 20px 0;
      font-family: SimplerPro;
      font-weight: bold;
      text-align: right;
      font-size: 20px;
      line-height: 24px;
      align: right;
    }

    .description {
      margin-bottom: 45px; // TODO reconsider
      line-height: 20px;
      font-size: 15px;
      text-align: right;
      .bold {
        font-weight: bold;
      }
    }
  }

</style>
