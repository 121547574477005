<template>
  <div class="checked-items" :style="{ '--dark': flavours[flavour].dark, '--light':  flavours[flavour].light }">
    <div v-for="(item, i) of items" :key="i" class="row flex-row align-center">
      <div class="circle-container padding-vertical-s">
        <div class="circle" :class="{checked: item.checked}">
          <div v-if="item.checked" class="padding-top-xxs">
            <icon icon-name="Check" fill size="24px" color="white" :optical-size="24"/>
          </div>
        </div>
      </div>
      <div class="text ri-large-bold-body padding-horizontal-sm" :class="{checked: item.checked}">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'CheckedItems',
  components: {
    Icon: BaseUI.Icon,
  },
  props: {
    items: {
      type: Array,
      required: true,
      validator: items => items.every(item => typeof item.text === 'string' && typeof item.checked === 'boolean'),
    },
    flavour: {
      type: String,
      default: 'blue',
      required: false,
    },
  },
  data() {
    return {
      flavours: {
        blue: {
          dark: BaseUI.Colors.riseupDarkBlue,
          light: BaseUI.Colors.riseupLightBlue,
        },
        green: {
          dark: BaseUI.Colors.riseupGreen,
          light: BaseUI.Colors.riseupLightGreen,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.checked-items{
  display: flex;
  flex-direction: column;

  .circle-container{
    background: var(--light);
    padding-left: 5px;
    padding-right: 5px;

    .circle{
      display: flex;
      justify-content: center;
      align-items: center;
      background: $riseup_white;
      border-radius: 50%;
      height: $ri-spacing-l;
      width: $ri-spacing-l;

      &.checked{
        background: var(--dark);
      }

      .checked-icon-container {
        padding-top: 4px;
      }
    }
  }

  .row:first-child {
    .circle-container {
      border-top-right-radius: $ri-spacing-l;
      border-top-left-radius: $ri-spacing-l;
    }

    .circle-container {
      padding-top: 6px;
    }
  }

  .row:last-child {
    .circle-container {
      border-bottom-right-radius: $ri-spacing-l;
      border-bottom-left-radius: $ri-spacing-l;
    }

    .circle-container {
      padding-bottom: 6px;
    }
  }

  .text{
    &.checked {
      color: var(--dark),
    }
  }

}
</style>
