<template>
  <div class="plan-amount-and-date-page">
    <div class="container">
      <div class="headline">{{ currentPlan.name }}</div>
      <div class="input-title">כמה נראה לך שצריך?</div>
      <amount-input v-model="targetAmount" :error-message="amountErrorMsg" label="" autofocus
                    hint="לא חייבים את הסכום המדוייק כרגע, תמיד אפשר לחזור ולערוך"/>
      <a v-if="enablePlanAheadAddExistingFund" class="clickable-title margin-top-sm" @click="openPopup" tabindex="0" @keyup.enter="openPopup">
        יש לך כבר חלק מהכסף &gt;
      </a>
      <div class="date-title">למתי בערך?</div>
      <date-picker @select="setDeadline"/>
      <div class="bottom-content">
        <monthly-amount-container
                          :isLoading="loading"
                          :calculated-monthly-amount="calculatedMonthlyAmount"
                          :is-current-plan-on-track="isCurrentPlanOnTrack"
                          :totalRemaining="totalRemaining"
                          :negativeCashflowExperience="cashflowIsNegative" />

        <riseup-button class="button" :disabled="saveDisabled"
                       :title="buttonText" size="slim" variant="primary" :action="onDone" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import { mapActions, mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import Segment from '@/Segment';
import moneyUtils from '@/utils/money';
import dateUtils from '@/utils/dates';
import PlanExistingFundPopup from './PlanExistingFundPopup.vue';
import MonthlyAmountContainer from '../MonthlyAmountContainer';
import DatePicker from '../DatePicker';

export default {
  name: 'PlanAmountAndDatePage',
  components: {
    MonthlyAmountContainer,
    DatePicker,
    AmountInput: BaseUi.AmountInput,
    RiseupButton: BaseUi.RiseupButton,
  },
  data() {
    return {
      targetAmount: null,
      deadline: null,
      loading: false,
      debounceMonthlyCalcFetch: null,
    };
  },
  props: {
    plan: {
      type: Object,
      require: false,
    },
  },
  created() {
    if (this.currentPlan.targetAmount && this.currentPlan.deadline) {
      this.targetAmount = this.currentPlan.targetAmount;
      this.deadline = this.currentPlan.deadline;
    }
    this.debounceMonthlyCalcFetch = _.debounce(async newVal => {
      if (newVal) {
        await this.handleMonthlyCalculation();
      }
    }, 500);
  },
  computed: {
    ...mapState('planAhead', ['calculatedMonthlyAmount', 'currentPlan']),
    ...mapGetters('planAhead', ['totalPlansMonthlyAmount']),
    ...mapGetters('cashflowView', ['riseupGoal', 'predictedBalanceAtEndOfMonth']),
    ...mapGetters('featureFlags', ['enablePlanAheadAddExistingFund']),
    cashflowIsNegative() {
      return this.predictedBalanceAtEndOfMonth < 0;
    },
    existingPlanMonthlyTarget() {
      return this.plan ? this.plan.monthlyTarget : 0;
    },
    buttonText() {
      return this.plan ? 'סיימתי לערוך' : 'שמירה';
    },
    totalRemaining() {
      const remaining = this.riseupGoal - this.totalPlansMonthlyAmount + this.existingPlanMonthlyTarget;
      return moneyUtils.formatAmountWithFraction(remaining, 0);
    },
    isCurrentPlanOnTrack() {
      return this.totalPlansMonthlyAmount + this.calculatedMonthlyAmount - this.existingPlanMonthlyTarget <= this.riseupGoal;
    },
    amountErrorMsg() {
      const currentAmount = this.currentPlan?.savedAmountObj?.amount;
      if (_.isNumber(currentAmount) && currentAmount > this.targetAmount) {
        return 'אי אפשר לקבוע סכום שהוא פחות ממה שכבר שמת בצד';
      }
      return undefined;
    },
    saveDisabled() {
      return !this.targetAmount
          || !this.deadline
          || this.loading
          || !!this.amountErrorMsg;
    },
  },
  methods: {
    ...mapActions('planAhead', ['getCalculatedMonthlyAmount']),
    ...mapActions('modalRootStore', ['openModal']),
    openPopup() {
      Segment.trackUserInteraction('AddAmountToPlan_Clicked');
      this.openModal({
        component: PlanExistingFundPopup,
        showLastModal: true,
        props: { handleMonthlyCalculation: this.handleMonthlyCalculation, maxAmount: this.targetAmount },
      });
    },
    async setTargetAmount(value) {
      this.targetAmount = value;
      await this.handleMonthlyCalculation();
    },
    async setDeadline(value) {
      this.deadline = dateUtils.formatCashflowDate(value);
      if (this.targetAmount) {
        this.loading = true;
      }
      await this.handleMonthlyCalculation();
    },
    async handleMonthlyCalculation() {
      if (this.deadline && this.targetAmount) {
        await this.setCalculatedMonthlyAmount();
      }
    },
    async setCalculatedMonthlyAmount() {
      const savedAmount = this.currentPlan.savedAmountObj?.amount ?? 0;
      const targetAmount = this.plan ? this.targetAmount - this.plan.currentAmount - savedAmount
        : this.targetAmount - savedAmount;
      const data = { deadline: this.deadline, targetAmount };
      await this.getCalculatedMonthlyAmount(data);
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      Segment.trackUserInteraction(
        'PlanAhead_Flow_AmountPage_OnCalculationChanged',
        { isAble: this.isCurrentPlanOnTrack, cashflowIsPositive: !this.cashflowIsNegative },
      );
    },
    onDone() {
      Segment.trackUserInteraction('PlanAhead_Flow_AmountPage_NextClicked', {
        isAble: this.isCurrentPlanOnTrack,
        hasExistingFund: !!this.currentPlan.savedAmountObj,
        hasExistingFundComment: !!this.currentPlan.savedAmountObj?.comment,
      });
      this.$emit('done', { targetAmount: this.targetAmount, deadline: this.deadline });
    },
  },
  watch: {
    targetAmount(newVal) {
      if (this.deadline) {
        this.loading = true;
      }
      this.debounceMonthlyCalcFetch(newVal);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.plan-amount-and-date-page {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;

  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 24px;
    text-align: start;

    .clickable-title {
      font-size: 16px;
      line-height: 24px;
      color: $riseup_blue;
      padding-bottom: 30px;
      cursor: pointer;
    }

    .headline {
      height: 75px;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-size: 16px;
    }

    .input-title {
      font-size: 20px;
      line-height: 28px;
    }

    .date-title {
      font-size: 20px;
      line-height: 28px;
      padding-bottom: 8px;
    }

    .amount-input {
      margin-top: -8px;
      margin-bottom: 0px;
    }

    .amount-note {
      font-size: 14px;
      line-height: 16px;
      color: $riseup_gray_2;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    .amount {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 44px;
      color: $riseup_gray_2;
    }

    .bottom-content {
      margin-top: 40px;
    }

    .button {
      margin-bottom: 40px;
    }
  }
}

</style>
