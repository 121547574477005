<template>
  <div class="intercom-tool-tip">
    <action-tool-tip icon-path="/images/tooltip_chat_icon.svg"
      :text="text"
      :action-text="'הצוות שלנו ישמח לעזור לך בצ׳אט.'"
      :handle-action="openIntercom" >
    </action-tool-tip>
  </div>
</template>

<script>
import ActionToolTip from '@/base-components/onboarding/ActionToolTip';
import Intercom from '@/Intercom';
import Segment from '@/Segment';

export default {
  name: 'IntercomToolTip',
  components: { ActionToolTip },
  props: {
    text: {
      type: String,
    },
    message: {
      type: String,
    },
    eventSource: {
      type: String,
      required: true,
    },
    handleAction: {
      type: Function,
    },
  },
  async created() {
    Segment.trackUserInteraction('IntercomToolTip_Shown', { eventSource: this.eventSource });
  },
  methods: {
    openIntercom() {
      Segment.trackUserInteraction('IntercomToolTip_Clicked', { eventSource: this.eventSource });
      if (this.handleAction) {
        this.handleAction();
      }
      Intercom.showNewMessage(this.message);
    },
  },
};
</script>

<style scoped>

</style>
