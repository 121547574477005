import { client } from '../http';

async function moveExpenseToFixed(transactionId, monthsDiff, expenseCategory) {
  return await client.post('/api/investigator/answers/budget-category', { transactionId, budgetCategory: 'fixed', monthsDiff, expenseCategory });
}

async function excludeTransaction(transactionId) {
  return await client.post('/api/investigator/answers/budget-category', { transactionId, budgetCategory: 'excluded' });
}

async function moveToVariables(transactionId) {
  return await client.post('/api/investigator/answers/budget-category', { transactionId, budgetCategory: 'variable' });
}

async function unexcludeTransaction(transactionId) {
  return await client.post('/api/investigator/answers/unexclude-transaction', { transactionId });
}

async function unlinkTransactionFromSequence({ transactionId }) {
  return await client.post('/api/prediction-update/unlink-transaction-from-sequence', { transactionId });
}

export default {
  async cancelEnvelope({ envelopeId, sequenceId }) {
    return await client.post('/api/prediction-update/cancel-envelope', { envelopeId, sequenceId });
  },
  async disableTrackingCategory({ envelopeId, trackingCategoryId }) {
    return await client.post('/api/tracking-category-disable', { trackingCategoryId });
  },
  async updateEnvelopeAmount({ envelopeId, amount, applyOnBudgetDate, sequenceId }) {
    return await client.post('/api/prediction-update/update-amount', { envelopeId, amount, applyOnBudgetDate, sequenceId });
  },
  async ignoreAmountInPrediction({ transactionId, sequenceId }) {
    return await client.post('/api/prediction-update/ignore-amount-in-prediction', { transactionId, sequenceId });
  },
  async updateRiseupGoal({ budgetDate, amount }) {
    return await client.post(
      '/api/prediction-update/update-riseup-goal',
      { budgetDate, amount: amount.toString() },
    );
  },
  async changeCategory({ businessName, transactionId, applyTo, expense }) {
    return await client.post('/api/enrichment-update/save-enrichment', { businessName, transactionId, applyTo, expense });
  },
  async changeTransactionCategory({ trackingCategoryId, matchBy, value }) {
    return client.post('/api/tracking-categories-predicates', {
      trackingCategoryId,
      matchBy,
      value,
    });
  },
  async moveFromTracking({ matchBy, value }) {
    return client.post('/api/tracking-categories-blacklist-predicates', {
      matchBy,
      value,
    });
  },
  async showTrackingCategory({ name, icon, amount, frequency }) {
    return (await client.post('/api/tracking-categories-show/', { name, icon, amount, frequency })).data.trackingCategoryId;
  },
  async moveVariableExpenseToFixed({ transactionId, monthsDiff, expense }) {
    return await moveExpenseToFixed(transactionId, monthsDiff, expense);
  },
  async moveExpenseToFixed({ transactionId, monthsDiff, expense }) {
    return await moveExpenseToFixed(transactionId, monthsDiff, expense);
  },
  async moveIncomeToFixed({ transactionId }) {
    return await moveExpenseToFixed(transactionId);
  },
  async moveFixedToVariable({ transactionId }) {
    return await unlinkTransactionFromSequence({ transactionId });
  },
  async excludeVariableExpense({ transactionId }) {
    return await excludeTransaction(transactionId);
  },
  async excludeVariableIncome({ transactionId }) {
    return await excludeTransaction(transactionId);
  },
  async moveExcludedToVariableExpense({ transactionId }) {
    return await moveToVariables(transactionId);
  },
  async moveExcludedToVariableIncome({ transactionId }) {
    return await moveToVariables(transactionId);
  },
  async unexcludeTransaction({ transactionId }) {
    return await unexcludeTransaction(transactionId);
  },
  async changeEnvelopeExpenseCategory({ envelopeId, sequenceId, expenseCategory, isIncome }) {
    return await client.post('/api/prediction-update/envelope-expense-category', { envelopeId, sequenceId, expenseCategory, isIncome });
  },
  async setTransactionComment({ transactionId, comment }) {
    return await client.post('/api/enrichment-update/save-comments', { transactionId, comment });
  },
  async setSequenceComment({ sequenceId, comment }) {
    return await client.post('/api/enrichment-update/save-sequence-comments', { sequenceId, comment });
  },
  async excludeTransaction({ transactionId }) {
    return await excludeTransaction(transactionId);
  },
  async excludeExpense({ transactionId }) {
    return await excludeTransaction(transactionId);
  },
  async splitTransaction({ transactionId, slices, actionType }) {
    return await client.post('/api/transaction-management', { transactionId, slices, actionType });
  },
  async deactivateTrackingCategory({ trackingCategoryId }) {
    return client.post('/api/tracking-categories-deactivate', { trackingCategoryId });
  },
  async setIncomePredictionAmount({ budgetDate, amount }) {
    return client.post('/api/prediction-update/set-income-prediction-amount', { budgetDate, amount });
  },
  moveToVariables,
};
