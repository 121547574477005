<template>
  <div class="plan-suggestions">
    <div class="input-title">איזו הוצאה גדולה מעסיקה אותך?</div>
    <div class="name-input">
      <default-text-input
        autofocus
        label=""
        :value="planNameAndType.name"
        :error-message="errorMessage"
        @input="onManualInput" />
    </div>

    <div class="tags-title">הצעות שיכולות להתאים לך:</div>
    <div class="margin-bottom-sm">
      <tag v-for="(suggestion, index) in suggestions"
           :key="index"
           :title="suggestion.name"
           :is-selected="planNameAndType.name === suggestion.name"
           @click="() => suggestionPicked(suggestion)" />
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapState, mapActions } from 'vuex';
import Segment from '@/Segment';
import Tag from './Tag';

export default {
  name: 'PlanSuggestions',
  props: {
    initialNameAndType: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      planNameAndType: { name: '', type: undefined },
      errorMessage: '',
    };
  },
  components: {
    DefaultTextInput: BaseUI.DefaultTextInput,
    Tag,
  },
  async created() {
    if (!this.suggestions?.length) {
      await this.initPlansSuggestions();
    }
    if (this.initialNameAndType) {
      this.setPlanNameAndType(this.initialNameAndType);
    }
  },
  computed: {
    ...mapState('planAhead', ['suggestions']),
  },
  methods: {
    ...mapActions('planAhead', ['initPlansSuggestions']),
    suggestionPicked(suggestion) {
      Segment.trackUserInteraction('PlanAhead_Flow_SuggestionClicked', { suggestion: suggestion.type });
      this.setPlanNameAndType(suggestion);
    },
    onManualInput(newValue) {
      this.setPlanNameAndType({ name: newValue, type: 'general' });
    },
    setPlanNameAndType(newValue) {
      this.errorMessage = '';
      this.planNameAndType = newValue;
      this.$emit('change', this.planNameAndType);
    },
  },
};
</script>

<style lang="scss" scoped>
.plan-suggestions {

  text-align: start;

  .name-input {
    margin-top: -13px;
  }

  .input-title {
    margin-top: 15px;
    font-size: 20px;
  }

  .tags-title {
    margin-top: 30px;
    margin-bottom: 2px;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
