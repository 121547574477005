import _ from 'lodash';
import InsightsApi from '../../api/InsightsApi';

const INSIGHT_NAMES = {
  // transaction insights (on specific transactions)
  HighExpense: 'highExpense',
  RelativeHighExpense: 'relativeHighExpense',
  HighExpenseExclusion: 'highExpenseExclusion',
  RelativeExpenseExclusion: 'relativeExpenseExclusion',
  HighIncomeExclusion: 'highIncomeExclusion',
  RelativeIncomeExclusion: 'relativeIncomeExclusion',
  LoanExclusion: 'loanExclusion',
  MightBeFixed: 'mightBeFixed',
  // balance insights (on balance event)
  EconomyBalance: 'economyBalance',
  FixedBalance: 'fixedBalance',
  RecurringBalance: 'recurringBalance',

  ZeroBalance: 'zeroBalance',
  // tracking insight (first 2 days after activation)
  TrackingCategories: 'trackingCategories',
  PredictedVariableIncomeEOMInsight: 'predictedVariableIncomeEOMInsight',
  PredictedVariableIncomeInsight: 'predictedVariableIncomeInsight',
  // savings
  OpportunisticSaving: 'OpportunisticSaving',
  MonthlySaving: 'MonthlySaving',
  HarelMonthlySaving: 'HarelMonthlySaving',
  MeitavOpportunisticSaving: 'MeitavOpportunisticSaving',
  MeitavMonthlySaving: 'MeitavMonthlySaving',
  NewPapasMergeInsight: 'newPapasMergeInsight',
  SonsMergeInsight: 'SonsMergeInsight',
  AllClassifiedPapasMergeInsight: 'allClassifiedPapasMergeInsight',
  BalanceMoneyInsight: 'balanceMoneyInsight',
  // sumsum
  DemographicInfo: 'demographicInfo',
  ReferralMissingPaymentDetails: 'referralMissingPaymentDetails',
  // fees insight (first 2 weeks after activation)
  FeesInsight: 'feesInsight',
  NegativeBalanceInsight: 'negativeBalanceInsight',
  RiseupGoalInsight: 'riseupGoalInsight',
  SetMonthlyGoalInsight: 'setMonthlyGoalInsight',
  BalancedMonthlyGoalInsight: 'balancedMonthlyGoalInsight',
  RollingCreditInsight: 'rollingCredit',
  ChallengeInsight: 'challengeInsight',
  JoinAmbassadorsInsight: 'joinAmbassadorsInsight',
  TrackingCategoriesSelectionInsight: 'trackingCategoriesSelectionInsight',
  MissingBankCCsAfterOBKMigrationInsight: 'missingBankCCsAfterOBKMigrationInsight',
  MissingRegularCCsAfterOBKMigrationInsight: 'missingRegularCCsAfterOBKMigrationInsight',
  HeverBenefitInsight: 'heverBenefitInsight',
  PersonalizedInsight: 'personalizedInsight',
  PlanCompletedEnoughMoney: 'PlanCompletedEnoughMoney',
  PlanCompletedNotEnoughMoney: 'PlanCompletedNotEnoughMoney',
  RecalculatePlans: 'RecalculatePlans',
  IndentedIncome: 'indentedIncome',
  Advisory: 'advisory',
  PromoteAnnual: 'promoteAnnual',
  PromoteSemiAnnual: 'promoteSemiAnnual',
  CashflowStartDay: 'cashflowStartDayInsight',
  MonthOpeningExperienceInsight: 'monthOpeningExperienceInsight',
  BoxToSavingsInsight: 'boxToSavingsInsight',
  MyProgressHowMuchISavedInsight: 'MyProgressHowMuchISaved',
  InsightsCenterInsight: 'insightsCenterInsight',
};

const HIDDEN_INSIGHTS = [];
const KNOWN_INSIGHTS = _.difference(_.values(INSIGHT_NAMES), HIDDEN_INSIGHTS);

const EXCLUSION_INSIGHTS = [
  INSIGHT_NAMES.LoanExclusion,
  INSIGHT_NAMES.HighIncomeExclusion,
  INSIGHT_NAMES.HighExpense,
  INSIGHT_NAMES.RelativeIncomeExclusion,
  INSIGHT_NAMES.RelativeHighExpense,
];

const state = {
  initializedInsights: false,
  insights: [],
};

const getters = {
  INSIGHT_NAMES: () => INSIGHT_NAMES,
  EXCLUSION_INSIGHTS: () => EXCLUSION_INSIGHTS,
  filteredInsights: (state, _, __, rootGetters) => state.insights.filter(insight => !insight.featureFlag
    || rootGetters['featureFlags/isFlagIsOn'](insight.featureFlag)),
};

const actions = {
  async initInsights({ commit, state }) {
    if (state.initializedInsights) {
      return;
    }
    const insights = await InsightsApi.fetchInsights();
    const knownInsights = insights.filter(insight => KNOWN_INSIGHTS.includes(insight.insightName))
      // This is a temporary change and we should remove after 1 day. We are changing the way the insights look. To prevent races, filter out old
      // version insights (that dont have the 'data' field).
      .filter(insight => !(insight.insightName === INSIGHT_NAMES.PersonalizedInsight && _.isNil(insight.config?.data)));
    commit('setInsights', knownInsights);
    commit('setInitialized', true);
  },
  async addInsight({ commit, state }, insight) {
    commit('setInsights', [...state.insights, insight]);
  },
  async clearInsight({ commit }, insight) {
    commit('clearInsight', insight.insightId);
  },
  async markAsRead({ commit }, insight) {
    commit('clearInsight', insight.insightId);
    // eslint-disable-next-line
    InsightsApi.sendInsightEvent(insight, 'read');
    await InsightsApi.markAsRead(insight.insightId, insight.insightName, insight, false);
  },
  async dismiss({ commit }, insight) {
    commit('clearInsight', insight.insightId);
    // eslint-disable-next-line
    InsightsApi.sendInsightEvent(insight, 'dismissed');
    await InsightsApi.markAsRead(insight.insightId, insight.insightName, insight, true);
  },
  async snooze({ commit }, { insight, timeAmount, timeType }) {
    commit('clearInsight', insight.insightId);
    await InsightsApi.snooze(insight, timeAmount, timeType);
  },
  async sendInsightFeedback({ commit }, { insight, feedback }) {
    await InsightsApi.sendInsightFeedback(insight.insightId, insight.insightName, insight, feedback);
  },
  async reactiveInsights({ commit, state, rootGetters }) {
    commit('setInsights', state.insights.filter(insight => insight.insightName !== INSIGHT_NAMES.SetMonthlyGoalInsight
      || rootGetters['cashflowView/riseupGoal'] > 0));
  },
};

// mutations
const mutations = {
  setInsights(state, insights) {
    state.insights = insights;
  },
  clearInsight(state, insightId) {
    const indexToSlice = _.findIndex(state.insights, insight => insight.insightId === insightId);
    if (indexToSlice >= 0) {
      state.insights.splice(indexToSlice, 1);
    }
  },
  setInitialized(state, initialized) {
    state.initializedInsights = initialized;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
