<template>
  <div class="plan-deposits">
    <transaction v-for="transaction in deposits"
                 class="plan-deposits-transaction"
                 :key="transaction._id"
                 :transaction="depositTransaction(transaction)"
                 :can-edit-category="false"/>
  </div>
</template>

<script>

import cashflowHistory from '@/store/utilities/cashflow-history';
import dateUtils from '@/utils/dates';
import moneyUtils from '@/utils/money';

export default {
  name: 'PlanDeposits',
  components: {
    Transaction: () => import('../../cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/Transaction'),
  },
  props: {
    deposits: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(created) {
      const actualBudgetDate = cashflowHistory.getPrevBudgetDate(created);
      return dateUtils.getShortMonthAndYear(actualBudgetDate);
    },
    formatAmount(amount) {
      return moneyUtils.formatAmountWithFraction(amount, 0);
    },
    depositTransaction(transaction) {
      return {
        billingAmount: transaction.amount,
        transactionDate: transaction.created,
        customerComment: transaction.comment,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
@import "~@riseupil/base-ui/src/scss/spacings";

.plan-deposits {
  border-collapse: collapse;
  width: 100%;
  text-align: right;

  .plan-deposits-transaction {
    min-height: 61px;
    background: $riseup_gray_01;
  }
}
</style>
