const ACTIONS = {
  SETUP_ACCOUNTS: 'setup_accounts',
  FIRST_CF: 'first_cf',
  PREDICTED_VARIABLE_INCOME_CHECK: 'predicted_variable_income_check',
  FIXED_CHECK: 'fixed_check',
  SET_CHALLENGE: 'set_challenge',
  SET_RISEUP_GOAL: 'set_riseup_goal',
  OPEN_PLAN_AHEAD_PLAN: 'open_plan_ahead_plan',
  INVITE_MEMBER: 'invite_member',
  GO_TO_CHAT: 'go_to_chat',
  GO_TO_COMMUNITY: 'go_to_community',
  ADD_CATEGORIES: 'add_categories',
};

export default ACTIONS;
