import moment from 'moment';
import * as _ from 'lodash';
import DDLogs from '@/DDLogs';
import subscriptionStateApi from '../../api/SubscriptionStateApi';

import { TRIAL_PERIODS, SUBSCRIPTION_STATES } from '../../constants/subscription-state';

const LOCAL_STORAGE_KEY = 'riseup.subscriptionCtaDismissedData';
const LOCAL_STORAGE_KEY_MBG = 'riseup.subscriptionMbgCtaRemindMe';

const hoursToShowMbgOffer = 24 * 2;
const hoursForDismissButton = 12;

const state = {
  subscriptionState: null,
  since: null,
  until: null,
  untilWithPromo: null,
  moneyBackGuaranteeRecord: null,
  dormantWithMBGOffer: null,
  eoyDismissed: false,
};

function _shouldShowCta({ inTrial, trialPeriod }) {
  if (inTrial) {
    const subscriptionDismissedDataString = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (subscriptionDismissedDataString) {
      const { periodDismissed } = JSON.parse(subscriptionDismissedDataString);
      if (!periodDismissed) {
        return true;
      }
      const currentTrialPeriod = trialPeriod;
      return currentTrialPeriod !== periodDismissed;
    }
    return true;
  }
  return false;
}

function _hasMoneyBackOffer({ response, inTrial, mbgOfferEndDate, dormantWithMBGOffer }) {
  return dormantWithMBGOffer
    || (!_.isEmpty(response)
      && (_.isNil(response.acceptedOffer))
      && inTrial
      && moment().isBefore(mbgOfferEndDate));
}

function _mbgCtaDismissed() {
  const remindMeLaterInput = localStorage.getItem(LOCAL_STORAGE_KEY_MBG);
  if (!remindMeLaterInput) {
    return false;
  }
  const { dismissDate } = JSON.parse(remindMeLaterInput);
  if (!dismissDate) {
    return false;
  }
  return _dismissedRecently(dismissDate);
}

function _dismissedRecently(dismissDate) {
  return moment().diff(moment(dismissDate), 'hours') < hoursForDismissButton;
}

const getters = {
  daysLeft: state => Math.max(moment(state.until).diff(moment(), 'days') + 1, 0),
  daysLeftIncludingPromotion: state => Math.max(moment(state.untilWithPromo).diff(moment(), 'days') + 1, 0),
  trialLength: state => moment(state.until).diff(moment(state.since), 'days'),
  trialPeriod: (state, getters) => {
    if (getters.inTrial) {
      const diffWithUntil = moment(state.until).diff(moment(), 'days');
      if (diffWithUntil > 20) {
        return TRIAL_PERIODS.BEGINNING;
      } if (diffWithUntil <= 20 && diffWithUntil > 7) {
        return TRIAL_PERIODS.MIDDLE;
      }
      // 7 days left for the trial
      return TRIAL_PERIODS.FINAL;
    }
    return undefined;
  },
  inTrial: state => state.subscriptionState === SUBSCRIPTION_STATES.TRIAL,
  isFree: state => state.subscriptionState === SUBSCRIPTION_STATES.FREE,
  subscribed: state => state.subscriptionState === SUBSCRIPTION_STATES.SUBSCRIBED,
  canceled: state => state.subscriptionState === SUBSCRIPTION_STATES.CANCELED,
  dormantAfterCanceled: state => state.subscriptionState === SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED,
  preTrial: state => state.subscriptionState === SUBSCRIPTION_STATES.PRE_TRIAL,
  mbgOfferEndDate: state => {
    if (state.moneyBackGuaranteeRecord) {
      return moment(state.moneyBackGuaranteeRecord.created).add(hoursToShowMbgOffer, 'hour');
    }
    return null;
  },
  shouldShowCta: (state, getters) => _shouldShowCta({
    inTrial: getters.inTrial,
    trialPeriod: getters.trialPeriod,
  }),
  hasMoneyBackOffer: (state, getters) => _hasMoneyBackOffer({
    response: state.moneyBackGuaranteeRecord,
    inTrial: getters.inTrial,
    mbgOfferEndDate: getters.mbgOfferEndDate,
    dormantWithMBGOffer: state.dormantWithMBGOffer,
  }),
  hasAcceptedMoneyBackOffer: state => state.moneyBackGuaranteeRecord && state.moneyBackGuaranteeRecord.acceptedOffer,
  isMbgEnded: (state, getters) => {
    if (getters.hasAcceptedMoneyBackOffer) {
      return moment().isAfter(state.moneyBackGuaranteeRecord.endOfRefundEligibilityPeriod);
    }
    return true;
  },
  showMbgPopup: () => !_mbgCtaDismissed(),
  isDormant: state => _.includes([SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL, SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED], state.subscriptionState),
  isDormantWithoutMGB: (state, getters) => getters.isDormant && !getters.hasMoneyBackOffer,
  subscriptionMode: (state, getters) => (getters.subscribed ? 'update' : 'create'),
};

const actions = {
  async initEouDismissed({ commit }) {
    commit('setEoyDismissed', (localStorage.getItem('eoy-sale-dismissed') === 'true'));
  },
  async initSubscriptionState({ dispatch }) {
    await dispatch('fetchSubscriptionState');
  },
  async fetchSubscriptionState({ commit }) {
    const subscriptionState = await subscriptionStateApi.fetchSubscriptionState();
    const moneyBackGuaranteeRecord = await subscriptionStateApi.fetchMoneyBackGuaranteeRecord();
    commit('setSubscriptionState', subscriptionState.state);
    commit('setSince', subscriptionState.since);
    commit('setUntil', subscriptionState.until);
    commit('setUntilWithPromo', subscriptionState.untilWithPromo);
    commit('setMoneyBackGuaranteeRecord', moneyBackGuaranteeRecord);
  },
  async setSubscriptionStateVariables({ commit }, subscriptionStateVariables) {
    commit('setSubscriptionStateVariables', subscriptionStateVariables);
  },
  ctaDismissed({ getters }) {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({ dismissDate: new Date(), periodDismissed: getters.trialPeriod }));
    } catch (error) {
      DDLogs.error(error);
    }
  },
  ctaDismissedMbg({ getters }) {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY_MBG, JSON.stringify({ dismissDate: new Date() }));
    } catch (error) {
      DDLogs.error(error);
    }
  },
};

// mutations
const mutations = {
  setSubscriptionState(state, subscriptionState) {
    state.subscriptionState = subscriptionState;
  },
  setEoyDismissed(state, eoyDismissed) {
    state.eoyDismissed = eoyDismissed;
  },
  setSince(state, since) {
    state.since = since;
  },
  setUntil(state, until) {
    state.until = until;
  },
  setUntilWithPromo(state, untilWithPromo) {
    state.untilWithPromo = untilWithPromo;
  },
  setMoneyBackGuaranteeRecord(state, response) {
    state.moneyBackGuaranteeRecord = response;
  },
  setSubscriptionStateVariables(state, { subscriptionState, moneyBackGuarantee }) {
    state.subscriptionState = subscriptionState?.state ?? null;
    state.since = subscriptionState?.since ?? null;
    state.until = subscriptionState?.until ?? null;
    state.untilWithPromo = subscriptionState?.untilWithPromo ?? null;
    state.moneyBackGuaranteeRecord = moneyBackGuarantee;
  },
  setDormantWithMBGOffer(state, isDormantWithMBGOffer) {
    state.dormantWithMBGOffer = isDormantWithMBGOffer;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
