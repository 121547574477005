import moment from 'moment';
import ChallengesApi from '@/api/ChallengesApi';
import { OFFERING_TYPES } from '@/api/BillyApi';
import DDLogs from '@/DDLogs';
import { SIGNUP_OFFERING_IDS } from './oneOff-Offerings';

export default class HundredDays {
  id = SIGNUP_OFFERING_IDS.HUNDRED_DAYS;

  total = 0;

  subscriptionStartDate = moment();

  offeringCard = {
    buttonCtaIcon: 'groups_2',
    buttonCtaIconSize: 24,
    buttonCtaText: 'להצטרף לתוכנית',
    moreInfoLink: 'https://www.riseup.co.il/100-days',
    offeringBullets: [
      'תוכנית אימון למשך 100 יום',
      'תוכן בלעדי בנושאי התזרים',
      'טיפים לשינוי הרגלים',
      'תזרים חודשי אוטומטי',
    ],
    isOneOffPayment: true,
    price: 0,
  };

  purchaseInfo() {
    return {
      buttonText: 'הרשמה והתחלת תקופת נסיון',
      details: {
        offerId: '100-days',
        title: 'תוכנית ליווי',
        subTitle: 'תוכנית אימון למשך 100 יום',
        amount: 0,
        type: OFFERING_TYPES.ONE_OFF,
      },
    };
  }

  insertPaymentDetailsPage = {
    title: 'לרכישת התוכנית',
    buttonCtaText: 'לאישור התשלום',
  };

  successPurchasePageInfo = {
    checkedItems: [{
      text: 'הרשמה לרייזאפ',
      checked: true,
    }, {
      text: 'הצטרפות לתוכנית',
      checked: true,
    }, {
      text: 'חיבור חשבון בנק וקבלת תזרים',
      checked: false,
    }, {
      text: 'התחלת התוכנית',
      checked: false,
    }],
  };

  constructor() {
    this.challengePromise = ChallengesApi
      .getUpcomingChallengeOfType(this.id)
      .then(challenge => { this.challenge = challenge; })
      .catch(error => { DDLogs.error(`Failed to get ${this.id} challenge`, { error }); });
  }

  async isAvailable({ offerIds, featureFlagAvailableOfferings }) {
    if (!offerIds.includes(this.id) && !featureFlagAvailableOfferings.includes(this.id)) {
      return false;
    }

    await this.challengePromise;
    return !!this.challenge;
  }

  async startDate() {
    await this.challengePromise;
    return this.challenge?.startDate;
  }
}
