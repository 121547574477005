<template>
  <arrow-selection-item class="account-selection-item"
                        :image-source="require(`@/assets/bank-logos/${source.logoFile}`)"
                        image-size="large"
                        @click="onSelection(source.sourceName)">
  </arrow-selection-item>
</template>

<script>
import ArrowSelectionItem from '../ArrowSelectionItem';

export default {
  name: 'AccountSelectionItem',
  components: { ArrowSelectionItem },
  props: {
    source: {
      type: Object,
      required: true,
    },
    onSelection: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

.account-selection-item {
  .logo-image {
    height: 40px;
  }
}

</style>
