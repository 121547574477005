const BROWSER_NAMES = {
  CHROME: 'Chrome',
  SAFARI: 'Safari',
};

async function isIncognitoMode() {
  const browser = getBrowser();
  return await _isIncognito(browser);
}

async function _isIncognito(browser) {
  if (browser === BROWSER_NAMES.CHROME) {
    return _isIncognitoChrome();
  }

  if (browser === BROWSER_NAMES.SAFARI) {
    return _isIncognitoSafari();
  }

  return 'unknown';
}

function getBrowser() {
  if (_isChrome()) {
    return BROWSER_NAMES.CHROME;
  }

  if (_isSafari()) {
    return BROWSER_NAMES.SAFARI;
  }

  return 'other';
}

function _isChrome() {
  const isChromiumBrowser = navigator.vendor?.indexOf('Google') === 0;
  // https://chromium.googlesource.com/chromium/src.git/+/HEAD/docs/ios/user_agent.md
  const isChromeBrowserInIOS = navigator.userAgent.match(/CriOS/);
  const userAgent = navigator?.userAgent;

  if (!isChromiumBrowser && !isChromeBrowserInIOS) {
    return false;
  }

  if (navigator.brave || userAgent.match(/Edg/) || userAgent.match(/OPR/)) {
    return false;
  }

  return true;
}

function _isSafari() {
  return navigator.vendor?.indexOf('Apple') === 0;
}

// This function uses the `webkitTemporaryStorage` API to query the storage quota
// in incognito mode. If the quota is below a certain threshold compared to the
// normal mode quota, it indicates that the browser is in incognito mode.
async function _isIncognitoChrome() {
  try {
    const { webkitTemporaryStorage } = navigator;
    if (webkitTemporaryStorage && webkitTemporaryStorage.queryUsageAndQuota) {
      const quota = await new Promise((resolve, reject) => {
        webkitTemporaryStorage.queryUsageAndQuota(
          (_, quota) => resolve(quota),
          reject,
        );
      });

      const quotaInMib = Math.round(quota / (1024 * 1024));
      const quotaLimitInMib = Math.round(_getQuotaLimit() / (1024 * 1024)) * 2;

      return quotaInMib < quotaLimitInMib;
    }
  } catch (error) {
    // Ignore the error
  }

  return 'unknown';
}

// This function uses the behavior of IndexedDB and Blobs in Safari's incognito mode
// to determine if the user is browsing privately. It creates a new IndexedDB database
// with a random name and attempts to add a Blob object to an object store. If the
// addition succeeds, it indicates normal browsing mode. If an exception is thrown
// with an error message indicating that BlobURLs are not supported, it suggests
// incognito mode.
//
// Please note that this approach relies on the specific behavior of IndexedDB and
// Blobs in Safari's incognito mode, which may change in future browser updates.
async function _isIncognitoSafari() {
  return new Promise(resolve => {
    const tmpName = String(Math.random());
    try {
      const db = window.indexedDB.open(tmpName, 1);
      // triggered when a new DB is created
      db.onupgradeneeded = async event => {
        const res = event.target?.result;
        try {
          await res.createObjectStore('test', { autoIncrement: true }).put(new Blob());
          resolve(false);
        } catch (error) {
          const message = error instanceof Error ? error.message : error;
          if (typeof message === 'string') {
            resolve(/BlobURLs are not yet supported/.test(message));
          } else {
            resolve(false);
          }
        } finally {
          res.close();
          window.indexedDB.deleteDatabase(tmpName);
        }
      };
    } catch {
      resolve(false);
    }
  });
}

function _getQuotaLimit() {
  const { performance } = window;
  return performance?.memory?.jsHeapSizeLimit || 1073741824;
}

module.exports = {
  isIncognitoMode,
  getBrowser,
  BROWSER_NAMES,
};
