<template>
  <img class="prefetch-image" :src="imgSrc"/>
</template>

<script>
export default {
  name: 'ImagePrefetch',
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
  .prefetch-image {
    width: 0;
    height: 0;
  }
</style>
