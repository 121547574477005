<template>
  <toaster @close-toaster="closeTransactionMovedToaster" class="transaction-moved-toaster">
    <template v-slot:text>
      <div>
        <span v-if="!assignedToPlan">ה{{categrotyTypeName}} עברה ל<span class="move-to-category-name">{{destinations}}</span></span>
        <span v-else>ההוצאה נוספה לתוכנית המתאימה</span>
      </div>
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../base-components/Toaster';

export default {
  name: 'TransactionMovedToaster',
  components: {
    Toaster,
  },
  props: {
    changedTransaction: {
      type: Object,
      required: true,
    },
    targetCategory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    categrotyTypeName() {
      return this.changedTransaction.isIncome ? 'הכנסה' : 'הוצאה';
    },
    categrotyTypePlural() {
      return this.changedTransaction.isIncome ? 'הכנסות' : 'הוצאות';
    },
    assignedToPlan() {
      return this.targetCategory.planAheadCategory;
    },
    destinations() {
      if (this.changedTransaction.isSaving) {
        return this.targetCategory.pluralLabel;
      }
      return `${this.categrotyTypePlural} ${this.targetCategory.pluralLabel}`;
    },
  },
  methods: {
    closeTransactionMovedToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
.transaction-moved-toaster{
  .move-to-category-name{
    font-weight: bold;
  }
}

</style>
