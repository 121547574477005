<template>
  <insight :insight="insight"
           :animation-name="insight.isIncome ? 'might-be-fixed-income.json' : 'might-be-fixed-expense.json'"
           :button-text="'פרטים נוספים ועדכון'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>זיהינו {{ flavour }} חדשה מ<span class="business-name">{{ insight.businessName }}</span>
        של  <span class="amount">{{ formattedAmount }} ₪</span> ב{{ budgetMonth }}.
        אנחנו חושבים שזאת {{ flavour }} קבועה
        </div>
    </template>
  </insight>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CashflowUpdaterApi from '@/api/CashflowUpdaterApi';
import cashflowViewConsts from '@/constants/cashflow-view';
import TransactionInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/TransactionInsightPopup';
import TransactionSummaryPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/TransactionSummaryPopup';
import EventBus from '@/event-bus/event-bus';
import MightBeFixedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/insights/MightBeFixedToaster';
import moneyUtils from '@/utils/money';
import dateUtils from '@/utils/dates';
import CashflowViewConsts from '@/mixins/CashflowViewConsts';

import Segment from '@/Segment';
import moment from 'moment';
import Insight from './components/Insight';

export default {
  name: 'MightBeFixedInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  mixins: [CashflowViewConsts],
  computed: {
    ...mapState('cashflowView', ['presentedBudgetDate']),
    flavour() {
      return this.insight.isIncome ? 'הכנסה' : 'הוצאה';
    },
    buttons() {
      return [
        {
          text: `זאת באמת ${this.flavour} קבועה`,
          variant: 'primary',
          action: async () => {
            if (this.insight.isIncome) {
              Segment.trackUserInteraction('MightBeFixedIncome_moveToFixedClicked', {
                transactionId: this.insight.transactionId,
                transactionDate: this.insight.transactionDate,
                isInSameMonth: moment().month() === moment(this.insight.transactionDate).month(),
              });

              await this.moveTransactionToFixed({ monthsDiff: 1 });
              this.openToaster(true);
              this.closePopup();
            } else {
              this.openBiMonthlyCheckPopup();
            }
          },
        },
        {
          text: `זאת לא ${this.flavour} קבועה`,
          variant: 'secondary',
          action: async () => {
            this.markAsRead(this.insight);
            this.openToaster(false);
            this.closePopup();
          },
        },
      ];
    },
    canDoOptimisticChange() {
      return this.insight.transactionBudgetDate === this.presentedBudgetDate;
    },
    budgetMonth() {
      return dateUtils.getShortHebrewMonth(this.insight.budgetDate);
    },
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.isIncome ? this.insight.incomeAmount : this.insight.billingAmount, 0);
    },
  },
  methods: {
    ...mapActions('editCashflow', ['moveExpenseToFixed', 'moveIncomeToFixed']),
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    buttonAction() {
      this.openModal({
        component: TransactionInsightPopup,
        props: {
          insight: this.insight,
          insightPrefix: `${this.flavour} משתנה`,
          cashflowCategory: this.insight.isIncome ? cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE_INCOME
            : cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE,
          insightPopupTitle: `אנחנו חושבים שה${this.flavour} הזאת היא קבועה`,
          insightPopupExplanation: 'במידה והיא אכן קבועה, המערכת תצפה אותה גם בחודשים הבאים',
          buttons: this.buttons,
        },
      });
    },
    openBiMonthlyCheckPopup() {
      this.closePopup();
      this.openModal({
        component: TransactionInsightPopup,
        props: {
          insight: this.insight,
          insightPrefix: `${this.flavour} קבועה`,
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
          insightPopupTitle: 'כל כמה זמן ההוצאה הזו הולכת לרדת?',
          backAction: () => {
            this.closePopup();
            this.buttonAction();
          },
          buttons: [{
            text: 'כל חודש',
            variant: 'primary',
            action: () => this.openSummaryPopup(1),
          },
          {
            text: 'פעם בחודשיים',
            variant: 'secondary',
            action: () => this.openSummaryPopup(2),
          },
          ],
        },
      });
    },
    openSummaryPopup(monthsDiff) {
      const isSavingExpense = cashflowViewConsts.FIXED_SAVING_CATEGORY_NAMES.includes(this.insight.expense);
      if (monthsDiff === 1 && isSavingExpense) {
        this.openSavingSummaryPopup();
      } else {
        const expenseCategory = isSavingExpense ? cashflowViewConsts.OTHER_CATEGORY : undefined;
        this.openTransactionSummaryPopup({
          monthsDiff,
          backAction: () => this.openBiMonthlyCheckPopup(),
          expenseCategory,
        });
      }
    },
    openSavingSummaryPopup() {
      this.closePopup();
      this.openModal({
        component: TransactionSummaryPopup,
        props: {
          transaction: this.insight,
          fromCategory: this.variableCategory,
          toCategory: this.fixedSavingCategory,
          backAction: () => this.openBiMonthlyCheckPopup(),
          cancelButton: {
            action: () => this.openTransactionSummaryPopup({
              monthsDiff: 1,
              backAction: () => this.openSavingSummaryPopup(),
              expenseCategory: cashflowViewConsts.OTHER_CATEGORY,
            }),
            text: 'זו לא הפקדה לחיסכון',
          },
          buttons: [{
            title: 'אישור',
            variant: 'primary',
            size: 'slim',
            action: async () => {
              await this.moveTransactionToFixed({ monthsDiff: 1, expenseCategory: cashflowViewConsts.FIXED_SAVING_CATEGORY_NAME });
              this.openToaster(true, cashflowViewConsts.FIXED_SAVING_CATEGORY_NAME);
              this.closePopup();
            },
          }],
        },
      });
    },
    openTransactionSummaryPopup({ monthsDiff, backAction, expenseCategory }) {
      this.closePopup();
      this.openModal({
        component: TransactionSummaryPopup,
        props: {
          transaction: this.insight,
          fromCategory: this.variableCategory,
          toCategory: this.fixedCategory,
          backAction,
          buttons: [{
            title: 'אישור',
            variant: 'primary',
            size: 'slim',
            action: async () => {
              await this.moveTransactionToFixed({ monthsDiff, expenseCategory });
              this.openToaster(true, expenseCategory);
              this.closePopup();
            },
          }],
        },
      });
    },
    async moveTransactionToFixed({ monthsDiff, expenseCategory }) {
      this.markAsRead(this.insight);
      if (this.insight.isIncome) {
        if (this.canDoOptimisticChange) {
          await this.moveIncomeToFixed({ transactionId: this.insight.transactionId });
        } else {
          await CashflowUpdaterApi.moveIncomeToFixed({ transactionId: this.insight.transactionId });
        }
      } else if (this.canDoOptimisticChange) {
        await this.moveExpenseToFixed({ transactionId: this.insight.transactionId, monthsDiff, expense: expenseCategory });
      } else {
        await CashflowUpdaterApi.moveVariableExpenseToFixed({ transactionId: this.insight.transactionId, monthsDiff, expense: expenseCategory });
      }
    },
    closePopup() {
      this.closeModal();
    },
    openToaster(isFixed, expenseCategory) {
      EventBus.$emit('open-toaster', {
        component: MightBeFixedToaster,
        props: {
          isIncome: this.insight.isIncome,
          isFixed,
          isSaving: cashflowViewConsts.FIXED_SAVING_CATEGORY_NAMES.includes(expenseCategory),
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}
.business-name {
  font-weight: bold;
}
</style>
