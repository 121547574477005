import _ from 'lodash';
import { clientWithoutErrorHandling } from '@/http';

async function getAllChallengesData(challengeType) {
  return getActiveChallengesData(challengeType, true, true);
}

async function getActiveChallengesData(challengeType, includeFutureChallenges, includePastChallenges) {
  const queryParams = _.compact([
    challengeType ? `challengeType=${challengeType}` : undefined,
    includeFutureChallenges ? `includeFutureChallenges=${includeFutureChallenges}` : undefined,
    includePastChallenges ? `includePastChallenges=${includePastChallenges}` : undefined,
  ]).join('&');
  const url = '/api/partial-auth/participants/active-challenges-data';
  const urlWithQueryParams = queryParams ? `${url}?${queryParams}` : url;
  const response = await clientWithoutErrorHandling.get(urlWithQueryParams);
  return response.data;
}

async function updateParticipantDailyTaskDone(challengeId) {
  const response = await clientWithoutErrorHandling.put(`/api/partial-auth/participants/challenges/${challengeId}/update-daily-task-done`);
  return response.data;
}

async function removeParticipantCFCard(challengeId) {
  const response = await clientWithoutErrorHandling.put(`/api/partial-auth/participants/challenges/${challengeId}/remove-cf-card`);
  return response.data;
}

async function addChallengesProduct() {
  const response = await clientWithoutErrorHandling.put('/api/partial-auth/enable-challenges');
  return response.data;
}

async function addParticipantToChallenge(challengeId) {
  const response = await clientWithoutErrorHandling.post('/api/partial-auth/participants', { challengeData: {}, challengeId });
  return response.data;
}

async function getChallengeNoAuth(challengeId) {
  const response = await clientWithoutErrorHandling.get(`/api/no-auth/challenges/restricted/${challengeId}`);
  return response.data;
}

async function getUpcomingChallengeOfType(planType) {
  const response = await clientWithoutErrorHandling.get(`/api/partial-auth/challenges/restricted/upcoming-challenge?planType=${planType}`);
  return response.data;
}

// this will create participants for all members of the customer
async function createParticipants(challengeId) {
  const response = await clientWithoutErrorHandling.post(`/api/partial-auth/participantsByCustomer/${challengeId}`);
  return response.data;
}

async function getChallengesDataForCustomer() {
  const response = await clientWithoutErrorHandling.get('/api/participants/customer/challenges-data');
  return response.data;
}

export default {
  getAllChallengesData,
  getActiveChallengesData,
  updateParticipantDailyTaskDone,
  removeParticipantCFCard,
  addChallengesProduct,
  addParticipantToChallenge,
  getChallengeNoAuth,
  createParticipants,
  getChallengesDataForCustomer,
  getUpcomingChallengeOfType,
};
