<template>
  <div class="monthly-saving-insight-popup">
    <insight-details-popup
                      :cashflow-category="cashflowCategory"
                      :insight="insight"
                      :showFeedbackFooter="false"
                      :buttons="buttons"
                      :close-action="closePopup" >
      <template v-slot:top-content>
        <div class="insight-header">
          <div v-if="!insight.hasSavingEnvelope">נראה שאפשר להעביר כל חודש לחיסכון</div>
          <div v-else>נראה שאפשר להעביר כל חודש עוד</div>
          <div class="amount">{{ formattedAmount }} <span class="nis">₪</span></div>
        </div>
      </template>
      <template v-slot:bottom-content>
        <div class="insight-content">
          <div>
            <span class="pink-font">חדש! </span>
            <span class="emphasise">החיסכון הדינמי </span>
            של רייזאפ דואג לעדכן אותך כשיש לך הזדמנות לחסוך, ומאפשר להגדיל או להקטין את החיסכון ישירות מהתזרים.
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div @click="dismissInsight" class="footer-btn">הבנתי, אל תציגו לי יותר</div>
      </template>
    </insight-details-popup>
  </div>
</template>

<script>
import Segment from '@/Segment';
import moneyUtils from '@/utils/money';
import { mapActions } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';
import DDLogs from '@/DDLogs';
import InsightDetailsPopup from '../InsightDetailsPopup';
import StartSave from './start-save/StartSave';

export default {
  name: 'MonthlySavingPopup',
  components: {
    InsightDetailsPopup,
  },
  props: {
    insight: { type: Object, require: true },
    cashflowCategory: { type: String, required: true },
  },
  created() {
    Segment.trackUserGot('MonthlySavingPopupLoaded', this.insight);
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.proposedMonthlySavingAmount, 0);
    },
    buttons() {
      return [{
        text: 'לפרטים נוספים',
        variant: 'primary',
        action: () => {
          this.closeModal();
          this.openModal({
            component: StartSave,
            props: {
              cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED,
              insight: this.insight,
              closeAction: this.closeAction,
              dismissAction: this.dismissAction,
            },
          });
        },
      }];
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['closeModal', 'openModal']),
    closePopup() {
      Segment.trackUserGot('MonthlySavingPopupClosed', this.insight);
      this.closeModal();
    },
    dismissInsight() {
      Segment.trackUserGot('MonthlySavingPopupDismissed', this.insight);
      this.closeModal();
      this.markAsRead(this.insight).catch(error => {
        DDLogs.error('Failed to mark insight as read', { insight: this.insight, error });
      });
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .monthly-saving-insight-popup {
    text-align: right;

    .insight-header {
      color: $riseup_white;
      font-size: 16px;
      text-align: right;

      .amount {
        font-size: 46px;
        font-weight: bold;
        margin-top: 10px;
        .nis {
          font-size: 32px;
        }
      }
    }

    .insight-content {
      text-align: right;
      font-size: 22px;
      line-height: 28px;

      .emphasise {
        font-weight: bold;
      }
      .pink-font {
        color: $riseup_red;
        font-weight: bold;
      }
    }

    .footer-btn {
      margin-bottom: unset;
      margin-top: unset;
    }
  }

</style>
