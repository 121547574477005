<template>
  <insight :insight="insight"
           :animation-name="animationName"
           button-text="לפרטים נוספים"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div v-if="planCompletedEnoughMoney">
        איזה כיף!
        <span class="bold">הגעת ליעד</span>
        של {{ planName }}, ושמת בצד ₪{{ formattedAmount }}!
      </div>
      <div v-else>
        <span>הגעת למועד שקבעת לתוכנית {{ planName }},</span>
        <span v-if="formattedAmount === 0">נראה שלא שמת עבורה כסף בצד.</span>
        <span v-else> ושמת בצד ₪{{ formattedAmount }}</span>
      </div>
    </template>
  </insight>
</template>

<script>
import _ from 'lodash';
import DDLogs from '@/DDLogs';
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import moneyUtils from '@/utils/money';
import PlanCompletedEnoughMoneyPopUp from
'@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/plans-insights-flow/PlanCompletedEnoughMoneyPopup';
import PlanCompletedNotEnoughMoneyPopUp from
'@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/plans-insights-flow/PlanCompletedNotEnoughMoneyPopup';
import Insight from './components/Insight';

const insightNameToNextComponent = _.keyBy([
  PlanCompletedEnoughMoneyPopUp,
  PlanCompletedNotEnoughMoneyPopUp,
], 'insightName');

export default {
  name: 'PlanAheadInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object, require: true },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('insights', ['markAsRead']),
    async buttonAction() {
      const component = insightNameToNextComponent[this.insightName];
      if (!component) {
        DDLogs.error('Cant find componenet for insight', this.insight);
        return;
      }
      this.openModal({
        component,
        props: {
          plan: this.insight.plan,
          closeAction: this.closeAction,
        },
      });
    },
    closeAction() {
      Segment.trackUserInteraction('PlanAheadInsightPopupClosed', this.insight);
      this.markAsRead(this.insight);
      this.closeModal();
    },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.plan.currentAmount, 0);
    },
    planName() {
      return this.insight.plan.name;
    },
    insightName() {
      return this.insight.insightName;
    },
    planCompletedEnoughMoney() {
      return this.insight.insightName === 'PlanCompletedEnoughMoney';
    },
    animationName() {
      return this.planCompletedEnoughMoney ? 'sunrise.json' : 'clouds.json';
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
