<template>
  <div class="note padding-horizontal-s padding-vertical-xxs ri-bold-label">{{ text }}</div>
</template>

<script>
export default {
  name: 'DiscountNote',
  props: {
    text: {
      type: String,
      require: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../../scss/mixins';
.note {
  display: inline-block;
  background: $riseup_green;
  color: $riseup_white;
  border-radius: 8px;
}
</style>
