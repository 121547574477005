import FeaturamaApi from '@/api/Featurama';
import cashflowHistory from '@/store/utilities/cashflow-history';

const state = {
  loading: false,
  pastInsights: undefined,
  currentBudgetDateInsights: undefined,
};

const actions = {
  async getPastInsights({ commit }) {
    commit('setLoading', true);
    const insights = await FeaturamaApi.getSimulatorInsights();
    commit('setPastInsights', insights);
    const currentBudgetDateInsights = insights.filter(insight => insight.budgetDate === cashflowHistory.getCurrentBudgetDate());
    commit('setCurrentBudgetDateInsights', currentBudgetDateInsights);
    commit('setLoading', false);
  },
};

const getters = {};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setPastInsights(state, insights) {
    state.pastInsights = insights;
  },
  setCurrentBudgetDateInsights(state, insights) {
    state.currentBudgetDateInsights = insights;
  },
  setCategories(state, categories) {
    state.categories = categories;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
