import Vue from 'vue';
import _ from 'lodash';
import { ACTIVATION_SURVEY_STEPS, activationSurveyStepsConfig } from '@/pages/responsive-pages/authenticated/survey/activation-survey/consts';
import SurveyApi from '../../../api/SurveyApi';

const state = {
  activeStep: ACTIVATION_SURVEY_STEPS.Start,
  stepStack: [],
  answers: {},
};

const getters = {};

const actions = {
  async saveAnswers({ state }) {
    const formattedAnswers = _.map(_.keys(state.answers), step => _formatResults(step, state.answers[step]));
    await SurveyApi.saveActivationSurveyAnswers({ answers: formattedAnswers });
  },
};

const mutations = {
  updateStepAnswer(state, { stepName, stepResults }) {
    Vue.set(state.answers, stepName, stepResults);
  },
  setActiveStep(state, nextStep) {
    state.stepStack.push(state.activeStep);
    state.activeStep = nextStep;
  },
  setStepBack(state) {
    state.activeStep = state.stepStack.pop() || ACTIVATION_SURVEY_STEPS.Start;
  },
};

function _formatResults(step, answer) {
  return {
    name: activationSurveyStepsConfig[step].nameForCxSummary,
    answer,
  };
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
