import SuspiciousGroupJoinApi from '../../api/SuspiciousGroupJoinApi';

const state = {
  reportApproved: false,
};

const actions = {
  async reportSuspiciousGroupJoin({ commit }) {
    commit('setReportApprove');
    await SuspiciousGroupJoinApi.reportSuspiciousGroupJoin();
  },
};

const mutations = {
  setReportApprove(state) {
    state.reportApproved = true;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
