<template>
  <open-banking-description-step class="obk-description-connect-supported-accounts-step" :step-number="stepNumber">
    <template v-slot:text>
      <div class="bold">איזה חשבונות אפשר לחבר?</div>
      <div>רק את החשבונות הפעילים שיש בהם עסקאות.</div>
    </template>
  </open-banking-description-step>
</template>

<script>
import OpenBankingDescriptionStep from '@/base-components/onboarding/openBanking/OpenBankingDescriptionStep';

export default {
  name: 'ObkDescriptionConnectSupportedAccountsStep',
  components: {
    OpenBankingDescriptionStep,
  },
  props: {
    stepNumber: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.obk-description-connect-supported-accounts-step {
  .bold {
    font-weight: bold;
  }
}

</style>
