<template>
  <div class="riseup-header" :class="{ border: showBottomBorder }">
    <div class="right-nav">
      <slot v-if="!contentLoading"></slot>
    </div>
    <div v-if="showLogo" class="logo">
      <img class="riseup-logo" :src="require('@/assets/riseup_logo.svg')"/>
    </div>
    <div v-else class="left-nav" @click="close">
      <img :src="require('@/assets/icons/ic-close.svg')">
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'RiseupHeader',
  props: {
    showLogo: {
      type: Boolean,
      default: true,
    },
    showBottomBorder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('contentLoader', ['contentLoading']),
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/riseup-z-index';
  @import '../../scss/mixins';
  @import '../../scss/global-variables';

  .riseup-header {
    @include responsive-padding;
    position: sticky;
    position: -webkit-sticky;
    top: 0px;
    width: 100%;
    height: $app_header_height;
    background-color: $riseup_white;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;
    z-index: $app-header;

    &.border {
      border-bottom: 1px solid $riseup_gray_0;
    }

    .logo {
      width: 90px;
      display: flex;
      margin-bottom: 7px;

      .riseup-logo {
        width: 100%;
      }
    }

    .right-nav, .left-nav {
      color: $riseup_black;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

</style>
