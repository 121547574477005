<template>
  <info-tool-tip-help-popup class="isracard-creds-help-popup"
    :title="'איך לחבר חשבון ישראכרט?'"
    :cx-dude="{ name: 'guy', displayName: 'גיא' }"
    :source-name="'ישראכארט'"
    :popup-name="'IsracardCredsHelpPopup'"
    :on-close="onClose">
    <template v-slot:description>
      <div class="description">
        <div>
        כדי לחבר את הישראכרט ל-riseup צריך לספק למערכת את פרטי המשתמש הקבועים (כולל סיסמא קבועה) איתם נכנסים לאתר או לאפליקציה של ישראכרט.
        </div>
        <div>&nbsp;</div>
        <div class="bold">שכחת את הסיסמא הקבועה?</div>
        אפשר לשחזר אותה <a target="_blank" rel="noopener noreferrer"
                           href="https://digital.isracard.co.il/personalarea/loginarea/#/ForgotPassword">בלינק הזה</a>
        <div>&nbsp;</div>
        <div class="bold">אין לך סיסמא קבועה?</div>
        אפשר ליצור סיסמא חדשה <a target="_blank" rel="noopener noreferrer"
                                 href="https://digital.isracard.co.il/personalarea/loginarea/#/Register">בלינק הזה</a>
        <div>&nbsp;</div>
        את פרטי המשתמש העדכניים צריך להזין במסך חיבור החשבון ל-riseup.
      </div>
    </template>
  </info-tool-tip-help-popup>
</template>

<script>

import InfoToolTipHelpPopup from '../InfoToolTipHelpPopup.vue';

export default {
  name: 'IsracardCredsHelpPopup',
  components: {
    InfoToolTipHelpPopup,
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .isracard-creds-help-popup {
    .description {
      a {
        color: $riseup_blue;
        font-weight: bold;
      }
      .bold {
        font-weight: bold;
      }
    }
  }

</style>
