import { render, staticRenderFns } from "./OpenBankingIsracardDescription.vue?vue&type=template&id=6fa9afee&scoped=true"
import script from "./OpenBankingIsracardDescription.vue?vue&type=script&lang=js"
export * from "./OpenBankingIsracardDescription.vue?vue&type=script&lang=js"
import style0 from "./OpenBankingIsracardDescription.vue?vue&type=style&index=0&id=6fa9afee&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa9afee",
  null
  
)

export default component.exports