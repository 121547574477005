<template>
  <navigation-menu-item target="balance" title="מצב העו״ש והאשראי"
                        v-on:navigate="$emit('navigate')"
                        icon-name="account_balance"
                        :disabled="!allowNavigationToBalancePage" />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { SUBSCRIPTION_STATES } from '@/constants/subscription-state';
import NavigationMenuItem from '../NavigationMenuItem.vue';

export default {
  name: 'BalancePageMenuItem',
  components: { NavigationMenuItem },
  computed: {
    ...mapState('credentials', ['credentialsList']),
    ...mapState('subscriptionState', ['subscriptionState']),
    ...mapState('applicationState', ['cashflowState']),
    ...mapGetters('applicationState', ['cashflowTypes']),
    allowNavigationToBalancePage() {
      return !this.isDormant
        && !this.isPreTrial
        && !this.cashflowOutOfDate
        && !this.cashflowBlocked;
    },
    isDormant() {
      return [SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED, SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL].includes(this.subscriptionState);
    },
    isPreTrial() {
      return SUBSCRIPTION_STATES.PRE_TRIAL === this.subscriptionState;
    },
    cashflowOutOfDate() {
      return this.cashflowState === this.cashflowTypes.OUT_OF_DATE;
    },
    cashflowBlocked() {
      return this.cashflowState === this.cashflowTypes.BLOCKED;
    },

  },
};
</script>

<style scoped lang="scss">
</style>
