<script>

import _ from 'lodash';
import { mapGetters } from 'vuex';
import cashflowViewConsts from '@/constants/cashflow-view';

export default {
  name: 'CashflowViewConsts',
  computed: {
    ...mapGetters('cashflowView', ['allCategories']),
    fixedCategory() {
      return _.find(cashflowViewConsts.CATEGORIES, category => category.categoryName === cashflowViewConsts.CATEGORY_NAMES.FIXED_EXPENSE);
    },
    fixedSavingCategory() {
      return _.find(cashflowViewConsts.CATEGORIES, category => category.categoryName === cashflowViewConsts.CATEGORY_NAMES.FIXED_SAVING);
    },
    variableCategory() {
      return _.find(cashflowViewConsts.CATEGORIES, category => category.categoryName === cashflowViewConsts.CATEGORY_NAMES.VARIABLE);
    },
    exclusionCategory() {
      return _.find(cashflowViewConsts.CATEGORIES, category => category.categoryName === cashflowViewConsts.CATEGORY_NAMES.EXCLUDED_EXPENSE);
    },
  },
  methods: {
    getCashflowCategory({ categoryName, categoryId }) {
      if (categoryId) {
        return _.find(this.allCategories, { categoryId });
      }
      return _.find(this.allCategories, { categoryName });
    },
  },
};

</script>
