<template>
  <div class="open-banking-credentials-details">
<!--    <div class="separator">-->
<!--      <div class="line"></div>-->
<!--    </div>-->
    <div class="page-content">
      <separator/>
      <div class="direct-connection-title">
        <span class="direct-connection-text">חיבור ישיר</span>
      </div>
      <div class="title">
        <span>חיבור חשבון באתר או באפליקציית {{source.sourceName}}</span>
        <span class="bold">בקלות, בלי שם משתמש וסיסמה</span>
      </div>
      <base-tool-tip  class="info-tooltip" icon-path="/images/info-icon-blue.svg">
        <span class="bold">החיבור הישיר של {{ source.sourceName }} לא זמין כרגע בגלל תקלה זמנית. </span>
        <span>אפשר לבדוק שוב מאוחר יותר</span>
        <span v-if="!preventClosedBankingConnection">, או להתחבר עם פרטי ההתחברות שלך.</span>
      </base-tool-tip>
    </div>
  </div>
</template>

<script>
import BaseToolTip from '@/base-components/onboarding/BaseToolTip';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapGetters } from 'vuex';

export default {
  name: 'OpenBankingIncidentInfo',
  components: {
    BaseToolTip,
    Separator: BaseUI.Separator,
  },
  props: {
    source: {
      type: Object,
      required: true,
    },
    preventClosedBankingConnection: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    Segment.trackUserGot('OpenBankingIncidentInfo', { source: this.source.sourceName, customerOBState: this.customerOBState });
  },
  computed: {
    ...mapGetters('onboardingState', ['isOnboarding']),
    customerOBState() {
      return this.isOnboarding ? 'onboarding' : 'cashflow';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .open-banking-credentials-details {
    width: 100%;

    .info-tooltip{
      display: flex;
      margin-top: 5px;
      margin-bottom: 35px;
      font-style: normal;
      font-size: 14px;
      text-align: right;
      line-height: 20px;
    }

    .page-content {
      @include responsive-padding;
      padding-top: 20px;
      padding-bottom: 30px;
      .direct-connection-title{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 35px;
        margin-bottom: 16px;
        .direct-connection-text {
          font-size: 24px;
          line-height: 28px;
          font-weight: bold;
          margin-left: 12px;
        }
      }

      .title {
        color: $riseup_black;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 30px;

      }
    }

    .bold {
      font-weight: bold;
    }
  }

</style>
