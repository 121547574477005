import { uuid } from 'vue-uuid';

export default {
  getId() {
    let anonymousUid = localStorage.getItem('riseup.anonymousUid');
    if (!anonymousUid) {
      anonymousUid = `anon-${uuid.v4()}`;
      localStorage.setItem('riseup.anonymousUid', anonymousUid);
    }
    return anonymousUid;
  },
};
