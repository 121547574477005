<template>
  <div :class="['plan-status-note', size]">
    <icon :class="['icon', size]" v-if="planCompletedEnoughMoney" icon-name="check_circle" :color="completedEnoughColor" fill/>
    <icon :class="['icon', size]" v-else-if="planCompletedNotEnoughMoney" icon-name="schedule" :color="completedNotEnoughColor" fill/>
    <icon :class="['icon', size]" v-else-if="cashflowIsNegative" icon-name="pause_circle" :color="negativeCashflowColor" fill/>
    <icon :class="['icon', size]" v-else-if="isOnTrack" icon-name="check_circle" :color="checkIconColor" fill/>
    <icon :class="['icon', size]" v-else-if="customNotOnTrackIcon" :icon-name="customNotOnTrackIcon.icon" :color="customNotOnTrackIcon.color" fill/>
    <icon :class="['icon', size]" v-else icon-name="error" :color="errorIconColor" fill/>
    <span :class="['text', textColor, { bold: isBold }, size]">{{ text }}</span>
  </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';

export default {
  name: 'PlanStatusNote',
  components: {
    Icon: BaseUi.Icon,
  },
  props: {
    text: {
      type: String,
      require: true,
    },
    isOnTrack: {
      type: Boolean,
      require: true,
    },
    cashflowIsNegative: {
      type: Boolean,
      default: false,
    },
    planCompleted: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      validator: val => ['green', 'black'].includes(val),
      default: 'black',
    },
    size: {
      type: String,
      validator: val => ['small', 'regular'].includes(val),
      default: 'regular',
    },
    isBold: {
      type: Boolean,
      default: false,
    },
    customNotOnTrackIcon: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      checkIconColor: BaseUi.Colors.riseupGreen,
      errorIconColor: BaseUi.Colors.riseupYellow,
      completedEnoughColor: BaseUi.Colors.riseupGreen,
      completedNotEnoughColor: BaseUi.Colors.riseupBlue,
      negativeCashflowColor: BaseUi.Colors.riseupYellow,
    };
  },
  computed: {
    planCompletedEnoughMoney() {
      return this.planCompleted && this.isOnTrack;
    },
    planCompletedNotEnoughMoney() {
      return this.planCompleted && !this.isOnTrack;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.plan-status-note {
  display: flex;

  .icon {
    &.regular {
      font-size: 16px;
    }

    &.small {
      font-size: 16px;
    }
  }

  .text {
    color: $riseup_black;

    &.regular {
      padding-right: 6px;
      font-size: 14px;
      line-height: 18px;
    }

    &.small {
      padding-right: 4px;
      font-size: 12px;
      line-height: 18px;
    }

    &.green {
      color: $riseup_green;
    }
    &.bold {
      font-weight: bold;
    }
  }
}
</style>
