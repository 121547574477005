<template>
  <popup class="insight-customer-feedback-popup"
         :back-action="backActionClicked"
         :buttons="buttons"
         :title="'נשמח לדעת מה לא היה נכון כדי שנוכל להשתפר'">
    <template v-slot:content>
      <text-area :autofocus="true" value="" :on-input="handleInput"/>
    </template>
  </popup>
</template>

<script>
import Segment from '@/Segment';
import EventBus from '@/event-bus/event-bus';
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import InsightFeedbackSentToaster from '../../toasters/InsightFeedbackSentToaster';

export default {
  name: 'InsightCustomerFeedbackPopup',
  data() {
    return {
      feedback: '',
    };
  },
  props: {
    insight: { type: Object, required: true },
    backAction: { type: Function, required: true },
    closeAction: { type: Function, required: false },
  },
  components: {
    Popup: BaseUI.Popup,
    TextArea: BaseUI.TextArea,
  },
  async created() {
    Segment.trackUserInteraction('InsightCustomerFeedbackPopupOpened', this.insight);
  },
  computed: {
    buttons() {
      return [
        {
          title: 'שליחה',
          variant: 'primary',
          action: this.sendFeedback,
          disabled: this.feedback.trim() === '',
        },
      ];
    },
  },
  methods: {
    ...mapActions('insights', ['sendInsightFeedback', 'markAsRead']),
    handleInput(value) {
      this.feedback = value;
    },
    async sendFeedback() {
      Segment.trackUserInteraction('InsightFeedbackSent', { insight: this.insight, feedback: this.feedback });
      await this.markAsRead(this.insight);
      await this.sendInsightFeedback({ insight: this.insight, feedback: this.feedback });
      this.closeAction();
      EventBus.$emit('open-toaster', {
        component: InsightFeedbackSentToaster,
      });
    },
    backActionClicked() {
      Segment.trackUserInteraction('InsightFeedbackPopupClosed', this.insight);
      this.backAction();
    },
  },
};

</script>

<style scoped lang="scss">

@import "~@riseupil/base-ui/src/scss/riseup-colors";

.insight-customer-feedback-popup {
}

</style>
