<template>
  <connection-failure-base class="consent-retry-failure" :close-action="closeAction" :buttons="buttons">
    <template v-slot:title>{{title}}</template>
    <template v-slot:subtitle>
      <div>זה קורה, האפשרות לחיבור ישיר ב{{ sourceName }} עדיין חדשה, והדרך לא תמיד חלקה.
      </div>
      <div class="subtitle-line-2">
        <span>תמיד אפשר להוסיף את החשבון עם פרטי ההתחברות שלך ל{{ sourceName }}.</span>
        <span v-if="jitConsentFailureRetry"> ובכל מקרה</span>
        <span v-if="jitConsentFailureRetry" class="bold">הצוות שלנו כאן וישמח לעזור לך.</span>
      </div>
    </template>
  </connection-failure-base>
</template>

<script>
import Intercom from '@/Intercom';
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import router from '@/router';
import AddCredentialsFlow
  from '@/pages/responsive-pages/authenticated/navigation-pages/credentials-settings/add-credentials-flow/AddCredentialsFlow.vue';
import CreateCredentialsFlow from '@/pages/responsive-pages/authenticated/onboard2/steps/create-credentials-flow/CreateCredentialsFlow.vue';
import ConnectionFailureBase from './ConnectionFailureBase';

export default {
  name: 'ConsentRetryFailure',
  components: {
    ConnectionFailureBase,
  },
  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
  },
  created() {
    Segment.trackUserGot('OBKConsentApplyRetryFailureShown', { source: this.sourceName, isOnboarding: !this.onboardingCompleted });
    Intercom.hideLauncher();
  },
  computed: {
    ...mapGetters('featureFlags', ['jitConsentFailureRetry']),
    ...mapGetters('onboardingState', ['onboardingCompleted']),
    title() {
      return `לצערנו החיבור ל${this.sourceName} לא הצליח`;
    },
    sourceName() {
      return this.source.displayName;
    },
    buttons() {
      if (!this.onboardingCompleted) {
        const buttons = [{
          variant: 'secondary',
          action: this.connectWithUsernameAndPassword,
          title: 'להתחבר עם פרטי ההתחברות',
          size: 'slim',
        }];
        if (this.jitConsentFailureRetry) {
          buttons.push({
            action: this.getHelp,
            // eslint-disable-next-line global-require
            icon: require('@/assets/icons/chat_icon_teriatry_white.svg'),
            title: 'אשמח לעזרה מהצוות',
            size: 'slim',
            id: 'cx-consent-apply-retry-failure',
          });
        }
        return buttons;
      }
      return [{
        action: this.connectWithUsernameAndPassword,
        title: 'לחיבור עם סיסמה',
        size: 'slim',
      }, {
        action: () => router.push({ path: '/credentials' }),
        title: 'תודה, הבנתי',
        size: 'slim',
        variant: 'secondary',
      }];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    connectWithUsernameAndPassword() {
      Segment.trackUserGot(
        'OBKConsentApplyRetryFailureShown_ConnectWithUsernameAndPasswordClicked',
        { source: this.sourceName, isOnboarding: !this.onboardingCompleted },
      );
      this.openModal({
        component: this.onboardingCompleted ? AddCredentialsFlow : CreateCredentialsFlow,
        props: {
          preventOBkConnection: true,
          sourceNameToAdd: this.sourceName,
        },
        popupAlignment: 'full-screen',
      });
    },
    getHelp() {
      Segment.trackUserGot(
        'OBKConsentApplyRetryFailureShown_CXButtonClicked  ',
        { source: this.sourceName, isOnboarding: !this.onboardingCompleted },
      );
      Intercom.show();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../../../scss/mixins';

  .consent-retry-failure {
    .title {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      font-family: SimplerPro;
      margin-bottom: 24px;
    }
    .subtitle {
      font-size: 18px;
      line-height: 25px;

      .subtitle-line-2 {
        margin-top: 20px;
      }
    }

    .bold {
      font-weight: bold;
    }
  }

</style>
