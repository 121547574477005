<template>
  <div class="input-container">
    <ValidationProvider :rules="validations" ref="input" slim>
      <div class="input-group">
        <input
          :class="{empty: empty, error: !!validationError || !!error}"
          :value="fieldValue"
          :type="'text'"
          :autofocus="autofocus"
          v-on:input="onInput($event.target.value)"
          :disabled="disabled"
          autocapitalize="none"/>
        <label class="field-name">{{ fieldText }}</label>
        <label v-if="!!validationError || !!error" class="top-left-label">{{ validationError || error }}</label>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>

export default {
  name: 'EditTextInputField',
  props: ['disabled', 'error', 'errorMessage', 'fieldText', 'fieldValue', 'updateInput', 'autofocus', 'validations'],
  data() {
    return {
      validationError: null,
    };
  },
  computed: {
    empty() {
      return !this.fieldValue || this.fieldValue === '';
    },
  },
  methods: {
    onInput(value) {
      this.validationError = null;
      this.updateInput(value);
    },
    async validate() {
      this.validationError = null;
      const isValid = await this.$refs.input.validate();
      if (!isValid.valid) {
        [this.validationError] = isValid.errors;
        const textField = this.$el;
        if (textField) {
          textField.classList.add('animation');
          setTimeout(() => textField.classList.remove('animation'), 1000);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
    @keyframes shake {
      0% { transform: translate(2px, 0); }
      50% { transform: translate(-2px, 0); }
      100% { transform: translate(2px, 0); }
    }
    .input-container {
      height: 40px;
      margin-bottom: 20px;

      &.animation {
        animation: shake 200ms infinite;
        animation-timing-function: linear;
      }

      .input-group {
        position: relative;
        width: 100%;
        height: 40px;

        .top-left-label {
          position: absolute;
          top: -13px;
          right: -2px;
          width: 100%;
          text-align: left;
          font-size: 11px;
          color: $riseup_gray_2;
        }

        > input {
          font-size: 20px;
          width: 100%;
          height: 100%;
          padding: 10px 10px 10px 5px;
          display: block;
          border: 1px solid $riseup_gray_inputs;
          border-radius: 3px;

          &:disabled {
            color: $riseup_gray_2;
            border: 1px solid $riseup_gray_2;
            background-color: $riseup_gray_0;
          }

          &:disabled ~ .field-name {
            position: relative;
            top: -57px;
            font-size: 12px;
            color: $riseup_gray_2;
          }

          &:focus {
            outline: none;
            border: 1px solid #4a90e2;
          }

          &:focus ~ .field-name,
          &:valid ~ .field-name {
            position: relative;
            top: -59px;
            font-size: 12px;
            color: $riseup_blue;
            transition: 300ms ease all;
          }

          &.empty:not(:focus):not(:disabled) ~ .field-name {
            color: $riseup_gray_2;
            font-size: 20px;
            font-weight: normal;
            position: relative;
            pointer-events: none;
            right: 10px;
            top: -33px;
            transition: 300ms ease all;
          }

          &:not(.empty):not(:focus):not(:disabled):not(.error) {
            border: 1px solid $riseup_blue;
          }

          &:not(.empty).error {
            border: 1px solid $riseup_danger_red;
          }

          &:not(.empty).error ~ .field-name {
            color: $riseup_danger_red;
          }

        }
      }

      > img {
        position: relative;
        top: -31px;
        left: -220px;

        &.green-lock {
          height: 25px;
          top: -32px;
        }
      }
    }
</style>
