<template>
  <div class="stacked-modal-root" v-if="!errorType && showingModal">
    <page-overlay v-for="(modal, index) in modalsToShow" :key="index" :isOpen="modal.show" :popup-alignment="modal.popupAlignment">
      <component :is="modal.component" v-on:close="handleClose" v-bind="modal.props" v-on="modal.eventHandlers" />
    </page-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import PageOverlay from '../base-components/PageOverlay.vue';

export default {
  name: 'StackedModalRoot',
  components: {
    PageOverlay,
  },
  computed: {
    ...mapGetters('modalRootStore', ['showingModal', 'modalsToShow']),
    ...mapState('genericError', ['errorType']),
  },
  created() {
    document.addEventListener('keyup', this.handleKeyup);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleKeyup);
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    handleClose() {
      this.closeModal();
    },
    handleKeyup(e) {
      if (e.keyCode === 27) {
        this.handleClose();
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.stacked-modal-root {
  z-index: 30;
}

</style>
