<template>
  <div class="offering-terms">
    <div class="ri-label">
      <div class="margin-bottom-sm" >התשלום עבור {{ numberToText }} החודשים הראשונים הוא מיידי.</div>
      <separator />
    </div>
    <div class="ri-label margin-vertical-sm">
      <span> בתאריך {{ this.endOfPeriodDate }}, המינוי ימשיך אוטומטית</span>
      <span class="ri-bold-label"> במחיר רגיל של {{ this.pricing.monthly}} ₪ בחודש.</span>
    </div>
    <separator />
    <div class="ri-label">
      <div class="margin-vertical-sm">
        <span>אפשר לבטל את המינוי בכל עת ולקבל החזר יחסי.</span>
        <span class="blue link" @click="openTerms">לתנאים המלאים.</span>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import moment from 'moment';
import dateUtils from '@/utils/dates';
import { mapState } from 'vuex';

export default {
  name: 'OfferingTerms',
  components: {
    Separator: BaseUi.Separator,
  },
  props: {
    iterationCount: {
      type: Number,
    },
  },
  computed: {
    ...mapState('subscription', ['pricing']),
    numberToText() { // TODO YY - support all scenarios
      if (this.iterationCount === 3) {
        return 'שלושת';
      }
      return null;
    },
    endOfPeriodDate() {
      return dateUtils.normalizeDate(moment().add(this.iterationCount, 'months').toDate());
    },
  },
  methods: {
    openTerms() {
      window.open('https://www.riseup.co.il/termsofuse/', '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.offering-terms {
  .blue {
    color: $riseup_blue;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  .no-break {
    white-space: nowrap;
  }
}
</style>
