<template>
  <insight :insight="insight"
           :image-name="'promote-semi-annual.svg'"
           button-text="ספרו לי עוד"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>
        רגע לפני התשלום הבא על המינוי לרייזאפ,  <span class="ri-bold-title">למה לא לחסוך {{ yearlySavingAgainstMonthly }} עם מינוי שנתי</span>
      </div>
    </template>
  </insight>
</template>
<script>
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import router from '@/router';

export default {
  name: 'PromoteSemiAnnualInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    ...mapGetters('subscription', ['yearlySavingAgainstMonthly']),
  },
  async created() {
    await this.fetchPricing();
  },
  methods: {
    ...mapActions('subscription', ['fetchPricing']),
    buttonAction() {
      Segment.trackUserInteraction('PromoteSemiAnnualInsight_ButtonClicked');
      router.push({ path: '/subscription/update' });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
