<template>
  <menu-item :disabled="disabled" :title="title" :icon-name="iconName" :showNewBadge="showNewBadge" :on-click="navigate"
             :selected="selected"
  />
</template>

<script>
import Segment from '@/Segment';
import MenuItem from '@/base-components/app-header/MenuItem';
import router from '../../router';

export default {
  name: 'NavigationMenuItem',
  components: {
    MenuItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    target: {
      type: [String, Object],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconName: {
      type: String,
      required: false,
    },
    showNewBadge: {
      type: Boolean,
    },
    segmentEventSuffix: {
      type: String,
      required: false,
    },
  },
  computed: {
    selected() {
      if (typeof this.target === 'string') {
        if (this.target === 'sr') {
          return this.$route.path === '/home';
        }

        return this.$route.path === `/${this.target}`;
      }

      return this.$route.path === `/${this.target.path}`;
    },
  },
  methods: {
    navigate() {
      if (this.segmentEventSuffix) {
        Segment.trackUserInteraction(`NavigationMenu_${this.segmentEventSuffix}_Clicked`);
      }
      if (this.disabled) {
        return;
      }

      if (typeof this.target === 'string') {
        router.push({ path: `/${this.target}`, query: this.$route.query });
      } else {
        router.push(this.target);
      }
      this.$emit('navigate');
    },
  },
};
</script>

<style scoped lang="scss">
</style>
