<template>
  <div>
    <div>החיסכון הדינמי שלנו מחובר לקופת גמל להשקעה
      <span class="emphasise">במיטב</span>.
      כל מה שצריך לעשות זה לפתוח קופה דרכנו, ואנחנו נעדכן אותך כשאפשר לחסוך יותר. יעלי מסבירה:
    </div>
    <div class="kgl-video">
      <video-player
        video-url="https://video.wixstatic.com/video/dcff78_00f290ce695249e68f01ef332c6784ea/720p/mp4/file.mp4"
        controls
        poster="/kgl-frame.png"
        @video-played="videoPlayed" />
    </div>
    <riseup-button :action="goToOpenKgl" variant="primary" title="לפתיחת קופה ב-5 דק׳!" size="slim"/>
    <riseup-button :action="moreDetails" variant="secondary" title="אני רוצה עוד פרטים" size="slim"/>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'AboutKgl',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    VideoPlayer: BaseUI.VideoPlayer,
  },
  props: {
    insight: { type: Object, require: true },
    nextStep: { type: Function, require: true },
  },
  created() {
    Segment.trackUserInteraction('StartSaveAboutKglLoaded', this.insight);
  },
  methods: {
    goToOpenKgl() {
      Segment.trackUserInteraction('StartSaveAboutKgl_Confirmed');
      window.open('https://saving.riseup.co.il', '_blank');
    },
    moreDetails() {
      Segment.trackUserInteraction('StartSaveAboutKgl_More');
      this.nextStep();
    },
    videoPlayed() {
      Segment.trackUserInteraction('StartSaveAboutKgl_StartVideo');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './start-save.scss';

.kgl-video {
  margin-top: 20px;
  margin-left: -10px;
  margin-right: -10px;
}
</style>
