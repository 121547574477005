<template>
  <div class="negative-balance-insight-popup">
    <insight-details-popup :cashflow-category="cashflowCategory"
                           :insight="insight"
                           :close-action="close">
      <template v-slot:top-content>
        <div class="insight-prefix">
          <div class="small">איך זה מסתדר?</div>
          <div class="large">בשונה מהעו״ש, התזרים מציג רק את התמונה של החודש הנוכחי</div>
        </div>
      </template>
      <template v-slot:bottom-content>
        <div class="insight-popup-title">
          <div>כל הכבוד, התזרים החודשי שלך חיובי! החודש צפויות יותר הכנסות מהוצאות 💪</div>
        </div>
        <div class="insight-popup-text">{{text}}</div>
        <intercom-tool-tip v-if="jitNegativeBalancePositiveCf" :text="'רוצה להתייעץ לגבי היעד החודשי? '"
                           :handle-action="handleIntercomClickAction"
                           :event-source="componentName"
                           :message="''"
        >
          </intercom-tool-tip>
        <riseup-button class="riseup-button"
                       :action="emitEditRiseupGoalPopup"
                       :size="'slim'"
                       :title="riseupGoalButtonText"
                       :variant="'primary'"/>
      </template>
      <template v-slot:footer>
        <div @click="doNotShowAgain" class="footer-btn">הבנתי, אל תציגו לי יותר</div>
      </template>
    </insight-details-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import InsightDetailsPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/InsightDetailsPopup';
import IntercomToolTip from '@/base-components/onboarding/IntercomToolTip';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import RiseupGoalDonePopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/RiseupGoalDonePopup';
import EditMonthlyGoalAmount
  from '../../cashflow-view/current-month/components/cashflow-edit/edit-riseup-goal/EditMonthlyGoalAmount';

export default {
  name: 'NegativeBalanceInsightPopup',
  components: {
    InsightDetailsPopup,
    RiseupButton: BaseUI.RiseupButton,
    IntercomToolTip,
  },
  props: {
    insight: { type: Object, required: true },
    cashflowCategory: { type: String, required: true },
  },
  created() {
    Segment.trackUserInteraction('NegativeBalanceInsightPopupShown');
    Segment.trackUserInteraction('JustInTimeCX_Shown', { source: this.componentName, isButtonShown: this.jitNegativeBalancePositiveCf });
  },
  computed: {
    ...mapGetters('featureFlags', ['jitNegativeBalancePositiveCf']),
    riseupGoalButtonText() {
      return 'להגדרת יעד חודשי';
    },
    componentName() {
      return this.$options.name;
    },
    text() {
      return `כדי לצאת בהדרגה מהמינוס מומלץ להגדיר בדיוק כמה נרצה לשים בצד בסוף החודש - עם הגדרת יעד החודשי${this.eligibleForPlanAhead
        ? ' והתוכניות העתידיות שנייעד לכסף הזה' : ''}.`;
    },
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['openModal']),
    close() {
      Segment.trackUserInteraction('NegativeBalanceInsightPopupCloseClicked');
      this.$emit('close');
    },
    onSaveRiseUpGoal() {
      Segment.trackUserInteraction('EditRisepGoalAmountUponNegativeBalanceInsight');
      this.openModal({
        component: RiseupGoalDonePopup,
      });
    },
    async doNotShowAgain() {
      Segment.trackUserInteraction('NegativeBalanceInsightDoNotShowAgain');
      await this.markAsRead(this.insight);
      this.close();
    },
    async emitEditRiseupGoalPopup() {
      Segment.trackUserInteraction('NegativeBalanceInsightRiseupGoalClicked');
      await this.markAsRead(this.insight);
      this.close();
      this.openModal({
        component: EditMonthlyGoalAmount,
        props: {
          onSaveCb: this.onSaveRiseUpGoal,
        },
      });
    },
    handleIntercomClickAction() {
      Segment.trackUserInteraction('JustInTimeCX_Clicked', { source: this.componentName });
    },
  },
};
</script>

<style lang="scss" scoped>
  .insight-popup-title {
    font-weight: bold;
    line-height: 26px;
    font-size: 18px;
  }

  .insight-popup-text {
    margin-top: 20px;
    margin-bottom: 30px;
    line-height: 26px;
    font-size: 18px;
  }

  .small {
    font-size: 16px;
    line-height: 8px;
    margin-bottom: 9px;
  }

  .large {
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
    margin-bottom: -15px;
  }

  .footer-btn {
    margin-bottom: unset;
    margin-top: unset;
  }

  .bottom {
    padding: 20px;
  }

  .riseup-button {
    margin-top: 40px;
  }
</style>
