<template>
  <div>
    <colored-popup class="demographic-info-popup warm" :close-action="isFirstScreen ? close : null" :back-action="isFirstScreen ? null : back">
      <template v-slot:top-content>
        <div class="title">השוואה ללקוחות שדומים לך</div>
      </template>
      <template v-slot:bottom-content>
        <div class="progress">{{ cardIndex + 1 }} מתוך 3</div>
        <div v-if="cardIndex === 0">
          <div class="question-text">מצב משפחתי</div>
          <radio-select :options="maritalStatusAnswers" :set-answer="setMaritalStatus"/>
        </div>
        <div v-else-if="cardIndex === 1">
          <div class="question-text">מספר ילדים</div>
          <text-input :autofocus="true" placeholder="להקליד כאן" :value="numberOfKids" :onInput="setKids" inputType="number" :inputPattern="'\\d*'"/>
        </div>
        <div v-else-if="cardIndex === 2">
          <div class="question-text">עיר מגורים נוכחית</div>
          <autocomplete-input class="select-city" placeholder="עיר מגורים נוכחית" :options="cityNames" @change="setCity" :start-value="city"/>
        </div>
        <riseup-button class="button" variant="primary" size="slim" :title="isLastScreen ? 'סיימתי' : 'הבא >'"
                       :action="next" :disabled="continueDisabled"/>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import RadioSelect from '@/base-components/RadioSelect';
import * as CashflowHomeApi from '@/api/CashflowHomeApi';
import DemographicInfoDone from '../demographic-info/DemographicInfoDone';

export default {
  name: 'DemographicInfoPopup',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    textInput: BaseUI.TextInput,
    ColoredPopup: BaseUI.ColoredPopup,
    RadioSelect,
    AutocompleteInput: BaseUI.AutocompleteInput,
  },
  props: {
    insight: { type: Object, required: true },
  },
  data() {
    return {
      cardIndex: 0,
      maritalStatus: '',
      numberOfKids: '',
      city: '',
      isSaving: false,
    };
  },
  async created() {
    this.sendSegmentEvent('DemographicInfoPopupShown', this.insight);
    await this.loadAllCities();
  },
  computed: {
    ...mapState('cities', ['cityNames']),
    isLastScreen() {
      return this.cardIndex === 2;
    },
    isFirstScreen() {
      return this.cardIndex === 0;
    },
    maritalStatusAnswers() {
      return [
        { value: 'single', label: 'רווק.ה' },
        { value: 'married', label: 'בנישואים / בזוגיות' },
        { value: 'divorced', label: 'גרוש.ה' },
        { value: 'other', label: 'אחר' },
      ];
    },
    continueDisabled() {
      return this.isSaving || ((this.cardIndex === 2) && !this.isCityValid);
    },
    demographicInfo() {
      const data = { maritalStatus: this.maritalStatus, city: this.city, numberOfKids: this.numberOfKids };
      return _.pickBy(data, d => !_.isEmpty(d));
    },
    isCityValid() {
      return (this.city.length === 0) || this.cityNames.includes(this.city);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('insights', ['clearInsight']),
    ...mapActions('cities', ['loadAllCities']),
    sendSegmentEvent(eventName, extraProps = {}) {
      Segment.trackUserInteraction(eventName, { ...this.insight, ...extraProps, cardIndex: this.cardIndex });
    },
    setMaritalStatus(answer) {
      this.maritalStatus = answer;
    },
    setCity(answer) {
      this.city = answer;
    },
    setKids(answer) {
      this.numberOfKids = answer;
    },
    back() {
      this.sendSegmentEvent('DemographicInfoPopupBackClicked');
      this.cardIndex -= 1;
    },
    async next() {
      this.updateNextClicked();
      if (this.isLastScreen) {
        await this.save();
      } else {
        this.cardIndex += 1;
      }
    },
    updateNextClicked() {
      if (this.cardIndex === 0) {
        this.sendSegmentEvent('DemographicInfoMaritalStatusContinueClicked', { answered: !_.isEmpty(this.maritalStatus) });
      } else if (this.cardIndex === 1) {
        this.sendSegmentEvent('DemographicInfoNumberOfKidsContinueClicked', { answered: !_.isEmpty(this.numberOfKids) });
      } else {
        this.sendSegmentEvent('DemographicInfoCityContinueClicked', { answered: !_.isEmpty(this.city) });
      }
    },
    async save() {
      this.sendSegmentEvent('DemographicInfoPopupSaveClicked');
      await this.saveAndClear();
      this.closeModal();
      this.openModal({
        component: DemographicInfoDone,
      });
    },
    async close() {
      if (!this.isSaving) {
        this.sendSegmentEvent('DemographicInfoPopupCloseClicked');
        await this.saveAndClear();
        this.closeModal();
      }
    },
    async saveAndClear() {
      this.isSaving = true;
      await this.clearInsight(this.insight);
      await CashflowHomeApi.createDemographicInfo(this.demographicInfo);
      this.isSaving = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
.demographic-info-popup {
  border-radius: 8px;
  font-size: 18px;
  color: $riseup_white;
  position: relative;
  text-align: right;
  .title {
    font-weight: bold;
  }
  .question-text {
    padding-bottom: 25px;
    font-weight: bold;
    font-size: 22px;
    color: $riseup_black;
    line-height: 26px;
  }
  .progress {
    color: $riseup_blue;
    font-size: 12px;
    padding-bottom: 6px;
  }
  .select-city {
    margin-bottom: 20px;
  }
  .button {
    margin-top: 20px;
  }
}
</style>
