<template>
  <div class="move-transaction-to-saving">
    <colored-popup :class="categoryToVariant[fixedCategory.cashflowCategory]"
                   :close-action="onClose">

      <template v-slot:top-content>
        <div class="top-content">
          <div class="transaction-title">
            <span class="name">הוצאה קבועה</span>
          </div>
          <transaction-details :transaction="predictedTransaction.actual" />
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="details">
          <div class="title">הפקדה לחיסכון זה מעולה, כל הכבוד!</div>
          <div>היא קבועה או חד-פעמית?</div>
        </div>

        <riseup-button size="slim" title="קבועה"
                       :action="() => openSummary('fixed')"/>

        <or-separator/>
        <riseup-button variant="secondary" size="slim" title="חד-פעמית"
                       :action="() => openSummary('one-time')"/>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import cashflowViewConsts from '@/constants/cashflow-view';
import CashflowViewConsts from '@/mixins/CashflowViewConsts';
import EventBus from '@/event-bus/event-bus';
import TransactionMovedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/TransactionMovedToaster';
import SegmentTracker from '@/mixins/SegmentTracker';
import TransactionDetails from '../TransactionDetails';
import MoveTransactionToSavingSummary from './MoveTransactionToSavingSummary';

export default {
  name: 'MovePredictionWithActualToSaving',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    TransactionDetails,
    RiseupButton: BaseUI.RiseupButton,
    OrSeparator: BaseUI.OrSeparator,
  },
  props: {
    predictedTransaction: { type: Object, required: true },
  },
  mixins: [CashflowViewConsts, SegmentTracker],
  created() {
    this.initTracker('MovePredictionTransactionWithActualToSaving_', { predictedTransaction: this.predictedTransaction });
    this.track('Entered');
  },
  computed: {
    ...mapGetters('editTransaction', ['categoryToVariant', 'ENRICHMENT_INPUT_TYPES']),
    ...mapGetters('cashflowView', ['oneTimeSavingCategory']),
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('editCashflow', ['changeEnvelopeExpenseCategory', 'moveTransaction']),
    openSummary(savingType) {
      if (savingType === 'fixed') {
        this.track('ToFixeSaving_NextClicked');
      } else {
        this.track('ToOneTimeSaving_NextClicked');
      }
      this.closeModal();
      this.openModal({
        component: MoveTransactionToSavingSummary,
        props: {
          transaction: this.predictedTransaction.actual,
          fromCategory: this.fixedCategory,
          toCategory: this.fixedSavingCategory,
          backContext: { backComponent: 'MovePredictionWithActualToSaving', props: this.$props },
        },
        eventHandlers: {
          save: event => {
            event.stopPropagation();
            this.onSave(savingType);
          },
        },
      });
    },
    onSave(savingType) {
      if (savingType === 'fixed') {
        this.track('ToFixedSaving_SaveClicked');
        this.changeEnvelopeExpenseCategory({
          envelopeId: this.predictedTransaction.envelopeId,
          sequenceId: this.predictedTransaction.sequenceId,
          expenseCategory: cashflowViewConsts.FIXED_SAVING_CATEGORY_NAME,
          isIncome: this.predictedTransaction.isIncome,
        });
      } else if (savingType === 'one-time') {
        this.track('ToOneTimeSaving_SaveClicked');
        this.moveTransaction({
          originalCategory: this.fixedCategory,
          selectedCategory: {
            cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.ONE_TIME_SAVING,
            categoryId: this.oneTimeSavingCategory.categoryId,
          },
          transaction: this.predictedTransaction.actual,
          categoryRuleType: this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID,
          valueForPredicate: this.predictedTransaction.actual.transactionId,
        });
      }

      EventBus.$emit('open-toaster', {
        component: TransactionMovedToaster,
        props: {
          changedTransaction: { ...this.predictedTransaction, isSaving: true },
          targetCategory: { pluralLabel: cashflowViewConsts.SAVING_PLURAL_LABEL },
        },
      });
    },
    onClose() {
      this.track('CloseClicked');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.move-transaction-to-saving {
  text-align: right;

  .top-content {

    .transaction-title {
      margin-bottom: 16px;
      line-height: 11px;
      .name {
        font-weight: bold;
      }
    }
  }

  .details {
    margin-bottom: 34px;
    font-size: 18px;
    line-height: 26px;

    .title {
      font-weight: bold;
    }
  }
}
</style>
