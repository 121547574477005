<template>
  <div class="create-tracking-category-amount-popup">
    <colored-popup
      :class="variant"
      :back-action="back">

      <template v-slot:top-content>
        <div class="title">
          {{ title }}
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="popup-bottom-margin">
          <slot name="sub-title"/>
          <amount-input :value="amount" @input="setAmount" autofocus :placeholder="placeHolder" label=""
          :error-message="displayErrorMessage && errorMessage"/>
          <slot name="comment" v-if="!amount"/>
          <card class="affect" :has-content-padding="false">
            <div>
              <div class="progress-bar-title"> {{progressTitle}} </div>
              <separator/>
              <div class="progress-bar-wrapper">
                <progress-bar :left-amount="Number(cashculations.recommendedAmount)"
                              :left-label="labelText"
                              :right-label="'יצא עד עכשיו'"
                              :right-amount="cashculations.untilNowAmount"
                              :cashflow-category="category"
                              :show-warning-if-exceeding-amount="true"/>
              </div>
            </div>
          </card>

          <riseup-button :action="save"
                         :variant="'primary'"
                         :size="'slim'"
                         :title="buttonText || 'הוספת קטגוריה'"
                         :disabled="!amount || displayErrorMessage"/>
        </div>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import cashflowViewConsts from '../../../../../../../../constants/cashflow-view';
import ProgressBar from '../../generic-components/cashflow-types/cashflow-types-base/ProgressBar.vue';

export default {
  name: 'CategoryNumericInputPopup',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    Card: BaseUI.Card,
    Separator: BaseUI.Separator,
    AmountInput: BaseUI.AmountInput,
    ProgressBar,
  },
  props: {
    title: {
      type: String,
      require: true,
    },
    buttonText: {
      type: String,
      require: false,
    },
    placeHolder: {
      type: String,
      default: '0',
    },
    cashculations: {
      type: Object,
      require: true,
    },
    displayErrorMessage: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE,
    },
    progressTitle: {
      type: String,
      default: 'איך זה משפיע על המשתנות?',
    },
    labelText: {
      type: String,
      default: 'מומלץ להוציא עד',
    },
  },
  computed: {
    ...mapState('createTrackingCategory', ['amount']),
    variant() {
      return cashflowViewConsts.CATEGORY_STYLE[cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING];
    },
  },
  methods: {
    ...mapActions('createTrackingCategory', ['setAmount']),
    back() {
      this.$emit('back-clicked');
    },
    save() {
      this.$emit('save');
    },
  },
};
</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.create-tracking-category-amount-popup {
  text-align: right;

  .colored-top {
    color: $riseup_white;

    .title {
      font-size: 22px;
      font-weight: bold;
      line-height: 22px;
    }
  }

  .bottom {
    .title {
      margin-bottom: 13px;
      font-size: 18px;
      line-height: 24px;
      .bold {
        font-weight: bold;
      }
    }
    .comment {
      font-size: 14px;
      line-height: 24px;
      margin-top: 6px;
    }

    .affect {
      margin: 30px 0 30px;

      .progress-bar-title {
        margin: 23px 19px 12px 16px;
        font-weight: bold;
        font-size: 16px;
      }

      .progress-bar-wrapper {
        margin: 28px 20px;
      }
    }

    .riseup-button {
      width: 100%;
    }
  }
}

</style>
