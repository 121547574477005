<template>
  <info-tool-tip-help-popup class="invite-member-help-popup"
    :title="'ככה תראה הודעת ההזמנה האישית ממך:'"
    :popup-name="'InviteMemberHelpPopup'"
    :on-close="onClose">
    <template v-slot:description>
      <img class="message-img" :src="require('@/assets/invite-member-message.png')"/>
    </template>
  </info-tool-tip-help-popup>
</template>

<script>

import InfoToolTipHelpPopup from '../InfoToolTipHelpPopup.vue';

export default {
  name: 'InviteMemberHelpPopup',
  components: {
    InfoToolTipHelpPopup,
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .invite-member-help-popup {
    .message-img {
      width: 100%;
      height: 100%;
    }
  }

</style>
