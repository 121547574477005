import _ from 'lodash';
import DDLogs from '@/DDLogs';

const REDIRECT_TO_BLACKLIST = ['/login', '/signup', '/signup/details'];
const REDIRECT_URL_FOR_DECODING = 'decode-and-redirect';

function getEncodedRedirectTo(to) {
  const redirectToForAuth0 = _extractRedirectToForAuth0(to);
  const params = (to.path.startsWith('/discount')) ? {} : to.query;
  const encodedUrl = encodeURIComponent(_toEncodedUrl(redirectToForAuth0, params));
  return `${REDIRECT_URL_FOR_DECODING}/${encodedUrl}`;
}

function decodeUrl(encodedUrl) {
  try {
    const buff = Buffer.from(encodedUrl, 'base64');
    return JSON.parse(buff.toString('utf-8'));
  } catch (e) {
    DDLogs.log('couldn\'t parse encoded url from base 64');
    throw e;
  }
}

function _extractRedirectToForAuth0(to) {
  // TODO - we can now use the query params as is
  if (_isOBKWelcomeBack(to.path)) {
    return _OBKWelcomeBackRedirectUrl(to);
  }
  if (_.includes(REDIRECT_TO_BLACKLIST, to.path)) {
    return 'sr';
  }
  return _.trimStart(to.path, '/');
}

function _isOBKWelcomeBack(path) {
  return /\/*\/welcome-back$/.test(path);
}

function _toEncodedUrl(path, params) {
  const buff = Buffer.from(JSON.stringify({ path, params }), 'utf-8');
  return buff.toString('base64');
}

function _OBKWelcomeBackRedirectUrl(to) {
  const { state, code } = to.query;
  return `${_.trimStart(to.path, '/')}/${state}/${code}`;
}

export default {
  getEncodedRedirectTo,
  decodeUrl,
};
