<script>

import { mapState } from 'vuex';

export default {
  name: 'CustomerBenefits',
  computed: {
    ...mapState('benefits', ['registeredUsingSpecialPromotion']),
    registeredUsingSpecialSource() {
      return this.registeredUsingSpecialPromotion;
    },
  },
};

</script>
