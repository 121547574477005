<template>
  <colored-popup class="primary"
                    :close-action="closeAction"
                    :dark-text="false">
    <template v-slot:top-content>
      <div class="insight-header ri-large-headline">
          <span>התוכנית
          <span class="ri-font-weight-bold">{{ planName }}</span>
          <span v-if="formattedAmount === 0"> הגיעה למועד הסיום שקבעת. נראה שלא שמת עבורה כסף בצד.</span>
          <span v-else>הגיעה למועד הסיום שקבעת עם ₪{{ formattedAmount }}</span>
        </span>
      </div>
    </template>
    <template v-slot:bottom-content>
      <div>
        <plan class="text-align-right" :plan="plan" style="margin-right: 0px; margin-left: 0px;" :previewMode="true" />
        <div class="ri-bold-title ri-blue text-align-right margin-top-xl margin-bottom-ms">מה אפשר לעשות?</div>
        <div class="ri-large-body text-align-right margin-bottom-custom-40">
          אפשר להחליט שזה הסכום שמתאים לך, ולהשאיר אותה תחת ״תוכניות שהסתיימו״.
          או שאפשר להאריך את מועד התוכנית כדי לשים עוד כסף בצד בחודשים הבאים.
        </div>
        <riseup-button class="margin-bottom-l"
                          variant="primary"
                          title="לעריכת התוכנית"
                          :action="editPlan"
                          size="slim"
                          :bold="true"/>

        <riseup-button
                          variant="secondary"
                          title="לסיום התוכנית"
                          :action="goToPlanDashboard"
                          size="slim"
                          :bold="true"/>
      </div>
    </template>
  </colored-popup>
</template>

<script>
import { mapActions } from 'vuex';
import EditPlanFlow from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/plans-flow/EditPlanFlow';
import router from '@/router';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import Plan from '@/pages/responsive-pages/authenticated/navigation-pages/plan-ahead/Plan';
import moneyUtils from '@/utils/money';

export default {
  name: 'PlanCompletedNotEnoughMoneyPopup',
  insightName: 'PlanCompletedNotEnoughMoney',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
    Plan,
  },
  props: {
    plan: { type: Object, require: true },
    closeAction: { type: Function, required: true },
  },
  created() {
    Segment.trackUserGot('PlanCompletedNotEnoughMoneyPopUpLoaded', this.insight);
    this.updateCurrentPlan(this.plan);
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.plan.currentAmount, 0);
    },
    planName() {
      return this.plan.name;
    },
  },

  methods: {
    ...mapActions('planAhead', ['updatePlan', 'updateCurrentPlan']),
    ...mapActions('modalRootStore', ['openModal']),
    goToPlanDashboard() {
      this.closeAction();
      Segment.trackUserInteraction('PlanAhead_Section_GoToPlansDashboard');
      router.push({ path: '/plan-ahead' });
    },
    async finishEditPlan() {
      await this.updatePlan();
    },
    editPlan() {
      this.closeAction();
      Segment.trackUserInteraction('PlanAhead_Section_GoToEditPlan');

      this.openModal({
        component: EditPlanFlow,
        props: {
          onDone: this.finishEditPlan,
          plan: this.plan,
          closeOnShowPrevious: true,
        },
        popupAlignment: 'full-screen',
      });
    },
  },
};

</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/spacings';

  .insight-header {
    color: $riseup_white;
    display: flex;
    padding-top: $ri-spacing-l;
    padding-bottom: $ri-spacing-s;
  }

  .text-align-right {
    text-align: right;
  }

  .ri-blue {
    color: $riseup_blue;
  }
  .margin-bottom-custom-40 {
    margin-bottom: 40px;
  }
</style>
