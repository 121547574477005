import _ from 'lodash';
import DDLogs from '@/DDLogs';
import config from '@/config';
import MetricsApi from '@/api/MetricsApi';

export const PerformanceMarks = {
  AUTHENTICATE_TOKEN: 'authenticateToken',
  INIT_CUSTOMER_STATE: 'initCustomerState',
  INIT_CURRENT_BUDGET: 'initCurrentBudget',
  INIT_PREVIOUS_BUDGETS: 'initPreviousBudgets',
  INIT_BUDGETS: 'initBudgets',
  COMPONENT: 'component',
  NAVIGATE_TO_MONTH: 'navigateToMonth',
  DESTROY_CURRENT_CF_VIEW: 'destroyCurrentCfView',
  MOVE_TRANSACTION: 'moveTransaction',
  INIT_SESSION: 'initSession',
  CASHFLOW_SEARCH: 'cashflowSearch',
  CASHFLOW_SEARCH_RESULT_CLICK: 'cashflowSearchResultClick',
};

export const StartupMarks = [
  PerformanceMarks.AUTHENTICATE_TOKEN,
  PerformanceMarks.INIT_CUSTOMER_STATE,
  PerformanceMarks.INIT_CURRENT_BUDGET,
  PerformanceMarks.INIT_PREVIOUS_BUDGETS,
  PerformanceMarks.INIT_BUDGETS,
  PerformanceMarks.INIT_SESSION,
];

class PerformanceService {
  START_POSTFIX = '_start';

  END_POSTFIX = '_end';

  MARK_PREFIX = 'riseup_';

  MARK_REGEX = new RegExp(`(${this.START_POSTFIX}|${this.END_POSTFIX})`, 'g');

  isLoggingEnabled = Math.random() < 0.5;

  metrics = [];

  metricsSent = false;

  _debounceSendMetrics = _.debounce(() => this._sendMetrics(), 1000);

  clearMarks() {
    performance.clearMarks();
  }

  markStart(markName) {
    performance.mark(`${this.MARK_PREFIX}${markName}${this.START_POSTFIX}`);
  }

  markEnd(markName) {
    performance.mark(`${this.MARK_PREFIX}${markName}${this.END_POSTFIX}`);
  }

  markEndAndLog(markName) {
    this.markEnd(markName);
    this._collectMetrics(this._toMetric('Performance_MarkDuration', this._duration(markName), { markName }));
  }

  _devMode() {
    return sessionStorage.getItem('debug_performance') === 'true';
  }

  _log(message, data) {
    if (this.isLoggingEnabled) {
      DDLogs.log(message, data);
    }
    if (this._devMode()) {
      window.riseup_debug_performance = window.riseup_debug_performance || {};
      window.riseup_debug_performance[message] = data;
      console.log(message, data);
    }
  }

  _toMetric(message, value, tags) {
    return { metricName: message, value, tags };
  }

  _collectMetrics(metric) {
    if (metric.metricName && _.isNumber(metric.value) && metric.value > 0) {
      this.metrics.push(metric);
      if (this._devMode()) {
        this._log('metric', metric);
      }
      if (this.metricsSent) {
        this._debounceSendMetrics();
      }
    }
  }

  async _sendMetrics() {
    this.metricsSent = true;
    if (this.metrics.length) {
      await MetricsApi.sendLoadTimeMetrics(this.metrics);
    }
    this.metrics = [];
  }

  _getMetrics(markName) {
    try {
      const measurement = performance.measure(
        markName,
        `${this.MARK_PREFIX}${markName}${this.START_POSTFIX}`,
        `${this.MARK_PREFIX}${markName}${this.END_POSTFIX}`,
      );
      return {
        startTime: measurement.startTime,
        duration: measurement.duration,
        endTime: measurement.startTime + measurement.duration,
      };
    } catch (e) {
      // measurement not found
      return 'N/A';
    }
  }

  _duration(markName) {
    try {
      return performance.measure(
        markName,
        `${this.MARK_PREFIX}${markName}${this.START_POSTFIX}`,
        `${this.MARK_PREFIX}${markName}${this.END_POSTFIX}`,
      )?.duration;
    } catch (e) {
      // measurement not found
      return 'N/A';
    }
  }

  _getTotalLoadTime() {
    const navigationEntry = performance.getEntriesByType('navigation')[0];
    const now = performance.now();
    return {
      redirectStart: now - (navigationEntry?.redirectStart ?? 0),
      requestStart: now - (navigationEntry?.requestStart ?? 0),
    };
  }

  _getFirstContentfulPaintTime() {
    const measurement = performance.getEntriesByType('paint')?.[1];
    if (!measurement) {
      return 'N/A';
    }
    return {
      startTime: 0,
      duration: measurement.startTime,
      endTime: measurement.startTime,
    };
  }

  _logResourcePerformanceData() {
    performance.getEntriesByType('resource')
      .map(resource => {
        const url = new URL(resource.name);
        return {
          name: `${url.origin}${url.pathname}`,
          fullUrl: resource.name,
          duration: resource.duration,
          type: resource.initiatorType,
          size: resource.decodedBodySize,
        };
      })
      .filter(this._isElsaScript)
      .forEach(resource => {
        this._collectMetrics(this._toMetric('Performance_Resource', resource.duration, { name: resource.name, type: resource.type }));
      });
  }

  _waitingForEndMark() {
    // match each start mark with an end mark
    const [startMarks, endMarks] = _.chain(performance.getEntriesByType('mark'))
      .filter(mark => mark.name.startsWith(this.MARK_PREFIX))
      .map(m => m.name)
      .partition(mark => mark.endsWith(this.START_POSTFIX))
      .value();
    return _.differenceBy(startMarks, endMarks, mark => mark.replace(this.MARK_REGEX, '')).length > 0;
  }

  _getComponentMetrics() {
    return performance.getEntriesByType('mark')
      .filter(mark => mark.name.startsWith(`${this.MARK_PREFIX}${PerformanceMarks.COMPONENT}`))
      .reduce((components, currentValue) => {
        const componentName = currentValue.name.split('_')[2];
        // eslint-disable-next-line no-param-reassign
        components[`rendered${componentName}`] = this._getMetrics(`${PerformanceMarks.COMPONENT}_${componentName}`);
        return components;
      }, {});
  }

  _isElsaScript({ name }) {
    const { elsaPublicUrl } = config.get();
    return name.startsWith(`${elsaPublicUrl}/js/`) || name.startsWith(`${elsaPublicUrl}/css/`);
  }

  _logScriptsSumDuration() {
    const scripts = performance.getEntriesByType('resource')
      .filter(this._isElsaScript);
    const { startTime, endTime } = scripts
      .reduce((agg, { name, startTime, duration }) => {
        const endTime = startTime + duration;
        return {
          startTime: Math.min(agg.startTime, startTime),
          endTime: Math.max(agg.endTime, endTime),
        };
      }, { startTime: Infinity, endTime: 0 });
    const totalDuration = Math.max(0, endTime - startTime);
    this._log('Performance_ScriptsSumDuration', { totalDuration, startTime, endTime });
    this._collectMetrics(this._toMetric('Performance_ScriptsSumDuration', totalDuration));
  }

  logPerformanceReport(retries = 0) {
    if (retries > 100) {
      this._log('PerformanceService: Could not log performance report after 100 retries');
      return;
    }
    if (this._waitingForEndMark()) {
      // eslint-disable-next-line no-param-reassign
      setTimeout(() => this.logPerformanceReport(++retries), 100);
      return;
    }
    const total = this._getTotalLoadTime();
    const componentMetrics = this._getComponentMetrics();
    const firstContentfulPaint = this._getFirstContentfulPaintTime();
    this._log('Performance_LoadTimeReport', {
      cashflowLoadTime: {
        total,
        firstContentfulPaint,
        ...componentMetrics,
      },
    });
    this._collectMetrics(this._toMetric('Performance_RequestStart', total.requestStart, { markName: 'total' }));
    this._collectMetrics(this._toMetric('Performance_MarkDuration', firstContentfulPaint.duration, { markName: 'firstContentfulPaint' }));
    StartupMarks.forEach(mark => {
      const duration = this._duration(mark);
      if (_.isNumber(duration)) {
        this._collectMetrics(this._toMetric('Performance_MarkDuration', duration, { markName: mark }));
      }
    });
    Object.entries(componentMetrics)
      .forEach(([name, metrics]) => {
        this._collectMetrics(this._toMetric('Performance_MarkDuration', metrics.duration, { markName: name }));
        this._collectMetrics(this._toMetric('Performance_MarkEndTime', metrics.endTime, { markName: name }));
      });
    this._logResourcePerformanceData();
    this._logScriptsSumDuration();
    this._sendMetrics();
  }
}

export const performanceService = new PerformanceService();
