<template>
  <div class="purchase">
    <div class="personal-info">
      <div class="title">פרטי בעל הכרטיס</div>
      <personal-info-form/>
    </div>
    <separator/>
    <div class="credit-card-details">
      <credit-card-details-form @init-failed="creditCardFormInitFailed"/>
    </div>
    <separator/>
  </div>
</template>

<script>
import PersonalInfoForm from '@/pages/responsive-pages/authenticated/navigation-pages/account-settings/components/PersonalInfoForm';
import PaymentHelper from '@/pages/responsive-pages/authenticated/payment/PaymentHelper';
import Segment from '@/Segment';
import CreditCardDetailsForm from '@/pages/responsive-pages/authenticated/payment/CreditCardDetailsForm';
import PaymeInterface from '@/Payme';
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'DifferentCreditCard',
  components: {
    CreditCardDetailsForm,
    PersonalInfoForm,
    Separator: BaseUI.Separator,
  },
  mixins: [PaymentHelper],
  async created() {
    Segment.trackUserGot('PaymentDifferentCreditCard_Entered');
  },
  beforeDestroy() {
    PaymeInterface.teardown();
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '@/scss/mixins';

.purchase {
  .personal-info {
    @include responsive-padding;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: SimplerPro;

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .email-explained {
      margin-top: -15px;
      padding-right: 5px;
      font-size: 14px;
    }
  }

  .credit-card-details {
    @include responsive-padding;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
</style>
