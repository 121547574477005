import { client } from '../http';

export default {
  SPECIAL_PROMOTION_1: 'specialPromotion',
  async getPromotion(promoCode) {
    const response = await client.get(`/api/no-auth/promotion/${promoCode}`);
    return response.data;
  },
  async createPromotionForSource(promoSource) {
    const response = await client.post(`/api/no-auth/promotion/${promoSource}`);
    return response.data.createdPromotion;
  },
  async isRegisteredUsingSource(promoSource) {
    const response = await client.get(`/api/is-registered-using-source/${promoSource}`);
    return response.data;
  },
};
