<template>
  <div class="share-button" ref="button">
    <slot :onClick="onClick" :loading="loading"></slot>
  </div>
</template>

<script>

import shareEvents from '@/utils/shareEvents';
import SharePopup from '@/base-components/share/SharePopup';
import { mapActions, mapGetters } from 'vuex';
import shareUtils from '@/utils/share';
import config from '@/config';

export default {
  name: 'ShareButton',
  props: {
    forceDownload: {
      type: Boolean,
      default: false,
    },
    shareImpression: {
      type: String,
      default: shareUtils.SHARE_IMPRESSION_OPTIONS.ON_SHOW,
    },
    isShareLink: {
      type: Boolean,
      default: false,
    },
    shareText: {
      type: String,
      required: false,
    },
    shareComponent: {
      type: Object,
      required: true,
    },
    shareComponentProps: {
      type: Object,
    },
    shareName: {
      type: String,
      required: true,
    },
    shareType: {
      type: String,
      required: false,
    },
    shareData: {
      type: Object,
      required: false,
    },
    filename: {
      type: String,
      required: false,
    },
    showPopup: {
      type: Boolean,
      default: true,
    },
    segmentExtraProps: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    if (this.shareImpression === shareUtils.SHARE_IMPRESSION_OPTIONS.ON_SHOW) {
      this.sendImpressionEvent();
    }
  },
  computed: {
    ...mapGetters('featureFlags', ['shouldSharePreviewImages']),
    segmentData() {
      return {
        shareName: this.shareName,
        forceDownload: this.forceDownload,
        isShareLink: this.isShareLink,
        ...this.segmentExtraProps,
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('sharePage', ['createSharePage']),
    async onClick() {
      if (this.shareImpression === shareUtils.SHARE_IMPRESSION_OPTIONS.ON_CLICK) {
        this.sendImpressionEvent();
      }
      if (this.showPopup) {
        this.openSharePopup();
        return;
      }
      this.sendNativeShareClicked();
      if (this.isShareLink) {
        await this.saveShareLink();
        return;
      }
      await this.createShareAsset();
    },
    openSharePopup() {
      this.sendShareIntentEvent();
      this.openModal({
        component: SharePopup,
        props: {
          shareComponent: this.shareComponent,
          shareComponentProps: this.shareComponentProps,
          filename: this.filename,
          segmentData: this.segmentData,
          shareText: this.shareText,
        },
      });
    },
    async saveShareLink() {
      this.loading = true;
      const sharePage = {
        shareContent: {
          data: this.shareData,
          shareType: this.shareType,
        },
        ...(this.shouldSharePreviewImages ? { previewImage: await this.getImageObject() } : {}),
      };
      const { sharePageId } = await this.createSharePage(sharePage);
      const shareLinkUrl = `${config.get().elsaPublicUrl}/shared/${sharePageId}`;
      await shareUtils.shareLink(shareLinkUrl, this.shareText, this.segmentData);
      this.loading = false;
    },
    sendImpressionEvent() {
      shareEvents.trackImpression(this.segmentData);
    },
    sendShareIntentEvent() {
      shareEvents.trackShareIntentClicked(this.segmentData);
    },
    sendNativeShareClicked() {
      shareEvents.trackNativeShareClicked(this.segmentData);
    },
    async createShareAsset() {
      this.loading = true;
      try {
        const canvas = await this.createCanvas();
        await shareUtils.shareImage(canvas, `${this.filename}.png`, this.segmentData, this.forceDownload);
      } finally {
        this.loading = false;
      }
    },
    async getImageObject() {
      const canvas = await this.createCanvas();
      const dataUrl = canvas.toDataURL('image/jpeg', 0.5);
      // Regex taken is format of DataUrl and taken from here: https://stackoverflow.com/questions/11335460/how-do-i-parse-a-data-url-in-node
      // It is important to first split the data and not regex all the way because regex on large object is VERY slow.
      const [prefix, data] = dataUrl.split(',');
      const [, type] = prefix.match(/^data:(.+\/.+);base64$/);
      return { type, data };
    },
    async createCanvas() {
      // We're using the parent of the button in order to inherit the closest css properties to the current location we're in
      // but without getting the css properties of the button (text color for example)
      return await shareUtils.createCanvas(this.shareComponent, this.shareComponentProps, this.$refs.button.parentNode);
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
