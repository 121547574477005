import moment from 'moment';
import promotions from '@/constants/promotions';
import PromotionsApi from '../../api/PromotionsApi';

function _promotionExpired(validUntil) {
  return validUntil && moment().isAfter(moment(state.validUntil));
}

const state = {
  promotion: null,
  promotionType: null,
  benefitAmount: null,
  redeemed: null,
  validUntil: null,
  validatedPromoCode: null,
  benefitPercentage: null,
  benefitType: promotions.BENEFIT_TYPES.FREE_MONTHS,
};

const getters = {
  isPromotionEligible: state => {
    if ((state.promotionType === promotions.PROMOTION_TYPES.ONE_TIME && state.redeemed)
      || _promotionExpired(state.validUntil)) {
      return false;
    }
    return true;
  },
  isPercentageDiscountPromo: (state, getters) => state.promotion && getters.isPromotionEligible
    && state.benefitType === promotions.BENEFIT_TYPES.PERCENTAGE_DISCOUNT,
  isFreeMonthsPromo: (state, getters) => state.promotion && getters.isPromotionEligible
    && state.benefitType === promotions.BENEFIT_TYPES.FREE_MONTHS,
};

const actions = {
  async fetchPromotion({ dispatch }, promoCode) {
    const promotion = await PromotionsApi.getPromotion(promoCode);
    await dispatch('setPromotion', promotion);
  },
  async setPromotion({ commit }, promotion) {
    if (promotion) {
      commit('setPromotion', promotion.name);
      commit('setPromotionType', promotion.promotionType);
      commit('setBenefitType', promotion.benefitType);
      commit('setBenefitPercentage', promotion.benefitPercentage);
      commit('setRedeemed', promotion.redeemed);
      commit('setBenefitAmount', promotion.benefitAmount);
      commit('setValidUntil', promotion.validUntil);
      commit('setPromoCode', promotion.promoCode);
    }
  },
  async createPromotionForSource({ dispatch }, promoSource) {
    const promotion = await PromotionsApi.createPromotionForSource(promoSource);
    await dispatch('setPromotion', promotion);
  },
};

const mutations = {
  setPromotion(state, promotion) {
    state.promotion = promotion;
  },
  setBenefitAmount(state, benefitAmount) {
    state.benefitAmount = benefitAmount;
  },
  setBenefitType(state, benefitType) {
    state.benefitType = benefitType;
  },
  setBenefitPercentage(state, benefitPercentage) {
    state.benefitPercentage = benefitPercentage;
  },
  setPromotionType(state, promotionType) {
    state.promotionType = promotionType;
  },
  setRedeemed(state, redeemed) {
    state.redeemed = redeemed;
  },
  setValidUntil(state, validUntil) {
    state.validUntil = validUntil;
  },
  setPromoCode(state, promoCode) {
    state.validatedPromoCode = promoCode;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
