<template>
  <div class="options-question">
    <div class="title"> {{ title }}</div>
    <div :class="getClasses(option)" v-for="option in options" :key="option" @click="onOptionSelected(option)">
      <img :src="require(`@/assets/${getImageName(option)}.svg`)">
      <div class="option-text">{{ option }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelectQuestion',
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    defaultResult: {
      type: Array,
      required: false,
    },
    onOptionChanged: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedOptions: this.defaultResult || [],
    };
  },
  methods: {
    getImageName(option) {
      return this.isOptionSelected(option) ? 'checkbox-blue-marked' : 'unchecked-circle';
    },
    getClasses(option) {
      return {
        option: true,
        'option-selected': this.isOptionSelected(option),
      };
    },
    isOptionSelected(option) {
      return this.selectedOptions.includes(option);
    },
    onOptionSelected(option) {
      if (this.isOptionSelected(option)) {
        this.selectedOptions = this.selectedOptions.filter(r => r !== option);
      } else {
        this.selectedOptions.push(option);
      }
      this.onOptionChanged(this.selectedOptions);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.options-question {
  .title {
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
  }

  .option {
    display: flex;
    align-items: center;
    height: 76px;
    border: solid 1px $riseup_gray_0;
    border-radius: 6px;
    margin-bottom: 8px;
    padding: 26px 20px;
    cursor: pointer;
  }

  .option-text {
    margin-right: 16px;
    font-size: 16px;
    line-height: 24px;
  }

  .option-selected {
    font-weight: 700;
    color: $riseup_blue;
    border-color: $riseup_blue;
  }
}
</style>
