<template>
  <div class="move-transaction-to-saving">
    <colored-popup :class="categoryToVariant[cashflowCategory.cashflowCategory]"
                   :back-action="goBack">

      <template v-slot:top-content>
        <div class="top-content">
          <div class="transaction-title">
            <span class="name">הוצאה {{cashflowCategory.label}}</span>
          </div>
          <transaction-details :transaction="transaction" />
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="details">
          <div class="content">
            <div class="ri-font-weight-bold">הפקדה חד-פעמית לחיסכון</div>
            <div>האם להשאיר אותה בהוצאות של החודש או להוציא אותה?</div>
          </div>

          <expandable-section header-text="מתי כדאי להוציא?"
                              @expand-change="moreInfoClicked">
            <template v-slot:content>
              <div class="ri-body">
                <div>אם זה סכום משמעותי שצברת בעו״ש ועכשיו העברת אותו לחיסכון, כנראה שכדאי להוציא אותו מהתזרים.</div>
                <div>חשוב לשים לב שלהשאיר בתזרים אומר שיתרת המשתנות החודש תצטמצם.</div>
              </div>
            </template>
          </expandable-section>
        </div>

        <riseup-button size="slim" :title="`להשאיר בתזרים ${budgetDate}`" :action="() => openSummary('include')"/>

        <or-separator/>
        <riseup-button size="slim" :title="`להוציא מתזרים ${budgetDate}`" :action="() => openSummary('exclude')"/>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import * as HamsterApi from '@/api/HamsterApi';
import cashflowViewConsts from '@/constants/cashflow-view';
import CashflowViewConsts from '@/mixins/CashflowViewConsts';
import dateUtils from '@/utils/dates';
import EventBus from '@/event-bus/event-bus';
import TransactionMovedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/TransactionMovedToaster';
import SegmentTracker from '@/mixins/SegmentTracker';
import TransactionDetails from '../TransactionDetails';
import MoveTransactionToSaving from './MoveTransactionToSaving';
import MoveTransactionToSavingSummary from './MoveTransactionToSavingSummary';

export default {
  name: 'MoveOneTimeTransactionToSaving',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    TransactionDetails,
    RiseupButton: BaseUI.RiseupButton,
    ExpandableSection: BaseUI.ExpandableSection,
    OrSeparator: BaseUI.OrSeparator,
  },
  props: {
    transaction: { type: Object, required: true },
    categoryId: { type: String, required: false },
    categoryName: { type: String, required: true },
  },
  mixins: [CashflowViewConsts, SegmentTracker],
  created() {
    this.initTracker('MoveOneTimeTransactionToSaving_', { transaction: this.transaction });
    this.track('Entered');
  },
  computed: {
    ...mapGetters('editTransaction', ['categoryToVariant', 'ENRICHMENT_INPUT_TYPES']),
    ...mapGetters('cashflowView', ['oneTimeSavingCategory']),
    cashflowCategory() {
      return this.getCashflowCategory({
        categoryName: this.categoryName,
        categoryId: this.categoryId,
      });
    },
    budgetDate() {
      return dateUtils.getHebrewMonth(this.transaction.transactionBudgetDate);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('editCashflow', ['moveTransaction', 'setComment', 'excludeExpense']),
    openSummary(savingType) {
      this.track('ToOneTimedSaving_NextClicked', { keepInCashflow: savingType });
      this.closeModal();
      this.openModal({
        component: MoveTransactionToSavingSummary,
        props: {
          transaction: this.transaction,
          fromCategory: this.cashflowCategory,
          toCategory: savingType === 'exclude' ? this.exclusionCategory : this.fixedSavingCategory,
          backContext: { backComponent: 'MoveOneTimeTransactionToSaving', props: this.$props },
        },
        eventHandlers: {
          save: event => {
            event.stopPropagation();
            this.onSave(savingType);
          },
        },
      });
    },
    moreInfoClicked() {
      this.track('ExplainWhenToExclude_Clicked');
    },
    async onSave(savingType) {
      await HamsterApi.markAsSavingTransaction(this.transaction.transactionId);
      if (savingType === 'exclude') {
        this.track('ToExcludedSaving_SavedClicked');
        const commentPrefix = 'חיסכון 💰';
        const comment = commentPrefix + (this.transaction.customerComment ? `: ${this.transaction.customerComment}` : '');
        this.setComment({ transactionId: this.transaction.transactionId, comment });
        this.excludeExpense({ transactionId: this.transaction.transactionId });
        EventBus.$emit('open-toaster', {
          component: TransactionMovedToaster,
          props: {
            changedTransaction: { ...this.transaction },
            targetCategory: this.exclusionCategory,
          },
        });
        return;
      }

      if (savingType === 'include') {
        this.track('ToOneTimeSaving_SavedClicked');
        this.moveTransaction({
          originalCategory: this.cashflowCategory,
          selectedCategory: {
            cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.ONE_TIME_SAVING,
            categoryId: this.oneTimeSavingCategory.categoryId,
          },
          transaction: this.transaction,
          categoryRuleType: this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID,
          valueForPredicate: this.transaction.transactionId,
        });
        EventBus.$emit('open-toaster', {
          component: TransactionMovedToaster,
          props: {
            changedTransaction: { ...this.transaction, isSaving: true },
            targetCategory: { pluralLabel: cashflowViewConsts.SAVING_PLURAL_LABEL },
          },
        });
      }
    },
    goBack() {
      this.track('BackClicked');
      this.closeModal();
      this.openModal({
        component: MoveTransactionToSaving,
        props: this.$props,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.move-transaction-to-saving {
  text-align: right;

  .top-content {

    .transaction-title {
      margin-bottom: 16px;
      line-height: 11px;
      .name {
        font-weight: bold;
      }
    }
  }

  .details {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;

    .title {
      font-weight: bold;
    }

    .content {
      margin-bottom: 10px;
    }

  }
}
</style>
