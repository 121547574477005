<template>
  <toaster @undo-action="undoAction" @close-toaster="closeToaster" has-undo>
    <template v-slot:text>
      ההודעה לא תוצג יותר
    </template>
  </toaster>
</template>

<script>
import EventBus from '@/event-bus/event-bus';
import Toaster from '../../../../../../../base-components/Toaster';

export default {
  name: 'DismissInsightToaster',
  components: {
    Toaster,
  },
  props: {
    undo: {
      type: Function,
      required: true,
    },
  },
  methods: {
    undoAction() {
      this.closeToaster();
      this.undo();
    },
    closeToaster() {
      EventBus.$emit('close-toaster');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
