<template>
  <div class="connection-failure-base">
    <div class="header">
      <div v-if="closeAction" class="close-wrapper" v-on:click="closeAction">
        <img :src="require('@/assets/icons/ic-close.svg')" class="close">
      </div>
    </div>
    <div class="connection-failure">
      <div class="content">
        <div>
          <img :src="require('@/assets/bank-connection-failure.png')" class="failure-in-full-screen"/>
        </div>
        <div class="title">
          <slot name="title"></slot>
        </div>
        <div class="subtitle">
          <slot name="subtitle"></slot>
        </div>
        <div class="button-wrapper">
          <riseup-button v-for="(button, i) of buttons"
                         class="button"
                         :id="button.id"
                         :action="button.action"
                         :icon="button.icon"
                           :title="button.title"
                           :variant="button.variant"
                           :size="button.size"
                           :key="i"/>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ConnectionFailure',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    buttons: {
      type: Array,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '@/scss/mixins';
  .connection-failure-base {
    display: flex;
    flex-direction: column;
    height: 100%;
    .connection-failure {
      width: 100%;
      height: 100%;
      flex-grow: 1;

      .content {
        color: $riseup_black;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0 27px;
        margin-bottom: 32px;

        .title {
          font-size: 24px;
          line-height: 32px;
          font-weight: bold;
          margin-bottom: 24px;
        }
        .subtitle {
          font-size: 18px;
          line-height: 25px;
        }

        .button-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          flex-grow: 1;

          .button {
            margin-bottom: 20px;
          }
        }
      }
    }

    .header {
      z-index: 10;
      position: -webkit-sticky;
      position: sticky;
      background-color: $riseup_white;
      top: 0;
      height: 54px;
      width: 100%;
      border-bottom: solid 1px $riseup_gray_0;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      .close-wrapper {
        color: $riseup_black;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 20px;

        .close {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
</style>
