<template>
  <div class="base-credit-card margin-bottom-l">
    <div class="hosted-field" :class="{ focus : focused, empty: empty, error : !!errorMessage }" :id="[ hostFieldId ]"></div>
    <label class="hosted-field-name">{{ title }}</label>
    <label v-if="!!errorMessage" class="top-left-label">{{ errorMessage }}</label>
  </div>
</template>

<script>

export default {
  name: 'BaseCreditCard',
  props: {
    hostFieldId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      required: true,
    },
    focused: {
      type: Boolean,
      required: true,
    },
    empty: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@riseupil/base-ui/src/scss/riseup-colors';
  @import '@riseupil/base-ui/src/scss/border-radius';

  .base-credit-card {
    position: relative;

    .hosted-field {
      padding: 1px 8px 3px 0;
      height: 40px;
      border: 1px solid $riseup_gray_2;
      border-radius: $input-field-border-radius;

      &:focus {
        outline: none;
        border: 1px solid #4a90e2;
      }

      &:not(.empty):not(:focus) ~ .hosted-field-name,
      &:focus ~ .hosted-field-name {
        position: absolute;
        top: -13px;
        right: 0;
        transition: 300ms ease all;
        font-family: SimplerPro;
        font-size: 12px;
        color: $riseup_blue;
      }

      &:not(.empty):not(:focus) {
        border: 1px solid $riseup_blue;
      }

      &:not(.empty).error {
        border: 1px solid $riseup_danger_red;
      }

      &:not(.empty).error ~ .hosted-field-name {
        color: $riseup_danger_red;
      }

      &.empty:not(:focus) ~ .hosted-field-name {
        position: absolute;
        right: 10px;
        top: 5px;
        color: $riseup_gray_2;
        font-size: 20px;
        pointer-events: none;
        transition: 300ms ease all;
        line-height: 1.5;
      }
    }

    .top-left-label {
      position: absolute;
      top: -13px;
      right: -2px;
      width: 100%;
      text-align: left;
      font-size: 12px;
      color: $riseup_gray_2
    }
  }
</style>
