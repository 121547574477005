<template>
  <div class="subscription-flow-v2" v-if="!asyncLoading">
   <purchase-offering-payment v-if="isPayment" :done="goToDone"/>
   <purchase-offering-done v-else/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PurchaseOfferingPayment from './PurchaseOfferingPayment.vue';
import PurchaseOfferingDone from './PurchaseOfferingDone.vue';

export const PURCHASE_OFFERING_STEPS = {
  payment: 'payment',
  done: 'done',
};
export default {
  name: 'PurchaseOfferingFlow',
  components: {
    PurchaseOfferingDone,
    PurchaseOfferingPayment,
  },
  data() {
    return {
      asyncLoading: true,
      flowStep: null,
    };
  },
  computed: {
    isPayment() {
      return this.flowStep === PURCHASE_OFFERING_STEPS.payment;
    },
  },
  async created() {
    this.asyncLoading = false;
    this.flowStep = PURCHASE_OFFERING_STEPS.payment;
  },
  methods: {
    ...mapActions('subscription', ['fetchPricing', 'fetchCreditCardMask', 'fetchSubscription']),
    async goToDone() {
      await this.fetchSubscription();
      this.flowStep = PURCHASE_OFFERING_STEPS.done;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
