<template>
  <sticky-cta-page :cta-props="{buttonProps: { title: button.title}}" @click="button.action">
    <img src="/images/advisory/mislaka-consent-header.svg" />
    <div class="mislaka-consent-success">
      <div class="padding-horizontal-ml">
        <div class="ri-body top-title margin-top-ml margin-bottom-sm">מעולה, קיבלנו!</div>
        <div class="ri-large-headline margin-bottom-xs">תודה שאישרת לנו לפנות בשמך למסלקה הפנסיונית, אנחנו על זה.</div>
        <div class="ri-large-body margin-bottom-ml">
          המידע מהמסלקה יאפשר לנו להציג את הקופות והקרנות שלך ולתפור תוכנית שתתאים לך באמת.
        </div>
        <img src="/images/advisory/mislaka-consent-success.png" class="margin-bottom-ml image" />
      </div>
    </div>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'MislakaConsentSuccess',
  components: {
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
  },
  props: {
    isSecondMember: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    button() {
      if (this.isSecondMember) {
        return {
          title: 'בחזרה לתזרים',
          action: () => {
            Segment.trackUserGot('MislakaConsentSuccess_SecondMemberFinished');
            this.$emit('close');
          },
        };
      }

      return {
        title: 'הבנתי אפשר להמשיך',
        action: this.onContinueClicked,
      };
    },
  },
  created() {
    Segment.trackUserGot('MislakaConsentSuccess_Entered');
  },
  methods: {
    onContinueClicked() {
      Segment.trackUserGot('MislakaConsentSuccess_ContinueClicked');
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.mislaka-consent-success{
  height: 100%;
  text-align: right;

  .top-title{
    color: $riseup_blue;
  }

  .image {
    height: 130px;
    width: 200px;
  }
}

</style>
