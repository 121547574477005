<template>
  <merge-investigation-popup :back-action="cancel"
                             :footer-action="cancel"
                             :footer-title="'בטל'"
                             :title="'מה יקרה כשתאשרו שזה החזר הלוואה?'"
                             :show-contact-c-x-button="false">
    <template v-slot:content>
          <div class="mark-papa-as-loan">
            <div class="mark-as-loan-warning">
              <span>המערכת תתייחס להוצאה זו כהחזר הלוואה</span>
              <span class="bold">ולא תמשיך לשאול לגביה</span>
            </div>
            <papa-merge-question :question="question" :show-add-credit-card-button="false" />
            <riseup-button class="approve-button"
                           :title="'הבנתי, זו הכוונה שלי'"
                           :size="'slim'"
                           :action="markPapaAsLoan"
                           :variant="'primary'" />
          </div>
    </template>
  </merge-investigation-popup>
</template>

<script>
import PapaMergeQuestionsMixin
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/PapaMergeQuestionsMixin';
import BaseUI from '@riseupil/base-ui';
import { mapActions } from 'vuex';
import MergeInvestigationPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/investigations/MergeInvestigationPopup';
import Segment from '@/Segment';

export default {
  name: 'MarkPapaAsLoan',
  components: {
    MergeInvestigationPopup,
    // using dynamic import in order to enable curcular dependency between "PapaMergeQuestion" and "MarkPapaAsLoan" component
    // for more info see here: https://v2.vuejs.org/v2/guide/components-edge-cases.html?redirect=true#Circular-References-Between-Components
    PapaMergeQuestion:
      () => import('@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/merge/PapaMergeQuestion'),
    RiseupButton: BaseUI.RiseupButton,
  },
  mixins: [PapaMergeQuestionsMixin],
  props: {
    question: {
      required: true,
      type: Object,
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal', 'closeAllModals']),
    markPapaAsLoan() {
      Segment.trackUserInteraction('MergeQuestions_Mark_As_Loan_Confirm_Clicked', { source: this.$options.name });
      this.markPapaMergeQuestionAsLoan(this.question);
      this.closeAllModals();
    },
    cancel() {
      Segment.trackUserInteraction('MergeQuestions_Mark_As_Loan_Cancel_Clicked', { source: this.$options.name });
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.mark-papa-as-loan {
  .mark-as-loan-warning{
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  .bold{
    font-weight: bold;
  }

  .papa-question{
    margin-top: 24px;
  }
}

</style>
