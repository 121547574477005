<template>
  <div class="move-transaction-to-saving">
    <colored-popup :class="categoryToVariant[cashflowCategory.cashflowCategory]"
                   :close-action="onClose">

      <template v-slot:top-content>
        <div class="top-content">
          <div class="transaction-title">
            <span class="name">הוצאה {{cashflowCategory.label}}</span>
          </div>
          <transaction-details :transaction="transaction" />
        </div>
      </template>

      <template v-slot:bottom-content>
        <div class="details">
          <div class="title">הפקדה לחיסכון זה מעולה, כל הכבוד!</div>
          <div>היא קבועה או חד-פעמית?</div>
        </div>

        <riseup-button size="slim" title="קבועה"
                       :action="openMoveToFixedExplanation"/>

        <or-separator/>
        <riseup-button size="slim" title="חד-פעמית"
                       :action="openSummary"/>
      </template>

    </colored-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import cashflowViewConsts from '@/constants/cashflow-view';
import CashflowViewConsts from '@/mixins/CashflowViewConsts';
import EventBus from '@/event-bus/event-bus';
import TransactionMovedToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/TransactionMovedToaster';
import SegmentTracker from '@/mixins/SegmentTracker';
import TransactionDetails from '../TransactionDetails';
import MoveTransactionToSavingSummary from './MoveTransactionToSavingSummary';
import MoveOneTimeTransactionToSaving from './MoveOneTimeTransactionToSaving';
import MoveToFixedSavingExplanationPopup from './MoveToFixedSavingExplanationPopup';

export default {
  name: 'MoveTransactionToSaving',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    TransactionDetails,
    RiseupButton: BaseUI.RiseupButton,
    OrSeparator: BaseUI.OrSeparator,
  },
  props: {
    transaction: { type: Object, required: true },
    categoryId: { type: String, required: false },
    categoryName: { type: String, required: true },
  },
  mixins: [CashflowViewConsts, SegmentTracker],
  created() {
    this.initTracker('MoveTransactionToSaving_', { transaction: this.transaction });
    this.track('Entered');
  },
  computed: {
    ...mapGetters('editTransaction', ['categoryToVariant', 'ENRICHMENT_INPUT_TYPES']),
    ...mapGetters('featureFlags', ['excludedSavingsTransactions']),
    ...mapGetters('cashflowView', ['oneTimeSavingCategory']),
    cashflowCategory() {
      return this.getCashflowCategory({
        categoryName: this.categoryName,
        categoryId: this.categoryId,
      });
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('editCashflow', ['moveExpenseToFixed', 'moveTransaction']),
    openMoveToFixedExplanation() {
      this.track('ToFixedSaving_NextClicked');
      this.closeModal();
      this.openModal({
        component: MoveToFixedSavingExplanationPopup,
        props: {
          ...this.$props,
          saveAction: () => {
            this.onSave('fixed');
          },
        },
      });
    },
    openSummary() {
      this.track('ToOneTimedSaving_NextClicked');
      this.closeModal();
      if (this.excludedSavingsTransactions) {
        this.openModal({
          component: MoveOneTimeTransactionToSaving,
          props: {
            transaction: this.transaction,
            categoryId: this.categoryId,
            categoryName: this.categoryName,
          },
        });
      } else {
        this.openModal({
          component: MoveTransactionToSavingSummary,
          props: {
            transaction: this.transaction,
            fromCategory: this.cashflowCategory,
            toCategory: this.fixedSavingCategory,
            backContext: { backComponent: 'MoveTransactionToSaving', props: this.$props },
          },
          eventHandlers: {
            save: event => {
              event.stopPropagation();
              this.onSave('one-time');
            },
          },
        });
      }
    },
    onSave(savingType) {
      if (savingType === 'fixed') {
        this.track('ToFixedSaving_SavedClicked');
        this.moveExpenseToFixed({
          transactionId: this.transaction.transactionId,
          expense: cashflowViewConsts.FIXED_SAVING_CATEGORY_NAME,
        });
      } else if (savingType === 'one-time') {
        this.track('ToOneTimeSaving_SavedClicked');
        this.moveTransaction({
          originalCategory: this.cashflowCategory,
          selectedCategory: {
            cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.ONE_TIME_SAVING,
            categoryId: this.oneTimeSavingCategory.categoryId,
          },
          transaction: this.transaction,
          categoryRuleType: this.ENRICHMENT_INPUT_TYPES.TRANSACTION_ID,
          valueForPredicate: this.transaction.transactionId,
        });
      }

      EventBus.$emit('open-toaster', {
        component: TransactionMovedToaster,
        props: {
          changedTransaction: { ...this.transaction, isSaving: true },
          targetCategory: { pluralLabel: cashflowViewConsts.SAVING_PLURAL_LABEL },
        },
      });
    },
    onClose() {
      this.track('CloseClicked');
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.move-transaction-to-saving {
  text-align: right;

  .top-content {

    .transaction-title {
      margin-bottom: 16px;
      line-height: 11px;
      .name {
        font-weight: bold;
      }
    }
  }

  .details {
    margin-bottom: 34px;
    font-size: 18px;
    line-height: 26px;

    .title {
      font-weight: bold;
    }
  }
}
</style>
