<template>
  <navigation-menu-item
    title="חיסכון לטווח קצר"
    target="balance-money"
    icon-name="potted_plant"
    v-on:navigate="$emit('navigate')"/>
</template>

<script>
import NavigationMenuItem from '@/base-components/app-header/NavigationMenuItem';

export default {
  name: 'BalanceMoneyMenuItem',
  components: {
    NavigationMenuItem,
  },
};
</script>

<style scoped lang="scss">
</style>
