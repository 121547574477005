<template>
  <div class="update-creds-name-popup">
    <colored-line-popup :close-action="closePopup" :class="coloredLinePopupVariant">
      <template v-slot:top-content>
        <connected-credentials-details :source="source"/>
      </template>
      <template v-slot:bottom-content>
        <div class="name-description">איך לקרוא לחשבון?</div>
        <ValidationObserver ref="observer" slim>
          <edit-text-input-field ref="nameInput" :field-type="'text'"
                                 :field-value="name"
                                 :error="nameAlreadyUsedErrorMessage"
                                 :validations="'min-length:2'"
                                 :update-input="updateName"/>
        </ValidationObserver>
        <div class="buttons-wrapper">
          <riseup-button :loading="loading" :size="'small'" :disabled="disabled" :title="'עדכן'" :action="saveUpdatedCreds" :variant="'primary'"/>
        </div>
      </template>
    </colored-line-popup>
  </div>
</template>

<script>
import Segment from '@/Segment';
import ConnectedCredentialsDetails from '@/base-components/credentials/credentials-list/ConnectedCredentialsDetails';
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapState } from 'vuex';
import EditTextInputField from '@/base-components/card-parts/EditTextInputField';
import _ from 'lodash';

export default {
  name: 'UpdateCredsNamePopup',
  components: {
    ColoredLinePopup: BaseUI.ColoredLinePopup,
    RiseupButton: BaseUI.RiseupButton,
    ConnectedCredentialsDetails,
    EditTextInputField,
  },
  props: {
    source: Object,
  },
  data() {
    return {
      name: '',
      loading: false,
      nameAlreadyInUseError: false,
    };
  },
  created() {
    this.name = this.source.name;
  },
  computed: {
    ...mapState('credentials', ['credentialsList']),
    coloredLinePopupVariant() {
      return this.source.status === 'valid' ? 'success' : 'danger';
    },
    disabled() {
      return _.isEmpty(this.name) || this.name === this.source.name;
    },
    nameAlreadyUsedErrorMessage() {
      return this.nameAlreadyInUseError ? 'כבר השתמשת בשם הזה' : null;
    },
  },
  methods: {
    ...mapActions('credentials', ['updateCredentialsName', 'setName']),
    closePopup() {
      Segment.trackUserInteraction('UpdateCreds_CloseClicked', { sourceName: this.source.sourceName, name: this.source.name });
      this.nameAlreadyInUseError = false;
      this.$emit('close');
    },
    updateName(value) {
      this.name = value;
      this.nameAlreadyInUseError = false;
    },
    validateName() {
      const existingCreds = _.find(this.credentialsList, { sourceName: this.source.sourceName, name: this.name });
      this.nameAlreadyInUseError = !!existingCreds;
    },
    async saveUpdatedCreds() {
      this.loading = true;
      Segment.trackUserInteraction(
        'UpdateCreds_UpdateNameClicked',
        { sourceName: this.source.sourceName, oldName: this.source.name, newName: this.name },
      );
      const valid = await this.$refs.observer.validate();
      this.$refs.nameInput.validate();
      this.validateName();
      if (valid && !this.nameAlreadyInUseError) {
        await this.setName({ credentialsId: this.source.credentialsId, name: this.name });
        await this.updateCredentialsName({ source: this.source });
        this.closePopup();
        this.loading = false;
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../scss/mixins';

.update-creds-name-popup {
  text-align: right;

  .name-description {
    font-size: 16px;
    font-weight: bold;
    color: $riseup_black;
    margin-bottom: 20px;
  }

  .buttons-wrapper {
    margin-top: 35px;
    .riseup-button {
      width: 100%;
      margin-bottom: 16px;
    }
  }
}
</style>
