<template>
  <div class="advisory-package-intro-3">
    <div class="content">
      <div class="top-section padding-horizontal-ml">
        <advisory-top-header @close="close" @back="back" variant="blue"/>
        <Stepper :current-step="2" :total-steps="packageStepsNumber"/>
        <div class="ri-title margin-top-ml">חבילת ייעוץ אישי ברייזאפ</div>
        <div class="ri-small-display margin-top-ml ri-alte-bold">
          <span class="pink">סדרת פגישות לתכנון כלכלי שתיקח אותך קדימה</span>
          <span>במחיר בלעדי ללקוחות רייזאפ</span>
        </div>
      </div>
      <div class="curly-container ri-large-body padding-ml padding-top-l">
        <div class="text-container">
          <div class="item margin-bottom-xs ri-font-16">
            <colored-svg class="flower margin-left-s" :color="flowerColor" svg-src="/images/shapes/rounded-eight-points-star.svg" />
            <div>נעשה מיפוי של כל הכספים הקיימים</div>
          </div>
          <div class="item margin-bottom-xs ri-font-16">
            <colored-svg class="flower margin-left-s" :color="flowerColor" svg-src="/images/shapes/rounded-eight-points-star.svg" />
            <div>נגדיר את המטרות שלך לעתיד</div>
          </div>
          <div class="item margin-bottom-xs ri-font-16">
            <colored-svg class="flower margin-left-s" :color="flowerColor" svg-src="/images/shapes/rounded-eight-points-star.svg" />
            <div>נבנה תוכנית כלכלית ברורה</div>
          </div>
          <div class="item margin-bottom-l ri-font-16">
            <colored-svg class="flower margin-left-s" :color="flowerColor" svg-src="/images/shapes/rounded-eight-points-star.svg" />
            <div>נלווה אותך בהתקדמות</div>
          </div>
        </div>
        <riseup-button :action="next"
                       :size="'slim'"
                       title="ספרו לי עוד"
                       :variant="'primary'"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapGetters } from 'vuex';
import AdvisoryTopHeader from '../AdvisoryTopHeader';

export default {
  name: 'AdvisoryPackageIntro3',
  components: {
    Stepper: BaseUI.Stepper,
    RiseupButton: BaseUI.RiseupButton,
    ColoredSvg: BaseUI.ColoredSvg,
    AdvisoryTopHeader,
  },
  data() {
    return {
      flowerColor: BaseUI.Colors.riseupBlue,
    };
  },
  created() {
    Segment.trackUserGot('AdvisoryPackageIntro3');
  },
  computed: {
    ...mapGetters('advisory', ['packageStepsNumber']),
  },
  methods: {
    next() {
      this.$emit('next');
    },
    back() {
      this.$emit('back');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/border-radius';

.advisory-package-intro-3 {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #142AA0;

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    text-align: start;
    color: $riseup_white;

    .pink {
      color: #F89BB4;
    }

    .top-section {
      flex: 3;
    }

    .curly-container {
      background: $riseup_beige;
      border-top-left-radius: 42px;
      border-top-right-radius: 42px;
      flex: 1;
      display: flex;
      flex-direction: column;

      .text-container {
        flex: 1;
        color: $riseup_black;
      }

      .item {
        display: inline-flex;
        align-items: center;
        line-height: 38px;
      }
    }
  }

  .button {
    flex-shrink: 0;
  }

  .flower {
    width: 20px;
    height: 20px;
  }
}
</style>
