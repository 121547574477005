<template>
  <insight :insight="insight"
           image-name="tomer-insight.png"
           button-text="ספרו לי עוד"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div v-if="advisoryInsightNewVariant">
        <span>כשהכסף במסלול הנכון, אפשר</span>
        <span class="ri-font-weight-bold">להיות בראש שקט לגבי העתיד.</span>
        <span>המומחים שלנו לייעוץ כלכלי כאן כדי לעשות לך סדר לשנים הבאות.</span>
      </div>
      <div v-else>
        <span v-if="positiveCashflows === 5">5 תזרימים חיוביים ברצף, שאפו!</span>
        <span v-else>{{positiveCashflows}} תזרימים חיוביים ב־5 חודשים האחרונים, שאפו!</span>
        <span>עם המומחים שלנו לייעוץ כלכלי, אפשר</span>
        <span class="ri-font-weight-bold">להתחיל לחשוב על העתיד.</span>
      </div>
    </template>
  </insight>
</template>

<script>
import Insight from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/Insight';
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import router from '@/router';
import moneyUtils from '../../../../../../utils/money';

export default {
  name: 'AdvisoryInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  created() {
    Segment.trackUserGot('AdvisoryInsight', { variant: this.variant, positiveCashflowsNum: this.positiveCashflows });
  },
  computed: {
    ...mapGetters('featureFlags', ['advisoryInsightNewVariant']),
    positiveCashflows() {
      return this.insight.positiveCashflows;
    },
    previous5MonthsBalanceIncludingSavings() {
      return moneyUtils.formatAmount(moneyUtils.extractRoundedIntegerPart(this.insight.previous5MonthsBalanceIncludingSavings));
    },
    variant() {
      return this.advisoryInsightNewVariant ? 'new' : 'old';
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    async buttonAction() {
      Segment.trackUserInteraction('AdvisoryInsight_ButtonClicked');
      router.push({ path: '/advisory-plan' });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
