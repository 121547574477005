<template>
  <div class="personal-info-form">
    <personal-info-field
      class="field margin-bottom-l"
      :label="'שם פרטי'"
      :field-name="personalInfoFields.firstName"
      :initialValue="primaryMember.firstNamePiiValue"
      :isValid="value => value.length >= 2"
    />
    <personal-info-field
      class="field margin-bottom-l"
      :label="'שם משפחה'"
      :field-name="personalInfoFields.lastName"
      :initialValue="primaryMember.lastNamePiiValue"
      :isValid="value => value.length >= 2"
    />
    <personal-info-field
      class="field margin-bottom-l"
      :label="'מס׳ נייד'"
      :field-name="personalInfoFields.phoneNumber"
      :initialValue="primaryMember.phoneNumberPiiValue"
      :isValid="value => value.length >= 10"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonalInfoField from '../../../subscription/components/PersonalInfoField.vue';

export default {
  name: 'PersonalInfoForm',
  components: {
    PersonalInfoField,
  },
  data() {
    return {
      personalInfoFields: {
        firstName: 'payerFirstName',
        lastName: 'payerLastName',
        phoneNumber: 'payerPhone',
      },
    };
  },
  computed: {
    ...mapGetters('session', ['primaryMember']),
  },
};
</script>

<style scoped lang="scss">
  .personal-info-form {
    padding-top: 10px;
  }

</style>
