<template>
  <div :class="`benefit-notification-box-base ${color}`">
    <img class="icon" :src="require(`@/assets/${iconPath}`)">
    <div class="text-container">
      <div class="title">{{ title }}</div>
      <div class>{{ description }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BenefitNotificationBoxBase',
  props: {
    iconPath: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.benefit-notification-box-base {
  display: flex;
  padding: 20px 15px;
  border-radius: 8px;
  width: 100%;
  background-color: $riseup_beige;
  font-size: 14px;
  line-height: 1.36;

  &.green {
    background-color: $riseup_light_green;
  }

  &.blue {
    background-color: $riseup_light_blue;
  }

  .icon {
    width: 30px;
    height: 30px;
    margin-left: 15px;
  }

  .text-container {
    .title {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
}

</style>
