<template>
  <div>
    <div v-for="(account, index) in accountList" :key="index">
      <account-check-list-item :account="account" :toggleCheck="toggleCheck"/>
        <separator/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import AccountCheckListItem from '@/base-components/AccountCheckListItem';

export default {
  name: 'AccountCheckList',
  components: {
    Separator: BaseUI.Separator,
    AccountCheckListItem,
  },
  props: {
    accountList: {
      type: Array,
      required: true,
    },
    toggleCheck: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../scss/mixins';

</style>
