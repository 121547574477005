<template>
  <div class="step-container">
    <Step>
      <template v-slot:header>
        <StepIndicator :current-step="currentStepNumber" :total-number-of-steps="totalStepNumber"/>
      </template>
      <template v-slot:content>
        <MultiSelectQuestion
            :title="title"
            :onOptionChanged="onOptionChanged"
            :options="options"
            :defaultResult="selectedReasons"
        />
      </template>
      <template v-slot:footer>
        <RiseupButton
            class="next-button"
            title="אפשר להמשיך"
            :action="onClicked"
            :bold="true"
        />
      </template>
    </Step>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { activationSurveyStepsConfig } from '@/pages/responsive-pages/authenticated/survey/activation-survey/consts';
import Step from '../../components/Step';
import StepIndicator from '../../components/StepIndicator';
import MultiSelectQuestion from '../../components/MultiSelectQuestion';

export default {
  name: 'ReasonsStep',
  components: {
    Step,
    StepIndicator,
    MultiSelectQuestion,
    RiseupButton: BaseUI.RiseupButton,
  },
  created() {
    Segment.trackUserGot(`ActivationSurvey${this.activeStep}_PageEntered`);
  },
  computed: {
    ...mapState('activationSurvey', ['answers', 'activeStep']),
    currentStep() {
      return activationSurveyStepsConfig[this.activeStep];
    },
    title() {
      return this.currentStep.question;
    },
    currentStepNumber() {
      return this.currentStep.step.currentStep;
    },
    totalStepNumber() {
      return this.currentStep.step.totalNumberOfSteps;
    },
    options() {
      return this.currentStep.options;
    },
    selectedReasons() {
      return this.answers[this.activeStep];
    },
  },
  methods: {
    ...mapMutations('activationSurvey', ['setActiveStep', 'updateStepAnswer']),
    onOptionChanged(options) {
      this.updateStepAnswer({ stepName: this.activeStep, stepResults: options });
    },
    onClicked() {
      Segment.trackUserGot(`ActivationSurvey${this.activeStep}_NextClicked`, this.answers);
      this.setActiveStep(this.currentStep.nextStep(this.answers));
    },
  },
  watch: {
    activeStep() {
      Segment.trackUserGot(`ActivationSurvey${this.activeStep}_PageEntered`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.step-container {
  height: 100%;

  .next-button {
    height: 54px;
    width: 100%;
  }
}
</style>
