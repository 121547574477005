<template>
  <dynamic-deposit-step :authenticated="false" :step="this.$options.name">
    <template v-slot:content>
      <div class="dd-choose-monthly-amount">
        <div class="dd-headline-label ri-bold-body">עדכון סכום</div>
        <div class="ri-large-headline margin-bottom-s">
          <div>הוראת הקבע הנוכחית שלך היא בסכום של {{ formatAmount(monthlyDeposit) }} ₪.</div>
          <div>בכמה לצמצם?</div>
        </div>
        <div>
          <amount-input
            :label="'הפחתה מהוראת הקבע'"
            :value="reduceFromSaving"
            :error-message="errorMessage"
            @input="setMonthlyDepositSum"
          />
        </div>
        <div v-if="!invalidInput" class="info-input">
          <div v-if="newDepositAmount === 0">
            הוראת הקבע תעודכן ל-
            <span class="new-monthly-deposit">{{ formatAmount(newDepositAmount) }} ₪</span>,
            תמיד אפשר להגדיל אותה חזרה מאזור החסכונות בתפריט
          </div>
          <div v-else>
            הוראת הקבע תעודכן ל-
            <span class="new-monthly-deposit">{{ formatAmount(newDepositAmount) }} ₪</span>
            כל חודש
          </div>
        </div>
      </div>
    </template>
    <template v-slot:next-button>
      <dynamic-deposit-button title="הבא >" :action="verifyDepositSum" :loading="loading" :disabled="invalidInput"/>
    </template>
  </dynamic-deposit-step>
</template>

<script>

import * as _ from 'lodash';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapMutations } from 'vuex';
import moneyUtils from '@/utils/money';
import * as SavingsApi from '@/api/SavingsApi';
import DynamicDepositStep from './DynamicDepositStep';
import DynamicDepositButton from './DynamicDepositButton';

export default {
  name: 'DDReduceMonthlyAmount',
  components: {
    DynamicDepositButton,
    DynamicDepositStep,
    AmountInput: BaseUI.AmountInput,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    showErrorPage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      reduceFromSaving: 0,
    };
  },
  created() {
    Segment.trackUserInteraction('DynamicDeposit_ReduceMonthlyAmount_Entered');
    const previouslySelectedDepositSum = this.depositSum;
    this.reduceFromSaving = previouslySelectedDepositSum ? this.monthlyDeposit - previouslySelectedDepositSum : this.proposedSavingsAmount;
    this.setMonthlyDepositSum(this.reduceFromSaving);
  },
  computed: {
    ...mapGetters('savings', ['monthlyDeposit']),
    ...mapGetters('dynamicSavings', ['proposedSavingsAmount', 'depositSum']),
    newDepositAmount() {
      if (_.isNil(this.reduceFromSaving)) {
        return this.monthlyDeposit;
      }
      return this.monthlyDeposit - this.reduceFromSaving;
    },
    invalidInput() {
      return _.isNil(this.reduceFromSaving) || !!this.errorMessage;
    },
  },
  methods: {
    ...mapMutations('dynamicSavings', ['setDepositSum', 'setProposedSavingsAmount', 'setBankDetails', 'setFundDetails']),

    setMonthlyDepositSum(value) {
      this.errorMessage = '';
      this.reduceFromSaving = value;
      if (_.isNil(value)) {
        return;
      }

      if (this.reduceFromSaving === 0) {
        this.errorMessage = '0 לא משפיע על הוראת הקבע';
        return;
      }

      const newDepositAmount = this.monthlyDeposit - this.reduceFromSaving;
      if (newDepositAmount !== 0 && newDepositAmount < 100) {
        const maxReduction = this.monthlyDeposit - 100;
        this.errorMessage = `אי אפשר לצמצם ביותר מ-${maxReduction} ₪ את הוראת הקבע`;
      }
    },
    formatAmount(value) {
      return moneyUtils.formatAmount(value);
    },
    async verifyDepositSum() {
      this.loading = true;
      Segment.trackUserInteraction('DynamicDeposit_ReduceMonthlyAmount_NextClicked');
      Segment.trackUserInteraction(
        'DynamicDeposit_ReduceMonthlyAmount_EditAmount',
        { diff: -this.reduceFromSaving },
      );
      try {
        const verifyResponse = await SavingsApi.verifyDepositSum(this.newDepositAmount, true);
        if (verifyResponse.status === SavingsApi.VERIFY_DEPOSIT_RESPONSE_TYPES.SUCCESS) {
          this.setDepositSum(this.monthlyDeposit - this.reduceFromSaving);
          const { data: { bankNumber, bankBranch, bankAccountNumber, fundNumber } } = verifyResponse;
          this.setBankDetails({ bankNumber, bankBranch, bankAccountNumber });
          this.setFundDetails({ fundNumber });
          this.nextStep();
        } else {
          this.showErrorPage();
        }
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
  .new-monthly-deposit {
    text-decoration: underline;
  }

  .info-input {
    font-size: 18px;
    line-height: 20px;
  }
</style>
