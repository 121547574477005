<template>
  <component :is="currentStepComponent"
             :onClose="onClose"
             :onBack="onBack"
             :onNext="onNext"
             :onDismiss="onDismiss"
             :onMoneyFundClicked="onMoneyFundClicked"
             :onPakamClicked="onPakamClicked"
             :onBoxClicked="onBoxClicked"
             :onCalculatorClicked="onCalculatorClicked"
             :stepName="currentStepName"
             :origin="origin"
             :hide-snooze="hideSnooze"
  />
</template>

<script>
import Segment from '@/Segment';
import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/event-bus/event-bus';
import BalanceMoneyStep3 from './BalanceMoneyStep3';
import BalanceMoneyStep1 from './BalanceMoneyStep1';
import BalanceMoneyStep2 from './BalanceMoneyStep2';
import BalanceMoneyCalculatorStep from './BalanceMoneyCalculatorStep';
import MoneyFundInfoStep from './MoneyFundInfoStep';
import PakamInfoStep from './PakamInfoStep';
import BoxInfoStep from './BoxInfoStep';
import SnoozeInsightComplete from '../../insights/components/SnoozeInsightComplete';

const BalanceMoneyFlowKeys = {
  InflationPage: 'InflationPage',
  BOIInterestRatePage: 'BOIInterestRatePage',
  OshCalculator: 'OshCalculator',
  InvestmentOptionsPage: 'InvestmentOptionsPage',
  KerenKaspitPage: 'KerenKaspitPage',
  BoxPage: 'BoxPage',
  PakamPage: 'PakamPage',
};

const BalanceMoneyFlowSteps = {
  [BalanceMoneyFlowKeys.InflationPage]: {
    component: BalanceMoneyStep1,
    name: BalanceMoneyFlowKeys.InflationPage,
    nextKey: BalanceMoneyFlowKeys.BOIInterestRatePage,
  },
  [BalanceMoneyFlowKeys.BOIInterestRatePage]: {
    component: BalanceMoneyStep2,
    name: BalanceMoneyFlowKeys.BOIInterestRatePage,
    previousKey: BalanceMoneyFlowKeys.InflationPage,
    nextKey: BalanceMoneyFlowKeys.InvestmentOptionsPage,
  },
  [BalanceMoneyFlowKeys.OshCalculator]: {
    component: BalanceMoneyCalculatorStep,
    name: BalanceMoneyFlowKeys.OshCalculator,
    previousKey: BalanceMoneyFlowKeys.BOIInterestRatePage,
    nextKey: BalanceMoneyFlowKeys.InvestmentOptionsPage,
  },
  [BalanceMoneyFlowKeys.InvestmentOptionsPage]: {
    component: BalanceMoneyStep3,
    name: BalanceMoneyFlowKeys.InvestmentOptionsPage,
    previousKey: BalanceMoneyFlowKeys.BOIInterestRatePage,
  },
  [BalanceMoneyFlowKeys.KerenKaspitPage]: {
    component: MoneyFundInfoStep,
    name: BalanceMoneyFlowKeys.KerenKaspitPage,
    previousKey: BalanceMoneyFlowKeys.InvestmentOptionsPage,
  },
  [BalanceMoneyFlowKeys.PakamPage]: {
    component: PakamInfoStep,
    name: BalanceMoneyFlowKeys.PakamPage,
    previousKey: BalanceMoneyFlowKeys.InvestmentOptionsPage,
  },
  [BalanceMoneyFlowKeys.BoxPage]: {
    component: BoxInfoStep,
    name: BalanceMoneyFlowKeys.BoxPage,
    previousKey: BalanceMoneyFlowKeys.InvestmentOptionsPage,
  },
};

export default {
  name: 'BalanceMoneyFlow',
  data() {
    return {
      currentStepKey: BalanceMoneyFlowKeys.InflationPage,
    };
  },
  props: {
    insight: {
      type: Object,
      require: false,
    },
    origin: {
      type: String,
      require: true,
    },
  },
  created() {
    Segment.trackUserInteraction('BalanceMoneyFlow_Shown', {
      possibleProfit: this.possibleProfit,
      origin,
    });
  },
  computed: {
    ...mapGetters('balance', ['totalOshBalance']),
    ...mapGetters('featureFlags', ['inflationRate', 'israelBankInterest']),
    currentStep() {
      return BalanceMoneyFlowSteps[this.currentStepKey];
    },
    currentStepComponent() {
      return this.currentStep.component;
    },
    currentStepName() {
      return this.currentStep.name;
    },
    possibleProfit() {
      return Math.round(this.totalOshBalance * (this.israelBankInterest / 100));
    },
    hideSnooze() {
      return !this.insight || this.insight.snoozed;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal', 'openModal']),
    ...mapActions('insights', ['markAsRead', 'snooze']),
    onBack() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedPreviousStep', { currentStep: this.currentStepName });
      this.currentStepKey = this.currentStep.previousKey;
      EventBus.$emit('reset-scroll');
    },
    onNext() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedNextStep', { currentStep: this.currentStepName });
      this.currentStepKey = this.currentStep.nextKey;
      EventBus.$emit('reset-scroll');
    },
    onCalculatorClicked() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedNextStep', { currentStep: this.currentStepName });
      this.currentStepKey = BalanceMoneyFlowKeys.OshCalculator;
      EventBus.$emit('reset-scroll');
    },
    onPakamClicked() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedNextStep', { currentStep: this.currentStepName });
      this.currentStepKey = BalanceMoneyFlowKeys.PakamPage;
      EventBus.$emit('reset-scroll');
    },
    onMoneyFundClicked() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedNextStep', { currentStep: this.currentStepName });
      this.currentStepKey = BalanceMoneyFlowKeys.KerenKaspitPage;
      EventBus.$emit('reset-scroll');
    },
    onBoxClicked() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedNextStep', { currentStep: this.currentStepName });
      this.currentStepKey = BalanceMoneyFlowKeys.BoxPage;
      EventBus.$emit('reset-scroll');
    },
    onClose() {
      Segment.trackUserInteraction('BalanceMoneyFlow_CloseClicked', { currentStep: this.currentStepName });
      this.closeModal();
    },
    onDismiss(isRemindMeLater) {
      Segment.trackUserInteraction(
        `BalanceMoneyFlow_${isRemindMeLater ? 'RemindMeLaterClicked' : 'ClickedDismissInsight'}`,
        { currentStep: this.currentStepName },
      );
      this.closeModal();
      if (this.insight) {
        if (isRemindMeLater) {
          this.openModal({
            component: SnoozeInsightComplete,
          });
          this.snooze({ insight: this.insight, timeAmount: 1, timeType: 'weeks' });
        } else {
          this.markAsRead(this.insight);
        }
      }
    },
  },
};
</script>

<style scoped>

</style>
