<template>
  <insight :insight="insight"
           image-name="accumulation-high-insight.png"
           button-text="להתקדמות שלי"
           :button-action="navigateToMyProgress">
    <template v-slot:insight-text>
      <div v-if="activationBeforeEarliestCashflow">
        מאז ינואר 2022
        <span class="ri-font-weight-bold">צברת ברייזאפ סה״כ {{ formattedAmount }} ₪, מדהים!</span>
        רוצה לגלות עוד על ההתקדמות שלך ברייזאפ?
      </div>
      <div v-else>
        מאז שהצטרפת לרייזאפ
        <span class="ri-font-weight-bold">צברת סה״כ {{ formattedAmount }} ₪, מדהים!</span>
        רוצה לגלות עוד על ההתקדמות שלך ברייזאפ?
      </div>
    </template>
  </insight>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moneyUtils from '@/utils/money';
import moment from 'moment';
import Insight from './components/Insight';

export default {
  name: 'MyProgressInsight',
  components: {
    Insight,
  },
  props: {
    insight: {
      type: Object,
      require: true,
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapState('applicationState', ['activationDate']),
    ...mapActions('insights', ['markAsRead']),
    async navigateToMyProgress() {
      this.closeAction();
      this.$router.push({ path: '/my-progress' });
    },
    closeAction() {
      this.markAsRead(this.insight);
      this.closeModal();
    },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.totalCashflowsAndSavingsSinceActivation, 0);
    },
    activationBeforeEarliestCashflow() {
      return this.activationDate && moment(this.activationDate).isBefore(moment('2022-01-01'), 'month');
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
