<template>
  <colored-popup :class="'light-blue'" :back-action="onBack" :dark-text="true">
    <template v-slot:top-content>
      <div class="centered">
        <img class="balance-money-logos logo" src="/images/saving-zone/balance-money/balance-money-asset.png"/>
        <div class="title margin-top-large">קרן כספית</div>
      </div>
    </template>

    <template v-slot:bottom-content>
      <div class="body-text margin-bottom-x-large">
        <div class="margin-bottom-sm">קרנות כספיות אפשר לקנות בקלות בכל הבנקים.</div>
        <div>כדי לעשות את זה, צריך להיכנס לאזור שוק ההון או מסחר בניירות ערך, לחפש את הקרן הרצויה, להזין את הסכום המבוקש ולאשר.</div>
        <div class="margin-top-m">
          <icon-and-text v-bind="iconAndTextProps">
            <template v-slot:text>לפעמים לוקח יום או יומיים עד שהרכישה נכנסת לתוקף</template>
          </icon-and-text>
          <icon-and-text v-bind="iconAndTextProps">
            <template v-slot:text>כדאי לדעת שהבנק לא יכול לגבות עמלת קנייה או מכירה על קרן כספית</template>
          </icon-and-text>

        </div>
        <div class="margin-top-medium margin-bottom-large">

        </div>
        <callout iconPath="icons/video-icon-blue.svg">
          <template v-slot:text>
            תומר ורון, היועץ הפיננסי שלנו,  מסביר עוד על קרן כספית <a class="link" @click="tomerVideoClicked">כאן</a>.
          </template>
        </callout>
      </div>
    </template>

    <template v-slot:footer v-if="!hideSnooze">
      <div class="centered">
        <a class="link-button" @click="remindMeLaterClicked">
          מעניין, תזכירו לי אח"כ
        </a>
      </div>
    </template>
  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'MoneyFundStepIntro',
  components: {
    ColoredPopup: BaseUI.ColoredPopup,
    Callout: BaseUI.Callout,
    IconAndText: BaseUI.IconAndText,
  },
  props: {
    onBack: {
      type: Function,
      required: true,
    },
    onDismiss: {
      type: Function,
      required: true,
    },
    stepName: {
      type: String,
      required: true,
    },
    hideSnooze: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconAndTextProps: {
        iconSize: '16px',
        fillIcon: true,
        class: 'balance-money-icon-and-text',
        iconFontName: 'check_circle',
        iconColor: BaseUI.Colors.riseupBlue,
      },
    };
  },
  created() {
    Segment.trackUserInteraction('BalanceMoneyFlow_StepEntered', { step: this.stepName });
  },
  methods: {
    tomerVideoClicked() {
      Segment.trackUserInteraction('BalanceMoneyFlow_KerenKaspit_TomerVideo_Clicked');
      window.open('https://bit.ly/3TB2WF3', '_blank');
    },
    remindMeLaterClicked() {
      this.onDismiss(true);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/balance-money/balance-money-styles";
.logo {
  height: 182px;
  width: 262px;
}
</style>
