import _ from 'lodash';
import moment from 'moment';
import consts from '@/constants/transaction-stories-consts';
import cashflowHistory from '../utilities/cashflow-history';

const state = {
  selectableTransactions: [],
  title: '',
};

const getters = {
  selectedTransactions: state => _.chain(state.selectableTransactions)
    .filter(t => t.selected)
    .orderBy(['transactionDate', 'businessName'])
    .value(),
  storyTransactions: (state, getters) => _.map(getters.selectedTransactions, t => ({
    businessName: t.businessName,
    billingAmount: t.billingAmount,
  })),
};

const actions = {
  async initTransactions({ commit }) {
    const presentedBudgetDates = cashflowHistory.getPresentedBudgetDates();
    const budgets = await cashflowHistory.getBudgets(presentedBudgetDates);
    const transactions = _.chain(budgets)
      .flatMap(budget => budget.envelopes)
      .filter(_envelopesIsVariableOrTracking)
      .flatMap(envelope => envelope.actuals)
      .map(transaction => _.extend({}, transaction, { selected: false, transactionDate: moment(transaction.transactionDate) }))
      .value();
    commit('setSelectableTransactions', transactions);
    commit('setTitle', consts.STORY_SETTINGS[consts.TITLES.BLUE_WHITE].label);
  },
  setSelectedTransactions({ commit }, transactions) {
    commit('setSelectableTransactions', _.map(transactions, t => ({ ...t, selected: true })));
  },
  toggleSelection({ commit }, transactionId) {
    commit('selectTransaction', transactionId);
  },
  setTitle({ commit }, title) {
    commit('setTitle', title);
  },
};

function _envelopesIsVariableOrTracking(envelope) {
  return envelope.type === 'variable' || envelope.type === 'trackingCategory';
}

// mutations
const mutations = {
  setSelectableTransactions(state, transactions) {
    state.selectableTransactions = transactions;
  },
  setTitle(state, title) {
    state.title = title;
  },
  selectTransaction(state, transactionId) {
    const transaction = _.find(state.selectableTransactions, { transactionId });
    transaction.selected = !transaction.selected;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
