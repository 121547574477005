<template>
  <insight :insight="insight"
           :animation-name="'merge-insight.json'"
           :button-text="'ספרו לי עוד'"
           :background-variant="'red'"
           :loop="false"
           :show-dismiss="false"
           :button-action="()=>openInvestigationPopup(insight.sonsMergeInvestigation)">
    <template v-slot:insight-text>
      <div>
        <span>המערכת זיהתה עסקאות אשראי שיורדות מחשבון בנק שלא מחובר לתזרים.</span>
        <span class="bold">יש חשבון בנק נוסף שצריך לחבר?</span>
      </div>
    </template>
  </insight>
</template>

<script>

import Segment from '@/Segment';
import { mapActions } from 'vuex';
import SonsMergeInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/merge/SonsMergeInsightPopup';
import Insight from './components/Insight';

export default {
  name: 'SonsMergeInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object, required: true },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openInvestigationPopup(sonsMergeInvestigation) {
      Segment.trackUserInteraction('SonsMergeInsight_Clicked');
      this.openModal({
        component: SonsMergeInsightPopup,
        props: {
          investigation: sonsMergeInvestigation,
          insightId: this.insight.insightId,
        },
      });
    },
  },
};

</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
