import _ from 'lodash';
import * as CashflowHomeApi from '@/api/CashflowHomeApi';

const state = {
  maritalStatus: '',
  city: '',
  numberOfKids: null,
};

const actions = {
  async initDemographicInfo({ commit }) {
    const { maritalStatus, numberOfKids, city } = (await CashflowHomeApi.getDemographicInfo()) || {};
    commit('setMaritalStatus', maritalStatus || '');
    commit('setCity', city || '');
    commit('setNumberOfKids', _.isNumber(numberOfKids) ? numberOfKids : null);
  },
  async createDemographicInfo({ commit }, demographicInfo) {
    await CashflowHomeApi.createDemographicInfo(demographicInfo);
  },
  setCity({ commit }, city) {
    commit('setCity', city);
  },
  setKids({ commit }, numberOfKids) {
    commit('setNumberOfKids', _.toNumber(numberOfKids));
  },
  setMaritalStatus({ commit }, maritalStatus) {
    commit('setMaritalStatus', maritalStatus);
  },
};

const mutations = {
  setCity(state, city) {
    state.city = city;
  },
  setNumberOfKids(state, numberOfKids) {
    state.numberOfKids = numberOfKids;
  },
  setMaritalStatus(state, maritalStatus) {
    state.maritalStatus = maritalStatus;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
