<template>
  <card class="payment-details-card" custom-menu>
    <div class="title ri-bold-title padding-bottom-ml">אמצעי קבלת תשלום</div>
    <div class="padding-bottom-sm">
      <img class="logo-image" :src="require(`@/assets/bank-logos/${source.logoFileStacked}`)">
    </div>
    <div class="ri-title padding-bottom-xxs">{{ bankAccountOwner }}</div>
    <div class="ri-bold-body">{{ bankAccountNumber }}</div>
    <template v-slot:menuItems>
      <bottom-sheet-overflow-button :bottom-sheet-component="sheetComponent" :component-props="{ menuItems }" />
    </template>
  </card>
</template>

<script>
import Banks
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/data/Banks';
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import Segment from '@/Segment';
import BankAccounts from '@/mixins/BankAccounts.vue';
import GetPaymentDetailsPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/GetPaymentDetailsPopup';
import SimpleBottomSheetMenu from '@/base-components/SimpleBottomSheetMenu';
import BottomSheetOverflowButton from '@/base-components/BottomSheetOverflowButton';

export default {
  name: 'PaymentDetailsCard',
  mixins: [Banks, BankAccounts],
  components: {
    Card: BaseUI.Card,
    BottomSheetOverflowButton,
  },
  data() {
    return {
      sheetComponent: SimpleBottomSheetMenu,
    };
  },
  computed: {
    ...mapState('referrals', ['referrerInfo']),
    ...mapGetters('referrals', ['bankId', 'bankAccountOwner', 'bankAccountNumber']),
    source() {
      return _.find(this.sortedCombinedBankSources, { bankCode: _.parseInt(this.bankId) });
    },
    menuItems() {
      return [
        {
          text: 'החלפת חשבון',
          iconProps: {
            iconName: 'cached',
            fill: true,
          },
          onClick: this.changePaymentDetails,
        },
        {
          text: 'עריכת פרטים',
          iconProps: {
            iconName: 'edit',
            fill: true,
          },
          onClick: this.editPaymentDetails,
        },
      ];
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    openPaymentPopup(isEditMode) {
      this.openModal({
        component: GetPaymentDetailsPopup,
        popupAlignment: 'full-screen',
        props: {
          afterSave: this.afterSave,
          isEditMode,
        },
      });
    },
    editPaymentDetails() {
      Segment.trackUserInteraction('EditPaymentDetails_Clicked');
      this.openPaymentPopup(true);
    },
    changePaymentDetails() {
      Segment.trackUserInteraction('ChangePaymentDetails_Clicked');
      this.openPaymentPopup(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-details-card {
  padding-top: 24px;
  padding-bottom: 24px;

  .logo-image {
    height: 30px;
  }
}
</style>
