<template>
  <base-credit-card :host-field-id="containerName"
                    :title="'מספר כרטיס'"
                    :error-message="cardNumber.error"
                    :focused="focused"
                    :empty="cardNumber.isEmpty"/>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';

import BaseCreditCard from './BaseCreditCard.vue';

export default {
  name: 'CardNumber',
  components: {
    BaseCreditCard,
  },
  data() {
    return {
      containerName: 'card-number-container',
      blurredOnce: false,
      focused: false,
    };
  },
  async mounted() {
    const cardNumber = this.fields.create(window.PayMe.fields.NUMBER, {
      placeholder: ' ',
      styles: {
        base: {
          'font-size': '20px',
          'text-align': 'right',
          color: '#2b323b',
          '::placeholder': {
            color: '#9b9b9b',
          },
        },
      },
    });
    cardNumber.mount(`#${this.containerName}`);

    cardNumber.on('keyup', this.setKeyUpResult);
    cardNumber.on('blur', this.markAsBlurred);
    cardNumber.on('focus', this.markAsFocused);
  },
  computed: {
    ...mapState('subscription', ['fields', 'creditCard']),
    ...mapGetters('subscription', ['creditCardFields']),
    cardNumber() {
      return this.creditCard[this.creditCardFields.cardNumber];
    },
  },
  methods: {
    ...mapActions('subscription', ['setCreditCardField']),
    setKeyUpResult(event) {
      const { isValid, isEmpty } = event;
      this.setCreditCardField({ field: this.creditCardFields.cardNumber, isValid, isEmpty });
    },
    markAsBlurred() {
      this.blurredOnce = true;
      this.focused = false;
    },
    markAsFocused() {
      this.focused = true;
    },
  },
};
</script>
