import { client, clientWithoutErrorHandling } from '../http';

export const OFFERING_TYPES = {
  ONE_OFF: 'oneOff',
  SUBSCRIPTION: 'subscription',
  BUNDLE: 'bundle',
};
export default {

  async updateSubscriptionV2({ paymentToken = null, card = null, iterationType }) {
    const response = await clientWithoutErrorHandling.put('/api/v2/subscription', { paymentToken, card, iterationType });
    return response.data;
  },

  async purchaseGiftcard(token, email) {
    const response = await clientWithoutErrorHandling.post('/api/sale', { token, email, productType: 'GIFTCARD' });
    return response.data;
  },
  async purchaseOffering({ token, oneOffOfferings, subscriptionOfferings, bundleOfferings, email }) {
    const response = await client.post(
      '/api/v2/offerings/purchase-new',
      { token, oneOffOfferings, subscriptionOfferings, bundleOfferings, email },
    );
    return response.data;
  },

  async purchaseOfferings({ token, subscriptionOfferings, bundleOfferings, oneOffOfferings }) {
    const response = await client.post(
      '/api/v2/offerings/purchase-new',
      { token, oneOffOfferings, subscriptionOfferings, bundleOfferings },
    );
    return response.data;
  },

  async getCreditCardDetails() {
    const response = await clientWithoutErrorHandling.get('/api/credit-card-details');
    return response.data;
  },

  async getSubscriptionV2() {
    const response = await clientWithoutErrorHandling.get('/api/v2/subscription');
    return response.data;
  },

  async fetchPricingOption() {
    const response = await client.get('/api/pricing-options');
    return response.data;
  },

  async getOfferings() {
    const response = await client.get('/api/v2/offerings');
    return response.data;
  },

  async claimVoucher(vendor, worth, price, credentialsId, ibanPiiId) {
    const response = await clientWithoutErrorHandling.post('/api/vouchers/claim', {
      vendor,
      worth,
      price,
      fromCredentialsId: credentialsId,
      fromIbanAccountPiiId: ibanPiiId,
    });
    return response.data;
  },

  async voucherStatusForMember(vendor) {
    const response = await client.get(`/api/vouchers-status/${vendor.toLowerCase()}`);
    return response.data;
  },
  async customerVoucher() {
    const response = await client.get('/api/vouchers');
    return response.data;
  },
  async completeVoucherPurchaseByPaymentRecordId(paymentRecordId, accessCode) {
    const response = await clientWithoutErrorHandling.post('/api/vouchers/complete-purchase', { paymentRecordId, accessCode });
    return response.data;
  },
  async availableVouchers(vendor) {
    const response = await client.get('/api/voucher/available');
    const { vouchers } = response.data;
    const vendorVouchers = vouchers.find(item => item.vendor === vendor);
    return vendorVouchers && vendorVouchers.count > 0;
  },

  async registerForSpecialTwoYearsOffering() {
    await client.put('/api/v2/special-offering-subscription');
  },

  async getPaymentDetails() {
    const response = await client.get('/api/payment-details');
    return response.data;
  },
};
