<template>
  <info-tool-tip-help-popup class="leumi-card-max-creds-help-popup"
    :title="'איך לחבר חשבון לאומי קארד max?'"
    :cx-dude="{ name: 'yael', displayName: 'יעל' }"
    :source-name="'לאומי קארד'"
    :popup-name="'LeumiCardMaxCredsHelpPopup'"
    :on-close="onClose">
    <template v-slot:description>
      <div class="description">
        <div>
          כדי לחבר את max (לאומי קארד) ל-riseup צריך לספק למערכת את פרטי המשתמש הקבועים
          (כולל סיסמא קבועה) איתם נכנסים לאתר או לאפליקציה של max.
        </div>
        <div>&nbsp;</div>
        <div class="bold">שכחת את שם המשתמש והסיסמא הקבועה?</div>
        אפשר לשחזר אותם <a target="_blank" rel="noopener noreferrer"
           href="https://www.max.co.il/auth/recover/enterid">בלינק הזה</a>
        <div>&nbsp;</div>
        <div class="bold">אין לך שם משתמש וסיסמא קבועה?</div>
        אפשר ליצור אותם <a target="_blank" rel="noopener noreferrer"
           href="https://online.max.co.il/Anonymous/Registration.aspx">בלינק הזה</a>
      </div>
    </template>
  </info-tool-tip-help-popup>
</template>

<script>

import InfoToolTipHelpPopup from '../InfoToolTipHelpPopup.vue';

export default {
  name: 'LeumiCardMaxCredsHelpPopup',
  components: {
    InfoToolTipHelpPopup,
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../../scss/mixins';

  .leumi-card-max-creds-help-popup {
    .description {
      a {
        color: $riseup_blue;
        font-weight: bold;
      }
      .bold {
        font-weight: bold;
      }
    }
  }

</style>
