import store from '@/store';

// eslint-disable-next-line import/prefer-default-export
export async function getNextRouteBySubscriptionState(to) {
  if (to.params.offerId || to.query.offerId) {
    const offerId = to.params.offerId || to.query.offerId;
    const routeConsideringSubscriptionState = await _calculatePurchaseRouteBySubscriptionState(offerId);
    if (routeConsideringSubscriptionState.path !== to.path) {
      return routeConsideringSubscriptionState;
    }
  }
  // Need to return undefined to continue with the navigation
  return undefined;
}

async function _calculatePurchaseRouteBySubscriptionState(offerId) {
  await store.dispatch('subscriptionState/fetchSubscriptionState');
  if (store.getters['subscriptionState/subscribed'] || store.getters['subscriptionState/isFree']) {
    return { path: `/one-off/${offerId}` };
  }
  return { path: `/offerings/${offerId}` };
}
