<template>
  <div class="mislaka-consent-form">
    <img src="/images/advisory/mislaka-consent-header.svg" />
      <div class="padding-ml">
        <validation-observer ref="validationObserver" v-slot="{invalid}">
        <div class="ri-body top-title margin-bottom-sm">מיפוי התמונה הכלכלית שלך</div>
        <div class="ri-large-headline margin-bottom-xl">הסכמה לפנייה חד־פעמית למסלקה פנסיונית</div>
          <text-input class="margin-top-l" :autofocus="true" label-text="שם מלא" :value="activeMemberFullName" :disabled="true"/>
          <text-input class="margin-top-l" :autofocus="true" label-text="מספר טלפון" :value="activeMemberPhoneNumber" :disabled="true"/>
            <validation-provider v-slot="{errors}" rules="id">
              <text-input class="margin-top-l" v-model="idNumber" :autofocus="true" label-text="תעודת זהות" :error-message="errors[0]" />
            </validation-provider>
          <text-input class="margin-top-l" label-text="עיר (לפי תעודת הזהות)" v-model="city" />
          <text-input class="margin-top-l" label-text="רחוב (לפי תעודת הזהות)" v-model="street"/>
          <email-input class="margin-top-l" :on-error="onEmailError" label-text="מייל" :value="email" :on-input="setEmail"/>

        <div class="ri-large-body margin-bottom-l">
          <div class="margin-bottom-m">
            <span>אני מייפה את כוחו של הסוכן תומר ורון, רשיון מספר 032540460, או מי מטעמו לפנות בשמי </span>
            <span>באופן חד־פעמי לכל גוף מוסדי כדי לקבל מידע לגבי מוצרים פנסיוניים ותוכניות ביטוח.</span>
          </div>
          <div class="margin-bottom-m">העברת מידע אודותיי כאמור לעיל יכולה להיעשות באמצעות מערכת סליקה פנסיונית.</div>
          <div class="margin-bottom-m">
            ייפוי כוח זה מתייחס לכל המוצרים הפנסיוניים המנוהלים עבורי בגוף מוסדי כלשהו נכון למועד אישור הרשאה זו.
          </div>
          <div>ההרשאה תעמוד בתוקף למשך 3 חודשים.</div>
        </div>
        <callout iconPath="icons/heart-icon.svg" title="כדאי לשים לב" class="margin-bottom-l">
          <template v-slot:text>
            ההרשאה תשמש לצורך שיקוף המידע בלבד, ולא יינתן ייעוץ פנסיוני לגבי המוצרים.
          </template>
        </callout>
        <check-box :initially-checked="false" v-model="isChecked" class="margin-top-l">
          <template v-slot:title>
            <div class="ri-label margin-right-s">קראתי ואישרתי את הפנייה מטעמכם בשמי למסלקה הפנסיונית לצורך שיקוף המידע בלבד</div>
          </template>
        </check-box>
        <riseup-button title="הבנתי, אפשר להמשיך"
                       :disabled="invalid || hasEmptyFields || emailError || !isChecked"
                       :action="onContinueClicked" variant="primary" :size="'slim'" class="margin-bottom-s margin-top-sm"/>
        </validation-observer>
      </div>
    </div>
</template>

<script>
import BaseUi from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DDLogs from '@/DDLogs';

export default {
  name: 'MislakaConsentForm',
  components: {
    RiseupButton: BaseUi.RiseupButton,
    TextInput: BaseUi.V2Components.TextInput,
    EmailInput: BaseUi.V2Components.EmailInput,
    Callout: BaseUi.Callout,
    CheckBox: BaseUi.CheckBox,
  },
  async created() {
    Segment.trackUserGot('MislakaConsentForm_Entered');
  },
  data() {
    return {
      idNumber: '',
      errorMessage: '',
      city: '',
      street: '',
      email: '',
      emailError: false,
      isChecked: false,
    };
  },
  computed: {
    ...mapGetters('session', ['activeMemberFullName', 'activeMemberPhoneNumber', 'activeMemberFirstName', 'activeMemberLastName']),
    hasEmptyFields() {
      return this.idNumber === '' || this.city === '' || this.street === '' || this.email === '';
    },
  },
  methods: {
    ...mapActions('assetsAndLiabilities', ['createMislakaConsent']),
    ...mapMutations('assetsAndLiabilities', ['setMislakaConsentData']),
    async onContinueClicked() {
      try {
        Segment.trackUserGot('MislakaConsentForm_ConfirmClicked');
        this.setMislakaConsentData({
          phoneNumber: this.activeMemberPhoneNumber,
          firstName: this.activeMemberFirstName,
          lastName: this.activeMemberLastName,
          idNumber: this.idNumber,
          city: this.city,
          street: this.street,
          email: this.email,
        });
        await this.createMislakaConsent();

        this.$emit('next');
      } catch (error) {
        Segment.trackUserGot('MislakaConsentForm_ConfirmError`');
        DDLogs.error('Failed saving clearing house consent', { error });
        this.$emit('error');
      }
    },
    setEmail(email) {
      this.email = email;
    },
    onEmailError(error) {
      this.emailError = error;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.mislaka-consent-form{
  text-align: right;
  .top-title {
    color: $riseup_blue;
  }

  ::v-deep .callout .icon-wrapper {
    justify-content: center;
  }
}

</style>
