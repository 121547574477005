const state = {
  pairs: [],
  enabled: false,
};

const getters = {
};

const actions = {
  initAnonymizeData({ commit }) {
    const anonymizeState = JSON.parse(localStorage.getItem('anonymizeState')) || {
      pairs: [],
      enabled: false,
    };
    commit('setAnonymizePairs', anonymizeState.pairs);
    commit('setAnonymizeEnabled', anonymizeState.enabled);
  },
  toggleEnabled({ commit, state }) {
    commit('setAnonymizeEnabled', !state.enabled);
  },
  save({ state }) {
    localStorage.setItem('anonymizeState', JSON.stringify({
      pairs: state.pairs,
      enabled: state.enabled,
    }));
  },
  addEmptyPair({ commit, state }) {
    commit('addEmptyPair');
  },
  removePair({ commit, state }, index) {
    commit('removePair', index);
  },
  updatePair({ commit, state }, { index, key, value }) {
    commit('updatePair', { index, key, value });
  },
};

// mutations
const mutations = {
  setAnonymizePairs(state, pairs) {
    state.pairs = pairs;
  },
  setAnonymizeEnabled(state, enabled) {
    state.enabled = enabled;
  },
  addEmptyPair(state) {
    state.pairs.push({
      from: '',
      to: '',
    });
  },
  removePair(state, index) {
    state.pairs.splice(index, 1);
  },
  updatePair(state, { index, key, value }) {
    state.pairs[index][key] = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
