<template>
  <div class="plan-history">
    <tabbed-section v-if="tabs" :tabs="tabs" @tab-click="tabClicked">
      <div class="plan-history-wrapper" :class="{ 'padding-bottom-ml': !noHistory }">
        <separator />
        <div v-if="noHistory" class="padding-vertical-xxl padding-horizontal-ml">
          <img src="/images/plan-ahead/full-flower.svg"/>
          <div class="title">עדיין אין עדכונים להציג</div>
          <div class="subtitle margin-top-xxs">{{ emptyStateText }}</div>
          <div v-if="isDepositsTab" class="subtitle margin-top-m">{{ externalFundText }}</div>
        </div>
        <plan-deposits v-else-if="isDepositsTab" :deposits="deposits"/>
        <plan-expenses v-else :expenses="expenses"/>
      </div>
    </tabbed-section>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUi from '@riseupil/base-ui';
import { mapGetters } from 'vuex';
import PlanDeposits from './PlanDeposits';
import PlanExpenses from './PlanExpenses';

export default {
  name: 'PlanHistory',
  components: {
    PlanExpenses,
    PlanDeposits,
    TabbedSection: BaseUi.TabbedSection,
    Separator: BaseUi.Separator,
  },
  props: {
    plan: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      TAB_KEY_DEPOSITS,
      TAB_KEY_EXPENSES,
      selectedTabKey: TAB_KEY_DEPOSITS,
    };
  },
  computed: {
    ...mapGetters('cashflowView', ['allExcludedTransactions']),
    tabs() {
      return _.compact([
        {
          key: TAB_KEY_DEPOSITS,
          text: 'כמה שמתי',
        },
        {
          key: TAB_KEY_EXPENSES,
          text: 'על מה הוצאתי',
        },
      ]);
    },
    deposits() {
      return this.plan.planTransactions.filter(t => t.amount > 0);
    },
    expenses() {
      return this.plan.relatedTransactionIds?.map(tid => this.allExcludedTransactions[tid]).filter(Boolean);
    },
    isDepositsTab() {
      return this.selectedTabKey === TAB_KEY_DEPOSITS;
    },
    noHistory() {
      return this.isDepositsTab ? !this.deposits?.length : !this.expenses?.length;
    },
    emptyStateText() {
      return this.isDepositsTab
        ? 'כאן נציג לך את ההתקדמות חודש אחר חודש.\nעדכון ראשון יופיע כשהחודש יסתיים.'
        : 'כדי להוסיף הוצאות של התוכנית, צריך להזיז אותן בתזרים לקטגוריה של התוכנית הזו';
    },
    externalFundText() {
      return 'אם כבר שמת בצד חלק מהסכום במקום אחר,\nאפשר להוסיף אותו לתוכנית בכפתור ״עריכה״.';
    },
  },
  methods: {
    tabClicked(key) {
      this.selectedTabKey = key;
    },
  },
};

const TAB_KEY_DEPOSITS = 'deposits';
const TAB_KEY_EXPENSES = 'expenses';

</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.plan-history {
  display: flex;
  flex-direction: column;

  .tabbed-section {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .plan-history-wrapper {
    background: $riseup_gray_01;
    flex: 1;
  }

  .header {
    font-weight: bold;
    display: flex;
    font-size: 24px;
    line-height: 30px;
  }

  .title {
    font-size: 18px;
    line-height: 20px;
    color: $riseup_gray_3;
    font-weight: bold;
    margin-top: 24px;
  }
  .subtitle {
    font-size: 16px;
    line-height: 20px;
    white-space: pre-wrap;
    color: $riseup_gray_3;
  }

  .align-text-right {
    text-align: right;
  }
}

</style>
