import moneyUtils from '@/utils/money';

const DEPOSIT_TYPE = { OPPORTUNISTIC: 0, MONTHLY: 1, REDUCE_MONTHLY: 2 };

const state = {
  proposedSavingsAmount: undefined,
  depositSum: undefined,
  depositType: undefined,
  idNumber: undefined,
  bankDetails: undefined,
  fundDetails: undefined,
};

const getters = {
  depositSum: state => state.depositSum,
  formattedDepositSum: state => moneyUtils.formatAmount(state.depositSum),
  proposedSavingsAmount: state => state.proposedSavingsAmount,
  isOpportunisticDeposit: state => state.depositType === DEPOSIT_TYPE.OPPORTUNISTIC,
  isMonthlyDeposit: state => state.depositType === DEPOSIT_TYPE.MONTHLY,
  isReduceMonthlyDeposit: state => state.depositType === DEPOSIT_TYPE.REDUCE_MONTHLY,
  idNumber: state => state.idNumber,
  bankDetails: state => state.bankDetails,
  fundDetails: state => state.fundDetails,
};

const mutations = {
  setOpportunisticDeposit(state) {
    state.depositType = DEPOSIT_TYPE.OPPORTUNISTIC;
  },
  setMonthlyDeposit(state) {
    state.depositType = DEPOSIT_TYPE.MONTHLY;
  },
  setReduceMonthlyDeposit(state) {
    state.depositType = DEPOSIT_TYPE.REDUCE_MONTHLY;
  },
  setDepositSum(state, depositSum) {
    state.depositSum = depositSum;
  },
  setIdNumber(state, idNumber) {
    state.idNumber = idNumber;
  },
  setBankDetails(state, bankDetails) {
    state.bankDetails = bankDetails;
  },
  setFundDetails(state, fundDetails) {
    state.fundDetails = fundDetails;
  },
  setProposedSavingsAmount(state, proposedSavingsAmount) {
    state.proposedSavingsAmount = proposedSavingsAmount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
