// Merge the one-off offerings display constants with the backend one-off offering data

export const SIGNUP_OFFERING_IDS = {
  HUNDRED_DAYS: '100-days',
  AUDIO_COURSE: 'lior-frenkel-audio-course',
  BOOST: 'boost',
  THIRTY_DAYS: '30-days',
};

export function fillOneOffOfferingDisplayInfo(signupOfferingDisplayInfo, offeringsData) {
  // Fill oneOff offering in the purchase info with the amount from the backend
  const oneOffOffering = offeringsData.find(({ id }) => id === signupOfferingDisplayInfo.purchaseInfo.details.offerId);
  const details = {
    ...signupOfferingDisplayInfo.purchaseInfo.details,
    amount: oneOffOffering?.price.amount ?? 0,
  };
  // Return the merged display info that will be saved on the store and accessed through all the components
  return {
    ...signupOfferingDisplayInfo,
    name: oneOffOffering.name,
    description: oneOffOffering.description,
    offeringCard: {
      ...signupOfferingDisplayInfo.offeringCard,
      price: oneOffOffering.price.amount,
    },
    purchaseInfo: {
      ...signupOfferingDisplayInfo.purchaseInfo,
      details,
    },
  };
}
