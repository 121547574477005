<template>
  <div class="anl-account-card margin-bottom-ml">
    <card class="padding-top-ml" :has-content-padding="false">
      <div class="padding-horizontal-ml">
        <div class="sourcename ri-font-20 ri-font-weight-bold">{{sourceName}}</div>
        <div v-if="accountNumber" class="account-number ri-body padding-top-xxs">{{accountNumber}}</div>
        <div v-if="name" class="account-number ri-body padding-top-xxs">{{name}}</div>
        <anl-balance class="margin-top-s margin-bottom-ml" :balance="amount" :custom-variant="isMortgage ? 'black' : undefined" />
      </div>
      <div>
        <separator />
        <div v-if="hasExtraInfo">
          <expandable-section>
            <template v-slot:header>
              <div class="ri-font-weight-bold padding-horizontal-ml">פרטים נוספים</div>
            </template>
            <template v-slot:content>
              <div class="extra-info">
                <div class="ri-font-14">
                  <template v-if="interests && interests.length">
                    <div class="extra-info-item">
                      <span>ריבית</span>
                      <span class="ri-font-weight-bold">{{interest}}%</span>
                    </div>
                    <separator />
                  </template>
                  <template v-if="contractEndDate">
                    <div class="extra-info-item">
                      <span>תאריך פרעון</span>
                      <span class="ri-font-weight-bold">{{formatDate(contractEndDate)}}</span>
                    </div>
                    <separator />
                  </template>
                  <template v-if="contractStartDate">
                    <div class="extra-info-item">
                      <span>תאריך פתיחה</span>
                      <span class="ri-font-weight-bold">{{formatDate(contractStartDate)}}</span>
                    </div>
                  </template>
                  <template v-if="liquidityDate">
                    <div class="extra-info-item">
                      <span>תאריך נזילות</span>
                      <span class="ri-font-weight-bold">{{formatDate(liquidityDate)}}</span>
                    </div>
                  </template>
                  <template v-if="fees && fees.length">
                    <div v-for="(fee, i) of fees" class="extra-info-item" :key="i">
                      <span>{{feesTypes[fee.typeId]}}</span>
                      <span class="ri-font-weight-bold">{{fee.value}}%</span>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </expandable-section>
        </div>
      </div>
    </card>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import _ from 'lodash';
import dateUtils from '@/utils/dates';

import AnlBalance from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AnlBalance';

export default {
  name: 'AnLAccountCard',
  components: {
    Card: BaseUI.Card,
    Separator: BaseUI.Separator,
    ExpandableSection: BaseUI.ExpandableSection,
    AnlBalance,
  },
  props: {
    sourceName: {
      type: String,
      required: true,
    },
    accountNumber: {
      type: String,
      required: false,
    },
    amount: {
      type: String,
      required: true,
    },
    calculatedInterest: {
      type: Object,
      required: false,
    },
    interests: {
      type: Array,
      required: false,
    },
    contractStartDate: {
      type: String,
      required: false,
    },
    contractEndDate: {
      type: String,
      required: false,
    },
    isMortgage: {
      type: Boolean,
      required: false,
      default: false,
    },
    liquidityDate: {
      type: String,
      required: false,
    },
    fees: {
      type: Array,
      required: false,
    },
    firstName: {
      type: String,
      required: false,
    },
    lastName: {
      type: String,
      required: false,
    },
  },
  computed: {
    hasExtraInfo() {
      return this.interests?.length || this.contractStartDate || this.contractEndDate || this.liquidityDate || this.fees?.length;
    },
    name() {
      if (this.firstName && this.lastName) {
        return `${this.firstName} ${this.lastName}`;
      }
      return undefined;
    },
    interest() {
      if (this.calculatedInterest?.rate) {
        return this.calculatedInterest.rate;
      }

      return _.chain(this.interests)
        .map(interest => +interest.rate[0].percentage)
        .sum()
        .round(2)
        .value();
    },
    feesTypes() {
      return { 1: 'דמי ניהול מצבירה', 2: 'דמי ניהול מהפקדה' };
    },
  },
  methods: {
    formatDate(date) {
      return dateUtils.normalizeDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';

.anl-account-card{

  .account-number, .sourcename {
    line-height: 26px;
  }

  .green{
    color: $riseup_green;
  }

  .red{
    color: $riseup_danger_red;
  }

  .extra-info{
    padding: 2px $ri-spacing-ml;
    background-color: $riseup_beige;
    box-shadow: inset 0 2px 6px -2px rgba(199, 199, 199, 0.5);

    .extra-info-item{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: $ri-spacing-sm 0;
    }
  }

  ::v-deep .expand-arrow{
    padding: 0 $ri-spacing-ml;
  }

}
</style>
