<template>
  <div class="missing-bank-ccs-popup">
    <colored-popup
      class="danger"
      :close-action="closeAction">
      <template v-slot:top-content  >
        <div class="insight-header ri-font-22 ri-font-weight-bold">
          בעקבות ההסכמה שנתת ב{{sourceName}}, חסרים כרטיסים בתזרים
        </div>
      </template>
      <template v-slot:bottom-content>
        <div class="insight-content">
          <img class="logo" :src="require(`@/assets/bank-logos/${logo}`)">
          <missing-credit-cards class="margin-top-s" :missing-card-numbers="cardNumbers" />
          <div class="margin-top-l">
            <span>כדי לקבל תזרים מלא, צריך </span>
            <span class="ri-font-weight-bold">לתת הסכמה מחדש ולסמן בה את הכרטיסים שחסרים.</span>
          </div>
          <missing-creds-partner-wraning-tooltip class="margin-top-l" />
          <riseup-button class="margin-top-l"
                         :action="openConnectionDecisionPopup"
                         variant="primary"
                         title="לחבר את הכרטיס החסר"
                         size="slim"/>
          </div>
      </template>
      <template v-slot:footer>
        <div @click="dismissInsight" class="footer-btn">הבנתי, אל תציגו לי יותר</div>
      </template>
    </colored-popup>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import MissingCreditCards from '@/pages/responsive-pages/non-authenticated/open-banking/MissingCreditCards';
import MissingCredsPartnerWraningTooltip
  from '@/pages/responsive-pages/non-authenticated/open-banking/MissingCredsPartnerWraningTooltip';
import ConnectMissingRegularCCsConnectionDecision
  from '@/pages/responsive-pages/non-authenticated/open-banking/ConnectMissingRegularCCsConnectionDecision';

export default {
  name: 'MissingRegularCCsAfterOBKMigrationPopup.vue',
  components: {
    MissingCredsPartnerWraningTooltip,
    MissingCreditCards,
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    missingCCs: { type: Array, required: true },
    insight: { type: Object, required: true },
  },
  created() {
    Segment.trackUserGot('MissingRegularCCsAfterOBKMigrationPopup_Entered');
  },
  computed: {
    ...mapGetters('credentials', ['getCredsByCredentialsId']),
    sourceConfiguration() {
      return getSourceConfigurationBySourceName(this.missingCCs[0].source);
    },
    sourceName() {
      return this.sourceConfiguration.displayName;
    },
    logo() {
      return this.sourceConfiguration.logoFile;
    },
    cardNumbers() {
      return _.map(this.missingCCs, c => c.accountNumberPiiValue);
    },
    missingSourceCreds() {
      return this.getCredsByCredentialsId(this.missingCCs[0].credentialsId);
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    ...mapActions('insights', ['markAsRead']),
    openConnectionDecisionPopup() {
      Segment.trackUserGot('ConnectMissingRegularCCsSuggestions_Connect_Clicked');
      this.openModal({
        component: ConnectMissingRegularCCsConnectionDecision,
        props: {
          source: this.missingSourceCreds,
          missingAccount: this.cardNumbers[0],
        },
        showLastModal: true,
      });
    },
    closeAction() {
      Segment.trackUserInteraction('MissingRegularCCsAfterOBKMigrationPopup_CloseClicked');
      this.closeModal();
    },
    dismissInsight() {
      Segment.trackUserInteraction('MissingRegularCCsAfterOBKMigrationPopup_CloseClicked', this.insight);
      this.closeModal();
      this.markAsRead(this.insight);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.missing-bank-ccs-popup {
  text-align: right;

  .insight-header {
    color: $riseup_white;
    line-height: 28px;
  }

  .insight-content {
    line-height: 26px;

    .continue-btn {
      margin-top: 26px;
    }

    .logo{
      height: 32px;
    }
  }

  .footer-btn {
    display: flex;
    justify-content: center;
    font-size: 18px;
    color: $riseup_black;
    cursor: pointer;
  }

}

</style>
