<template>
  <open-banking-description-step class="obk-description-connect-unsupported-accounts-step" :step-number="stepNumber">
    <template v-slot:text>
      <div class="bold">איזה חשבונות אי אפשר לחבר כרגע?</div>
      <div>חשבונות עסקיים, חשבונות הלוואה או משכנתא, וחשבונות מט״ח. נעדכן כשיהיה אפשר!</div>
    </template>
  </open-banking-description-step>
</template>

<script>
import OpenBankingDescriptionStep from '@/base-components/onboarding/openBanking/OpenBankingDescriptionStep';

export default {
  name: 'ObkDescriptionConnectUnsupportedAccountsStep',
  components: {
    OpenBankingDescriptionStep,
  },
  props: {
    stepNumber: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>

.obk-description-connect-unsupported-accounts-step {
  .bold {
    font-weight: bold;
  }
}

</style>
